(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.hq.db === region.id.db)
	{
		return 'on line ' + region.hq.db;
	}
	return 'on lines ' + region.hq.db + ' through ' + region.id.db;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.w,
		impl.ad,
		impl.mn,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		li: func(record.li),
		mg: record.mg,
		lR: record.lR
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.li;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.mg;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.lR) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.w,
		impl.ad,
		impl.mn,
		function(sendToApp, initialModel) {
			var view = impl.mE;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.w,
		impl.ad,
		impl.mn,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.hn && impl.hn(sendToApp)
			var view = impl.mE;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cK);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hx) && (_VirtualDom_doc.title = title = doc.hx);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.lH;
	var onUrlRequest = impl.lI;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		hn: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.jb === next.jb
							&& curr.iq === next.iq
							&& curr.i_.a === next.i_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		w: function(flags)
		{
			return A3(impl.w, flags, _Browser_getUrl(), key);
		},
		mE: impl.mE,
		ad: impl.ad,
		mn: impl.mn
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { kZ: 'hidden', kf: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { kZ: 'mozHidden', kf: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { kZ: 'msHidden', kf: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { kZ: 'webkitHidden', kf: 'webkitvisibilitychange' }
		: { kZ: 'hidden', kf: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		jm: _Browser_getScene(),
		jV: {
			mI: _Browser_window.pageXOffset,
			mJ: _Browser_window.pageYOffset,
			j_: _Browser_doc.documentElement.clientWidth,
			ip: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		j_: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		ip: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			jm: {
				j_: node.scrollWidth,
				ip: node.scrollHeight
			},
			jV: {
				mI: node.scrollLeft,
				mJ: node.scrollTop,
				j_: node.clientWidth,
				ip: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			jm: _Browser_getScene(),
			jV: {
				mI: x,
				mJ: y,
				j_: _Browser_doc.documentElement.clientWidth,
				ip: _Browser_doc.documentElement.clientHeight
			},
			kH: {
				mI: x + rect.left,
				mJ: y + rect.top,
				j_: rect.width,
				ip: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.cU.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.cU.b, xhr)); });
		$elm$core$Maybe$isJust(request.dN) && _Http_track(router, xhr, request.dN.a);

		try {
			xhr.open(request.dg, request.dS, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.dS));
		}

		_Http_configureRequest(xhr, request);

		request.cK.a && xhr.setRequestHeader('Content-Type', request.cK.a);
		xhr.send(request.cK.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.cY; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.dK.a || 0;
	xhr.responseType = request.cU.d;
	xhr.withCredentials = request.j4;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		dS: xhr.responseURL,
		me: xhr.status,
		mf: xhr.statusText,
		cY: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			l9: event.loaded,
			jx: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			lW: event.loaded,
			jx: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.ls) { flags += 'm'; }
	if (options.ke) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$LinkClicked = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Main$OnNavigation = function (a) {
	return {$: 4, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.n) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.p),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.p);
		} else {
			var treeLen = builder.n * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.t) : builder.t;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.n);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.p) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.p);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{t: nodeList, n: (len / $elm$core$Array$branchFactor) | 0, p: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ij: fragment, iq: host, lL: path, i_: port_, jb: protocol, bn: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Main$Redirect = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Cms = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotCmsMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$GotUserMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$User = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Pages$Cms$AddProductConfigModel = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Cms$ChangeDisplayIntervalModel = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Cms$ChangeDisplayOrderModel = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Cms$ChangeUserProductModel = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Cms$EditMailAddress = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$EditPermalinkGroups = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$EditProducts = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Cms$EditProductsPermalinks = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Cms$EditStoreGroups = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$EditStoreProperties = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$EditStores = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Cms$EditUsersStores = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$GetAccessCount = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$GotAddProductConfigMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Cms$GotChangeDisplayIntervalMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Cms$GotChangeDisplayOrderMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Cms$GotChangeUserProductMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Cms$GotEditMailAddressMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$GotEditPermalinkGroupsMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$GotEditProducts = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Cms$GotEditProductsPermalinks = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Cms$GotEditStoreGroupsMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$GotEditStorePropertiesMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Cms$GotEditStoresMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Cms$GotEditUsersStoresMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$GotGetAccessCountMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Cms$GotRakutenExcludedItems = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$GotUpdateHIitachiProductsOrderByCsv = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$GotUpdateHitachiWeeklyProductsByCsv = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$GotUpdateSharpProductsByCsv = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$GotUpdateTescomProductsByCsv = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Cms$GotUpdateTletProductsByCsv = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Cms$GotUpdateWeek30ByCsv = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$GotUserSpecificRoutingMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Cms$GotYahooMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$Cms$RakutenExcludedItems = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Cms$UpdateHitachiProductsOrderByCsv = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$UpdateHitachiWeeklyProductsByCsv = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$UpdateSharpProductsByCsv = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Cms$UpdateTescomProductsByCsv = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$UpdateTletProductsByCsv = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Cms$UpdateWeek30ByCsv = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Cms$UserSpecificRoutingModel = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Cms$Yahoo = function (a) {
	return {$: 22, a: a};
};
var $author$project$Routers$CmsRouter$NotFound = 0;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bb: frag, bm: params, a$: unvisited, i: value, bB: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.a$;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.i);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.i);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.lL),
					$elm$url$Url$Parser$prepareQuery(url.bn),
					url.ij,
					$elm$core$Basics$identity)));
	});
var $author$project$Routers$CmsRouter$AddProductConfig = 17;
var $author$project$Routers$CmsRouter$ChangeDisplayInterval = 14;
var $author$project$Routers$CmsRouter$ChangeDisplayOrder = 16;
var $author$project$Routers$CmsRouter$ChangeUserProduct = 15;
var $author$project$Routers$CmsRouter$EditMailAddress = 20;
var $author$project$Routers$CmsRouter$EditPermalinkGroups = 3;
var $author$project$Routers$CmsRouter$EditProducts = 19;
var $author$project$Routers$CmsRouter$EditProductsPermalinks = 18;
var $author$project$Routers$CmsRouter$EditStoreGroups = 4;
var $author$project$Routers$CmsRouter$EditStoreProperties = 2;
var $author$project$Routers$CmsRouter$EditStores = 22;
var $author$project$Routers$CmsRouter$EditUsersStores = 5;
var $author$project$Routers$CmsRouter$GetAccessCount = 21;
var $author$project$Routers$CmsRouter$RakutenExcludedItems = 13;
var $author$project$Routers$CmsRouter$Top = 1;
var $author$project$Routers$CmsRouter$UpdateHitachiProductsOrderByCsv = 11;
var $author$project$Routers$CmsRouter$UpdateHitachiWeeklyProductsByCsv = 10;
var $author$project$Routers$CmsRouter$UpdateSharpProductsByCsv = 9;
var $author$project$Routers$CmsRouter$UpdateTescomProductsByCsv = 8;
var $author$project$Routers$CmsRouter$UpdateTletProductsByCsv = 7;
var $author$project$Routers$CmsRouter$UpdateWeek30ByCsv = 6;
var $author$project$Routers$CmsRouter$UserSpecificRouting = 12;
var $author$project$Routers$CmsRouter$Yahoo = 23;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bB;
		var unvisited = _v0.a$;
		var params = _v0.bm;
		var frag = _v0.bb;
		var value = _v0.i;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bB;
			var unvisited = _v1.a$;
			var params = _v1.bm;
			var frag = _v1.bb;
			var value = _v1.i;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bB;
		var unvisited = _v0.a$;
		var params = _v0.bm;
		var frag = _v0.bb;
		var value = _v0.i;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routers$CmsRouter$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, 1, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			2,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('edit-store-properties')))),
			A2(
			$elm$url$Url$Parser$map,
			3,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('edit-permalink-groups')))),
			A2(
			$elm$url$Url$Parser$map,
			4,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('edit-store-groups')))),
			A2(
			$elm$url$Url$Parser$map,
			20,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('others'),
					$elm$url$Url$Parser$s('edit-mailaddress')))),
			A2(
			$elm$url$Url$Parser$map,
			22,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('edit-stores')))),
			A2(
			$elm$url$Url$Parser$map,
			5,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('edit-users-stores')))),
			A2(
			$elm$url$Url$Parser$map,
			6,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-week30-by-csv')))),
			A2(
			$elm$url$Url$Parser$map,
			7,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-tlet-products')))),
			A2(
			$elm$url$Url$Parser$map,
			8,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-tescom-products')))),
			A2(
			$elm$url$Url$Parser$map,
			9,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-sharp-products')))),
			A2(
			$elm$url$Url$Parser$map,
			10,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-hitachi-weekly-products')))),
			A2(
			$elm$url$Url$Parser$map,
			11,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('update-hitachi-products-order')))),
			A2(
			$elm$url$Url$Parser$map,
			12,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('user-specific-routing')))),
			A2(
			$elm$url$Url$Parser$map,
			13,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('rakuten-excluded-items')))),
			A2(
			$elm$url$Url$Parser$map,
			14,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('change-display-interval')))),
			A2(
			$elm$url$Url$Parser$map,
			15,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('change-user-product')))),
			A2(
			$elm$url$Url$Parser$map,
			16,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('change-display-order')))),
			A2(
			$elm$url$Url$Parser$map,
			17,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('add-product-config')))),
			A2(
			$elm$url$Url$Parser$map,
			18,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('edit-products-permalinks')))),
			A2(
			$elm$url$Url$Parser$map,
			19,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('edit-products')))),
			A2(
			$elm$url$Url$Parser$map,
			23,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('stores'),
					$elm$url$Url$Parser$s('yahoo')))),
			A2(
			$elm$url$Url$Parser$map,
			21,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('cms'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('others'),
					$elm$url$Url$Parser$s('get-access-count'))))
		]));
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Routers$CmsRouter$fromUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$url$Url$Parser$parse, $author$project$Routers$CmsRouter$parser, url));
};
var $author$project$Pages$Cms$Others$EditMailAddress$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Endpoints$Cms$cms = 'cms';
var $author$project$Endpoints$Cms$editMailAddress = 'edit-mailaddress';
var $author$project$Endpoints$Cms$others = 'others';
var $author$project$Endpoints$Cms$editMailAddressEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress, 'init']),
		_List_Nil),
	ld: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress, 'list-stores']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$editMailAddress, 'update']),
		_List_Nil)
};
var $author$project$Api$GET = 0;
var $author$project$Api$decodeHTTPMethod = function (method) {
	switch (method) {
		case 0:
			return 'GET';
		case 1:
			return 'POST';
		case 2:
			return 'PUT';
		default:
			return 'DELETE';
	}
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Api$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$elm$http$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($elm$http$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($elm$http$Http$NetworkError);
					case 3:
						var body = response.b;
						return $elm$core$Result$Err(
							$elm$http$Http$BadBody(body));
					default:
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$elm$http$Http$BadBody(
									$elm$json$Json$Decode$errorToString(err)));
						}
				}
			});
	});
var $author$project$Session$getConfig = function (session) {
	switch (session.$) {
		case 0:
			var config = session.e;
			return config;
		case 1:
			var config = session.c;
			return config;
		default:
			var config = session.b;
			return config;
	}
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Session$token = function (session) {
	switch (session.$) {
		case 0:
			var t = session.b;
			return $elm$core$Maybe$Just(t);
		case 1:
			var t = session.b;
			return $elm$core$Maybe$Just(t);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$makeAuthHeader = function (session) {
	var _v0 = $author$project$Session$token(session);
	if (!_v0.$) {
		var t = _v0.a;
		return _List_fromArray(
			[
				A2($elm$http$Http$header, 'authorization', 'Bearer ' + t),
				A2($elm$http$Http$header, 'Accept', 'application/json')
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Api$makeUrl = F2(
	function (ip, port_) {
		return ip + (':' + $elm$core$String$fromInt(port_));
	});
var $author$project$Utils$Constants$version = 'v1';
var $author$project$Api$makeEndpoint = F3(
	function (config, paths, queryParam) {
		var url = A2($author$project$Api$makeUrl, config.hN, config.hO);
		return _Utils_ap(
			url,
			A2(
				$elm$url$Url$Builder$absolute,
				A2(
					$elm$core$List$cons,
					'api',
					A2($elm$core$List$cons, $author$project$Utils$Constants$version, paths)),
				queryParam));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {je: reqs, jG: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.dN;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.je));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.jG)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					j4: r.j4,
					cK: r.cK,
					cU: A2(_Http_mapExpect, func, r.cU),
					cY: r.cY,
					dg: r.dg,
					dK: r.dK,
					dN: r.dN,
					dS: r.dS
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{j4: false, cK: r.cK, cU: r.cU, cY: r.cY, dg: r.dg, dK: r.dK, dN: r.dN, dS: r.dS}));
};
var $author$project$Api$get = F4(
	function (path, session, decoder, msg) {
		var apiConf = $author$project$Session$getConfig(session);
		var config = {
			cK: $elm$http$Http$emptyBody,
			cU: A2($author$project$Api$expectJson, msg, decoder),
			cY: $author$project$Api$makeAuthHeader(session),
			dg: $author$project$Api$decodeHTTPMethod(0),
			dK: $elm$core$Maybe$Nothing,
			dN: $elm$core$Maybe$Nothing,
			dS: A3(
				$author$project$Api$makeEndpoint,
				apiConf,
				_List_fromArray(
					[path]),
				_List_Nil)
		};
		return $elm$http$Http$request(config);
	});
var $author$project$Pages$Cms$Others$EditMailAddress$initNewPermalinkGroup = {at: false, R: 0, aV: ''};
var $author$project$Pages$Cms$Others$EditMailAddress$InitResponse = F2(
	function (users, permalinkGroups) {
		return {A: permalinkGroups, jT: users};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Pages$Cms$Others$EditMailAddress$PermalinkGroup = F3(
	function (permalinkGroupId, permalinkGroupName, isUpdated) {
		return {at: isUpdated, R: permalinkGroupId, aV: permalinkGroupName};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Pages$Cms$Others$EditMailAddress$permalinkGroupsDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Pages$Cms$Others$EditMailAddress$PermalinkGroup,
		A2($elm$json$Json$Decode$field, 'addr_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'mail_address', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Utils$Types$User = F2(
	function (userId, userName) {
		return {jS: userId, mD: userName};
	});
var $author$project$Utils$Decoders$usersDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Utils$Types$User,
		A2($elm$json$Json$Decode$field, 'user_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'user_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Others$EditMailAddress$initResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Others$EditMailAddress$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder),
	A2($elm$json$Json$Decode$field, 'mail_address', $author$project$Pages$Cms$Others$EditMailAddress$permalinkGroupsDecoder));
var $author$project$Pages$Cms$Others$EditMailAddress$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{ar: $elm$core$Maybe$Nothing, b2: true, J: false, cc: $author$project$Pages$Cms$Others$EditMailAddress$initNewPermalinkGroup, A: _List_Nil, hk: $elm$core$Maybe$Nothing, bu: session, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editMailAddressEndpoints.w, session, $author$project$Pages$Cms$Others$EditMailAddress$initResponseDecoder, $author$project$Pages$Cms$Others$EditMailAddress$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Others$GetAccessCount$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$getAccessCount = 'get-access-count';
var $author$project$Endpoints$Cms$getAccessCountEndpoints = {
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$getAccessCount, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$getAccessCount]),
		_List_Nil),
	hm: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$others, $author$project$Endpoints$Cms$getAccessCount, 'send']),
		_List_Nil)
};
var $author$project$Pages$Cms$Others$GetAccessCount$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Others$GetAccessCount$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Others$GetAccessCount$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Pages$Cms$Others$GetAccessCount$init = function (session) {
	return _Utils_Tuple2(
		{
			bE: {
				gc: {cH: $elm$core$Maybe$Nothing},
				gd: _List_Nil,
				hC: $elm$core$Maybe$Nothing
			},
			ar: $elm$core$Maybe$Nothing,
			b2: true,
			du: $elm$core$Maybe$Nothing,
			dv: $elm$core$Maybe$Nothing,
			hk: $elm$core$Maybe$Nothing,
			bu: session,
			jT: _List_Nil
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4($author$project$Api$get, $author$project$Endpoints$Cms$getAccessCountEndpoints.w, session, $author$project$Pages$Cms$Others$GetAccessCount$initResponseDecoder, $author$project$Pages$Cms$Others$GetAccessCount$InitRequestSent)
				])));
};
var $author$project$Pages$Cms$Products$AddProductConfig$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$addProductConfig = 'add-product-config';
var $author$project$Endpoints$Cms$products = 'products';
var $author$project$Endpoints$Cms$addProductConfigEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$addProductConfig, 'add']),
		_List_Nil),
	gj: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$addProductConfig, 'categories']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$addProductConfig, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$addProductConfig]),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$AddProductConfig$InitResponse = F2(
	function (users, makers) {
		return {ca: makers, jT: users};
	});
var $author$project$Utils$Types$Maker = F2(
	function (makerId, makerName) {
		return {iJ: makerId, lg: makerName};
	});
var $author$project$Utils$Decoders$makersDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Utils$Types$Maker,
		A2($elm$json$Json$Decode$field, 'maker_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'maker_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$AddProductConfig$initResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$AddProductConfig$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Cms$Products$AddProductConfig$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{bF: '', bG: '', hM: $elm$core$Maybe$Nothing, hR: _List_Nil, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, gz: false, b2: true, c5: false, J: false, ca: _List_Nil, ja: _List_Nil, he: $elm$core$Maybe$Nothing, dw: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, fJ: $elm$core$Maybe$Nothing, bu: session, jy: _List_Nil, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$addProductConfigEndpoints.w, session, $author$project$Pages$Cms$Products$AddProductConfig$initResponseDecoder, $author$project$Pages$Cms$Products$AddProductConfig$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$changeDisplayInterval = 'change-display-interval';
var $author$project$Endpoints$Cms$changeDisplayIntervalEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'add']),
		_List_Nil),
	gj: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'categories']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'init']),
		_List_Nil),
	ja: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'products']),
		_List_Nil),
	hc: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'remove']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'update']),
		_List_Nil),
	hB: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayInterval, 'update-all']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$ChangeDisplayInterval$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, hR: _List_Nil, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, gz: false, b2: true, c5: false, J: false, ca: _List_Nil, ja: _List_Nil, he: $elm$core$Maybe$Nothing, dw: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, fJ: $elm$core$Maybe$Nothing, bu: session, jy: _List_Nil, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$changeDisplayIntervalEndpoints.w, session, $author$project$Pages$Cms$Products$ChangeDisplayInterval$initResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayInterval$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$changeDisplayOrder = 'change-display-order';
var $author$project$Endpoints$Cms$changeDisplayOrderEndpoints = {
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayOrder, 'init']),
		_List_Nil),
	ja: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayOrder, 'products']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayOrder]),
		_List_Nil),
	hB: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeDisplayOrder, 'update-all']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$ChangeDisplayOrder$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, hR: _List_Nil, ar: $elm$core$Maybe$Nothing, gz: false, b2: true, c5: false, J: false, ca: _List_Nil, ja: _List_Nil, he: $elm$core$Maybe$Nothing, dw: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, fJ: $elm$core$Maybe$Nothing, bu: session, jy: _List_Nil, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$changeDisplayOrderEndpoints.w, session, $author$project$Pages$Cms$Products$ChangeDisplayOrder$initResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayOrder$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$changeUserProduct = 'change-user-product';
var $author$project$Endpoints$Cms$changeUserProductEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'add']),
		_List_Nil),
	gj: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'categories']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'init']),
		_List_Nil),
	ja: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'products']),
		_List_Nil),
	hc: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'remove']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'update']),
		_List_Nil),
	hB: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$changeUserProduct, 'update-all']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$initAddProduct = {
	j9: 0,
	ka: 0,
	aI: true,
	kC: _List_fromArray(
		[15]),
	kE: 0,
	aP: true,
	$9: 0,
	i8: '',
	lT: '',
	lU: 0,
	cG: false,
	mM: false
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$ChangeUserProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Pages$Cms$Products$ChangeUserProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{Y: $author$project$Pages$Cms$Products$ChangeUserProduct$initAddProduct, hM: $elm$core$Maybe$Nothing, hR: _List_Nil, cR: 0, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, gz: false, b2: true, c5: false, J: false, ca: _List_Nil, ja: _List_Nil, he: $elm$core$Maybe$Nothing, dw: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, fJ: $elm$core$Maybe$Nothing, bu: session, jy: _List_Nil, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$changeUserProductEndpoints.w, session, $author$project$Pages$Cms$Products$ChangeUserProduct$initResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Products$EditProducts$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$editProducts = 'edit-products';
var $author$project$Endpoints$Cms$editProductsEndpoints = {
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProducts, 'init']),
		_List_Nil),
	gQ: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProducts, 'list-stores']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProducts]),
		_List_Nil),
	hm: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProducts, 'send']),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProducts, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$EditProducts$InitResponse = F2(
	function (makers, small_categories) {
		return {ca: makers, jz: small_categories};
	});
var $author$project$Pages$Cms$Products$EditProducts$Maker = F2(
	function (maker_id, maker_name) {
		return {bj: maker_id, iK: maker_name};
	});
var $author$project$Pages$Cms$Products$EditProducts$makersDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Products$EditProducts$Maker,
		A2($elm$json$Json$Decode$field, 'maker_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'maker_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$EditProducts$SmallCategory = F4(
	function (small_category_id, small_category_name, big_category_name, category_name) {
		return {hS: big_category_name, hZ: category_name, bv: small_category_id, jA: small_category_name};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Pages$Cms$Products$EditProducts$smallCategoriesDecoder = $elm$json$Json$Decode$list(
	A5(
		$elm$json$Json$Decode$map4,
		$author$project$Pages$Cms$Products$EditProducts$SmallCategory,
		A2($elm$json$Json$Decode$field, 'small_category_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'small_category_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'big_category_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'category_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$EditProducts$initResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$EditProducts$InitResponse,
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Pages$Cms$Products$EditProducts$makersDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Pages$Cms$Products$EditProducts$smallCategoriesDecoder));
var $author$project$Pages$Cms$Products$EditProducts$init = function (session) {
	return _Utils_Tuple2(
		{eJ: true, c1: '', c2: '', eM: false, eQ: false, ca: _List_Nil, i8: '', lT: '', ao: _List_Nil, jq: _List_Nil, bu: session, jy: _List_Nil, f$: false},
		A4($author$project$Api$get, $author$project$Endpoints$Cms$editProductsEndpoints.w, session, $author$project$Pages$Cms$Products$EditProducts$initResponseDecoder, $author$project$Pages$Cms$Products$EditProducts$InitRequestSent));
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Show = 0;
var $author$project$Endpoints$Cms$editProductsPermalinks = 'edit-products-permalinks';
var $author$project$Endpoints$Cms$editProductsPermalinksEndpoints = {
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProductsPermalinks, 'init']),
		_List_Nil),
	gQ: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProductsPermalinks, 'list-stores']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProductsPermalinks]),
		_List_Nil),
	hm: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProductsPermalinks, 'send']),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$editProductsPermalinks, 'update']),
		_List_Nil)
};
var $inkuzmin$elm_multiselect$Multiselect$Closed = 0;
var $inkuzmin$elm_multiselect$Multiselect$Model = $elm$core$Basics$identity;
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $inkuzmin$elm_multiselect$Multiselect$initModel = F3(
	function (values, tag1, inputInMenu) {
		return {
			a8: $elm$core$Maybe$Nothing,
			k: values,
			l: $elm$core$List$head(values),
			ix: $elm$core$Maybe$Just(''),
			gH: inputInMenu,
			gI: 23.0,
			ax: false,
			jo: _List_Nil,
			ac: 0,
			u: tag1,
			q: values
		};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$InitResponse = F2(
	function (users, stores) {
		return {ap: stores, jT: users};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$Store = F9(
	function (store_id, store_name, store_group_id, url, exhibition_name, property_id, permalink_group_id, display_name, isUpdated) {
		return {h7: display_name, kL: exhibition_name, at: isUpdated, y: permalink_group_id, lV: property_id, ml: store_group_id, fO: store_id, dz: store_name, dS: url};
	});
var $elm_community$json_extra$Json$Decode$Extra$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$storeDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$succeed(false),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'display_name',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'permalink_group_id',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'property_id',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'exhibition_name',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'url',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'store_group_id',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'store_name',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Products$EditProductsPermalinks$Store))))))))));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$storesDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int)),
		$elm$json$Json$Decode$list($author$project$Pages$Cms$Products$EditProductsPermalinks$storeDecoder)));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$usersDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Utils$Types$User,
		A2($elm$json$Json$Decode$field, 'user_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'user_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$initResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$EditProductsPermalinks$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Pages$Cms$Products$EditProductsPermalinks$usersDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Pages$Cms$Products$EditProductsPermalinks$storesDecoder));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$init = function (session) {
	return _Utils_Tuple2(
		{
			eJ: true,
			eM: false,
			eQ: false,
			r: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'stores-selector', 0),
			x: _List_Nil,
			i8: '',
			lT: '',
			dx: '',
			dy: '',
			ju: $elm$core$Maybe$Nothing,
			fJ: $elm$core$Maybe$Nothing,
			aY: _List_Nil,
			bu: session,
			ap: $elm$core$Dict$empty,
			dT: _List_Nil,
			jT: _List_Nil,
			gb: false
		},
		A4($author$project$Api$get, $author$project$Endpoints$Cms$editProductsPermalinksEndpoints.w, session, $author$project$Pages$Cms$Products$EditProductsPermalinks$initResponseDecoder, $author$project$Pages$Cms$Products$EditProductsPermalinks$InitRequestSent));
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$Product = F2(
	function (productId, productName) {
		return {i8: productId, lT: productName};
	});
var $author$project$Pages$Cms$Products$RakutenExcludedItems$productsDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Products$RakutenExcludedItems$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$RakutenExcludedItems$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$RakutenExcludedItems$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Cms$Products$RakutenExcludedItems$productsDecoder));
var $author$project$Endpoints$Cms$rakutenExcludedItems = 'rakuten-excluded-items';
var $author$project$Endpoints$Cms$rakutenExcludedItemsEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$rakutenExcludedItems, 'add']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$rakutenExcludedItems, 'init']),
		_List_Nil),
	hc: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$rakutenExcludedItems, 'remove']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$rakutenExcludedItems]),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$init = function (session) {
	return _Utils_Tuple2(
		{bF: '', eJ: true, ja: _List_Nil, co: '', bu: session},
		A4($author$project$Api$get, $author$project$Endpoints$Cms$rakutenExcludedItemsEndpoints.w, session, $author$project$Pages$Cms$Products$RakutenExcludedItems$initResponseDecoder, $author$project$Pages$Cms$Products$RakutenExcludedItems$InitRequestSent));
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$init = function (session) {
	return _Utils_Tuple2(
		{
			hR: _List_fromArray(
				[
					{ed: 1, kb: '冷蔵庫'},
					{ed: 2, kb: '洗濯機'},
					{ed: 3, kb: 'クリーナー・空清'},
					{ed: 4, kb: 'レンジ・炊飯器'},
					{ed: 5, kb: '小家電'},
					{ed: 6, kb: 'ビルトインIH'}
				]),
			ar: $elm$core$Maybe$Nothing,
			b9: false,
			he: $elm$core$Maybe$Nothing,
			bu: session,
			mo: false
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, b9: false, bu: session, mo: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, b9: false, bu: session, mo: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, b9: false, bu: session, mo: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, b9: false, bu: session, mo: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, b9: false, bu: session, mo: false},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$UserSpecificRouting$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Endpoints$Cms$userSpecificRouting = 'user-specific-routing';
var $author$project$Endpoints$Cms$userSpecificRoutingEndpoints = {
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$userSpecificRouting, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$userSpecificRouting]),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{ar: $elm$core$Maybe$Nothing, b2: true, fJ: $elm$core$Maybe$Nothing, bu: session, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$userSpecificRoutingEndpoints.w, session, $author$project$Pages$Cms$Products$UserSpecificRouting$initResponseDecoder, $author$project$Pages$Cms$Products$UserSpecificRouting$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$editPermalinkGroup = 'edit-permalink-groups';
var $author$project$Endpoints$Cms$stores = 'stores';
var $author$project$Endpoints$Cms$editPermalinkGroupsEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editPermalinkGroup, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editPermalinkGroup, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editPermalinkGroup, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editPermalinkGroup]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editPermalinkGroup, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$initNewPermalinkGroup = {at: false, R: 0, aV: ''};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$InitResponse = function (permalinkGroups) {
	return {A: permalinkGroups};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$PermalinkGroup = F3(
	function (permalinkGroupId, permalinkGroupName, isUpdated) {
		return {at: isUpdated, R: permalinkGroupId, aV: permalinkGroupName};
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$permalinkGroupsDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Pages$Cms$Stores$EditPermalinkGroups$PermalinkGroup,
		A2($elm$json$Json$Decode$field, 'permalink_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'permalink_group_name', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditPermalinkGroups$InitResponse,
	A2($elm$json$Json$Decode$field, 'permalink_groups', $author$project$Pages$Cms$Stores$EditPermalinkGroups$permalinkGroupsDecoder));
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{ar: $elm$core$Maybe$Nothing, b2: true, J: false, cc: $author$project$Pages$Cms$Stores$EditPermalinkGroups$initNewPermalinkGroup, A: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.w, session, $author$project$Pages$Cms$Stores$EditPermalinkGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditPermalinkGroups$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$editStoreGroup = 'edit-store-groups';
var $author$project$Endpoints$Cms$editStoreGroupsEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreGroup, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreGroup, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreGroup, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreGroup]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreGroup, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$initNewStoreGroup = {at: false, mh: 0, mi: ''};
var $author$project$Pages$Cms$Stores$EditStoreGroups$InitResponse = function (storeGroups) {
	return {U: storeGroups};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$StoreGroup = F3(
	function (storeGroupId, storeGroupName, isUpdated) {
		return {at: isUpdated, mh: storeGroupId, mi: storeGroupName};
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$storeGroupsDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Pages$Cms$Stores$EditStoreGroups$StoreGroup,
		A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Pages$Cms$Stores$EditStoreGroups$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditStoreGroups$InitResponse,
	A2($elm$json$Json$Decode$field, 'store_groups', $author$project$Pages$Cms$Stores$EditStoreGroups$storeGroupsDecoder));
var $author$project$Pages$Cms$Stores$EditStoreGroups$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{ar: $elm$core$Maybe$Nothing, b2: true, J: false, cd: $author$project$Pages$Cms$Stores$EditStoreGroups$initNewStoreGroup, bu: session, U: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editStoreGroupsEndpoints.w, session, $author$project$Pages$Cms$Stores$EditStoreGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStoreGroups$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$None = 0;
var $author$project$Endpoints$Cms$editStoreProperty = 'edit-store-properties';
var $author$project$Endpoints$Cms$editStorePropertiesEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreProperty, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreProperty, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreProperty, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreProperty]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStoreProperty, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$InitResponse = F3(
	function (storeProperties, convertTypes, drivers) {
		return {a2: convertTypes, a6: drivers, V: storeProperties};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$ConvertType = F2(
	function (convertType, description) {
		return {em: convertType, kB: description};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$KeyLabel = F2(
	function (key, label) {
		return {c7: key, iF: label};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$convertTypesDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Stores$EditStoreProperties$ConvertType,
		A3(
			$elm$json$Json$Decode$map2,
			$author$project$Pages$Cms$Stores$EditStoreProperties$KeyLabel,
			A2($elm$json$Json$Decode$field, 'convert_type_id', $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$field, 'convert_type_name', $elm$json$Json$Decode$string)),
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditStoreProperties$driversDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Stores$EditStoreProperties$KeyLabel,
		A2($elm$json$Json$Decode$field, 'driver_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'driver_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditStoreProperties$StoreProperty = function (property_id) {
	return function (property_name) {
		return function (price_selector) {
			return function (price_regex) {
				return function (point_selector) {
					return function (point_regex) {
						return function (constant_point) {
							return function (soldout_selector) {
								return function (soldout_regex) {
									return function (convert_type_id) {
										return function (sleep_time) {
											return function (driver_id) {
												return function (isUpdated) {
													return {bQ: constant_point, bR: convert_type_id, bV: driver_id, at: isUpdated, cf: point_regex, cg: point_selector, ci: price_regex, cj: price_selector, lV: property_id, cm: property_name, cv: sleep_time, cx: soldout_regex, cy: soldout_selector};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$storePropertyDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$succeed(false),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'driver_id', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'sleep_time', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'convert_type_id', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'soldout_regex',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'soldout_selector',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'constant_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'point_regex',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2(
										$elm$json$Json$Decode$field,
										'point_selector',
										$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'price_regex', $elm$json$Json$Decode$string),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'price_selector', $elm$json$Json$Decode$string),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2($elm$json$Json$Decode$field, 'property_name', $elm$json$Json$Decode$string),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A2($elm$json$Json$Decode$field, 'property_id', $elm$json$Json$Decode$int),
													$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Stores$EditStoreProperties$StoreProperty))))))))))))));
var $author$project$Pages$Cms$Stores$EditStoreProperties$storePropertiesDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2($elm$json$Json$Decode$field, 'property_id', $elm$json$Json$Decode$int)),
		$elm$json$Json$Decode$list($author$project$Pages$Cms$Stores$EditStoreProperties$storePropertyDecoder)));
var $author$project$Pages$Cms$Stores$EditStoreProperties$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Cms$Stores$EditStoreProperties$InitResponse,
	A2($elm$json$Json$Decode$field, 'store_properties', $author$project$Pages$Cms$Stores$EditStoreProperties$storePropertiesDecoder),
	A2($elm$json$Json$Decode$field, 'convert_types', $author$project$Pages$Cms$Stores$EditStoreProperties$convertTypesDecoder),
	A2($elm$json$Json$Decode$field, 'webdrivers', $author$project$Pages$Cms$Stores$EditStoreProperties$driversDecoder));
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$newStoreProperty = {
	bQ: 0,
	bR: 1,
	bV: 1,
	at: false,
	cf: $elm$core$Maybe$Just('.*?(\\d{1,3}(,\\d{3})*).*?'),
	cg: $elm$core$Maybe$Nothing,
	ci: '.*?(\\d{1,3}(,\\d{3})*).*?',
	cj: '',
	lV: -1,
	cm: '',
	cv: 5,
	cx: $elm$core$Maybe$Nothing,
	cy: $elm$core$Maybe$Nothing
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$init = function (session) {
	return _Utils_Tuple2(
		{a2: _List_Nil, a6: _List_Nil, ar: $elm$core$Maybe$Nothing, eJ: true, aS: $author$project$Pages$Cms$Stores$EditStoreProperties$newStoreProperty, fg: $elm$core$Dict$empty, bu: session, V: $elm$core$Dict$empty, F: 0, a0: $elm$core$Maybe$Nothing},
		A4($author$project$Api$get, $author$project$Endpoints$Cms$editStorePropertiesEndpoints.w, session, $author$project$Pages$Cms$Stores$EditStoreProperties$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStoreProperties$InitRequestSent));
};
var $author$project$Pages$Cms$Stores$EditStores$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$None = 0;
var $author$project$Endpoints$Cms$editStores = 'edit-stores';
var $author$project$Endpoints$Cms$editStoresEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStores, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStores, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStores, 'init']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStores]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editStores, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Stores$EditStores$InitResponse = F4(
	function (storeProperties, storeGroups, permalinkGroups, stores) {
		return {A: permalinkGroups, U: storeGroups, V: storeProperties, ap: stores};
	});
var $author$project$Utils$Types$PermalinkGroup = F2(
	function (permalink_group_id, permalink_group_name) {
		return {y: permalink_group_id, iX: permalink_group_name};
	});
var $author$project$Pages$Cms$Stores$EditStores$permalinkGroupsDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Utils$Types$PermalinkGroup,
		A2($elm$json$Json$Decode$field, 'permalink_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'permalink_group_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditStores$StoreGroup = F2(
	function (store_group_id, store_group_name) {
		return {ml: store_group_id, jE: store_group_name};
	});
var $author$project$Pages$Cms$Stores$EditStores$storeGroupsDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Stores$EditStores$StoreGroup,
		A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditStores$StoreProperty = function (property_id) {
	return function (property_name) {
		return function (price_selector) {
			return function (price_regex) {
				return function (point_selector) {
					return function (point_regex) {
						return function (constant_point) {
							return function (soldout_selector) {
								return function (soldout_regex) {
									return function (convert_type_id) {
										return function (sleep_time) {
											return function (driver_id) {
												return {bQ: constant_point, bR: convert_type_id, bV: driver_id, cf: point_regex, cg: point_selector, ci: price_regex, cj: price_selector, lV: property_id, cm: property_name, cv: sleep_time, cx: soldout_regex, cy: soldout_selector};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Cms$Stores$EditStores$storePropertyDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'driver_id', $elm$json$Json$Decode$int),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'sleep_time', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'convert_type_id', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'soldout_regex',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'soldout_selector',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'constant_point', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'point_regex',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'point_selector',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'price_regex', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'price_selector', $elm$json$Json$Decode$string),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'property_name', $elm$json$Json$Decode$string),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2($elm$json$Json$Decode$field, 'property_id', $elm$json$Json$Decode$int),
												$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Stores$EditStores$StoreProperty)))))))))))));
var $author$project$Pages$Cms$Stores$EditStores$storePropertiesDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2($elm$json$Json$Decode$field, 'property_id', $elm$json$Json$Decode$int)),
		$elm$json$Json$Decode$list($author$project$Pages$Cms$Stores$EditStores$storePropertyDecoder)));
var $author$project$Pages$Cms$Stores$EditStores$Store = F9(
	function (store_id, store_name, store_group_id, url, exhibition_name, property_id, permalink_group_id, display_name, isUpdated) {
		return {h7: display_name, kL: exhibition_name, at: isUpdated, y: permalink_group_id, lV: property_id, ml: store_group_id, fO: store_id, dz: store_name, dS: url};
	});
var $author$project$Pages$Cms$Stores$EditStores$storeDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	$elm$json$Json$Decode$succeed(false),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'display_name',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'permalink_group_id',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'property_id',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'exhibition_name',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2(
							$elm$json$Json$Decode$field,
							'url',
							$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'store_group_id',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'store_name',
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Stores$EditStores$Store))))))))));
var $author$project$Pages$Cms$Stores$EditStores$storesDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int)),
		$elm$json$Json$Decode$list($author$project$Pages$Cms$Stores$EditStores$storeDecoder)));
var $author$project$Pages$Cms$Stores$EditStores$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Cms$Stores$EditStores$InitResponse,
	A2($elm$json$Json$Decode$field, 'store_properties', $author$project$Pages$Cms$Stores$EditStores$storePropertiesDecoder),
	A2($elm$json$Json$Decode$field, 'store_groups', $author$project$Pages$Cms$Stores$EditStores$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'permalink_groups', $author$project$Pages$Cms$Stores$EditStores$permalinkGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Pages$Cms$Stores$EditStores$storesDecoder));
var $author$project$Pages$Cms$Stores$EditStores$newStore = {h7: $elm$core$Maybe$Nothing, kL: $elm$core$Maybe$Nothing, at: false, y: $elm$core$Maybe$Nothing, lV: $elm$core$Maybe$Nothing, ml: $elm$core$Maybe$Nothing, fO: -1, dz: $elm$core$Maybe$Nothing, dS: $elm$core$Maybe$Nothing};
var $author$project$Pages$Cms$Stores$EditStores$init = function (session) {
	return _Utils_Tuple2(
		{ar: $elm$core$Maybe$Nothing, eJ: true, eQ: false, bl: $author$project$Pages$Cms$Stores$EditStores$newStore, A: _List_Nil, jp: $elm$core$Maybe$Nothing, jr: $elm$core$Maybe$Nothing, jt: $elm$core$Maybe$Nothing, bu: session, U: _List_Nil, V: $elm$core$Dict$empty, ap: $elm$core$Dict$empty, F: 0, f0: $elm$core$Maybe$Nothing},
		A4($author$project$Api$get, $author$project$Endpoints$Cms$editStoresEndpoints.w, session, $author$project$Pages$Cms$Stores$EditStores$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStores$InitRequestSent));
};
var $author$project$Pages$Cms$Stores$EditUsersStores$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$Cms$editUsersStores = 'edit-users-stores';
var $author$project$Endpoints$Cms$editUsersStoresEndpoints = {
	ae: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores, 'add']),
		_List_Nil),
	bS: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores, 'delete']),
		_List_Nil),
	w: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores, 'init']),
		_List_Nil),
	gQ: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores, 'list-stores']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores]),
		_List_Nil),
	ad: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$editUsersStores, 'update']),
		_List_Nil)
};
var $author$project$Pages$Cms$Stores$EditUsersStores$initNewUserStore = {h6: $elm$core$Maybe$Nothing, kS: false, kT: $elm$core$Maybe$Nothing, at: false, mh: 0, mi: '', hs: -1, jD: '', mk: 0};
var $author$project$Pages$Cms$Stores$EditUsersStores$InitResponse = F3(
	function (users, storeGroups, stores) {
		return {U: storeGroups, ap: stores, jT: users};
	});
var $author$project$Utils$Types$Store = F3(
	function (storeId, storeName, displayName) {
		return {h6: displayName, hs: storeId, jD: storeName};
	});
var $author$project$Utils$Decoders$storesDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Utils$Types$Store,
		A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'display_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditUsersStores$TStoreGroup = F2(
	function (storeGroupId, storeGroupName) {
		return {mh: storeGroupId, mi: storeGroupName};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$tStoreGroupsDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Pages$Cms$Stores$EditUsersStores$TStoreGroup,
		A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Stores$EditUsersStores$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Cms$Stores$EditUsersStores$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder),
	A2($elm$json$Json$Decode$field, 'store_groups', $author$project$Pages$Cms$Stores$EditUsersStores$tStoreGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $author$project$Pages$Cms$Stores$EditUsersStores$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{ar: $elm$core$Maybe$Nothing, b2: true, J: false, s: $author$project$Pages$Cms$Stores$EditUsersStores$initNewUserStore, fG: $elm$core$Maybe$Nothing, hk: $elm$core$Maybe$Nothing, bu: session, U: _List_Nil, ap: _List_Nil, dT: _List_Nil, jT: _List_Nil},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editUsersStoresEndpoints.w, session, $author$project$Pages$Cms$Stores$EditUsersStores$initResponseDecoder, $author$project$Pages$Cms$Stores$EditUsersStores$InitRequestSent)
					])));
	});
var $author$project$Pages$Cms$Stores$Yahoo$GotInitResponse = function (a) {
	return {$: 0, a: a};
};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Pages$Cms$Stores$Yahoo$InitResponse = function (users) {
	return {jT: users};
};
var $author$project$Pages$Cms$Stores$Yahoo$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$Yahoo$InitResponse,
	A2($elm$json$Json$Decode$field, 'users', $author$project$Utils$Decoders$usersDecoder));
var $author$project$Endpoints$Cms$yahoo = 'yahoo';
var $author$project$Endpoints$Cms$yahooEndpoints = {
	kR: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$yahoo, 'direct-urls'])),
	kV: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$yahoo, 'get-products'])),
	w: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$yahoo, 'init'])),
	j: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$yahoo])),
	ad: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$stores, $author$project$Endpoints$Cms$yahoo, 'update']))
};
var $author$project$Pages$Cms$Stores$Yahoo$init = function (session) {
	return _Utils_Tuple2(
		{aG: $krisajenkins$remotedata$RemoteData$Loading, eK: $krisajenkins$remotedata$RemoteData$Loading, b4: false, eR: false, ja: $krisajenkins$remotedata$RemoteData$NotAsked, hg: '', cr: '', bu: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$Cms$yahooEndpoints.w(_List_Nil),
					session,
					$author$project$Pages$Cms$Stores$Yahoo$initResponseDecoder,
					$author$project$Pages$Cms$Stores$Yahoo$GotInitResponse)
				])));
};
var $author$project$Pages$Cms$Others$EditMailAddress$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Others$GetAccessCount$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$AddProductConfig$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$EditProducts$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$EditStores$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$EditUsersStores$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$Stores$Yahoo$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Cms$toSession = function (_v0) {
	var cmsModel = _v0.a;
	switch (cmsModel.$) {
		case 0:
			var session = cmsModel.a;
			return session;
		case 1:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$EditStoreProperties$toSession(espModel);
		case 2:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$EditPermalinkGroups$toSession(espModel);
		case 3:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$EditStoreGroups$toSession(espModel);
		case 4:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Others$EditMailAddress$toSession(espModel);
		case 5:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Others$GetAccessCount$toSession(espModel);
		case 6:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$EditUsersStores$toSession(espModel);
		case 21:
			var espModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$EditStores$toSession(espModel);
		case 10:
			var uwModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$toSession(uwModel);
		case 11:
			var model = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$toSession(model);
		case 12:
			var model = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$toSession(model);
		case 9:
			var model = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$toSession(model);
		case 7:
			var model = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$toSession(model);
		case 8:
			var model = cmsModel.a;
			return $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$toSession(model);
		case 14:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$RakutenExcludedItems$toSession(reiModel);
		case 15:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$ChangeDisplayInterval$toSession(reiModel);
		case 13:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$UserSpecificRouting$toSession(reiModel);
		case 16:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$ChangeUserProduct$toSession(reiModel);
		case 17:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$AddProductConfig$toSession(reiModel);
		case 18:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$ChangeDisplayOrder$toSession(reiModel);
		case 19:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$EditProductsPermalinks$toSession(reiModel);
		case 20:
			var reiModel = cmsModel.a;
			return $author$project$Pages$Cms$Products$EditProducts$toSession(reiModel);
		default:
			var yModel = cmsModel.a;
			return $author$project$Pages$Cms$Stores$Yahoo$toSession(yModel);
	}
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Pages$Cms$updateWithNavModel = F4(
	function (toModel, toMsg, navModel, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple2(
				toModel(subModel),
				navModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Pages$Cms$changeRouteTo = F2(
	function (url, model) {
		var router = $author$project$Routers$CmsRouter$fromUrl(url);
		var _v0 = model;
		var cmsModel = _v0.a;
		var navModel = _v0.b;
		switch (router) {
			case 1:
				return _Utils_Tuple2(
					_Utils_Tuple2(cmsModel, navModel),
					$elm$core$Platform$Cmd$none);
			case 2:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditStoreProperties,
					$author$project$Pages$Cms$GotEditStorePropertiesMsg,
					navModel,
					$author$project$Pages$Cms$Stores$EditStoreProperties$init(
						$author$project$Pages$Cms$toSession(model)));
			case 3:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditPermalinkGroups,
					$author$project$Pages$Cms$GotEditPermalinkGroupsMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 4:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditStoreGroups,
					$author$project$Pages$Cms$GotEditStoreGroupsMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Stores$EditStoreGroups$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 20:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditMailAddress,
					$author$project$Pages$Cms$GotEditMailAddressMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Others$EditMailAddress$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 21:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$GetAccessCount,
					$author$project$Pages$Cms$GotGetAccessCountMsg,
					navModel,
					$author$project$Pages$Cms$Others$GetAccessCount$init(
						$author$project$Pages$Cms$toSession(model)));
			case 5:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditUsersStores,
					$author$project$Pages$Cms$GotEditUsersStoresMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Stores$EditUsersStores$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 6:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateWeek30ByCsv,
					$author$project$Pages$Cms$GotUpdateWeek30ByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateWeek30ByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 7:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateTletProductsByCsv,
					$author$project$Pages$Cms$GotUpdateTletProductsByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateTletProductsByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 8:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateTescomProductsByCsv,
					$author$project$Pages$Cms$GotUpdateTescomProductsByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 10:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateHitachiWeeklyProductsByCsv,
					$author$project$Pages$Cms$GotUpdateHitachiWeeklyProductsByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 11:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateHitachiProductsOrderByCsv,
					$author$project$Pages$Cms$GotUpdateHIitachiProductsOrderByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 9:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UpdateSharpProductsByCsv,
					$author$project$Pages$Cms$GotUpdateSharpProductsByCsv,
					navModel,
					$author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$init(
						$author$project$Pages$Cms$toSession(model)));
			case 12:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$UserSpecificRoutingModel,
					$author$project$Pages$Cms$GotUserSpecificRoutingMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Products$UserSpecificRouting$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 13:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$RakutenExcludedItems,
					$author$project$Pages$Cms$GotRakutenExcludedItems,
					navModel,
					$author$project$Pages$Cms$Products$RakutenExcludedItems$init(
						$author$project$Pages$Cms$toSession(model)));
			case 14:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$ChangeDisplayIntervalModel,
					$author$project$Pages$Cms$GotChangeDisplayIntervalMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Products$ChangeDisplayInterval$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 15:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$ChangeUserProductModel,
					$author$project$Pages$Cms$GotChangeUserProductMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Products$ChangeUserProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 17:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$AddProductConfigModel,
					$author$project$Pages$Cms$GotAddProductConfigMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Products$AddProductConfig$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 16:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$ChangeDisplayOrderModel,
					$author$project$Pages$Cms$GotChangeDisplayOrderMsg,
					navModel,
					A3(
						$author$project$Pages$Cms$Products$ChangeDisplayOrder$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$Cms$toSession(model)));
			case 18:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditProductsPermalinks,
					$author$project$Pages$Cms$GotEditProductsPermalinks,
					navModel,
					$author$project$Pages$Cms$Products$EditProductsPermalinks$init(
						$author$project$Pages$Cms$toSession(model)));
			case 19:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditProducts,
					$author$project$Pages$Cms$GotEditProducts,
					navModel,
					$author$project$Pages$Cms$Products$EditProducts$init(
						$author$project$Pages$Cms$toSession(model)));
			case 22:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$EditStores,
					$author$project$Pages$Cms$GotEditStoresMsg,
					navModel,
					$author$project$Pages$Cms$Stores$EditStores$init(
						$author$project$Pages$Cms$toSession(model)));
			case 23:
				return A4(
					$author$project$Pages$Cms$updateWithNavModel,
					$author$project$Pages$Cms$Yahoo,
					$author$project$Pages$Cms$GotYahooMsg,
					navModel,
					$author$project$Pages$Cms$Stores$Yahoo$init(
						$author$project$Pages$Cms$toSession(model)));
			default:
				return _Utils_Tuple2(
					_Utils_Tuple2(cmsModel, navModel),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$User$AddProductConfigModel = function (a) {
	return {$: 68, a: a};
};
var $author$project$Pages$User$BraunComnetHourModel = function (a) {
	return {$: 71, a: a};
};
var $author$project$Pages$User$BraunComnetWideModel = function (a) {
	return {$: 72, a: a};
};
var $author$project$Pages$User$Ca6ComnetHourCsvModel = function (a) {
	return {$: 29, a: a};
};
var $author$project$Pages$User$Ca6ComnetWeeklyProductFifteenModel = function (a) {
	return {$: 30, a: a};
};
var $author$project$Pages$User$ChangeProductModel = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$User$ChangeUserProductModel = function (a) {
	return {$: 67, a: a};
};
var $author$project$Pages$User$Dbi6ComnetHourModel = function (a) {
	return {$: 62, a: a};
};
var $author$project$Pages$User$Dbi6ComnetTimeSeqModel = function (a) {
	return {$: 63, a: a};
};
var $author$project$Pages$User$DefaultModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$User$DelonghiChangeProductModel = function (a) {
	return {$: 48, a: a};
};
var $author$project$Pages$User$DelonghiHourModel = function (a) {
	return {$: 47, a: a};
};
var $author$project$Pages$User$DelonghiTimeSeq2Model = function (a) {
	return {$: 50, a: a};
};
var $author$project$Pages$User$DelonghiTimeSeqModel = function (a) {
	return {$: 49, a: a};
};
var $author$project$Pages$User$Denon6ComnetTimeSeqModel = function (a) {
	return {$: 64, a: a};
};
var $author$project$Pages$User$Denon6ComnetWideModel = function (a) {
	return {$: 65, a: a};
};
var $author$project$Pages$User$EditProductsModel = function (a) {
	return {$: 69, a: a};
};
var $author$project$Pages$User$EditProductsPermalinks = function (a) {
	return {$: 66, a: a};
};
var $author$project$Pages$User$ElecComnetChangeProductModel = function (a) {
	return {$: 44, a: a};
};
var $author$project$Pages$User$ElecComnetExcelModel = function (a) {
	return {$: 43, a: a};
};
var $author$project$Pages$User$ElecComnetHourModel = function (a) {
	return {$: 42, a: a};
};
var $author$project$Pages$User$ElecComnetTimeSeqModel = function (a) {
	return {$: 45, a: a};
};
var $author$project$Pages$User$FujiComnetDayModel = function (a) {
	return {$: 75, a: a};
};
var $author$project$Pages$User$GotAddProductConfigMsg = function (a) {
	return {$: 69, a: a};
};
var $author$project$Pages$User$GotBraunComnetHourMsg = function (a) {
	return {$: 72, a: a};
};
var $author$project$Pages$User$GotBraunComnetWideMsg = function (a) {
	return {$: 73, a: a};
};
var $author$project$Pages$User$GotCa6ComnetHourCsvMsg = function (a) {
	return {$: 30, a: a};
};
var $author$project$Pages$User$GotCa6ComnetWeeklyProductFifteenMsg = function (a) {
	return {$: 31, a: a};
};
var $author$project$Pages$User$GotChangeProductMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$User$GotChangeUserProductMsg = function (a) {
	return {$: 68, a: a};
};
var $author$project$Pages$User$GotDbi6ComnetHourMsg = function (a) {
	return {$: 63, a: a};
};
var $author$project$Pages$User$GotDbi6ComnetTimeSeqMsg = function (a) {
	return {$: 64, a: a};
};
var $author$project$Pages$User$GotDefaultMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$User$GotDelonghiChangeProductMsg = function (a) {
	return {$: 49, a: a};
};
var $author$project$Pages$User$GotDelonghiHourMsg = function (a) {
	return {$: 48, a: a};
};
var $author$project$Pages$User$GotDelonghiTimeSeq2Msg = function (a) {
	return {$: 51, a: a};
};
var $author$project$Pages$User$GotDelonghiTimeSeqMsg = function (a) {
	return {$: 50, a: a};
};
var $author$project$Pages$User$GotDenon6ComnetTimeSeqMsg = function (a) {
	return {$: 66, a: a};
};
var $author$project$Pages$User$GotDenon6ComnetWideMsg = function (a) {
	return {$: 65, a: a};
};
var $author$project$Pages$User$GotEditProductsMsg = function (a) {
	return {$: 70, a: a};
};
var $author$project$Pages$User$GotEditProductsPermalinks = function (a) {
	return {$: 67, a: a};
};
var $author$project$Pages$User$GotElecComnetChangeProductMsg = function (a) {
	return {$: 45, a: a};
};
var $author$project$Pages$User$GotElecComnetExcelMsg = function (a) {
	return {$: 44, a: a};
};
var $author$project$Pages$User$GotElecComnetHourMsg = function (a) {
	return {$: 43, a: a};
};
var $author$project$Pages$User$GotElecComnetTimeSeqMsg = function (a) {
	return {$: 46, a: a};
};
var $author$project$Pages$User$GotFujiComnetDayMsg = function (a) {
	return {$: 76, a: a};
};
var $author$project$Pages$User$GotHi24ComnetHourMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$User$GotHi24ComnetTenAMMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$User$GotHi24ComnetTimeSeqMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$User$GotHi24ComnetWeeklyProductFifteenMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$User$GotHi3ComnetHourMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$User$GotHi3ComnetTimeSeqMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$User$GotHisenseAmazonMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$User$GotHisenseChangeProductMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$User$GotHisenseDayMsg = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$User$GotHisenseExcelMsg = function (a) {
	return {$: 24, a: a};
};
var $author$project$Pages$User$GotHisenseReviewMsg = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$User$GotHisenseTimeSeqMsg = function (a) {
	return {$: 23, a: a};
};
var $author$project$Pages$User$GotHisenseTvHourMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$User$GotJvcComnetTimeSeqMsg = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$User$GotKinujoComnetChangeProductMsg = function (a) {
	return {$: 53, a: a};
};
var $author$project$Pages$User$GotKinujoComnetExcelMsg = function (a) {
	return {$: 52, a: a};
};
var $author$project$Pages$User$GotKinujoComnetFifteeenMsg = function (a) {
	return {$: 54, a: a};
};
var $author$project$Pages$User$GotMlnComnetAutoReloadMsg = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$User$GotMlnComnetChangeMailMsg = function (a) {
	return {$: 33, a: a};
};
var $author$project$Pages$User$GotMlnComnetChangePasswordMsg = function (a) {
	return {$: 39, a: a};
};
var $author$project$Pages$User$GotMlnComnetChangeProductMsg = function (a) {
	return {$: 32, a: a};
};
var $author$project$Pages$User$GotMlnComnetChronologicalMsg = function (a) {
	return {$: 34, a: a};
};
var $author$project$Pages$User$GotMlnComnetHourMsg = function (a) {
	return {$: 35, a: a};
};
var $author$project$Pages$User$GotMlnComnetHourYamadaMsg = function (a) {
	return {$: 36, a: a};
};
var $author$project$Pages$User$GotMlnComnetMailSettingMsg = function (a) {
	return {$: 38, a: a};
};
var $author$project$Pages$User$GotMlnComnetYahooMsg = function (a) {
	return {$: 40, a: a};
};
var $author$project$Pages$User$GotShaComnetChangeProductMsg = function (a) {
	return {$: 60, a: a};
};
var $author$project$Pages$User$GotShaComnetDayMsg = function (a) {
	return {$: 59, a: a};
};
var $author$project$Pages$User$GotShaComnetHourMsg = function (a) {
	return {$: 56, a: a};
};
var $author$project$Pages$User$GotShaComnetTimeSeqMsg = function (a) {
	return {$: 57, a: a};
};
var $author$project$Pages$User$GotShaComnetWeeklyProductMsg = function (a) {
	return {$: 58, a: a};
};
var $author$project$Pages$User$GotShaComnetWideMsg = function (a) {
	return {$: 55, a: a};
};
var $author$project$Pages$User$GotTescomComnetChangeProductMsg = function (a) {
	return {$: 28, a: a};
};
var $author$project$Pages$User$GotTescomComnetExcelMsg = function (a) {
	return {$: 27, a: a};
};
var $author$project$Pages$User$GotTescomComnetHourMsg = function (a) {
	return {$: 26, a: a};
};
var $author$project$Pages$User$GotTestFifteeenMsg = function (a) {
	return {$: 77, a: a};
};
var $author$project$Pages$User$GotTo24tclComnetChangeProductMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$User$GotTo24tclComnetDayMsg = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$User$GotTo24tclComnetTimeSeqMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$User$GotTo24tcmComnetChangeProductMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$User$GotTo24tcmComnetCsvMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$User$GotTo24tcmComnetDayMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$User$GotTo24tcmComnetProductMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$User$GotTo24tcmKitchenDayMsg = function (a) {
	return {$: 47, a: a};
};
var $author$project$Pages$User$GotTsBatTimeSeqMsg = function (a) {
	return {$: 75, a: a};
};
var $author$project$Pages$User$GotTsBatWideMsg = function (a) {
	return {$: 74, a: a};
};
var $author$project$Pages$User$GotTstComnetTimeSeqMsg = function (a) {
	return {$: 61, a: a};
};
var $author$project$Pages$User$GotTstComnetWideMsg = function (a) {
	return {$: 62, a: a};
};
var $author$project$Pages$User$GotTv24ComnetChangeProductMsg = function (a) {
	return {$: 41, a: a};
};
var $author$project$Pages$User$GotTv24ComnetThirtyMsg = function (a) {
	return {$: 42, a: a};
};
var $author$project$Pages$User$GotYahooMsg = function (a) {
	return {$: 71, a: a};
};
var $author$project$Pages$User$Hi24ComnetHourModel = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$User$Hi24ComnetTenAMModel = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$User$Hi24ComnetTimeSeqModel = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$User$Hi24ComnetWeeklyProductFifteenModel = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$User$Hi3ComnetHourModel = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$User$Hi3ComnetTimeSeqModel = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$User$HisenseAmazonModel = function (a) {
	return {$: 22, a: a};
};
var $author$project$Pages$User$HisenseChangeProductModel = function (a) {
	return {$: 24, a: a};
};
var $author$project$Pages$User$HisenseDayModel = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$User$HisenseExcelModel = function (a) {
	return {$: 25, a: a};
};
var $author$project$Pages$User$HisenseReviewModel = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$User$HisenseTimeSeqModel = function (a) {
	return {$: 23, a: a};
};
var $author$project$Pages$User$HisenseTvHourModel = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$User$JvcComnetTimeSeqModel = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$User$KinujoComnetChangeProductModel = function (a) {
	return {$: 52, a: a};
};
var $author$project$Pages$User$KinujoComnetExcelModel = function (a) {
	return {$: 51, a: a};
};
var $author$project$Pages$User$KinujoComnetFifteenModel = function (a) {
	return {$: 53, a: a};
};
var $author$project$Pages$User$MlnComnetAutoReloadModel = function (a) {
	return {$: 36, a: a};
};
var $author$project$Pages$User$MlnComnetChangeMailModel = function (a) {
	return {$: 35, a: a};
};
var $author$project$Pages$User$MlnComnetChangePasswordModel = function (a) {
	return {$: 38, a: a};
};
var $author$project$Pages$User$MlnComnetChangeProductModel = function (a) {
	return {$: 31, a: a};
};
var $author$project$Pages$User$MlnComnetChronologicalModel = function (a) {
	return {$: 32, a: a};
};
var $author$project$Pages$User$MlnComnetHourModel = function (a) {
	return {$: 33, a: a};
};
var $author$project$Pages$User$MlnComnetHourYamadaModel = function (a) {
	return {$: 34, a: a};
};
var $author$project$Pages$User$MlnComnetMailSettingModel = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$User$MlnComnetYahooModel = function (a) {
	return {$: 39, a: a};
};
var $author$project$Pages$User$ShaComnetChangeProductModel = function (a) {
	return {$: 59, a: a};
};
var $author$project$Pages$User$ShaComnetDayModel = function (a) {
	return {$: 58, a: a};
};
var $author$project$Pages$User$ShaComnetHourModel = function (a) {
	return {$: 55, a: a};
};
var $author$project$Pages$User$ShaComnetTimeSeqModel = function (a) {
	return {$: 56, a: a};
};
var $author$project$Pages$User$ShaComnetWeeklyProductModel = function (a) {
	return {$: 57, a: a};
};
var $author$project$Pages$User$ShaComnetWideModel = function (a) {
	return {$: 54, a: a};
};
var $author$project$Pages$User$TescomComnetChangeProductModel = function (a) {
	return {$: 28, a: a};
};
var $author$project$Pages$User$TescomComnetExcelModel = function (a) {
	return {$: 27, a: a};
};
var $author$project$Pages$User$TescomComnetHourModel = function (a) {
	return {$: 26, a: a};
};
var $author$project$Pages$User$TestFifteenModel = function (a) {
	return {$: 76, a: a};
};
var $author$project$Pages$User$To24tclComnetChangeProductModel = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$User$To24tclComnetDayModel = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$User$To24tclComnetTimeSeqModel = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$User$To24tcmComnetChangeProductModel = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$User$To24tcmComnetCsvModel = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$User$To24tcmComnetDayModel = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$User$To24tcmComnetProductModel = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$User$To24tcmComnetTimeSeqModel = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$User$To24tcmKitchenDayModel = function (a) {
	return {$: 46, a: a};
};
var $author$project$Pages$User$TsBatTimeSeqModel = function (a) {
	return {$: 73, a: a};
};
var $author$project$Pages$User$TsBatWideModel = function (a) {
	return {$: 74, a: a};
};
var $author$project$Pages$User$TstComnetTimeSeqModel = function (a) {
	return {$: 60, a: a};
};
var $author$project$Pages$User$TstComnetWideModel = function (a) {
	return {$: 61, a: a};
};
var $author$project$Pages$User$Tv24ComnetChangeProductModel = function (a) {
	return {$: 40, a: a};
};
var $author$project$Pages$User$Tv24ComnetThirtyModel = function (a) {
	return {$: 41, a: a};
};
var $author$project$Pages$User$Yahoo = function (a) {
	return {$: 70, a: a};
};
var $author$project$Routers$UserRouter$NotFound = 0;
var $author$project$Routers$UserRouter$AddProductConfig = 101;
var $author$project$Routers$UserRouter$BraunComnetHour = 97;
var $author$project$Routers$UserRouter$BraunComnetWide = 98;
var $author$project$Routers$UserRouter$Ca6ComnetFifteen = 43;
var $author$project$Routers$UserRouter$Ca6ComnetHourCsv = 44;
var $author$project$Routers$UserRouter$Ca6ComnetHourCsvWeek = 45;
var $author$project$Routers$UserRouter$Ca6ComnetWeeklyProductFifteen = 46;
var $author$project$Routers$UserRouter$ChangeUserProduct = 100;
var $author$project$Routers$UserRouter$Dbi6ComnetHour = 93;
var $author$project$Routers$UserRouter$Dbi6ComnetTimeSeq = 94;
var $author$project$Routers$UserRouter$DelonghiChangeProduct = 74;
var $author$project$Routers$UserRouter$DelonghiHour = 73;
var $author$project$Routers$UserRouter$DelonghiTimeSeq2Hour = 76;
var $author$project$Routers$UserRouter$DelonghiTimeSeqHour = 75;
var $author$project$Routers$UserRouter$Denon6ComnetTimeSeq = 96;
var $author$project$Routers$UserRouter$Denon6ComnetWide = 95;
var $author$project$Routers$UserRouter$EditProducts = 102;
var $author$project$Routers$UserRouter$EditProductsPermalinks = 99;
var $author$project$Routers$UserRouter$ElecComnet15Min = 59;
var $author$project$Routers$UserRouter$ElecComnetChangeProduct = 62;
var $author$project$Routers$UserRouter$ElecComnetExcel = 61;
var $author$project$Routers$UserRouter$ElecComnetHour = 60;
var $author$project$Routers$UserRouter$ElecComnetTimeSeqFifteen = 63;
var $author$project$Routers$UserRouter$ElecComnetTimeSeqHour = 64;
var $author$project$Routers$UserRouter$FujiComnetDay = 106;
var $author$project$Routers$UserRouter$Hi24ComnetHour = 80;
var $author$project$Routers$UserRouter$Hi24ComnetSixHour = 81;
var $author$project$Routers$UserRouter$Hi24ComnetTenAM = 84;
var $author$project$Routers$UserRouter$Hi24ComnetTimeSeq = 82;
var $author$project$Routers$UserRouter$Hi24ComnetWeeklyProductFifteen = 83;
var $author$project$Routers$UserRouter$Hi3ComnetChangeProduct = 72;
var $author$project$Routers$UserRouter$Hi3ComnetHour = 70;
var $author$project$Routers$UserRouter$Hi3ComnetHourSeq = 71;
var $author$project$Routers$UserRouter$HisenseAmazon = 35;
var $author$project$Routers$UserRouter$HisenseChangeProduct = 36;
var $author$project$Routers$UserRouter$HisenseDay = 32;
var $author$project$Routers$UserRouter$HisenseExcel = 38;
var $author$project$Routers$UserRouter$HisenseReview = 37;
var $author$project$Routers$UserRouter$HisenseTimeSeq = 33;
var $author$project$Routers$UserRouter$HisenseTvHour = 34;
var $author$project$Routers$UserRouter$JvcComnetFifteen = 28;
var $author$project$Routers$UserRouter$JvcComnetHour = 29;
var $author$project$Routers$UserRouter$JvcComnetTimeSeqFifteen = 30;
var $author$project$Routers$UserRouter$JvcComnetTimeSeqHour = 31;
var $author$project$Routers$UserRouter$KinujoComnetChangeProduct = 79;
var $author$project$Routers$UserRouter$KinujoComnetExcel = 78;
var $author$project$Routers$UserRouter$KinujoComnetFifteen = 77;
var $author$project$Routers$UserRouter$MlnComnetAutoReload = 53;
var $author$project$Routers$UserRouter$MlnComnetChangeMail = 48;
var $author$project$Routers$UserRouter$MlnComnetChangePassword = 49;
var $author$project$Routers$UserRouter$MlnComnetChangeProduct = 47;
var $author$project$Routers$UserRouter$MlnComnetChronological30min = 50;
var $author$project$Routers$UserRouter$MlnComnetHour = 51;
var $author$project$Routers$UserRouter$MlnComnetHourYamada = 52;
var $author$project$Routers$UserRouter$MlnComnetMailSetting = 54;
var $author$project$Routers$UserRouter$MlnComnetYahoo = 55;
var $author$project$Routers$UserRouter$ShaComnetChangeProduct = 90;
var $author$project$Routers$UserRouter$ShaComnetDay = 89;
var $author$project$Routers$UserRouter$ShaComnetHour = 86;
var $author$project$Routers$UserRouter$ShaComnetHourSeq = 87;
var $author$project$Routers$UserRouter$ShaComnetWeeklyProduct = 88;
var $author$project$Routers$UserRouter$ShaComnetWide = 85;
var $author$project$Routers$UserRouter$TescomComnet15Min = 39;
var $author$project$Routers$UserRouter$TescomComnetChangeProduct = 42;
var $author$project$Routers$UserRouter$TescomComnetExcel = 41;
var $author$project$Routers$UserRouter$TescomComnetHour = 40;
var $author$project$Routers$UserRouter$TestFifteen = 107;
var $author$project$Routers$UserRouter$To24tclComnetChangeProduct = 13;
var $author$project$Routers$UserRouter$To24tclComnetCsv = 12;
var $author$project$Routers$UserRouter$To24tclComnetDay = 11;
var $author$project$Routers$UserRouter$To24tclComnetFifteen = 9;
var $author$project$Routers$UserRouter$To24tclComnetFifteenSeq = 10;
var $author$project$Routers$UserRouter$To24tcmAirChangeProduct = 69;
var $author$project$Routers$UserRouter$To24tcmAirCsv = 68;
var $author$project$Routers$UserRouter$To24tcmAirFifteen = 65;
var $author$project$Routers$UserRouter$To24tcmAirHour = 66;
var $author$project$Routers$UserRouter$To24tcmAirHourSeq = 67;
var $author$project$Routers$UserRouter$To24tcmComnetChangeProduct = 8;
var $author$project$Routers$UserRouter$To24tcmComnetCsv = 7;
var $author$project$Routers$UserRouter$To24tcmComnetDay = 4;
var $author$project$Routers$UserRouter$To24tcmComnetFifteen = 2;
var $author$project$Routers$UserRouter$To24tcmComnetFifteenSeq = 5;
var $author$project$Routers$UserRouter$To24tcmComnetHour = 3;
var $author$project$Routers$UserRouter$To24tcmComnetHourSeq = 6;
var $author$project$Routers$UserRouter$To24tcmFridgeChangeProduct = 20;
var $author$project$Routers$UserRouter$To24tcmFridgeCsv = 18;
var $author$project$Routers$UserRouter$To24tcmFridgeDay = 19;
var $author$project$Routers$UserRouter$To24tcmFridgeFifteen = 14;
var $author$project$Routers$UserRouter$To24tcmFridgeFifteenSeq = 16;
var $author$project$Routers$UserRouter$To24tcmFridgeHour = 15;
var $author$project$Routers$UserRouter$To24tcmFridgeHourSeq = 17;
var $author$project$Routers$UserRouter$To24tcmKitchenChangeProduct = 27;
var $author$project$Routers$UserRouter$To24tcmKitchenCsv = 26;
var $author$project$Routers$UserRouter$To24tcmKitchenDay = 23;
var $author$project$Routers$UserRouter$To24tcmKitchenFifteen = 21;
var $author$project$Routers$UserRouter$To24tcmKitchenFifteenSeq = 24;
var $author$project$Routers$UserRouter$To24tcmKitchenHour = 22;
var $author$project$Routers$UserRouter$To24tcmKitchenHourSeq = 25;
var $author$project$Routers$UserRouter$Top = 1;
var $author$project$Routers$UserRouter$TsBatTimeSeq = 105;
var $author$project$Routers$UserRouter$TsBatWide = 104;
var $author$project$Routers$UserRouter$TstComnetTimeSeq = 91;
var $author$project$Routers$UserRouter$TstComnetWide = 92;
var $author$project$Routers$UserRouter$Tv24ComnetChangeProduct = 58;
var $author$project$Routers$UserRouter$Tv24ComnetCsv = 57;
var $author$project$Routers$UserRouter$Tv24ComnetThirty = 56;
var $author$project$Routers$UserRouter$Yahoo = 103;
var $author$project$Routers$UserRouter$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, 1, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			2,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			3,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			4,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			5,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('fifteen-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			6,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			7,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			8,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			9,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcl-comnet'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			10,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcl-comnet'),
					$elm$url$Url$Parser$s('fifteen-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			11,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcl-comnet'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			12,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcl-comnet'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			13,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcl-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			14,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			15,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			16,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('fifteen-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			17,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			18,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			19,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			20,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-fridge'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			21,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			24,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('fifteen-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			25,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			22,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			23,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			26,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			27,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-kitchen'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			28,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('jvc-comnet'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			29,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('jvc-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			30,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('jvc-comnet'),
					$elm$url$Url$Parser$s('time-seq-fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			31,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('jvc-comnet'),
					$elm$url$Url$Parser$s('time-seq-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			32,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			33,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			34,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('tv-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			35,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('amazon-ranking')))),
			A2(
			$elm$url$Url$Parser$map,
			36,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			37,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('review')))),
			A2(
			$elm$url$Url$Parser$map,
			38,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hisense'),
					$elm$url$Url$Parser$s('excel')))),
			A2(
			$elm$url$Url$Parser$map,
			39,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tescom-comnet'),
					$elm$url$Url$Parser$s('15min')))),
			A2(
			$elm$url$Url$Parser$map,
			40,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tescom-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			41,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tescom-comnet'),
					$elm$url$Url$Parser$s('excel')))),
			A2(
			$elm$url$Url$Parser$map,
			42,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tescom-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			51,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			52,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('hour-yamada')))),
			A2(
			$elm$url$Url$Parser$map,
			43,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ca6-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			44,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ca6-comnet'),
					$elm$url$Url$Parser$s('hour-csv')))),
			A2(
			$elm$url$Url$Parser$map,
			45,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ca6-comnet'),
					$elm$url$Url$Parser$s('hour-csv-week')))),
			A2(
			$elm$url$Url$Parser$map,
			46,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ca6-comnet'),
					$elm$url$Url$Parser$s('weekly-product-15min')))),
			A2(
			$elm$url$Url$Parser$map,
			47,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			48,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('change-mail')))),
			A2(
			$elm$url$Url$Parser$map,
			49,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('change-password')))),
			A2(
			$elm$url$Url$Parser$map,
			53,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('auto-reload')))),
			A2(
			$elm$url$Url$Parser$map,
			54,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('mail-setting')))),
			A2(
			$elm$url$Url$Parser$map,
			50,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('chronological-30min')))),
			A2(
			$elm$url$Url$Parser$map,
			55,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mln-comnet'),
					$elm$url$Url$Parser$s('yahoo')))),
			A2(
			$elm$url$Url$Parser$map,
			56,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tv24-comnet'),
					$elm$url$Url$Parser$s('thirty')))),
			A2(
			$elm$url$Url$Parser$map,
			57,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tv24-comnet'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			58,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tv24-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			59,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('15min')))),
			A2(
			$elm$url$Url$Parser$map,
			60,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			61,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('excel')))),
			A2(
			$elm$url$Url$Parser$map,
			62,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			63,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('time-seq-fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			64,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('elec-comnet'),
					$elm$url$Url$Parser$s('time-seq-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			65,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-air'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			66,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-air'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			67,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-air'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			68,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-air'),
					$elm$url$Url$Parser$s('csv')))),
			A2(
			$elm$url$Url$Parser$map,
			69,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('to24tcm-air'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			70,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi3-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			71,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi3-comnet'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			72,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi3-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			73,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('delonghi'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			74,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('delonghi'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			75,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('delonghi'),
					$elm$url$Url$Parser$s('time-seq-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			76,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('delonghi'),
					$elm$url$Url$Parser$s('time-seq-2-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			77,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('kinujo-comnet'),
					$elm$url$Url$Parser$s('fifteen')))),
			A2(
			$elm$url$Url$Parser$map,
			78,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('kinujo-comnet'),
					$elm$url$Url$Parser$s('excel')))),
			A2(
			$elm$url$Url$Parser$map,
			79,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('kinujo-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			80,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi24-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			81,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi24-comnet'),
					$elm$url$Url$Parser$s('six-hour')))),
			A2(
			$elm$url$Url$Parser$map,
			82,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi24-comnet'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			83,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi24-comnet'),
					$elm$url$Url$Parser$s('weekly-product-15min')))),
			A2(
			$elm$url$Url$Parser$map,
			84,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('hi24-comnet'),
					$elm$url$Url$Parser$s('ten-am')))),
			A2(
			$elm$url$Url$Parser$map,
			85,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('wide')))),
			A2(
			$elm$url$Url$Parser$map,
			86,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			87,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('hour-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			90,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('change-product')))),
			A2(
			$elm$url$Url$Parser$map,
			89,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			88,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('sha-comnet'),
					$elm$url$Url$Parser$s('weekly-product')))),
			A2(
			$elm$url$Url$Parser$map,
			92,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tst-comnet'),
					$elm$url$Url$Parser$s('wide')))),
			A2(
			$elm$url$Url$Parser$map,
			91,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('tst-comnet'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			93,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('dbi6-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			94,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('dbi6-comnet'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			95,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('denon6-comnet'),
					$elm$url$Url$Parser$s('wide')))),
			A2(
			$elm$url$Url$Parser$map,
			96,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('denon6-comnet'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			97,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('braun-comnet'),
					$elm$url$Url$Parser$s('hour')))),
			A2(
			$elm$url$Url$Parser$map,
			98,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('braun-comnet'),
					$elm$url$Url$Parser$s('wide')))),
			A2(
			$elm$url$Url$Parser$map,
			99,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('edit-products-permalinks')))),
			A2(
			$elm$url$Url$Parser$map,
			100,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('change-user-product')))),
			A2(
			$elm$url$Url$Parser$map,
			101,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('add-product-config')))),
			A2(
			$elm$url$Url$Parser$map,
			102,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('edit-products')))),
			A2(
			$elm$url$Url$Parser$map,
			103,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('products'),
					$elm$url$Url$Parser$s('yahoo')))),
			A2(
			$elm$url$Url$Parser$map,
			104,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ts-bat'),
					$elm$url$Url$Parser$s('wide')))),
			A2(
			$elm$url$Url$Parser$map,
			105,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('ts-bat'),
					$elm$url$Url$Parser$s('time-seq')))),
			A2(
			$elm$url$Url$Parser$map,
			106,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('fuji-comnet'),
					$elm$url$Url$Parser$s('day')))),
			A2(
			$elm$url$Url$Parser$map,
			107,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('test'),
					$elm$url$Url$Parser$s('fifteen'))))
		]));
var $author$project$Routers$UserRouter$fromUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$url$Url$Parser$parse, $author$project$Routers$UserRouter$parser, url));
};
var $author$project$Pages$Users$BraunComnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $Herteby$url_builder_plus$UrlBuilderPlus$None = {$: 1};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $Herteby$url_builder_plus$UrlBuilderPlus$toQueryPair = function (param) {
	if (!param.$) {
		var key = param.a;
		var value = param.b;
		return key + ('=' + value);
	} else {
		return '';
	}
};
var $Herteby$url_builder_plus$UrlBuilderPlus$toQuery = function (parameters) {
	var _v0 = A2(
		$elm$core$List$filter,
		function (p) {
			return !_Utils_eq(p, $Herteby$url_builder_plus$UrlBuilderPlus$None);
		},
		parameters);
	if (!_v0.b) {
		return '';
	} else {
		var params = _v0;
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $Herteby$url_builder_plus$UrlBuilderPlus$toQueryPair, params));
	}
};
var $Herteby$url_builder_plus$UrlBuilderPlus$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $Herteby$url_builder_plus$UrlBuilderPlus$toQuery(parameters));
	});
var $author$project$Endpoints$User$common = 'common';
var $author$project$Endpoints$User$default = 'default';
var $author$project$Endpoints$User$user = 'user';
var $author$project$Endpoints$User$defaultEndpoints = {
	bN: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'change-product', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'change-product', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'change-product', 'update-all']),
			_List_Nil)
	},
	cX: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'csv'])),
	gx: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'news'])),
	C: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'prices'])),
	w: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'init']))
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.hq, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		gp: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		lp: month,
		mL: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gp;
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).lp;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).mL;
	});
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			gp: A2($elm$time$Time$toDay, zone, posix),
			k_: A2($elm$time$Time$toHour, zone, posix),
			ll: A2($elm$time$Time$toMillis, zone, posix),
			ln: A2($elm$time$Time$toMinute, zone, posix),
			lp: A2($elm$time$Time$toMonth, zone, posix),
			l7: A2($elm$time$Time$toSecond, zone, posix),
			mL: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $author$project$Utils$Helpers$getDate = A3($elm$core$Task$map2, $justinmimbs$time_extra$Time$Extra$posixToParts, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$Pages$Users$BraunComnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Utils$Types$BigCategory = F2(
	function (bigCategoryId, bigCategoryName) {
		return {ed: bigCategoryId, kb: bigCategoryName};
	});
var $author$project$Utils$Decoders$bigCategoriesDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Utils$Types$BigCategory,
		A2($elm$json$Json$Decode$field, 'big_category_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'big_category_name', $elm$json$Json$Decode$string)));
var $author$project$Utils$Types$SmallCategory = F3(
	function (smallCategoryId, smallCategoryName, bigCategoryId) {
		return {ed: bigCategoryId, ho: smallCategoryId, mc: smallCategoryName};
	});
var $author$project$Utils$Decoders$smallCategoriesDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Utils$Types$SmallCategory,
		A2($elm$json$Json$Decode$field, 'small_category_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'small_category_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'big_category_id', $elm$json$Json$Decode$int)));
var $author$project$Pages$Users$BraunComnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$BraunComnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Pages$Users$BraunComnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$BraunComnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$BraunComnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$BraunComnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$BraunComnet$Wide$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Utils$Types$UsersProduct = function (productId) {
	return function (productName) {
		return function (basePrice) {
			return function (basePriceIncludeTax) {
				return function (basePoint) {
					return function (basePriceWithoutPoint) {
						return function (makerId) {
							return function (smallCategoryId) {
								return function (bigCategoryId) {
									return function (yodobashiFlag) {
										return function (triggerFlag) {
											return function (releaseDate) {
												return function (mailEnabled) {
													return function (productOrder) {
														return function (getInterval) {
															return {j9: basePoint, ka: basePrice, eb: basePriceIncludeTax, bM: basePriceWithoutPoint, ed: bigCategoryId, kT: getInterval, bg: mailEnabled, iJ: makerId, i8: productId, lT: productName, lU: productOrder, hb: releaseDate, ho: smallCategoryId, mz: triggerFlag, mM: yodobashiFlag};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {h1: col, kp: contextStack, i5: problem, ji: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.ji, s.h1, x, s.f));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{h1: col, f: s0.f, g: s0.g, b: offset, ji: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.ji, s.h1, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{h1: 1, f: s.f, g: s.g, b: s.b + 1, ji: s.ji + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{h1: s.h1 + 1, f: s.f, g: s.g, b: newOffset, ji: s.ji, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.ji, s.h1, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{h1: newCol, f: s.f, g: s.g, b: newOffset, ji: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {h1: col, i5: problem, ji: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.ji, p.h1, p.i5);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{h1: 1, f: _List_Nil, g: 1, b: 0, ji: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Utils$Decoders$productDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$map,
		$elm_community$maybe_extra$Maybe$Extra$join,
		$elm$json$Json$Decode$maybe(
			A2(
				$elm$json$Json$Decode$field,
				'get_interval',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)))),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$map,
			$elm_community$maybe_extra$Maybe$Extra$join,
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'product_order',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)))),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(false),
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'mail_enabled', $elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$map,
					$elm_community$maybe_extra$Maybe$Extra$join,
					$elm$json$Json$Decode$maybe(
						A2(
							$elm$json$Json$Decode$field,
							'release_date',
							$elm$json$Json$Decode$maybe($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder)))),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'trigger_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'big_category_id', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'small_category_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'maker_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'base_price_without_point', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A2($elm$json$Json$Decode$field, 'base_price', $elm$json$Json$Decode$int),
													A2(
														$elm_community$json_extra$Json$Decode$Extra$andMap,
														A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
														A2(
															$elm_community$json_extra$Json$Decode$Extra$andMap,
															A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
															$elm$json$Json$Decode$succeed($author$project$Utils$Types$UsersProduct))))))))))))))));
var $author$project$Utils$Decoders$productsDecoder = $elm$json$Json$Decode$list($author$project$Utils$Decoders$productDecoder);
var $author$project$Pages$Users$BraunComnet$Wide$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$BraunComnet$Wide$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Endpoints$User$timeSeq = 'time-seq';
var $author$project$Endpoints$User$timeSeqEndpoints = {
	cX: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$timeSeq, 'csv'])),
	C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$timeSeq, 'prices'])),
	w: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$timeSeq, 'init']))
};
var $author$project$Pages$Users$BraunComnet$Wide$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$BraunComnet$Wide$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$BraunComnet$Wide$initResponseDecoder,
						$author$project$Pages$Users$BraunComnet$Wide$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Ca6Comnet$HourCsv$GotDate = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $Herteby$url_builder_plus$UrlBuilderPlus$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Herteby$url_builder_plus$UrlBuilderPlus$bool = F2(
	function (key, value) {
		return A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$QueryParameter,
			$elm$url$Url$percentEncode(key),
			value ? 'true' : 'false');
	});
var $author$project$Endpoints$User$ca6Comnet = 'ca6-comnet';
var $author$project$Endpoints$User$ca6ComnetEndpoints = {
	k$: {
		kD: function (path) {
			return $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
				_List_fromArray(
					[$author$project$Endpoints$User$ca6Comnet, path]));
		},
		kQ: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$ca6Comnet, 'hour-csv', 'gen-csv'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$ca6Comnet, 'hour-csv', 'init']))
	},
	jZ: {
		C: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$ca6Comnet, 'weekly-product-fifteen', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$ca6Comnet, 'weekly-product-fifteen', 'init']))
	}
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Ca6Comnet$HourCsv$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Ca6Comnet$HourCsv$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Ca6Comnet$HourCsv$init = F4(
	function (interval, maybeTitle, session, useWeek) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				cM: $elm$core$Set$empty,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				Z: interval,
				b2: false,
				c5: false,
				ca: _List_Nil,
				de: '',
				dh: '',
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				bu: session,
				jy: _List_Nil,
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Ca6Comnet$HourCsv$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$ca6ComnetEndpoints.k$.w(
							_List_fromArray(
								[
									A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'useWeek', useWeek)
								])),
						session,
						$author$project$Pages$Users$Ca6Comnet$HourCsv$initResponseDecoder,
						$author$project$Pages$Users$Ca6Comnet$HourCsv$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$ca6ComnetEndpoints.jZ.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$initResponseDecoder,
						$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Common$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$Common$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $elm$json$Json$Decode$map8 = _Json_map8;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Pages$Users$Common$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$Common$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$Common$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Common$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$Common$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$Common$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$Common$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$Common$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Common$Default$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$Default$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Common$Default$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Common$Default$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Common$Default$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Common$Default$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Common$Default$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Common$Default$initResponseDecoder,
						$author$project$Pages$Users$Common$Default$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Dbi6Comnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Dbi6Comnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Dbi6Comnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Dbi6Comnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$Dbi6Comnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Dbi6Comnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Dbi6Comnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$Delonghi$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$Delonghi$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Delonghi$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$Delonghi$ChangeProduct$productsDecoder));
var $author$project$Endpoints$User$tv24Comnet = 'to24tcm-comnet';
var $author$project$Endpoints$User$tv24ComnetEndpoints = {
	bN: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tv24Comnet, 'change-product', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tv24Comnet, 'change-product', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tv24Comnet, 'change-product', 'update-all']),
			_List_Nil)
	}
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tv24ComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$Delonghi$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$Delonghi$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Delonghi$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Delonghi$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Delonghi$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Delonghi$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty,
				X: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Delonghi$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Delonghi$Hour$initResponseDecoder,
						$author$project$Pages$Users$Delonghi$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Endpoints$User$delonghi = 'Delonghi';
var $author$project$Endpoints$User$delonghiEndpoints = {
	hw: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$delonghi, 'time-seq', 'prices'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$delonghi, 'time-seq', 'init']))
	},
	mv: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$delonghi, 'time-seq2', 'prices'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$delonghi, 'time-seq2', 'init']))
	}
};
var $author$project$Pages$Users$Delonghi$TimeSeq$InitResponse = F3(
	function (bigCategories, smallCategories, products) {
		return {hR: bigCategories, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Delonghi$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $Herteby$url_builder_plus$UrlBuilderPlus$string = F2(
	function (key, value) {
		return A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				c6: false,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				hf: $elm$core$Maybe$Nothing,
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				bu: session,
				jy: _List_Nil,
				ap: _List_Nil,
				dB: '',
				dC: '',
				dD: $elm$core$Maybe$Nothing,
				dE: '',
				dF: '',
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Delonghi$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$delonghiEndpoints.hw.w(
							_List_fromArray(
								[
									A2(
									$Herteby$url_builder_plus$UrlBuilderPlus$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Delonghi$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Delonghi$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$InitResponse = F5(
	function (bigCategories, smallCategories, makers, products, userStores) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories, dT: userStores};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Utils$Types$UserStore = F9(
	function (storeId, storeName, storeGroupId, displayName, storeOrder, storeGroupName, getInterval, getEnabled, isUpdated) {
		return {h6: displayName, kS: getEnabled, kT: getInterval, at: isUpdated, mh: storeGroupId, mi: storeGroupName, hs: storeId, jD: storeName, mk: storeOrder};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$userStoresDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_enabled',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(true),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'store_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'display_name',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Utils$Types$UserStore)))))))))));
var $author$project$Pages$Users$Delonghi$TimeSeq2$initResponseDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Pages$Users$Delonghi$TimeSeq2$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder),
	A2($elm$json$Json$Decode$field, 'users_stores', $author$project$Pages$Users$Delonghi$TimeSeq2$userStoresDecoder));
var $author$project$Pages$Users$Delonghi$TimeSeq2$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				r: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'stores-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				hl: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Delonghi$TimeSeq2$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$delonghiEndpoints.mv.w(_List_Nil),
						session,
						$author$project$Pages$Users$Delonghi$TimeSeq2$initResponseDecoder,
						$author$project$Pages$Users$Delonghi$TimeSeq2$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Denon6Comnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Denon6Comnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Denon6Comnet$Wide$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Denon6Comnet$Wide$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Denon6Comnet$Wide$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Denon6Comnet$Wide$initResponseDecoder,
						$author$project$Pages$Users$Denon6Comnet$Wide$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$ElecComnet$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$ElecComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$ElecComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$ElecComnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$ElecComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tv24ComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$ElecComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$ElecComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ElecComnet$Excel$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Endpoints$User$elecComnet = 'elec-comnet';
var $author$project$Endpoints$User$elecComnetEndpoints = {
	ex: {
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'time-seq', 'init'])),
		dA: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$elecComnet, 'excel', 'submit']))
	},
	hw: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$elecComnet, 'time-seq', 'prices'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$elecComnet, 'time-seq', 'init']))
	}
};
var $author$project$Pages$Users$ElecComnet$Excel$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$ElecComnet$Excel$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$ElecComnet$Excel$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ElecComnet$Excel$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ElecComnet$Excel$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$elecComnetEndpoints.ex.w(_List_Nil),
						session,
						$author$project$Pages$Users$ElecComnet$Excel$initResponseDecoder,
						$author$project$Pages$Users$ElecComnet$Excel$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ElecComnet$Hour$init = $author$project$Pages$Users$Common$Default$init;
var $author$project$Pages$Users$ElecComnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$InitResponse = F3(
	function (bigCategories, smallCategories, products) {
		return {hR: bigCategories, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ElecComnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ElecComnet$TimeSeq$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				c6: false,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				hf: $elm$core$Maybe$Nothing,
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				bu: session,
				jy: _List_Nil,
				ap: _List_Nil,
				dB: '',
				dC: '',
				dD: $elm$core$Maybe$Nothing,
				dE: '',
				dF: '',
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ElecComnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$elecComnetEndpoints.hw.w(
							_List_fromArray(
								[
									A2(
									$Herteby$url_builder_plus$UrlBuilderPlus$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$ElecComnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$ElecComnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$FujiComnet$Day$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$FujiComnet$Day$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$FujiComnet$Day$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$FujiComnet$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$FujiComnet$Day$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$FujiComnet$Day$initResponseDecoder,
						$author$project$Pages$Users$FujiComnet$Day$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Hi24Comnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi24Comnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Hi24Comnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$Hi24Comnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Endpoints$User$hi24Comnet = 'hi24-comnet';
var $author$project$Endpoints$User$hi24ComnetEndpoints = {
	mr: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hi24Comnet, 'tenAM', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hi24Comnet, 'tenAM', 'init']))
	},
	jZ: {
		C: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hi24Comnet, 'weekly-product-fifteen', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hi24Comnet, 'weekly-product-fifteen', 'init']))
	}
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$InitResponse = F5(
	function (bigCategories, smallCategories, makers, products, userStores) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories, dT: userStores};
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$userStoresDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_enabled',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(true),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'store_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'display_name',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Utils$Types$UserStore)))))))))));
var $author$project$Pages$Users$Hi24Comnet$TenAM$initResponseDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Pages$Users$Hi24Comnet$TenAM$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder),
	A2($elm$json$Json$Decode$field, 'users_stores', $author$project$Pages$Users$Hi24Comnet$TenAM$userStoresDecoder));
var $author$project$Pages$Users$Hi24Comnet$TenAM$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				r: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'stores-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '10:00:00',
				hj: '23:59:59',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi24Comnet$TenAM$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$hi24ComnetEndpoints.mr.w(_List_Nil),
						session,
						$author$project$Pages$Users$Hi24Comnet$TenAM$initResponseDecoder,
						$author$project$Pages$Users$Hi24Comnet$TenAM$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Hi24Comnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi24Comnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$hi24ComnetEndpoints.jZ.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$initResponseDecoder,
						$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi3Comnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Hi3Comnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi3Comnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Hi3Comnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$Hi3Comnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Hi3Comnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hi3Comnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hisense$Amazon$GotDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$init = function (session) {
	return _Utils_Tuple2(
		{en: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b1: false, cp: -1, bs: '', aX: '', bu: session, dI: $elm$core$Maybe$Nothing},
		A2($elm$core$Task$perform, $author$project$Pages$Users$Hisense$Amazon$GotDate, $author$project$Utils$Helpers$getDate));
};
var $author$project$Pages$Users$Hisense$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Endpoints$User$hisense = 'hisense';
var $author$project$Endpoints$User$hisenseEndpoints = {
	bN: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'change-product', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'change-product', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'change-product', 'update-all']),
			_List_Nil)
	},
	ex: {
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'time-seq', 'init'])),
		dA: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'excel', 'submit']))
	},
	il: $elm$url$Url$Builder$absolute(
		_List_fromArray(
			[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'amazon-ranking'])),
	l1: {
		kW: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$hisense, 'review', 'get-review-excel']))
	}
};
var $author$project$Pages$Users$Hisense$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$Hisense$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$Hisense$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Hisense$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$Hisense$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$Hisense$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$hisenseEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$Hisense$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$Hisense$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hisense$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Common$Default$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Common$Default$initResponseDecoder,
						$author$project$Pages$Users$Common$Default$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hisense$Excel$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Hisense$Excel$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Hisense$Excel$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hisense$Excel$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hisense$Excel$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$hisenseEndpoints.ex.w(_List_Nil),
						session,
						$author$project$Pages$Users$Hisense$Excel$initResponseDecoder,
						$author$project$Pages$Users$Hisense$Excel$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hisense$Review$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hisense$Review$init = function (session) {
	return _Utils_Tuple2(
		{bc: '', bu: session, by: ''},
		A2($elm$core$Task$perform, $author$project$Pages$Users$Hisense$Review$GotDate, $elm$time$Time$now));
};
var $author$project$Pages$Users$Hisense$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$InitResponse = F3(
	function (bigCategories, smallCategories, products) {
		return {hR: bigCategories, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Hisense$TimeSeq$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hisense$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Endpoints$User$jvcComnet = 'jvc-comnet';
var $author$project$Endpoints$User$jvcComnetEndpoints = {
	hw: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$jvcComnet, 'time-seq', 'prices'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$jvcComnet, 'time-seq', 'init']))
	}
};
var $author$project$Pages$Users$Hisense$TimeSeq$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				c6: false,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				hf: $elm$core$Maybe$Nothing,
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				bu: session,
				jy: _List_Nil,
				ap: _List_Nil,
				dB: '',
				dC: '',
				dD: $elm$core$Maybe$Nothing,
				dE: '',
				dF: '',
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Hisense$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$jvcComnetEndpoints.hw.w(
							_List_fromArray(
								[
									A2(
									$Herteby$url_builder_plus$UrlBuilderPlus$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Hisense$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Hisense$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Hisense$TvHour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Common$Default$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$Common$Default$initResponseDecoder,
						$author$project$Pages$Users$Common$Default$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$InitResponse = F3(
	function (bigCategories, smallCategories, products) {
		return {hR: bigCategories, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$JvcComnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$JvcComnet$TimeSeq$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				c6: false,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				hf: $elm$core$Maybe$Nothing,
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				bu: session,
				jy: _List_Nil,
				ap: _List_Nil,
				dB: '',
				dC: '',
				dD: $elm$core$Maybe$Nothing,
				dE: '',
				dF: '',
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$JvcComnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$jvcComnetEndpoints.hw.w(
							_List_fromArray(
								[
									A2(
									$Herteby$url_builder_plus$UrlBuilderPlus$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$JvcComnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$JvcComnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$Product = F9(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, triggerPointFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, bz: triggerPointFlag, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(0),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_interval',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(0),
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'trigger_flag', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$KinujoComnet$ChangeProduct$Product)))))))))));
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$KinujoComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$KinujoComnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tv24ComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$KinujoComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$KinujoComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$KinujoComnet$Excel$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$KinujoComnet$Excel$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$KinujoComnet$Excel$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Endpoints$User$kinujoComnet = 'kinujo-comnet';
var $author$project$Endpoints$User$kinujoComnetEndpoints = {
	ex: {
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'time-seq', 'init'])),
		dA: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$kinujoComnet, 'excel', 'submit']))
	}
};
var $author$project$Pages$Users$KinujoComnet$Excel$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$KinujoComnet$Excel$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$kinujoComnetEndpoints.ex.w(_List_Nil),
						session,
						$author$project$Pages$Users$KinujoComnet$Excel$initResponseDecoder,
						$author$project$Pages$Users$KinujoComnet$Excel$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$KinujoComnet$Fifteen$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$KinujoComnet$Fifteen$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$KinujoComnet$Fifteen$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$KinujoComnet$Fifteen$initResponseDecoder,
						$author$project$Pages$Users$KinujoComnet$Fifteen$InitRequestSent)
					])));
	});
var $author$project$Utils$Types$ReleaseDateDesc = 0;
var $author$project$Pages$Users$MlnComnet$AutoReload$Tick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{eT: $elm$core$Maybe$Nothing, br: $krisajenkins$remotedata$RemoteData$NotAsked, cq: 'DisplayAll', js: 0, bu: session, L: $elm$core$Set$empty},
			A2($elm$core$Task$perform, $author$project$Pages$Users$MlnComnet$AutoReload$Tick, $elm$time$Time$now));
	});
var $author$project$Pages$Users$MlnComnet$ChangeMail$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$InitResponse = function (productsMails) {
	return {fn: productsMails};
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$ProductMail = F3(
	function (product, mails, mailsPerHour) {
		return {bh: mails, bi: mailsPerHour, an: product};
	});
var $author$project$Pages$Users$MlnComnet$ChangeMail$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$MlnComnet$ChangeMail$InitResponse,
	A2(
		$elm$json$Json$Decode$field,
		'products_mails',
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				$author$project$Pages$Users$MlnComnet$ChangeMail$ProductMail,
				A2($elm$json$Json$Decode$field, 'userProduct', $author$project$Utils$Decoders$productDecoder),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$String$join(','),
					A2(
						$elm$json$Json$Decode$field,
						'mails',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string))),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$String$join(','),
					A2(
						$elm$json$Json$Decode$field,
						'mailsPerHour',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string)))))));
var $author$project$Endpoints$User$mlnComnet = 'mln-comnet';
var $author$project$Endpoints$User$mlnComnetEndpoints = {
	j2: {
		j0: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'alert-setting', 'add-mails-products'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'alert-setting', 'init'])),
		l_: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'alert-setting', 'remove-mails-products'])),
		mB: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'alert-setting', 'update-mails-products']))
	},
	kg: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-mail', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-mail', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-mail', 'update-all']),
			_List_Nil)
	},
	bN: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-product', 'init'])),
		l3: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-product', 'save-form-snapshot']),
			_List_Nil),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-product', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-product', 'update-all']),
			_List_Nil)
	},
	ki: {
		kI: function (filename) {
			return $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
				_List_fromArray(
					[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'chronological', 'export-excel', filename]));
		},
		kJ: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'chronological', 'export-excel'])),
		kK: function (filename) {
			return $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
				_List_fromArray(
					[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'chronological', 'export-excel', filename, 'status']));
		},
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'chronological', 'init'])),
		dA: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'chronological', 'submit']))
	},
	le: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'mail-setting', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'mail-setting', 'update']),
			_List_Nil)
	},
	l8: {
		kr: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'selector-snapshot', 'create'])),
		il: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'selector-snapshot', 'get'])),
		hc: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'selector-snapshot', 'remove'])),
		l2: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'selector-snapshot', 'save']))
	},
	mC: {
		ad: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'change-password']))
	},
	mK: {
		kR: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'yahoo', 'direct-urls'])),
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'yahoo', 'init'])),
		ad: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$mlnComnet, 'yahoo', 'update']))
	}
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$init = function (session) {
	return _Utils_Tuple2(
		{eK: $krisajenkins$remotedata$RemoteData$NotAsked, bn: '', js: 0, bu: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$User$mlnComnetEndpoints.j2.w(_List_Nil),
					session,
					$author$project$Pages$Users$MlnComnet$ChangeMail$initResponseDecoder,
					$author$project$Pages$Users$MlnComnet$ChangeMail$InitRequestSent)
				])));
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$init = function (session) {
	return _Utils_Tuple2(
		{cP: '', aR: '', ds: '', bu: session, aA: $krisajenkins$remotedata$RemoteData$NotAsked},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$InitResponse = F4(
	function (products, bigCategories, smallCategories, formSnapshot) {
		return {hR: bigCategories, cW: formSnapshot, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$Form = F4(
	function (bigCategoryId, smallCategoryId, showOnlyBasePriceEnabled, showOnlyMailEnabled) {
		return {ed: bigCategoryId, jw: showOnlyBasePriceEnabled, ab: showOnlyMailEnabled, ho: smallCategoryId};
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$formSnapshotDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$MlnComnet$ChangeProduct$Form,
	A2(
		$elm$json$Json$Decode$field,
		'big_category_id',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'small_category_id',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'display_only_base_price_enabled', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'display_only_mail_enabled', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$MlnComnet$ChangeProduct$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$MlnComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder),
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'change_product_form_snapshot',
		$elm$json$Json$Decode$nullable($author$project$Pages$Users$MlnComnet$ChangeProduct$formSnapshotDecoder)));
var $author$project$Pages$Users$MlnComnet$ChangeProduct$init = function (session) {
	return _Utils_Tuple2(
		{hM: $elm$core$Maybe$Nothing, hR: _List_Nil, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bn: '', he: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, js: 0, bu: session, ay: false, ab: false, jy: _List_Nil},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$User$mlnComnetEndpoints.bN.w(_List_Nil),
					session,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$initResponseDecoder,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$InitRequestSent)
				])));
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotGetSelectorSnapshot = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$NotAsked = {$: 0};
var $frandibar$elm_bootstrap$Bootstrap$Modal$Hide = 3;
var $frandibar$elm_bootstrap$Bootstrap$Modal$hidden = 3;
var $author$project$Pages$Users$MlnComnet$Chronological$InitResponse = F5(
	function (bigCategories, smallCategories, makers, products, stores) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories, ap: stores};
	});
var $author$project$Pages$Users$MlnComnet$Chronological$initResponseDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Pages$Users$MlnComnet$Chronological$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area = F4(
	function (top, left, width, height) {
		return {ip: height, iH: left, jN: top, j_: width};
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$Closed = 2;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$State = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$initialState = {
	df: A4($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0),
	ac: 2,
	fZ: A4($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0)
};
var $author$project$Pages$Users$MlnComnet$Chronological$pageName = 'MLN_COMNET_CHRONOLOGICAL';
var $author$project$Utils$Types$SelectorSnapshot = F6(
	function (selectorSnapshotId, name, bigCategoryId, smallCategoryId, productIds, storeIds) {
		return {ed: bigCategoryId, iM: name, lS: productIds, fK: selectorSnapshotId, ho: smallCategoryId, mj: storeIds};
	});
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Utils$Decoders$selectorSnapshotDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Utils$Types$SelectorSnapshot,
	A2($elm$json$Json$Decode$field, 'selector_snapshot_id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'big_category_id',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'small_category_id',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'product_ids',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'store_ids',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$Utils$Decoders$selectorSnapshotsDecoder = $elm$json$Json$Decode$list($author$project$Utils$Decoders$selectorSnapshotDecoder);
var $author$project$Pages$Users$MlnComnet$Chronological$init = F2(
	function (session, interval) {
		return _Utils_Tuple2(
			{
				O: $elm$core$Set$empty,
				a3: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden,
				en: $elm$core$Maybe$Nothing,
				a9: $author$project$Pages$Users$MlnComnet$Chronological$NotAsked,
				aJ: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden,
				aK: true,
				as: $krisajenkins$remotedata$RemoteData$NotAsked,
				Z: interval,
				ag: false,
				r: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'stores', 0),
				aj: false,
				al: false,
				am: true,
				dq: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden,
				g3: '',
				bo: false,
				bp: false,
				bq: false,
				dt: $frandibar$elm_bootstrap$Bootstrap$Dropdown$initialState,
				he: -1,
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Nothing,
				hh: _List_Nil,
				fH: -1,
				aa: $elm$core$Maybe$Nothing,
				fI: '',
				hi: '',
				hj: '',
				bt: $krisajenkins$remotedata$RemoteData$NotAsked,
				cu: false,
				bu: session,
				az: '',
				cA: $krisajenkins$remotedata$RemoteData$NotAsked,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				fY: $elm$core$Maybe$Nothing,
				bA: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$MlnComnet$Chronological$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$mlnComnetEndpoints.ki.w(_List_Nil),
						session,
						$author$project$Pages$Users$MlnComnet$Chronological$initResponseDecoder,
						$author$project$Pages$Users$MlnComnet$Chronological$InitRequestSent),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$mlnComnetEndpoints.l8.il(
							_List_fromArray(
								[
									A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'pageName', $author$project$Pages$Users$MlnComnet$Chronological$pageName)
								])),
						session,
						A2($elm$json$Json$Decode$field, 'selectorSnapshots', $author$project$Utils$Decoders$selectorSnapshotsDecoder),
						$author$project$Pages$Users$MlnComnet$Chronological$GotGetSelectorSnapshot)
					])));
	});
var $author$project$Pages$Users$MlnComnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$MlnComnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$MlnComnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				js: 0,
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$MlnComnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$MlnComnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$MlnComnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$HourYamada$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$MlnComnet$HourYamada$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				js: 0,
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$MlnComnet$HourYamada$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$MlnComnet$HourYamada$initResponseDecoder,
						$author$project$Pages$Users$MlnComnet$HourYamada$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$MlnComnet$MailSetting$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$MailSetting$InitResponse = function (mails) {
	return {bh: mails};
};
var $author$project$Pages$Users$MlnComnet$MailSetting$Mail = F5(
	function (mailId, mailAddress, enabled, startTime, endTime) {
		return {ev: enabled, ew: endTime, eX: mailAddress, eY: mailId, fN: startTime};
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Pages$Users$MlnComnet$MailSetting$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$MlnComnet$MailSetting$InitResponse,
	A2(
		$elm$json$Json$Decode$field,
		'mails',
		$elm$json$Json$Decode$list(
			A6(
				$elm$json$Json$Decode$map5,
				$author$project$Pages$Users$MlnComnet$MailSetting$Mail,
				A2($elm$json$Json$Decode$field, 'mail_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'mail_address', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'enabled', $elm$json$Json$Decode$bool),
				A2(
					$elm$json$Json$Decode$field,
					'start_time',
					$elm$json$Json$Decode$maybe(
						A2(
							$elm$json$Json$Decode$andThen,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$split(':'),
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$take(2),
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$join(':'),
										$elm$json$Json$Decode$succeed))),
							$elm$json$Json$Decode$string))),
				A2(
					$elm$json$Json$Decode$field,
					'end_time',
					$elm$json$Json$Decode$maybe(
						A2(
							$elm$json$Json$Decode$andThen,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$split(':'),
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$take(2),
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$join(':'),
										$elm$json$Json$Decode$succeed))),
							$elm$json$Json$Decode$string)))))));
var $author$project$Pages$Users$MlnComnet$MailSetting$init = function (session) {
	return _Utils_Tuple2(
		{eK: $krisajenkins$remotedata$RemoteData$NotAsked, bn: '', bu: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$User$mlnComnetEndpoints.le.w(_List_Nil),
					session,
					$author$project$Pages$Users$MlnComnet$MailSetting$initResponseDecoder,
					$author$project$Pages$Users$MlnComnet$MailSetting$InitRequestSent)
				])));
};
var $author$project$Pages$Users$MlnComnet$Yahoo$GotInitResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$MlnComnet$Yahoo$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$MlnComnet$Yahoo$init = function (session) {
	return _Utils_Tuple2(
		{aG: $krisajenkins$remotedata$RemoteData$Loading, eK: $krisajenkins$remotedata$RemoteData$Loading, hg: '', bu: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$User$mlnComnetEndpoints.mK.w(_List_Nil),
					session,
					$author$project$Pages$Users$MlnComnet$Yahoo$initResponseDecoder,
					$author$project$Pages$Users$MlnComnet$Yahoo$GotInitResponse)
				])));
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$ShaComnet$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$ShaComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$ShaComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$ShaComnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$ShaComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$ShaComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ShaComnet$Day$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$InitResponse = F5(
	function (bigCategories, smallCategories, makers, products, userStores) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories, dT: userStores};
	});
var $author$project$Pages$Users$ShaComnet$Day$userStoresDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_enabled',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(true),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'store_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'display_name',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Utils$Types$UserStore)))))))))));
var $author$project$Pages$Users$ShaComnet$Day$initResponseDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Pages$Users$ShaComnet$Day$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder),
	A2($elm$json$Json$Decode$field, 'users_stores', $author$project$Pages$Users$ShaComnet$Day$userStoresDecoder));
var $author$project$Endpoints$User$shaComnet = 'sha-comnet';
var $author$project$Endpoints$User$shaComnetEndpoints = {
	gp: {
		C: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$shaComnet, 'day', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$shaComnet, 'day', 'init']))
	},
	jY: {
		C: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$shaComnet, 'weekly-product', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$shaComnet, 'weekly-product', 'init']))
	}
};
var $author$project$Pages$Users$ShaComnet$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				r: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'stores-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				hl: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				dT: _List_Nil,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ShaComnet$Day$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$shaComnetEndpoints.gp.w(_List_Nil),
						session,
						$author$project$Pages$Users$ShaComnet$Day$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$Day$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ShaComnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$ShaComnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$ShaComnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ShaComnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$ShaComnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$ShaComnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ShaComnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$ShaComnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$WeeklyProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ShaComnet$WeeklyProduct$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$shaComnetEndpoints.jY.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$ShaComnet$WeeklyProduct$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$WeeklyProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$ShaComnet$Wide$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$ShaComnet$Wide$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$ShaComnet$Wide$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$Wide$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				ct: false,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$ShaComnet$Wide$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$ShaComnet$Wide$initResponseDecoder,
						$author$project$Pages$Users$ShaComnet$Wide$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$Product = F9(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, triggerFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mz: triggerFlag, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(0),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_interval',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(0),
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'trigger_flag', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$TescomComnet$ChangeProduct$Product)))))))))));
var $author$project$Pages$Users$TescomComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$TescomComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$TescomComnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$TescomComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tv24ComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$TescomComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$TescomComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TescomComnet$Excel$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$TescomComnet$Excel$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$TescomComnet$Excel$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Endpoints$User$tescomComnet = 'tescom-comnet';
var $author$project$Endpoints$User$tescomComnetEndpoints = {
	ex: {
		w: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'time-seq', 'init'])),
		dA: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tescomComnet, 'excel', 'submit']))
	}
};
var $author$project$Pages$Users$TescomComnet$Excel$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				hx: maybeTitle
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TescomComnet$Excel$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tescomComnetEndpoints.ex.w(_List_Nil),
						session,
						$author$project$Pages$Users$TescomComnet$Excel$initResponseDecoder,
						$author$project$Pages$Users$TescomComnet$Excel$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TescomComnet$Hour$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$TescomComnet$Hour$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TescomComnet$Hour$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$TescomComnet$Hour$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TescomComnet$Hour$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$TescomComnet$Hour$initResponseDecoder,
						$author$project$Pages$Users$TescomComnet$Hour$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Test$Fifteen$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Test$Fifteen$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Test$Fifteen$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Test$Fifteen$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Test$Fifteen$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Test$Fifteen$initResponseDecoder,
						$author$project$Pages$Users$Test$Fifteen$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, isDraggingOver) {
		return {j9: basePoint, ka: basePrice, $9: get_interval, c3: isDraggingOver, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$To24tclComnet$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tclComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$To24tclComnet$ChangeProduct$productsDecoder));
var $author$project$Endpoints$User$to24tcmComnet = 'to24tcm-comnet';
var $author$project$Endpoints$User$to24tcmComnetEndpoints = {
	bN: {
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$to24tcmComnet, 'change-product', 'init'])),
		ad: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$to24tcmComnet, 'change-product', 'update']),
			_List_Nil),
		hB: A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$absolute,
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$to24tcmComnet, 'change-product', 'update-all']),
			_List_Nil)
	},
	ks: {
		cX: $Herteby$url_builder_plus$UrlBuilderPlus$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tv24Comnet, 'csv', 'get-csv'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$tv24Comnet, 'csv', 'init']))
	},
	gp: {
		cX: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'csv'])),
		gx: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, 'news'])),
		C: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'prices'])),
		im: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$to24tcmComnet, 'day', 'prices'])),
		w: $elm$url$Url$Builder$absolute(
			_List_fromArray(
				[$author$project$Endpoints$User$user, $author$project$Endpoints$User$common, $author$project$Endpoints$User$default, 'init']))
	}
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, bU: $elm$core$Maybe$Nothing, cS: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$to24tcmComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$To24tclComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$To24tclComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tclComnet$Day$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$To24tclComnet$Day$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tclComnet$Day$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$To24tclComnet$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$To24tclComnet$Day$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$To24tclComnet$Day$initResponseDecoder,
						$author$project$Pages$Users$To24tclComnet$Day$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Common$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$Common$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$Common$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Common$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Common$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Common$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$Common$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tclComnet$TimeSeq$init = $author$project$Pages$Users$Common$TimeSeq$init;
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$Product = F8(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$productsDecoder = $elm$json$Json$Decode$list(
	A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$To24tcmComnet$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0)));
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tcmComnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$To24tcmComnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$to24tcmComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tcmComnet$Csv$GotDate = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$productsDecoder = $elm$json$Json$Decode$list(
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$Tuple$pair,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string)));
var $author$project$Pages$Users$To24tcmComnet$Csv$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tcmComnet$Csv$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$To24tcmComnet$Csv$productsDecoder));
var $author$project$Pages$Users$To24tcmComnet$Csv$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{cM: $elm$core$Set$empty, en: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, Z: interval, b2: false, c5: false, de: '', dh: '', ja: _List_Nil, du: '', dv: '', hh: _List_Nil, hi: '', hj: '', bu: session, hx: maybeTitle},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$To24tcmComnet$Csv$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$to24tcmComnetEndpoints.ks.w(_List_Nil),
						session,
						$author$project$Pages$Users$To24tcmComnet$Csv$initResponseDecoder,
						$author$project$Pages$Users$To24tcmComnet$Csv$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$To24tcmComnet$Day$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmComnet$Day$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$To24tcmComnet$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$To24tcmComnet$Day$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$To24tcmComnet$Day$initResponseDecoder,
						$author$project$Pages$Users$To24tcmComnet$Day$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$To24tcmComnet$Product$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmComnet$Product$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$To24tcmComnet$Product$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$To24tcmComnet$Product$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$To24tcmComnet$Product$initResponseDecoder,
						$author$project$Pages$Users$To24tcmComnet$Product$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$init = $author$project$Pages$Users$Common$TimeSeq$init;
var $author$project$Pages$Users$To24tcmKitchen$Day$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmKitchen$Day$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$To24tcmKitchen$Day$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$To24tcmKitchen$Day$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$To24tcmKitchen$Day$initResponseDecoder,
						$author$project$Pages$Users$To24tcmKitchen$Day$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TsBat$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$TsBat$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$TsBat$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TsBat$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TsBat$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$TsBat$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$TsBat$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TsBat$Wide$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$TsBat$Wide$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$TsBat$Wide$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TsBat$Wide$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TsBat$Wide$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$TsBat$Wide$initResponseDecoder,
						$author$project$Pages$Users$TsBat$Wide$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$TstComnet$TimeSeq$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TstComnet$TimeSeq$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TstComnet$TimeSeq$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$TstComnet$TimeSeq$initResponseDecoder,
						$author$project$Pages$Users$TstComnet$TimeSeq$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$TstComnet$Wide$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$InitResponse = F4(
	function (bigCategories, smallCategories, makers, products) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Users$TstComnet$Wide$initResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Users$TstComnet$Wide$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TstComnet$Wide$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				D: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'products-selector', 0),
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				du: '',
				dv: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				hi: '',
				hj: '',
				cs: _List_Nil,
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				dG: '',
				fR: '',
				dH: '',
				fS: '',
				hx: maybeTitle,
				L: $elm$core$Set$empty,
				gb: false
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$TstComnet$Wide$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$timeSeqEndpoints.w(_List_Nil),
						session,
						$author$project$Pages$Users$TstComnet$Wide$initResponseDecoder,
						$author$project$Pages$Users$TstComnet$Wide$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$InitRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$InitResponse = function (products) {
	return {ja: products};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$Product = F9(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus, rTyousei) {
		return {j9: basePoint, ka: basePrice, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, dr: rTyousei, mM: yodobashiFlag};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$productsDecoder = function () {
	var mainFields = A9(
		$elm$json$Json$Decode$map8,
		$author$project$Pages$Users$Tv24Comnet$ChangeProduct$Product,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'base_price', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'get_interval',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(0),
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$succeed(0));
	return $elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Basics$apL,
			mainFields,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'r_tyousei', $elm$json$Json$Decode$int))));
}();
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$initResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Tv24Comnet$ChangeProduct$InitResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$Tv24Comnet$ChangeProduct$productsDecoder));
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$init = F3(
	function (interval, maybeTitle, session) {
		return _Utils_Tuple2(
			{hM: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ar: $elm$core$Maybe$Nothing, b2: true, J: false, ja: _List_Nil, bu: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$tv24ComnetEndpoints.bN.w(_List_Nil),
						session,
						$author$project$Pages$Users$Tv24Comnet$ChangeProduct$initResponseDecoder,
						$author$project$Pages$Users$Tv24Comnet$ChangeProduct$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$GotDate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$InitRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$InitResponse = F3(
	function (bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, jy: smallCategories};
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$initResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Tv24Comnet$Thirty$InitResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Users$Tv24Comnet$Thirty$init = F4(
	function (interval, maybeTitle, usePast, session) {
		return _Utils_Tuple2(
			{
				hR: _List_Nil,
				en: $elm$core$Maybe$Nothing,
				ar: $elm$core$Maybe$Nothing,
				gz: false,
				Z: interval,
				b2: true,
				c5: false,
				ca: _List_Nil,
				ck: $elm$core$Dict$empty,
				ja: _List_Nil,
				he: $elm$core$Maybe$Just(-1),
				bs: '',
				cq: 'DisplayAll',
				dw: $elm$core$Maybe$Just(-1),
				fH: $elm$core$Maybe$Just(-1),
				aX: '',
				bu: session,
				jy: _List_Nil,
				U: _List_Nil,
				ap: _List_Nil,
				fP: '',
				fQ: '',
				hx: maybeTitle,
				f3: usePast,
				L: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Pages$Users$Tv24Comnet$Thirty$GotDate, $author$project$Utils$Helpers$getDate),
						A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$defaultEndpoints.w(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Builder$string,
									'interval',
									$elm$core$String$fromInt(interval))
								])),
						session,
						$author$project$Pages$Users$Tv24Comnet$Thirty$initResponseDecoder,
						$author$project$Pages$Users$Tv24Comnet$Thirty$InitRequestSent)
					])));
	});
var $author$project$Pages$Users$BraunComnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$BraunComnet$Wide$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Common$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Common$Default$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Common$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Delonghi$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Delonghi$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Denon6Comnet$Wide$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ElecComnet$Excel$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ElecComnet$Hour$toSession = $author$project$Pages$Users$Common$Default$toSession;
var $author$project$Pages$Users$ElecComnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$FujiComnet$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi24Comnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi3Comnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$Amazon$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$Excel$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$Review$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Hisense$TvHour$toSession = $author$project$Pages$Users$Common$Default$toSession;
var $author$project$Pages$Users$JvcComnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$KinujoComnet$Excel$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$AutoReload$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$Chronological$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$HourYamada$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$MailSetting$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$MlnComnet$Yahoo$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$ShaComnet$Wide$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TescomComnet$Excel$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TescomComnet$Hour$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Test$Fifteen$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tclComnet$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$toSession = $author$project$Pages$Users$Common$TimeSeq$toSession;
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tcmComnet$Csv$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tcmComnet$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tcmComnet$Product$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$toSession = $author$project$Pages$Users$Common$TimeSeq$toSession;
var $author$project$Pages$Users$To24tcmKitchen$Day$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TsBat$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TsBat$Wide$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TstComnet$TimeSeq$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$TstComnet$Wide$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$toSession = function (model) {
	return model.bu;
};
var $author$project$Pages$User$toSession = function (_v0) {
	var userModel = _v0.a;
	switch (userModel.$) {
		case 0:
			var session = userModel.a;
			return session;
		case 1:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Common$Default$toSession(defaultModel);
		case 2:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Common$TimeSeq$toSession(defaultModel);
		case 9:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Common$ChangeProduct$toSession(defaultModel);
		case 10:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmComnet$TimeSeq$toSession(defaultModel);
		case 11:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmComnet$Csv$toSession(defaultModel);
		case 12:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmComnet$Day$toSession(defaultModel);
		case 13:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmComnet$ChangeProduct$toSession(defaultModel);
		case 14:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmComnet$Product$toSession(defaultModel);
		case 46:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tcmKitchen$Day$toSession(defaultModel);
		case 15:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tclComnet$ChangeProduct$toSession(defaultModel);
		case 16:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tclComnet$Day$toSession(defaultModel);
		case 17:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$To24tclComnet$TimeSeq$toSession(defaultModel);
		case 18:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$JvcComnet$TimeSeq$toSession(defaultModel);
		case 3:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi24Comnet$Hour$toSession(defaultModel);
		case 4:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi24Comnet$TimeSeq$toSession(defaultModel);
		case 5:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$toSession(defaultModel);
		case 6:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi24Comnet$TenAM$toSession(defaultModel);
		case 7:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi3Comnet$Hour$toSession(defaultModel);
		case 8:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hi3Comnet$TimeSeq$toSession(defaultModel);
		case 19:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$Day$toSession(defaultModel);
		case 20:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$TvHour$toSession(defaultModel);
		case 22:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$Amazon$toSession(defaultModel);
		case 24:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$ChangeProduct$toSession(defaultModel);
		case 21:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$Review$toSession(defaultModel);
		case 23:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$TimeSeq$toSession(defaultModel);
		case 25:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Hisense$Excel$toSession(defaultModel);
		case 26:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TescomComnet$Hour$toSession(defaultModel);
		case 27:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TescomComnet$Excel$toSession(defaultModel);
		case 28:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TescomComnet$ChangeProduct$toSession(defaultModel);
		case 29:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Ca6Comnet$HourCsv$toSession(defaultModel);
		case 30:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$toSession(defaultModel);
		case 31:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$ChangeProduct$toSession(defaultModel);
		case 35:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$ChangeMail$toSession(defaultModel);
		case 38:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$ChangePassword$toSession(defaultModel);
		case 32:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$Chronological$toSession(defaultModel);
		case 33:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$Hour$toSession(defaultModel);
		case 34:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$HourYamada$toSession(defaultModel);
		case 36:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$AutoReload$toSession(defaultModel);
		case 37:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$MailSetting$toSession(defaultModel);
		case 39:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$MlnComnet$Yahoo$toSession(defaultModel);
		case 40:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Tv24Comnet$ChangeProduct$toSession(defaultModel);
		case 41:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Tv24Comnet$Thirty$toSession(defaultModel);
		case 42:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ElecComnet$Hour$toSession(defaultModel);
		case 43:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ElecComnet$Excel$toSession(defaultModel);
		case 44:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ElecComnet$ChangeProduct$toSession(defaultModel);
		case 45:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ElecComnet$TimeSeq$toSession(defaultModel);
		case 47:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Delonghi$Hour$toSession(defaultModel);
		case 48:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Delonghi$ChangeProduct$toSession(defaultModel);
		case 49:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Delonghi$TimeSeq$toSession(defaultModel);
		case 50:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Delonghi$TimeSeq2$toSession(defaultModel);
		case 51:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$KinujoComnet$Excel$toSession(defaultModel);
		case 52:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$KinujoComnet$ChangeProduct$toSession(defaultModel);
		case 53:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$KinujoComnet$Fifteen$toSession(defaultModel);
		case 54:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$Wide$toSession(defaultModel);
		case 55:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$Hour$toSession(defaultModel);
		case 56:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$TimeSeq$toSession(defaultModel);
		case 57:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$WeeklyProduct$toSession(defaultModel);
		case 58:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$Day$toSession(defaultModel);
		case 59:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$ShaComnet$ChangeProduct$toSession(defaultModel);
		case 60:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TstComnet$TimeSeq$toSession(defaultModel);
		case 61:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TstComnet$Wide$toSession(defaultModel);
		case 62:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Dbi6Comnet$Hour$toSession(defaultModel);
		case 63:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Dbi6Comnet$TimeSeq$toSession(defaultModel);
		case 65:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Denon6Comnet$Wide$toSession(defaultModel);
		case 64:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Denon6Comnet$TimeSeq$toSession(defaultModel);
		case 71:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$BraunComnet$Hour$toSession(defaultModel);
		case 72:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$BraunComnet$Wide$toSession(defaultModel);
		case 66:
			var reiModel = userModel.a;
			return $author$project$Pages$Cms$Products$EditProductsPermalinks$toSession(reiModel);
		case 67:
			var reiModel = userModel.a;
			return $author$project$Pages$Cms$Products$ChangeUserProduct$toSession(reiModel);
		case 68:
			var reiModel = userModel.a;
			return $author$project$Pages$Cms$Products$AddProductConfig$toSession(reiModel);
		case 69:
			var reiModel = userModel.a;
			return $author$project$Pages$Cms$Products$EditProducts$toSession(reiModel);
		case 70:
			var yModel = userModel.a;
			return $author$project$Pages$Cms$Stores$Yahoo$toSession(yModel);
		case 74:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TsBat$Wide$toSession(defaultModel);
		case 73:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$TsBat$TimeSeq$toSession(defaultModel);
		case 75:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$FujiComnet$Day$toSession(defaultModel);
		default:
			var defaultModel = userModel.a;
			return $author$project$Pages$Users$Test$Fifteen$toSession(defaultModel);
	}
};
var $author$project$Pages$User$updateWithNavModel = F5(
	function (toModel, toMsg, navModel, news, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple3(
				toModel(subModel),
				navModel,
				news),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Pages$User$changeRouteTo = F2(
	function (url, model) {
		var router = $author$project$Routers$UserRouter$fromUrl(url);
		var _v0 = model;
		var userModel = _v0.a;
		var navModel = _v0.b;
		var news = _v0.c;
		switch (router) {
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetProductModel,
					$author$project$Pages$User$GotTo24tcmComnetProductMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$Product$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 3:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetProductModel,
					$author$project$Pages$User$GotTo24tcmComnetProductMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$Product$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 4:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetDayModel,
					$author$project$Pages$User$GotTo24tcmComnetDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$Day$init,
						1440,
						$elm$core$Maybe$Just('一日取得'),
						false,
						$author$project$Pages$User$toSession(model)));
			case 5:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 6:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 7:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 8:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetChangeProductModel,
					$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 9:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 10:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tclComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tclComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tclComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 11:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tclComnetDayModel,
					$author$project$Pages$User$GotTo24tclComnetDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tclComnet$Day$init,
						1440,
						$elm$core$Maybe$Just('一日取得'),
						false,
						$author$project$Pages$User$toSession(model)));
			case 12:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 13:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tclComnetChangeProductModel,
					$author$project$Pages$User$GotTo24tclComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tclComnet$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 14:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 15:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 16:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 17:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 18:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 19:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetDayModel,
					$author$project$Pages$User$GotTo24tcmComnetDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$Day$init,
						1440,
						$elm$core$Maybe$Just('一日取得'),
						false,
						$author$project$Pages$User$toSession(model)));
			case 20:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetChangeProductModel,
					$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 21:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 22:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 24:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 25:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 23:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmKitchenDayModel,
					$author$project$Pages$User$GotTo24tcmKitchenDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmKitchen$Day$init,
						1440,
						$elm$core$Maybe$Just('一日取得'),
						false,
						$author$project$Pages$User$toSession(model)));
			case 26:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 27:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetChangeProductModel,
					$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 28:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 29:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 30:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$JvcComnetTimeSeqModel,
					$author$project$Pages$User$GotJvcComnetTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$JvcComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 31:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$JvcComnetTimeSeqModel,
					$author$project$Pages$User$GotJvcComnetTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$JvcComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 32:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseDayModel,
					$author$project$Pages$User$GotHisenseDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hisense$Day$init,
						1440,
						$elm$core$Maybe$Just('一日取得'),
						false,
						$author$project$Pages$User$toSession(model)));
			case 34:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseTvHourModel,
					$author$project$Pages$User$GotHisenseTvHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hisense$TvHour$init,
						60,
						$elm$core$Maybe$Just('60分取得/ハイセンス4商品'),
						true,
						$author$project$Pages$User$toSession(model)));
			case 35:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseAmazonModel,
					$author$project$Pages$User$GotHisenseAmazonMsg,
					navModel,
					news,
					$author$project$Pages$Users$Hisense$Amazon$init(
						$author$project$Pages$User$toSession(model)));
			case 36:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseChangeProductModel,
					$author$project$Pages$User$GotHisenseChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Hisense$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 37:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseReviewModel,
					$author$project$Pages$User$GotHisenseReviewMsg,
					navModel,
					news,
					$author$project$Pages$Users$Hisense$Review$init(
						$author$project$Pages$User$toSession(model)));
			case 33:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseTimeSeqModel,
					$author$project$Pages$User$GotHisenseTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Hisense$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 38:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$HisenseExcelModel,
					$author$project$Pages$User$GotHisenseExcelMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hisense$Excel$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 43:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 44:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Ca6ComnetHourCsvModel,
					$author$project$Pages$User$GotCa6ComnetHourCsvMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Ca6Comnet$HourCsv$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model),
						false));
			case 45:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Ca6ComnetHourCsvModel,
					$author$project$Pages$User$GotCa6ComnetHourCsvMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Ca6Comnet$HourCsv$init,
						60,
						$elm$core$Maybe$Just('今週の品目 期間指定CSV'),
						$author$project$Pages$User$toSession(model),
						true));
			case 46:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Ca6ComnetWeeklyProductFifteenModel,
					$author$project$Pages$User$GotCa6ComnetWeeklyProductFifteenMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$init,
						15,
						$elm$core$Maybe$Just('今週の品目15分'),
						true,
						$author$project$Pages$User$toSession(model)));
			case 47:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetChangeProductModel,
					$author$project$Pages$User$GotMlnComnetChangeProductMsg,
					navModel,
					news,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$init(
						$author$project$Pages$User$toSession(model)));
			case 48:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetChangeMailModel,
					$author$project$Pages$User$GotMlnComnetChangeMailMsg,
					navModel,
					news,
					$author$project$Pages$Users$MlnComnet$ChangeMail$init(
						$author$project$Pages$User$toSession(model)));
			case 49:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetChangePasswordModel,
					$author$project$Pages$User$GotMlnComnetChangePasswordMsg,
					navModel,
					news,
					$author$project$Pages$Users$MlnComnet$ChangePassword$init(
						$author$project$Pages$User$toSession(model)));
			case 50:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetChronologicalModel,
					$author$project$Pages$User$GotMlnComnetChronologicalMsg,
					navModel,
					news,
					A2(
						$author$project$Pages$Users$MlnComnet$Chronological$init,
						$author$project$Pages$User$toSession(model),
						30));
			case 51:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetHourModel,
					$author$project$Pages$User$GotMlnComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$MlnComnet$Hour$init,
						30,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 52:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetHourYamadaModel,
					$author$project$Pages$User$GotMlnComnetHourYamadaMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$MlnComnet$HourYamada$init,
						30,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 53:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetAutoReloadModel,
					$author$project$Pages$User$GotMlnComnetAutoReloadMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$MlnComnet$AutoReload$init,
						30,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 54:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetMailSettingModel,
					$author$project$Pages$User$GotMlnComnetMailSettingMsg,
					navModel,
					news,
					$author$project$Pages$Users$MlnComnet$MailSetting$init(
						$author$project$Pages$User$toSession(model)));
			case 55:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$MlnComnetYahooModel,
					$author$project$Pages$User$GotMlnComnetYahooMsg,
					navModel,
					news,
					$author$project$Pages$Users$MlnComnet$Yahoo$init(
						$author$project$Pages$User$toSession(model)));
			case 39:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TescomComnetHourModel,
					$author$project$Pages$User$GotTescomComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TescomComnet$Hour$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 40:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TescomComnetHourModel,
					$author$project$Pages$User$GotTescomComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TescomComnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 41:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TescomComnetExcelModel,
					$author$project$Pages$User$GotTescomComnetExcelMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TescomComnet$Excel$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 42:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TescomComnetChangeProductModel,
					$author$project$Pages$User$GotTescomComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$TescomComnet$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 56:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Tv24ComnetThirtyModel,
					$author$project$Pages$User$GotTv24ComnetThirtyMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Tv24Comnet$Thirty$init,
						30,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 57:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						30,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 58:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Tv24ComnetChangeProductModel,
					$author$project$Pages$User$GotTv24ComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Tv24Comnet$ChangeProduct$init,
						30,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 59:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetHourModel,
					$author$project$Pages$User$GotElecComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ElecComnet$Hour$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 60:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetHourModel,
					$author$project$Pages$User$GotElecComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ElecComnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 61:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetExcelModel,
					$author$project$Pages$User$GotElecComnetExcelMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ElecComnet$Excel$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 62:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetChangeProductModel,
					$author$project$Pages$User$GotElecComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$ElecComnet$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 63:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetTimeSeqModel,
					$author$project$Pages$User$GotElecComnetTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$ElecComnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 64:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ElecComnetTimeSeqModel,
					$author$project$Pages$User$GotElecComnetTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$ElecComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 65:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 66:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DefaultModel,
					$author$project$Pages$User$GotDefaultMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Common$Default$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 67:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetTimeSeqModel,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 68:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetCsvModel,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$Csv$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 69:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$To24tcmComnetChangeProductModel,
					$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 70:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi3ComnetHourModel,
					$author$project$Pages$User$GotHi3ComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi3Comnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 71:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi3ComnetTimeSeqModel,
					$author$project$Pages$User$GotHi3ComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 72:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ChangeProductModel,
					$author$project$Pages$User$GotChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Common$ChangeProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 73:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DelonghiHourModel,
					$author$project$Pages$User$GotDelonghiHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Delonghi$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 74:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DelonghiChangeProductModel,
					$author$project$Pages$User$GotDelonghiChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Delonghi$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 75:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DelonghiTimeSeqModel,
					$author$project$Pages$User$GotDelonghiTimeSeqMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$Delonghi$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 76:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$DelonghiTimeSeq2Model,
					$author$project$Pages$User$GotDelonghiTimeSeq2Msg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Delonghi$TimeSeq2$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 77:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$KinujoComnetFifteenModel,
					$author$project$Pages$User$GotKinujoComnetFifteeenMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$KinujoComnet$Fifteen$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 78:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$KinujoComnetExcelModel,
					$author$project$Pages$User$GotKinujoComnetExcelMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$KinujoComnet$Excel$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 79:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$KinujoComnetChangeProductModel,
					$author$project$Pages$User$GotKinujoComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$KinujoComnet$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 80:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi24ComnetHourModel,
					$author$project$Pages$User$GotHi24ComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi24Comnet$Hour$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 81:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi24ComnetHourModel,
					$author$project$Pages$User$GotHi24ComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi24Comnet$Hour$init,
						360,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 82:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi24ComnetTimeSeqModel,
					$author$project$Pages$User$GotHi24ComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 83:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi24ComnetWeeklyProductFifteenModel,
					$author$project$Pages$User$GotHi24ComnetWeeklyProductFifteenMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$init,
						15,
						$elm$core$Maybe$Just('今週の品目15分'),
						true,
						$author$project$Pages$User$toSession(model)));
			case 84:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Hi24ComnetTenAMModel,
					$author$project$Pages$User$GotHi24ComnetTenAMMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Hi24Comnet$TenAM$init,
						15,
						$elm$core$Maybe$Just('10時の状況'),
						true,
						$author$project$Pages$User$toSession(model)));
			case 85:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetWideModel,
					$author$project$Pages$User$GotShaComnetWideMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ShaComnet$Wide$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 86:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetHourModel,
					$author$project$Pages$User$GotShaComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ShaComnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 87:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetTimeSeqModel,
					$author$project$Pages$User$GotShaComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ShaComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 89:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetDayModel,
					$author$project$Pages$User$GotShaComnetDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ShaComnet$Day$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 90:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetChangeProductModel,
					$author$project$Pages$User$GotShaComnetChangeProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Users$ShaComnet$ChangeProduct$init,
						60,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 88:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ShaComnetWeeklyProductModel,
					$author$project$Pages$User$GotShaComnetWeeklyProductMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$ShaComnet$WeeklyProduct$init,
						160,
						$elm$core$Maybe$Just('週次(1時間毎に頁更新)'),
						true,
						$author$project$Pages$User$toSession(model)));
			case 91:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TstComnetTimeSeqModel,
					$author$project$Pages$User$GotTstComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TstComnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 92:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TstComnetWideModel,
					$author$project$Pages$User$GotTstComnetWideMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TstComnet$Wide$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 93:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Dbi6ComnetHourModel,
					$author$project$Pages$User$GotDbi6ComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Dbi6Comnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 94:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Dbi6ComnetTimeSeqModel,
					$author$project$Pages$User$GotDbi6ComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 95:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Denon6ComnetWideModel,
					$author$project$Pages$User$GotDenon6ComnetWideMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Denon6Comnet$Wide$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 96:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Denon6ComnetTimeSeqModel,
					$author$project$Pages$User$GotDenon6ComnetTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 97:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$BraunComnetHourModel,
					$author$project$Pages$User$GotBraunComnetHourMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$BraunComnet$Hour$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 98:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$BraunComnetWideModel,
					$author$project$Pages$User$GotBraunComnetWideMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$BraunComnet$Wide$init,
						60,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 99:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$EditProductsPermalinks,
					$author$project$Pages$User$GotEditProductsPermalinks,
					navModel,
					news,
					$author$project$Pages$Cms$Products$EditProductsPermalinks$init(
						$author$project$Pages$User$toSession(model)));
			case 100:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$ChangeUserProductModel,
					$author$project$Pages$User$GotChangeUserProductMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Cms$Products$ChangeUserProduct$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 101:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$AddProductConfigModel,
					$author$project$Pages$User$GotAddProductConfigMsg,
					navModel,
					news,
					A3(
						$author$project$Pages$Cms$Products$AddProductConfig$init,
						15,
						$elm$core$Maybe$Nothing,
						$author$project$Pages$User$toSession(model)));
			case 102:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$EditProductsModel,
					$author$project$Pages$User$GotEditProductsMsg,
					navModel,
					news,
					$author$project$Pages$Cms$Products$EditProducts$init(
						$author$project$Pages$User$toSession(model)));
			case 103:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$Yahoo,
					$author$project$Pages$User$GotYahooMsg,
					navModel,
					news,
					$author$project$Pages$Cms$Stores$Yahoo$init(
						$author$project$Pages$User$toSession(model)));
			case 104:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TsBatWideModel,
					$author$project$Pages$User$GotTsBatWideMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TsBat$Wide$init,
						360,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 105:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TsBatTimeSeqModel,
					$author$project$Pages$User$GotTsBatTimeSeqMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$TsBat$TimeSeq$init,
						360,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
			case 106:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$FujiComnetDayModel,
					$author$project$Pages$User$GotFujiComnetDayMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$FujiComnet$Day$init,
						1440,
						$elm$core$Maybe$Nothing,
						false,
						$author$project$Pages$User$toSession(model)));
			default:
				return A5(
					$author$project$Pages$User$updateWithNavModel,
					$author$project$Pages$User$TestFifteenModel,
					$author$project$Pages$User$GotTestFifteeenMsg,
					navModel,
					news,
					A4(
						$author$project$Pages$Users$Test$Fifteen$init,
						15,
						$elm$core$Maybe$Nothing,
						true,
						$author$project$Pages$User$toSession(model)));
		}
	});
var $author$project$Routers$TopRouter$Cms = {$: 4};
var $author$project$Routers$TopRouter$Login = {$: 2};
var $author$project$Routers$TopRouter$NotFound = {$: 0};
var $author$project$Routers$TopRouter$Redirect = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routers$TopRouter$User = {$: 5};
var $author$project$Session$isAdmin = function (session) {
	if (session.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Session$isUser = function (session) {
	if (!session.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Routers$TopRouter$Root = {$: 1};
var $author$project$Routers$TopRouter$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Routers$TopRouter$Root, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routers$TopRouter$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routers$TopRouter$Cms,
			$elm$url$Url$Parser$s('cms')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routers$TopRouter$User,
			$elm$url$Url$Parser$s('user'))
		]));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routers$TopRouter$fromUrl = F2(
	function (url, session) {
		var firstPathUrl = function (path) {
			return _Utils_update(
				url,
				{lL: '/' + path});
		}(
			A2(
				$elm$core$Maybe$withDefault,
				'/',
				$elm$core$List$head(
					A2(
						$elm$core$Maybe$withDefault,
						_List_fromArray(
							['/']),
						$elm$core$List$tail(
							A2($elm$core$String$split, '/', url.lL))))));
		var parsedUrl = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routers$TopRouter$NotFound,
			A2($elm$url$Url$Parser$parse, $author$project$Routers$TopRouter$parser, firstPathUrl));
		switch (parsedUrl.$) {
			case 1:
				return $author$project$Session$isAdmin(session) ? $author$project$Routers$TopRouter$Redirect('cms') : ($author$project$Session$isUser(session) ? $author$project$Routers$TopRouter$Redirect('user') : $author$project$Routers$TopRouter$Redirect('login'));
			case 2:
				return $author$project$Session$isAdmin(session) ? $author$project$Routers$TopRouter$Redirect('cms') : ($author$project$Session$isUser(session) ? $author$project$Routers$TopRouter$Redirect('user') : $author$project$Routers$TopRouter$Login);
			case 4:
				return $author$project$Session$isAdmin(session) ? $author$project$Routers$TopRouter$Cms : $author$project$Routers$TopRouter$NotFound;
			case 5:
				return $author$project$Session$isUser(session) ? $author$project$Routers$TopRouter$User : $author$project$Routers$TopRouter$NotFound;
			default:
				return $author$project$Routers$TopRouter$NotFound;
		}
	});
var $author$project$Pages$Cms$GotNavBarMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Cms$Top = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$NavBar$NavbarMsg = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Hidden = 0;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$State = $elm$core$Basics$identity;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $frandibar$elm_bootstrap$Bootstrap$Navbar$mapState = F2(
	function (mapper, _v0) {
		var state = _v0;
		return mapper(state);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$initWindowSize = F2(
	function (toMsg, state) {
		return A2(
			$elm$core$Task$perform,
			function (vp) {
				return toMsg(
					A2(
						$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
						function (s) {
							return _Utils_update(
								s,
								{
									dZ: $elm$core$Maybe$Just(vp.jV.j_)
								});
						},
						state));
			},
			$elm$browser$Browser$Dom$getViewport);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$initialState = function (toMsg) {
	var state = {a7: $elm$core$Dict$empty, ip: $elm$core$Maybe$Nothing, B: 0, dZ: $elm$core$Maybe$Nothing};
	return _Utils_Tuple2(
		state,
		A2($frandibar$elm_bootstrap$Bootstrap$Navbar$initWindowSize, toMsg, state));
};
var $author$project$Components$NavBar$init = function () {
	var _v0 = $frandibar$elm_bootstrap$Bootstrap$Navbar$initialState($author$project$Components$NavBar$NavbarMsg);
	var navbarState = _v0.a;
	var navbarCmd = _v0.b;
	return _Utils_Tuple2(
		{dj: navbarState},
		navbarCmd);
}();
var $author$project$Pages$Cms$init = function (session) {
	var _v0 = $author$project$Components$NavBar$init;
	var navbarState = _v0.a;
	var navbarCmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_Tuple2(
			$author$project$Pages$Cms$Top(session),
			navbarState),
		A2($elm$core$Platform$Cmd$map, $author$project$Pages$Cms$GotNavBarMsg, navbarCmd));
};
var $author$project$Pages$Login$Valid = {$: 0};
var $author$project$Pages$Login$init = function (session) {
	return _Utils_Tuple2(
		{
			ar: $elm$core$Maybe$Nothing,
			aO: {dn: '', mD: ''},
			bu: session,
			jU: $author$project$Pages$Login$Valid
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Pages$User$GotNavBarMsg = function (a) {
	return {$: 25, a: a};
};
var $author$project$Pages$User$GotNewsMsg = function (a) {
	return {$: 29, a: a};
};
var $author$project$Pages$User$Top = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$User$NewsResponse = function (news) {
	return {iO: news};
};
var $author$project$Pages$User$newsResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$User$NewsResponse,
	A2($elm$json$Json$Decode$field, 'news', $elm$json$Json$Decode$string));
var $author$project$Pages$User$init = function (session) {
	var _v0 = $author$project$Components$NavBar$init;
	var navbarState = _v0.a;
	var navbarCmd = _v0.b;
	return _Utils_Tuple2(
		_Utils_Tuple3(
			$author$project$Pages$User$Top(session),
			navbarState,
			''),
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$User$GotNavBarMsg, navbarCmd),
					A4(
					$author$project$Api$get,
					$author$project$Endpoints$User$defaultEndpoints.gx(_List_Nil),
					session,
					$author$project$Pages$User$newsResponseDecoder,
					$author$project$Pages$User$GotNewsMsg)
				])));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Pages$Login$toSession = function (model) {
	return model.bu;
};
var $author$project$Main$toSession = function (model) {
	switch (model.$) {
		case 0:
			var session = model.a;
			return session;
		case 1:
			var loginModel = model.a;
			return $author$project$Pages$Login$toSession(loginModel);
		case 2:
			var cmsModel = model.a;
			return $author$project$Pages$Cms$toSession(cmsModel);
		default:
			var userModel = model.a;
			return $author$project$Pages$User$toSession(userModel);
	}
};
var $author$project$Main$updateWith = F3(
	function (toModel, toMsg, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Main$changeRouteTo = F2(
	function (url, model) {
		var router = A2(
			$author$project$Routers$TopRouter$fromUrl,
			url,
			$author$project$Main$toSession(model));
		switch (router.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var segment = router.a;
				return _Utils_Tuple2(
					model,
					$elm$browser$Browser$Navigation$load(segment));
			case 1:
				return A3(
					$author$project$Main$updateWith,
					$author$project$Main$Login,
					$author$project$Main$GotLoginMsg,
					$author$project$Pages$Login$init(
						$author$project$Main$toSession(model)));
			case 2:
				return A3(
					$author$project$Main$updateWith,
					$author$project$Main$Login,
					$author$project$Main$GotLoginMsg,
					$author$project$Pages$Login$init(
						$author$project$Main$toSession(model)));
			case 4:
				var _v1 = $author$project$Pages$Cms$init(
					$author$project$Main$toSession(model));
				var cmsModel = _v1.a;
				var cmsCmd = _v1.b;
				return function (_v2) {
					var model_ = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						model_,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCmsMsg, cmsCmd),
									cmd
								])));
				}(
					A3(
						$author$project$Main$updateWith,
						$author$project$Main$Cms,
						$author$project$Main$GotCmsMsg,
						A2($author$project$Pages$Cms$changeRouteTo, url, cmsModel)));
			default:
				var _v3 = $author$project$Pages$User$init(
					$author$project$Main$toSession(model));
				var userModel = _v3.a;
				var userCmd = _v3.b;
				return function (_v4) {
					var model_ = _v4.a;
					var cmd = _v4.b;
					return _Utils_Tuple2(
						model_,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Main$GotUserMsg, userCmd),
									cmd
								])));
				}(
					A3(
						$author$project$Main$updateWith,
						$author$project$Main$User,
						$author$project$Main$GotUserMsg,
						A2($author$project$Pages$User$changeRouteTo, url, userModel)));
		}
	});
var $author$project$Session$Config = F3(
	function (bindAddress, apiDestIp, apiDestPort) {
		return {hN: apiDestIp, hO: apiDestPort, kc: bindAddress};
	});
var $author$project$Main$configDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Session$Config,
	A2($elm$json$Json$Decode$field, 'bindAddress', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'apiDestIp', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'apiDestPort', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Session$NotLoggedIn = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Session$initSession = F2(
	function (key, config) {
		return A2($author$project$Session$NotLoggedIn, key, config);
	});
var $author$project$Session$LoggedIn = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Session$LoggedInAsAdmin = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Session$login = F5(
	function (session, t, isAdmin_, name, role) {
		switch (session.$) {
			case 0:
				return session;
			case 1:
				return session;
			default:
				var key = session.a;
				var config = session.b;
				return isAdmin_ ? A3($author$project$Session$LoggedInAsAdmin, key, t, config) : A5($author$project$Session$LoggedIn, key, t, name, role, config);
		}
	});
var $author$project$Main$UserInfo = F4(
	function (token, isAdmin, userName, role) {
		return {gJ: isAdmin, hd: role, hy: token, mD: userName};
	});
var $author$project$Main$userInfoDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Main$UserInfo,
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'isAdmin', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'userName', $elm$json$Json$Decode$string),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'role', $elm$json$Json$Decode$string)));
var $author$project$Main$init = F3(
	function (maybeEncodedFlags, url, key) {
		var defaultConfig = {hN: 'http://localhost', hO: 5001, kc: 'http://0.0.0.0'};
		var config = function () {
			if (!maybeEncodedFlags.$) {
				var encodedFlags = maybeEncodedFlags.a;
				var decodedConfig = A2($elm$json$Json$Decode$decodeValue, $author$project$Main$configDecoder, encodedFlags);
				if (!decodedConfig.$) {
					var conf = decodedConfig.a;
					return conf;
				} else {
					var err = decodedConfig.a;
					return defaultConfig;
				}
			} else {
				return defaultConfig;
			}
		}();
		var initialSession = function () {
			if (!maybeEncodedFlags.$) {
				var encodedFlags = maybeEncodedFlags.a;
				var maybeUserInfo = A2($elm$json$Json$Decode$decodeValue, $author$project$Main$userInfoDecoder, encodedFlags);
				if (!maybeUserInfo.$) {
					var userInfo = maybeUserInfo.a;
					return A5(
						$author$project$Session$login,
						A2($author$project$Session$initSession, key, config),
						userInfo.hy,
						userInfo.gJ,
						userInfo.mD,
						userInfo.hd);
				} else {
					var err = maybeUserInfo.a;
					return A2($author$project$Session$initSession, key, config);
				}
			} else {
				return A2($author$project$Session$initSession, key, config);
			}
		}();
		return A2(
			$author$project$Main$changeRouteTo,
			url,
			$author$project$Main$Redirect(initialSession));
	});
var $author$project$Main$OnLogout = {$: 6};
var $author$project$Main$OnUserInfoChanged = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Api$login = _Platform_incomingPort('login', $elm$json$Json$Decode$value);
var $author$project$Api$logout = _Platform_incomingPort(
	'logout',
	$elm$json$Json$Decode$null(0));
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Pages$User$GotTimeSeqMsg = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$AnimatingDown = 2;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$AnimatingUp = 4;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Closed = 2;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$ListenClicks = 1;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Open = 0;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {gW: oldTime, jf: request, jG: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.jf;
		var oldTime = _v0.gW;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.jG;
		var oldTime = _v0.gW;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {iY: pids, jG: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {ie: event, c7: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.iY,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.c7;
		var event = _v0.ie;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.jG);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $frandibar$elm_bootstrap$Bootstrap$Navbar$dropdownSubscriptions = F2(
	function (state, toMsg) {
		var dropdowns = state.a7;
		var updDropdowns = A2(
			$elm$core$Dict$map,
			F2(
				function (_v2, status) {
					switch (status) {
						case 0:
							return 1;
						case 1:
							return 2;
						default:
							return 2;
					}
				}),
			dropdowns);
		var updState = A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
			function (s) {
				return _Utils_update(
					s,
					{a7: updDropdowns});
			},
			state);
		var needsSub = function (s) {
			return A2(
				$elm$core$List$any,
				function (_v1) {
					var status = _v1.b;
					return _Utils_eq(status, s);
				},
				$elm$core$Dict$toList(dropdowns));
		};
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					needsSub(0) ? $elm$browser$Browser$Events$onAnimationFrame(
					function (_v0) {
						return toMsg(updState);
					}) : $elm$core$Platform$Sub$none,
					needsSub(1) ? $elm$browser$Browser$Events$onClick(
					$elm$json$Json$Decode$succeed(
						toMsg(updState))) : $elm$core$Platform$Sub$none
				]));
	});
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$subscriptions = F2(
	function (state, toMsg) {
		var visibility = state.B;
		var updState = function (v) {
			return A2(
				$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{B: v});
				},
				state);
		};
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					function () {
					switch (visibility) {
						case 1:
							return $elm$browser$Browser$Events$onAnimationFrame(
								function (_v1) {
									return toMsg(
										updState(2));
								});
						case 3:
							return $elm$browser$Browser$Events$onAnimationFrame(
								function (_v2) {
									return toMsg(
										updState(4));
								});
						default:
							return $elm$core$Platform$Sub$none;
					}
				}(),
					$elm$browser$Browser$Events$onResize(
					F2(
						function (x, _v3) {
							return toMsg(
								A2(
									$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
									function (s) {
										return _Utils_update(
											s,
											{
												dZ: $elm$core$Maybe$Just(x)
											});
									},
									state));
						})),
					A2($frandibar$elm_bootstrap$Bootstrap$Navbar$dropdownSubscriptions, state, toMsg)
				]));
	});
var $author$project$Components$NavBar$subscriptions = function (model) {
	return A2($frandibar$elm_bootstrap$Bootstrap$Navbar$subscriptions, model.dj, $author$project$Components$NavBar$NavbarMsg);
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$Tick = function (a) {
	return {$: 14, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {i7: processes, jH: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.i7;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.jH);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$Tick)
			]));
};
var $author$project$Pages$Users$Common$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$Common$Default$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Common$Default$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Common$Default$Tick)
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Click = {$: 1};
var $inkuzmin$elm_multiselect$Multiselect$Opened = 2;
var $inkuzmin$elm_multiselect$Multiselect$subscriptions = function (_v0) {
	var model = _v0;
	return (model.ac === 2) ? $elm$browser$Browser$Events$onClick(
		$elm$json$Json$Decode$succeed($inkuzmin$elm_multiselect$Multiselect$Click)) : $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Users$Common$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Common$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Common$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Dbi6Comnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$Delonghi$Hour$Tick = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Delonghi$Hour$Tick)
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Delonghi$TimeSeq$Tick)
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeStore = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$Tick = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Delonghi$TimeSeq2$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeStore,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.r))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Denon6Comnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$Tick = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Denon6Comnet$Wide$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Denon6Comnet$Wide$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$ElecComnet$Excel$OnChangeProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$Tick = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ElecComnet$Excel$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$ElecComnet$Excel$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$ElecComnet$Hour$subscriptions = $author$project$Pages$Users$Common$Default$subscriptions;
var $author$project$Pages$Users$ElecComnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ElecComnet$TimeSeq$Tick)
			]));
};
var $author$project$Pages$Users$FujiComnet$Day$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$FujiComnet$Day$Tick)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi24Comnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeStore = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$Tick = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi24Comnet$TenAM$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeStore,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.r))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi24Comnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$Tick)
			]));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi3Comnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hi3Comnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Hisense$Amazon$Tick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$subscriptions = function (_v0) {
	return A2($elm$time$Time$every, 60 * 1000, $author$project$Pages$Users$Hisense$Amazon$Tick);
};
var $author$project$Pages$Users$Hisense$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$Hisense$Day$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Common$Default$Tick)
			]));
};
var $author$project$Pages$Users$Hisense$Excel$OnChangeProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$Tick = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hisense$Excel$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$Hisense$Excel$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Hisense$Review$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Users$Hisense$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Hisense$TimeSeq$Tick)
			]));
};
var $author$project$Pages$Users$Hisense$TvHour$subscriptions = $author$project$Pages$Users$Common$Default$subscriptions;
var $author$project$Pages$Users$JvcComnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$JvcComnet$TimeSeq$Tick)
			]));
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnChangeProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$Tick = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$KinujoComnet$Excel$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$KinujoComnet$Excel$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$KinujoComnet$Fifteen$Tick)
			]));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 30000, $author$project$Pages$Users$MlnComnet$AutoReload$Tick)
			]));
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotDropdownMsg = function (a) {
	return {$: 28, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnChangeStore = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$Tick = function (a) {
	return {$: 22, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$ListenClicks = 1;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$updateStatus = F2(
	function (status, _v0) {
		var stateRec = _v0;
		return _Utils_update(
			stateRec,
			{ac: status});
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$subscriptions = F2(
	function (state, toMsg) {
		var status = state.ac;
		switch (status) {
			case 0:
				return $elm$browser$Browser$Events$onAnimationFrame(
					function (_v1) {
						return toMsg(
							A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 1, state));
					});
			case 1:
				return $elm$browser$Browser$Events$onClick(
					$elm$json$Json$Decode$succeed(
						toMsg(
							A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 2, state))));
			default:
				return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Pages$Users$MlnComnet$Chronological$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$MlnComnet$Chronological$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$MlnComnet$Chronological$OnChangeStore,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.r)),
				A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$subscriptions, model.dt, $author$project$Pages$Users$MlnComnet$Chronological$GotDropdownMsg)
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$Tick = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$MlnComnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$MlnComnet$HourYamada$Tick)
			]));
};
var $author$project$Pages$Users$MlnComnet$MailSetting$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$MlnComnet$Yahoo$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeStore = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$Tick = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ShaComnet$Day$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$ShaComnet$Day$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$ShaComnet$Day$OnChangeStore,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.r))
			]));
};
var $author$project$Pages$Users$ShaComnet$Hour$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ShaComnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ShaComnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$Tick = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ShaComnet$WeeklyProduct$Tick)
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$Tick = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$ShaComnet$Wide$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$ShaComnet$Wide$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$TescomComnet$Excel$OnChangeProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$Tick = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TescomComnet$Excel$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$TescomComnet$Excel$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$TescomComnet$Hour$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TescomComnet$Hour$Tick)
			]));
};
var $author$project$Pages$Users$Test$Fifteen$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Test$Fifteen$Tick)
			]));
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$To24tclComnet$Day$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$To24tclComnet$Day$Tick)
			]));
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$subscriptions = $author$project$Pages$Users$Common$TimeSeq$subscriptions;
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$To24tcmComnet$Csv$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Pages$Users$To24tcmComnet$Day$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$To24tcmComnet$Day$Tick)
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Product$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$To24tcmComnet$Product$Tick)
			]));
};
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$subscriptions = $author$project$Pages$Users$Common$TimeSeq$subscriptions;
var $author$project$Pages$Users$To24tcmKitchen$Day$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$To24tcmKitchen$Day$Tick)
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TsBat$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$TsBat$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$TsBat$Wide$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$Tick = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TsBat$Wide$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$TsBat$Wide$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$Tick = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TstComnet$TimeSeq$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$TstComnet$TimeSeq$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$Tick = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$TstComnet$Wide$Tick),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Pages$Users$TstComnet$Wide$OnChangeProduct,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.D))
			]));
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(_List_Nil);
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$Tick = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$time$Time$every, 1000, $author$project$Pages$Users$Tv24Comnet$Thirty$Tick)
			]));
};
var $author$project$Pages$User$subscriptions = function (_v0) {
	var userModel = _v0.a;
	var navModel = _v0.b;
	var userSub = function () {
		switch (userModel.$) {
			case 0:
				return $elm$core$Platform$Sub$none;
			case 1:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDefaultMsg,
					$author$project$Pages$Users$Common$Default$subscriptions(defaultModel));
			case 2:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTimeSeqMsg,
					$author$project$Pages$Users$Common$TimeSeq$subscriptions(defaultModel));
			case 9:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotChangeProductMsg,
					$author$project$Pages$Users$Common$ChangeProduct$subscriptions(defaultModel));
			case 10:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
					$author$project$Pages$Users$To24tcmComnet$TimeSeq$subscriptions(defaultModel));
			case 11:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
					$author$project$Pages$Users$To24tcmComnet$Csv$subscriptions(defaultModel));
			case 12:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmComnetDayMsg,
					$author$project$Pages$Users$To24tcmComnet$Day$subscriptions(defaultModel));
			case 13:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
					$author$project$Pages$Users$To24tcmComnet$ChangeProduct$subscriptions(defaultModel));
			case 14:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmComnetProductMsg,
					$author$project$Pages$Users$To24tcmComnet$Product$subscriptions(defaultModel));
			case 46:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tcmKitchenDayMsg,
					$author$project$Pages$Users$To24tcmKitchen$Day$subscriptions(defaultModel));
			case 15:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tclComnetChangeProductMsg,
					$author$project$Pages$Users$To24tclComnet$ChangeProduct$subscriptions(defaultModel));
			case 16:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tclComnetDayMsg,
					$author$project$Pages$Users$To24tclComnet$Day$subscriptions(defaultModel));
			case 17:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTo24tclComnetTimeSeqMsg,
					$author$project$Pages$Users$To24tclComnet$TimeSeq$subscriptions(defaultModel));
			case 18:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotJvcComnetTimeSeqMsg,
					$author$project$Pages$Users$JvcComnet$TimeSeq$subscriptions(defaultModel));
			case 3:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi24ComnetHourMsg,
					$author$project$Pages$Users$Hi24Comnet$Hour$subscriptions(defaultModel));
			case 4:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi24ComnetTimeSeqMsg,
					$author$project$Pages$Users$Hi24Comnet$TimeSeq$subscriptions(defaultModel));
			case 5:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi24ComnetWeeklyProductFifteenMsg,
					$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$subscriptions(defaultModel));
			case 6:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi24ComnetTenAMMsg,
					$author$project$Pages$Users$Hi24Comnet$TenAM$subscriptions(defaultModel));
			case 7:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi3ComnetHourMsg,
					$author$project$Pages$Users$Hi3Comnet$Hour$subscriptions(defaultModel));
			case 8:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHi3ComnetTimeSeqMsg,
					$author$project$Pages$Users$Hi3Comnet$TimeSeq$subscriptions(defaultModel));
			case 19:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseDayMsg,
					$author$project$Pages$Users$Hisense$Day$subscriptions(defaultModel));
			case 20:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseTvHourMsg,
					$author$project$Pages$Users$Hisense$TvHour$subscriptions(defaultModel));
			case 22:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseAmazonMsg,
					$author$project$Pages$Users$Hisense$Amazon$subscriptions(defaultModel));
			case 24:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseChangeProductMsg,
					$author$project$Pages$Users$Hisense$ChangeProduct$subscriptions(defaultModel));
			case 21:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseReviewMsg,
					$author$project$Pages$Users$Hisense$Review$subscriptions(defaultModel));
			case 23:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseTimeSeqMsg,
					$author$project$Pages$Users$Hisense$TimeSeq$subscriptions(defaultModel));
			case 25:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotHisenseExcelMsg,
					$author$project$Pages$Users$Hisense$Excel$subscriptions(defaultModel));
			case 26:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTescomComnetHourMsg,
					$author$project$Pages$Users$TescomComnet$Hour$subscriptions(defaultModel));
			case 27:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTescomComnetExcelMsg,
					$author$project$Pages$Users$TescomComnet$Excel$subscriptions(defaultModel));
			case 28:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTescomComnetChangeProductMsg,
					$author$project$Pages$Users$TescomComnet$ChangeProduct$subscriptions(defaultModel));
			case 29:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotCa6ComnetHourCsvMsg,
					$author$project$Pages$Users$Ca6Comnet$HourCsv$subscriptions(defaultModel));
			case 30:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotCa6ComnetWeeklyProductFifteenMsg,
					$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$subscriptions(defaultModel));
			case 31:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetChangeProductMsg,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$subscriptions(defaultModel));
			case 35:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetChangeMailMsg,
					$author$project$Pages$Users$MlnComnet$ChangeMail$subscriptions(defaultModel));
			case 38:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetChangePasswordMsg,
					$author$project$Pages$Users$MlnComnet$ChangePassword$subscriptions(defaultModel));
			case 32:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetChronologicalMsg,
					$author$project$Pages$Users$MlnComnet$Chronological$subscriptions(defaultModel));
			case 33:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetHourMsg,
					$author$project$Pages$Users$MlnComnet$Hour$subscriptions(defaultModel));
			case 34:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetHourYamadaMsg,
					$author$project$Pages$Users$MlnComnet$HourYamada$subscriptions(defaultModel));
			case 36:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetAutoReloadMsg,
					$author$project$Pages$Users$MlnComnet$AutoReload$subscriptions(defaultModel));
			case 37:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetMailSettingMsg,
					$author$project$Pages$Users$MlnComnet$MailSetting$subscriptions(defaultModel));
			case 39:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotMlnComnetYahooMsg,
					$author$project$Pages$Users$MlnComnet$Yahoo$subscriptions(defaultModel));
			case 40:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTv24ComnetChangeProductMsg,
					$author$project$Pages$Users$Tv24Comnet$ChangeProduct$subscriptions(defaultModel));
			case 41:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTv24ComnetThirtyMsg,
					$author$project$Pages$Users$Tv24Comnet$Thirty$subscriptions(defaultModel));
			case 42:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotElecComnetHourMsg,
					$author$project$Pages$Users$ElecComnet$Hour$subscriptions(defaultModel));
			case 43:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotElecComnetExcelMsg,
					$author$project$Pages$Users$ElecComnet$Excel$subscriptions(defaultModel));
			case 44:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotElecComnetChangeProductMsg,
					$author$project$Pages$Users$ElecComnet$ChangeProduct$subscriptions(defaultModel));
			case 45:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotElecComnetTimeSeqMsg,
					$author$project$Pages$Users$ElecComnet$TimeSeq$subscriptions(defaultModel));
			case 47:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDelonghiHourMsg,
					$author$project$Pages$Users$Delonghi$Hour$subscriptions(defaultModel));
			case 48:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDelonghiChangeProductMsg,
					$author$project$Pages$Users$Delonghi$ChangeProduct$subscriptions(defaultModel));
			case 49:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDelonghiTimeSeqMsg,
					$author$project$Pages$Users$Delonghi$TimeSeq$subscriptions(defaultModel));
			case 50:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDelonghiTimeSeq2Msg,
					$author$project$Pages$Users$Delonghi$TimeSeq2$subscriptions(defaultModel));
			case 51:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotKinujoComnetExcelMsg,
					$author$project$Pages$Users$KinujoComnet$Excel$subscriptions(defaultModel));
			case 52:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotKinujoComnetChangeProductMsg,
					$author$project$Pages$Users$KinujoComnet$ChangeProduct$subscriptions(defaultModel));
			case 53:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotKinujoComnetFifteeenMsg,
					$author$project$Pages$Users$KinujoComnet$Fifteen$subscriptions(defaultModel));
			case 54:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetWideMsg,
					$author$project$Pages$Users$ShaComnet$Wide$subscriptions(defaultModel));
			case 55:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetHourMsg,
					$author$project$Pages$Users$ShaComnet$Hour$subscriptions(defaultModel));
			case 56:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetTimeSeqMsg,
					$author$project$Pages$Users$ShaComnet$TimeSeq$subscriptions(defaultModel));
			case 57:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetWeeklyProductMsg,
					$author$project$Pages$Users$ShaComnet$WeeklyProduct$subscriptions(defaultModel));
			case 58:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetDayMsg,
					$author$project$Pages$Users$ShaComnet$Day$subscriptions(defaultModel));
			case 59:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotShaComnetChangeProductMsg,
					$author$project$Pages$Users$ShaComnet$ChangeProduct$subscriptions(defaultModel));
			case 60:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTstComnetTimeSeqMsg,
					$author$project$Pages$Users$TstComnet$TimeSeq$subscriptions(defaultModel));
			case 61:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTstComnetWideMsg,
					$author$project$Pages$Users$TstComnet$Wide$subscriptions(defaultModel));
			case 62:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDbi6ComnetHourMsg,
					$author$project$Pages$Users$Dbi6Comnet$Hour$subscriptions(defaultModel));
			case 63:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDbi6ComnetTimeSeqMsg,
					$author$project$Pages$Users$Dbi6Comnet$TimeSeq$subscriptions(defaultModel));
			case 65:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDenon6ComnetWideMsg,
					$author$project$Pages$Users$Denon6Comnet$Wide$subscriptions(defaultModel));
			case 64:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotDenon6ComnetTimeSeqMsg,
					$author$project$Pages$Users$Denon6Comnet$TimeSeq$subscriptions(defaultModel));
			case 74:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTsBatWideMsg,
					$author$project$Pages$Users$TsBat$Wide$subscriptions(defaultModel));
			case 73:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTsBatTimeSeqMsg,
					$author$project$Pages$Users$TsBat$TimeSeq$subscriptions(defaultModel));
			case 75:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotFujiComnetDayMsg,
					$author$project$Pages$Users$FujiComnet$Day$subscriptions(defaultModel));
			case 76:
				var defaultModel = userModel.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Pages$User$GotTestFifteeenMsg,
					$author$project$Pages$Users$Test$Fifteen$subscriptions(defaultModel));
			default:
				return $elm$core$Platform$Sub$none;
		}
	}();
	var navSub = A2(
		$elm$core$Platform$Sub$map,
		$author$project$Pages$User$GotNavBarMsg,
		$author$project$Components$NavBar$subscriptions(navModel));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[userSub, navSub]));
};
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Api$login(
				A2(
					$elm$core$Basics$composeR,
					$elm$json$Json$Decode$decodeValue($author$project$Main$userInfoDecoder),
					$author$project$Main$OnUserInfoChanged)),
				$author$project$Api$logout(
				function (_v0) {
					return $author$project$Main$OnLogout;
				}),
				function () {
				if (model.$ === 3) {
					var userModel = model.a;
					return A2(
						$elm$core$Platform$Sub$map,
						$author$project$Main$GotUserMsg,
						$author$project$Pages$User$subscriptions(userModel));
				} else {
					return $elm$core$Platform$Sub$none;
				}
			}()
			]));
};
var $author$project$Session$navKey = function (session) {
	switch (session.$) {
		case 0:
			var key = session.a;
			return key;
		case 1:
			var key = session.a;
			return key;
		default:
			var key = session.a;
			return key;
	}
};
var $author$project$Session$logout = function (session) {
	return A2(
		$author$project$Session$initSession,
		$author$project$Session$navKey(session),
		$author$project$Session$getConfig(session));
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Api$removeUserInfo = _Platform_outgoingPort(
	'removeUserInfo',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$NavBar$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var state = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{dj: state}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(
				model,
				$author$project$Api$removeUserInfo(0));
		}
	});
var $author$project$Pages$Cms$Others$EditMailAddress$CompletedAddPermalinkGroup = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$Others$EditMailAddress$CompletedDeletePermalinkGroup = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Others$EditMailAddress$CompletedUpdatePermalinkGroup = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Utils$Helpers$errToStr = function (err) {
	switch (err.$) {
		case 1:
			return 'RequestTimeout';
		case 2:
			return 'Network is Unreachable';
		case 3:
			var code = err.a;
			return 'Error Status Code: ' + $elm$core$String$fromInt(code);
		case 4:
			var body = err.a;
			return 'Error: ' + body;
		default:
			var url = err.a;
			return 'Malformed url: ' + url;
	}
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Api$POST = 1;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Api$post = F5(
	function (path, session, body, decoder, msg) {
		var apiConf = $author$project$Session$getConfig(session);
		var config = {
			cK: $elm$http$Http$jsonBody(body),
			cU: A2($author$project$Api$expectJson, msg, decoder),
			cY: $author$project$Api$makeAuthHeader(session),
			dg: $author$project$Api$decodeHTTPMethod(1),
			dK: $elm$core$Maybe$Nothing,
			dN: $elm$core$Maybe$Nothing,
			dS: A3(
				$author$project$Api$makeEndpoint,
				apiConf,
				_List_fromArray(
					[path]),
				_List_Nil)
		};
		return $elm$http$Http$request(config);
	});
var $author$project$Pages$Cms$Others$EditMailAddress$CompletedListUserAddress = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$Others$EditMailAddress$UserAddressResponse = function (permalinkGroups) {
	return {A: permalinkGroups};
};
var $author$project$Pages$Cms$Others$EditMailAddress$userAddressDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Pages$Cms$Others$EditMailAddress$PermalinkGroup,
		A2($elm$json$Json$Decode$field, 'addr_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'mail_address', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Pages$Cms$Others$EditMailAddress$listMailAddressResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Others$EditMailAddress$UserAddressResponse,
	A2($elm$json$Json$Decode$field, 'mail_address', $author$project$Pages$Cms$Others$EditMailAddress$userAddressDecoder));
var $author$project$Pages$Cms$Others$EditMailAddress$postListAddress = F2(
	function (maybeUser, model) {
		if (maybeUser.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			var userId = maybeUser.a;
			return A5(
				$author$project$Api$post,
				$author$project$Endpoints$Cms$editMailAddressEndpoints.ld,
				model.bu,
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user_id',
							$elm$json$Json$Encode$int(userId))
						])),
				$author$project$Pages$Cms$Others$EditMailAddress$listMailAddressResponseDecoder,
				$author$project$Pages$Cms$Others$EditMailAddress$CompletedListUserAddress);
		}
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm_community$list_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $elm_community$list_extra$List$Extra$setIf = F3(
	function (predicate, replacement, list) {
		return A3(
			$elm_community$list_extra$List$Extra$updateIf,
			predicate,
			$elm$core$Basics$always(replacement),
			list);
	});
var $author$project$Pages$Cms$Others$EditMailAddress$updatePermalinkGroups = F2(
	function (permalinkGroup, permalinkGroups) {
		return A3(
			$elm_community$list_extra$List$Extra$setIf,
			function (pg) {
				return _Utils_eq(pg.R, permalinkGroup.R);
			},
			permalinkGroup,
			permalinkGroups);
	});
var $author$project$Pages$Cms$Others$EditMailAddress$UpdateResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Others$EditMailAddress$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Others$EditMailAddress$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Others$EditMailAddress$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, A: initResponse.A, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				if (!msg.a.$) {
					var userAddressResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, A: userAddressResponse.A}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.b.$) {
					var permalinkGroupId = msg.a;
					var updateResponse = msg.b.a;
					var permalinkGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.R, permalinkGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.A);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, A: permalinkGroups}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				if (!msg.a.$) {
					var updateResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, cc: $author$project$Pages$Cms$Others$EditMailAddress$initNewPermalinkGroup}),
						A2($author$project$Pages$Cms$Others$EditMailAddress$postListAddress, model.hk, model));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.b.$) {
					var permalinkGroupId = msg.a;
					var updateResponse = msg.b.a;
					var permalinkGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.R, permalinkGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.A);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, A: permalinkGroups}),
						A2($author$project$Pages$Cms$Others$EditMailAddress$postListAddress, model.hk, model));
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				var permalinkGroup = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'permalink_group_id',
							$elm$json$Json$Encode$int(permalinkGroup.R)),
							_Utils_Tuple2(
							'permalink_group_name',
							$elm$json$Json$Encode$string(permalinkGroup.aV))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editMailAddressEndpoints.ad,
						model.bu,
						body,
						$author$project$Pages$Cms$Others$EditMailAddress$updateResponseDecoder,
						$author$project$Pages$Cms$Others$EditMailAddress$CompletedUpdatePermalinkGroup(permalinkGroup.R)));
			case 10:
				var newPermalinkGroup = msg.a;
				var _v1 = model.hk;
				if (_v1.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var selectedUser = _v1.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'mail_address',
								$elm$json$Json$Encode$string(newPermalinkGroup.aV)),
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(selectedUser))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$Cms$editMailAddressEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Others$EditMailAddress$updateResponseDecoder, $author$project$Pages$Cms$Others$EditMailAddress$CompletedAddPermalinkGroup));
				}
			case 9:
				var permalinkGroupId = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'permalink_group_id',
							$elm$json$Json$Encode$int(permalinkGroupId))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editMailAddressEndpoints.bS,
						model.bu,
						body,
						$author$project$Pages$Cms$Others$EditMailAddress$updateResponseDecoder,
						$author$project$Pages$Cms$Others$EditMailAddress$CompletedDeletePermalinkGroup(permalinkGroupId)));
			case 2:
				var permalinkGroupId = msg.a;
				var permalinkGroupName = msg.b;
				var permalinkGroup = {at: true, R: permalinkGroupId, aV: permalinkGroupName};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							A: A2($author$project$Pages$Cms$Others$EditMailAddress$updatePermalinkGroups, permalinkGroup, model.A)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var selectedUserStr = msg.a;
				var maybeSelectedUser = $elm$core$String$toInt(selectedUserStr);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hk: maybeSelectedUser}),
					A2($author$project$Pages$Cms$Others$EditMailAddress$postListAddress, maybeSelectedUser, model));
			default:
				var permalinkGroupName = msg.a;
				var newPermalinkGroup = model.cc;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cc: _Utils_update(
								newPermalinkGroup,
								{at: true, aV: permalinkGroupName})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Others$GetAccessCount$SendRequestSent = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Others$GetAccessCount$postListAddress = F2(
	function (maybeUser, model) {
		return $elm$core$Platform$Cmd$none;
	});
var $author$project$Pages$Cms$Others$GetAccessCount$SendResponse = F3(
	function (user_id, access_counts_each_day, access_count_total) {
		return {gc: access_count_total, gd: access_counts_each_day, hC: user_id};
	});
var $author$project$Pages$Cms$Others$GetAccessCount$AccessCountsEachDay = F2(
	function (access_count, access_date) {
		return {cH: access_count, hH: access_date};
	});
var $author$project$Pages$Cms$Others$GetAccessCount$accessCountsEachDayDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Others$GetAccessCount$AccessCountsEachDay,
	A2(
		$elm$json$Json$Decode$field,
		'access_count',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'access_date',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Others$GetAccessCount$AccessCountsTotal = function (access_count) {
	return {cH: access_count};
};
var $author$project$Pages$Cms$Others$GetAccessCount$accessCountsTotalDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Others$GetAccessCount$AccessCountsTotal,
	A2(
		$elm$json$Json$Decode$field,
		'access_count',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)));
var $author$project$Pages$Cms$Others$GetAccessCount$sendResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Cms$Others$GetAccessCount$SendResponse,
	A2(
		$elm$json$Json$Decode$field,
		'user_id',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'access_counts_each_day',
		$elm$json$Json$Decode$list($author$project$Pages$Cms$Others$GetAccessCount$accessCountsEachDayDecoder)),
	A2($elm$json$Json$Decode$field, 'access_count_total', $author$project$Pages$Cms$Others$GetAccessCount$accessCountsTotalDecoder));
var $author$project$Pages$Cms$Others$GetAccessCount$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b2: false, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var selectedUserStr = msg.a;
				var maybeSelectedUser = $elm$core$String$toInt(selectedUserStr);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hk: maybeSelectedUser}),
					A2($author$project$Pages$Cms$Others$GetAccessCount$postListAddress, maybeSelectedUser, model));
			case 2:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: $elm$core$Maybe$Just(date)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dv: $elm$core$Maybe$Just(date)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var maybeSelectedUser = model.hk;
				var maybeSelectedDateTo = model.dv;
				var maybeSelectedDateFrom = model.du;
				var _v1 = _Utils_Tuple3(maybeSelectedUser, maybeSelectedDateFrom, maybeSelectedDateTo);
				if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
					var selectedUser = _v1.a.a;
					var selectedDateFrom = _v1.b.a;
					var selectedDateTo = _v1.c.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5(
									$author$project$Api$post,
									$author$project$Endpoints$Cms$getAccessCountEndpoints.hm,
									model.bu,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'user_id',
												$elm$json$Json$Encode$int(selectedUser)),
												_Utils_Tuple2(
												'date_from',
												$elm$json$Json$Encode$string(selectedDateFrom)),
												_Utils_Tuple2(
												'date_to',
												$elm$json$Json$Encode$string(selectedDateTo))
											])),
									$author$project$Pages$Cms$Others$GetAccessCount$sendResponseDecoder,
									$author$project$Pages$Cms$Others$GetAccessCount$SendRequestSent)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just('ユーザー、日付を選択してください')
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					var submitResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bE: submitResponse, ar: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err))
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Cms$Products$AddProductConfig$CategoryRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$AddProductConfig$InsertionRequestSent = function (a) {
	return {$: 9, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Pages$Cms$Products$AddProductConfig$CategoryResponse = F2(
	function (bigCategories, smallCategories) {
		return {hR: bigCategories, jy: smallCategories};
	});
var $author$project$Pages$Cms$Products$AddProductConfig$categoryResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$AddProductConfig$CategoryResponse,
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder));
var $author$project$Pages$Cms$Products$AddProductConfig$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ca: initResponse.ca, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var object = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'jan',
							$elm$json$Json$Encode$string(model.bF)),
							_Utils_Tuple2(
							'kataban',
							$elm$json$Json$Encode$string(model.bG)),
							_Utils_Tuple2(
							'maker',
							$elm$json$Json$Encode$int(
								A2($elm$core$Maybe$withDefault, -1, model.dw))),
							_Utils_Tuple2(
							'big_category',
							$elm$json$Json$Encode$int(
								A2($elm$core$Maybe$withDefault, -1, model.he))),
							_Utils_Tuple2(
							'small_category',
							$elm$json$Json$Encode$int(
								A2($elm$core$Maybe$withDefault, -1, model.fH)))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: '', bG: ''}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$addProductConfigEndpoints.ae, model.bu, object, $author$project$Pages$Cms$Products$AddProductConfig$initResponseDecoder, $author$project$Pages$Cms$Products$AddProductConfig$InsertionRequestSent));
			case 9:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: input}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bG: input}),
					$elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var categoryResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: categoryResponse.hR, gz: true, c5: false, jy: categoryResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var maybeUserId = msg.a;
				var newModel = _Utils_update(
					model,
					{fJ: maybeUserId});
				if (maybeUserId.$ === 1) {
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var userId = maybeUserId.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(userId))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$addProductConfigEndpoints.gj, model.bu, body, $author$project$Pages$Cms$Products$AddProductConfig$categoryResponseDecoder, $author$project$Pages$Cms$Products$AddProductConfig$CategoryRequestSent)
								])));
				}
		}
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$ProductsRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateProductRequestSent = function (a) {
	return {$: 2, a: a};
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$DragPorts$dragover = _Platform_outgoingPort(
	'dragover',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'dropEffect',
					$elm$json$Json$Encode$string($.kF)),
					_Utils_Tuple2(
					'event',
					$elm$core$Basics$identity($.ie))
				]));
	});
var $author$project$DragPorts$dragstart = _Platform_outgoingPort(
	'dragstart',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'effectAllowed',
					$elm$json$Json$Encode$string($.kG)),
					_Utils_Tuple2(
					'event',
					$elm$core$Basics$identity($.ie))
				]));
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$getIntervalSum = function (products) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function (p) {
				return (60 / p.$9) | 0;
			},
			products));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$dropEffectToString = function (dropEffect) {
	switch (dropEffect) {
		case 0:
			return 'none';
		case 1:
			return 'move';
		case 2:
			return 'copy';
		default:
			return 'link';
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData = F2(
	function (dropEffect, value) {
		return {
			kF: $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$dropEffectToString(dropEffect),
			ie: value
		};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$ProductsResponse = F4(
	function (products, bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$Product = F9(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus, display_intervals) {
		return {j9: basePoint, ka: basePrice, kC: display_intervals, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$productsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'display_intervals',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			$elm$json$Json$Decode$succeed(0),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(0),
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Products$ChangeDisplayInterval$Product)))))))))));
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$productsResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Cms$Products$ChangeDisplayInterval$ProductsResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Cms$Products$ChangeDisplayInterval$productsDecoder),
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$effectAllowedToString = function (eff) {
	var _v0 = _Utils_Tuple3(eff.lr, eff.kq, eff.lc);
	if (!_v0.a) {
		if (!_v0.b) {
			if (!_v0.c) {
				return 'none';
			} else {
				return 'link';
			}
		} else {
			if (!_v0.c) {
				return 'copy';
			} else {
				return 'copyLink';
			}
		}
	} else {
		if (!_v0.b) {
			if (!_v0.c) {
				return 'move';
			} else {
				return 'linkMove';
			}
		} else {
			if (!_v0.c) {
				return 'copyMove';
			} else {
				return 'all';
			}
		}
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData = F2(
	function (effectAllowed, value) {
		return {
			kG: $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$effectAllowedToString(effectAllowed),
			ie: value
		};
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $elm_community$list_extra$List$Extra$uncons = function (list) {
	if (!list.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var first = list.a;
		var rest = list.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(first, rest));
	}
};
var $elm_community$list_extra$List$Extra$swapAt = F3(
	function (index1, index2, l) {
		swapAt:
		while (true) {
			if (_Utils_eq(index1, index2) || (index1 < 0)) {
				return l;
			} else {
				if (_Utils_cmp(index1, index2) > 0) {
					var $temp$index1 = index2,
						$temp$index2 = index1,
						$temp$l = l;
					index1 = $temp$index1;
					index2 = $temp$index2;
					l = $temp$l;
					continue swapAt;
				} else {
					var _v0 = A2($elm_community$list_extra$List$Extra$splitAt, index1, l);
					var part1 = _v0.a;
					var tail1 = _v0.b;
					var _v1 = A2($elm_community$list_extra$List$Extra$splitAt, index2 - index1, tail1);
					var head2 = _v1.a;
					var tail2 = _v1.b;
					var _v2 = _Utils_Tuple2(
						$elm_community$list_extra$List$Extra$uncons(head2),
						$elm_community$list_extra$List$Extra$uncons(tail2));
					if ((!_v2.a.$) && (!_v2.b.$)) {
						var _v3 = _v2.a.a;
						var value1 = _v3.a;
						var part2 = _v3.b;
						var _v4 = _v2.b.a;
						var value2 = _v4.a;
						var part3 = _v4.b;
						return $elm$core$List$concat(
							_List_fromArray(
								[
									part1,
									A2($elm$core$List$cons, value2, part2),
									A2($elm$core$List$cons, value1, part3)
								]));
					} else {
						return l;
					}
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var productResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: productResponse.hR, gz: true, c5: false, ca: productResponse.ca, ja: productResponse.ja, jy: productResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							$9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Cms$Products$ChangeDisplayInterval$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 7:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 14:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 17:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var maybeUserId = msg.a;
				var newModel = _Utils_update(
					model,
					{fJ: maybeUserId});
				if (maybeUserId.$ === 1) {
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var userId = maybeUserId.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(userId))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayIntervalEndpoints.ja, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayInterval$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayInterval$ProductsRequestSent)
								])));
				}
			case 8:
				var index = msg.a;
				if ($author$project$Pages$Cms$Products$ChangeDisplayInterval$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
					if (!maybeProduct.$) {
						var product = maybeProduct.a;
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'user_id',
									$elm$json$Json$Encode$int(
										A2($elm$core$Maybe$withDefault, -1, model.fJ))),
									_Utils_Tuple2(
									'product',
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'product_id',
												$elm$json$Json$Encode$string(product.i8)),
												_Utils_Tuple2(
												'base_price',
												$elm$json$Json$Encode$int(product.ka)),
												_Utils_Tuple2(
												'base_point',
												$elm$json$Json$Encode$int(product.j9)),
												_Utils_Tuple2(
												'product_order',
												$elm$json$Json$Encode$int(index + 1)),
												_Utils_Tuple2(
												'yodobashi_base_price_flag',
												$elm$json$Json$Encode$bool(product.mM)),
												_Utils_Tuple2(
												'get_interval',
												$elm$json$Json$Encode$int(product.$9)),
												_Utils_Tuple2(
												'display_intervals',
												A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
											])))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{J: true}),
							A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayIntervalEndpoints.ad, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProductResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateProductRequestSent));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 9:
				if ($author$project$Pages$Cms$Products$ChangeDisplayInterval$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.fJ))),
								_Utils_Tuple2(
								'products',
								A2(
									$elm$json$Json$Encode$list,
									function (product) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'product_id',
													$elm$json$Json$Encode$string(product.i8)),
													_Utils_Tuple2(
													'base_price',
													$elm$json$Json$Encode$int(product.ka)),
													_Utils_Tuple2(
													'base_point',
													$elm$json$Json$Encode$int(product.j9)),
													_Utils_Tuple2(
													'product_order',
													$elm$json$Json$Encode$int(product.lU)),
													_Utils_Tuple2(
													'yodobashi_base_price_flag',
													$elm$json$Json$Encode$bool(product.mM)),
													_Utils_Tuple2(
													'get_interval',
													$elm$json$Json$Encode$int(product.$9)),
													_Utils_Tuple2(
													'display_intervals',
													A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
												]));
									},
									model.ja))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayIntervalEndpoints.hB, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayInterval$updateProductResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateProductRequestSent));
				}
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var index = msg.a;
				var display_interval = msg.b;
				var enableInterval = msg.c;
				var products = A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, product) {
							return _Utils_eq(i, index) ? (enableInterval ? _Utils_update(
								product,
								{
									kC: A2($elm$core$List$cons, display_interval, product.kC)
								}) : _Utils_update(
								product,
								{
									kC: A2(
										$elm$core$List$filter,
										function (di) {
											return !_Utils_eq(di, display_interval);
										},
										product.kC)
								})) : product;
						}),
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ja: products}),
					$elm$core$Platform$Cmd$none);
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$UpdateProductRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsResponse = F4(
	function (products, bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$Product = F9(
	function (productId, productName, basePrice, basePoint, productOrder, yodobashiFlag, get_interval, dragStatus, display_intervals) {
		return {j9: basePoint, ka: basePrice, kC: display_intervals, kE: dragStatus, $9: get_interval, i8: productId, lT: productName, lU: productOrder, mM: yodobashiFlag};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'display_intervals',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			$elm$json$Json$Decode$succeed(0),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(0),
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Products$ChangeDisplayOrder$Product)))))))))));
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsDecoder),
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$ChangeDisplayOrder$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var productResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: productResponse.hR, gz: true, c5: false, ca: productResponse.ca, ja: productResponse.ja, jy: productResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Cms$Products$ChangeDisplayOrder$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 5:
				var maybeUserId = msg.a;
				var newModel = _Utils_update(
					model,
					{fJ: maybeUserId});
				if (maybeUserId.$ === 1) {
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var userId = maybeUserId.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(userId)),
								_Utils_Tuple2(
								'big_category_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.he)))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayOrderEndpoints.ja, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsRequestSent)
								])));
				}
			case 6:
				var maybeBigCategoryId = msg.a;
				var newModel = _Utils_update(
					model,
					{he: maybeBigCategoryId});
				if (maybeBigCategoryId.$ === 1) {
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.fJ))),
								_Utils_Tuple2(
								'big_category_id',
								$elm$json$Json$Encode$int(-1))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayOrderEndpoints.ja, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsRequestSent)
								])));
				} else {
					var bigCategoryId = maybeBigCategoryId.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.fJ))),
								_Utils_Tuple2(
								'big_category_id',
								$elm$json$Json$Encode$int(bigCategoryId))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayOrderEndpoints.ja, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayOrder$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayOrder$ProductsRequestSent)
								])));
				}
			case 7:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user_id',
							$elm$json$Json$Encode$int(
								A2($elm$core$Maybe$withDefault, -1, model.fJ))),
							_Utils_Tuple2(
							'products',
							A2(
								$elm$json$Json$Encode$list,
								function (product) {
									return $elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'product_id',
												$elm$json$Json$Encode$string(product.i8)),
												_Utils_Tuple2(
												'base_price',
												$elm$json$Json$Encode$int(product.ka)),
												_Utils_Tuple2(
												'base_point',
												$elm$json$Json$Encode$int(product.j9)),
												_Utils_Tuple2(
												'product_order',
												$elm$json$Json$Encode$int(product.lU)),
												_Utils_Tuple2(
												'yodobashi_base_price_flag',
												$elm$json$Json$Encode$bool(product.mM)),
												_Utils_Tuple2(
												'get_interval',
												$elm$json$Json$Encode$int(product.$9)),
												_Utils_Tuple2(
												'display_intervals',
												A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
											]));
								},
								model.ja))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$changeDisplayOrderEndpoints.hB, model.bu, body, $author$project$Pages$Cms$Products$ChangeDisplayOrder$updateProductResponseDecoder, $author$project$Pages$Cms$Products$ChangeDisplayOrder$UpdateProductRequestSent));
		}
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$getIntervalSum = function (products) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function (p) {
				return (60 / p.$9) | 0;
			},
			products));
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsResponse = F4(
	function (products, bigCategories, smallCategories, makers) {
		return {hR: bigCategories, ca: makers, ja: products, jy: smallCategories};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$Product = function (productId) {
	return function (productName) {
		return function (basePrice) {
			return function (basePoint) {
				return function (productOrder) {
					return function (getFlag) {
						return function (displayFlag) {
							return function (yodobashiFlag) {
								return function (week30Flag) {
									return function (get_interval) {
										return function (dragStatus) {
											return function (display_intervals) {
												return {j9: basePoint, ka: basePrice, aI: displayFlag, kC: display_intervals, kE: dragStatus, aP: getFlag, $9: get_interval, i8: productId, lT: productName, lU: productOrder, cG: week30Flag, mM: yodobashiFlag};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$productsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'display_intervals',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			$elm$json$Json$Decode$succeed(0),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(0),
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int))),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'week30_flag', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'display_enabled', $elm$json$Json$Decode$bool),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'get_enabled', $elm$json$Json$Decode$bool),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_order', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
													$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Products$ChangeUserProduct$Product))))))))))))));
var $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Pages$Cms$Products$ChangeUserProduct$ProductsResponse,
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Cms$Products$ChangeUserProduct$productsDecoder),
	A2($elm$json$Json$Decode$field, 'big_categories', $author$project$Utils$Decoders$bigCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'small_categories', $author$project$Utils$Decoders$smallCategoriesDecoder),
	A2($elm$json$Json$Decode$field, 'makers', $author$project$Utils$Decoders$makersDecoder));
var $author$project$Pages$Cms$Products$ChangeUserProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (_Utils_eq(ind, -1)) {
			var newProduct = f(model.Y);
			return _Utils_update(
				model,
				{Y: newProduct});
		} else {
			if (!maybeCorrespondingProduct.$) {
				var product = maybeCorrespondingProduct.a;
				var newProduct = f(product);
				var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				return _Utils_update(
					model,
					{ja: newProducts});
			} else {
				return model;
			}
		}
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var productResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Y: _Utils_update(
									$author$project$Pages$Cms$Products$ChangeUserProduct$initAddProduct,
									{j9: model.cR}),
								hR: productResponse.hR,
								gz: true,
								c5: false,
								ca: productResponse.ca,
								ja: productResponse.ja,
								jy: productResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Y: _Utils_update(
									$author$project$Pages$Cms$Products$ChangeUserProduct$initAddProduct,
									{j9: model.cR}),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var productId = msg.a;
				var addProduct = model.Y;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Y: _Utils_update(
								addProduct,
								{i8: productId})
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							lU: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 7:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							$9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Cms$Products$ChangeUserProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 9:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{aP: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 10:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{aI: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 11:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$ChangeUserProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 20:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 21:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 23:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var maybeUserId = msg.a;
				var newDefaultPoint = _Utils_eq(
					maybeUserId,
					$elm$core$Maybe$Just(31)) ? 10 : 0;
				var newModel = _Utils_update(
					model,
					{
						Y: _Utils_update(
							$author$project$Pages$Cms$Products$ChangeUserProduct$initAddProduct,
							{j9: newDefaultPoint}),
						cR: newDefaultPoint,
						fJ: maybeUserId
					});
				if (maybeUserId.$ === 1) {
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var userId = maybeUserId.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(userId))
							]));
					return _Utils_Tuple2(
						newModel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A5($author$project$Api$post, $author$project$Endpoints$Cms$changeUserProductEndpoints.ja, model.bu, body, $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent)
								])));
				}
			case 13:
				var product = model.Y;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user_id',
							$elm$json$Json$Encode$int(
								A2($elm$core$Maybe$withDefault, -1, model.fJ))),
							_Utils_Tuple2(
							'product',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'product_id',
										$elm$json$Json$Encode$string(product.i8)),
										_Utils_Tuple2(
										'base_price',
										$elm$json$Json$Encode$int(product.ka)),
										_Utils_Tuple2(
										'base_point',
										$elm$json$Json$Encode$int(product.j9)),
										_Utils_Tuple2(
										'product_order',
										$elm$json$Json$Encode$int(0)),
										_Utils_Tuple2(
										'get_enabled',
										$elm$json$Json$Encode$bool(product.aP)),
										_Utils_Tuple2(
										'display_enabled',
										$elm$json$Json$Encode$bool(product.aI)),
										_Utils_Tuple2(
										'yodobashi_base_price_flag',
										$elm$json$Json$Encode$bool(product.mM)),
										_Utils_Tuple2(
										'week30_flag',
										$elm$json$Json$Encode$bool(product.cG)),
										_Utils_Tuple2(
										'get_interval',
										$elm$json$Json$Encode$int(product.$9)),
										_Utils_Tuple2(
										'display_intervals',
										A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
									])))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: false}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$changeUserProductEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent));
			case 12:
				var index = msg.a;
				if ($author$project$Pages$Cms$Products$ChangeUserProduct$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
					if (!maybeProduct.$) {
						var product = maybeProduct.a;
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'user_id',
									$elm$json$Json$Encode$int(
										A2($elm$core$Maybe$withDefault, -1, model.fJ))),
									_Utils_Tuple2(
									'product',
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'product_id',
												$elm$json$Json$Encode$string(product.i8)),
												_Utils_Tuple2(
												'base_price',
												$elm$json$Json$Encode$int(product.ka)),
												_Utils_Tuple2(
												'base_point',
												$elm$json$Json$Encode$int(product.j9)),
												_Utils_Tuple2(
												'product_order',
												$elm$json$Json$Encode$int(product.lU)),
												_Utils_Tuple2(
												'get_enabled',
												$elm$json$Json$Encode$bool(product.aP)),
												_Utils_Tuple2(
												'display_enabled',
												$elm$json$Json$Encode$bool(product.aI)),
												_Utils_Tuple2(
												'yodobashi_base_price_flag',
												$elm$json$Json$Encode$bool(product.mM)),
												_Utils_Tuple2(
												'week30_flag',
												$elm$json$Json$Encode$bool(product.cG)),
												_Utils_Tuple2(
												'get_interval',
												$elm$json$Json$Encode$int(product.$9)),
												_Utils_Tuple2(
												'display_intervals',
												A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
											])))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{J: false}),
							A5($author$project$Api$post, $author$project$Endpoints$Cms$changeUserProductEndpoints.ad, model.bu, body, $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 15:
				if ($author$project$Pages$Cms$Products$ChangeUserProduct$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.fJ))),
								_Utils_Tuple2(
								'products',
								A2(
									$elm$json$Json$Encode$list,
									function (product) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'product_id',
													$elm$json$Json$Encode$string(product.i8)),
													_Utils_Tuple2(
													'base_price',
													$elm$json$Json$Encode$int(product.ka)),
													_Utils_Tuple2(
													'base_point',
													$elm$json$Json$Encode$int(product.j9)),
													_Utils_Tuple2(
													'product_order',
													$elm$json$Json$Encode$int(product.lU)),
													_Utils_Tuple2(
													'get_enabled',
													$elm$json$Json$Encode$bool(product.aP)),
													_Utils_Tuple2(
													'display_enabled',
													$elm$json$Json$Encode$bool(product.aI)),
													_Utils_Tuple2(
													'yodobashi_base_price_flag',
													$elm$json$Json$Encode$bool(product.mM)),
													_Utils_Tuple2(
													'week30_flag',
													$elm$json$Json$Encode$bool(product.cG)),
													_Utils_Tuple2(
													'get_interval',
													$elm$json$Json$Encode$int(product.$9)),
													_Utils_Tuple2(
													'display_intervals',
													A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, product.kC))
												]));
									},
									model.ja))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						A5($author$project$Api$post, $author$project$Endpoints$Cms$changeUserProductEndpoints.hB, model.bu, body, $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent));
				}
			case 17:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var index = msg.a;
				var display_interval = msg.b;
				var enableInterval = msg.c;
				var products = A2(
					$elm$core$List$indexedMap,
					F2(
						function (i, product) {
							return _Utils_eq(i, index) ? (enableInterval ? _Utils_update(
								product,
								{
									kC: A2($elm$core$List$cons, display_interval, product.kC)
								}) : _Utils_update(
								product,
								{
									kC: A2(
										$elm$core$List$filter,
										function (di) {
											return !_Utils_eq(di, display_interval);
										},
										product.kC)
								})) : product;
						}),
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ja: products}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(
									A2($elm$core$Maybe$withDefault, -1, model.fJ))),
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						A5($author$project$Api$post, $author$project$Endpoints$Cms$changeUserProductEndpoints.hc, model.bu, body, $author$project$Pages$Cms$Products$ChangeUserProduct$productsResponseDecoder, $author$project$Pages$Cms$Products$ChangeUserProduct$ProductsRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Cms$Products$EditProducts$SendRequestSent = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$Products$EditProducts$UpdateRequestSent = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$EditProducts$SendResponse = function (productsGeneralInformations) {
	return {ao: productsGeneralInformations};
};
var $author$project$Pages$Cms$Products$EditProducts$ProductsGeneralInformation = F6(
	function (product_id, product_name, release_date, maker_id, small_category_id, isUpdated) {
		return {at: isUpdated, bj: maker_id, g4: product_id, fm: product_name, lZ: release_date, bv: small_category_id};
	});
var $author$project$Pages$Cms$Products$EditProducts$productGeneralInformationsDecoder = $elm$json$Json$Decode$list(
	A7(
		$elm$json$Json$Decode$map6,
		$author$project$Pages$Cms$Products$EditProducts$ProductsGeneralInformation,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'release_date',
			$elm$json$Json$Decode$maybe($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder)),
		A2($elm$json$Json$Decode$field, 'maker_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'small_category_id', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed(false)));
var $author$project$Pages$Cms$Products$EditProducts$sendResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$EditProducts$SendResponse,
	A2($elm$json$Json$Decode$field, 'products_general_informations', $author$project$Pages$Cms$Products$EditProducts$productGeneralInformationsDecoder));
var $elm$core$String$trim = _String_trim;
var $author$project$Pages$Cms$Products$EditProducts$updateProductGeneralInformation = F3(
	function (index, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ao);
		if (_Utils_eq(index, -1)) {
			return model;
		} else {
			if (!maybeCorrespondingProduct.$) {
				var product = maybeCorrespondingProduct.a;
				var newProduct = f(
					_Utils_update(
						product,
						{at: true}));
				var newProducts = A3($elm_community$list_extra$List$Extra$setAt, index, newProduct, model.ao);
				return _Utils_update(
					model,
					{ao: newProducts});
			} else {
				return model;
			}
		}
	});
var $author$project$Pages$Cms$Products$EditProducts$UpdateResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Products$EditProducts$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$EditProducts$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Products$EditProducts$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eJ: false, ca: initResponse.ca, jy: initResponse.jz}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c1: $elm$core$String$trim(input)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c2: $elm$core$String$trim(input)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var object = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'product_id',
							$elm$json$Json$Encode$string(model.c1)),
							_Utils_Tuple2(
							'product_name',
							$elm$json$Json$Encode$string(model.c2))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eQ: true}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$editProductsEndpoints.hm, model.bu, object, $author$project$Pages$Cms$Products$EditProducts$sendResponseDecoder, $author$project$Pages$Cms$Products$EditProducts$SendRequestSent));
			case 6:
				if (!msg.a.$) {
					var response = msg.a.a;
					var newModel = _Utils_update(
						model,
						{ao: response.ao});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var selected = msg.a;
				if (selected.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var id = selected.a;
					switch (id) {
						case 0:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{eM: false}),
								$elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{eM: true}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 9:
				var index = msg.a;
				var productName = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{fm: productName});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$EditProducts$updateProductGeneralInformation, index, model, f),
					$elm$core$Platform$Cmd$none);
			case 10:
				var index = msg.a;
				var makerId = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{bj: makerId});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$EditProducts$updateProductGeneralInformation, index, model, f),
					$elm$core$Platform$Cmd$none);
			case 11:
				var index = msg.a;
				var smallCategoryId = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{bv: smallCategoryId});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$EditProducts$updateProductGeneralInformation, index, model, f),
					$elm$core$Platform$Cmd$none);
			case 12:
				var index = msg.a;
				var maybepProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ao);
				if (!maybepProduct.$) {
					var product = maybepProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.g4)),
								_Utils_Tuple2(
								'product_name',
								$elm$json$Json$Encode$string(product.fm)),
								_Utils_Tuple2(
								'maker_id',
								$elm$json$Json$Encode$int(product.bj)),
								_Utils_Tuple2(
								'small_category_id',
								$elm$json$Json$Encode$int(product.bv))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f$: true}),
						A5(
							$author$project$Api$post,
							$author$project$Endpoints$Cms$editProductsEndpoints.ad,
							model.bu,
							body,
							$author$project$Pages$Cms$Products$EditProducts$updateResponseDecoder,
							$author$project$Pages$Cms$Products$EditProducts$UpdateRequestSent(index)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.b.$) {
					var index = msg.a;
					var updateResponse = msg.b.a;
					var newmodel = A3(
						$author$project$Pages$Cms$Products$EditProducts$updateProductGeneralInformation,
						index,
						model,
						function (p) {
							return _Utils_update(
								p,
								{at: false});
						});
					return _Utils_Tuple2(
						_Utils_update(
							newmodel,
							{f$: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var index = msg.a;
					var e = msg.b.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeStore = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeUserId = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$SendRequestSent = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $inkuzmin$elm_multiselect$Multiselect$getSelectedValues = function (_v0) {
	var model = _v0;
	return model.jo;
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedStores = F3(
	function (permalinkInfos, stores, multiselectStores) {
		var selected = A2(
			$elm$core$List$map,
			function (s) {
				return A2(
					$elm$core$Maybe$withDefault,
					-1,
					$elm$core$String$toInt(s));
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(multiselectStores)));
		var selectedStores = A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, item) {
					return A2($elm$core$List$member, item.fO, selected);
				}),
			stores);
		var selectedPermalinkGroupIds = A2(
			$elm$core$List$filterMap,
			function (store) {
				return store.y;
			},
			$elm$core$Dict$values(selectedStores));
		return A2(
			$elm$core$List$filter,
			function (permalinkInfo) {
				return A2($elm$core$List$member, permalinkInfo.E.y, selectedPermalinkGroupIds);
			},
			permalinkInfos);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$userStoresToPermalinkGroupIds = F2(
	function (userStores, stores) {
		var getPermalinkGroupId = function (userStore) {
			var storeId = userStore.hs;
			var maybeStore = A2($elm$core$Dict$get, storeId, stores);
			if (!maybeStore.$) {
				var store = maybeStore.a;
				var maybePermalinkGroupId = store.y;
				if (!maybePermalinkGroupId.$) {
					var permalinkGroupId = maybePermalinkGroupId.a;
					return permalinkGroupId;
				} else {
					return 0;
				}
			} else {
				return 0;
			}
		};
		var plist = A2($elm$core$List$map, getPermalinkGroupId, userStores);
		return $elm$core$Set$toList(
			$elm$core$Set$fromList(plist));
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedUserStores = F3(
	function (permalinkInfos, stores, userStores) {
		var selectedList = A2($author$project$Pages$Cms$Products$EditProductsPermalinks$userStoresToPermalinkGroupIds, userStores, stores);
		var selectedListWithRakutenAmazon = _Utils_ap(
			selectedList,
			_List_fromArray(
				[12, 2]));
		return A2(
			$elm$core$List$filter,
			function (permalinkInfo) {
				return A2($elm$core$List$member, permalinkInfo.E.y, selectedListWithRakutenAmazon);
			},
			permalinkInfos);
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$isInSomeMall = F2(
	function (store, mallName) {
		var isInMall = function (maybeStoreName) {
			if (!maybeStoreName.$) {
				var storeName = maybeStoreName.a;
				return A2($elm$core$String$contains, 'リスト', storeName) ? false : A2($elm$core$String$contains, mallName, storeName);
			} else {
				return false;
			}
		};
		return isInMall(store.h7);
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$filterMall = function (stores) {
	var malls = _List_fromArray(
		['Rakuten', 'Yahoo', 'Ymall', 'Amazon', 'auPay', 'Q10', 'PayPay']);
	return A3(
		$elm$core$List$foldl,
		F2(
			function (mall, acc) {
				return A2(
					$elm$core$Dict$filter,
					F2(
						function (key, store) {
							return !A2($author$project$Pages$Cms$Products$EditProductsPermalinks$isInSomeMall, store, mall);
						}),
					acc);
			}),
		stores,
		malls);
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$isInSomeMallU = F2(
	function (userStore, mallName) {
		var isInMall = function (maybeStoreName) {
			if (!maybeStoreName.$) {
				var storeName = maybeStoreName.a;
				return A2($elm$core$String$contains, 'リスト', storeName) ? false : A2($elm$core$String$contains, mallName, storeName);
			} else {
				return false;
			}
		};
		return isInMall(userStore.h6);
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$filterMallU = function (stores) {
	var malls = _List_fromArray(
		['Rakuten', 'Yahoo', 'Ymall', 'Amazon', 'auPay', 'Q10', 'PayPay']);
	return A3(
		$elm$core$List$foldl,
		F2(
			function (mall, acc) {
				return A2(
					$elm$core$List$filter,
					function (store) {
						return !A2($author$project$Pages$Cms$Products$EditProductsPermalinks$isInSomeMallU, store, mall);
					},
					acc);
			}),
		stores,
		malls);
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected = function (_v0) {
	var selected = _v0.jo;
	var values = _v0.q;
	return A2(
		$elm$core$List$filter,
		function (value) {
			return !A2($elm$core$List$member, value, selected);
		},
		values);
};
var $inkuzmin$elm_multiselect$Multiselect$populateValues = F3(
	function (_v0, values, selected) {
		var model = _v0;
		var filtered = $elm$core$List$isEmpty(selected) ? values : $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
			{jo: selected, q: values});
		return _Utils_update(
			model,
			{k: filtered, jo: selected, q: values});
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$SendListUserStoresRequestSent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$UserStoresResponse = function (userStores) {
	return {dT: userStores};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$UserStore = F9(
	function (storeId, storeName, storeGroupId, displayName, storeOrder, storeGroupName, getInterval, getEnabled, isUpdated) {
		return {h6: displayName, kS: getEnabled, kT: getInterval, at: isUpdated, mh: storeGroupId, mi: storeGroupName, hs: storeId, jD: storeName, mk: storeOrder};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$userStoresDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_enabled',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(true),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'store_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'display_name',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Products$EditProductsPermalinks$UserStore)))))))))));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$listUserStoresResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$EditProductsPermalinks$UserStoresResponse,
	A2($elm$json$Json$Decode$field, 'userStores', $author$project$Pages$Cms$Products$EditProductsPermalinks$userStoresDecoder));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$postListStores = F2(
	function (userId, model) {
		return A5(
			$author$project$Api$post,
			$author$project$Endpoints$Cms$editProductsPermalinksEndpoints.gQ,
			model.bu,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'user_id',
						$elm$json$Json$Encode$int(userId))
					])),
			$author$project$Pages$Cms$Products$EditProductsPermalinks$listUserStoresResponseDecoder,
			$author$project$Pages$Cms$Products$EditProductsPermalinks$SendListUserStoresRequestSent);
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$SendResponse = function (permalinkInfos) {
	return {x: permalinkInfos};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$PermalinkInfo = F4(
	function (products, permalink_group, permalink, url) {
		return {fj: permalink, E: permalink_group, ja: products, dS: url};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$PermalinkGroup = F2(
	function (permalink_group_id, permalink_group_name) {
		return {y: permalink_group_id, iX: permalink_group_name};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$permalinkGroupDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$EditProductsPermalinks$PermalinkGroup,
	A2($elm$json$Json$Decode$field, 'permalink_group_id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'permalink_group_name', $elm$json$Json$Decode$string));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$Products = F2(
	function (product_id, product_name) {
		return {g4: product_id, fm: product_name};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$productsDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Cms$Products$EditProductsPermalinks$Products,
	A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$permalinkInfoDecoder = $elm$json$Json$Decode$list(
	A5(
		$elm$json$Json$Decode$map4,
		$author$project$Pages$Cms$Products$EditProductsPermalinks$PermalinkInfo,
		A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Cms$Products$EditProductsPermalinks$productsDecoder),
		A2($elm$json$Json$Decode$field, 'permalink_groups', $author$project$Pages$Cms$Products$EditProductsPermalinks$permalinkGroupDecoder),
		A2($elm$json$Json$Decode$field, 'permalink', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string)));
var $author$project$Pages$Cms$Products$EditProductsPermalinks$sendResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Products$EditProductsPermalinks$SendResponse,
	A2($elm$json$Json$Decode$field, 'permalinkInfos', $author$project$Pages$Cms$Products$EditProductsPermalinks$permalinkInfoDecoder));
var $elm$core$List$sortBy = _List_sortBy;
var $inkuzmin$elm_multiselect$Multiselect$Cleared = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$DisableProtection = {$: 3};
var $inkuzmin$elm_multiselect$Multiselect$FocusResult = function (a) {
	return {$: 8, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Hide = 1;
var $inkuzmin$elm_multiselect$Multiselect$NotFound = function (a) {
	return {$: 3, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollResult = function (a) {
	return {$: 9, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollY = function (a) {
	return {$: 14, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Selected = function (a) {
	return {$: 0, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Unselected = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace = 8;
var $elm$core$Process$sleep = _Process_sleep;
var $inkuzmin$elm_multiselect$Multiselect$delayInMs = F2(
	function (ms, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(ms));
	});
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $inkuzmin$elm_multiselect$Multiselect$domScrollToY = F2(
	function (id, y) {
		return A2(
			$elm$core$Task$andThen,
			function (vp) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, vp.jV.mI, y);
			},
			$elm$browser$Browser$Dom$getViewportOf(id));
	});
var $inkuzmin$elm_multiselect$Multiselect$domScrollY = function (id) {
	return A2(
		$elm$core$Task$map,
		function (vp) {
			return vp.jV.mJ;
		},
		$elm$browser$Browser$Dom$getViewportOf(id));
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow = 40;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$end = 35;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape = 27;
var $inkuzmin$elm_multiselect$Multiselect$fitViewPort = F2(
	function (_v0, _v1) {
		var top = _v0.a;
		var bottom = _v0.b;
		var vpTop = _v1.a;
		var vpBottom = _v1.b;
		return (_Utils_cmp(top, vpTop) < 0) ? top : ((_Utils_cmp(bottom, vpBottom) > 0) ? (vpTop + (bottom - vpBottom)) : vpTop);
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight = 32;
var $inkuzmin$elm_multiselect$Multiselect$getBoundaries = function (i) {
	return _Utils_Tuple2(i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight, (i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight) + $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight = 200;
var $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries = function (i) {
	return _Utils_Tuple2(i, i + $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$home = 36;
var $inkuzmin$elm_multiselect$Multiselect$indexOf = F2(
	function (el, list) {
		var helper = F2(
			function (l, index) {
				helper:
				while (true) {
					if (!l.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var xs = l.b;
						if (_Utils_eq(x, el)) {
							return $elm$core$Maybe$Just(index);
						} else {
							var $temp$l = xs,
								$temp$index = index + 1;
							l = $temp$l;
							index = $temp$index;
							continue helper;
						}
					}
				}
			});
		return A2(helper, list, 0);
	});
var $inkuzmin$elm_multiselect$Multiselect$lastElem = A2(
	$elm$core$List$foldl,
	A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, $elm$core$Basics$always),
	$elm$core$Maybe$Nothing);
var $inkuzmin$elm_multiselect$Multiselect$nextItem = F2(
	function (list, item) {
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? $elm$core$List$head(list) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$nextSelectedItem = F2(
	function (list, item) {
		var takeLast = function (l) {
			if (!l.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!l.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var _v3 = l.b;
					var y = _v3.a;
					return $elm$core$Maybe$Just(y);
				}
			}
		};
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? takeLast(
							$elm$core$List$reverse(list)) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown = 34;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp = 33;
var $inkuzmin$elm_multiselect$Multiselect$prevItem = F2(
	function (list, item) {
		return A2(
			$inkuzmin$elm_multiselect$Multiselect$nextItem,
			$elm$core$List$reverse(list),
			item);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$return = 13;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab = 9;
var $elm$core$String$toLower = _String_toLower;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow = 38;
var $inkuzmin$elm_multiselect$Multiselect$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 4:
				return (model.ac === 2) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{ac: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.u))
							])),
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{ac: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.u))
							])),
					$elm$core$Maybe$Nothing);
			case 1:
				return model.ax ? _Utils_Tuple3(
					_Utils_update(
						model,
						{ax: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{ac: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ax: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				return model.ax ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{ax: true, ac: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.u)),
								A2($inkuzmin$elm_multiselect$Multiselect$delayInMs, 100, $inkuzmin$elm_multiselect$Multiselect$DisableProtection)
							])),
					$elm$core$Maybe$Nothing);
			case 9:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a8: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v3 = model.ix;
					if (_v3.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ix: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a8: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 8:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a8: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v5 = model.ix;
					if (_v5.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ix: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{a8: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 11:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gI: value}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var value = msg.a;
				if (model.ax) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ax: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var lowerValue = $elm$core$String$toLower(value);
					var valuesMatchingSearch = A2(
						$elm$core$List$filter,
						function (_v8) {
							var val = _v8.b;
							return A2(
								$elm$core$String$contains,
								lowerValue,
								$elm$core$String$toLower(val));
						},
						model.q);
					var maybePrefixedWithValue = ((model.gH === 1) || ((value === '') || A2(
						$elm$core$List$any,
						function (_v7) {
							var val = _v7.b;
							return _Utils_eq(
								$elm$core$String$toLower(val),
								value);
						},
						valuesMatchingSearch))) ? valuesMatchingSearch : A2(
						$elm$core$List$cons,
						_Utils_Tuple2(value, value),
						valuesMatchingSearch);
					var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
						{jo: model.jo, q: maybePrefixedWithValue});
					var _v6 = model.l;
					if (_v6.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									k: filtered,
									l: $elm$core$List$head(filtered),
									ix: $elm$core$Maybe$Just(value),
									ac: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v6.a;
						return (!$elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i, item);
								},
								filtered))) ? _Utils_Tuple3(
							_Utils_update(
								model,
								{
									k: filtered,
									l: $elm$core$List$head(filtered),
									ix: $elm$core$Maybe$Just(value),
									ac: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing) : _Utils_Tuple3(
							_Utils_update(
								model,
								{
									k: filtered,
									ix: $elm$core$Maybe$Just(value),
									ac: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 5:
				var item = msg.a;
				var selected = _Utils_ap(
					model.jo,
					_List_fromArray(
						[item]));
				var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
					{jo: selected, q: model.q});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							k: filtered,
							l: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.k, item),
							ix: $elm$core$Maybe$Nothing,
							jo: selected,
							ac: $elm$core$List$isEmpty(filtered) ? 0 : 2
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.u))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
			case 6:
				var item = msg.a;
				var selected = A2(
					$elm$core$List$filter,
					function (value) {
						return !_Utils_eq(value, item);
					},
					model.jo);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							k: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{jo: selected, q: model.q}),
							l: $elm$core$Maybe$Just(item),
							jo: selected
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$ScrollY,
								$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
			case 7:
				var selected = _List_Nil;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							k: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{jo: selected, q: model.q}),
							ix: $elm$core$Maybe$Nothing,
							jo: selected,
							ac: 0
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.u))
							])),
					$elm$core$Maybe$Just($inkuzmin$elm_multiselect$Multiselect$Cleared));
			case 12:
				var item = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							l: $elm$core$Maybe$Just(item)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a8: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var y = result.a;
					var _v10 = model.l;
					if (_v10.$ === 1) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					} else {
						var item = _v10.a;
						var _v11 = A2($inkuzmin$elm_multiselect$Multiselect$indexOf, item, model.k);
						if (_v11.$ === 1) {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						} else {
							var idx = _v11.a;
							var vpBoundaries = $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries(y);
							var boundaries = $inkuzmin$elm_multiselect$Multiselect$getBoundaries(idx);
							var scroll = A2($inkuzmin$elm_multiselect$Multiselect$fitViewPort, boundaries, vpBoundaries);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{a8: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollResult,
											A2($inkuzmin$elm_multiselect$Multiselect$domScrollToY, 'multiselectMenu' + model.u, scroll))
										])),
								$elm$core$Maybe$Nothing);
						}
					}
				}
			default:
				var key = msg.a;
				if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow)) {
					var _v12 = model.l;
					if (_v12.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									l: $elm$core$List$head(model.k)
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v12.a;
						var prev = A2($inkuzmin$elm_multiselect$Multiselect$prevItem, model.k, item);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{l: prev}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Task$attempt,
										$inkuzmin$elm_multiselect$Multiselect$ScrollY,
										$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
									])),
							$elm$core$Maybe$Nothing);
					}
				} else {
					if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) {
						var _v13 = model.l;
						if (_v13.$ === 1) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										l: $elm$core$List$head(model.k)
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var item = _v13.a;
							var next = A2($inkuzmin$elm_multiselect$Multiselect$nextItem, model.k, item);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{l: next}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
										])),
								$elm$core$Maybe$Nothing);
						}
					} else {
						if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$home)) {
							var first = $elm$core$List$head(model.k);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{l: first}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
										])),
								$elm$core$Maybe$Nothing);
						} else {
							if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$end)) {
								var last = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.k);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{l: last}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Task$attempt,
												$inkuzmin$elm_multiselect$Multiselect$ScrollY,
												$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
											])),
									$elm$core$Maybe$Nothing);
							} else {
								if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$return) && ((model.ac === 2) || $elm$core$List$isEmpty(model.k))) {
									var _v14 = model.l;
									if (_v14.$ === 1) {
										var _v15 = model.ix;
										if (_v15.$ === 1) {
											return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
										} else {
											if (_v15.a === '') {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											} else {
												var input_ = _v15.a;
												return _Utils_Tuple3(
													model,
													$elm$core$Platform$Cmd$none,
													$elm$core$Maybe$Just(
														$inkuzmin$elm_multiselect$Multiselect$NotFound(input_)));
											}
										}
									} else {
										var _v16 = _v14.a;
										var id = _v16.a;
										var val = _v16.b;
										var lowerVal = $elm$core$String$toLower(val);
										if ((!model.gH) && (_Utils_eq(
											$elm$core$Maybe$Just(val),
											model.ix) && A2(
											$elm$core$List$all,
											function (_v17) {
												var value = _v17.b;
												return !_Utils_eq(
													$elm$core$String$toLower(value),
													lowerVal);
											},
											model.q))) {
											return _Utils_Tuple3(
												model,
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$NotFound(val)));
										} else {
											var item = _Utils_Tuple2(id, val);
											var selected = _Utils_ap(
												model.jo,
												_List_fromArray(
													[item]));
											var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
												{jo: selected, q: model.q});
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{
														k: filtered,
														l: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.k, item),
														ix: $elm$core$Maybe$Nothing,
														jo: selected,
														ac: $elm$core$List$isEmpty(filtered) ? 0 : 2
													}),
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2(
															$elm$core$Task$attempt,
															$inkuzmin$elm_multiselect$Multiselect$FocusResult,
															$elm$browser$Browser$Dom$focus('multiselectInput' + model.u))
														])),
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
										}
									}
								} else {
									if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape)) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{ax: true, ac: 0}),
											$elm$core$Platform$Cmd$none,
											$elm$core$Maybe$Nothing);
									} else {
										if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab)) {
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{ac: 0}),
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Nothing);
										} else {
											if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace)) {
												var _v18 = model.ix;
												if ((!_v18.$) && (_v18.a === '')) {
													var _v19 = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.jo);
													if (_v19.$ === 1) {
														return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
													} else {
														var item = _v19.a;
														var selected = A2(
															$elm$core$List$filter,
															function (value) {
																return !_Utils_eq(value, item);
															},
															model.jo);
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{
																	k: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
																		{jo: selected, q: model.q}),
																	l: $elm$core$Maybe$Just(item),
																	jo: selected
																}),
															$elm$core$Platform$Cmd$batch(
																_List_fromArray(
																	[
																		A2(
																		$elm$core$Task$attempt,
																		$inkuzmin$elm_multiselect$Multiselect$ScrollY,
																		$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.u))
																	])),
															$elm$core$Maybe$Just(
																$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
													}
												} else {
													return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
												}
											} else {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											}
										}
									}
								}
							}
						}
					}
				}
		}
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$updatePermalink = F3(
	function (index, model, f) {
		var targetPermalinkInfos = $elm$core$List$isEmpty(
			$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? (model.eM ? A2($elm_community$list_extra$List$Extra$getAt, index, model.aY) : A2($elm_community$list_extra$List$Extra$getAt, index, model.x)) : A2(
			$elm_community$list_extra$List$Extra$getAt,
			index,
			A3($author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedStores, model.x, model.ap, model.r));
		var maybeCorrespondingPermalinkInfo = A2(
			$elm$core$Maybe$andThen,
			function (target) {
				return $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (permalinkInfo) {
							return _Utils_eq(permalinkInfo.E.y, target.E.y) && _Utils_eq(permalinkInfo.ja.g4, target.ja.g4);
						},
						model.x));
			},
			targetPermalinkInfos);
		if (!maybeCorrespondingPermalinkInfo.$) {
			var permalinkInfo = maybeCorrespondingPermalinkInfo.a;
			var newPermaLinkInfo = f(permalinkInfo);
			var newPermaLinkInfos = A2(
				$elm$core$List$map,
				function (procPermalinkInfo) {
					if (!targetPermalinkInfos.$) {
						var target = targetPermalinkInfos.a;
						return (_Utils_eq(procPermalinkInfo.E.y, target.E.y) && _Utils_eq(procPermalinkInfo.ja.g4, target.ja.g4)) ? newPermaLinkInfo : procPermalinkInfo;
					} else {
						return procPermalinkInfo;
					}
				},
				model.x);
			var newSelectedUserPermalinkInfos = A2(
				$elm$core$List$map,
				function (procPermalinkInfo) {
					if (!targetPermalinkInfos.$) {
						var target = targetPermalinkInfos.a;
						return (_Utils_eq(procPermalinkInfo.E.y, target.E.y) && _Utils_eq(procPermalinkInfo.ja.g4, target.ja.g4)) ? newPermaLinkInfo : procPermalinkInfo;
					} else {
						return procPermalinkInfo;
					}
				},
				model.aY);
			return _Utils_update(
				model,
				{x: newPermaLinkInfos, aY: newSelectedUserPermalinkInfos});
		} else {
			return model;
		}
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					var filteredStores = $author$project$Pages$Cms$Products$EditProductsPermalinks$filterMall(initResponse.ap);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eJ: false,
								r: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.r,
									A2(
										$elm$core$List$map,
										function (s) {
											return _Utils_Tuple2(
												$elm$core$String$fromInt(s.fO),
												A2($elm$core$Maybe$withDefault, '', s.dz));
										},
										A2(
											$elm$core$List$sortBy,
											function (s) {
												return A2($elm$core$Maybe$withDefault, '', s.dz);
											},
											$elm$core$Dict$values(filteredStores))),
									_List_Nil),
								ap: filteredStores,
								jT: initResponse.jT
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jT: response.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jT: response.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dx: $elm$core$String$trim(input)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dy: $elm$core$String$trim(input)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var maybeUserId = msg.a;
				var newModel = _Utils_update(
					model,
					{fJ: maybeUserId});
				if (maybeUserId.$ === 1) {
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var userId = maybeUserId.a;
					return _Utils_Tuple2(
						newModel,
						A2($author$project$Pages$Cms$Products$EditProductsPermalinks$postListStores, userId, newModel));
				}
			case 6:
				var object = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'product_id',
							$elm$json$Json$Encode$string(model.dx)),
							_Utils_Tuple2(
							'product_name',
							$elm$json$Json$Encode$string(model.dy))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eQ: true}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$editProductsPermalinksEndpoints.hm, model.bu, object, $author$project$Pages$Cms$Products$EditProductsPermalinks$sendResponseDecoder, $author$project$Pages$Cms$Products$EditProductsPermalinks$SendRequestSent));
			case 7:
				if (!msg.a.$) {
					var response = msg.a.a;
					var newModel = _Utils_update(
						model,
						{x: response.x});
					var _v2 = model.fJ;
					if (_v2.$ === 1) {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					} else {
						var userId = _v2.a;
						return _Utils_Tuple2(
							newModel,
							A2(
								$elm$core$Task$perform,
								function (_v3) {
									return $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeUserId(
										$elm$core$Maybe$Just(userId));
								},
								$elm$core$Task$succeed(0)));
					}
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (!msg.a.$) {
					var response = msg.a.a;
					var newUserStores = $author$project$Pages$Cms$Products$EditProductsPermalinks$filterMallU(response.dT);
					var newSelectedUserPermalinkInfos = A3($author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedUserStores, model.x, model.ap, newUserStores);
					var selectStores = $elm$core$List$isEmpty(newUserStores) ? A2(
						$elm$core$List$map,
						function (s) {
							return _Utils_Tuple2(
								$elm$core$String$fromInt(s.fO),
								A2($elm$core$Maybe$withDefault, '', s.dz));
						},
						A2(
							$elm$core$List$sortBy,
							function (s) {
								return A2($elm$core$Maybe$withDefault, '', s.dz);
							},
							$elm$core$Dict$values(model.ap))) : A2(
						$elm$core$List$map,
						function (s) {
							return _Utils_Tuple2(
								$elm$core$String$fromInt(s.hs),
								s.jD);
						},
						A2(
							$elm$core$List$sortBy,
							function (s) {
								return s.jD;
							},
							newUserStores));
					var newModel = _Utils_update(
						model,
						{
							r: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, model.r, selectStores, _List_Nil),
							aY: newSelectedUserPermalinkInfos,
							dT: newUserStores
						});
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var id = msg.a;
				var input = msg.b;
				var f = function (permalinkInfos) {
					return _Utils_update(
						permalinkInfos,
						{fj: input});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Cms$Products$EditProductsPermalinks$updatePermalink, id, model, f),
					$elm$core$Platform$Cmd$none);
			case 10:
				var id = msg.a;
				var targetPermalinkInfo = $elm$core$List$isEmpty(
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? (model.eM ? A2($elm_community$list_extra$List$Extra$getAt, id, model.aY) : A2($elm_community$list_extra$List$Extra$getAt, id, model.x)) : A2(
					$elm_community$list_extra$List$Extra$getAt,
					id,
					A3($author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedStores, model.x, model.ap, model.r));
				var updatePermalinkInfo = A2(
					$elm$core$Maybe$andThen,
					function (target) {
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (permalinkInfo) {
									return _Utils_eq(permalinkInfo.E.y, target.E.y) && _Utils_eq(permalinkInfo.ja.g4, target.ja.g4);
								},
								model.x));
					},
					targetPermalinkInfo);
				if (!updatePermalinkInfo.$) {
					var permalinkInfo = updatePermalinkInfo.a;
					var object = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(permalinkInfo.ja.g4)),
								_Utils_Tuple2(
								'permalink_group_id',
								$elm$json$Json$Encode$int(permalinkInfo.E.y)),
								_Utils_Tuple2(
								'permalink',
								$elm$json$Json$Encode$string(permalinkInfo.fj))
							]));
					return _Utils_Tuple2(
						model,
						A5($author$project$Api$post, $author$project$Endpoints$Cms$editProductsPermalinksEndpoints.ad, model.bu, object, $author$project$Pages$Cms$Products$EditProductsPermalinks$sendResponseDecoder, $author$project$Pages$Cms$Products$EditProductsPermalinks$SendRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 11:
				var selected = msg.a;
				if (selected.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var id = selected.a;
					switch (id) {
						case 0:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{eM: false}),
								$elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{eM: true}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			default:
				var sub = msg.a;
				var _v7 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.r);
				var subModel = _v7.a;
				var subCmd = _v7.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeStore, subCmd));
		}
	});
var $author$project$Pages$Cms$Products$RakutenExcludedItems$InsertionRequestSent = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$RemoveRequestSent = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eJ: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var object = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'jan',
							$elm$json$Json$Encode$string(model.bF))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: ''}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$rakutenExcludedItemsEndpoints.ae, model.bu, object, $author$project$Pages$Cms$Products$RakutenExcludedItems$initResponseDecoder, $author$project$Pages$Cms$Products$RakutenExcludedItems$InsertionRequestSent));
			case 2:
				var object = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'jan',
							$elm$json$Json$Encode$string(model.co))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{co: ''}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$rakutenExcludedItemsEndpoints.hc, model.bu, object, $author$project$Pages$Cms$Products$RakutenExcludedItems$initResponseDecoder, $author$project$Pages$Cms$Products$RakutenExcludedItems$RemoveRequestSent));
			case 5:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ja: response.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ja: response.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bF: input}),
					$elm$core$Platform$Cmd$none);
			default:
				var input = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{co: input}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Utils$Helpers$delay = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(time));
	});
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$file$File$toString = _File_toString;
var $author$project$Endpoints$Cms$updateHitachiProductsOrder = {
	kj: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'cleaner']),
		_List_Nil),
	k3: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'ih']),
		_List_Nil),
	lk: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'microwave']),
		_List_Nil),
	lY: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'refrigirator']),
		_List_Nil),
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order']),
		_List_Nil),
	md: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'smalls']),
		_List_Nil),
	mF: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-products-order', 'washing-machine']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var maybeMaybeEndpoint = A2(
					$elm$core$Maybe$map,
					function (bigCategoryId) {
						switch (bigCategoryId) {
							case 1:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.lY);
							case 2:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.mF);
							case 3:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.kj);
							case 4:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.lk);
							case 5:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.md);
							case 6:
								return $elm$core$Maybe$Just($author$project$Endpoints$Cms$updateHitachiProductsOrder.k3);
							default:
								return $elm$core$Maybe$Nothing;
						}
					},
					model.he);
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				if (!maybeMaybeEndpoint.$) {
					var maybeEndpoint = maybeMaybeEndpoint.a;
					if (!maybeEndpoint.$) {
						var endpoint = maybeEndpoint.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{b9: true}),
							A5(
								$author$project$Api$post,
								endpoint,
								model.bu,
								body,
								$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
								$author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$Received));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ar: $elm$core$Maybe$Just('正しい大分類を1つだけ選択してください')
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just('正しい大分類を1つだけ選択してください')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
			default:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Endpoints$Cms$updateHitachiWeeklyProducts = {
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-weekly-products']),
		_List_Nil),
	dR: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-hitachi-weekly-products', 'upload-csv']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b9: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$updateHitachiWeeklyProducts.dR,
						model.bu,
						body,
						$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
						$author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$Received));
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Endpoints$Cms$updateSharpProducts = {
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-sharp-products']),
		_List_Nil),
	dR: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-sharp-products', 'upload-csv']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b9: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$updateSharpProducts.dR,
						model.bu,
						body,
						$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
						$author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$Received));
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Endpoints$Cms$updateTescomProducts = {
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-tescom-products']),
		_List_Nil),
	dR: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-tescom-products', 'upload-csv']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b9: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$updateTescomProducts.dR,
						model.bu,
						body,
						$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
						$author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$Received));
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Endpoints$Cms$updateTletProducts = {
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-tlet-products']),
		_List_Nil),
	dR: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, 'update-tlet-products', 'upload-csv']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b9: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$updateTletProducts.dR,
						model.bu,
						body,
						$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
						$author$project$Pages$Cms$Products$UpdateTletProductsByCsv$Received));
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnCsvLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnSucceeded = {$: 3};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnUpload = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$Received = function (a) {
	return {$: 4, a: a};
};
var $author$project$Endpoints$Cms$updateWeek30ByCsv = 'update-week30-by-csv';
var $author$project$Endpoints$Cms$updateWeek30ByCsvEndpoints = {
	j: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$updateWeek30ByCsv]),
		_List_Nil),
	dR: A2(
		$elm$url$Url$Builder$absolute,
		_List_fromArray(
			[$author$project$Endpoints$Cms$cms, $author$project$Endpoints$Cms$products, $author$project$Endpoints$Cms$updateWeek30ByCsv, 'upload-csv']),
		_List_Nil)
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnCsvLoaded));
			case 1:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnUpload,
						$elm$file$File$toString(file)));
			case 2:
				var csv = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'csv',
							$elm$json$Json$Encode$string(csv))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b9: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$updateWeek30ByCsvEndpoints.dR,
						model.bu,
						body,
						$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
						$author$project$Pages$Cms$Products$UpdateWeek30ByCsv$Received));
			case 4:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, b9: false, mo: true}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnSucceeded));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e)),
								b9: false,
								mo: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mo: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Products$UserSpecificRouting$filterUser = function (user) {
	return A2(
		$elm$core$List$member,
		user.jS,
		_List_fromArray(
			[1, 2, 6, 16, 23, 31]));
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$update = F2(
	function (msg, model) {
		if (!msg.$) {
			if (!msg.a.$) {
				var initResponse = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b2: false,
							jT: A2($elm$core$List$filter, $author$project$Pages$Cms$Products$UserSpecificRouting$filterUser, initResponse.jT)
						}),
					$elm$core$Platform$Cmd$none);
			} else {
				var err = msg.a.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ar: $elm$core$Maybe$Just(
								$author$project$Utils$Helpers$errToStr(err)),
							b2: false
						}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			var maybeUserId = msg.a;
			var newModel = _Utils_update(
				model,
				{fJ: maybeUserId});
			if (maybeUserId.$ === 1) {
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			} else {
				var userId = maybeUserId.a;
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedAddPermalinkGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedDeletePermalinkGroup = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedUpdatePermalinkGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$updatePermalinkGroups = F2(
	function (permalinkGroup, permalinkGroups) {
		return A3(
			$elm_community$list_extra$List$Extra$setIf,
			function (pg) {
				return _Utils_eq(pg.R, permalinkGroup.R);
			},
			permalinkGroup,
			permalinkGroups);
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$UpdateResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditPermalinkGroups$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, A: initResponse.A}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!msg.b.$) {
					var permalinkGroupId = msg.a;
					var updateResponse = msg.b.a;
					var permalinkGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.R, permalinkGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.A);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, A: permalinkGroups}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var updateResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, cc: $author$project$Pages$Cms$Stores$EditPermalinkGroups$initNewPermalinkGroup}),
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.w, model.bu, $author$project$Pages$Cms$Stores$EditPermalinkGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditPermalinkGroups$InitRequestSent));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.b.$) {
					var permalinkGroupId = msg.a;
					var updateResponse = msg.b.a;
					var permalinkGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.R, permalinkGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.A);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, A: permalinkGroups}),
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.w, model.bu, $author$project$Pages$Cms$Stores$EditPermalinkGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditPermalinkGroups$InitRequestSent));
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var permalinkGroup = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'permalink_group_id',
							$elm$json$Json$Encode$int(permalinkGroup.R)),
							_Utils_Tuple2(
							'permalink_group_name',
							$elm$json$Json$Encode$string(permalinkGroup.aV))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.ad,
						model.bu,
						body,
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$updateResponseDecoder,
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedUpdatePermalinkGroup(permalinkGroup.R)));
			case 8:
				var newPermalinkGroup = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'permalink_group_name',
							$elm$json$Json$Encode$string(newPermalinkGroup.aV))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Stores$EditPermalinkGroups$updateResponseDecoder, $author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedAddPermalinkGroup));
			case 7:
				var permalinkGroupId = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'permalink_group_id',
							$elm$json$Json$Encode$int(permalinkGroupId))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.bS,
						model.bu,
						body,
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$updateResponseDecoder,
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$CompletedDeletePermalinkGroup(permalinkGroupId)));
			case 1:
				var permalinkGroupId = msg.a;
				var permalinkGroupName = msg.b;
				var permalinkGroup = {at: true, R: permalinkGroupId, aV: permalinkGroupName};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							A: A2($author$project$Pages$Cms$Stores$EditPermalinkGroups$updatePermalinkGroups, permalinkGroup, model.A)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var permalinkGroupName = msg.a;
				var newPermalinkGroup = model.cc;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cc: _Utils_update(
								newPermalinkGroup,
								{at: true, aV: permalinkGroupName})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$CompletedAddStoreGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$CompletedDeleteStoreGroup = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$CompletedUpdateStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$UpdateResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditStoreGroups$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Stores$EditStoreGroups$updateStoreGroups = F2(
	function (storeGroup, storeGroups) {
		return A3(
			$elm_community$list_extra$List$Extra$setIf,
			function (pg) {
				return _Utils_eq(pg.mh, storeGroup.mh);
			},
			storeGroup,
			storeGroups);
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, U: initResponse.U}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!msg.b.$) {
					var storeGroupId = msg.a;
					var updateResponse = msg.b.a;
					var storeGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.mh, storeGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.U);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, U: storeGroups}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var updateResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, cd: $author$project$Pages$Cms$Stores$EditStoreGroups$initNewStoreGroup}),
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editStoreGroupsEndpoints.w, model.bu, $author$project$Pages$Cms$Stores$EditStoreGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStoreGroups$InitRequestSent));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!msg.b.$) {
					var storeGroupId = msg.a;
					var updateResponse = msg.b.a;
					var storeGroups = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.mh, storeGroupId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.U);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, U: storeGroups}),
						A4($author$project$Api$get, $author$project$Endpoints$Cms$editStoreGroupsEndpoints.w, model.bu, $author$project$Pages$Cms$Stores$EditStoreGroups$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStoreGroups$InitRequestSent));
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var storeGroup = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'store_group_id',
							$elm$json$Json$Encode$int(storeGroup.mh)),
							_Utils_Tuple2(
							'store_group_name',
							$elm$json$Json$Encode$string(storeGroup.mi))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editStoreGroupsEndpoints.ad,
						model.bu,
						body,
						$author$project$Pages$Cms$Stores$EditStoreGroups$updateResponseDecoder,
						$author$project$Pages$Cms$Stores$EditStoreGroups$CompletedUpdateStoreGroup(storeGroup.mh)));
			case 8:
				var newStoreGroup = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'store_group_name',
							$elm$json$Json$Encode$string(newStoreGroup.mi))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$Cms$editStoreGroupsEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Stores$EditStoreGroups$updateResponseDecoder, $author$project$Pages$Cms$Stores$EditStoreGroups$CompletedAddStoreGroup));
			case 7:
				var storeGroupId = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'store_group_id',
							$elm$json$Json$Encode$int(storeGroupId))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editStoreGroupsEndpoints.bS,
						model.bu,
						body,
						$author$project$Pages$Cms$Stores$EditStoreGroups$updateResponseDecoder,
						$author$project$Pages$Cms$Stores$EditStoreGroups$CompletedDeleteStoreGroup(storeGroupId)));
			case 1:
				var storeGroupId = msg.a;
				var storeGroupName = msg.b;
				var storeGroup = {at: true, mh: storeGroupId, mi: storeGroupName};
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							U: A2($author$project$Pages$Cms$Stores$EditStoreGroups$updateStoreGroups, storeGroup, model.U)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var storeGroupName = msg.a;
				var newStoreGroup = model.cd;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cd: _Utils_update(
								newStoreGroup,
								{at: true, mi: storeGroupName})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$AddRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$DeleteRequestSent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$Failed = 3;
var $author$project$Pages$Cms$Stores$EditStoreProperties$Loading = 1;
var $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState = {$: 4};
var $author$project$Pages$Cms$Stores$EditStoreProperties$Succeeded = 2;
var $author$project$Pages$Cms$Stores$EditStoreProperties$UpdateRequestSent = function (a) {
	return {$: 1, a: a};
};
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$UpdateResponse = function (isSucceeded) {
	return {k8: isSucceeded};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditStoreProperties$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'isSucceeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties = F3(
	function (id, model, f) {
		var ff = function (storeProperty) {
			var sp = f(storeProperty);
			return _Utils_update(
				sp,
				{at: true});
		};
		if (_Utils_eq(id, -1)) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						aS: ff(model.aS)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var newStoreProperties = A3(
				$elm$core$Dict$update,
				id,
				$elm$core$Maybe$andThen(
					A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, ff)),
				model.V);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{V: newStoreProperties}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a2: initResponse.a2, a6: initResponse.a6, eJ: false, fg: initResponse.V, V: initResponse.V}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e))
							}),
						A2($author$project$Utils$Helpers$delay, 1000, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState));
				}
			case 3:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 2}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState),
									A4(
									$author$project$Api$get,
									$author$project$Endpoints$Cms$editStorePropertiesEndpoints.w,
									$author$project$Pages$Cms$Stores$EditStoreProperties$toSession(model),
									$author$project$Pages$Cms$Stores$EditStoreProperties$initResponseDecoder,
									$author$project$Pages$Cms$Stores$EditStoreProperties$InitRequestSent)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState));
				}
			case 2:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aS: $author$project$Pages$Cms$Stores$EditStoreProperties$newStoreProperty, F: 2}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState),
									A4(
									$author$project$Api$get,
									$author$project$Endpoints$Cms$editStorePropertiesEndpoints.w,
									$author$project$Pages$Cms$Stores$EditStoreProperties$toSession(model),
									$author$project$Pages$Cms$Stores$EditStoreProperties$initResponseDecoder,
									$author$project$Pages$Cms$Stores$EditStoreProperties$InitRequestSent)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState));
				}
			case 1:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 2}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStoreProperties$ResetUpdateRequestState));
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{F: 0, a0: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{cm: value});
					});
			case 6:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{cj: value});
					});
			case 7:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{ci: value});
					});
			case 8:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								cg: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 9:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								cf: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 11:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								cy: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 12:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								cx: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 10:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								bQ: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(value))
							});
					});
			case 13:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								cv: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(value))
							});
					});
			case 14:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								bR: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(value))
							});
					});
			case 15:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStoreProperties$updateStoreProperties,
					id,
					model,
					function (storeProperty) {
						return _Utils_update(
							storeProperty,
							{
								bV: A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(value))
							});
					});
			case 16:
				var id = msg.a;
				return _Utils_eq(id, -1) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{aS: $author$project$Pages$Cms$Stores$EditStoreProperties$newStoreProperty}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							V: A3(
								$elm$core$Dict$update,
								id,
								function (_v1) {
									return A2($elm$core$Dict$get, id, model.fg);
								},
								model.V)
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var id = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'property_id',
							$elm$json$Json$Encode$int(id))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							F: 1,
							a0: $elm$core$Maybe$Just(id)
						}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editStorePropertiesEndpoints.bS,
						$author$project$Pages$Cms$Stores$EditStoreProperties$toSession(model),
						body,
						$author$project$Pages$Cms$Stores$EditStoreProperties$updateResponseDecoder,
						$author$project$Pages$Cms$Stores$EditStoreProperties$DeleteRequestSent));
			default:
				var id = msg.a;
				var _v2 = A2($elm$core$Dict$get, id, model.V);
				if (!_v2.$) {
					var storeProperty = _v2.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'property_id',
								$elm$json$Json$Encode$int(storeProperty.lV)),
								_Utils_Tuple2(
								'property_name',
								$elm$json$Json$Encode$string(storeProperty.cm)),
								_Utils_Tuple2(
								'price_selector',
								$elm$json$Json$Encode$string(storeProperty.cj)),
								_Utils_Tuple2(
								'price_regex',
								$elm$json$Json$Encode$string(storeProperty.ci)),
								_Utils_Tuple2(
								'point_selector',
								A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cg)),
								_Utils_Tuple2(
								'point_regex',
								A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cf)),
								_Utils_Tuple2(
								'constant_point',
								$elm$json$Json$Encode$int(storeProperty.bQ)),
								_Utils_Tuple2(
								'soldout_selector',
								A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cy)),
								_Utils_Tuple2(
								'soldout_regex',
								A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cx)),
								_Utils_Tuple2(
								'convert_type_id',
								$elm$json$Json$Encode$int(storeProperty.bR)),
								_Utils_Tuple2(
								'sleep_time',
								$elm$json$Json$Encode$int(storeProperty.cv)),
								_Utils_Tuple2(
								'driver_id',
								$elm$json$Json$Encode$int(storeProperty.bV))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								F: 1,
								a0: $elm$core$Maybe$Just(id)
							}),
						A5(
							$author$project$Api$post,
							$author$project$Endpoints$Cms$editStorePropertiesEndpoints.ad,
							$author$project$Pages$Cms$Stores$EditStoreProperties$toSession(model),
							body,
							$author$project$Pages$Cms$Stores$EditStoreProperties$updateResponseDecoder,
							$author$project$Pages$Cms$Stores$EditStoreProperties$UpdateRequestSent));
				} else {
					if (_Utils_eq(id, -1)) {
						var storeProperty = model.aS;
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'property_id',
									$elm$json$Json$Encode$int(storeProperty.lV)),
									_Utils_Tuple2(
									'property_name',
									$elm$json$Json$Encode$string(storeProperty.cm)),
									_Utils_Tuple2(
									'price_selector',
									$elm$json$Json$Encode$string(storeProperty.cj)),
									_Utils_Tuple2(
									'price_regex',
									$elm$json$Json$Encode$string(storeProperty.ci)),
									_Utils_Tuple2(
									'point_selector',
									A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cg)),
									_Utils_Tuple2(
									'point_regex',
									A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cf)),
									_Utils_Tuple2(
									'constant_point',
									$elm$json$Json$Encode$int(storeProperty.bQ)),
									_Utils_Tuple2(
									'soldout_selector',
									A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cy)),
									_Utils_Tuple2(
									'soldout_regex',
									A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, storeProperty.cx)),
									_Utils_Tuple2(
									'convert_type_id',
									$elm$json$Json$Encode$int(storeProperty.bR)),
									_Utils_Tuple2(
									'sleep_time',
									$elm$json$Json$Encode$int(storeProperty.cv)),
									_Utils_Tuple2(
									'driver_id',
									$elm$json$Json$Encode$int(storeProperty.bV))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									F: 1,
									a0: $elm$core$Maybe$Just(-1)
								}),
							A5(
								$author$project$Api$post,
								$author$project$Endpoints$Cms$editStorePropertiesEndpoints.ae,
								$author$project$Pages$Cms$Stores$EditStoreProperties$toSession(model),
								body,
								$author$project$Pages$Cms$Stores$EditStoreProperties$updateResponseDecoder,
								$author$project$Pages$Cms$Stores$EditStoreProperties$AddRequestSent));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ar: $elm$core$Maybe$Just('Error: Attempt to refer unknown property_id')
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
		}
	});
var $author$project$Pages$Cms$Stores$EditStores$AddRequestSent = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$DeleteRequestSent = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$Failed = 3;
var $author$project$Pages$Cms$Stores$EditStores$Loading = 1;
var $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState = {$: 14};
var $author$project$Pages$Cms$Stores$EditStores$Succeeded = 2;
var $author$project$Pages$Cms$Stores$EditStores$UpdateRequestSent = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$SendResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Stores$EditStores$sendResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditStores$SendResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Stores$EditStores$updateStores = F3(
	function (id, model, f) {
		var ff = function (store) {
			var store_ = f(store);
			return _Utils_update(
				store_,
				{at: true});
		};
		if (_Utils_eq(id, -1)) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						bl: ff(model.bl)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var newStores = A3(
				$elm$core$Dict$update,
				id,
				$elm$core$Maybe$andThen(
					A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, ff)),
				model.ap);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ap: newStores}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$EditStores$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eJ: false, A: initResponse.A, U: initResponse.U, V: initResponse.V, ap: initResponse.ap}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								eJ: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{lV: value});
					});
			case 2:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{y: value});
					});
			case 3:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{ml: value});
					});
			case 4:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{
								dS: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 6:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{
								h7: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 7:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{
								kL: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 5:
				var id = msg.a;
				var value = msg.b;
				return A3(
					$author$project$Pages$Cms$Stores$EditStores$updateStores,
					id,
					model,
					function (store) {
						return _Utils_update(
							store,
							{
								dz: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
							});
					});
			case 8:
				var store = model.bl;
				var _v1 = _Utils_Tuple3(store.ml, store.lV, store.y);
				if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
					var store_group_id = _v1.a.a;
					var property_id = _v1.b.a;
					var permalink_group_id = _v1.c.a;
					if (_Utils_eq(store_group_id, -1) || (_Utils_eq(property_id, -1) || _Utils_eq(permalink_group_id, -1))) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'store_group_id',
									$elm$json$Json$Encode$int(store_group_id)),
									_Utils_Tuple2(
									'property_id',
									$elm$json$Json$Encode$int(property_id)),
									_Utils_Tuple2(
									'permalink_group_id',
									$elm$json$Json$Encode$int(permalink_group_id)),
									_Utils_Tuple2(
									'url',
									$elm$json$Json$Encode$string(
										A2($elm$core$Maybe$withDefault, '', store.dS))),
									_Utils_Tuple2(
									'store_name',
									$elm$json$Json$Encode$string(
										A2($elm$core$Maybe$withDefault, '', store.dz))),
									_Utils_Tuple2(
									'display_name',
									$elm$json$Json$Encode$string(
										A2($elm$core$Maybe$withDefault, '', store.h7))),
									_Utils_Tuple2(
									'exhibition_name',
									$elm$json$Json$Encode$string(
										A2($elm$core$Maybe$withDefault, '', store.kL)))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{eQ: true}),
							A5($author$project$Api$post, $author$project$Endpoints$Cms$editStoresEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Stores$EditStores$sendResponseDecoder, $author$project$Pages$Cms$Stores$EditStores$AddRequestSent));
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bl: $author$project$Pages$Cms$Stores$EditStores$newStore, F: 2}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState),
									A4($author$project$Api$get, $author$project$Endpoints$Cms$editStoresEndpoints.w, model.bu, $author$project$Pages$Cms$Stores$EditStores$initResponseDecoder, $author$project$Pages$Cms$Stores$EditStores$InitRequestSent)
								])));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState));
				}
			case 10:
				var id = msg.a;
				var _v2 = A2($elm$core$Dict$get, id, model.ap);
				if (!_v2.$) {
					var store = _v2.a;
					var _v3 = _Utils_Tuple3(store.ml, store.lV, store.y);
					if (((!_v3.a.$) && (!_v3.b.$)) && (!_v3.c.$)) {
						var store_group_id = _v3.a.a;
						var property_id = _v3.b.a;
						var permalink_group_id = _v3.c.a;
						if (_Utils_eq(store_group_id, -1) || (_Utils_eq(property_id, -1) || _Utils_eq(permalink_group_id, -1))) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							var body = $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'store_id',
										$elm$json$Json$Encode$int(store.fO)),
										_Utils_Tuple2(
										'store_group_id',
										$elm$json$Json$Encode$int(store_group_id)),
										_Utils_Tuple2(
										'property_id',
										$elm$json$Json$Encode$int(property_id)),
										_Utils_Tuple2(
										'permalink_group_id',
										$elm$json$Json$Encode$int(permalink_group_id)),
										_Utils_Tuple2(
										'url',
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, '', store.dS))),
										_Utils_Tuple2(
										'store_name',
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, '', store.dz))),
										_Utils_Tuple2(
										'display_name',
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, '', store.h7))),
										_Utils_Tuple2(
										'exhibition_name',
										$elm$json$Json$Encode$string(
											A2($elm$core$Maybe$withDefault, '', store.kL)))
									]));
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{eQ: true, F: 1}),
								A5($author$project$Api$post, $author$project$Endpoints$Cms$editStoresEndpoints.ad, model.bu, body, $author$project$Pages$Cms$Stores$EditStores$sendResponseDecoder, $author$project$Pages$Cms$Stores$EditStores$UpdateRequestSent));
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 11:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 2}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState));
				}
			case 12:
				var id = msg.a;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'store_id',
							$elm$json$Json$Encode$int(id))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							F: 1,
							f0: $elm$core$Maybe$Just(id)
						}),
					A5(
						$author$project$Api$post,
						$author$project$Endpoints$Cms$editStoresEndpoints.bS,
						$author$project$Pages$Cms$Stores$EditStores$toSession(model),
						body,
						$author$project$Pages$Cms$Stores$EditStores$sendResponseDecoder,
						$author$project$Pages$Cms$Stores$EditStores$DeleteRequestSent));
			case 13:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 2}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState),
									A4(
									$author$project$Api$get,
									$author$project$Endpoints$Cms$editStoresEndpoints.w,
									$author$project$Pages$Cms$Stores$EditStores$toSession(model),
									$author$project$Pages$Cms$Stores$EditStores$initResponseDecoder,
									$author$project$Pages$Cms$Stores$EditStores$InitRequestSent)
								])));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: 3}),
						A2($author$project$Utils$Helpers$delay, 500, $author$project$Pages$Cms$Stores$EditStores$ResetUpdateRequestState));
				}
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{F: 0, f0: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$CompletedAddUserStore = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$CompletedDeleteUserStore = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$CompletedUpdateUserStore = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Cms$Stores$EditUsersStores$CompletedListUserStores = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$UserStoresResponse = function (userStores) {
	return {dT: userStores};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$UserStore = F9(
	function (storeId, storeName, storeGroupId, displayName, storeOrder, storeGroupName, getInterval, getEnabled, isUpdated) {
		return {h6: displayName, kS: getEnabled, kT: getInterval, at: isUpdated, mh: storeGroupId, mi: storeGroupName, hs: storeId, jD: storeName, mk: storeOrder};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$userStoresDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$succeed(false),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'get_enabled',
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$withDefault(true),
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool))),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'get_interval',
					$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'store_order', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2(
								$elm$json$Json$Decode$field,
								'display_name',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										$elm$json$Json$Decode$succeed($author$project$Pages$Cms$Stores$EditUsersStores$UserStore)))))))))));
var $author$project$Pages$Cms$Stores$EditUsersStores$listUserStoresResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditUsersStores$UserStoresResponse,
	A2($elm$json$Json$Decode$field, 'userStores', $author$project$Pages$Cms$Stores$EditUsersStores$userStoresDecoder));
var $author$project$Pages$Cms$Stores$EditUsersStores$postListStores = F2(
	function (maybeUser, model) {
		if (maybeUser.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			var userId = maybeUser.a;
			return A5(
				$author$project$Api$post,
				$author$project$Endpoints$Cms$editUsersStoresEndpoints.gQ,
				model.bu,
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'user_id',
							$elm$json$Json$Encode$int(userId))
						])),
				$author$project$Pages$Cms$Stores$EditUsersStores$listUserStoresResponseDecoder,
				$author$project$Pages$Cms$Stores$EditUsersStores$CompletedListUserStores);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$UpdateResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$updateResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Cms$Stores$EditUsersStores$UpdateResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Cms$Stores$EditUsersStores$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, U: initResponse.U, ap: initResponse.ap, jT: initResponse.jT}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 10:
				if (!msg.a.$) {
					var userStoresResponse = msg.a.a;
					var order = A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$List$maximum(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.mk;
								},
								userStoresResponse.dT))) + 1;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								J: false,
								s: _Utils_update(
									$author$project$Pages$Cms$Stores$EditUsersStores$initNewUserStore,
									{mk: order}),
								dT: userStoresResponse.dT
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				if (!msg.b.$) {
					var userStoreId = msg.a;
					var updateResponse = msg.b.a;
					var userStores = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.hs, userStoreId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.dT);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, dT: userStores}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				if (!msg.a.$) {
					var updateResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, s: $author$project$Pages$Cms$Stores$EditUsersStores$initNewUserStore, fG: $elm$core$Maybe$Nothing}),
						A2($author$project$Pages$Cms$Stores$EditUsersStores$postListStores, model.hk, model));
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				if (!msg.b.$) {
					var userStoreId = msg.a;
					var updateResponse = msg.b.a;
					var userStores = A3(
						$elm_community$list_extra$List$Extra$updateIf,
						function (pg) {
							return _Utils_eq(pg.hs, userStoreId);
						},
						function (pg) {
							return _Utils_update(
								pg,
								{at: false});
						},
						model.dT);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false, dT: userStores}),
						A2($author$project$Pages$Cms$Stores$EditUsersStores$postListStores, model.hk, model));
				} else {
					var err = msg.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var userStore = msg.a;
				var _v1 = model.hk;
				if (_v1.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var selectedUser = _v1.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(selectedUser)),
								_Utils_Tuple2(
								'store_id',
								$elm$json$Json$Encode$int(userStore.hs)),
								_Utils_Tuple2(
								'ord',
								$elm$json$Json$Encode$int(userStore.mk)),
								_Utils_Tuple2(
								'get_interval',
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, userStore.kT)),
								_Utils_Tuple2(
								'get_enabled',
								$elm$json$Json$Encode$bool(userStore.kS)),
								_Utils_Tuple2(
								'user_store_group_id',
								$elm$json$Json$Encode$int(userStore.mh))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5(
							$author$project$Api$post,
							$author$project$Endpoints$Cms$editUsersStoresEndpoints.ad,
							model.bu,
							body,
							$author$project$Pages$Cms$Stores$EditUsersStores$updateResponseDecoder,
							$author$project$Pages$Cms$Stores$EditUsersStores$CompletedUpdateUserStore(userStore.hs)));
				}
			case 13:
				var userStore = msg.a;
				var _v2 = model.hk;
				if (_v2.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var selectedUser = _v2.a;
					if (userStore.hs < 0) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'user_id',
									$elm$json$Json$Encode$int(selectedUser)),
									_Utils_Tuple2(
									'store_id',
									$elm$json$Json$Encode$int(userStore.hs)),
									_Utils_Tuple2(
									'ord',
									$elm$json$Json$Encode$int(userStore.mk)),
									_Utils_Tuple2(
									'get_interval',
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, userStore.kT)),
									_Utils_Tuple2(
									'get_enabled',
									$elm$json$Json$Encode$bool(userStore.kS)),
									_Utils_Tuple2(
									'user_store_group_id',
									$elm$json$Json$Encode$int(userStore.mh))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{J: true}),
							A5($author$project$Api$post, $author$project$Endpoints$Cms$editUsersStoresEndpoints.ae, model.bu, body, $author$project$Pages$Cms$Stores$EditUsersStores$updateResponseDecoder, $author$project$Pages$Cms$Stores$EditUsersStores$CompletedAddUserStore));
					}
				}
			case 12:
				var userStoreId = msg.a;
				var _v3 = model.hk;
				if (_v3.$ === 1) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var selectedUser = _v3.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'user_id',
								$elm$json$Json$Encode$int(selectedUser)),
								_Utils_Tuple2(
								'store_id',
								$elm$json$Json$Encode$int(userStoreId))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5(
							$author$project$Api$post,
							$author$project$Endpoints$Cms$editUsersStoresEndpoints.bS,
							model.bu,
							body,
							$author$project$Pages$Cms$Stores$EditUsersStores$updateResponseDecoder,
							$author$project$Pages$Cms$Stores$EditUsersStores$CompletedDeleteUserStore(userStoreId)));
				}
			case 1:
				var selectedUserStr = msg.a;
				var maybeSelectedUser = $elm$core$String$toInt(selectedUserStr);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hk: maybeSelectedUser}),
					A2($author$project$Pages$Cms$Stores$EditUsersStores$postListStores, maybeSelectedUser, model));
			case 2:
				var userStoreId = msg.a;
				var storeOrder = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dT: A3(
								$elm_community$list_extra$List$Extra$updateIf,
								function (us) {
									return _Utils_eq(us.hs, userStoreId);
								},
								function (us) {
									return _Utils_update(
										us,
										{
											at: true,
											mk: A2(
												$elm$core$Maybe$withDefault,
												0,
												$elm$core$String$toInt(storeOrder))
										});
								},
								model.dT)
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var storeOrder = msg.a;
				var newUserStore = model.s;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{
									at: true,
									mk: A2(
										$elm$core$Maybe$withDefault,
										0,
										$elm$core$String$toInt(storeOrder))
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var userStoreId = msg.a;
				var getIntervalStr = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dT: A3(
								$elm_community$list_extra$List$Extra$updateIf,
								function (us) {
									return _Utils_eq(us.hs, userStoreId);
								},
								function (us) {
									return _Utils_update(
										us,
										{
											kT: $elm$core$String$toInt(getIntervalStr),
											at: true
										});
								},
								model.dT)
						}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var getIntervalStr = msg.a;
				var newUserStore = model.s;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{
									kT: $elm$core$String$toInt(getIntervalStr),
									at: true
								})
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var userStoreId = msg.a;
				var isGetEnabled = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dT: A3(
								$elm_community$list_extra$List$Extra$updateIf,
								function (us) {
									return _Utils_eq(us.hs, userStoreId);
								},
								function (us) {
									return _Utils_update(
										us,
										{kS: isGetEnabled, at: true});
								},
								model.dT)
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var isGetEnabled = msg.a;
				var newUserStore = model.s;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{kS: isGetEnabled, at: true})
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var userStoreId = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var storeGroupName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					function ($) {
						return $.mi;
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (sg) {
							return _Utils_eq(sg.mh, storeGroupId);
						},
						model.U));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dT: A3(
								$elm_community$list_extra$List$Extra$updateIf,
								function (us) {
									return _Utils_eq(us.hs, userStoreId);
								},
								function (us) {
									return _Utils_update(
										us,
										{at: true, mh: storeGroupId, mi: storeGroupName});
								},
								model.dT)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var userStoreName = msg.a;
				var newUserStore = model.s;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{at: true, jD: userStoreName})
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var selectedStoreIdStr = msg.a;
				var selectedStoreId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(selectedStoreIdStr));
				var newUserStoreName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					function ($) {
						return $.jD;
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (s) {
							return _Utils_eq(s.hs, selectedStoreId);
						},
						model.ap));
				var newUserStore = model.s;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{at: true, hs: selectedStoreId, jD: newUserStoreName})
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var selectedStoreGroupIdStr = msg.a;
				var selectedStoreGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(selectedStoreGroupIdStr));
				var newUserStore = model.s;
				var newStoreGroupName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					function ($) {
						return $.mi;
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (s) {
							return _Utils_eq(s.mh, selectedStoreGroupId);
						},
						model.U));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							s: _Utils_update(
								newUserStore,
								{at: true, mh: selectedStoreGroupId, mi: newStoreGroupName})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$Stores$Yahoo$GotDirectURLs = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Stores$Yahoo$GotProducts = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Stores$Yahoo$GotSubmitResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Stores$Yahoo$DirectURL = F6(
	function (productID, storeID, productName, storeName, url, enabled) {
		return {ev: enabled, cl: productID, lT: productName, cz: storeID, jD: storeName, dS: url};
	});
var $author$project$Pages$Cms$Stores$Yahoo$directURLsDecoder = $elm$json$Json$Decode$list(
	A7(
		$elm$json$Json$Decode$map6,
		$author$project$Pages$Cms$Stores$Yahoo$DirectURL,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'direct_url',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(''),
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))),
		A2($elm$json$Json$Decode$field, 'enabled', $elm$json$Json$Decode$bool)));
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.me));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Api$postWhatever = F4(
	function (path, session, body, msg) {
		var apiConf = $author$project$Session$getConfig(session);
		var config = {
			cK: $elm$http$Http$jsonBody(body),
			cU: $elm$http$Http$expectWhatever(msg),
			cY: $author$project$Api$makeAuthHeader(session),
			dg: $author$project$Api$decodeHTTPMethod(1),
			dK: $elm$core$Maybe$Nothing,
			dN: $elm$core$Maybe$Nothing,
			dS: A3(
				$author$project$Api$makeEndpoint,
				apiConf,
				_List_fromArray(
					[path]),
				_List_Nil)
		};
		return $elm$http$Http$request(config);
	});
var $author$project$Pages$Cms$Stores$Yahoo$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eK: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ja: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var userID = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b4: false, eR: true, cr: userID}),
					A4(
						$author$project$Api$get,
						$author$project$Endpoints$Cms$yahooEndpoints.kV(
							_List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'user_id', userID)
								])),
						model.bu,
						$author$project$Utils$Decoders$productsDecoder,
						$author$project$Pages$Cms$Stores$Yahoo$GotProducts));
			case 4:
				var productID = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b4: true, hg: productID}),
					A4(
						$author$project$Api$get,
						$author$project$Endpoints$Cms$yahooEndpoints.kR(
							_List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'product_id', productID),
									A2($elm$url$Url$Builder$string, 'user_id', model.cr)
								])),
						model.bu,
						$author$project$Pages$Cms$Stores$Yahoo$directURLsDecoder,
						$author$project$Pages$Cms$Stores$Yahoo$GotDirectURLs));
			case 5:
				var productID = msg.a;
				var storeID = msg.b;
				var url = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: A2(
								$krisajenkins$remotedata$RemoteData$map,
								A2(
									$elm_community$list_extra$List$Extra$updateIf,
									function (data) {
										return _Utils_eq(data.cl, productID) && _Utils_eq(data.cz, storeID);
									},
									function (data) {
										return _Utils_update(
											data,
											{dS: url});
									}),
								model.aG)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var productID = msg.a;
				var storeID = msg.b;
				var enabled = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: A2(
								$krisajenkins$remotedata$RemoteData$map,
								A2(
									$elm_community$list_extra$List$Extra$updateIf,
									function (data) {
										return _Utils_eq(data.cl, productID) && _Utils_eq(data.cz, storeID);
									},
									function (data) {
										return _Utils_update(
											data,
											{ev: enabled});
									}),
								model.aG)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var directURL = msg.a;
				return _Utils_Tuple2(
					model,
					A4(
						$author$project$Api$postWhatever,
						$author$project$Endpoints$Cms$yahooEndpoints.ad(_List_Nil),
						model.bu,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'user_id',
									$elm$json$Json$Encode$string(model.cr)),
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(directURL.cl)),
									_Utils_Tuple2(
									'store_id',
									$elm$json$Json$Encode$int(directURL.cz)),
									_Utils_Tuple2(
									'direct_url',
									$elm$json$Json$Encode$string(directURL.dS)),
									_Utils_Tuple2(
									'enabled',
									$elm$json$Json$Encode$bool(directURL.ev))
								])),
						$author$project$Pages$Cms$Stores$Yahoo$GotSubmitResponse));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Cms$updateWithCmsModel = F3(
	function (toMsg, cmsModel, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple2(cmsModel, subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Pages$Cms$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$23:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (_v0.b.a.$ === 1) {
						var espMsg = _v0.a.a;
						var _v1 = _v0.b;
						var espModel = _v1.a.a;
						var navModel = _v1.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditStoreProperties,
							$author$project$Pages$Cms$GotEditStorePropertiesMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$EditStoreProperties$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 1:
					if (_v0.b.a.$ === 2) {
						var espMsg = _v0.a.a;
						var _v2 = _v0.b;
						var espModel = _v2.a.a;
						var navModel = _v2.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditPermalinkGroups,
							$author$project$Pages$Cms$GotEditPermalinkGroupsMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$EditPermalinkGroups$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 2:
					if (_v0.b.a.$ === 3) {
						var espMsg = _v0.a.a;
						var _v3 = _v0.b;
						var espModel = _v3.a.a;
						var navModel = _v3.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditStoreGroups,
							$author$project$Pages$Cms$GotEditStoreGroupsMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$EditStoreGroups$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 3:
					if (_v0.b.a.$ === 4) {
						var espMsg = _v0.a.a;
						var _v4 = _v0.b;
						var espModel = _v4.a.a;
						var navModel = _v4.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditMailAddress,
							$author$project$Pages$Cms$GotEditMailAddressMsg,
							navModel,
							A2($author$project$Pages$Cms$Others$EditMailAddress$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 21:
					if (_v0.b.a.$ === 5) {
						var espMsg = _v0.a.a;
						var _v5 = _v0.b;
						var espModel = _v5.a.a;
						var navModel = _v5.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$GetAccessCount,
							$author$project$Pages$Cms$GotGetAccessCountMsg,
							navModel,
							A2($author$project$Pages$Cms$Others$GetAccessCount$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 4:
					if (_v0.b.a.$ === 6) {
						var espMsg = _v0.a.a;
						var _v6 = _v0.b;
						var espModel = _v6.a.a;
						var navModel = _v6.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditUsersStores,
							$author$project$Pages$Cms$GotEditUsersStoresMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$EditUsersStores$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				case 8:
					if (_v0.b.a.$ === 10) {
						var uwMsg = _v0.a.a;
						var _v7 = _v0.b;
						var uwModel = _v7.a.a;
						var navModel = _v7.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateWeek30ByCsv,
							$author$project$Pages$Cms$GotUpdateWeek30ByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateWeek30ByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 9:
					if (_v0.b.a.$ === 11) {
						var uwMsg = _v0.a.a;
						var _v8 = _v0.b;
						var uwModel = _v8.a.a;
						var navModel = _v8.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateTletProductsByCsv,
							$author$project$Pages$Cms$GotUpdateTletProductsByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateTletProductsByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 10:
					if (_v0.b.a.$ === 12) {
						var uwMsg = _v0.a.a;
						var _v9 = _v0.b;
						var uwModel = _v9.a.a;
						var navModel = _v9.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateTescomProductsByCsv,
							$author$project$Pages$Cms$GotUpdateTescomProductsByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 5:
					if (_v0.b.a.$ === 7) {
						var uwMsg = _v0.a.a;
						var _v10 = _v0.b;
						var uwModel = _v10.a.a;
						var navModel = _v10.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateHitachiWeeklyProductsByCsv,
							$author$project$Pages$Cms$GotUpdateHitachiWeeklyProductsByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 6:
					if (_v0.b.a.$ === 8) {
						var uwMsg = _v0.a.a;
						var _v11 = _v0.b;
						var uwModel = _v11.a.a;
						var navModel = _v11.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateHitachiProductsOrderByCsv,
							$author$project$Pages$Cms$GotUpdateHIitachiProductsOrderByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 7:
					if (_v0.b.a.$ === 9) {
						var uwMsg = _v0.a.a;
						var _v12 = _v0.b;
						var uwModel = _v12.a.a;
						var navModel = _v12.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UpdateSharpProductsByCsv,
							$author$project$Pages$Cms$GotUpdateSharpProductsByCsv,
							navModel,
							A2($author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$update, uwMsg, uwModel));
					} else {
						break _v0$23;
					}
				case 12:
					if (_v0.b.a.$ === 14) {
						var reiMsg = _v0.a.a;
						var _v13 = _v0.b;
						var reiModel = _v13.a.a;
						var navModel = _v13.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$RakutenExcludedItems,
							$author$project$Pages$Cms$GotRakutenExcludedItems,
							navModel,
							A2($author$project$Pages$Cms$Products$RakutenExcludedItems$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 11:
					if (_v0.b.a.$ === 13) {
						var reiMsg = _v0.a.a;
						var _v14 = _v0.b;
						var reiModel = _v14.a.a;
						var navModel = _v14.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$UserSpecificRoutingModel,
							$author$project$Pages$Cms$GotUserSpecificRoutingMsg,
							navModel,
							A2($author$project$Pages$Cms$Products$UserSpecificRouting$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 13:
					if (_v0.b.a.$ === 15) {
						var reiMsg = _v0.a.a;
						var _v15 = _v0.b;
						var reiModel = _v15.a.a;
						var navModel = _v15.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$ChangeDisplayIntervalModel,
							$author$project$Pages$Cms$GotChangeDisplayIntervalMsg,
							navModel,
							A2($author$project$Pages$Cms$Products$ChangeDisplayInterval$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 14:
					if (_v0.b.a.$ === 16) {
						var reiMsg = _v0.a.a;
						var _v16 = _v0.b;
						var reiModel = _v16.a.a;
						var navModel = _v16.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$ChangeUserProductModel,
							$author$project$Pages$Cms$GotChangeUserProductMsg,
							navModel,
							A2($author$project$Pages$Cms$Products$ChangeUserProduct$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 15:
					if (_v0.b.a.$ === 17) {
						var reiMsg = _v0.a.a;
						var _v17 = _v0.b;
						var reiModel = _v17.a.a;
						var navModel = _v17.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$AddProductConfigModel,
							$author$project$Pages$Cms$GotAddProductConfigMsg,
							navModel,
							A2($author$project$Pages$Cms$Products$AddProductConfig$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 16:
					if (_v0.b.a.$ === 18) {
						var reiMsg = _v0.a.a;
						var _v18 = _v0.b;
						var reiModel = _v18.a.a;
						var navModel = _v18.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$ChangeDisplayOrderModel,
							$author$project$Pages$Cms$GotChangeDisplayOrderMsg,
							navModel,
							A2($author$project$Pages$Cms$Products$ChangeDisplayOrder$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 17:
					if (_v0.b.a.$ === 19) {
						var reiMsg = _v0.a.a;
						var _v19 = _v0.b;
						var reiModel = _v19.a.a;
						var navModel = _v19.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditProductsPermalinks,
							$author$project$Pages$Cms$GotEditProductsPermalinks,
							navModel,
							A2($author$project$Pages$Cms$Products$EditProductsPermalinks$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 18:
					if (_v0.b.a.$ === 20) {
						var reiMsg = _v0.a.a;
						var _v20 = _v0.b;
						var reiModel = _v20.a.a;
						var navModel = _v20.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditProducts,
							$author$project$Pages$Cms$GotEditProducts,
							navModel,
							A2($author$project$Pages$Cms$Products$EditProducts$update, reiMsg, reiModel));
					} else {
						break _v0$23;
					}
				case 19:
					var navMsg = _v0.a.a;
					var _v21 = _v0.b;
					var cmsModel = _v21.a;
					var navModel = _v21.b;
					return A3(
						$author$project$Pages$Cms$updateWithCmsModel,
						$author$project$Pages$Cms$GotNavBarMsg,
						cmsModel,
						A2($author$project$Components$NavBar$update, navMsg, navModel));
				case 20:
					if (_v0.b.a.$ === 21) {
						var espMsg = _v0.a.a;
						var _v22 = _v0.b;
						var espModel = _v22.a.a;
						var navModel = _v22.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$EditStores,
							$author$project$Pages$Cms$GotEditStoresMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$EditStores$update, espMsg, espModel));
					} else {
						break _v0$23;
					}
				default:
					if (_v0.b.a.$ === 22) {
						var yMsg = _v0.a.a;
						var _v23 = _v0.b;
						var yModel = _v23.a.a;
						var navModel = _v23.b;
						return A4(
							$author$project$Pages$Cms$updateWithNavModel,
							$author$project$Pages$Cms$Yahoo,
							$author$project$Pages$Cms$GotYahooMsg,
							navModel,
							A2($author$project$Pages$Cms$Stores$Yahoo$update, yMsg, yModel));
					} else {
						break _v0$23;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Pages$Login$FormSent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Login$LoginPayload = F3(
	function (token, isAdmin, userName) {
		return {gJ: isAdmin, hy: token, mD: userName};
	});
var $author$project$Endpoints$Login$login = A2(
	$elm$url$Url$Builder$absolute,
	_List_fromArray(
		['login']),
	_List_Nil);
var $author$project$Api$storeUserInfo = _Platform_outgoingPort('storeUserInfo', $elm$core$Basics$identity);
var $author$project$Pages$Login$updateForm = F2(
	function (model, f) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					aO: f(model.aO)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Pages$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var input = msg.a;
				return A2(
					$author$project$Pages$Login$updateForm,
					model,
					function (form) {
						return _Utils_update(
							form,
							{mD: input});
					});
			case 2:
				var input = msg.a;
				return A2(
					$author$project$Pages$Login$updateForm,
					model,
					function (form) {
						return _Utils_update(
							form,
							{dn: input});
					});
			case 0:
				var decoder = A4(
					$elm$json$Json$Decode$map3,
					$author$project$Pages$Login$LoginPayload,
					A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'isAdmin', $elm$json$Json$Decode$bool),
					A2($elm$json$Json$Decode$field, 'userName', $elm$json$Json$Decode$string));
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'username',
							$elm$json$Json$Encode$string(model.aO.mD)),
							_Utils_Tuple2(
							'password',
							$elm$json$Json$Encode$string(model.aO.dn))
						]));
				return _Utils_Tuple2(
					model,
					A5($author$project$Api$post, $author$project$Endpoints$Login$login, model.bu, body, decoder, $author$project$Pages$Login$FormSent));
			default:
				if (!msg.a.$) {
					var loginPayload = msg.a.a;
					var msg_ = $author$project$Api$storeUserInfo(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'token',
									$elm$json$Json$Encode$string(loginPayload.hy)),
									_Utils_Tuple2(
									'isAdmin',
									$elm$json$Json$Encode$bool(loginPayload.gJ)),
									_Utils_Tuple2(
									'userName',
									$elm$json$Json$Encode$string(loginPayload.mD))
								])));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing}),
						msg_);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(e))
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$User$TimeSeqModel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$time_extra$Time$Extra$Minute = 13;
var $justinmimbs$time_extra$Time$Extra$Day = 11;
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$time_extra$Time$Extra$Millisecond = 15;
var $justinmimbs$time_extra$Time$Extra$Month = 2;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {gp: d, lp: m, mL: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		gY: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		mL: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.mL, 0, date.gY);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.mL - 1)) + ($justinmimbs$date$Date$monthToNumber(date.lp) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.gp,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$add = F4(
	function (interval, n, zone, posix) {
		add:
		while (true) {
			switch (interval) {
				case 15:
					return $elm$time$Time$millisToPosix(
						$elm$time$Time$posixToMillis(posix) + n);
				case 14:
					var $temp$interval = 15,
						$temp$n = n * 1000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 13:
					var $temp$interval = 15,
						$temp$n = n * 60000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 12:
					var $temp$interval = 15,
						$temp$n = n * 3600000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 11:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							3,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 2:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							1,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 0:
					var $temp$interval = 2,
						$temp$n = n * 12,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 1:
					var $temp$interval = 2,
						$temp$n = n * 3,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 3:
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				default:
					var weekday = interval;
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Save = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {gn: current, I: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {hp: standardOffset, hF: zoneRules};
	});
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {aE: clock, gp: day, lp: month, fV: time, mL: year};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.hq <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.b, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.aZ;
		var save = _v0.l2;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.mL;
	var month = _v0.lp;
	var day = _v0.gp;
	var time = _v0.fV;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.aE, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.hq;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								aE: rule.aE,
								l2: rule.l2,
								hq: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.gp;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lp, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lp, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lp, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.lp));
										}
									}()) + rule.fV
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.ik, year) < 1) && (_Utils_cmp(year, rule.jK) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.mL - 1, until.mL));
		var initialOffset = {l2: 0, aZ: standardOffset};
		var initialChange = {
			b: standardOffset,
			hq: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {l2: transition.l2, aZ: standardOffset};
					if (_Utils_cmp(
						transition.hq + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aE, previousOffset),
						initialChange.hq) < 1) {
						var updatedInitialChange = {b: standardOffset + transition.l2, hq: initialChange.hq};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.hq + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aE, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								b: standardOffset + transition.l2,
								hq: transition.hq + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aE, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.hp;
		var zoneRules = _v0.hF;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						b: standardOffset + save,
						hq: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{l2: save, aZ: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.I);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.gn, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.I;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.gn;
			}
		}();
		var initialOffset = {
			l2: function () {
				var _v4 = initialState.hF;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			aZ: initialState.hp
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.b;
			},
			initialOffset.aZ + initialOffset.l2,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.aZ + initialOffset.l2, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $justinmimbs$timezone_data$TimeZone$asia__tokyo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$Api$createFixedMidashi = _Platform_outgoingPort(
	'createFixedMidashi',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $justinmimbs$time_extra$Time$Extra$Parts = F7(
	function (year, month, day, hour, minute, second, millisecond) {
		return {gp: day, k_: hour, ll: millisecond, ln: minute, lp: month, l7: second, mL: year};
	});
var $author$project$Utils$Helpers$intToMonth = function (num) {
	switch (num) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		case 12:
			return 11;
		default:
			return 0;
	}
};
var $author$project$Utils$Helpers$dateTimeToParts = F2(
	function (date, time) {
		var timeList = A2(
			$elm$core$List$take,
			2,
			A2($elm$core$String$split, ':', time));
		var dateList = A2(
			$elm$core$List$take,
			3,
			A2($elm$core$String$split, '-', date));
		var _v0 = _Utils_Tuple2(dateList, timeList);
		if ((((((_v0.a.b && _v0.a.b.b) && _v0.a.b.b.b) && (!_v0.a.b.b.b.b)) && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.b.b)) {
			var _v1 = _v0.a;
			var year = _v1.a;
			var _v2 = _v1.b;
			var month = _v2.a;
			var _v3 = _v2.b;
			var day = _v3.a;
			var _v4 = _v0.b;
			var hour = _v4.a;
			var _v5 = _v4.b;
			var minute = _v5.a;
			var _v6 = _List_fromArray(
				[
					$elm$core$String$toInt(year),
					$elm$core$String$toInt(month),
					$elm$core$String$toInt(day),
					$elm$core$String$toInt(hour),
					$elm$core$String$toInt(minute)
				]);
			if ((((((((((_v6.b && (!_v6.a.$)) && _v6.b.b) && (!_v6.b.a.$)) && _v6.b.b.b) && (!_v6.b.b.a.$)) && _v6.b.b.b.b) && (!_v6.b.b.b.a.$)) && _v6.b.b.b.b.b) && (!_v6.b.b.b.b.a.$)) && (!_v6.b.b.b.b.b.b)) {
				var iyear = _v6.a.a;
				var _v7 = _v6.b;
				var imonth = _v7.a.a;
				var _v8 = _v7.b;
				var iday = _v8.a.a;
				var _v9 = _v8.b;
				var ihour = _v9.a.a;
				var _v10 = _v9.b;
				var iminute = _v10.a.a;
				return $elm$core$Maybe$Just(
					A7(
						$justinmimbs$time_extra$Time$Extra$Parts,
						iyear,
						$author$project$Utils$Helpers$intToMonth(imonth),
						iday,
						ihour,
						iminute,
						0,
						0));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $author$project$Utils$Helpers$flip = function (f) {
	return F2(
		function (a, b) {
			return A2(f, b, a);
		});
};
var $author$project$Utils$Helpers$floorTimeByInterval = F2(
	function (parts, interval) {
		return function (minute) {
			return _Utils_update(
				parts,
				{ln: minute});
		}(interval * ((parts.ln / interval) | 0));
	});
var $author$project$Utils$Helpers$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$Utils$Helpers$formatDate = F3(
	function (year, month, day) {
		return $elm$core$String$fromInt(year) + ('-' + (A2(
			$elm$core$String$right,
			2,
			'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(month)) + ('-' + A2(
			$elm$core$String$right,
			2,
			'0' + $elm$core$String$fromInt(day)))));
	});
var $author$project$Utils$Helpers$formatTime = F2(
	function (hour, minute) {
		return A2(
			$elm$core$String$right,
			2,
			'0' + $elm$core$String$fromInt(hour)) + (':' + A2(
			$elm$core$String$right,
			2,
			'0' + $elm$core$String$fromInt(minute)));
	});
var $author$project$Utils$Helpers$formatDateTime = function (parts) {
	return A3($author$project$Utils$Helpers$formatDate, parts.mL, parts.lp, parts.gp) + (' ' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln));
};
var $author$project$Pages$Users$BraunComnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Utils$Types$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Utils$Decoders$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Utils$Types$Price))))))))))));
var $author$project$Utils$Decoders$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Utils$Decoders$priceDecoder)));
var $author$project$Utils$Types$StoreGroup = F3(
	function (storeGroupId, storeGroupName, stores) {
		return {mh: storeGroupId, mi: storeGroupName, ap: stores};
	});
var $author$project$Utils$Decoders$storeGroupsDecoder = $elm$json$Json$Decode$list(
	A4(
		$elm$json$Json$Decode$map3,
		$author$project$Utils$Types$StoreGroup,
		A2($elm$json$Json$Decode$field, 'store_group_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder)));
var $author$project$Pages$Users$BraunComnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$BraunComnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Set$isEmpty = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$isEmpty(dict);
};
var $author$project$Pages$Users$BraunComnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$BraunComnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $author$project$Utils$Helpers$splitRight3 = F2(
	function (str, lst) {
		splitRight3:
		while (true) {
			if (str === '') {
				return lst;
			} else {
				var right = A2($elm$core$String$right, 3, str);
				var rest = A2($elm$core$String$dropRight, 3, str);
				var $temp$str = rest,
					$temp$lst = A2($elm$core$List$cons, right, lst);
				str = $temp$str;
				lst = $temp$lst;
				continue splitRight3;
			}
		}
	});
var $author$project$Utils$Helpers$toCommmaSplited = function (src) {
	return A2(
		$elm$core$String$join,
		',',
		A2($author$project$Utils$Helpers$splitRight3, src, _List_Nil));
};
var $author$project$Pages$Users$BraunComnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				if (product.lT === 'blank') {
					return _List_Nil;
				} else {
					var maybeYodobashiPrice = A2(
						$elm$core$Dict$get,
						_Utils_Tuple2(product.i8, 7),
						model.ck);
					var lowestPrice = A3(
						$elm$core$List$foldl,
						function (price) {
							return function (m) {
								return A2($elm$core$Basics$min, m, price);
							};
						},
						A2($elm$core$Basics$pow, 2, 30),
						A2(
							$elm$core$List$map,
							function (maybePrice) {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
								} else {
									return A2($elm$core$Basics$pow, 2, 30);
								}
							},
							A2(
								$elm$core$List$map,
								function (store) {
									return A2(
										$elm$core$Dict$get,
										_Utils_Tuple2(product.i8, store.hs),
										model.ck);
								},
								stores)));
					var itemList = _List_fromArray(
						[
							{iF: '税抜き', i: 0},
							{iF: '税込み', i: 1},
							{iF: 'P/クーポン', i: 2},
							{iF: 'P後', i: 3},
							{iF: '取得時刻', i: 4},
							{iF: '工事費', i: 5}
						]);
					var filterItemList = function (list) {
						return A2(
							$elm$core$List$map,
							function (i) {
								return A2(
									$elm$core$Maybe$withDefault,
									{iF: '', i: -1},
									A2($elm_community$list_extra$List$Extra$getAt, i, list));
							},
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									'DisplayAll',
									_List_fromArray(
										[0, 1, 2, 3, 4, 5])),
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (dispToValue) {
										return _Utils_eq(dispToValue.a, model.cq);
									},
									$author$project$Pages$Users$BraunComnet$Hour$displayStyleToValues)).b);
					};
					var filteredItemList = filterItemList(itemList);
					var _v0 = function () {
						var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
						if (_v1.a && (!_v1.b.$)) {
							var yodobashiPrice = _v1.b.a;
							return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
						} else {
							return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
						}
					}();
					var basePrice = _v0.ka;
					var basePriceIncludeTax = _v0.eb;
					var basePoint = _v0.j9;
					var basePriceWithoutPoint = _v0.bM;
					var basePriceList = _List_fromArray(
						[
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePrice)),
							i: 0
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceIncludeTax)),
							i: 1
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePoint)),
							i: 2
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceWithoutPoint)),
							i: 3
						},
							{iF: '-', i: 4},
							{iF: '-', i: 5}
						]);
					var filteredBasePriceList = filterItemList(basePriceList);
					return _Utils_ap(
						_List_fromArray(
							[
								$author$project$Pages$Users$BraunComnet$Hour$ProductPriceHeader(
								_List_fromArray(
									[
										{
										iF: $elm$core$String$fromInt(ind + 1),
										i: 0
									}
									])),
								$author$project$Pages$Users$BraunComnet$Hour$ProductPriceHeader(
								_List_fromArray(
									[
										{iF: product.lT, i: 0}
									])),
								$author$project$Pages$Users$BraunComnet$Hour$ProductPriceHeader(filteredItemList),
								$author$project$Pages$Users$BraunComnet$Hour$ProductPriceHeader(filteredBasePriceList)
							]),
						A2(
							$elm$core$List$map,
							function (store) {
								var maybePrice = A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
								var isLowest = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return _Utils_eq(price.aW, lowestPrice);
									} else {
										return false;
									}
								}();
								var isLowestButHigherThanBase = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v7.a && (!_v7.b.$)) {
											var yodobashiPrice = _v7.b.a;
											return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
										} else {
											return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										}
									} else {
										return false;
									}
								}();
								var isLower = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v5 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v5.a && (!_v5.b.$)) {
											var yodobashiPrice = _v5.b.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										return false;
									}
								}();
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var priceList = _List_fromArray(
										[
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i3)),
											i: 0
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i4)),
											i: 1
										},
											{
											iF: function (point) {
												return _Utils_ap(
													point,
													(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
											}(
												$elm$core$String$fromInt(price.iZ)),
											i: 2
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.aW)),
											i: 3
										},
											{
											iF: A2(
												$elm$core$Maybe$withDefault,
												'',
												$elm$core$List$head(
													A2(
														$elm$core$Maybe$withDefault,
														_List_Nil,
														$elm$core$List$tail(
															A2($elm$core$String$split, ' ', price.kw))))),
											i: 4
										},
											{
											iF: function () {
												var _v3 = price.km;
												if (_v3.$ === 1) {
													return '0';
												} else {
													var constructionCost = _v3.a;
													return $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(constructionCost));
												}
											}(),
											i: 5
										}
										]);
									var filteredPriceList = filterItemList(priceList);
									return $author$project$Pages$Users$BraunComnet$Hour$ProductPriceData(
										{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
								} else {
									return $author$project$Pages$Users$BraunComnet$Hour$ProductPriceNoData;
								}
							},
							stores));
				}
			};
		},
		products);
};
var $author$project$Pages$Users$BraunComnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.mL;
		var month = _v0.lp;
		var day = _v0.gp;
		var hour = _v0.k_;
		var minute = _v0.ln;
		var second = _v0.l7;
		var millisecond = _v0.ll;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Utils$Helpers$roundByInterval = F2(
	function (interval, posix) {
		var minute = posix.ln;
		var mod = A2($elm$core$Basics$modBy, interval, minute);
		return _Utils_update(
			posix,
			{ln: minute - mod});
	});
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $elm$bytes$Bytes$Encode$Utf8 = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$string = function (str) {
	return A2(
		$elm$bytes$Bytes$Encode$Utf8,
		_Bytes_getStringWidth(str),
		str);
};
var $commonmind$elm_csv_encode$Csv$Encode$crlf = $elm$bytes$Bytes$Encode$string('\u000D\n');
var $commonmind$elm_csv_encode$Csv$Encode$escapeString = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$split('\"'),
	$elm$core$String$join('\"\"'));
var $commonmind$elm_csv_encode$Csv$Encode$formatField = function (s) {
	return '\"' + ($commonmind$elm_csv_encode$Csv$Encode$escapeString(s) + '\"');
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $commonmind$elm_csv_encode$Csv$Encode$formatRow = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map($commonmind$elm_csv_encode$Csv$Encode$formatField),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$intersperse(','),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($elm$bytes$Bytes$Encode$string),
			$elm$bytes$Bytes$Encode$sequence)));
var $commonmind$elm_csv_encode$Csv$Encode$formatLines = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map($commonmind$elm_csv_encode$Csv$Encode$formatRow),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$intersperse($commonmind$elm_csv_encode$Csv$Encode$crlf),
		$elm$bytes$Bytes$Encode$sequence));
var $commonmind$elm_csv_encode$Csv$Encode$toEncoder = function (_v0) {
	var headers = _v0.cY;
	var records = _v0.lX;
	return $elm$bytes$Bytes$Encode$sequence(
		_List_fromArray(
			[
				$commonmind$elm_csv_encode$Csv$Encode$formatLines(
				A2($elm$core$List$cons, headers, records)),
				$commonmind$elm_csv_encode$Csv$Encode$crlf
			]));
};
var $commonmind$elm_csv_encode$Csv$Encode$toBytes = A2($elm$core$Basics$composeR, $commonmind$elm_csv_encode$Csv$Encode$toEncoder, $elm$bytes$Bytes$Encode$encode);
var $author$project$Pages$Users$BraunComnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$BraunComnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $author$project$Pages$Users$BraunComnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$BraunComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$BraunComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$BraunComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$BraunComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$BraunComnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$BraunComnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$BraunComnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$BraunComnet$Wide$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Utils$Helpers$filterBySelector = F4(
	function (selectedMakerId, selectedBigCategoryId, selectedSmallCategoryId, products) {
		return A2(
			$elm$core$List$filter,
			function (p) {
				return _Utils_eq(
					selectedSmallCategoryId,
					$elm$core$Maybe$Just(-1)) || _Utils_eq(
					$elm$core$Maybe$Just(p.ho),
					selectedSmallCategoryId);
			},
			A2(
				$elm$core$List$filter,
				function (p) {
					return _Utils_eq(
						selectedBigCategoryId,
						$elm$core$Maybe$Just(-1)) || _Utils_eq(
						$elm$core$Maybe$Just(p.ed),
						selectedBigCategoryId);
				},
				A2(
					$elm$core$List$filter,
					function (p) {
						return _Utils_eq(
							selectedMakerId,
							$elm$core$Maybe$Just(-1)) || _Utils_eq(
							$elm$core$Maybe$Just(p.iJ),
							selectedMakerId);
					},
					products)));
	});
var $author$project$Pages$Users$BraunComnet$Wide$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$BraunComnet$Wide$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$BraunComnet$Wide$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$BraunComnet$Wide$Price))))))))))));
var $author$project$Pages$Users$BraunComnet$Wide$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$BraunComnet$Wide$priceDecoder)));
var $author$project$Pages$Users$BraunComnet$Wide$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$BraunComnet$Wide$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$BraunComnet$Wide$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $Herteby$url_builder_plus$UrlBuilderPlus$list = F3(
	function (encoder, key, values) {
		return _Utils_eq(values, _List_Nil) ? $Herteby$url_builder_plus$UrlBuilderPlus$None : A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$string,
			key,
			A2(
				$elm$core$String$join,
				',',
				A2(
					$elm$core$List$filterMap,
					function (p) {
						if (!p.$) {
							var str = p.b;
							return $elm$core$Maybe$Just(str);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					A2(
						$elm$core$List$map,
						encoder(''),
						values))));
	});
var $author$project$Pages$Users$BraunComnet$Wide$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$Lower = 1;
var $author$project$Pages$Users$BraunComnet$Wide$Lowest = 0;
var $author$project$Pages$Users$BraunComnet$Wide$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$BraunComnet$Wide$Normal = 3;
var $author$project$Pages$Users$BraunComnet$Wide$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$BraunComnet$Wide$Unchanged = {$: 2};
var $justinmimbs$time_extra$Time$Extra$Week = 3;
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Month = 2;
var $justinmimbs$date$Date$Quarter = 1;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $justinmimbs$date$Date$Week = 3;
var $justinmimbs$date$Date$Year = 0;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.lp;
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$time_extra$Time$Extra$floorDate = F3(
	function (dateInterval, zone, posix) {
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A2(
				$justinmimbs$date$Date$floor,
				dateInterval,
				A2($justinmimbs$date$Date$fromPosix, zone, posix)),
			0);
	});
var $justinmimbs$time_extra$Time$Extra$floor = F3(
	function (interval, zone, posix) {
		switch (interval) {
			case 15:
				return posix;
			case 14:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						A2($elm$time$Time$toSecond, zone, posix),
						0));
			case 13:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						0,
						0));
			case 12:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						0,
						0,
						0));
			case 11:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 11, zone, posix);
			case 2:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 2, zone, posix);
			case 0:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 0, zone, posix);
			case 1:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 1, zone, posix);
			case 3:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 3, zone, posix);
			case 4:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 4, zone, posix);
			case 5:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 5, zone, posix);
			case 6:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 6, zone, posix);
			case 7:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 7, zone, posix);
			case 8:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 8, zone, posix);
			case 9:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 9, zone, posix);
			default:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 10, zone, posix);
		}
	});
var $justinmimbs$time_extra$Time$Extra$toFractionalDay = F2(
	function (zone, posix) {
		return A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix) / 86400000;
	});
var $justinmimbs$time_extra$Time$Extra$toMonths = F2(
	function (zone, posix) {
		var wholeMonths = (12 * (A2($elm$time$Time$toYear, zone, posix) - 1)) + ($justinmimbs$date$Date$monthToNumber(
			A2($elm$time$Time$toMonth, zone, posix)) - 1);
		var fractionalMonth = (A2($elm$time$Time$toDay, zone, posix) + A2($justinmimbs$time_extra$Time$Extra$toFractionalDay, zone, posix)) / 100;
		return wholeMonths + fractionalMonth;
	});
var $justinmimbs$time_extra$Time$Extra$toRataDieMoment = F2(
	function (zone, posix) {
		return $justinmimbs$date$Date$toRataDie(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$toFractionalDay, zone, posix);
	});
var $elm$core$Basics$truncate = _Basics_truncate;
var $justinmimbs$time_extra$Time$Extra$diff = F4(
	function (interval, zone, posix1, posix2) {
		diff:
		while (true) {
			switch (interval) {
				case 15:
					return $elm$time$Time$posixToMillis(posix2) - $elm$time$Time$posixToMillis(posix1);
				case 14:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 15, zone, posix1, posix2) / 1000) | 0;
				case 13:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 15, zone, posix1, posix2) / 60000) | 0;
				case 12:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 15, zone, posix1, posix2) / 3600000) | 0;
				case 11:
					return (A2($justinmimbs$time_extra$Time$Extra$toRataDieMoment, zone, posix2) - A2($justinmimbs$time_extra$Time$Extra$toRataDieMoment, zone, posix1)) | 0;
				case 2:
					return (A2($justinmimbs$time_extra$Time$Extra$toMonths, zone, posix2) - A2($justinmimbs$time_extra$Time$Extra$toMonths, zone, posix1)) | 0;
				case 0:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 2, zone, posix1, posix2) / 12) | 0;
				case 1:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 2, zone, posix1, posix2) / 3) | 0;
				case 3:
					return (A4($justinmimbs$time_extra$Time$Extra$diff, 11, zone, posix1, posix2) / 7) | 0;
				default:
					var weekday = interval;
					var $temp$interval = 3,
						$temp$zone = zone,
						$temp$posix1 = A3($justinmimbs$time_extra$Time$Extra$floor, weekday, zone, posix1),
						$temp$posix2 = A3($justinmimbs$time_extra$Time$Extra$floor, weekday, zone, posix2);
					interval = $temp$interval;
					zone = $temp$zone;
					posix1 = $temp$posix1;
					posix2 = $temp$posix2;
					continue diff;
			}
		}
	});
var $author$project$Pages$Users$BraunComnet$Wide$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Utils$Helpers$formatDateWithoutYear = F2(
	function (month, day) {
		return A2(
			$elm$core$String$right,
			2,
			'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(month)) + ('-' + A2(
			$elm$core$String$right,
			2,
			'0' + $elm$core$String$fromInt(day)));
	});
var $author$project$Utils$Helpers$formatDateTimeWithoutYear = function (parts) {
	return A2($author$project$Utils$Helpers$formatDateWithoutYear, parts.lp, parts.gp) + (' ' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln));
};
var $author$project$Pages$Users$BraunComnet$Wide$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$BraunComnet$Wide$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$BraunComnet$Wide$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$BraunComnet$Wide$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$BraunComnet$Wide$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$BraunComnet$Wide$Unchanged);
											} else {
												return $author$project$Pages$Users$BraunComnet$Wide$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$BraunComnet$Wide$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$BraunComnet$Wide$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$BraunComnet$Wide$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.i5;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($justinmimbs$date$Date$deadEndToString),
			$elm$core$String$join('; '))));
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.gp;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.gY;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		jW: 1 + (((rd - week1Day1) / 7) | 0),
		jX: wy,
		mG: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.jW;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.jX;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.e$(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.gT(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.e$(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.gq(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.bC(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.bC(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.bC(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.hD(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bC(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.bC(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	gq: $justinmimbs$date$Date$withOrdinalSuffix,
	gT: $justinmimbs$date$Date$monthToName,
	e$: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	hD: $justinmimbs$date$Date$weekdayToName,
	bC: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Pages$Users$BraunComnet$Wide$succDate = function (dateString) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(dateString);
	if (!_v0.$) {
		var date = _v0.a;
		return $justinmimbs$date$Date$toIsoString(
			A3($justinmimbs$date$Date$add, 3, 1, date));
	} else {
		return dateString;
	}
};
var $author$project$Utils$Helpers$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$BraunComnet$Wide$insertHendouAfterStoreName = F2(
	function (acc, list) {
		insertHendouAfterStoreName:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(acc);
			} else {
				var x = list.a;
				var xs = list.b;
				if ($elm$core$List$length(acc) >= 6) {
					var $temp$acc = A2(
						$elm$core$List$cons,
						x,
						A2(
							$elm$core$List$cons,
							{mh: -2, jD: '価格変動'},
							acc)),
						$temp$list = xs;
					acc = $temp$acc;
					list = $temp$list;
					continue insertHendouAfterStoreName;
				} else {
					var $temp$acc = A2($elm$core$List$cons, x, acc),
						$temp$list = xs;
					acc = $temp$acc;
					list = $temp$list;
					continue insertHendouAfterStoreName;
				}
			}
		}
	});
var $author$project$Pages$Users$BraunComnet$Wide$PProductLowerMark = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$PProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$PProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$PProductPriceNoData = {$: 1};
var $author$project$Pages$Users$BraunComnet$Wide$convertBodyToProcBody = function (bodyProductPrice) {
	switch (bodyProductPrice.$) {
		case 0:
			var _v1 = bodyProductPrice.a;
			var rowspan = _v1.a;
			var list = _v1.b;
			return $author$project$Pages$Users$BraunComnet$Wide$PProductPriceHeader(
				_Utils_Tuple2(rowspan, list));
		case 1:
			return $author$project$Pages$Users$BraunComnet$Wide$PProductPriceNoData;
		default:
			var data = bodyProductPrice.a;
			return $author$project$Pages$Users$BraunComnet$Wide$PProductPriceData(
				{cO: data.cO, fl: data.fl, g2: data.g2, dS: data.dS});
	}
};
var $author$project$Pages$Users$BraunComnet$Wide$insertLowerMark = function (bodyProductPrices) {
	var insertLowerMarkSub = function (bodyProductPrice) {
		if (!bodyProductPrice.b) {
			return _List_Nil;
		} else {
			var x = bodyProductPrice.a;
			var xs = bodyProductPrice.b;
			switch (x.$) {
				case 2:
					var data = x.a;
					var lowerMark = function () {
						var _v2 = data.cO;
						switch (_v2) {
							case 0:
								return $author$project$Pages$Users$BraunComnet$Wide$PProductLowerMark(
									{
										dd: _List_fromArray(
											[
												{iF: '●', i: -1}
											])
									});
							case 1:
								return $author$project$Pages$Users$BraunComnet$Wide$PProductLowerMark(
									{
										dd: _List_fromArray(
											[
												{iF: '※', i: -1}
											])
									});
							default:
								return $author$project$Pages$Users$BraunComnet$Wide$PProductLowerMark(
									{
										dd: _List_fromArray(
											[
												{iF: '', i: -1}
											])
									});
						}
					}();
					return A2(
						$elm$core$List$cons,
						$author$project$Pages$Users$BraunComnet$Wide$convertBodyToProcBody(x),
						A2(
							$elm$core$List$cons,
							lowerMark,
							insertLowerMarkSub(xs)));
				case 1:
					var lowerMark = $author$project$Pages$Users$BraunComnet$Wide$PProductLowerMark(
						{
							dd: _List_fromArray(
								[
									{iF: '', i: -1}
								])
						});
					return A2(
						$elm$core$List$cons,
						$author$project$Pages$Users$BraunComnet$Wide$convertBodyToProcBody(x),
						A2(
							$elm$core$List$cons,
							lowerMark,
							insertLowerMarkSub(xs)));
				default:
					return A2(
						$elm$core$List$cons,
						$author$project$Pages$Users$BraunComnet$Wide$convertBodyToProcBody(x),
						insertLowerMarkSub(xs));
			}
		}
	};
	return A2(
		$elm$core$List$map,
		function (productRow) {
			return A2($elm$core$List$map, insertLowerMarkSub, productRow);
		},
		bodyProductPrices);
};
var $author$project$Pages$Users$BraunComnet$Wide$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		var procStoreHeader = A2($author$project$Pages$Users$BraunComnet$Wide$insertHendouAfterStoreName, _List_Nil, storeHeader);
		var procPriceRows = $author$project$Pages$Users$BraunComnet$Wide$insertLowerMark(priceRows);
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					procStoreHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										var top_ind = function () {
											var _v5 = A2($elm_community$list_extra$List$Extra$getAt, 0, indices);
											if (!_v5.$) {
												var x = _v5.a;
												return x;
											} else {
												return 0;
											}
										}();
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											A2(
												$elm$core$List$map,
												function (ind) {
													return A2(
														$elm$core$List$map,
														function (cell) {
															switch (cell.$) {
																case 0:
																	var _v1 = cell.a;
																	var data = _v1.b;
																	var res = function () {
																		var _v2 = A2(
																			$elm$core$List$filter,
																			function (item) {
																				return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																			},
																			data);
																		if (!_v2.b) {
																			return $elm$core$Maybe$Nothing;
																		} else {
																			var x = _v2.a;
																			return $elm$core$Maybe$Just(x.iF);
																		}
																	}();
																	return A2($elm$core$Maybe$withDefault, '', res);
																case 1:
																	return '';
																case 2:
																	var data = cell.a;
																	var res = function () {
																		var _v3 = A2(
																			$elm$core$List$filter,
																			function (item) {
																				return _Utils_eq(item.i, ind);
																			},
																			data.g2);
																		if (!_v3.b) {
																			return $elm$core$Maybe$Nothing;
																		} else {
																			var x = _v3.a;
																			return $elm$core$Maybe$Just(x.iF);
																		}
																	}();
																	return A2($elm$core$Maybe$withDefault, '', res);
																default:
																	var data = cell.a;
																	var res = function () {
																		var _v4 = A2(
																			$elm$core$List$filter,
																			function (item) {
																				return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																			},
																			data.dd);
																		if (!_v4.b) {
																			return $elm$core$Maybe$Nothing;
																		} else {
																			var x = _v4.a;
																			return $elm$core$Maybe$Just(x.iF);
																		}
																	}();
																	return A2($elm$core$Maybe$withDefault, '', res);
															}
														},
														row);
												},
												indices));
									};
								},
								priceRow));
					},
					procPriceRows))
		};
	});
var $author$project$Pages$Users$BraunComnet$Wide$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 14:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date, dv: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:00'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$BraunComnet$Wide$getPriceResponseDecoder,
								$author$project$Pages$Users$BraunComnet$Wide$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				var bigCategoryId = msg.a;
				var selectedBigCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategoryId);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.cs)),
								_List_Nil),
							dw: selectedMakerId
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$BraunComnet$Wide$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: $author$project$Pages$Users$BraunComnet$Wide$succDate(model.du),
								dH: '00:00',
								fS: '00:00',
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 9:
				var storeHeader = A2($author$project$Pages$Users$BraunComnet$Wide$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$BraunComnet$Wide$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$BraunComnet$Wide$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Ca6Comnet$HourCsv$CsvRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$CsvResponse = function (filename) {
	return {ih: filename};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$csvResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Ca6Comnet$HourCsv$CsvResponse,
	A2($elm$json$Json$Decode$field, 'filename', $elm$json$Json$Decode$string));
var $Herteby$url_builder_plus$UrlBuilderPlus$int = F2(
	function (key, value) {
		return A2(
			$Herteby$url_builder_plus$UrlBuilderPlus$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Api$makeCsvEndpoint = F3(
	function (config, paths, queryParam) {
		var url = A2($author$project$Api$makeUrl, config.hN, config.hO);
		return _Utils_ap(
			url,
			A2(
				$elm$url$Url$Builder$absolute,
				A2($elm$core$List$cons, 'csv', paths),
				queryParam));
	});
var $elm$file$File$Download$url = function (href) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$never,
		_File_downloadUrl(href));
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 12:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							de: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dh: function (parts) {
								return A3($author$project$Utils$Helpers$formatDate, parts.mL, parts.lp, parts.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										11,
										-62,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											date)))),
							du: function (parts) {
								return A3($author$project$Utils$Helpers$formatDate, parts.mL, parts.lp, parts.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										11,
										-3,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											date)))),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: '00:00',
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, model.dw, selectedBigCategory, model.fH, model.ja)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, model.dw, model.he, selectedSmallCategoryId, model.ja)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.ja)),
								_List_Nil),
							dw: selectedMakerId
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var sub = msg.a;
				var _v1 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeProduct, subCmd));
			case 11:
				var csvType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v2.a.$) && (!_v2.b.$)) {
							var fromDateTime = _v2.a.a;
							var toDateTime = _v2.b.a;
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$ca6ComnetEndpoints.k$.kQ(
									_List_fromArray(
										[
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'from',
											$author$project$Utils$Helpers$formatDateTime(fromDateTime)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'to',
											$author$project$Utils$Helpers$formatDateTime(toDateTime)),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$int, 'interval', model.Z),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'CSV_TYPE',
											function () {
												switch (csvType) {
													case 0:
														return 'All';
													case 1:
														return 'Ryouhan';
													case 2:
														return 'Amazon';
													case 3:
														return 'Rakuten';
													case 4:
														return 'Yahoo';
													default:
														return 'Kakakucom';
												}
											}()),
											$elm$core$List$isEmpty(
											$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'productIds',
											A2(
												$elm$core$List$map,
												function ($) {
													return $.i8;
												},
												A4($author$project$Utils$Helpers$filterBySelector, model.dw, model.he, model.fH, model.ja))) : A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'productIds',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								$author$project$Pages$Users$Ca6Comnet$HourCsv$toSession(model),
								$author$project$Pages$Users$Ca6Comnet$HourCsv$csvResponseDecoder,
								$author$project$Pages$Users$Ca6Comnet$HourCsv$CsvRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 0:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								ja: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.cM) : A2($elm$core$Set$remove, storeGroupId, model.cM);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cM: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			default:
				if (!msg.a.$) {
					var filename = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, c5: false}),
						$elm$file$File$Download$url(
							A3(
								$author$project$Api$makeCsvEndpoint,
								$author$project$Session$getConfig(model.bu),
								_List_fromArray(
									[
										A2($author$project$Endpoints$User$ca6ComnetEndpoints.k$.kD, filename.ih, _List_Nil)
									]),
								_List_Nil)));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just('エラーが発生しました'),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return {h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$Price)))))))))));
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$priceDecoder)));
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$ca6ComnetEndpoints.jZ.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$ca6ComnetEndpoints.jZ.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Common$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Common$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Common$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Common$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$Common$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Common$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$Common$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Common$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$Common$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Common$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Common$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$defaultEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$Common$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Common$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$defaultEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$Common$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Common$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Common$Default$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Common$Default$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Common$Default$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Common$Default$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Common$Default$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Common$Default$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$Default$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Common$Default$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Common$Default$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Common$Default$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Common$Default$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Common$Default$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Common$Default$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Common$Default$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Common$Default$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Common$Default$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Common$Default$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Common$Default$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Common$Default$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Common$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Common$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Common$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$Common$TimeSeq$Price)))))))))));
var $author$project$Pages$Users$Common$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Common$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Common$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Common$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Common$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Common$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$Lower = 1;
var $author$project$Pages$Users$Common$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$Common$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Common$TimeSeq$Normal = 3;
var $author$project$Pages$Users$Common$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Common$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v10 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v10.a.$) && (!_v10.b.$)) {
			var dateTimePartsFrom = _v10.a.a;
			var dateTimePartsTo = _v10.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Utils$Helpers$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v8 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v8.a.$) && (!_v8.b.$)) {
												var currentPrice = _v8.a.a;
												var prevPrice = _v8.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v6.a && (!_v6.b.$)) {
													var yodobashiPrice = _v6.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v4 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v4.a) {
												return 0;
											} else {
												if (_v4.b) {
													return 2;
												} else {
													if (_v4.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Common$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Common$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Common$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Common$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Common$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Common$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Common$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$Common$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Common$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Dbi6Comnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Dbi6Comnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Dbi6Comnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Dbi6Comnet$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Dbi6Comnet$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Dbi6Comnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Dbi6Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Dbi6Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Dbi6Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Dbi6Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Dbi6Comnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Dbi6Comnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Dbi6Comnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$Dbi6Comnet$TimeSeq$Price)))))))))));
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Dbi6Comnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Dbi6Comnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Dbi6Comnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v10 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v10.a.$) && (!_v10.b.$)) {
			var dateTimePartsFrom = _v10.a.a;
			var dateTimePartsTo = _v10.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Utils$Helpers$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v8 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v8.a.$) && (!_v8.b.$)) {
												var currentPrice = _v8.a.a;
												var prevPrice = _v8.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v6.a && (!_v6.b.$)) {
													var yodobashiPrice = _v6.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v4 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v4.a) {
												return 0;
											} else {
												if (_v4.b) {
													return 2;
												} else {
													if (_v4.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Dbi6Comnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Dbi6Comnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Dbi6Comnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Dbi6Comnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Dbi6Comnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Delonghi$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$Delonghi$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Delonghi$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$Delonghi$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Delonghi$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Delonghi$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$Delonghi$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Delonghi$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$Delonghi$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Delonghi$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Delonghi$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Delonghi$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Delonghi$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Delonghi$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Delonghi$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Delonghi$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$filter,
		function (store) {
			return A2($elm$core$Set$member, store.hs, model.X);
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.ap;
			},
			A2(
				$elm$core$List$filter,
				function (storeGroup) {
					return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
				},
				model.U)));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Delonghi$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Delonghi$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Delonghi$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Delonghi$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Delonghi$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Delonghi$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Delonghi$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Delonghi$Hour$pStoreHeader = F3(
	function (storeGroups, visibleStoreGroups, visibleStores) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$filterMap,
						function (store) {
							return A2($elm$core$Set$member, store.hs, visibleStores) ? $elm$core$Maybe$Just(
								{mh: storeGroup.mh, jD: store.h6}) : $elm$core$Maybe$Nothing;
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Utils$Helpers$findOpt = F2(
	function (f, list) {
		findOpt:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (f(x)) {
					return $elm$core$Maybe$Just(x);
				} else {
					var $temp$f = f,
						$temp$list = xs;
					f = $temp$f;
					list = $temp$list;
					continue findOpt;
				}
			}
		}
	});
var $author$project$Utils$Helpers$sortMakers = F2(
	function (makerName, unsortedMakers) {
		var makers = A2(
			$elm$core$List$sortBy,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.lg;
				},
				$elm$core$String$toLower),
			unsortedMakers);
		var isDelonghi = function (maker) {
			return _Utils_eq(maker.lg, makerName);
		};
		var _v0 = A2($author$project$Utils$Helpers$findOpt, isDelonghi, makers);
		if (_v0.$ === 1) {
			return makers;
		} else {
			var delonghi = _v0.a;
			return A2(
				$elm$core$List$cons,
				delonghi,
				A2(
					$elm$core$List$filter,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, isDelonghi),
					makers));
		}
	});
var $author$project$Pages$Users$Delonghi$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Delonghi$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Delonghi$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'noStoreGroupSort', 'true')
										])),
								model.bu,
								$author$project$Pages$Users$Delonghi$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Delonghi$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'noStoreGroupSort', 'true')
										])),
								model.bu,
								$author$project$Pages$Users$Delonghi$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Delonghi$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 12:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: A2($author$project$Utils$Helpers$sortMakers, 'De’Longhi', initResponse.ca),
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								ap: A2(
									$elm$core$List$concatMap,
									function (sg) {
										return sg.ap;
									},
									getPriceResponse.U),
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								X: $elm$core$Set$isEmpty(model.X) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.hs;
										},
										A2(
											$elm$core$List$concatMap,
											function ($) {
												return $.ap;
											},
											getPriceResponse.U))) : model.X
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 15:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 4:
				var checked = msg.a;
				var storeIdStr = msg.b;
				var storeId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeIdStr));
				var newVisibleStores = checked ? A2($elm$core$Set$insert, storeId, model.X) : A2($elm$core$Set$remove, storeId, model.X);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{X: newVisibleStores}),
					$author$project$Api$createFixedMidashi(0));
			case 11:
				var storeHeader = A3($author$project$Pages$Users$Delonghi$Hour$pStoreHeader, model.U, model.L, model.X);
				var priceRows = $author$project$Pages$Users$Delonghi$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Delonghi$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Utils$Helpers$defaultProduct = {j9: 0, ka: 0, eb: 0, bM: 0, ed: -1, kT: $elm$core$Maybe$Nothing, bg: false, iJ: -1, i8: '', lT: '', lU: $elm$core$Maybe$Nothing, hb: $elm$core$Maybe$Nothing, ho: -1, mz: false, mM: false};
var $author$project$Pages$Users$Delonghi$TimeSeq$GetPriceResponse = F2(
	function (dictPrice, stores) {
		return {h4: dictPrice, ap: stores};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$Price = F9(
	function (storeId, nth, dateTime, price, priceIncludeTax, point, priceWithoutPoint, coupon, url) {
		return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, hs: storeId, dS: url};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Users$Delonghi$TimeSeq$Price))))))))));
var $author$project$Pages$Users$Delonghi$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Delonghi$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Delonghi$TimeSeq$getPriceResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Users$Delonghi$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Delonghi$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $author$project$Pages$Users$Delonghi$TimeSeq$ProductPriceData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$ProductPriceNoData = {$: 2};
var $author$project$Pages$Users$Delonghi$TimeSeq$StoreHeader = function (a) {
	return {$: 0, a: a};
};
var $elm_community$list_extra$List$Extra$minimumBy = F2(
	function (f, ls) {
		var minBy = F2(
			function (x, _v1) {
				var y = _v1.a;
				var fy = _v1.b;
				var fx = f(x);
				return (_Utils_cmp(fx, fy) < 0) ? _Utils_Tuple2(x, fx) : _Utils_Tuple2(y, fy);
			});
		if (ls.b) {
			if (!ls.b.b) {
				var l_ = ls.a;
				return $elm$core$Maybe$Just(l_);
			} else {
				var l_ = ls.a;
				var ls_ = ls.b;
				return $elm$core$Maybe$Just(
					A3(
						$elm$core$List$foldl,
						minBy,
						_Utils_Tuple2(
							l_,
							f(l_)),
						ls_).a);
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$orList = function (maybes) {
	orList:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (maybes.a.$ === 1) {
				var _v1 = maybes.a;
				var rest = maybes.b;
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue orList;
			} else {
				var answer = maybes.a.a;
				return $elm$core$Maybe$Just(answer);
			}
		}
	}
};
var $elm_community$maybe_extra$Maybe$Extra$cons = F2(
	function (item, list) {
		if (!item.$) {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		} else {
			return list;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$cons, _List_Nil);
var $author$project$Pages$Users$Delonghi$TimeSeq$pProductPriceRows = F4(
	function (stores, maxRowNum, prices, basePrice) {
		return A2(
			$elm$core$List$map,
			function (store) {
				return A2(
					$elm$core$List$cons,
					$author$project$Pages$Users$Delonghi$TimeSeq$StoreHeader(store.jD),
					A2(
						$elm$core$List$map,
						function (ind) {
							var prevMaybePrice = $elm_community$maybe_extra$Maybe$Extra$orList(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$map,
										function (prevInd) {
											return A2(
												$elm$core$Dict$get,
												_Utils_Tuple2(store.hs, prevInd),
												prices);
										},
										A2($elm$core$List$range, 1, ind - 1))));
							var lowestPrice = A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.aW;
									},
									A2(
										$elm_community$list_extra$List$Extra$minimumBy,
										function ($) {
											return $.aW;
										},
										$elm_community$maybe_extra$Maybe$Extra$values(
											A2(
												$elm$core$List$map,
												function (st) {
													return A2(
														$elm$core$Dict$get,
														_Utils_Tuple2(st.hs, ind),
														prices);
												},
												stores)))));
							var currentMaybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(store.hs, ind),
								prices);
							if (!currentMaybePrice.$) {
								var currentPrice = currentMaybePrice.a;
								return $author$project$Pages$Users$Delonghi$TimeSeq$ProductPriceData(
									{
										cO: (_Utils_cmp(currentPrice.aW, basePrice) < 0) ? (_Utils_eq(currentPrice.aW, lowestPrice) ? 0 : 1) : (_Utils_eq(currentPrice.aW, lowestPrice) ? 2 : 3),
										iZ: currentPrice.iZ,
										fl: function () {
											if (!prevMaybePrice.$) {
												var prevPrice = prevMaybePrice.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}(),
										aW: currentPrice.aW,
										dS: currentPrice.dS
									});
							} else {
								return $author$project$Pages$Users$Delonghi$TimeSeq$ProductPriceNoData;
							}
						},
						A2($elm$core$List$range, 1, maxRowNum - 1)));
			},
			stores);
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$formatDateTimeWithoutYear = function (parts) {
	return A2(
		$elm$core$String$right,
		2,
		'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(parts.lp)) + ('月' + (A2(
		$elm$core$String$right,
		2,
		'0' + $elm$core$String$fromInt(parts.gp)) + ('日' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln))));
};
var $justinmimbs$time_extra$Time$Extra$ceiling = F3(
	function (interval, zone, posix) {
		var floored = A3($justinmimbs$time_extra$Time$Extra$floor, interval, zone, posix);
		return _Utils_eq(floored, posix) ? posix : A4($justinmimbs$time_extra$Time$Extra$add, interval, 1, zone, floored);
	});
var $justinmimbs$time_extra$Time$Extra$rangeHelp = F6(
	function (interval, step, zone, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(
				$elm$time$Time$posixToMillis(current),
				$elm$time$Time$posixToMillis(until)) < 0) {
				var $temp$interval = interval,
					$temp$step = step,
					$temp$zone = zone,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = A4($justinmimbs$time_extra$Time$Extra$add, interval, step, zone, current);
				interval = $temp$interval;
				step = $temp$step;
				zone = $temp$zone;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$time_extra$Time$Extra$range = F5(
	function (interval, step, zone, start, until) {
		return A6(
			$justinmimbs$time_extra$Time$Extra$rangeHelp,
			interval,
			A2($elm$core$Basics$max, 1, step),
			zone,
			until,
			_List_Nil,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, interval, zone, start));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$pStoreHeader = F3(
	function (maybeFromParts, maybeToParts, interval) {
		var _v0 = _Utils_Tuple2(maybeFromParts, maybeToParts);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var fromParts = _v0.a.a;
			var toParts = _v0.b.a;
			var toPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				toParts);
			var fromPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				fromParts);
			return function (ls) {
				return A2($elm$core$List$cons, '', ls);
			}(
				A2(
					$elm$core$List$map,
					function (posix) {
						return $author$project$Pages$Users$Delonghi$TimeSeq$formatDateTimeWithoutYear(
							A2(
								$justinmimbs$time_extra$Time$Extra$posixToParts,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								posix));
					},
					A5(
						$justinmimbs$time_extra$Time$Extra$range,
						13,
						interval,
						$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
						fromPosix,
						toPosix)));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$toCsv = F2(
	function (headerDateTimes, bodyProducts) {
		var header = headerDateTimes;
		var body = A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$elm$core$List$map,
					function (product) {
						switch (product.$) {
							case 0:
								var storeName = product.a;
								return storeName;
							case 1:
								var cell = product.a;
								return $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(cell.aW));
							default:
								return '';
						}
					},
					productList);
			},
			bodyProducts);
		return {
			cY: _List_Nil,
			lX: A2($elm$core$List$cons, header, body)
		};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var _v1 = model.hf;
				if (!_v1.$) {
					var product = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: true}),
						function () {
							var _v2 = _Utils_Tuple2(
								A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
								A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
							if ((!_v2.a.$) && (!_v2.b.$)) {
								var dateTimePartsFrom = _v2.a.a;
								var dateTimePartsTo = _v2.b.a;
								var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
								var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
								return A4(
									$author$project$Api$get,
									$author$project$Endpoints$User$delonghiEndpoints.hw.C(
										_List_fromArray(
											[
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'interval',
												$elm$core$String$fromInt(model.Z)),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'products',
												_List_fromArray(
													[product]))
											])),
									model.bu,
									$author$project$Pages$Users$Delonghi$TimeSeq$getPriceResponseDecoder,
									$author$project$Pages$Users$Delonghi$TimeSeq$GetPriceRequestSent);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c6: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{he: selectedBigCategory}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fH: selectedSmallCategoryId}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var productId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c6: true,
							hf: (productId === '-1') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(productId)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ja: initResponse.ja, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, gz: true, c5: false, ck: getPriceResponse.h4, ap: getPriceResponse.ap, dB: model.du, dC: model.dv, dD: model.hf, dE: model.hi, dF: model.hj}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var headerDateTimes = A3(
					$author$project$Pages$Users$Delonghi$TimeSeq$pStoreHeader,
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
					model.Z);
				var currentProduct = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$Helpers$defaultProduct,
					A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.i8;
							},
							$elm$core$Basics$eq(
								A2($elm$core$Maybe$withDefault, '', model.dD))),
						model.ja));
				var bodyProducts = A4(
					$author$project$Pages$Users$Delonghi$TimeSeq$pProductPriceRows,
					model.ap,
					$elm$core$List$length(headerDateTimes),
					model.ck,
					currentProduct.ka);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A2($author$project$Pages$Users$Delonghi$TimeSeq$toCsv, headerDateTimes, bodyProducts))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Delonghi$TimeSeq2$Price))))))))))));
var $author$project$Pages$Users$Delonghi$TimeSeq2$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Delonghi$TimeSeq2$priceDecoder)));
var $author$project$Pages$Users$Delonghi$TimeSeq2$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Delonghi$TimeSeq2$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Delonghi$TimeSeq2$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Delonghi$TimeSeq2$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$Lower = 1;
var $author$project$Pages$Users$Delonghi$TimeSeq2$Lowest = 0;
var $author$project$Pages$Users$Delonghi$TimeSeq2$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Delonghi$TimeSeq2$Normal = 3;
var $author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Delonghi$TimeSeq2$Unchanged = {$: 2};
var $author$project$Pages$Users$Delonghi$TimeSeq2$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Delonghi$TimeSeq2$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Delonghi$TimeSeq2$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Delonghi$TimeSeq2$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Delonghi$TimeSeq2$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Delonghi$TimeSeq2$Unchanged);
											} else {
												return $author$project$Pages$Users$Delonghi$TimeSeq2$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Delonghi$TimeSeq2$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 18:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$delonghiEndpoints.mv.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'stores',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))
										])),
								model.bu,
								$author$project$Pages$Users$Delonghi$TimeSeq2$getPriceResponseDecoder,
								$author$project$Pages$Users$Delonghi$TimeSeq2$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 14:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var makerId = msg.a;
				var newSelectedMaker = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				var filteredProducts = A2(
					$elm$core$List$filter,
					function (p) {
						return _Utils_eq(
							newSelectedMaker,
							$elm$core$Maybe$Just(-1)) || _Utils_eq(
							$elm$core$Maybe$Just(p.iJ),
							newSelectedMaker);
					},
					model.cs);
				var newMultiselectProducts = A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					model.D,
					A2(
						$elm$core$List$map,
						function (p) {
							return _Utils_Tuple2(p.i8, p.lT);
						},
						filteredProducts),
					_List_Nil);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: newMultiselectProducts, dw: newSelectedMaker}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeProduct, subCmd));
			case 7:
				var sub = msg.a;
				var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.r);
				var subModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeStore, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								r: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.r,
									A2(
										$elm$core$List$map,
										function (s) {
											return _Utils_Tuple2(
												$elm$core$String$fromInt(s.hs),
												s.jD);
										},
										initResponse.dT),
									_List_Nil),
								cs: initResponse.ja,
								hl: initResponse.dT,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 17:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 13:
				var storeHeader = A2($author$project$Pages$Users$Delonghi$TimeSeq2$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Delonghi$TimeSeq2$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Delonghi$TimeSeq2$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Denon6Comnet$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Denon6Comnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Denon6Comnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Denon6Comnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Denon6Comnet$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Denon6Comnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Denon6Comnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Denon6Comnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Denon6Comnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Denon6Comnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Denon6Comnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Denon6Comnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Denon6Comnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Denon6Comnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Denon6Comnet$Wide$Price))))))))))));
var $author$project$Pages$Users$Denon6Comnet$Wide$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Denon6Comnet$Wide$priceDecoder)));
var $author$project$Pages$Users$Denon6Comnet$Wide$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Denon6Comnet$Wide$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Denon6Comnet$Wide$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Denon6Comnet$Wide$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$Lower = 1;
var $author$project$Pages$Users$Denon6Comnet$Wide$Lowest = 0;
var $author$project$Pages$Users$Denon6Comnet$Wide$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Denon6Comnet$Wide$Normal = 3;
var $author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Denon6Comnet$Wide$Unchanged = {$: 2};
var $author$project$Pages$Users$Denon6Comnet$Wide$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Denon6Comnet$Wide$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Denon6Comnet$Wide$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Denon6Comnet$Wide$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Denon6Comnet$Wide$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Denon6Comnet$Wide$Unchanged);
											} else {
												return $author$project$Pages$Users$Denon6Comnet$Wide$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Denon6Comnet$Wide$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Denon6Comnet$Wide$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$succDate = function (dateString) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(dateString);
	if (!_v0.$) {
		var date = _v0.a;
		return $justinmimbs$date$Date$toIsoString(
			A3($justinmimbs$date$Date$add, 3, 1, date));
	} else {
		return dateString;
	}
};
var $author$project$Pages$Users$Denon6Comnet$Wide$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										var top_ind = function () {
											var _v6 = A2($elm_community$list_extra$List$Extra$getAt, 0, indices);
											if (!_v6.$) {
												var x = _v6.a;
												return x;
											} else {
												return 0;
											}
										}();
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		var res = function () {
																			var _v2 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																				},
																				data);
																			if (!_v2.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v2.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var res = function () {
																			var _v3 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return _Utils_eq(item.i, ind);
																				},
																				data.g2);
																			if (!_v3.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v3.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v5 = data.cO;
																		switch (_v5) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 14:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date, dv: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:00'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Denon6Comnet$Wide$getPriceResponseDecoder,
								$author$project$Pages$Users$Denon6Comnet$Wide$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				var bigCategoryId = msg.a;
				var selectedBigCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategoryId);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.cs)),
								_List_Nil),
							dw: selectedMakerId
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: $author$project$Pages$Users$Denon6Comnet$Wide$succDate(model.du),
								dH: '00:00',
								fS: '00:00',
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 9:
				var storeHeader = A2($author$project$Pages$Users$Denon6Comnet$Wide$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Denon6Comnet$Wide$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Denon6Comnet$Wide$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$ElecComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$ElecComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ElecComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$ElecComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ElecComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ElecComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$ElecComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$ElecComnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$ElecComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$ElecComnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ElecComnet$Excel$GetExcelRequestSent = function (a) {
	return {$: 12, a: a};
};
var $author$project$Api$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.me));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $author$project$Api$getByte = F3(
	function (path, session, msg) {
		var apiConf = $author$project$Session$getConfig(session);
		var config = {
			cK: $elm$http$Http$emptyBody,
			cU: A2(
				$elm$http$Http$expectBytesResponse,
				msg,
				$author$project$Api$resolve($elm$core$Result$Ok)),
			cY: $author$project$Api$makeAuthHeader(session),
			dg: $author$project$Api$decodeHTTPMethod(0),
			dK: $elm$core$Maybe$Nothing,
			dN: $elm$core$Maybe$Nothing,
			dS: A3(
				$author$project$Api$makeEndpoint,
				apiConf,
				_List_fromArray(
					[path]),
				_List_Nil)
		};
		return $elm$http$Http$request(config);
	});
var $author$project$Pages$Users$ElecComnet$Excel$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A3(
								$author$project$Api$getByte,
								$author$project$Endpoints$User$elecComnetEndpoints.ex.dA(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(60)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$ElecComnet$Excel$GetExcelRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 7:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$ElecComnet$Excel$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var bytes = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, c5: false}),
						A3($elm$file$File$Download$bytes, model.du + ('〜' + (model.hj + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
				} else {
					var e = msg.a.a;
					var errMsg = function () {
						if ((e.$ === 3) && (e.a === 404)) {
							return '該当するデータが見つかりませんでした。検索条件を変更して再度お試しください。';
						} else {
							return $author$project$Utils$Helpers$errToStr(e);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(errMsg),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$ElecComnet$Hour$update = $author$project$Pages$Users$Common$Default$update;
var $author$project$Pages$Users$ElecComnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$GetPriceResponse = F2(
	function (dictPrice, stores) {
		return {h4: dictPrice, ap: stores};
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$Price = F9(
	function (storeId, nth, dateTime, price, priceIncludeTax, point, priceWithoutPoint, coupon, url) {
		return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, hs: storeId, dS: url};
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Users$ElecComnet$TimeSeq$Price))))))))));
var $author$project$Pages$Users$ElecComnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$ElecComnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$ElecComnet$TimeSeq$getPriceResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Users$ElecComnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$ElecComnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $author$project$Pages$Users$ElecComnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$ProductPriceNoData = {$: 2};
var $author$project$Pages$Users$ElecComnet$TimeSeq$StoreHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$pProductPriceRows = F4(
	function (stores, maxRowNum, prices, basePrice) {
		return A2(
			$elm$core$List$map,
			function (store) {
				return A2(
					$elm$core$List$cons,
					$author$project$Pages$Users$ElecComnet$TimeSeq$StoreHeader(store.h6),
					A2(
						$elm$core$List$map,
						function (ind) {
							var prevMaybePrice = $elm_community$maybe_extra$Maybe$Extra$orList(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$map,
										function (prevInd) {
											return A2(
												$elm$core$Dict$get,
												_Utils_Tuple2(store.hs, prevInd),
												prices);
										},
										A2($elm$core$List$range, 1, ind - 1))));
							var lowestPrice = A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.aW;
									},
									A2(
										$elm_community$list_extra$List$Extra$minimumBy,
										function ($) {
											return $.aW;
										},
										$elm_community$maybe_extra$Maybe$Extra$values(
											A2(
												$elm$core$List$map,
												function (st) {
													return A2(
														$elm$core$Dict$get,
														_Utils_Tuple2(st.hs, ind),
														prices);
												},
												stores)))));
							var currentMaybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(store.hs, ind),
								prices);
							if (!currentMaybePrice.$) {
								var currentPrice = currentMaybePrice.a;
								return $author$project$Pages$Users$ElecComnet$TimeSeq$ProductPriceData(
									{
										cO: (_Utils_cmp(currentPrice.aW, basePrice) < 0) ? (_Utils_eq(currentPrice.aW, lowestPrice) ? 0 : 1) : (_Utils_eq(currentPrice.aW, lowestPrice) ? 2 : 3),
										fl: function () {
											if (!prevMaybePrice.$) {
												var prevPrice = prevMaybePrice.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}(),
										aW: currentPrice.aW,
										dS: currentPrice.dS
									});
							} else {
								return $author$project$Pages$Users$ElecComnet$TimeSeq$ProductPriceNoData;
							}
						},
						A2($elm$core$List$range, 1, maxRowNum - 1)));
			},
			stores);
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$formatDateTimeWithoutYear = function (parts) {
	return A2(
		$elm$core$String$right,
		2,
		'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(parts.lp)) + ('月' + (A2(
		$elm$core$String$right,
		2,
		'0' + $elm$core$String$fromInt(parts.gp)) + ('日' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln))));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$pStoreHeader = F3(
	function (maybeFromParts, maybeToParts, interval) {
		var _v0 = _Utils_Tuple2(maybeFromParts, maybeToParts);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var fromParts = _v0.a.a;
			var toParts = _v0.b.a;
			var toPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				toParts);
			var fromPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				fromParts);
			return function (ls) {
				return A2($elm$core$List$cons, '', ls);
			}(
				A2(
					$elm$core$List$map,
					function (posix) {
						return $author$project$Pages$Users$ElecComnet$TimeSeq$formatDateTimeWithoutYear(
							A2(
								$justinmimbs$time_extra$Time$Extra$posixToParts,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								posix));
					},
					A5(
						$justinmimbs$time_extra$Time$Extra$range,
						13,
						interval,
						$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
						fromPosix,
						toPosix)));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$toCsv = F2(
	function (headerDateTimes, bodyProducts) {
		var header = headerDateTimes;
		var body = A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$elm$core$List$map,
					function (product) {
						switch (product.$) {
							case 0:
								var storeName = product.a;
								return storeName;
							case 1:
								var cell = product.a;
								return A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $author$project$Utils$Helpers$toCommmaSplited)(cell.aW);
							default:
								return '';
						}
					},
					productList);
			},
			bodyProducts);
		return {
			cY: _List_Nil,
			lX: A2($elm$core$List$cons, header, body)
		};
	});
var $author$project$Pages$Users$ElecComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var _v1 = model.hf;
				if (!_v1.$) {
					var product = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: true}),
						function () {
							var _v2 = _Utils_Tuple2(
								A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
								A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
							if ((!_v2.a.$) && (!_v2.b.$)) {
								var dateTimePartsFrom = _v2.a.a;
								var dateTimePartsTo = _v2.b.a;
								var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
								var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
								return A4(
									$author$project$Api$get,
									$author$project$Endpoints$User$elecComnetEndpoints.hw.C(
										_List_fromArray(
											[
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'interval',
												$elm$core$String$fromInt(model.Z)),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'products',
												_List_fromArray(
													[product]))
											])),
									model.bu,
									$author$project$Pages$Users$ElecComnet$TimeSeq$getPriceResponseDecoder,
									$author$project$Pages$Users$ElecComnet$TimeSeq$GetPriceRequestSent);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c6: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{he: selectedBigCategory}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fH: selectedSmallCategoryId}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var productId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c6: true,
							hf: (productId === '-1') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(productId)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ja: initResponse.ja, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, gz: true, c5: false, ck: getPriceResponse.h4, ap: getPriceResponse.ap, dB: model.du, dC: model.dv, dD: model.hf, dE: model.hi, dF: model.hj}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var headerDateTimes = A3(
					$author$project$Pages$Users$ElecComnet$TimeSeq$pStoreHeader,
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
					model.Z);
				var currentProduct = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$Helpers$defaultProduct,
					A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.i8;
							},
							$elm$core$Basics$eq(
								A2($elm$core$Maybe$withDefault, '', model.dD))),
						model.ja));
				var bodyProducts = A4(
					$author$project$Pages$Users$ElecComnet$TimeSeq$pProductPriceRows,
					model.ap,
					$elm$core$List$length(headerDateTimes),
					model.ck,
					currentProduct.ka);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A2($author$project$Pages$Users$ElecComnet$TimeSeq$toCsv, headerDateTimes, bodyProducts))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$FujiComnet$Day$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $author$project$Pages$Users$FujiComnet$Day$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$FujiComnet$Day$DictPriceRecord = F4(
	function (productId, storeId, hour, data_day) {
		return {cQ: data_day, k_: hour, i8: productId, hs: storeId};
	});
var $author$project$Pages$Users$FujiComnet$Day$gen_dictpricekey = function (record) {
	return _Utils_ap(
		record.i8,
		_Utils_ap(
			$elm$core$String$fromInt(record.hs),
			_Utils_ap(
				$elm$core$String$fromInt(record.k_),
				record.cQ)));
};
var $author$project$Pages$Users$FujiComnet$Day$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Pages$Users$FujiComnet$Day$gen_dictpricekey,
				A5(
					$elm$json$Json$Decode$map4,
					$author$project$Pages$Users$FujiComnet$Day$DictPriceRecord,
					A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'hour', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'data_day', $elm$json$Json$Decode$string)))),
		$elm$json$Json$Decode$list($author$project$Utils$Decoders$priceDecoder)));
var $author$project$Pages$Users$FujiComnet$Day$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$FujiComnet$Day$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$FujiComnet$Day$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$FujiComnet$Day$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$FujiComnet$Day$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$FujiComnet$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				function (ind) {
					return function (product) {
						return A2(
							$elm$core$List$map,
							function (data_day) {
								return A2(
									$elm$core$List$map,
									function (hour) {
										var maybeYodobashiPrice = function () {
											var record = {cQ: data_day, k_: hour, i8: product.i8, hs: 7};
											return A2(
												$elm$core$Dict$get,
												$author$project$Pages$Users$FujiComnet$Day$gen_dictpricekey(record),
												model.ck);
										}();
										var lowestPrice = A3(
											$elm$core$List$foldl,
											function (price) {
												return function (m) {
													return A2($elm$core$Basics$min, m, price);
												};
											},
											A2($elm$core$Basics$pow, 2, 30),
											A2(
												$elm$core$List$map,
												function (maybePrice) {
													if (!maybePrice.$) {
														var price = maybePrice.a;
														return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
													} else {
														return A2($elm$core$Basics$pow, 2, 30);
													}
												},
												A2(
													$elm$core$List$map,
													function (store) {
														var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
														return A2(
															$elm$core$Dict$get,
															$author$project$Pages$Users$FujiComnet$Day$gen_dictpricekey(record),
															model.ck);
													},
													stores)));
										var itemList = _List_fromArray(
											[
												{iF: '税抜き', i: 0},
												{iF: '税込み', i: 1},
												{iF: 'P/クーポン', i: 2},
												{iF: 'P後', i: 3},
												{iF: '取得時刻', i: 4}
											]);
										var filterItemList = function (list) {
											return A2(
												$elm$core$List$map,
												function (i) {
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, i, list));
												},
												A2(
													$elm$core$Maybe$withDefault,
													_Utils_Tuple2(
														'DisplayAll',
														_List_fromArray(
															[0, 1, 2, 3, 4])),
													A2(
														$elm_community$list_extra$List$Extra$find,
														function (dispToValue) {
															return _Utils_eq(dispToValue.a, model.cq);
														},
														$author$project$Pages$Users$FujiComnet$Day$displayStyleToValues)).b);
										};
										var filteredItemList = filterItemList(itemList);
										var _v0 = function () {
											var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
											if (_v1.a && (!_v1.b.$)) {
												var yodobashiPrice = _v1.b.a;
												return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
											} else {
												return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
											}
										}();
										var basePrice = _v0.ka;
										var basePriceIncludeTax = _v0.eb;
										var basePoint = _v0.j9;
										var basePriceWithoutPoint = _v0.bM;
										var basePriceList = _List_fromArray(
											[
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePrice)),
												i: 0
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceIncludeTax)),
												i: 1
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePoint)),
												i: 2
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceWithoutPoint)),
												i: 3
											},
												{
												iF: $elm$core$String$fromInt(hour) + '時',
												i: 4
											}
											]);
										var filteredBasePriceList = filterItemList(basePriceList);
										return _Utils_ap(
											((hour === 9) && (data_day === 'today')) ? _List_fromArray(
												[
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '本日', i: 0}
														]))
												]) : (((hour === 9) && (data_day === 'yesterday')) ? _List_fromArray(
												[
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{
															iF: $elm$core$String$fromInt(ind + 1),
															i: 0
														}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: product.lT, i: 0}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '昨日', i: 0}
														]))
												]) : _List_fromArray(
												[
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														]))
												])),
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(filteredItemList),
														$author$project$Pages$Users$FujiComnet$Day$ProductPriceHeader(filteredBasePriceList)
													]),
												A2(
													$elm$core$List$map,
													function (store) {
														var maybePrice = function () {
															var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
															return A2(
																$elm$core$Dict$get,
																$author$project$Pages$Users$FujiComnet$Day$gen_dictpricekey(record),
																model.ck);
														}();
														var isLowest = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																return _Utils_eq(price.aW, lowestPrice);
															} else {
																return false;
															}
														}();
														var isLowestButHigherThanBase = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v6.a && (!_v6.b.$)) {
																	var yodobashiPrice = _v6.b.a;
																	return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
																} else {
																	return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
																}
															} else {
																return false;
															}
														}();
														var isLower = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v4.a && (!_v4.b.$)) {
																	var yodobashiPrice = _v4.b.a;
																	return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
																} else {
																	return _Utils_cmp(price.aW, product.bM) < 0;
																}
															} else {
																return false;
															}
														}();
														if (!maybePrice.$) {
															var price = maybePrice.a;
															var priceList = _List_fromArray(
																[
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i3)),
																	i: 0
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i4)),
																	i: 1
																},
																	{
																	iF: function (point) {
																		return _Utils_ap(
																			point,
																			(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
																	}(
																		$elm$core$String$fromInt(price.iZ)),
																	i: 2
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.aW)),
																	i: 3
																},
																	{
																	iF: A2(
																		$elm$core$Maybe$withDefault,
																		'',
																		$elm$core$List$head(
																			A2(
																				$elm$core$Maybe$withDefault,
																				_List_Nil,
																				$elm$core$List$tail(
																					A2($elm$core$String$split, ' ', price.kw))))),
																	i: 4
																}
																]);
															var filteredPriceList = filterItemList(priceList);
															return $author$project$Pages$Users$FujiComnet$Day$ProductPriceData(
																{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
														} else {
															return $author$project$Pages$Users$FujiComnet$Day$ProductPriceNoData;
														}
													},
													stores)));
									},
									_List_fromArray(
										[9, 15]));
							},
							_List_fromArray(
								['yesterday', 'today']));
					};
				},
				products)));
};
var $author$project$Pages$Users$FujiComnet$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '日にち', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$FujiComnet$Day$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$FujiComnet$Day$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$FujiComnet$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$FujiComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$FujiComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'16:00:00'
									]));
							var to3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'10:00:00'
									]));
							var to2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'16:00:00'
									]));
							var to1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'10:00:00'
									]));
							var from_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'15:00:00'
									]));
							var from3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'9:00:00'
									]));
							var from2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'15:00:00'
									]));
							var from1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'9:00:00'
									]));
							var except_id = '';
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$to24tcmComnetEndpoints.gp.im(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from1', from1),
											A2($elm$url$Url$Builder$string, 'to1', to1),
											A2($elm$url$Url$Builder$string, 'from2', from2),
											A2($elm$url$Url$Builder$string, 'to2', to2),
											A2($elm$url$Url$Builder$string, 'from3', from3),
											A2($elm$url$Url$Builder$string, 'to3', to3),
											A2($elm$url$Url$Builder$string, 'from4', from4),
											A2($elm$url$Url$Builder$string, 'to4', to4),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'exception', except_id)
										])),
								model.bu,
								$author$project$Pages$Users$FujiComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$FujiComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: A2(
									$elm$core$List$filter,
									function (sg) {
										return sg.mh === 19;
									},
									getPriceResponse.U),
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$FujiComnet$Day$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$FujiComnet$Day$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$FujiComnet$Day$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi24Comnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi24Comnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜', i: 0},
						{iF: '税込', i: 1},
						{iF: 'ポイント(/ク)', i: 2},
						{iF: 'P適用後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Hi24Comnet$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Hi24Comnet$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi24Comnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: A2(
				$elm$core$List$map,
				function ($) {
					return $.jD;
				},
				storeHeader),
			lX: A2(
				$elm$core$List$concatMap,
				function (row) {
					var indices = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(
							'DisplayAll',
							_List_fromArray(
								[0, 1, 2, 3])),
						A2(
							$elm_community$list_extra$List$Extra$find,
							function (dispToValue) {
								return _Utils_eq(dispToValue.a, selectedDisplayStyle);
							},
							$author$project$Pages$Users$Hi24Comnet$Hour$displayStyleToValues)).b;
					var numrow = A2(
						$elm$core$List$take,
						$elm$core$List$length(indices),
						_List_fromArray(
							[0, 1, 2, 3]));
					return A2(
						$elm$core$List$map,
						function (ind) {
							return A2(
								$elm$core$List$map,
								function (cell) {
									switch (cell.$) {
										case 0:
											var data = cell.a;
											return A2(
												$elm$core$Maybe$withDefault,
												{iF: '', i: -1},
												A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
										case 1:
											return '';
										default:
											var data = cell.a;
											return A2(
												$elm$core$Maybe$withDefault,
												{iF: '', i: -1},
												A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
									}
								},
								row);
						},
						numrow);
				},
				priceRows)
		};
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Hi24Comnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi24Comnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi24Comnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hi24Comnet$TenAM$Price))))))))))));
var $author$project$Pages$Users$Hi24Comnet$TenAM$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Hi24Comnet$TenAM$priceDecoder)));
var $author$project$Pages$Users$Hi24Comnet$TenAM$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$TenAM$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Hi24Comnet$TenAM$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi24Comnet$TenAM$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$Lower = 1;
var $author$project$Pages$Users$Hi24Comnet$TenAM$Lowest = 0;
var $author$project$Pages$Users$Hi24Comnet$TenAM$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Hi24Comnet$TenAM$Normal = 3;
var $author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi24Comnet$TenAM$Unchanged = {$: 2};
var $author$project$Pages$Users$Hi24Comnet$TenAM$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi24Comnet$TenAM$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				11,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return diff + 1;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Hi24Comnet$TenAM$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			11,
																			nth - 1,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Hi24Comnet$TenAM$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Hi24Comnet$TenAM$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Hi24Comnet$TenAM$Unchanged);
											} else {
												return $author$project$Pages$Users$Hi24Comnet$TenAM$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Hi24Comnet$TenAM$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 18:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$hi24ComnetEndpoints.mr.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'timefrom', model.hi),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'timeto', model.hj),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'stores',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$TenAM$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$TenAM$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 7:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.r);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeStore, subCmd));
			case 14:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								r: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.r,
									A2(
										$elm$core$List$map,
										function (s) {
											return _Utils_Tuple2(
												$elm$core$String$fromInt(s.hs),
												s.jD);
										},
										initResponse.dT),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 17:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 13:
				var storeHeader = A2($author$project$Pages$Users$Hi24Comnet$TenAM$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi24Comnet$TenAM$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi24Comnet$TenAM$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hi24Comnet$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Hi24Comnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Hi24Comnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Hi24Comnet$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Hi24Comnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Hi24Comnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Hi24Comnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Hi24Comnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Hi24Comnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi24Comnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi24Comnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return {h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$Price)))))))))));
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$priceDecoder)));
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$hi24ComnetEndpoints.jZ.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$hi24ComnetEndpoints.jZ.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi3Comnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi3Comnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi3Comnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4},
						{iF: '工事費', i: 5}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Hi3Comnet$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4},
						{iF: '-', i: 5}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v7.a && (!_v7.b.$)) {
										var yodobashiPrice = _v7.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v5 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v5.a && (!_v5.b.$)) {
										var yodobashiPrice = _v5.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									},
										{
										iF: function () {
											var _v3 = price.km;
											if (_v3.$ === 1) {
												return '0';
											} else {
												var constructionCost = _v3.a;
												return $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(constructionCost));
											}
										}(),
										i: 5
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Hi3Comnet$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi3Comnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Hi3Comnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi3Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi3Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi3Comnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi3Comnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Hi3Comnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi3Comnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi3Comnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hi3Comnet$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Hi3Comnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Hi3Comnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Hi3Comnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$Hi3Comnet$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Hi3Comnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Hi3Comnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Hi3Comnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Hi3Comnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Hi3Comnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Hi3Comnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Hi3Comnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Hi3Comnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Hi3Comnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hisense$Amazon$OnReceived = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$TableRow = function (category) {
	return function (jan) {
		return function (rank) {
			return function (asin) {
				return function (rating) {
					return function (rateNum) {
						return function (kakaku) {
							return function (date) {
								return function (kataban) {
									return function (makerName) {
										return function (url) {
											return function (text) {
												return {ge: asin, gk: category, go: date, gN: jan, gO: kakaku, eS: kataban, lg: makerName, g6: rank, g7: rateNum, g8: rating, jI: text, dS: url};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Pages$Users$Hisense$Amazon$amazonRankingDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'maker_name', $elm$json$Json$Decode$string),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'kataban', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'date', $elm$json$Json$Decode$string),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'kakaku', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'rating_num', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'rating', $elm$json$Json$Decode$float),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'asin', $elm$json$Json$Decode$string),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'rank', $elm$json$Json$Decode$int),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2(
													$elm$json$Json$Decode$field,
													'jan',
													$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$int),
													$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hisense$Amazon$TableRow))))))))))))));
var $author$project$Pages$Users$Hisense$Amazon$categories = _List_fromArray(
	['テレビ', '冷蔵庫', '洗濯機', '冷凍庫', 'エアコン']);
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Pages$Users$Hisense$Amazon$tableHeader = _List_fromArray(
	['ランキング', 'カテゴリー', '型番', 'ASIN', '評価', '評価数', '価格', 'メーカー', 'JANコード', '取得時間']);
var $author$project$Pages$Users$Hisense$Amazon$toCsv = function (tableRows) {
	return {
		cY: _List_Nil,
		lX: A2(
			$elm$core$List$cons,
			$author$project$Pages$Users$Hisense$Amazon$tableHeader,
			A2(
				$elm$core$List$map,
				function (row) {
					return _List_fromArray(
						[
							$elm$core$String$fromInt(row.g6),
							A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm_community$list_extra$List$Extra$getAt, row.gk - 1, $author$project$Pages$Users$Hisense$Amazon$categories)),
							row.eS,
							row.ge,
							$elm$core$String$fromFloat(row.g8),
							$elm$core$String$fromInt(row.g7),
							$elm$core$String$fromInt(row.gO),
							row.lg,
							A2($elm$core$Maybe$withDefault, '', row.gN),
							row.go
						]);
				},
				tableRows))
	};
};
var $author$project$Pages$Users$Hisense$Amazon$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var val = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cp: A2(
								$elm$core$Maybe$withDefault,
								-1,
								$elm$core$String$toInt(val))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var val = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: val}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var val = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: val}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
				if (!_v1.$) {
					var dateTimeParts = _v1.a;
					var date = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b1: true}),
						A4(
							$author$project$Api$get,
							$author$project$Endpoints$User$hisenseEndpoints.il(
								_List_fromArray(
									[
										A2(
										$elm$url$Url$Builder$string,
										'category',
										$elm$core$String$fromInt(model.cp)),
										A2($elm$url$Url$Builder$string, 'dateTime', date)
									])),
							model.bu,
							$author$project$Pages$Users$Hisense$Amazon$amazonRankingDecoder,
							$author$project$Pages$Users$Hisense$Amazon$OnReceived));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, 60))
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var result = msg.a;
				if (!result.$) {
					var tableRows = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b1: false,
								dI: $elm$core$Maybe$Just(tableRows)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b1: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var _v3 = model.dI;
				if (!_v3.$) {
					var tableRows = _v3.a;
					var csv = $elm$bytes$Bytes$Encode$encode(
						function (byteCsv) {
							return $elm$bytes$Bytes$Encode$sequence(
								_List_fromArray(
									[
										$elm$bytes$Bytes$Encode$unsignedInt8(239),
										$elm$bytes$Bytes$Encode$unsignedInt8(187),
										$elm$bytes$Bytes$Encode$unsignedInt8(191),
										$elm$bytes$Bytes$Encode$bytes(byteCsv)
									]));
						}(
							$commonmind$elm_csv_encode$Csv$Encode$toBytes(
								$author$project$Pages$Users$Hisense$Amazon$toCsv(tableRows))));
					return _Utils_Tuple2(
						model,
						A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Hisense$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$Hisense$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Hisense$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$Hisense$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Hisense$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Hisense$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$hisenseEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$Hisense$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Hisense$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$hisenseEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$Hisense$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Hisense$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hisense$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2($elm$url$Url$Builder$string, 'maker', '-1')
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return A2($author$project$Pages$Users$Common$Default$update, msg, model);
		}
	});
var $author$project$Pages$Users$Hisense$Excel$GetExcelRequestSent = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj);
						if (!_v1.$) {
							var dateTimePartsTo = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimePartsTo))));
							return A3(
								$author$project$Api$getByte,
								$author$project$Endpoints$User$hisenseEndpoints.ex.dA(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(60)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$Hisense$Excel$GetExcelRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 7:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$Hisense$Excel$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var bytes = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, c5: false}),
						A3($elm$file$File$Download$bytes, model.du + (' ' + (model.hj + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
				} else {
					var e = msg.a.a;
					var errMsg = function () {
						if ((e.$ === 3) && (e.a === 404)) {
							return '該当するデータが見つかりませんでした。検索条件を変更して再度お試しください。';
						} else {
							return $author$project$Utils$Helpers$errToStr(e);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(errMsg),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$Hisense$Review$ReceiveResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hisense$Review$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bc: function (p) {
								return A3($author$project$Utils$Helpers$formatDate, p.mL, p.lp, p.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										11,
										-3,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										posix))),
							by: function (p) {
								return A3($author$project$Utils$Helpers$formatDate, p.mL, p.lp, p.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									posix))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bc: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{by: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					model,
					A3(
						$author$project$Api$getByte,
						$author$project$Endpoints$User$hisenseEndpoints.l1.kW(
							_List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'date_from', model.bc),
									A2($elm$url$Url$Builder$string, 'date_to', model.by)
								])),
						model.bu,
						$author$project$Pages$Users$Hisense$Review$ReceiveResponse));
			default:
				if (!msg.a.$) {
					var bytes = msg.a.a;
					return _Utils_Tuple2(
						model,
						A3($elm$file$File$Download$bytes, model.bc + ('〜' + (model.by + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$Hisense$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$GetPriceResponse = F2(
	function (dictPrice, stores) {
		return {h4: dictPrice, ap: stores};
	});
var $author$project$Pages$Users$Hisense$TimeSeq$Price = F9(
	function (storeId, nth, dateTime, price, priceIncludeTax, point, priceWithoutPoint, coupon, url) {
		return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, hs: storeId, dS: url};
	});
var $author$project$Pages$Users$Hisense$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Users$Hisense$TimeSeq$Price))))))))));
var $author$project$Pages$Users$Hisense$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Hisense$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$Hisense$TimeSeq$getPriceResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Users$Hisense$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Hisense$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $author$project$Pages$Users$Hisense$TimeSeq$ProductPriceData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$ProductPriceNoData = {$: 2};
var $author$project$Pages$Users$Hisense$TimeSeq$StoreHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$pProductPriceRows = F4(
	function (stores, maxRowNum, prices, basePrice) {
		return A2(
			$elm$core$List$map,
			function (store) {
				return A2(
					$elm$core$List$cons,
					$author$project$Pages$Users$Hisense$TimeSeq$StoreHeader(store.h6),
					A2(
						$elm$core$List$map,
						function (ind) {
							var prevMaybePrice = $elm_community$maybe_extra$Maybe$Extra$orList(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$map,
										function (prevInd) {
											return A2(
												$elm$core$Dict$get,
												_Utils_Tuple2(store.hs, prevInd),
												prices);
										},
										A2($elm$core$List$range, 1, ind - 1))));
							var lowestPrice = A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.aW;
									},
									A2(
										$elm_community$list_extra$List$Extra$minimumBy,
										function ($) {
											return $.aW;
										},
										$elm_community$maybe_extra$Maybe$Extra$values(
											A2(
												$elm$core$List$map,
												function (st) {
													return A2(
														$elm$core$Dict$get,
														_Utils_Tuple2(st.hs, ind),
														prices);
												},
												stores)))));
							var currentMaybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(store.hs, ind),
								prices);
							if (!currentMaybePrice.$) {
								var currentPrice = currentMaybePrice.a;
								return $author$project$Pages$Users$Hisense$TimeSeq$ProductPriceData(
									{
										cO: (_Utils_cmp(currentPrice.aW, basePrice) < 0) ? (_Utils_eq(currentPrice.aW, lowestPrice) ? 0 : 1) : (_Utils_eq(currentPrice.aW, lowestPrice) ? 2 : 3),
										fl: function () {
											if (!prevMaybePrice.$) {
												var prevPrice = prevMaybePrice.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}(),
										aW: currentPrice.aW,
										dS: currentPrice.dS
									});
							} else {
								return $author$project$Pages$Users$Hisense$TimeSeq$ProductPriceNoData;
							}
						},
						A2($elm$core$List$range, 1, maxRowNum - 1)));
			},
			stores);
	});
var $author$project$Pages$Users$Hisense$TimeSeq$formatDateTimeWithoutYear = function (parts) {
	return A2(
		$elm$core$String$right,
		2,
		'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(parts.lp)) + ('月' + (A2(
		$elm$core$String$right,
		2,
		'0' + $elm$core$String$fromInt(parts.gp)) + ('日' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln))));
};
var $author$project$Pages$Users$Hisense$TimeSeq$pStoreHeader = F3(
	function (maybeFromParts, maybeToParts, interval) {
		var _v0 = _Utils_Tuple2(maybeFromParts, maybeToParts);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var fromParts = _v0.a.a;
			var toParts = _v0.b.a;
			var toPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				toParts);
			var fromPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				fromParts);
			return function (ls) {
				return A2($elm$core$List$cons, '', ls);
			}(
				A2(
					$elm$core$List$map,
					function (posix) {
						return $author$project$Pages$Users$Hisense$TimeSeq$formatDateTimeWithoutYear(
							A2(
								$justinmimbs$time_extra$Time$Extra$posixToParts,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								posix));
					},
					A5(
						$justinmimbs$time_extra$Time$Extra$range,
						13,
						interval,
						$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
						fromPosix,
						toPosix)));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$Users$Hisense$TimeSeq$toCsv = F2(
	function (headerDateTimes, bodyProducts) {
		var header = headerDateTimes;
		var body = A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$elm$core$List$map,
					function (product) {
						switch (product.$) {
							case 0:
								var storeName = product.a;
								return storeName;
							case 1:
								var cell = product.a;
								return A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $author$project$Utils$Helpers$toCommmaSplited)(cell.aW);
							default:
								return '';
						}
					},
					productList);
			},
			bodyProducts);
		return {
			cY: _List_Nil,
			lX: A2($elm$core$List$cons, header, body)
		};
	});
var $author$project$Pages$Users$Hisense$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var _v1 = model.hf;
				if (!_v1.$) {
					var product = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: true}),
						function () {
							var _v2 = _Utils_Tuple2(
								A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
								A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
							if ((!_v2.a.$) && (!_v2.b.$)) {
								var dateTimePartsFrom = _v2.a.a;
								var dateTimePartsTo = _v2.b.a;
								var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
								var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
								return A4(
									$author$project$Api$get,
									$author$project$Endpoints$User$jvcComnetEndpoints.hw.C(
										_List_fromArray(
											[
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'interval',
												$elm$core$String$fromInt(model.Z)),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'products',
												_List_fromArray(
													[product]))
											])),
									model.bu,
									$author$project$Pages$Users$Hisense$TimeSeq$getPriceResponseDecoder,
									$author$project$Pages$Users$Hisense$TimeSeq$GetPriceRequestSent);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c6: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{he: selectedBigCategory}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fH: selectedSmallCategoryId}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var productId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c6: true,
							hf: (productId === '-1') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(productId)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ja: initResponse.ja, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, gz: true, c5: false, ck: getPriceResponse.h4, ap: getPriceResponse.ap, dB: model.du, dC: model.dv, dD: model.hf, dE: model.hi, dF: model.hj}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var headerDateTimes = A3(
					$author$project$Pages$Users$Hisense$TimeSeq$pStoreHeader,
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
					model.Z);
				var currentProduct = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$Helpers$defaultProduct,
					A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.i8;
							},
							$elm$core$Basics$eq(
								A2($elm$core$Maybe$withDefault, '', model.dD))),
						model.ja));
				var bodyProducts = A4(
					$author$project$Pages$Users$Hisense$TimeSeq$pProductPriceRows,
					model.ap,
					$elm$core$List$length(headerDateTimes),
					model.ck,
					currentProduct.eb);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A2($author$project$Pages$Users$Hisense$TimeSeq$toCsv, headerDateTimes, bodyProducts))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Hisense$TvHour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2($elm$url$Url$Builder$string, 'bigCategory', '-1'),
											A2($elm$url$Url$Builder$string, 'smallCategory', '-1'),
											A2($elm$url$Url$Builder$string, 'maker', '26')
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2($elm$url$Url$Builder$string, 'bigCategory', '-1'),
											A2($elm$url$Url$Builder$string, 'smallCategory', '-1'),
											A2($elm$url$Url$Builder$string, 'maker', '26')
										])),
								model.bu,
								$author$project$Pages$Users$Common$Default$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$Default$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			default:
				return A2($author$project$Pages$Users$Common$Default$update, msg, model);
		}
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$GetPriceResponse = F2(
	function (dictPrice, stores) {
		return {h4: dictPrice, ap: stores};
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$Price = F9(
	function (storeId, nth, dateTime, price, priceIncludeTax, point, priceWithoutPoint, coupon, url) {
		return {h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, hs: storeId, dS: url};
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									$elm$json$Json$Decode$succeed($author$project$Pages$Users$JvcComnet$TimeSeq$Price))))))))));
var $author$project$Pages$Users$JvcComnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$JvcComnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$JvcComnet$TimeSeq$getPriceResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Users$JvcComnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$JvcComnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'stores', $author$project$Utils$Decoders$storesDecoder));
var $author$project$Pages$Users$JvcComnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$ProductPriceNoData = {$: 2};
var $author$project$Pages$Users$JvcComnet$TimeSeq$StoreHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$pProductPriceRows = F4(
	function (stores, maxRowNum, prices, basePrice) {
		return A2(
			$elm$core$List$map,
			function (store) {
				return A2(
					$elm$core$List$cons,
					$author$project$Pages$Users$JvcComnet$TimeSeq$StoreHeader(store.h6),
					A2(
						$elm$core$List$map,
						function (ind) {
							var prevMaybePrice = $elm_community$maybe_extra$Maybe$Extra$orList(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$map,
										function (prevInd) {
											return A2(
												$elm$core$Dict$get,
												_Utils_Tuple2(store.hs, prevInd),
												prices);
										},
										A2($elm$core$List$range, 1, ind - 1))));
							var lowestPrice = A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.aW;
									},
									A2(
										$elm_community$list_extra$List$Extra$minimumBy,
										function ($) {
											return $.aW;
										},
										$elm_community$maybe_extra$Maybe$Extra$values(
											A2(
												$elm$core$List$map,
												function (st) {
													return A2(
														$elm$core$Dict$get,
														_Utils_Tuple2(st.hs, ind),
														prices);
												},
												stores)))));
							var currentMaybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(store.hs, ind),
								prices);
							if (!currentMaybePrice.$) {
								var currentPrice = currentMaybePrice.a;
								return $author$project$Pages$Users$JvcComnet$TimeSeq$ProductPriceData(
									{
										cO: (_Utils_cmp(currentPrice.aW, basePrice) < 0) ? (_Utils_eq(currentPrice.aW, lowestPrice) ? 0 : 1) : (_Utils_eq(currentPrice.aW, lowestPrice) ? 2 : 3),
										fl: function () {
											if (!prevMaybePrice.$) {
												var prevPrice = prevMaybePrice.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}(),
										aW: currentPrice.aW,
										dS: currentPrice.dS
									});
							} else {
								return $author$project$Pages$Users$JvcComnet$TimeSeq$ProductPriceNoData;
							}
						},
						A2($elm$core$List$range, 1, maxRowNum - 1)));
			},
			stores);
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$formatDateTimeWithoutYear = function (parts) {
	return A2(
		$elm$core$String$right,
		2,
		'0' + A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $author$project$Utils$Helpers$monthToInt)(parts.lp)) + ('月' + (A2(
		$elm$core$String$right,
		2,
		'0' + $elm$core$String$fromInt(parts.gp)) + ('日' + A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln))));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$pStoreHeader = F3(
	function (maybeFromParts, maybeToParts, interval) {
		var _v0 = _Utils_Tuple2(maybeFromParts, maybeToParts);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var fromParts = _v0.a.a;
			var toParts = _v0.b.a;
			var toPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				toParts);
			var fromPosix = A2(
				$justinmimbs$time_extra$Time$Extra$partsToPosix,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				fromParts);
			return function (ls) {
				return A2($elm$core$List$cons, '', ls);
			}(
				A2(
					$elm$core$List$map,
					function (posix) {
						return $author$project$Pages$Users$JvcComnet$TimeSeq$formatDateTimeWithoutYear(
							A2(
								$justinmimbs$time_extra$Time$Extra$posixToParts,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								posix));
					},
					A5(
						$justinmimbs$time_extra$Time$Extra$range,
						13,
						interval,
						$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
						fromPosix,
						toPosix)));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$toCsv = F2(
	function (headerDateTimes, bodyProducts) {
		var header = headerDateTimes;
		var body = A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$elm$core$List$map,
					function (product) {
						switch (product.$) {
							case 0:
								var storeName = product.a;
								return storeName;
							case 1:
								var cell = product.a;
								return A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $author$project$Utils$Helpers$toCommmaSplited)(cell.aW);
							default:
								return '';
						}
					},
					productList);
			},
			bodyProducts);
		return {
			cY: _List_Nil,
			lX: A2($elm$core$List$cons, header, body)
		};
	});
var $author$project$Pages$Users$JvcComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var _v1 = model.hf;
				if (!_v1.$) {
					var product = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: true}),
						function () {
							var _v2 = _Utils_Tuple2(
								A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
								A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
							if ((!_v2.a.$) && (!_v2.b.$)) {
								var dateTimePartsFrom = _v2.a.a;
								var dateTimePartsTo = _v2.b.a;
								var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
								var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
								return A4(
									$author$project$Api$get,
									$author$project$Endpoints$User$jvcComnetEndpoints.hw.C(
										_List_fromArray(
											[
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'interval',
												$elm$core$String$fromInt(model.Z)),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'products',
												_List_fromArray(
													[product]))
											])),
									model.bu,
									$author$project$Pages$Users$JvcComnet$TimeSeq$getPriceResponseDecoder,
									$author$project$Pages$Users$JvcComnet$TimeSeq$GetPriceRequestSent);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c6: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{he: selectedBigCategory}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fH: selectedSmallCategoryId}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var productId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c6: true,
							hf: (productId === '-1') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(productId)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ja: initResponse.ja, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, gz: true, c5: false, ck: getPriceResponse.h4, ap: getPriceResponse.ap, dB: model.du, dC: model.dv, dD: model.hf, dE: model.hi, dF: model.hj}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var headerDateTimes = A3(
					$author$project$Pages$Users$JvcComnet$TimeSeq$pStoreHeader,
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
					model.Z);
				var currentProduct = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$Helpers$defaultProduct,
					A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.i8;
							},
							$elm$core$Basics$eq(
								A2($elm$core$Maybe$withDefault, '', model.dD))),
						model.ja));
				var bodyProducts = A4(
					$author$project$Pages$Users$JvcComnet$TimeSeq$pProductPriceRows,
					model.ap,
					$elm$core$List$length(headerDateTimes),
					model.ck,
					currentProduct.eb);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A2($author$project$Pages$Users$JvcComnet$TimeSeq$toCsv, headerDateTimes, bodyProducts))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$KinujoComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$KinujoComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{bz: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 7:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{bz: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 10:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'trigger_flag',
								$elm$json$Json$Encode$bool(product.bz)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$KinujoComnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'trigger_flag',
									$elm$json$Json$Encode$bool(product.bz)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$KinujoComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$KinujoComnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$KinujoComnet$Excel$GetExcelRequestSent = function (a) {
	return {$: 12, a: a};
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Pages$Users$KinujoComnet$Excel$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3(
								$author$project$Utils$Helpers$formatDate,
								date.mL,
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$elm$time$Time$utc,
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										3,
										-1,
										$elm$time$Time$utc,
										A2($justinmimbs$time_extra$Time$Extra$partsToPosix, $elm$time$Time$utc, date))).lp,
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$elm$time$Time$utc,
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										3,
										-1,
										$elm$time$Time$utc,
										A2($justinmimbs$time_extra$Time$Extra$partsToPosix, $elm$time$Time$utc, date))).gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A3(
								$author$project$Api$getByte,
								$author$project$Endpoints$User$kinujoComnetEndpoints.ex.dA(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(15)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$KinujoComnet$Excel$GetExcelRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 7:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$KinujoComnet$Excel$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var bytes = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, c5: false}),
						A3($elm$file$File$Download$bytes, model.du + ('〜' + (model.hj + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
				} else {
					var e = msg.a.a;
					var errMsg = function () {
						if ((e.$ === 3) && (e.a === 404)) {
							return '該当するデータが見つかりませんでした。検索条件を変更して再度お試しください。';
						} else {
							return $author$project$Utils$Helpers$errToStr(e);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(errMsg),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$KinujoComnet$Fifteen$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$KinujoComnet$Fifteen$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$KinujoComnet$Fifteen$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$KinujoComnet$Fifteen$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple3(product.mM, product.mz, maybeYodobashiPrice);
									if (_v4.b) {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.i4, yodobashiPrice.i4) < 0;
										} else {
											return _Utils_cmp(price.i4, product.eb) < 0;
										}
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$KinujoComnet$Fifteen$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$KinujoComnet$Fifteen$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$KinujoComnet$Fifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$KinujoComnet$Fifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$KinujoComnet$Fifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$KinujoComnet$Fifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$KinujoComnet$Fifteen$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$KinujoComnet$Fifteen$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$KinujoComnet$Fifteen$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$GetPriceRequestSent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return {h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$MlnComnet$AutoReload$Price)))))))))));
var $author$project$Pages$Users$MlnComnet$AutoReload$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$MlnComnet$AutoReload$priceDecoder)));
var $author$project$Pages$Users$MlnComnet$AutoReload$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$AutoReload$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$MlnComnet$AutoReload$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceNoData = {$: 1};
var $author$project$Utils$Helpers$compareProductBySortOption = F3(
	function (sortOption, p1, p2) {
		if (!sortOption) {
			var _v1 = _Utils_Tuple2(p1.hb, p2.hb);
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var d1 = _v1.a.a;
					var d2 = _v1.b.a;
					return (_Utils_cmp(
						$elm$time$Time$posixToMillis(d1),
						$elm$time$Time$posixToMillis(d2)) < 0) ? 2 : 0;
				} else {
					var _v2 = _v1.b;
					return 0;
				}
			} else {
				if (!_v1.b.$) {
					var _v3 = _v1.a;
					return 2;
				} else {
					return 1;
				}
			}
		} else {
			return (_Utils_cmp(p1.lT, p2.lT) < 0) ? 0 : 2;
		}
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Pages$Users$MlnComnet$AutoReload$pProductPriceRows = F4(
	function (response, visibleStoreGroups, selectedDisplayStyle, selectedSortOption) {
		var stores = A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.ap;
			},
			A2(
				$elm$core$List$filter,
				function (storeGroup) {
					return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
				},
				response.U));
		var products = response.ja;
		return A2(
			$elm$core$List$indexedMap,
			function (ind) {
				return function (product) {
					var maybeYodobashiPrice = A2(
						$elm$core$Dict$get,
						_Utils_Tuple2(product.i8, 7),
						response.h4);
					var lowestPrice = A3(
						$elm$core$List$foldl,
						function (price) {
							return function (m) {
								return A2($elm$core$Basics$min, m, price);
							};
						},
						A2($elm$core$Basics$pow, 2, 30),
						A2(
							$elm$core$List$map,
							function (maybePrice) {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
								} else {
									return A2($elm$core$Basics$pow, 2, 30);
								}
							},
							A2(
								$elm$core$List$map,
								function (store) {
									return A2(
										$elm$core$Dict$get,
										_Utils_Tuple2(product.i8, store.hs),
										response.h4);
								},
								stores)));
					var itemList = _List_fromArray(
						[
							{iF: '税抜き', i: 0},
							{iF: '税込み', i: 1},
							{iF: 'P/クーポン', i: 2},
							{iF: 'P後', i: 3},
							{iF: '取得時刻', i: 4}
						]);
					var filterItemList = function (list) {
						return A2(
							$elm$core$List$map,
							function (i) {
								return A2(
									$elm$core$Maybe$withDefault,
									{iF: '', i: -1},
									A2($elm_community$list_extra$List$Extra$getAt, i, list));
							},
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									'DisplayAll',
									_List_fromArray(
										[0, 1, 2, 3, 4])),
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (dispToValue) {
										return _Utils_eq(dispToValue.a, selectedDisplayStyle);
									},
									$author$project$Pages$Users$MlnComnet$AutoReload$displayStyleToValues)).b);
					};
					var filteredItemList = filterItemList(itemList);
					var _v0 = function () {
						var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
						if (_v1.a && (!_v1.b.$)) {
							var yodobashiPrice = _v1.b.a;
							return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
						} else {
							return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
						}
					}();
					var basePrice = _v0.ka;
					var basePriceIncludeTax = _v0.eb;
					var basePoint = _v0.j9;
					var basePriceWithoutPoint = _v0.bM;
					var basePriceList = _List_fromArray(
						[
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePrice)),
							i: 0
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceIncludeTax)),
							i: 1
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePoint)),
							i: 2
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceWithoutPoint)),
							i: 3
						},
							{iF: '-', i: 4}
						]);
					var filteredBasePriceList = filterItemList(basePriceList);
					return _Utils_ap(
						_List_fromArray(
							[
								$author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceHeader(
								_List_fromArray(
									[
										{
										iF: $elm$core$String$fromInt(ind + 1),
										i: 0
									}
									])),
								$author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceHeader(
								_List_fromArray(
									[
										{iF: product.lT, i: 0}
									])),
								$author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceHeader(filteredItemList),
								$author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceHeader(filteredBasePriceList)
							]),
						A2(
							$elm$core$List$map,
							function (store) {
								var maybePrice = A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									response.h4);
								var isLowest = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return _Utils_eq(price.aW, lowestPrice);
									} else {
										return false;
									}
								}();
								var isLowestButHigherThanBase = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v6.a && (!_v6.b.$)) {
											var yodobashiPrice = _v6.b.a;
											return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
										} else {
											return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										}
									} else {
										return false;
									}
								}();
								var isLower = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v4.a && (!_v4.b.$)) {
											var yodobashiPrice = _v4.b.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										return false;
									}
								}();
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var priceList = _List_fromArray(
										[
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i3)),
											i: 0
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i4)),
											i: 1
										},
											{
											iF: function (point) {
												return _Utils_ap(
													point,
													(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
											}(
												$elm$core$String$fromInt(price.iZ)),
											i: 2
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.aW)),
											i: 3
										},
											{
											iF: A2(
												$elm$core$Maybe$withDefault,
												'',
												$elm$core$List$head(
													A2(
														$elm$core$Maybe$withDefault,
														_List_Nil,
														$elm$core$List$tail(
															A2($elm$core$String$split, ' ', price.kw))))),
											i: 4
										}
										]);
									var filteredPriceList = filterItemList(priceList);
									return $author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceData(
										{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
								} else {
									return $author$project$Pages$Users$MlnComnet$AutoReload$ProductPriceNoData;
								}
							},
							stores));
				};
			},
			A2(
				$elm$core$List$sortWith,
				$author$project$Utils$Helpers$compareProductBySortOption(selectedSortOption),
				products));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '形番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.jD};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$MlnComnet$AutoReload$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $krisajenkins$remotedata$RemoteData$unwrap = F3(
	function (_default, _function, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return _function(data);
		} else {
			return _default;
		}
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $krisajenkins$remotedata$RemoteData$Success(getPriceResponse),
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								br: $krisajenkins$remotedata$RemoteData$Failure(
									$author$project$Utils$Helpers$errToStr(error))
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				var now = msg.a;
				var submit = function () {
					var dateTimeParts = function (n) {
						return _Utils_update(
							n,
							{ln: ((n.ln / 30) | 0) * 30});
					}(
						A2(
							$justinmimbs$time_extra$Time$Extra$posixToParts,
							$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
							now));
					var from = $author$project$Utils$Helpers$formatDateTime(
						A2(
							$justinmimbs$time_extra$Time$Extra$posixToParts,
							$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
							A4(
								$justinmimbs$time_extra$Time$Extra$add,
								13,
								(-1) * 30,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								A2(
									$justinmimbs$time_extra$Time$Extra$partsToPosix,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									dateTimeParts))));
					var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eT: $elm$core$Maybe$Just(now),
								br: $krisajenkins$remotedata$RemoteData$Loading
							}),
						A4(
							$author$project$Api$get,
							$author$project$Endpoints$User$defaultEndpoints.C(
								_List_fromArray(
									[
										A2($elm$url$Url$Builder$string, 'from', from),
										A2($elm$url$Url$Builder$string, 'to', to),
										A2(
										$elm$url$Url$Builder$string,
										'interval',
										$elm$core$String$fromInt(30)),
										A2($elm$url$Url$Builder$string, 'usePast', 'true'),
										A2($elm$url$Url$Builder$string, 'queryOnlyAlertProduct', 'true')
									])),
							model.bu,
							$author$project$Pages$Users$MlnComnet$AutoReload$getPriceResponseDecoder,
							$author$project$Pages$Users$MlnComnet$AutoReload$GetPriceRequestSent));
				}();
				var _v2 = model.eT;
				if (!_v2.$) {
					var latest = _v2.a;
					var nowMinute = function (t) {
						return (t.ln / 30) | 0;
					}(
						A2(
							$justinmimbs$time_extra$Time$Extra$posixToParts,
							$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
							now));
					var latestMinute = function (t) {
						return (t.ln / 30) | 0;
					}(
						A2(
							$justinmimbs$time_extra$Time$Extra$posixToParts,
							$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
							latest));
					return (!_Utils_eq(latestMinute, nowMinute)) ? submit : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return submit;
				}
			case 2:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var opt = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{js: opt}),
					$author$project$Api$createFixedMidashi(0));
			case 1:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			default:
				return _Utils_Tuple2(
					model,
					A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						$elm$core$Platform$Cmd$none,
						function (response) {
							var storeHeader = A2($author$project$Pages$Users$MlnComnet$AutoReload$pStoreHeader, response.U, model.L);
							var priceRows = A4($author$project$Pages$Users$MlnComnet$AutoReload$pProductPriceRows, response, model.L, model.cq, model.js);
							var csvFileName = 'data.csv';
							var csv = $elm$bytes$Bytes$Encode$encode(
								function (byteCsv) {
									return $elm$bytes$Bytes$Encode$sequence(
										_List_fromArray(
											[
												$elm$bytes$Bytes$Encode$unsignedInt8(239),
												$elm$bytes$Bytes$Encode$unsignedInt8(187),
												$elm$bytes$Bytes$Encode$unsignedInt8(191),
												$elm$bytes$Bytes$Encode$bytes(byteCsv)
											]));
								}(
									$commonmind$elm_csv_encode$Csv$Encode$toBytes(
										A3($author$project$Pages$Users$MlnComnet$AutoReload$toCsv, storeHeader, priceRows, model.cq))));
							return A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv);
						},
						model.br));
		}
	});
var $author$project$Pages$Users$MlnComnet$ChangeMail$FourTimesPerDay = 0;
var $author$project$Pages$Users$MlnComnet$ChangeMail$Nop = {$: 5};
var $author$project$Pages$Users$MlnComnet$ChangeMail$OncePerHour = 1;
var $author$project$Pages$Users$MlnComnet$ChangeMail$mapMailColType = function (colType) {
	return (!colType) ? 'FourTimesPerDay' : 'OncePerHour';
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {k5: index, lh: match, lu: number, mm: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ke: false, ls: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Pages$Users$MlnComnet$ChangeMail$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\S+@\\S+\\.\\S+$'));
var $author$project$Pages$Users$MlnComnet$ChangeMail$validateEmails = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$split(','),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map($elm$core$String$trim),
		$elm$core$List$all(
			$elm$regex$Regex$contains($author$project$Pages$Users$MlnComnet$ChangeMail$emailRegex))));
var $author$project$Pages$Users$MlnComnet$ChangeMail$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eK: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var q = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bn: q}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var productID = msg.a;
				var mailColType = msg.b;
				var newEmails = msg.c;
				return A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					function (initResponse) {
						var newPms = A3(
							$elm_community$list_extra$List$Extra$updateIf,
							function (pm) {
								return _Utils_eq(pm.an.i8, productID);
							},
							function (pm) {
								return (mailColType === 1) ? _Utils_update(
									pm,
									{
										bi: A3($elm$core$String$replace, ' ', '', newEmails)
									}) : _Utils_update(
									pm,
									{
										bh: A3($elm$core$String$replace, ' ', '', newEmails)
									});
							},
							initResponse.fn);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									eK: $krisajenkins$remotedata$RemoteData$Success(
										_Utils_update(
											initResponse,
											{fn: newPms}))
								}),
							$elm$core$Platform$Cmd$none);
					},
					model.eK);
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var productID = msg.a;
				var fourTimesPerDayMails = msg.b;
				var oncePerHourMails = msg.c;
				return ($author$project$Pages$Users$MlnComnet$ChangeMail$validateEmails(fourTimesPerDayMails) && $author$project$Pages$Users$MlnComnet$ChangeMail$validateEmails(oncePerHourMails)) ? _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A4(
								$author$project$Api$postWhatever,
								$author$project$Endpoints$User$mlnComnetEndpoints.j2.mB(_List_Nil),
								model.bu,
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'productId',
											$elm$json$Json$Encode$string(productID)),
											_Utils_Tuple2(
											'mailAddressList',
											A2(
												$elm$json$Json$Encode$list,
												$elm$json$Json$Encode$string,
												A2(
													$elm$core$String$split,
													',',
													A3($elm$core$String$replace, ' ', '', fourTimesPerDayMails)))),
											_Utils_Tuple2(
											'frequency',
											$elm$json$Json$Encode$string(
												$author$project$Pages$Users$MlnComnet$ChangeMail$mapMailColType(0)))
										])),
								$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeMail$Nop)),
								A4(
								$author$project$Api$postWhatever,
								$author$project$Endpoints$User$mlnComnetEndpoints.j2.mB(_List_Nil),
								model.bu,
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'productId',
											$elm$json$Json$Encode$string(productID)),
											_Utils_Tuple2(
											'mailAddressList',
											A2(
												$elm$json$Json$Encode$list,
												$elm$json$Json$Encode$string,
												A2(
													$elm$core$String$split,
													',',
													A3($elm$core$String$replace, ' ', '', oncePerHourMails)))),
											_Utils_Tuple2(
											'frequency',
											$elm$json$Json$Encode$string(
												$author$project$Pages$Users$MlnComnet$ChangeMail$mapMailColType(1)))
										])),
								$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeMail$Nop))
							]))) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var opt = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{js: opt}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$ChangePassword$GotResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var p = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cP: p, aA: $krisajenkins$remotedata$RemoteData$NotAsked}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var p = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aR: p, aA: $krisajenkins$remotedata$RemoteData$NotAsked}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var p = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ds: p, aA: $krisajenkins$remotedata$RemoteData$NotAsked}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: $krisajenkins$remotedata$RemoteData$Loading}),
					A4(
						$author$project$Api$postWhatever,
						$author$project$Endpoints$User$mlnComnetEndpoints.mC.ad(_List_Nil),
						model.bu,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'currentPassword',
									$elm$json$Json$Encode$string(model.cP)),
									_Utils_Tuple2(
									'newPassword',
									$elm$json$Json$Encode$string(model.aR))
								])),
						$author$project$Pages$Users$MlnComnet$ChangePassword$GotResponse));
			default:
				if (msg.a.$ === 1) {
					var err = msg.a.a;
					var errStr = function () {
						if (err.$ === 3) {
							var code = err.a;
							if (code === 409) {
								return '現在のパスワードが間違っています';
							} else {
								return 'エラーが発生しました';
							}
						} else {
							return 'エラーが発生しました';
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aA: $krisajenkins$remotedata$RemoteData$Failure(errStr)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aA: $krisajenkins$remotedata$RemoteData$Success(0)
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateProduct = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshot = {$: 15};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshotSent = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts = function (model) {
	return A2(
		$elm$core$List$filter,
		function (p) {
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				true,
				function (bigCategory) {
					return _Utils_eq(bigCategory, p.ed);
				},
				model.he) && (A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				true,
				function (smallCategory) {
					return _Utils_eq(smallCategory, p.ho);
				},
				model.fH) && A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(model.bn),
				$elm$core$String$toLower(p.lT)));
		},
		A2(
			$elm$core$List$filter,
			function (p) {
				return (!model.ab) || (model.ab && p.bg);
			},
			A2(
				$elm$core$List$filter,
				function (p) {
					return (!model.ay) || (model.ay && (!(!p.ka)));
				},
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.i8;
					},
					model.ja))));
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{
					lU: $elm$core$Maybe$Just(newOrd)
				});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(
						p.lU,
						$elm$core$Maybe$Just(newOrd));
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(
						p.lU,
						$elm$core$Maybe$Just(newOrd));
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2(
			$elm_community$list_extra$List$Extra$getAt,
			ind,
			$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model));
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3(
				$elm_community$list_extra$List$Extra$setIf,
				function (p) {
					return _Utils_eq(p.i8, newProduct.i8);
				},
				newProduct,
				model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$MlnComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								b2: false,
								ja: initResponse.ja,
								he: $elm_community$maybe_extra$Maybe$Extra$join(
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.ed;
										},
										initResponse.cW)),
								fH: $elm_community$maybe_extra$Maybe$Extra$join(
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.ho;
										},
										initResponse.cW)),
								ay: A2(
									$elm$core$Maybe$withDefault,
									false,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.jw;
										},
										initResponse.cW)),
								ab: A2(
									$elm$core$Maybe$withDefault,
									false,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.ab;
										},
										initResponse.cW)),
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var maybeProduct = A2(
					$elm_community$list_extra$List$Extra$getAt,
					ind,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model));
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$always(
								A2($author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateProduct, ind, product)),
							$elm$core$Task$succeed(0)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var ind = msg.a;
				var b = msg.b;
				var maybeProduct = A2(
					$elm_community$list_extra$List$Extra$getAt,
					ind,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model));
				var f = function (product) {
					return _Utils_update(
						product,
						{bg: b});
				};
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					return _Utils_Tuple2(
						A3($author$project$Pages$Users$MlnComnet$ChangeProduct$updateProduct, ind, model, f),
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$always(
								A2(
									$author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateProduct,
									ind,
									f(product))),
							$elm$core$Task$succeed(0)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				var maybeProduct = A2(
					$elm_community$list_extra$List$Extra$getAt,
					ind,
					$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model));
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					return _Utils_Tuple2(
						A3(
							$author$project$Pages$Users$MlnComnet$ChangeProduct$updateOrder,
							ind,
							model,
							A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(newOrder))),
						A2(
							$elm$core$Task$perform,
							$elm$core$Basics$always(
								A2($author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateProduct, ind, product)),
							$elm$core$Task$succeed(0)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var index = msg.a;
				var product = msg.b;
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'product_id',
							$elm$json$Json$Encode$string(product.i8)),
							_Utils_Tuple2(
							'base_price',
							$elm$json$Json$Encode$int(product.bM)),
							_Utils_Tuple2(
							'base_point',
							$elm$json$Json$Encode$int(product.j9)),
							_Utils_Tuple2(
							'product_order',
							$elm$json$Json$Encode$int(index + 1)),
							_Utils_Tuple2(
							'yodobashi_base_price_flag',
							$elm$json$Json$Encode$bool(false)),
							_Utils_Tuple2(
							'mail_enabled',
							$elm$json$Json$Encode$bool(product.bg)),
							_Utils_Tuple2(
							'get_interval',
							A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, product.kT))
						]));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$mlnComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$MlnComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductRequestSent));
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(false)),
									_Utils_Tuple2(
									'mail_enabled',
									$elm$json$Json$Encode$bool(product.bg)),
									_Utils_Tuple2(
									'get_interval',
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, product.kT))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$mlnComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$MlnComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductRequestSent));
			case 3:
				var filteredProduct = msg.a;
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.bM)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(false)),
									_Utils_Tuple2(
									'mail_enabled',
									$elm$json$Json$Encode$bool(product.bg)),
									_Utils_Tuple2(
									'get_interval',
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, product.kT))
								]));
					},
					filteredProduct);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$mlnComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$MlnComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$MlnComnet$ChangeProduct$UpdateProductRequestSent));
			case 8:
				var ind = msg.a;
				var input = msg.b;
				var newPriceWithoutPoint = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(input));
				var calcPrice = function (point) {
					return (((((100 * newPriceWithoutPoint) / (100 - point)) | 0) * 100) / (100 + 10)) | 0;
				};
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: calcPrice(product.j9),
							bM: newPriceWithoutPoint
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$MlnComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bigCategoryId = msg.a;
				var selectedBigCategory = function (c) {
					return _Utils_eq(
						c,
						$elm$core$Maybe$Just(-1)) ? $elm$core$Maybe$Nothing : c;
				}(
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{he: selectedBigCategory, fH: $elm$core$Maybe$Nothing}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshot),
						$elm$core$Task$succeed(0)));
			case 10:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = function (c) {
					return _Utils_eq(
						c,
						$elm$core$Maybe$Just(-1)) ? $elm$core$Maybe$Nothing : c;
				}(
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fH: selectedSmallCategoryId}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshot),
						$elm$core$Task$succeed(0)));
			case 11:
				var opt = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{js: opt}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var query = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bn: query}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ay: !model.ay}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshot),
						$elm$core$Task$succeed(0)));
			case 14:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: !model.ab}),
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshot),
						$elm$core$Task$succeed(0)));
			case 15:
				var body = $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'big_category_id',
							A2(
								$elm$core$Maybe$withDefault,
								$elm$json$Json$Encode$null,
								A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, model.he))),
							_Utils_Tuple2(
							'small_category_id',
							A2(
								$elm$core$Maybe$withDefault,
								$elm$json$Json$Encode$null,
								A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, model.fH))),
							_Utils_Tuple2(
							'display_only_base_price_enabled',
							$elm$json$Json$Encode$bool(model.ay)),
							_Utils_Tuple2(
							'display_only_mail_enabled',
							$elm$json$Json$Encode$bool(model.ab))
						]));
				return _Utils_Tuple2(
					model,
					A4($author$project$Api$postWhatever, $author$project$Endpoints$User$mlnComnetEndpoints.bN.l3, model.bu, body, $author$project$Pages$Users$MlnComnet$ChangeProduct$SaveFormSnapshotSent));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$Chronological$AttemptPollingExcelProcessingStatus = F2(
	function (a, b) {
		return {$: 44, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$Chronological$Failure = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GetExcelRequestSent = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GetPriceRequestSent = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotCreateSelectorSnapshotResult = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotDownloadExcel = function (a) {
	return {$: 45, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$GotExcelProcessingStatusPollingResult = F2(
	function (a, b) {
		return {$: 43, a: a, b: b};
	});
var $justinmimbs$time_extra$Time$Extra$Monday = 4;
var $author$project$Pages$Users$MlnComnet$Chronological$NotReady = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$time_extra$Time$Extra$Thursday = 7;
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Pages$Users$MlnComnet$Chronological$Ready = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$excelProcessingStatusDecoder = function (res) {
	return A2(
		$elm$json$Json$Decode$map,
		function (s) {
			switch (s) {
				case 'ready':
					return $author$project$Pages$Users$MlnComnet$Chronological$Ready(res);
				case 'not-ready':
					return $author$project$Pages$Users$MlnComnet$Chronological$NotReady(res);
				case 'failure':
					return $author$project$Pages$Users$MlnComnet$Chronological$Failure('内部エラーが発生しました。');
				default:
					return $author$project$Pages$Users$MlnComnet$Chronological$Failure('予期せぬエラーが発生しました。');
			}
		},
		A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string));
};
var $author$project$Pages$Users$MlnComnet$Chronological$ExcelRequestResponse = F2(
	function (downloadURL, filename) {
		return {gt: downloadURL, ih: filename};
	});
var $author$project$Pages$Users$MlnComnet$Chronological$excelRequestResponse = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Pages$Users$MlnComnet$Chronological$ExcelRequestResponse,
	A2($elm$json$Json$Decode$field, 'downloadURL', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'filename', $elm$json$Json$Decode$string));
var $elm_community$list_extra$List$Extra$andThen = $elm$core$List$concatMap;
var $elm_community$list_extra$List$Extra$lift2 = F3(
	function (f, la, lb) {
		return A2(
			$elm_community$list_extra$List$Extra$andThen,
			function (a) {
				return A2(
					$elm_community$list_extra$List$Extra$andThen,
					function (b) {
						return _List_fromArray(
							[
								A2(f, a, b)
							]);
					},
					lb);
			},
			la);
	});
var $elm_community$list_extra$List$Extra$cartesianProduct = function (ll) {
	if (!ll.b) {
		return _List_fromArray(
			[_List_Nil]);
	} else {
		var xs = ll.a;
		var xss = ll.b;
		return A3(
			$elm_community$list_extra$List$Extra$lift2,
			$elm$core$List$cons,
			xs,
			$elm_community$list_extra$List$Extra$cartesianProduct(xss));
	}
};
var $author$project$Pages$Users$MlnComnet$Chronological$filteredTimes = A2(
	$elm$core$List$map,
	function (hm) {
		if (!hm.b) {
			return '';
		} else {
			if (!hm.b.b) {
				return '';
			} else {
				if (!hm.b.b.b) {
					var h = hm.a;
					var _v1 = hm.b;
					var m = _v1.a;
					return A2($author$project$Utils$Helpers$formatTime, h, m);
				} else {
					return '';
				}
			}
		}
	},
	$elm_community$list_extra$List$Extra$cartesianProduct(
		_List_fromArray(
			[
				A2($elm$core$List$range, 0, 23),
				_List_fromArray(
				[0, 30])
			])));
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var y = xs.a;
			var ys = xs.b;
			return _Utils_eq(x, y) ? ys : A2(
				$elm$core$List$cons,
				y,
				A2($elm_community$list_extra$List$Extra$remove, x, ys));
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Modal$Show = 0;
var $frandibar$elm_bootstrap$Bootstrap$Modal$shown = 0;
var $author$project$Pages$Users$MlnComnet$Chronological$SubmitResponse = F3(
	function (rows, hasReachedlimit, hitDateTimes) {
		return {kX: hasReachedlimit, gC: hitDateTimes, jj: rows};
	});
var $elm_community$json_extra$Json$Decode$Extra$datetime = A2(
	$elm$json$Json$Decode$andThen,
	function (dateString) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(dateString);
		if (!_v0.$) {
			var v = _v0.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			return $elm$json$Json$Decode$fail('Expecting an ISO-8601 formatted date+time string');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Pages$Users$MlnComnet$Chronological$Row = F9(
	function (productId, storeId, category, productName, storeName, storeGroupName, url, alertPrice, productPrices) {
		return {hJ: alertPrice, gk: category, i8: productId, lT: productName, i9: productPrices, mi: storeGroupName, hs: storeId, jD: storeName, dS: url};
	});
var $author$project$Pages$Users$MlnComnet$Chronological$Cell = function (dateTime) {
	return function (price) {
		return function (priceIncludeTax) {
			return function (point) {
				return function (priceWithoutPoint) {
					return function (coupon) {
						return function (isLower) {
							return function (isLowest) {
								return function (isDecreased) {
									return function (isIncreased) {
										return {h3: coupon, kw: dateTime, iz: isDecreased, iA: isIncreased, gK: isLower, gM: isLowest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$MlnComnet$Chronological$cellDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'is_increased', $elm$json$Json$Decode$bool),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_decreased', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'is_lowest', $elm$json$Json$Decode$bool),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'is_lower', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'date_time', $elm_community$json_extra$Json$Decode$Extra$datetime),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$MlnComnet$Chronological$Cell)))))))))));
var $author$project$Pages$Users$MlnComnet$Chronological$rowDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'productPrices',
		$elm$json$Json$Decode$list(
			$elm$json$Json$Decode$maybe($author$project$Pages$Users$MlnComnet$Chronological$cellDecoder))),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'alert_price', $elm$json$Json$Decode$int),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'store_group_name', $elm$json$Json$Decode$string),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
									$elm$json$Json$Decode$succeed($author$project$Pages$Users$MlnComnet$Chronological$Row))))))))));
var $author$project$Pages$Users$MlnComnet$Chronological$submitResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$Chronological$SubmitResponse,
	A2(
		$elm$json$Json$Decode$field,
		'productPriceTimeSeries',
		$elm$json$Json$Decode$list($author$project$Pages$Users$MlnComnet$Chronological$rowDecoder)),
	A2($elm$json$Json$Decode$field, 'has_reached_limit', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$field,
		'hit_date_times',
		$elm$json$Json$Decode$list($elm_community$json_extra$Json$Decode$Extra$datetime)));
var $krisajenkins$remotedata$RemoteData$succeed = $krisajenkins$remotedata$RemoteData$Success;
var $author$project$Pages$Users$MlnComnet$Chronological$update = F2(
	function (msg, model) {
		_v0$51:
		while (true) {
			switch (msg.$) {
				case 52:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 0:
					var date = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
								dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
								hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
								hj: function (parts) {
									return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
								}(
									A2($author$project$Utils$Helpers$floorTimeByInterval, date, 60)),
								fY: $elm$core$Maybe$Just(
									A2(
										$justinmimbs$time_extra$Time$Extra$partsToPosix,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										date))
							}),
						$elm$core$Platform$Cmd$none);
				case 12:
					var date = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{du: date}),
						$elm$core$Platform$Cmd$none);
				case 13:
					var time = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hi: time}),
						$elm$core$Platform$Cmd$none);
				case 14:
					var date = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dv: date}),
						$elm$core$Platform$Cmd$none);
				case 15:
					var time = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hj: time}),
						$elm$core$Platform$Cmd$none);
				case 16:
					var time = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fI: time}),
						$elm$core$Platform$Cmd$none);
				case 17:
					var isHourly = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cA: model.aK ? $krisajenkins$remotedata$RemoteData$NotAsked : $krisajenkins$remotedata$RemoteData$Loading
							}),
						function () {
							var _v1 = _Utils_Tuple2(
								A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
								A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
							if ((!_v1.a.$) && (!_v1.b.$)) {
								var dateTimePartsFrom = _v1.a.a;
								var dateTimePartsTo = _v1.b.a;
								var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
								var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
								return (!model.aK) ? A4(
									$author$project$Api$get,
									$author$project$Endpoints$User$mlnComnetEndpoints.ki.dA(
										_List_fromArray(
											[
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'interval',
												isHourly ? '60' : $elm$core$String$fromInt(model.Z)),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'bigCategory',
												$elm$core$String$fromInt(model.he)),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'smallCategory',
												$elm$core$String$fromInt(model.fH)),
												A2(
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'maker',
												$elm$core$String$fromInt(
													A2($elm$core$Maybe$withDefault, -1, model.dw))),
												A3($Herteby$url_builder_plus$UrlBuilderPlus$list, $Herteby$url_builder_plus$UrlBuilderPlus$string, 'products', model.hh),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'stores',
												A2(
													$elm$core$List$map,
													$elm$core$Tuple$first,
													$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r))),
												A3(
												$Herteby$url_builder_plus$UrlBuilderPlus$list,
												$Herteby$url_builder_plus$UrlBuilderPlus$string,
												'filteredTimes',
												$elm$core$Set$toList(model.O)),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'queryOnlyAlertProduct', model.bo),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'queryOnlyLowest', model.bp),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'queryOnlyMoved', model.bq),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'priceEnabled', model.al),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'pointEnabled', model.aj),
												A2($Herteby$url_builder_plus$UrlBuilderPlus$bool, 'priceWithoutPointEnabled', model.am)
											])),
									model.bu,
									$author$project$Pages$Users$MlnComnet$Chronological$submitResponseDecoder,
									$author$project$Pages$Users$MlnComnet$Chronological$GetPriceRequestSent) : A5(
									$author$project$Api$post,
									$author$project$Endpoints$User$mlnComnetEndpoints.ki.kJ(_List_Nil),
									model.bu,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'from',
												$elm$json$Json$Encode$string(from)),
												_Utils_Tuple2(
												'to',
												$elm$json$Json$Encode$string(to)),
												_Utils_Tuple2(
												'interval',
												$elm$json$Json$Encode$string(
													isHourly ? '60' : $elm$core$String$fromInt(model.Z))),
												_Utils_Tuple2(
												'bigCategory',
												$elm$json$Json$Encode$string(
													$elm$core$String$fromInt(model.he))),
												_Utils_Tuple2(
												'smallCategory',
												$elm$json$Json$Encode$string(
													$elm$core$String$fromInt(model.fH))),
												_Utils_Tuple2(
												'maker',
												$elm$json$Json$Encode$string(
													$elm$core$String$fromInt(
														A2($elm$core$Maybe$withDefault, -1, model.dw)))),
												_Utils_Tuple2(
												'products',
												A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.hh)),
												_Utils_Tuple2(
												'stores',
												A2(
													$elm$json$Json$Encode$list,
													$elm$json$Json$Encode$string,
													A2(
														$elm$core$List$map,
														$elm$core$Tuple$first,
														$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))),
												_Utils_Tuple2(
												'separateSheet',
												$elm$json$Json$Encode$bool(model.cu)),
												_Utils_Tuple2(
												'specificTime',
												$elm$json$Json$Encode$string(model.fI)),
												_Utils_Tuple2(
												'priceEnabled',
												$elm$json$Json$Encode$bool(model.al)),
												_Utils_Tuple2(
												'pointEnabled',
												$elm$json$Json$Encode$bool(model.aj)),
												_Utils_Tuple2(
												'priceWithoutPointEnabled',
												$elm$json$Json$Encode$bool(model.am)),
												_Utils_Tuple2(
												'filteredTimes',
												A2(
													$elm$json$Json$Encode$list,
													$elm$json$Json$Encode$string,
													$elm$core$Set$toList(model.O))),
												_Utils_Tuple2(
												'queryOnlyAlertProduct',
												$elm$json$Json$Encode$bool(model.bo)),
												_Utils_Tuple2(
												'queryOnlyLowest',
												$elm$json$Json$Encode$bool(model.bp)),
												_Utils_Tuple2(
												'queryOnlyMoved',
												$elm$json$Json$Encode$bool(model.bq))
											])),
									$author$project$Pages$Users$MlnComnet$Chronological$excelRequestResponse,
									$author$project$Pages$Users$MlnComnet$Chronological$GetExcelRequestSent);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				case 9:
					var bigCategoryId = msg.a;
					var selectedBigCategory = A2(
						$elm$core$Maybe$withDefault,
						-1,
						$elm$core$String$toInt(bigCategoryId));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{he: selectedBigCategory, fH: -1}),
						$elm$core$Platform$Cmd$none);
				case 10:
					var smallCategoryId = msg.a;
					var selectedSmallCategoryId = A2(
						$elm$core$Maybe$withDefault,
						-1,
						$elm$core$String$toInt(smallCategoryId));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fH: selectedSmallCategoryId}),
						$elm$core$Platform$Cmd$none);
				case 11:
					var makerId = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dw: A2(
									$elm$core$Maybe$andThen,
									$elm$core$Maybe$Just,
									$elm$core$String$toInt(makerId))
							}),
						$elm$core$Platform$Cmd$none);
				case 49:
					var productId = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hh: _Utils_ap(
									model.hh,
									_List_fromArray(
										[productId]))
							}),
						$elm$core$Platform$Cmd$none);
				case 50:
					var productId = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hh: A2($elm_community$list_extra$List$Extra$remove, productId, model.hh)
							}),
						$elm$core$Platform$Cmd$none);
				case 8:
					var sub = msg.a;
					var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.r);
					var subModel = _v2.a;
					var subCmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{r: subModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$MlnComnet$Chronological$OnChangeStore, subCmd));
				case 1:
					var result = msg.a;
					if (!result.$) {
						var initResponse = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									as: $krisajenkins$remotedata$RemoteData$succeed(initResponse),
									r: A3(
										$inkuzmin$elm_multiselect$Multiselect$populateValues,
										model.r,
										A2(
											$elm$core$List$map,
											function (p) {
												return _Utils_Tuple2(
													$elm$core$String$fromInt(p.hs),
													p.jD);
											},
											initResponse.ap),
										_List_Nil)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									as: $krisajenkins$remotedata$RemoteData$Failure(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 2:
					var result = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bt: $krisajenkins$remotedata$RemoteData$fromResult(result)
							}),
						$elm$core$Platform$Cmd$none);
				case 5:
					var result = msg.a;
					if (!result.$) {
						var res = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cA: $krisajenkins$remotedata$RemoteData$Success(res),
									dG: model.du,
									fR: model.dv,
									dH: model.hi,
									fS: model.hj
								}),
							$author$project$Api$createFixedMidashi(0));
					} else {
						var error = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cA: $krisajenkins$remotedata$RemoteData$Failure(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 6:
					if (!msg.a.$) {
						var res = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									a9: $author$project$Pages$Users$MlnComnet$Chronological$NotReady(res),
									aJ: $frandibar$elm_bootstrap$Bootstrap$Modal$shown
								}),
							A2(
								$elm$core$Task$perform,
								$elm$core$Basics$always(
									A2(
										$author$project$Pages$Users$MlnComnet$Chronological$GotExcelProcessingStatusPollingResult,
										1000.0,
										$elm$core$Result$Ok(
											$author$project$Pages$Users$MlnComnet$Chronological$NotReady(res)))),
								$elm$core$Task$succeed(0)));
					} else {
						var e = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									a9: $author$project$Pages$Users$MlnComnet$Chronological$Failure('request.failed'),
									aJ: $frandibar$elm_bootstrap$Bootstrap$Modal$shown
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 43:
					if (!msg.b.$) {
						var pollingInterval = msg.a;
						var status = msg.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a9: status}),
							function () {
								switch (status.$) {
									case 0:
										return $elm$core$Platform$Cmd$none;
									case 1:
										var res = status.a;
										return A2(
											$elm$core$Task$perform,
											$elm$core$Basics$always(
												A2($author$project$Pages$Users$MlnComnet$Chronological$AttemptPollingExcelProcessingStatus, 10 * 1000.0, res)),
											$elm$core$Process$sleep(pollingInterval));
									case 2:
										var res = status.a;
										return A3(
											$author$project$Api$getByte,
											A2($author$project$Endpoints$User$mlnComnetEndpoints.ki.kI, res.ih, _List_Nil),
											model.bu,
											$author$project$Pages$Users$MlnComnet$Chronological$GotDownloadExcel);
									default:
										return $elm$core$Platform$Cmd$none;
								}
							}());
					} else {
						break _v0$51;
					}
				case 44:
					var nextPollingInterval = msg.a;
					var res = msg.b;
					return _Utils_Tuple2(
						model,
						A4(
							$author$project$Api$get,
							A2($author$project$Endpoints$User$mlnComnetEndpoints.ki.kK, res.ih, _List_Nil),
							model.bu,
							$author$project$Pages$Users$MlnComnet$Chronological$excelProcessingStatusDecoder(res),
							$author$project$Pages$Users$MlnComnet$Chronological$GotExcelProcessingStatusPollingResult(nextPollingInterval)));
				case 22:
					var posix = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								en: $elm$core$Maybe$Just(posix)
							}),
						$elm$core$Platform$Cmd$none);
				case 18:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aK: !model.aK}),
						$elm$core$Platform$Cmd$none);
				case 19:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bp: !model.bp}),
						$elm$core$Platform$Cmd$none);
				case 20:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bq: !model.bq}),
						$elm$core$Platform$Cmd$none);
				case 21:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bo: !model.bo}),
						$elm$core$Platform$Cmd$none);
				case 23:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{al: !model.al}),
						$elm$core$Platform$Cmd$none);
				case 24:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aj: !model.aj}),
						$elm$core$Platform$Cmd$none);
				case 25:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{am: !model.am}),
						$elm$core$Platform$Cmd$none);
				case 26:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cu: !model.cu}),
						$elm$core$Platform$Cmd$none);
				case 29:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a3: $frandibar$elm_bootstrap$Bootstrap$Modal$shown, az: ''}),
						$elm$core$Platform$Cmd$none);
				case 32:
					var _v6 = model.aa;
					if (!_v6.$) {
						var selectedSnapshotId = _v6.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									az: A3(
										$krisajenkins$remotedata$RemoteData$unwrap,
										'',
										A2(
											$elm$core$Basics$composeR,
											$elm_community$list_extra$List$Extra$find(
												function (snapshot) {
													return _Utils_eq(
														$elm$core$String$fromInt(snapshot.fK),
														selectedSnapshotId);
												}),
											A2(
												$elm$core$Basics$composeR,
												$elm$core$Maybe$map(
													function ($) {
														return $.iM;
													}),
												$elm$core$Maybe$withDefault(''))),
										model.bt),
									bA: $frandibar$elm_bootstrap$Bootstrap$Modal$shown
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 28:
					var dropdownState = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dt: dropdownState}),
						$elm$core$Platform$Cmd$none);
				case 30:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a3: _Utils_eq(model.a3, $frandibar$elm_bootstrap$Bootstrap$Modal$shown) ? $frandibar$elm_bootstrap$Bootstrap$Modal$hidden : $frandibar$elm_bootstrap$Bootstrap$Modal$shown
							}),
						$elm$core$Platform$Cmd$none);
				case 31:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bA: _Utils_eq(model.bA, $frandibar$elm_bootstrap$Bootstrap$Modal$shown) ? $frandibar$elm_bootstrap$Bootstrap$Modal$hidden : $frandibar$elm_bootstrap$Bootstrap$Modal$shown
							}),
						$elm$core$Platform$Cmd$none);
				case 37:
					var snapshotId = msg.a;
					var _v7 = A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						$elm$core$Maybe$Nothing,
						$elm_community$list_extra$List$Extra$find(
							function (s) {
								return _Utils_eq(
									$elm$core$String$fromInt(s.fK),
									snapshotId);
							}),
						model.bt);
					if (!_v7.$) {
						var snapshot = _v7.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									r: A3(
										$inkuzmin$elm_multiselect$Multiselect$populateValues,
										model.r,
										A2(
											$elm$core$List$map,
											function (s) {
												return _Utils_Tuple2(
													$elm$core$String$fromInt(s.hs),
													s.jD);
											},
											A3(
												$krisajenkins$remotedata$RemoteData$unwrap,
												_List_Nil,
												function ($) {
													return $.ap;
												},
												model.as)),
										A2(
											$elm$core$List$map,
											function (s) {
												return _Utils_Tuple2(
													$elm$core$String$fromInt(s.hs),
													s.jD);
											},
											A2(
												$elm$core$List$filter,
												function (s) {
													return A2(
														$elm$core$List$member,
														$elm$core$String$fromInt(s.hs),
														snapshot.mj);
												},
												A3(
													$krisajenkins$remotedata$RemoteData$unwrap,
													_List_Nil,
													function ($) {
														return $.ap;
													},
													model.as)))),
									he: A2($elm$core$Maybe$withDefault, -1, snapshot.ed),
									hh: snapshot.lS,
									fH: A2($elm$core$Maybe$withDefault, -1, snapshot.ho),
									aa: $elm$core$Maybe$Just(snapshotId)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aa: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					}
				case 33:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a3: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden}),
						A5(
							$author$project$Api$post,
							$author$project$Endpoints$User$mlnComnetEndpoints.l8.kr(_List_Nil),
							model.bu,
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'pageName',
										$elm$json$Json$Encode$string($author$project$Pages$Users$MlnComnet$Chronological$pageName)),
										_Utils_Tuple2(
										'bigCategoryId',
										$elm$json$Json$Encode$int(model.he)),
										_Utils_Tuple2(
										'smallCategoryId',
										$elm$json$Json$Encode$int(model.fH)),
										_Utils_Tuple2(
										'productIds',
										A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.hh)),
										_Utils_Tuple2(
										'storeIds',
										A2(
											$elm$json$Json$Encode$list,
											$elm$json$Json$Encode$string,
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))),
										_Utils_Tuple2(
										'selectorSnapshotName',
										$elm$json$Json$Encode$string(model.az))
									])),
							$author$project$Utils$Decoders$selectorSnapshotDecoder,
							$author$project$Pages$Users$MlnComnet$Chronological$GotCreateSelectorSnapshotResult));
				case 34:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bA: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden}),
						function () {
							var _v8 = model.aa;
							if (!_v8.$) {
								var selectedSnapshotId = _v8.a;
								return A5(
									$author$project$Api$post,
									$author$project$Endpoints$User$mlnComnetEndpoints.l8.l2(_List_Nil),
									model.bu,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'selectorSnapshotId',
												$elm$json$Json$Encode$string(selectedSnapshotId)),
												_Utils_Tuple2(
												'pageName',
												$elm$json$Json$Encode$string($author$project$Pages$Users$MlnComnet$Chronological$pageName)),
												_Utils_Tuple2(
												'bigCategoryId',
												$elm$json$Json$Encode$int(model.he)),
												_Utils_Tuple2(
												'smallCategoryId',
												$elm$json$Json$Encode$int(model.fH)),
												_Utils_Tuple2(
												'productIds',
												A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, model.hh)),
												_Utils_Tuple2(
												'storeIds',
												A2(
													$elm$json$Json$Encode$list,
													$elm$json$Json$Encode$string,
													A2(
														$elm$core$List$map,
														$elm$core$Tuple$first,
														$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))),
												_Utils_Tuple2(
												'selectorSnapshotName',
												$elm$json$Json$Encode$string(model.az))
											])),
									$author$project$Utils$Decoders$selectorSnapshotDecoder,
									$author$project$Pages$Users$MlnComnet$Chronological$GotCreateSelectorSnapshotResult);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				case 35:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: $elm$core$Maybe$Nothing}),
						function () {
							var _v9 = model.aa;
							if (!_v9.$) {
								var selectedSnapshotId = _v9.a;
								return A5(
									$author$project$Api$post,
									$author$project$Endpoints$User$mlnComnetEndpoints.l8.hc(_List_Nil),
									model.bu,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'selectorSnapshotId',
												$elm$json$Json$Encode$string(selectedSnapshotId))
											])),
									$author$project$Utils$Decoders$selectorSnapshotDecoder,
									$author$project$Pages$Users$MlnComnet$Chronological$GotCreateSelectorSnapshotResult);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				case 3:
					return _Utils_Tuple2(
						model,
						A4(
							$author$project$Api$get,
							$author$project$Endpoints$User$mlnComnetEndpoints.l8.il(
								_List_fromArray(
									[
										A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'pageName', $author$project$Pages$Users$MlnComnet$Chronological$pageName)
									])),
							model.bu,
							A2($elm$json$Json$Decode$field, 'selectorSnapshots', $author$project$Utils$Decoders$selectorSnapshotsDecoder),
							$author$project$Pages$Users$MlnComnet$Chronological$GotGetSelectorSnapshot));
				case 36:
					var name = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{az: name}),
						$elm$core$Platform$Cmd$none);
				case 38:
					if (model.ag) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ag: false}),
							$elm$core$Platform$Cmd$none);
					} else {
						var _v10 = model.fY;
						if (!_v10.$) {
							var today = _v10.a;
							var prevThursdayParts = A2(
								$justinmimbs$time_extra$Time$Extra$posixToParts,
								$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
								A3(
									$justinmimbs$time_extra$Time$Extra$floor,
									7,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									today));
							var prevMondayOrNowParts = function (mondayParts) {
								var adjustedMondayParts = _Utils_update(
									mondayParts,
									{k_: 10, ll: 0, ln: 0, l7: 0});
								return (_Utils_cmp(
									$elm$time$Time$posixToMillis(
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											adjustedMondayParts)),
									$elm$time$Time$posixToMillis(today)) < 0) ? A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									today) : adjustedMondayParts;
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A3(
										$justinmimbs$time_extra$Time$Extra$floor,
										4,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										today)));
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ag: true,
										du: A3($author$project$Utils$Helpers$formatDate, prevThursdayParts.mL, prevThursdayParts.lp, prevThursdayParts.gp),
										dv: A3($author$project$Utils$Helpers$formatDate, prevMondayOrNowParts.mL, prevMondayOrNowParts.lp, prevMondayOrNowParts.gp),
										hi: A2($author$project$Utils$Helpers$formatTime, 10, 0),
										hj: function (parts) {
											return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
										}(
											A2($author$project$Utils$Helpers$floorTimeByInterval, prevMondayOrNowParts, model.Z))
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 39:
					var timeStr = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: A2($elm$core$Set$member, timeStr, model.O) ? A2($elm$core$Set$remove, timeStr, model.O) : A2($elm$core$Set$insert, timeStr, model.O)
							}),
						$elm$core$Platform$Cmd$none);
				case 40:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: $elm$core$Set$fromList($author$project$Pages$Users$MlnComnet$Chronological$filteredTimes)
							}),
						$elm$core$Platform$Cmd$none);
				case 41:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: $elm$core$Set$fromList(
									A2(
										$elm$core$List$filter,
										function (str) {
											return A2($elm$core$String$endsWith, '00', str);
										},
										$author$project$Pages$Users$MlnComnet$Chronological$filteredTimes))
							}),
						$elm$core$Platform$Cmd$none);
				case 42:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{O: $elm$core$Set$empty}),
						$elm$core$Platform$Cmd$none);
				case 45:
					if (!msg.a.$) {
						var bytes = msg.a.a;
						return _Utils_Tuple2(
							model,
							A3($elm$file$File$Download$bytes, model.du + ('〜' + (model.hj + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
					} else {
						break _v0$51;
					}
				case 46:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aJ: _Utils_eq(model.aJ, $frandibar$elm_bootstrap$Bootstrap$Modal$hidden) ? $frandibar$elm_bootstrap$Bootstrap$Modal$shown : $frandibar$elm_bootstrap$Bootstrap$Modal$hidden
							}),
						$elm$core$Platform$Cmd$none);
				case 47:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aJ: $frandibar$elm_bootstrap$Bootstrap$Modal$hidden}),
						$elm$core$Platform$Cmd$none);
				case 48:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dq: _Utils_eq(model.dq, $frandibar$elm_bootstrap$Bootstrap$Modal$hidden) ? $frandibar$elm_bootstrap$Bootstrap$Modal$shown : $frandibar$elm_bootstrap$Bootstrap$Modal$hidden
							}),
						$elm$core$Platform$Cmd$none);
				case 51:
					var text = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{g3: text}),
						$elm$core$Platform$Cmd$none);
				default:
					break _v0$51;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Pages$Users$MlnComnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$MlnComnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$MlnComnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$MlnComnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$MlnComnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = A2(
		$elm$core$List$sortWith,
		$author$project$Utils$Helpers$compareProductBySortOption(model.js),
		model.ja);
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜', i: 0},
						{iF: '税込', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後(税込)', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$MlnComnet$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$MlnComnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$MlnComnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$MlnComnet$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$MlnComnet$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$MlnComnet$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$MlnComnet$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$MlnComnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '形名', '項目', '閾値'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$MlnComnet$Hour$storeGroupId2Ord = function (storeGroupId) {
	switch (storeGroupId) {
		case 1:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 2:
			return 4;
		case 5:
			return 5;
		default:
			return 6;
	}
};
var $author$project$Pages$Users$MlnComnet$Hour$sortStoreGroups = function (storeGroups) {
	return A2(
		$elm$core$List$sortBy,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Pages$Users$MlnComnet$Hour$storeGroupId2Ord,
			function ($) {
				return $.mh;
			}),
		storeGroups);
};
var $author$project$Pages$Users$MlnComnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$MlnComnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$MlnComnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$MlnComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$MlnComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$MlnComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$MlnComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 12:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: $author$project$Pages$Users$MlnComnet$Hour$sortStoreGroups(getPriceResponse.U),
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 15:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 5:
				var option = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{js: option}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 11:
				var storeHeader = A2($author$project$Pages$Users$MlnComnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$MlnComnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$MlnComnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$MlnComnet$HourYamada$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$MlnComnet$HourYamada$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$MlnComnet$HourYamada$pProductPriceRows = function (model) {
	var storesTarget = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return !$elm$core$List$isEmpty(storeGroup.ap);
			},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return _Utils_update(
						storeGroup,
						{
							ap: A2(
								$elm$core$List$filter,
								function (store) {
									return store.hs === 1;
								},
								storeGroup.ap)
						});
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return storeGroup.mh === 1;
					},
					model.U))));
	var storesAll = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return !$elm$core$List$isEmpty(storeGroup.ap);
			},
			model.U));
	var products = A2(
		$elm$core$List$sortWith,
		$author$project$Utils$Helpers$compareProductBySortOption(model.js),
		model.ja);
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							storesAll)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜', i: 0},
						{iF: '税込', i: 1},
						{iF: 'P/下取り', i: 2},
						{iF: 'P後(税込)', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$MlnComnet$HourYamada$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$MlnComnet$HourYamada$ProductPriceNoData;
							}
						},
						storesTarget));
			};
		},
		products);
};
var $author$project$Pages$Users$MlnComnet$HourYamada$pStoreHeader = function (storeGroups) {
	return _Utils_ap(
		A2(
			$elm$core$List$map,
			function (str) {
				return {mh: -1, jD: str};
			},
			_List_fromArray(
				['#', '形名', '項目', '閾値'])),
		A2(
			$elm$core$List$concatMap,
			function (storeGroup) {
				return A2(
					$elm$core$List$map,
					function (store) {
						return {mh: storeGroup.mh, jD: store.h6};
					},
					storeGroup.ap);
			},
			A2(
				$elm$core$List$filter,
				function (storeGroup) {
					return !$elm$core$List$isEmpty(storeGroup.ap);
				},
				A2(
					$elm$core$List$map,
					function (storeGroup) {
						return _Utils_update(
							storeGroup,
							{
								ap: A2(
									$elm$core$List$filter,
									function (store) {
										return store.hs === 1;
									},
									storeGroup.ap)
							});
					},
					A2(
						$elm$core$List$filter,
						function (storeGroup) {
							return storeGroup.mh === 1;
						},
						storeGroups)))));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$storeGroupId2Ord = function (storeGroupId) {
	switch (storeGroupId) {
		case 1:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 2:
			return 4;
		case 5:
			return 5;
		default:
			return 6;
	}
};
var $author$project$Pages$Users$MlnComnet$HourYamada$sortStoreGroups = function (storeGroups) {
	return A2(
		$elm$core$List$sortBy,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Pages$Users$MlnComnet$HourYamada$storeGroupId2Ord,
			function ($) {
				return $.mh;
			}),
		storeGroups);
};
var $author$project$Pages$Users$MlnComnet$HourYamada$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$MlnComnet$HourYamada$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$MlnComnet$HourYamada$getPriceResponseDecoder,
								$author$project$Pages$Users$MlnComnet$HourYamada$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$MlnComnet$HourYamada$getPriceResponseDecoder,
								$author$project$Pages$Users$MlnComnet$HourYamada$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: $author$project$Pages$Users$MlnComnet$HourYamada$sortStoreGroups(getPriceResponse.U),
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 4:
				var option = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{js: option}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = $author$project$Pages$Users$MlnComnet$HourYamada$pStoreHeader(model.U);
				var priceRows = $author$project$Pages$Users$MlnComnet$HourYamada$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$MlnComnet$HourYamada$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$MailSetting$Nop = {$: 3};
var $author$project$Pages$Users$MlnComnet$MailSetting$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eK: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var q = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bn: q}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var newMail = msg.a;
				return A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					function (initResponse) {
						var newMails = A3(
							$elm_community$list_extra$List$Extra$updateIf,
							function (mail) {
								return _Utils_eq(mail.eY, newMail.eY);
							},
							$elm$core$Basics$always(newMail),
							initResponse.bh);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									eK: $krisajenkins$remotedata$RemoteData$Success(
										_Utils_update(
											initResponse,
											{bh: newMails}))
								}),
							A4(
								$author$project$Api$postWhatever,
								$author$project$Endpoints$User$mlnComnetEndpoints.le.ad,
								model.bu,
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'mail_id',
											$elm$json$Json$Encode$int(newMail.eY)),
											_Utils_Tuple2(
											'mail_address',
											$elm$json$Json$Encode$string(newMail.eX)),
											_Utils_Tuple2(
											'enabled',
											$elm$json$Json$Encode$bool(newMail.ev)),
											_Utils_Tuple2(
											'start_time',
											A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, newMail.fN)),
											_Utils_Tuple2(
											'end_time',
											A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, newMail.ew))
										])),
								$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$MailSetting$Nop)));
					},
					model.eK);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$MlnComnet$Yahoo$GotDirectURLs = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$GotSubmitResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$DirectURL = F6(
	function (productID, storeID, productName, storeName, url, enabled) {
		return {ev: enabled, cl: productID, lT: productName, cz: storeID, jD: storeName, dS: url};
	});
var $author$project$Pages$Users$MlnComnet$Yahoo$directURLsDecoder = $elm$json$Json$Decode$list(
	A7(
		$elm$json$Json$Decode$map6,
		$author$project$Pages$Users$MlnComnet$Yahoo$DirectURL,
		A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'store_name', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'direct_url',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$withDefault(''),
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))),
		A2($elm$json$Json$Decode$field, 'enabled', $elm$json$Json$Decode$bool)));
var $author$project$Pages$Users$MlnComnet$Yahoo$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							eK: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: $krisajenkins$remotedata$RemoteData$fromResult(result)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var productID = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hg: productID}),
					A4(
						$author$project$Api$get,
						$author$project$Endpoints$User$mlnComnetEndpoints.mK.kR(
							_List_fromArray(
								[
									A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'product_id', productID)
								])),
						model.bu,
						$author$project$Pages$Users$MlnComnet$Yahoo$directURLsDecoder,
						$author$project$Pages$Users$MlnComnet$Yahoo$GotDirectURLs));
			case 3:
				var productID = msg.a;
				var storeID = msg.b;
				var url = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: A2(
								$krisajenkins$remotedata$RemoteData$map,
								A2(
									$elm_community$list_extra$List$Extra$updateIf,
									function (data) {
										return _Utils_eq(data.cl, productID) && _Utils_eq(data.cz, storeID);
									},
									function (data) {
										return _Utils_update(
											data,
											{dS: url});
									}),
								model.aG)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var productID = msg.a;
				var storeID = msg.b;
				var enabled = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aG: A2(
								$krisajenkins$remotedata$RemoteData$map,
								A2(
									$elm_community$list_extra$List$Extra$updateIf,
									function (data) {
										return _Utils_eq(data.cl, productID) && _Utils_eq(data.cz, storeID);
									},
									function (data) {
										return _Utils_update(
											data,
											{ev: enabled});
									}),
								model.aG)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var directURL = msg.a;
				return _Utils_Tuple2(
					model,
					A4(
						$author$project$Api$postWhatever,
						$author$project$Endpoints$User$mlnComnetEndpoints.mK.ad(_List_Nil),
						model.bu,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(directURL.cl)),
									_Utils_Tuple2(
									'store_id',
									$elm$json$Json$Encode$int(directURL.cz)),
									_Utils_Tuple2(
									'direct_url',
									$elm$json$Json$Encode$string(directURL.dS)),
									_Utils_Tuple2(
									'enabled',
									$elm$json$Json$Encode$bool(directURL.ev))
								])),
						$author$project$Pages$Users$MlnComnet$Yahoo$GotSubmitResponse));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$ShaComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$ShaComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					var filteredProducts = A2(
						$elm$core$List$filter,
						function (product) {
							return product.lT !== 'blank';
						},
						initResponse.ja);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: filteredProducts}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ShaComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$ShaComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ShaComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$ShaComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$defaultEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$ShaComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$ShaComnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$defaultEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$ShaComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$ShaComnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$Day$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$ShaComnet$Day$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$Day$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$Day$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$ShaComnet$Day$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$ShaComnet$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4},
						{iF: '工事費', i: 5}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$ShaComnet$Day$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4},
						{iF: '-', i: 5}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$ShaComnet$Day$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$ShaComnet$Day$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$ShaComnet$Day$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$ShaComnet$Day$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v7.a && (!_v7.b.$)) {
										var yodobashiPrice = _v7.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v5 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v5.a && (!_v5.b.$)) {
										var yodobashiPrice = _v5.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									},
										{
										iF: function () {
											var _v3 = price.km;
											if (_v3.$ === 1) {
												return '0';
											} else {
												var constructionCost = _v3.a;
												return $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(constructionCost));
											}
										}(),
										i: 5
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$ShaComnet$Day$ProductPriceData(
									{gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$ShaComnet$Day$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$ShaComnet$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Day$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$ShaComnet$Day$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$ShaComnet$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 18:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:00'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$shaComnetEndpoints.gp.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'stores',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 14:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var makerId = msg.a;
				var newSelectedMaker = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				var filteredProducts = A2(
					$elm$core$List$filter,
					function (p) {
						return _Utils_eq(
							newSelectedMaker,
							$elm$core$Maybe$Just(-1)) || _Utils_eq(
							$elm$core$Maybe$Just(p.iJ),
							newSelectedMaker);
					},
					model.cs);
				var newMultiselectProducts = A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					model.D,
					A2(
						$elm$core$List$map,
						function (p) {
							return _Utils_Tuple2(p.i8, p.lT);
						},
						filteredProducts),
					_List_Nil);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: newMultiselectProducts, dw: newSelectedMaker}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$ShaComnet$Day$OnChangeProduct, subCmd));
			case 7:
				var sub = msg.a;
				var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.r);
				var subModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$ShaComnet$Day$OnChangeStore, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					var filteredProducts = A2(
						$elm$core$List$filter,
						function (product) {
							return product.lT !== 'blank';
						},
						initResponse.ja);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								r: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.r,
									A2(
										$elm$core$List$map,
										function (s) {
											return _Utils_Tuple2(
												$elm$core$String$fromInt(s.hs),
												s.jD);
										},
										initResponse.dT),
									_List_Nil),
								cs: filteredProducts,
								hl: initResponse.dT,
								jy: initResponse.jy,
								dT: initResponse.dT
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 17:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 13:
				var storeHeader = A2($author$project$Pages$Users$ShaComnet$Day$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$ShaComnet$Day$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$ShaComnet$Day$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$ShaComnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$ShaComnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$ShaComnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				if (product.lT === 'blank') {
					return _List_Nil;
				} else {
					var maybeYodobashiPrice = A2(
						$elm$core$Dict$get,
						_Utils_Tuple2(product.i8, 7),
						model.ck);
					var lowestPrice = A3(
						$elm$core$List$foldl,
						function (price) {
							return function (m) {
								return A2($elm$core$Basics$min, m, price);
							};
						},
						A2($elm$core$Basics$pow, 2, 30),
						A2(
							$elm$core$List$map,
							function (maybePrice) {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
								} else {
									return A2($elm$core$Basics$pow, 2, 30);
								}
							},
							A2(
								$elm$core$List$map,
								function (store) {
									return A2(
										$elm$core$Dict$get,
										_Utils_Tuple2(product.i8, store.hs),
										model.ck);
								},
								stores)));
					var itemList = _List_fromArray(
						[
							{iF: '税抜き', i: 0},
							{iF: '税込み', i: 1},
							{iF: 'P/クーポン', i: 2},
							{iF: 'P後', i: 3},
							{iF: '取得時刻', i: 4},
							{iF: '工事費', i: 5}
						]);
					var filterItemList = function (list) {
						return A2(
							$elm$core$List$map,
							function (i) {
								return A2(
									$elm$core$Maybe$withDefault,
									{iF: '', i: -1},
									A2($elm_community$list_extra$List$Extra$getAt, i, list));
							},
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									'DisplayAll',
									_List_fromArray(
										[0, 1, 2, 3, 4, 5])),
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (dispToValue) {
										return _Utils_eq(dispToValue.a, model.cq);
									},
									$author$project$Pages$Users$ShaComnet$Hour$displayStyleToValues)).b);
					};
					var filteredItemList = filterItemList(itemList);
					var _v0 = function () {
						var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
						if (_v1.a && (!_v1.b.$)) {
							var yodobashiPrice = _v1.b.a;
							return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
						} else {
							return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
						}
					}();
					var basePrice = _v0.ka;
					var basePriceIncludeTax = _v0.eb;
					var basePoint = _v0.j9;
					var basePriceWithoutPoint = _v0.bM;
					var basePriceList = _List_fromArray(
						[
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePrice)),
							i: 0
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceIncludeTax)),
							i: 1
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePoint)),
							i: 2
						},
							{
							iF: $author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(basePriceWithoutPoint)),
							i: 3
						},
							{iF: '-', i: 4},
							{iF: '-', i: 5}
						]);
					var filteredBasePriceList = filterItemList(basePriceList);
					return _Utils_ap(
						_List_fromArray(
							[
								$author$project$Pages$Users$ShaComnet$Hour$ProductPriceHeader(
								_List_fromArray(
									[
										{
										iF: $elm$core$String$fromInt(ind + 1),
										i: 0
									}
									])),
								$author$project$Pages$Users$ShaComnet$Hour$ProductPriceHeader(
								_List_fromArray(
									[
										{iF: product.lT, i: 0}
									])),
								$author$project$Pages$Users$ShaComnet$Hour$ProductPriceHeader(filteredItemList),
								$author$project$Pages$Users$ShaComnet$Hour$ProductPriceHeader(filteredBasePriceList)
							]),
						A2(
							$elm$core$List$map,
							function (store) {
								var maybePrice = A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
								var isLowest = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return _Utils_eq(price.aW, lowestPrice);
									} else {
										return false;
									}
								}();
								var isLowestButHigherThanBase = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v7.a && (!_v7.b.$)) {
											var yodobashiPrice = _v7.b.a;
											return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
										} else {
											return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										}
									} else {
										return false;
									}
								}();
								var isLower = function () {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										var _v5 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
										if (_v5.a && (!_v5.b.$)) {
											var yodobashiPrice = _v5.b.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										return false;
									}
								}();
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var priceList = _List_fromArray(
										[
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i3)),
											i: 0
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.i4)),
											i: 1
										},
											{
											iF: function (point) {
												return _Utils_ap(
													point,
													(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
											}(
												$elm$core$String$fromInt(price.iZ)),
											i: 2
										},
											{
											iF: $author$project$Utils$Helpers$toCommmaSplited(
												$elm$core$String$fromInt(price.aW)),
											i: 3
										},
											{
											iF: A2(
												$elm$core$Maybe$withDefault,
												'',
												$elm$core$List$head(
													A2(
														$elm$core$Maybe$withDefault,
														_List_Nil,
														$elm$core$List$tail(
															A2($elm$core$String$split, ' ', price.kw))))),
											i: 4
										},
											{
											iF: function () {
												var _v3 = price.km;
												if (_v3.$ === 1) {
													return '0';
												} else {
													var constructionCost = _v3.a;
													return $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(constructionCost));
												}
											}(),
											i: 5
										}
										]);
									var filteredPriceList = filterItemList(priceList);
									return $author$project$Pages$Users$ShaComnet$Hour$ProductPriceData(
										{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
								} else {
									return $author$project$Pages$Users$ShaComnet$Hour$ProductPriceNoData;
								}
							},
							stores));
				}
			};
		},
		products);
};
var $author$project$Pages$Users$ShaComnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4, 5])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$ShaComnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$ShaComnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$ShaComnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$ShaComnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$ShaComnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$ShaComnet$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$ShaComnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$ShaComnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$ShaComnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$ShaComnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$ShaComnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$ShaComnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$ShaComnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$ShaComnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$ShaComnet$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$ShaComnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return (product.lT === 'blank') ? _List_Nil : A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$ShaComnet$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$ShaComnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$ShaComnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$ShaComnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$ShaComnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$ShaComnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					var filteredProducts = A2(
						$elm$core$List$filter,
						function (product) {
							return product.lT !== 'blank';
						},
						initResponse.ja);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: filteredProducts,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$ShaComnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$ShaComnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$ShaComnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return {h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$ShaComnet$WeeklyProduct$Price)))))))))));
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$ShaComnet$WeeklyProduct$priceDecoder)));
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$WeeklyProduct$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$ShaComnet$WeeklyProduct$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filteredProductName = (product.lT === 'blank') ? '__blank__' : product.lT;
				var filterItemList = function (list) {
					return (product.lT === 'blank') ? _List_Nil : A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: filteredProductName, i: 0}
								])),
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$ShaComnet$WeeklyProduct$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$shaComnetEndpoints.jY.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$shaComnetEndpoints.jY.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$WeeklyProduct$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 12:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$ShaComnet$WeeklyProduct$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$ShaComnet$WeeklyProduct$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$ShaComnet$WeeklyProduct$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$ShaComnet$Wide$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$ShaComnet$Wide$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$ShaComnet$Wide$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$ShaComnet$Wide$Price))))))))))));
var $author$project$Pages$Users$ShaComnet$Wide$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$ShaComnet$Wide$priceDecoder)));
var $author$project$Pages$Users$ShaComnet$Wide$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$ShaComnet$Wide$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$ShaComnet$Wide$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$ShaComnet$Wide$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$Lower = 1;
var $author$project$Pages$Users$ShaComnet$Wide$Lowest = 0;
var $author$project$Pages$Users$ShaComnet$Wide$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$ShaComnet$Wide$Normal = 3;
var $author$project$Pages$Users$ShaComnet$Wide$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$ShaComnet$Wide$Unchanged = {$: 2};
var $author$project$Pages$Users$ShaComnet$Wide$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$ShaComnet$Wide$saiyasuList = _List_fromArray(
	[10, 14, 97]);
var $author$project$Pages$Users$ShaComnet$Wide$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return (product.lT === 'blank') ? _List_Nil : A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$ShaComnet$Wide$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$ShaComnet$Wide$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$ShaComnet$Wide$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$ShaComnet$Wide$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$ShaComnet$Wide$Unchanged);
											} else {
												return $author$project$Pages$Users$ShaComnet$Wide$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return (!A2($elm$core$List$member, price.hs, $author$project$Pages$Users$ShaComnet$Wide$saiyasuList)) && _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$ShaComnet$Wide$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$ShaComnet$Wide$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$ShaComnet$Wide$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Wide$succDate = function (dateString) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(dateString);
	if (!_v0.$) {
		var date = _v0.a;
		return $justinmimbs$date$Date$toIsoString(
			A3($justinmimbs$date$Date$add, 3, 1, date));
	} else {
		return dateString;
	}
};
var $author$project$Pages$Users$ShaComnet$Wide$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										var top_ind = function () {
											var _v6 = A2($elm_community$list_extra$List$Extra$getAt, 0, indices);
											if (!_v6.$) {
												var x = _v6.a;
												return x;
											} else {
												return 0;
											}
										}();
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		var res = function () {
																			var _v2 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																				},
																				data);
																			if (!_v2.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v2.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var res = function () {
																			var _v3 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return _Utils_eq(item.i, ind);
																				},
																				data.g2);
																			if (!_v3.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v3.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v5 = data.cO;
																		switch (_v5) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$ShaComnet$Wide$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 14:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date, dv: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:59'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$ShaComnet$Wide$getPriceResponseDecoder,
								$author$project$Pages$Users$ShaComnet$Wide$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				var bigCategoryId = msg.a;
				var selectedBigCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				var sendable = ((!_Utils_eq(
					model.dw,
					$elm$core$Maybe$Just(-1))) && (!_Utils_eq(
					selectedBigCategoryId,
					$elm$core$Maybe$Just(-1)))) ? true : false;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategoryId);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategoryId,
							ct: sendable
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				var sendable = ((!_Utils_eq(
					model.dw,
					$elm$core$Maybe$Just(-1))) && (!_Utils_eq(
					model.he,
					$elm$core$Maybe$Just(-1)))) ? true : false;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId,
							ct: sendable
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				var sendable = ((!_Utils_eq(
					selectedMakerId,
					$elm$core$Maybe$Just(-1))) && (!_Utils_eq(
					model.he,
					$elm$core$Maybe$Just(-1)))) ? true : false;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.cs)),
								_List_Nil),
							dw: selectedMakerId,
							ct: sendable
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$ShaComnet$Wide$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: $author$project$Pages$Users$ShaComnet$Wide$succDate(model.du),
								dH: '00:00',
								fS: '00:00',
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 9:
				var storeHeader = A2($author$project$Pages$Users$ShaComnet$Wide$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$ShaComnet$Wide$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$ShaComnet$Wide$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$TescomComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$TescomComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$TescomComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$TescomComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$TescomComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$TescomComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mz: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$TescomComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 9:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'trigger_flag',
								$elm$json$Json$Encode$bool(product.mz)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$TescomComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$TescomComnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'trigger_flag',
									$elm$json$Json$Encode$bool(product.mz)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$TescomComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$TescomComnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TescomComnet$Excel$GetExcelRequestSent = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A3(
								$author$project$Api$getByte,
								$author$project$Endpoints$User$tescomComnetEndpoints.ex.dA(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(60)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$TescomComnet$Excel$GetExcelRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 7:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$TescomComnet$Excel$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				if (!msg.a.$) {
					var bytes = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ar: $elm$core$Maybe$Nothing, c5: false}),
						A3($elm$file$File$Download$bytes, model.du + ('〜' + (model.hj + '.xlsx')), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', bytes));
				} else {
					var e = msg.a.a;
					var errMsg = function () {
						if ((e.$ === 3) && (e.a === 404)) {
							return '該当するデータが見つかりませんでした。検索条件を変更して再度お試しください。';
						} else {
							return $author$project$Utils$Helpers$errToStr(e);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(errMsg),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Users$TescomComnet$Hour$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$TescomComnet$Hour$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TescomComnet$Hour$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TescomComnet$Hour$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$TescomComnet$Hour$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$TescomComnet$Hour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.i4 : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$TescomComnet$Hour$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$TescomComnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$TescomComnet$Hour$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$TescomComnet$Hour$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$TescomComnet$Hour$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.i4, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.i4) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.eb) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple3(product.mM, product.mz, maybeYodobashiPrice);
									if (_v4.b) {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.i4, yodobashiPrice.i4) < 0;
										} else {
											return _Utils_cmp(price.i4, product.eb) < 0;
										}
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$TescomComnet$Hour$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$TescomComnet$Hour$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$TescomComnet$Hour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TescomComnet$Hour$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$TescomComnet$Hour$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$TescomComnet$Hour$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$TescomComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$TescomComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$TescomComnet$Hour$getPriceResponseDecoder,
								$author$project$Pages$Users$TescomComnet$Hour$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$TescomComnet$Hour$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$TescomComnet$Hour$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$TescomComnet$Hour$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Test$Fifteen$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Test$Fifteen$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Test$Fifteen$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$Test$Fifteen$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Test$Fifteen$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Test$Fifteen$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Test$Fifteen$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Test$Fifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Test$Fifteen$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Test$Fifteen$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Test$Fifteen$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple3(product.mM, product.mz, maybeYodobashiPrice);
									if (_v4.b) {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
										} else {
											return _Utils_cmp(price.aW, product.bM) < 0;
										}
									} else {
										if (_v4.a && (!_v4.c.$)) {
											var yodobashiPrice = _v4.c.a;
											return _Utils_cmp(price.i4, yodobashiPrice.i4) < 0;
										} else {
											return _Utils_cmp(price.i4, product.eb) < 0;
										}
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : ((price.hs === 1) ? '' : (' / ' + $elm$core$String$fromInt(price.h3))));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Test$Fifteen$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Test$Fifteen$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Test$Fifteen$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Test$Fifteen$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Test$Fifteen$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Test$Fifteen$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Test$Fifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Test$Fifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Test$Fifteen$getPriceResponseDecoder,
								$author$project$Pages$Users$Test$Fifteen$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Test$Fifteen$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Test$Fifteen$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Test$Fifteen$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$moveTo = F3(
	function (src, dst, ls) {
		return (_Utils_cmp(src, dst) < 0) ? A3(
			$author$project$Pages$Users$To24tclComnet$ChangeProduct$moveTo,
			src + 1,
			dst,
			A3($elm_community$list_extra$List$Extra$swapAt, src, src + 1, ls)) : ((_Utils_cmp(src, dst) > 0) ? A3(
			$author$project$Pages$Users$To24tclComnet$ChangeProduct$moveTo,
			src - 1,
			dst,
			A3($elm_community$list_extra$List$Extra$swapAt, src, src - 1, ls)) : ls);
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$To24tclComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 8:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bU: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 9:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 10:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var products = A3(
					$elm_community$list_extra$List$Extra$updateAt,
					destInd,
					function (product) {
						return _Utils_update(
							product,
							{c3: true});
					},
					A2(
						$elm$core$List$map,
						function (product) {
							return _Utils_update(
								product,
								{c3: false});
						},
						model.ja));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cS: $elm$core$Maybe$Just(destInd),
							ja: products
						}),
					$author$project$DragPorts$dragover(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bU: $elm$core$Maybe$Nothing,
							ja: function (ls) {
								var _v1 = _Utils_Tuple2(model.bU, model.cS);
								if ((!_v1.a.$) && (!_v1.b.$)) {
									var from = _v1.a.a;
									var to = _v1.b.a;
									return A3($author$project$Pages$Users$To24tclComnet$ChangeProduct$moveTo, from, to, ls);
								} else {
									return ls;
								}
							}(
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_update(
											p,
											{c3: false});
									},
									model.ja))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(product.ka)),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$to24tcmComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$to24tcmComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$To24tclComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tclComnet$Day$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$To24tclComnet$Day$DictPriceRecord = F4(
	function (productId, storeId, hour, data_day) {
		return {cQ: data_day, k_: hour, i8: productId, hs: storeId};
	});
var $author$project$Pages$Users$To24tclComnet$Day$gen_dictpricekey = function (record) {
	return _Utils_ap(
		record.i8,
		_Utils_ap(
			$elm$core$String$fromInt(record.hs),
			_Utils_ap(
				$elm$core$String$fromInt(record.k_),
				record.cQ)));
};
var $author$project$Pages$Users$To24tclComnet$Day$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Pages$Users$To24tclComnet$Day$gen_dictpricekey,
				A5(
					$elm$json$Json$Decode$map4,
					$author$project$Pages$Users$To24tclComnet$Day$DictPriceRecord,
					A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'hour', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'data_day', $elm$json$Json$Decode$string)))),
		$elm$json$Json$Decode$list($author$project$Utils$Decoders$priceDecoder)));
var $author$project$Pages$Users$To24tclComnet$Day$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tclComnet$Day$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$To24tclComnet$Day$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$To24tclComnet$Day$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$To24tclComnet$Day$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$To24tclComnet$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				function (ind) {
					return function (product) {
						return A2(
							$elm$core$List$map,
							function (data_day) {
								return A2(
									$elm$core$List$map,
									function (hour) {
										var maybeYodobashiPrice = function () {
											var record = {cQ: data_day, k_: hour, i8: product.i8, hs: 7};
											return A2(
												$elm$core$Dict$get,
												$author$project$Pages$Users$To24tclComnet$Day$gen_dictpricekey(record),
												model.ck);
										}();
										var lowestPrice = A3(
											$elm$core$List$foldl,
											function (price) {
												return function (m) {
													return A2($elm$core$Basics$min, m, price);
												};
											},
											A2($elm$core$Basics$pow, 2, 30),
											A2(
												$elm$core$List$map,
												function (maybePrice) {
													if (!maybePrice.$) {
														var price = maybePrice.a;
														return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
													} else {
														return A2($elm$core$Basics$pow, 2, 30);
													}
												},
												A2(
													$elm$core$List$map,
													function (store) {
														var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
														return A2(
															$elm$core$Dict$get,
															$author$project$Pages$Users$To24tclComnet$Day$gen_dictpricekey(record),
															model.ck);
													},
													stores)));
										var itemList = _List_fromArray(
											[
												{iF: '税抜き', i: 0},
												{iF: '税込み', i: 1},
												{iF: 'P/クーポン', i: 2},
												{iF: 'P後', i: 3},
												{iF: '取得時刻', i: 4}
											]);
										var filterItemList = function (list) {
											return A2(
												$elm$core$List$map,
												function (i) {
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, i, list));
												},
												A2(
													$elm$core$Maybe$withDefault,
													_Utils_Tuple2(
														'DisplayAll',
														_List_fromArray(
															[0, 1, 2, 3, 4])),
													A2(
														$elm_community$list_extra$List$Extra$find,
														function (dispToValue) {
															return _Utils_eq(dispToValue.a, model.cq);
														},
														$author$project$Pages$Users$To24tclComnet$Day$displayStyleToValues)).b);
										};
										var filteredItemList = filterItemList(itemList);
										var _v0 = function () {
											var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
											if (_v1.a && (!_v1.b.$)) {
												var yodobashiPrice = _v1.b.a;
												return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
											} else {
												return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
											}
										}();
										var basePrice = _v0.ka;
										var basePriceIncludeTax = _v0.eb;
										var basePoint = _v0.j9;
										var basePriceWithoutPoint = _v0.bM;
										var basePriceList = _List_fromArray(
											[
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePrice)),
												i: 0
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceIncludeTax)),
												i: 1
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePoint)),
												i: 2
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceWithoutPoint)),
												i: 3
											},
												{
												iF: $elm$core$String$fromInt(hour) + '時',
												i: 4
											}
											]);
										var filteredBasePriceList = filterItemList(basePriceList);
										return _Utils_ap(
											((hour === 9) && (data_day === 'today')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '本日', i: 0}
														]))
												]) : (((hour === 9) && (data_day === 'yesterday')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{
															iF: $elm$core$String$fromInt(ind + 1),
															i: 0
														}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: product.lT, i: 0}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '昨日', i: 0}
														]))
												]) : _List_fromArray(
												[
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														]))
												])),
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(filteredItemList),
														$author$project$Pages$Users$To24tclComnet$Day$ProductPriceHeader(filteredBasePriceList)
													]),
												A2(
													$elm$core$List$map,
													function (store) {
														var maybePrice = function () {
															var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
															return A2(
																$elm$core$Dict$get,
																$author$project$Pages$Users$To24tclComnet$Day$gen_dictpricekey(record),
																model.ck);
														}();
														var isLowest = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																return _Utils_eq(price.aW, lowestPrice);
															} else {
																return false;
															}
														}();
														var isLowestButHigherThanBase = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v6.a && (!_v6.b.$)) {
																	var yodobashiPrice = _v6.b.a;
																	return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
																} else {
																	return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
																}
															} else {
																return false;
															}
														}();
														var isLower = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v4.a && (!_v4.b.$)) {
																	var yodobashiPrice = _v4.b.a;
																	return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
																} else {
																	return _Utils_cmp(price.aW, product.bM) < 0;
																}
															} else {
																return false;
															}
														}();
														if (!maybePrice.$) {
															var price = maybePrice.a;
															var priceList = _List_fromArray(
																[
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i3)),
																	i: 0
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i4)),
																	i: 1
																},
																	{
																	iF: function (point) {
																		return _Utils_ap(
																			point,
																			(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
																	}(
																		$elm$core$String$fromInt(price.iZ)),
																	i: 2
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.aW)),
																	i: 3
																},
																	{
																	iF: A2(
																		$elm$core$Maybe$withDefault,
																		'',
																		$elm$core$List$head(
																			A2(
																				$elm$core$Maybe$withDefault,
																				_List_Nil,
																				$elm$core$List$tail(
																					A2($elm$core$String$split, ' ', price.kw))))),
																	i: 4
																}
																]);
															var filteredPriceList = filterItemList(priceList);
															return $author$project$Pages$Users$To24tclComnet$Day$ProductPriceData(
																{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
														} else {
															return $author$project$Pages$Users$To24tclComnet$Day$ProductPriceNoData;
														}
													},
													stores)));
									},
									_List_fromArray(
										[9, 15]));
							},
							_List_fromArray(
								['yesterday', 'today']));
					};
				},
				products)));
};
var $author$project$Pages$Users$To24tclComnet$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '日にち', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tclComnet$Day$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$To24tclComnet$Day$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tclComnet$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$To24tclComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tclComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'16:00:00'
									]));
							var to3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'10:00:00'
									]));
							var to2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'16:00:00'
									]));
							var to1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'10:00:00'
									]));
							var from_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'15:00:00'
									]));
							var from3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'9:00:00'
									]));
							var from2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'15:00:00'
									]));
							var from1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'9:00:00'
									]));
							var except_id = '(4904530109010, 4904530109959, 4904530108952)';
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$to24tcmComnetEndpoints.gp.im(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from1', from1),
											A2($elm$url$Url$Builder$string, 'to1', to1),
											A2($elm$url$Url$Builder$string, 'from2', from2),
											A2($elm$url$Url$Builder$string, 'to2', to2),
											A2($elm$url$Url$Builder$string, 'from3', from3),
											A2($elm$url$Url$Builder$string, 'to3', to3),
											A2($elm$url$Url$Builder$string, 'from4', from4),
											A2($elm$url$Url$Builder$string, 'to4', to4),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'exception', except_id)
										])),
								model.bu,
								$author$project$Pages$Users$To24tclComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tclComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$To24tclComnet$Day$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$To24tclComnet$Day$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tclComnet$Day$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tclComnet$TimeSeq$csvStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			_List_fromArray(
				['型番', '']),
			$elm$core$List$concat(
				A2(
					$elm$core$List$concatMap,
					function (storeGroup) {
						return A2(
							$elm$core$List$map,
							function (store) {
								return _List_fromArray(
									[store.h6, '価格変動']);
							},
							storeGroup.ap);
					},
					A2(
						$elm$core$List$filter,
						function (storeGroup) {
							return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
						},
						storeGroups))));
	});
var $author$project$Pages$Users$To24tclComnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v10 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v10.a.$) && (!_v10.b.$)) {
			var dateTimePartsFrom = _v10.a.a;
			var dateTimePartsTo = _v10.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/下取り', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Utils$Helpers$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$Common$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v8 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v8.a.$) && (!_v8.b.$)) {
												var currentPrice = _v8.a.a;
												var prevPrice = _v8.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$Common$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$Common$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$Common$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$Common$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v6.a && (!_v6.b.$)) {
													var yodobashiPrice = _v6.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v4 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v4.a) {
												return 0;
											} else {
												if (_v4.b) {
													return 2;
												} else {
													if (_v4.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$Common$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$Common$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				storeHeader,
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return _Utils_ap(
													A2($elm$core$List$take, 2, lst),
													A2($elm$core$List$drop, 4, lst));
											},
											A2(
												$elm$core$List$map,
												function (lst) {
													return (!(!rowInd)) ? A2($elm$core$List$cons, '', lst) : A2(
														$elm$core$Maybe$withDefault,
														_List_Nil,
														$elm$core$List$tail(lst));
												},
												A2(
													$elm$core$List$map,
													function (ind) {
														return $elm$core$List$concat(
															A2(
																$elm$core$List$map,
																function (cell) {
																	switch (cell.$) {
																		case 0:
																			var _v1 = cell.a;
																			var data = _v1.b;
																			return $elm$core$List$singleton(
																				A2(
																					$elm$core$Maybe$withDefault,
																					{iF: '', i: -1},
																					A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF);
																		case 1:
																			return _List_fromArray(
																				['', '']);
																		default:
																			var data = cell.a;
																			return function (label) {
																				var annotation = (!ind) ? _Utils_ap(
																					function () {
																						var _v2 = data.cO;
																						switch (_v2) {
																							case 0:
																								return '●';
																							case 1:
																								return '※';
																							default:
																								return '';
																						}
																					}(),
																					function () {
																						var _v3 = data.fl;
																						switch (_v3.$) {
																							case 0:
																								return '▲';
																							case 1:
																								return '▼';
																							default:
																								return '';
																						}
																					}()) : '';
																				return _List_fromArray(
																					[label, annotation]);
																			}(
																				A2(
																					$elm$core$Maybe$withDefault,
																					{iF: '', i: -1},
																					A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF);
																	}
																},
																row));
													},
													indices)));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tclComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											(!_Utils_eq(
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D),
												_List_Nil)) ? A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) : _List_Nil)
										])),
								model.bu,
								$author$project$Pages$Users$Common$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 12:
				var storeHeader = A2($author$project$Pages$Users$To24tclComnet$TimeSeq$csvStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$To24tclComnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tclComnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return A2($author$project$Pages$Users$Common$TimeSeq$update, msg, model);
		}
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$getIntervalSum = function (products) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function (p) {
				return (60 / p.$9) | 0;
			},
			products));
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							$9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 9:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var index = msg.a;
				if ($author$project$Pages$Users$To24tcmComnet$ChangeProduct$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
					if (!maybeProduct.$) {
						var product = maybeProduct.a;
						var body = $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(product.ka)),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(index + 1)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{J: true}),
							A5($author$project$Api$post, $author$project$Endpoints$User$to24tcmComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateProductRequestSent));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 8:
				if ($author$project$Pages$Users$To24tcmComnet$ChangeProduct$getIntervalSum(model.ja) > 120) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var body = A2(
						$elm$json$Json$Encode$list,
						function (product) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'product_id',
										$elm$json$Json$Encode$string(product.i8)),
										_Utils_Tuple2(
										'base_price',
										$elm$json$Json$Encode$int(product.ka)),
										_Utils_Tuple2(
										'base_point',
										$elm$json$Json$Encode$int(product.j9)),
										_Utils_Tuple2(
										'product_order',
										$elm$json$Json$Encode$int(product.lU)),
										_Utils_Tuple2(
										'yodobashi_base_price_flag',
										$elm$json$Json$Encode$bool(product.mM)),
										_Utils_Tuple2(
										'get_interval',
										$elm$json$Json$Encode$int(product.$9))
									]));
						},
						model.ja);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$to24tcmComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$To24tcmComnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateProductRequestSent));
				}
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tcmComnet$Csv$CsvRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$CsvResponse = function (csv) {
	return {ks: csv};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$csvResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$To24tcmComnet$Csv$CsvResponse,
	A2($elm$json$Json$Decode$field, 'csv', $elm$json$Json$Decode$string));
var $author$project$Pages$Users$To24tcmComnet$Csv$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							de: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dh: function (parts) {
								return A3($author$project$Utils$Helpers$formatDate, parts.mL, parts.lp, parts.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										11,
										-60,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											date)))),
							du: function (parts) {
								return A3($author$project$Utils$Helpers$formatDate, parts.mL, parts.lp, parts.gp);
							}(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										11,
										-3,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											date)))),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: '00:00',
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var val = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 4:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var fromDateTime = _v1.a.a;
							var toDateTime = _v1.b.a;
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$to24tcmComnetEndpoints.ks.cX(
									_List_fromArray(
										[
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'from',
											$author$project$Utils$Helpers$formatDateTime(fromDateTime)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'to',
											$author$project$Utils$Helpers$formatDateTime(toDateTime)),
											A3($Herteby$url_builder_plus$UrlBuilderPlus$list, $Herteby$url_builder_plus$UrlBuilderPlus$string, 'products', model.hh),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$int, 'interval', model.Z)
										])),
								$author$project$Pages$Users$To24tcmComnet$Csv$toSession(model),
								$author$project$Pages$Users$To24tcmComnet$Csv$csvResponseDecoder,
								$author$project$Pages$Users$To24tcmComnet$Csv$CsvRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 0:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var response = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{c5: false}),
						A3(
							$elm$file$File$Download$bytes,
							model.du + (' ' + (model.hi + ('_' + (model.dv + (' ' + (model.hj + '.csv')))))),
							'text/csv',
							$elm$bytes$Bytes$Encode$encode(
								$elm$bytes$Bytes$Encode$sequence(
									_List_fromArray(
										[
											$elm$bytes$Bytes$Encode$unsignedInt8(239),
											$elm$bytes$Bytes$Encode$unsignedInt8(187),
											$elm$bytes$Bytes$Encode$unsignedInt8(191),
											$elm$bytes$Bytes$Encode$string(response.ks)
										])))));
				} else {
					var errMsg = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just('Csvのダウンロードに失敗しました')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.cM) : A2($elm$core$Set$remove, storeGroupId, model.cM);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cM: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			default:
				var selectedProducts = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hh: selectedProducts}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tcmComnet$Day$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$To24tcmComnet$Day$DictPriceRecord = F4(
	function (productId, storeId, hour, data_day) {
		return {cQ: data_day, k_: hour, i8: productId, hs: storeId};
	});
var $author$project$Pages$Users$To24tcmComnet$Day$gen_dictpricekey = function (record) {
	return _Utils_ap(
		record.i8,
		_Utils_ap(
			$elm$core$String$fromInt(record.hs),
			_Utils_ap(
				$elm$core$String$fromInt(record.k_),
				record.cQ)));
};
var $author$project$Pages$Users$To24tcmComnet$Day$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Pages$Users$To24tcmComnet$Day$gen_dictpricekey,
				A5(
					$elm$json$Json$Decode$map4,
					$author$project$Pages$Users$To24tcmComnet$Day$DictPriceRecord,
					A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'hour', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'data_day', $elm$json$Json$Decode$string)))),
		$elm$json$Json$Decode$list($author$project$Utils$Decoders$priceDecoder)));
var $author$project$Pages$Users$To24tcmComnet$Day$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmComnet$Day$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$To24tcmComnet$Day$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$To24tcmComnet$Day$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$To24tcmComnet$Day$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$To24tcmComnet$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				function (ind) {
					return function (product) {
						return A2(
							$elm$core$List$map,
							function (data_day) {
								return A2(
									$elm$core$List$map,
									function (hour) {
										var maybeYodobashiPrice = function () {
											var record = {cQ: data_day, k_: hour, i8: product.i8, hs: 7};
											return A2(
												$elm$core$Dict$get,
												$author$project$Pages$Users$To24tcmComnet$Day$gen_dictpricekey(record),
												model.ck);
										}();
										var lowestPrice = A3(
											$elm$core$List$foldl,
											function (price) {
												return function (m) {
													return A2($elm$core$Basics$min, m, price);
												};
											},
											A2($elm$core$Basics$pow, 2, 30),
											A2(
												$elm$core$List$map,
												function (maybePrice) {
													if (!maybePrice.$) {
														var price = maybePrice.a;
														return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
													} else {
														return A2($elm$core$Basics$pow, 2, 30);
													}
												},
												A2(
													$elm$core$List$map,
													function (store) {
														var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
														return A2(
															$elm$core$Dict$get,
															$author$project$Pages$Users$To24tcmComnet$Day$gen_dictpricekey(record),
															model.ck);
													},
													stores)));
										var itemList = _List_fromArray(
											[
												{iF: '税抜き', i: 0},
												{iF: '税込み', i: 1},
												{iF: 'P/クーポン', i: 2},
												{iF: 'P後', i: 3},
												{iF: '取得時刻', i: 4}
											]);
										var filterItemList = function (list) {
											return A2(
												$elm$core$List$map,
												function (i) {
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, i, list));
												},
												A2(
													$elm$core$Maybe$withDefault,
													_Utils_Tuple2(
														'DisplayAll',
														_List_fromArray(
															[0, 1, 2, 3, 4])),
													A2(
														$elm_community$list_extra$List$Extra$find,
														function (dispToValue) {
															return _Utils_eq(dispToValue.a, model.cq);
														},
														$author$project$Pages$Users$To24tcmComnet$Day$displayStyleToValues)).b);
										};
										var filteredItemList = filterItemList(itemList);
										var _v0 = function () {
											var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
											if (_v1.a && (!_v1.b.$)) {
												var yodobashiPrice = _v1.b.a;
												return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
											} else {
												return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
											}
										}();
										var basePrice = _v0.ka;
										var basePriceIncludeTax = _v0.eb;
										var basePoint = _v0.j9;
										var basePriceWithoutPoint = _v0.bM;
										var basePriceList = _List_fromArray(
											[
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePrice)),
												i: 0
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceIncludeTax)),
												i: 1
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePoint)),
												i: 2
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceWithoutPoint)),
												i: 3
											},
												{
												iF: $elm$core$String$fromInt(hour) + '時',
												i: 4
											}
											]);
										var filteredBasePriceList = filterItemList(basePriceList);
										return _Utils_ap(
											((hour === 9) && (data_day === 'today')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '本日', i: 0}
														]))
												]) : (((hour === 9) && (data_day === 'yesterday')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{
															iF: $elm$core$String$fromInt(ind + 1),
															i: 0
														}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: product.lT, i: 0}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '昨日', i: 0}
														]))
												]) : _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														]))
												])),
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(filteredItemList),
														$author$project$Pages$Users$To24tcmComnet$Day$ProductPriceHeader(filteredBasePriceList)
													]),
												A2(
													$elm$core$List$map,
													function (store) {
														var maybePrice = function () {
															var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
															return A2(
																$elm$core$Dict$get,
																$author$project$Pages$Users$To24tcmComnet$Day$gen_dictpricekey(record),
																model.ck);
														}();
														var isLowest = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																return _Utils_eq(price.aW, lowestPrice);
															} else {
																return false;
															}
														}();
														var isLowestButHigherThanBase = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v6.a && (!_v6.b.$)) {
																	var yodobashiPrice = _v6.b.a;
																	return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
																} else {
																	return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
																}
															} else {
																return false;
															}
														}();
														var isLower = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v4.a && (!_v4.b.$)) {
																	var yodobashiPrice = _v4.b.a;
																	return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
																} else {
																	return _Utils_cmp(price.aW, product.bM) < 0;
																}
															} else {
																return false;
															}
														}();
														if (!maybePrice.$) {
															var price = maybePrice.a;
															var priceList = _List_fromArray(
																[
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i3)),
																	i: 0
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i4)),
																	i: 1
																},
																	{
																	iF: function (point) {
																		return _Utils_ap(
																			point,
																			(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
																	}(
																		$elm$core$String$fromInt(price.iZ)),
																	i: 2
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.aW)),
																	i: 3
																},
																	{
																	iF: A2(
																		$elm$core$Maybe$withDefault,
																		'',
																		$elm$core$List$head(
																			A2(
																				$elm$core$Maybe$withDefault,
																				_List_Nil,
																				$elm$core$List$tail(
																					A2($elm$core$String$split, ' ', price.kw))))),
																	i: 4
																}
																]);
															var filteredPriceList = filterItemList(priceList);
															return $author$project$Pages$Users$To24tcmComnet$Day$ProductPriceData(
																{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
														} else {
															return $author$project$Pages$Users$To24tcmComnet$Day$ProductPriceNoData;
														}
													},
													stores)));
									},
									_List_fromArray(
										[9, 15]));
							},
							_List_fromArray(
								['yesterday', 'today']));
					};
				},
				products)));
};
var $author$project$Pages$Users$To24tcmComnet$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '日にち', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$To24tcmComnet$Day$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tcmComnet$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'16:00:00'
									]));
							var to3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'10:00:00'
									]));
							var to2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'16:00:00'
									]));
							var to1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'10:00:00'
									]));
							var from_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'15:00:00'
									]));
							var from3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'9:00:00'
									]));
							var from2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'15:00:00'
									]));
							var from1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'9:00:00'
									]));
							var except_id = '(4904530112881, 4904530108839, 4904530108495, 4904530112317, 4904530112348, 4904530112881)';
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$to24tcmComnetEndpoints.gp.im(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from1', from1),
											A2($elm$url$Url$Builder$string, 'to1', to1),
											A2($elm$url$Url$Builder$string, 'from2', from2),
											A2($elm$url$Url$Builder$string, 'to2', to2),
											A2($elm$url$Url$Builder$string, 'from3', from3),
											A2($elm$url$Url$Builder$string, 'to3', to3),
											A2($elm$url$Url$Builder$string, 'from4', from4),
											A2($elm$url$Url$Builder$string, 'to4', to4),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'exception', except_id)
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmComnet$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmComnet$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$To24tcmComnet$Day$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$To24tcmComnet$Day$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tcmComnet$Day$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tcmComnet$Product$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$To24tcmComnet$Product$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmComnet$Product$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Utils$Decoders$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$To24tcmComnet$Product$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$To24tcmComnet$Product$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$To24tcmComnet$Product$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$To24tcmComnet$Product$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
					if (_v1.a && (!_v1.b.$)) {
						var yodobashiPrice = _v1.b.a;
						return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
					} else {
						return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
					}
				}();
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$To24tcmComnet$Product$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$To24tcmComnet$Product$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$To24tcmComnet$Product$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$To24tcmComnet$Product$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v6.a && (!_v6.b.$)) {
										var yodobashiPrice = _v6.b.a;
										return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
									} else {
										return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v4.a && (!_v4.b.$)) {
										var yodobashiPrice = _v4.b.a;
										return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
									} else {
										return _Utils_cmp(price.aW, product.bM) < 0;
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$To24tcmComnet$Product$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$To24tcmComnet$Product$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$To24tcmComnet$Product$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$To24tcmComnet$Product$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tcmComnet$Product$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmComnet$Product$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmComnet$Product$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmComnet$Product$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmComnet$Product$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$To24tcmComnet$Product$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$To24tcmComnet$Product$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tcmComnet$Product$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$csvStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			_List_fromArray(
				['型番', '']),
			$elm$core$List$concat(
				A2(
					$elm$core$List$concatMap,
					function (storeGroup) {
						return A2(
							$elm$core$List$map,
							function (store) {
								return _List_fromArray(
									[store.h6, '価格変動']);
							},
							storeGroup.ap);
					},
					A2(
						$elm$core$List$filter,
						function (storeGroup) {
							return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
						},
						storeGroups))));
	});
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				storeHeader,
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return _Utils_ap(
													A2($elm$core$List$take, 2, lst),
													A2($elm$core$List$drop, 4, lst));
											},
											A2(
												$elm$core$List$map,
												function (lst) {
													return (!(!rowInd)) ? A2($elm$core$List$cons, '', lst) : A2(
														$elm$core$Maybe$withDefault,
														_List_Nil,
														$elm$core$List$tail(lst));
												},
												A2(
													$elm$core$List$map,
													function (ind) {
														return $elm$core$List$concat(
															A2(
																$elm$core$List$map,
																function (cell) {
																	switch (cell.$) {
																		case 0:
																			var _v1 = cell.a;
																			var data = _v1.b;
																			return $elm$core$List$singleton(
																				A2(
																					$elm$core$Maybe$withDefault,
																					{iF: '', i: -1},
																					A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF);
																		case 1:
																			return _List_fromArray(
																				['', '']);
																		default:
																			var data = cell.a;
																			return function (label) {
																				var annotation = (!ind) ? _Utils_ap(
																					function () {
																						var _v2 = data.cO;
																						switch (_v2) {
																							case 0:
																								return '●';
																							case 1:
																								return '※';
																							default:
																								return '';
																						}
																					}(),
																					function () {
																						var _v3 = data.fl;
																						switch (_v3.$) {
																							case 0:
																								return '▲';
																							case 1:
																								return '▼';
																							default:
																								return '';
																						}
																					}()) : '';
																				return _List_fromArray(
																					[label, annotation]);
																			}(
																				A2(
																					$elm$core$Maybe$withDefault,
																					{iF: '', i: -1},
																					A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF);
																	}
																},
																row));
													},
													indices)));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											(!_Utils_eq(
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D),
												_List_Nil)) ? A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) : _List_Nil)
										])),
								model.bu,
								$author$project$Pages$Users$Common$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$Common$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 12:
				var storeHeader = A2($author$project$Pages$Users$To24tcmComnet$TimeSeq$csvStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tcmComnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return A2($author$project$Pages$Users$Common$TimeSeq$update, msg, model);
		}
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$DictPriceRecord = F4(
	function (productId, storeId, hour, data_day) {
		return {cQ: data_day, k_: hour, i8: productId, hs: storeId};
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$gen_dictpricekey = function (record) {
	return _Utils_ap(
		record.i8,
		_Utils_ap(
			$elm$core$String$fromInt(record.hs),
			_Utils_ap(
				$elm$core$String$fromInt(record.k_),
				record.cQ)));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Pages$Users$To24tcmKitchen$Day$gen_dictpricekey,
				A5(
					$elm$json$Json$Decode$map4,
					$author$project$Pages$Users$To24tcmKitchen$Day$DictPriceRecord,
					A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'hour', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'data_day', $elm$json$Json$Decode$string)))),
		$elm$json$Json$Decode$list($author$project$Utils$Decoders$priceDecoder)));
var $author$project$Pages$Users$To24tcmKitchen$Day$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$To24tcmKitchen$Day$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$To24tcmKitchen$Day$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$To24tcmKitchen$Day$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$To24tcmKitchen$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				function (ind) {
					return function (product) {
						return A2(
							$elm$core$List$map,
							function (data_day) {
								return A2(
									$elm$core$List$map,
									function (hour) {
										var maybeYodobashiPrice = function () {
											var record = {cQ: data_day, k_: hour, i8: product.i8, hs: 7};
											return A2(
												$elm$core$Dict$get,
												$author$project$Pages$Users$To24tcmKitchen$Day$gen_dictpricekey(record),
												model.ck);
										}();
										var lowestPrice = A3(
											$elm$core$List$foldl,
											function (price) {
												return function (m) {
													return A2($elm$core$Basics$min, m, price);
												};
											},
											A2($elm$core$Basics$pow, 2, 30),
											A2(
												$elm$core$List$map,
												function (maybePrice) {
													if (!maybePrice.$) {
														var price = maybePrice.a;
														return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
													} else {
														return A2($elm$core$Basics$pow, 2, 30);
													}
												},
												A2(
													$elm$core$List$map,
													function (store) {
														var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
														return A2(
															$elm$core$Dict$get,
															$author$project$Pages$Users$To24tcmKitchen$Day$gen_dictpricekey(record),
															model.ck);
													},
													stores)));
										var itemList = _List_fromArray(
											[
												{iF: '税抜き', i: 0},
												{iF: '税込み', i: 1},
												{iF: 'P/クーポン', i: 2},
												{iF: 'P後', i: 3},
												{iF: '取得時刻', i: 4}
											]);
										var filterItemList = function (list) {
											return A2(
												$elm$core$List$map,
												function (i) {
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, i, list));
												},
												A2(
													$elm$core$Maybe$withDefault,
													_Utils_Tuple2(
														'DisplayAll',
														_List_fromArray(
															[0, 1, 2, 3, 4])),
													A2(
														$elm_community$list_extra$List$Extra$find,
														function (dispToValue) {
															return _Utils_eq(dispToValue.a, model.cq);
														},
														$author$project$Pages$Users$To24tcmKitchen$Day$displayStyleToValues)).b);
										};
										var filteredItemList = filterItemList(itemList);
										var _v0 = function () {
											var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
											if (_v1.a && (!_v1.b.$)) {
												var yodobashiPrice = _v1.b.a;
												return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
											} else {
												return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
											}
										}();
										var basePrice = _v0.ka;
										var basePriceIncludeTax = _v0.eb;
										var basePoint = _v0.j9;
										var basePriceWithoutPoint = _v0.bM;
										var basePriceList = _List_fromArray(
											[
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePrice)),
												i: 0
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceIncludeTax)),
												i: 1
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePoint)),
												i: 2
											},
												{
												iF: $author$project$Utils$Helpers$toCommmaSplited(
													$elm$core$String$fromInt(basePriceWithoutPoint)),
												i: 3
											},
												{
												iF: $elm$core$String$fromInt(hour) + '時',
												i: 4
											}
											]);
										var filteredBasePriceList = filterItemList(basePriceList);
										return _Utils_ap(
											((hour === 9) && (data_day === 'today')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '本日', i: 0}
														]))
												]) : (((hour === 9) && (data_day === 'yesterday')) ? _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{
															iF: $elm$core$String$fromInt(ind + 1),
															i: 0
														}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: product.lT, i: 0}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '昨日', i: 0}
														]))
												]) : _List_fromArray(
												[
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														])),
													$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(
													_List_fromArray(
														[
															{iF: '', i: 0}
														]))
												])),
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(filteredItemList),
														$author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceHeader(filteredBasePriceList)
													]),
												A2(
													$elm$core$List$map,
													function (store) {
														var maybePrice = function () {
															var record = {cQ: data_day, k_: hour, i8: product.i8, hs: store.hs};
															return A2(
																$elm$core$Dict$get,
																$author$project$Pages$Users$To24tcmKitchen$Day$gen_dictpricekey(record),
																model.ck);
														}();
														var isLowest = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																return _Utils_eq(price.aW, lowestPrice);
															} else {
																return false;
															}
														}();
														var isLowestButHigherThanBase = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v6 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v6.a && (!_v6.b.$)) {
																	var yodobashiPrice = _v6.b.a;
																	return isLowest && (_Utils_cmp(lowestPrice, yodobashiPrice.aW) > -1);
																} else {
																	return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
																}
															} else {
																return false;
															}
														}();
														var isLower = function () {
															if (!maybePrice.$) {
																var price = maybePrice.a;
																var _v4 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
																if (_v4.a && (!_v4.b.$)) {
																	var yodobashiPrice = _v4.b.a;
																	return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
																} else {
																	return _Utils_cmp(price.aW, product.bM) < 0;
																}
															} else {
																return false;
															}
														}();
														if (!maybePrice.$) {
															var price = maybePrice.a;
															var priceList = _List_fromArray(
																[
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i3)),
																	i: 0
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.i4)),
																	i: 1
																},
																	{
																	iF: function (point) {
																		return _Utils_ap(
																			point,
																			(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
																	}(
																		$elm$core$String$fromInt(price.iZ)),
																	i: 2
																},
																	{
																	iF: $author$project$Utils$Helpers$toCommmaSplited(
																		$elm$core$String$fromInt(price.aW)),
																	i: 3
																},
																	{
																	iF: A2(
																		$elm$core$Maybe$withDefault,
																		'',
																		$elm$core$List$head(
																			A2(
																				$elm$core$Maybe$withDefault,
																				_List_Nil,
																				$elm$core$List$tail(
																					A2($elm$core$String$split, ' ', price.kw))))),
																	i: 4
																}
																]);
															var filteredPriceList = filterItemList(priceList);
															return $author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceData(
																{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
														} else {
															return $author$project$Pages$Users$To24tcmKitchen$Day$ProductPriceNoData;
														}
													},
													stores)));
									},
									_List_fromArray(
										[9, 15]));
							},
							_List_fromArray(
								['yesterday', 'today']));
					};
				},
				products)));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '日にち', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$To24tcmKitchen$Day$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmKitchen$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmKitchen$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var to4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'16:00:00'
									]));
							var to3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp_2),
										'10:00:00'
									]));
							var to2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'16:00:00'
									]));
							var to1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, to_tmp),
										'10:00:00'
									]));
							var from_tmp_2 = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from_tmp = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											0 * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							var from4 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'15:00:00'
									]));
							var from3 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp_2),
										'9:00:00'
									]));
							var from2 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'15:00:00'
									]));
							var from1 = $elm$core$String$concat(
								_List_fromArray(
									[
										A3($elm$core$String$slice, 0, 11, from_tmp),
										'9:00:00'
									]));
							var except_id = '';
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$to24tcmComnetEndpoints.gp.im(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from1', from1),
											A2($elm$url$Url$Builder$string, 'to1', to1),
											A2($elm$url$Url$Builder$string, 'from2', from2),
											A2($elm$url$Url$Builder$string, 'to2', to2),
											A2($elm$url$Url$Builder$string, 'from3', from3),
											A2($elm$url$Url$Builder$string, 'to3', to3),
											A2($elm$url$Url$Builder$string, 'from4', from4),
											A2($elm$url$Url$Builder$string, 'to4', to4),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A2($elm$url$Url$Builder$string, 'exception', except_id)
										])),
								model.bu,
								$author$project$Pages$Users$To24tcmKitchen$Day$getPriceResponseDecoder,
								$author$project$Pages$Users$To24tcmKitchen$Day$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$To24tcmKitchen$Day$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$To24tcmKitchen$Day$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$To24tcmKitchen$Day$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TsBat$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$TsBat$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$TsBat$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$TsBat$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$TsBat$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$TsBat$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$TsBat$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TsBat$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$TsBat$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TsBat$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$Lower = 1;
var $author$project$Pages$Users$TsBat$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$TsBat$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$TsBat$TimeSeq$Normal = 3;
var $author$project$Pages$Users$TsBat$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$TsBat$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$TsBat$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Users$TsBat$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nthList = $elm$core$Set$toList(
		$elm$core$Set$fromList(
			A2(
				$elm$core$List$map,
				function (_v12) {
					var nth = _v12.c;
					return nth;
				},
				$elm$core$Dict$keys(model.ck))));
	var nOfRows = $elm$core$List$length(nthList);
	var firstNth = A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$List$minimum(nthList));
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$TsBat$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							_Utils_eq(nth, firstNth) ? _List_fromArray(
								[
									$author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$TsBat$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = function () {
											var _v10 = A2(
												$elm$core$Dict$get,
												_Utils_Tuple3(product.i8, store.hs, nth - 1),
												model.ck);
											if (!_v10.$) {
												var prevPrice = _v10.a;
												return $elm$core$Maybe$Just(prevPrice);
											} else {
												return A2(
													$elm$core$Dict$get,
													_Utils_Tuple3(product.i8, store.hs, nth - 3),
													model.ck);
											}
										}();
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$TsBat$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$TsBat$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$TsBat$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$TsBat$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$TsBat$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$TsBat$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					nthList);
			};
		},
		products);
};
var $author$project$Pages$Users$TsBat$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TsBat$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$TsBat$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$TsBat$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$TsBat$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$TsBat$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$TsBat$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$TsBat$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$TsBat$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TsBat$Wide$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$TsBat$Wide$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$TsBat$Wide$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$TsBat$Wide$Price))))))))))));
var $author$project$Pages$Users$TsBat$Wide$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$TsBat$Wide$priceDecoder)));
var $author$project$Pages$Users$TsBat$Wide$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TsBat$Wide$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$TsBat$Wide$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TsBat$Wide$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$Lower = 1;
var $author$project$Pages$Users$TsBat$Wide$Lowest = 0;
var $author$project$Pages$Users$TsBat$Wide$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$TsBat$Wide$Normal = 3;
var $author$project$Pages$Users$TsBat$Wide$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$TsBat$Wide$Unchanged = {$: 2};
var $author$project$Pages$Users$TsBat$Wide$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$TsBat$Wide$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nthList = $elm$core$Set$toList(
		$elm$core$Set$fromList(
			A2(
				$elm$core$List$map,
				function (_v12) {
					var nth = _v12.c;
					return nth;
				},
				$elm$core$Dict$keys(model.ck))));
	var nOfRows = $elm$core$List$length(nthList);
	var firstNth = A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$List$minimum(nthList));
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$TsBat$Wide$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							_Utils_eq(nth, firstNth) ? _List_fromArray(
								[
									$author$project$Pages$Users$TsBat$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$TsBat$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$TsBat$Wide$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$TsBat$Wide$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$TsBat$Wide$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = function () {
											var _v10 = A2(
												$elm$core$Dict$get,
												_Utils_Tuple3(product.i8, store.hs, nth - 1),
												model.ck);
											if (!_v10.$) {
												var prevPrice = _v10.a;
												return $elm$core$Maybe$Just(prevPrice);
											} else {
												return A2(
													$elm$core$Dict$get,
													_Utils_Tuple3(product.i8, store.hs, nth - 3),
													model.ck);
											}
										}();
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$TsBat$Wide$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$TsBat$Wide$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$TsBat$Wide$Unchanged);
											} else {
												return $author$project$Pages$Users$TsBat$Wide$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$TsBat$Wide$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$TsBat$Wide$ProductPriceNoData;
										}
									},
									stores)));
					},
					nthList);
			};
		},
		products);
};
var $author$project$Pages$Users$TsBat$Wide$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TsBat$Wide$succDate = function (dateString) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(dateString);
	if (!_v0.$) {
		var date = _v0.a;
		return $justinmimbs$date$Date$toIsoString(
			A3($justinmimbs$date$Date$add, 3, 1, date));
	} else {
		return dateString;
	}
};
var $author$project$Pages$Users$TsBat$Wide$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										var top_ind = function () {
											var _v6 = A2($elm_community$list_extra$List$Extra$getAt, 0, indices);
											if (!_v6.$) {
												var x = _v6.a;
												return x;
											} else {
												return 0;
											}
										}();
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		var res = function () {
																			var _v2 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																				},
																				data);
																			if (!_v2.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v2.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var res = function () {
																			var _v3 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return _Utils_eq(item.i, ind);
																				},
																				data.g2);
																			if (!_v3.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v3.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v5 = data.cO;
																		switch (_v5) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$TsBat$Wide$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 14:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date, dv: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:00'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$TsBat$Wide$getPriceResponseDecoder,
								$author$project$Pages$Users$TsBat$Wide$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				var bigCategoryId = msg.a;
				var selectedBigCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategoryId);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.cs)),
								_List_Nil),
							dw: selectedMakerId
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$TsBat$Wide$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: $author$project$Pages$Users$TsBat$Wide$succDate(model.du),
								dH: '00:00',
								fS: '00:00',
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 9:
				var storeHeader = A2($author$project$Pages$Users$TsBat$Wide$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$TsBat$Wide$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$TsBat$Wide$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$TstComnet$TimeSeq$Price))))))))))));
var $author$project$Pages$Users$TstComnet$TimeSeq$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$TstComnet$TimeSeq$priceDecoder)));
var $author$project$Pages$Users$TstComnet$TimeSeq$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TstComnet$TimeSeq$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$TstComnet$TimeSeq$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TstComnet$TimeSeq$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$Lower = 1;
var $author$project$Pages$Users$TstComnet$TimeSeq$Lowest = 0;
var $author$project$Pages$Users$TstComnet$TimeSeq$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$TstComnet$TimeSeq$Normal = 3;
var $author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$TstComnet$TimeSeq$Unchanged = {$: 2};
var $author$project$Pages$Users$TstComnet$TimeSeq$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$TstComnet$TimeSeq$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$TstComnet$TimeSeq$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$TstComnet$TimeSeq$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$TstComnet$TimeSeq$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$TstComnet$TimeSeq$Unchanged);
											} else {
												return $author$project$Pages$Users$TstComnet$TimeSeq$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$TstComnet$TimeSeq$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$TstComnet$TimeSeq$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		return A2(
																			$elm$core$Maybe$withDefault,
																			{iF: '', i: -1},
																			A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v3 = data.cO;
																		switch (_v3) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 17:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hi: time}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dv: date}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hj: time}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, model.hi),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, model.hj));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$TstComnet$TimeSeq$getPriceResponseDecoder,
								$author$project$Pages$Users$TstComnet$TimeSeq$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 13:
				var bigCategoryId = msg.a;
				var selectedBigCategory = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategory,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategory);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategory
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$TstComnet$TimeSeq$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: model.dv,
								dH: model.hi,
								fS: model.hj,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 16:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 12:
				var storeHeader = A2($author$project$Pages$Users$TstComnet$TimeSeq$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$TstComnet$TimeSeq$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$TstComnet$TimeSeq$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$TstComnet$Wide$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$TstComnet$Wide$Price = function (productId) {
	return function (storeId) {
		return function (nth) {
			return function (dateTime) {
				return function (price) {
					return function (priceIncludeTax) {
						return function (point) {
							return function (priceWithoutPoint) {
								return function (coupon) {
									return function (url) {
										return function (constructionCost) {
											return {km: constructionCost, h3: coupon, kw: dateTime, lt: nth, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$TstComnet$Wide$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'construction_cost',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Pages$Users$TstComnet$Wide$Price))))))))))));
var $author$project$Pages$Users$TstComnet$Wide$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A4(
				$elm$json$Json$Decode$map3,
				F3(
					function (a, b, c) {
						return _Utils_Tuple3(a, b, c);
					}),
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'nth', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$TstComnet$Wide$priceDecoder)));
var $author$project$Pages$Users$TstComnet$Wide$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$TstComnet$Wide$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$TstComnet$Wide$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Utils$Decoders$productsDecoder));
var $author$project$Pages$Users$TstComnet$Wide$Decreased = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$Increased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$Lower = 1;
var $author$project$Pages$Users$TstComnet$Wide$Lowest = 0;
var $author$project$Pages$Users$TstComnet$Wide$LowestButHigherThanBasePrice = 2;
var $author$project$Pages$Users$TstComnet$Wide$Normal = 3;
var $author$project$Pages$Users$TstComnet$Wide$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$TstComnet$Wide$Unchanged = {$: 2};
var $author$project$Pages$Users$TstComnet$Wide$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4, 5])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$TstComnet$Wide$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	var nOfRows = function () {
		var _v11 = _Utils_Tuple2(
			A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH),
			A2($author$project$Utils$Helpers$dateTimeToParts, model.fR, model.fS));
		if ((!_v11.a.$) && (!_v11.b.$)) {
			var dateTimePartsFrom = _v11.a.a;
			var dateTimePartsTo = _v11.b.a;
			var diff = A4(
				$justinmimbs$time_extra$Time$Extra$diff,
				13,
				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsFrom),
				A2(
					$justinmimbs$time_extra$Time$Extra$partsToPosix,
					$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
					dateTimePartsTo));
			return (diff / model.Z) | 0;
		} else {
			return 1;
		}
	}();
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				return A2(
					$elm$core$List$map,
					function (nth) {
						var maybeYodobashiPrice = A2(
							$elm$core$Dict$get,
							_Utils_Tuple3(product.i8, 7, nth),
							model.ck);
						var lowestPrice = A3(
							$elm$core$List$foldl,
							function (price) {
								return function (m) {
									return A2($elm$core$Basics$min, m, price);
								};
							},
							A2($elm$core$Basics$pow, 2, 30),
							A2(
								$elm$core$List$map,
								function (maybePrice) {
									if (!maybePrice.$) {
										var price = maybePrice.a;
										return price.aW;
									} else {
										return A2($elm$core$Basics$pow, 2, 30);
									}
								},
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
									},
									stores)));
						var itemList = _List_fromArray(
							[
								{iF: '税抜き', i: 0},
								{iF: '税込み', i: 1},
								{iF: 'P/クーポン', i: 2},
								{iF: 'P後', i: 3},
								{iF: '取得時刻', i: 4},
								{iF: '工事費', i: 5}
							]);
						var filterItemList = function (list) {
							return A2(
								$elm$core$Tuple$pair,
								1,
								A2(
									$elm$core$List$map,
									function (i) {
										return A2(
											$elm$core$Maybe$withDefault,
											{iF: '', i: -1},
											A2($elm_community$list_extra$List$Extra$getAt, i, list));
									},
									A2(
										$elm$core$Maybe$withDefault,
										_Utils_Tuple2(
											'DisplayAll',
											_List_fromArray(
												[0, 1, 2, 3, 4, 5])),
										A2(
											$elm_community$list_extra$List$Extra$find,
											function (dispToValue) {
												return _Utils_eq(dispToValue.a, model.cq);
											},
											$author$project$Pages$Users$TstComnet$Wide$displayStyleToValues)).b));
						};
						var filteredItemList = filterItemList(itemList);
						var _v0 = function () {
							var _v1 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
							if (_v1.a && (!_v1.b.$)) {
								var yodobashiPrice = _v1.b.a;
								return {j9: yodobashiPrice.iZ, ka: yodobashiPrice.i3, eb: yodobashiPrice.i4, bM: yodobashiPrice.aW};
							} else {
								return {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
							}
						}();
						var basePrice = _v0.ka;
						var basePriceIncludeTax = _v0.eb;
						var basePoint = _v0.j9;
						var basePriceWithoutPoint = _v0.bM;
						var basePriceList = _List_fromArray(
							[
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePrice)),
								i: 0
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceIncludeTax)),
								i: 1
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePoint)),
								i: 2
							},
								{
								iF: $author$project$Utils$Helpers$toCommmaSplited(
									$elm$core$String$fromInt(basePriceWithoutPoint)),
								i: 3
							},
								{iF: '-', i: 4},
								{iF: '-', i: 5}
							]);
						var filteredBasePriceList = filterItemList(basePriceList);
						return _Utils_ap(
							(nth === 1) ? _List_fromArray(
								[
									$author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{
												iF: $elm$core$String$fromInt(ind + 1),
												i: -1
											}
											]))),
									$author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader(
									_Utils_Tuple2(
										nOfRows,
										_List_fromArray(
											[
												{iF: product.lT, i: -1}
											])))
								]) : _List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader(
										_Utils_Tuple2(
											1,
											_List_fromArray(
												[
													{
													iF: function () {
														var datetimeParts = A2($author$project$Utils$Helpers$dateTimeToParts, model.dG, model.dH);
														var date = function () {
															if (!datetimeParts.$) {
																var part = datetimeParts.a;
																return $author$project$Utils$Helpers$formatDateTimeWithoutYear(
																	A2(
																		$justinmimbs$time_extra$Time$Extra$posixToParts,
																		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																		A4(
																			$justinmimbs$time_extra$Time$Extra$add,
																			13,
																			(nth - 1) * model.Z,
																			$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																			A2(
																				$justinmimbs$time_extra$Time$Extra$partsToPosix,
																				$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
																				part))));
															} else {
																return '';
															}
														}();
														return date;
													}(),
													i: -1
												}
												]))),
										$author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader(filteredItemList),
										$author$project$Pages$Users$TstComnet$Wide$ProductPriceHeader(filteredBasePriceList)
									]),
								A2(
									$elm$core$List$map,
									function (store) {
										var maybePrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth),
											model.ck);
										var maybePrevPrice = A2(
											$elm$core$Dict$get,
											_Utils_Tuple3(product.i8, store.hs, nth - 1),
											model.ck);
										var priceChangingType = function () {
											var _v9 = _Utils_Tuple2(maybePrice, maybePrevPrice);
											if ((!_v9.a.$) && (!_v9.b.$)) {
												var currentPrice = _v9.a.a;
												var prevPrice = _v9.b.a;
												return (_Utils_cmp(currentPrice.aW, prevPrice.aW) > 0) ? $author$project$Pages$Users$TstComnet$Wide$Increased(currentPrice.aW - prevPrice.aW) : ((_Utils_cmp(currentPrice.aW, prevPrice.aW) < 0) ? $author$project$Pages$Users$TstComnet$Wide$Decreased(prevPrice.aW - currentPrice.aW) : $author$project$Pages$Users$TstComnet$Wide$Unchanged);
											} else {
												return $author$project$Pages$Users$TstComnet$Wide$Unchanged;
											}
										}();
										var isLowest = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												return _Utils_eq(price.aW, lowestPrice);
											} else {
												return false;
											}
										}();
										var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
										var isLower = function () {
											if (!maybePrice.$) {
												var price = maybePrice.a;
												var _v7 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
												if (_v7.a && (!_v7.b.$)) {
													var yodobashiPrice = _v7.b.a;
													return _Utils_cmp(price.aW, yodobashiPrice.aW) < 0;
												} else {
													return _Utils_cmp(price.aW, product.bM) < 0;
												}
											} else {
												return false;
											}
										}();
										var comparedPriceType = function () {
											var _v5 = _Utils_Tuple3(isLowest, isLowestButHigherThanBase, isLower);
											if (_v5.a) {
												return 0;
											} else {
												if (_v5.b) {
													return 2;
												} else {
													if (_v5.c) {
														return 1;
													} else {
														return 3;
													}
												}
											}
										}();
										if (!maybePrice.$) {
											var price = maybePrice.a;
											var priceList = _List_fromArray(
												[
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i3)),
													i: 0
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.i4)),
													i: 1
												},
													{
													iF: function (point) {
														return _Utils_ap(
															point,
															(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
													}(
														$elm$core$String$fromInt(price.iZ)),
													i: 2
												},
													{
													iF: $author$project$Utils$Helpers$toCommmaSplited(
														$elm$core$String$fromInt(price.aW)),
													i: 3
												},
													{
													iF: A2(
														$elm$core$Maybe$withDefault,
														'',
														$elm$core$List$head(
															A2(
																$elm$core$Maybe$withDefault,
																_List_Nil,
																$elm$core$List$tail(
																	A2($elm$core$String$split, ' ', price.kw))))),
													i: 4
												},
													{
													iF: function () {
														var _v4 = price.km;
														if (_v4.$ === 1) {
															return '0';
														} else {
															var constructionCost = _v4.a;
															return $author$project$Utils$Helpers$toCommmaSplited(
																$elm$core$String$fromInt(constructionCost));
														}
													}(),
													i: 5
												}
												]);
											var filteredPriceList = filterItemList(priceList).b;
											return $author$project$Pages$Users$TstComnet$Wide$ProductPriceData(
												{cO: comparedPriceType, fl: priceChangingType, g2: filteredPriceList, dS: price.dS});
										} else {
											return $author$project$Pages$Users$TstComnet$Wide$ProductPriceNoData;
										}
									},
									stores)));
					},
					A2($elm$core$List$range, 1, nOfRows));
			};
		},
		products);
};
var $author$project$Pages$Users$TstComnet$Wide$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['', '型番', '時刻', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TstComnet$Wide$succDate = function (dateString) {
	var _v0 = $justinmimbs$date$Date$fromIsoString(dateString);
	if (!_v0.$) {
		var date = _v0.a;
		return $justinmimbs$date$Date$toIsoString(
			A3($justinmimbs$date$Date$add, 3, 1, date));
	} else {
		return dateString;
	}
};
var $author$project$Pages$Users$TstComnet$Wide$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (priceRow) {
						return $elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								function (rowInd) {
									return function (row) {
										var indices = A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(
												'DisplayAll',
												_List_fromArray(
													[0, 1, 2, 3, 4, 5])),
											A2(
												$elm_community$list_extra$List$Extra$find,
												function (dispToValue) {
													return _Utils_eq(dispToValue.a, selectedDisplayStyle);
												},
												$author$project$Utils$Helpers$displayStyleToValues)).b;
										var top_ind = function () {
											var _v6 = A2($elm_community$list_extra$List$Extra$getAt, 0, indices);
											if (!_v6.$) {
												var x = _v6.a;
												return x;
											} else {
												return 0;
											}
										}();
										return A2(
											$elm$core$List$map,
											function (lst) {
												return (!(!rowInd)) ? _Utils_ap(
													_List_fromArray(
														['', '']),
													lst) : lst;
											},
											_Utils_ap(
												A2(
													$elm$core$List$map,
													function (ind) {
														return A2(
															$elm$core$List$map,
															function (cell) {
																switch (cell.$) {
																	case 0:
																		var _v1 = cell.a;
																		var data = _v1.b;
																		var res = function () {
																			var _v2 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return (_Utils_eq(ind, top_ind) && _Utils_eq(item.i, -1)) || _Utils_eq(item.i, ind);
																				},
																				data);
																			if (!_v2.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v2.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var res = function () {
																			var _v3 = A2(
																				$elm$core$List$filter,
																				function (item) {
																					return _Utils_eq(item.i, ind);
																				},
																				data.g2);
																			if (!_v3.b) {
																				return $elm$core$Maybe$Nothing;
																			} else {
																				var x = _v3.a;
																				return $elm$core$Maybe$Just(x.iF);
																			}
																		}();
																		return A2($elm$core$Maybe$withDefault, '', res);
																}
															},
															row);
													},
													indices),
												_List_fromArray(
													[
														A2(
														$elm$core$List$indexedMap,
														function (ind) {
															return function (cell) {
																var fixedInd = (!(!rowInd)) ? (ind + 2) : ind;
																switch (cell.$) {
																	case 0:
																		return (fixedInd === 3) ? '最低● 基準※' : '';
																	case 1:
																		return '';
																	default:
																		var data = cell.a;
																		var _v5 = data.cO;
																		switch (_v5) {
																			case 0:
																				return '●';
																			case 1:
																				return '※';
																			default:
																				return '';
																		}
																}
															};
														},
														row)
													])));
									};
								},
								priceRow));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$TstComnet$Wide$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 14:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							du: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							dv: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							hi: A2($author$project$Utils$Helpers$formatTime, 0, 0),
							hj: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{du: date, dv: date}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = _Utils_Tuple2(
							A2($author$project$Utils$Helpers$dateTimeToParts, model.du, '00:00'),
							A2($author$project$Utils$Helpers$dateTimeToParts, model.dv, '23:00'));
						if ((!_v1.a.$) && (!_v1.b.$)) {
							var dateTimePartsFrom = _v1.a.a;
							var dateTimePartsTo = _v1.b.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimePartsTo);
							var from = $author$project$Utils$Helpers$formatDateTime(dateTimePartsFrom);
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$timeSeqEndpoints.C(
									_List_fromArray(
										[
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'from', from),
											A2($Herteby$url_builder_plus$UrlBuilderPlus$string, 'to', to),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw))),
											A3(
											$Herteby$url_builder_plus$UrlBuilderPlus$list,
											$Herteby$url_builder_plus$UrlBuilderPlus$string,
											'products',
											A2(
												$elm$core$List$map,
												$elm$core$Tuple$first,
												$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)))
										])),
								model.bu,
								$author$project$Pages$Users$TstComnet$Wide$getPriceResponseDecoder,
								$author$project$Pages$Users$TstComnet$Wide$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 10:
				var bigCategoryId = msg.a;
				var selectedBigCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(bigCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedBigCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ed),
												selectedBigCategoryId);
										},
										model.cs)),
								_List_Nil),
							he: selectedBigCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var smallCategoryId = msg.a;
				var selectedSmallCategoryId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(smallCategoryId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A2(
										$elm$core$List$filter,
										function (p) {
											return _Utils_eq(
												selectedSmallCategoryId,
												$elm$core$Maybe$Just(-1)) || _Utils_eq(
												$elm$core$Maybe$Just(p.ho),
												selectedSmallCategoryId);
										},
										model.cs)),
								_List_Nil),
							fH: selectedSmallCategoryId
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var makerId = msg.a;
				var selectedMakerId = A2(
					$elm$core$Maybe$andThen,
					$elm$core$Maybe$Just,
					$elm$core$String$toInt(makerId));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3(
								$inkuzmin$elm_multiselect$Multiselect$populateValues,
								model.D,
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.i8, p.lT);
									},
									A4($author$project$Utils$Helpers$filterBySelector, selectedMakerId, model.he, model.fH, model.cs)),
								_List_Nil),
							dw: selectedMakerId
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var sub = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, sub, model.D);
				var subModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel, gb: false}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Users$TstComnet$Wide$OnChangeProduct, subCmd));
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								hR: initResponse.hR,
								ar: $elm$core$Maybe$Nothing,
								b2: false,
								ca: initResponse.ca,
								D: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.D,
									A2(
										$elm$core$List$map,
										function (p) {
											return _Utils_Tuple2(p.i8, p.lT);
										},
										initResponse.ja),
									_List_Nil),
								cs: initResponse.ja,
								jy: initResponse.jy
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								dG: model.du,
								fR: $author$project$Pages$Users$TstComnet$Wide$succDate(model.du),
								dH: '00:00',
								fS: '00:00',
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L,
								gb: false
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 13:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 9:
				var storeHeader = A2($author$project$Pages$Users$TstComnet$Wide$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$TstComnet$Wide$pProductPriceRows(model);
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$TstComnet$Wide$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, 'data.csv', 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$UpdateProductRequestSent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Utils$Constants$tax = 10;
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateOrder = F3(
	function (ind, model, newOrd) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = _Utils_update(
				product,
				{lU: newOrd});
			var maybeNewOrdProductInd = A2(
				$elm_community$list_extra$List$Extra$findIndex,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var maybeNewOrdProduct = A2(
				$elm_community$list_extra$List$Extra$find,
				function (p) {
					return _Utils_eq(p.lU, newOrd);
				},
				model.ja);
			var newProducts = function () {
				var _v1 = _Utils_Tuple2(maybeNewOrdProduct, maybeNewOrdProductInd);
				if ((!_v1.a.$) && (!_v1.b.$)) {
					var newOrdProduct = _v1.a.a;
					var newOrdProductInd = _v1.b.a;
					var newOrdProduct_ = _Utils_update(
						newOrdProduct,
						{lU: product.lU});
					return A3(
						$elm_community$list_extra$List$Extra$setAt,
						newOrdProductInd,
						newOrdProduct_,
						A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja));
				} else {
					return A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
				}
			}();
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProduct = F3(
	function (ind, model, f) {
		var maybeCorrespondingProduct = A2($elm_community$list_extra$List$Extra$getAt, ind, model.ja);
		if (!maybeCorrespondingProduct.$) {
			var product = maybeCorrespondingProduct.a;
			var newProduct = f(product);
			var newProducts = A3($elm_community$list_extra$List$Extra$setAt, ind, newProduct, model.ja);
			return _Utils_update(
				model,
				{ja: newProducts});
		} else {
			return model;
		}
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$UpdateProductResponse = function (succeeded) {
	return {mo: succeeded};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProductResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Pages$Users$Tv24Comnet$ChangeProduct$UpdateProductResponse,
	A2($elm$json$Json$Decode$field, 'succeeded', $elm$json$Json$Decode$bool));
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var initResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b2: false, ja: initResponse.ja}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				if (!msg.a.$) {
					var updateProductResponse = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(err)),
								J: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							ka: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 3:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							dr: $elm$core$String$toInt(val)
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 5:
				var ind = msg.a;
				var newOrder = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateOrder,
						ind,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt(newOrder))),
					$elm$core$Platform$Cmd$none);
			case 4:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{
							j9: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(val))
						});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 6:
				var ind = msg.a;
				var val = msg.b;
				var f = function (product) {
					return _Utils_update(
						product,
						{mM: val});
				};
				return _Utils_Tuple2(
					A3($author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProduct, ind, model, f),
					$elm$core$Platform$Cmd$none);
			case 9:
				var ind = msg.a;
				var effectAllowed = msg.b;
				var value = msg.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Just(ind)
						}),
					$author$project$DragPorts$dragstart(
						A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$startPortData, effectAllowed, value)));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var destInd = msg.a;
				var dropEffect = msg.b;
				var value = msg.c;
				var _v1 = model.ia;
				if (!_v1.$) {
					var sourceInd = _v1.a;
					var dragStatusIncVal = (_Utils_cmp(sourceInd, destInd) < 0) ? 1 : (-1);
					var products = A3(
						$elm_community$list_extra$List$Extra$swapAt,
						sourceInd,
						destInd,
						A3(
							$elm_community$list_extra$List$Extra$updateAt,
							destInd,
							function (product) {
								return _Utils_update(
									product,
									{kE: product.kE + dragStatusIncVal});
							},
							model.ja));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ia: $elm$core$Maybe$Just(destInd),
								ja: products
							}),
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				} else {
					return _Utils_Tuple2(
						model,
						$author$project$DragPorts$dragover(
							A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$overPortData, dropEffect, value)));
				}
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ia: $elm$core$Maybe$Nothing,
							ja: A2(
								$elm$core$List$map,
								function (p) {
									return _Utils_update(
										p,
										{kE: 0});
								},
								model.ja)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var index = msg.a;
				var maybeProduct = A2($elm_community$list_extra$List$Extra$getAt, index, model.ja);
				if (!maybeProduct.$) {
					var product = maybeProduct.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'product_id',
								$elm$json$Json$Encode$string(product.i8)),
								_Utils_Tuple2(
								'base_price',
								$elm$json$Json$Encode$int(
									$elm$core$Basics$round(product.ka * ((100 + $author$project$Utils$Constants$tax) * 0.01)))),
								_Utils_Tuple2(
								'base_point',
								$elm$json$Json$Encode$int(product.j9)),
								_Utils_Tuple2(
								'product_order',
								$elm$json$Json$Encode$int(index + 1)),
								_Utils_Tuple2(
								'yodobashi_base_price_flag',
								$elm$json$Json$Encode$bool(product.mM)),
								_Utils_Tuple2(
								'get_interval',
								$elm$json$Json$Encode$int(product.$9)),
								_Utils_Tuple2(
								'r_tyousei',
								A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, product.dr))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: true}),
						A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.ad, model.bu, body, $author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Tv24Comnet$ChangeProduct$UpdateProductRequestSent));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				var body = A2(
					$elm$json$Json$Encode$list,
					function (product) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'product_id',
									$elm$json$Json$Encode$string(product.i8)),
									_Utils_Tuple2(
									'base_price',
									$elm$json$Json$Encode$int(
										$elm$core$Basics$round(product.ka * ((100 + $author$project$Utils$Constants$tax) * 0.01)))),
									_Utils_Tuple2(
									'base_point',
									$elm$json$Json$Encode$int(product.j9)),
									_Utils_Tuple2(
									'product_order',
									$elm$json$Json$Encode$int(product.lU)),
									_Utils_Tuple2(
									'yodobashi_base_price_flag',
									$elm$json$Json$Encode$bool(product.mM)),
									_Utils_Tuple2(
									'get_interval',
									$elm$json$Json$Encode$int(product.$9)),
									_Utils_Tuple2(
									'r_tyousei',
									A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$int, product.dr))
								]));
					},
					model.ja);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{J: true}),
					A5($author$project$Api$post, $author$project$Endpoints$User$tv24ComnetEndpoints.bN.hB, model.bu, body, $author$project$Pages$Users$Tv24Comnet$ChangeProduct$updateProductResponseDecoder, $author$project$Pages$Users$Tv24Comnet$ChangeProduct$UpdateProductRequestSent));
			default:
				var point = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hM: $elm$core$String$toInt(point)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$GetPriceRequestSent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$GetPriceResponse = F3(
	function (dictPrice, storeGroups, products) {
		return {h4: dictPrice, ja: products, U: storeGroups};
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$Price = function (productId) {
	return function (storeId) {
		return function (dateTime) {
			return function (price) {
				return function (priceIncludeTax) {
					return function (point) {
						return function (priceWithoutPoint) {
							return function (coupon) {
								return function (isLatest) {
									return function (url) {
										return {h3: coupon, kw: dateTime, eP: isLatest, iZ: point, i3: price, i4: priceIncludeTax, aW: priceWithoutPoint, i8: productId, hs: storeId, dS: url};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$priceDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'is_latest', $elm$json$Json$Decode$bool),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'coupon', $elm$json$Json$Decode$int),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'price_without_point', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'point', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'price_include_tax', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'price', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'date_time', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
										$elm$json$Json$Decode$succeed($author$project$Pages$Users$Tv24Comnet$Thirty$Price)))))))))));
var $author$project$Pages$Users$Tv24Comnet$Thirty$dictPriceDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$fromList,
	A3(
		$elm$json$Json$Decode$map2,
		$elm$core$List$map2($elm$core$Tuple$pair),
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'store_id', $elm$json$Json$Decode$int))),
		$elm$json$Json$Decode$list($author$project$Pages$Users$Tv24Comnet$Thirty$priceDecoder)));
var $author$project$Pages$Users$Tv24Comnet$Thirty$ProductR = function (productId) {
	return function (productName) {
		return function (basePrice) {
			return function (basePriceIncludeTax) {
				return function (basePoint) {
					return function (basePriceWithoutPoint) {
						return function (makerId) {
							return function (smallCategoryId) {
								return function (bigCategoryId) {
									return function (yodobashiFlag) {
										return function (r_tyousei) {
											return {j9: basePoint, ka: basePrice, eb: basePriceIncludeTax, bM: basePriceWithoutPoint, ed: bigCategoryId, iJ: makerId, i8: productId, lT: productName, g5: r_tyousei, ho: smallCategoryId, mM: yodobashiFlag};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$productRsDecoder = $elm$json$Json$Decode$list(
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'r_tyousei',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'yodobashi_base_price_flag', $elm$json$Json$Decode$bool),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'big_category_id', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'small_category_id', $elm$json$Json$Decode$int),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'maker_id', $elm$json$Json$Decode$int),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'base_price_without_point', $elm$json$Json$Decode$int),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'base_point', $elm$json$Json$Decode$int),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'base_price_include_tax', $elm$json$Json$Decode$int),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A2($elm$json$Json$Decode$field, 'base_price', $elm$json$Json$Decode$int),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2($elm$json$Json$Decode$field, 'product_name', $elm$json$Json$Decode$string),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2($elm$json$Json$Decode$field, 'product_id', $elm$json$Json$Decode$string),
												$elm$json$Json$Decode$succeed($author$project$Pages$Users$Tv24Comnet$Thirty$ProductR)))))))))))));
var $author$project$Pages$Users$Tv24Comnet$Thirty$getPriceResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Pages$Users$Tv24Comnet$Thirty$GetPriceResponse,
	A2($elm$json$Json$Decode$field, 'prices', $author$project$Pages$Users$Tv24Comnet$Thirty$dictPriceDecoder),
	A2($elm$json$Json$Decode$field, 'storeGroups', $author$project$Utils$Decoders$storeGroupsDecoder),
	A2($elm$json$Json$Decode$field, 'products', $author$project$Pages$Users$Tv24Comnet$Thirty$productRsDecoder));
var $author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceData = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceNoData = {$: 1};
var $author$project$Pages$Users$Tv24Comnet$Thirty$displayStyleToValues = _List_fromArray(
	[
		_Utils_Tuple2(
		'DisplayAll',
		_List_fromArray(
			[0, 1, 2, 3, 4])),
		_Utils_Tuple2(
		'DisplayPrice',
		_List_fromArray(
			[0, 1, 4])),
		_Utils_Tuple2(
		'DisplayPriceAndPoint',
		_List_fromArray(
			[0, 1, 2, 4])),
		_Utils_Tuple2(
		'DisplayAfterP',
		_List_fromArray(
			[3, 4])),
		_Utils_Tuple2(
		'DisplayAfterPWithoutTime',
		_List_fromArray(
			[3]))
	]);
var $author$project$Pages$Users$Tv24Comnet$Thirty$max_int = A2($elm$core$Basics$pow, 2, 30);
var $author$project$Pages$Users$Tv24Comnet$Thirty$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		F2(
			function (ind, product) {
				var rtyousei = function () {
					var _v8 = product.g5;
					if (!_v8.$) {
						var price = _v8.a;
						return $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(price));
					} else {
						return '-';
					}
				}();
				var maybeYodobashiPrice = A2(
					$elm$core$Dict$get,
					_Utils_Tuple2(product.i8, 7),
					model.ck);
				var lowestPrice = A3(
					$elm$core$List$foldl,
					F2(
						function (price, m) {
							return A2($elm$core$Basics$min, m, price);
						}),
					$author$project$Pages$Users$Tv24Comnet$Thirty$max_int,
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : $author$project$Pages$Users$Tv24Comnet$Thirty$max_int;
							} else {
								return $author$project$Pages$Users$Tv24Comnet$Thirty$max_int;
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Tv24Comnet$Thirty$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader(filteredBasePriceList),
							$author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: rtyousei, i: 0}
								]))
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return (price.hs === 4) ? _Utils_eq(price.i4, lowestPrice) : _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
							var isLowerRtyousei = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v5 = product.g5;
									if (!_v5.$) {
										var r_price = _v5.a;
										return (price.hs === 4) ? (_Utils_cmp(price.i4, r_price) < 0) : (_Utils_cmp(price.aW, r_price) < 0);
									} else {
										return false;
									}
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									var _v3 = _Utils_Tuple2(product.mM, maybeYodobashiPrice);
									if (_v3.a && (!_v3.b.$)) {
										var yodobashiPrice = _v3.b.a;
										return (price.hs === 4) ? (_Utils_cmp(price.i4, yodobashiPrice.aW) < 0) : (_Utils_cmp(price.aW, yodobashiPrice.aW) < 0);
									} else {
										return (price.hs === 4) ? (_Utils_cmp(price.i4, product.bM) < 0) : (_Utils_cmp(price.aW, product.bM) < 0);
									}
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: (price.hs === 4) ? '0' : function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: (price.hs === 4) ? $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)) : $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceData(
									{eP: price.eP, gK: isLower, gL: isLowerRtyousei, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Tv24Comnet$Thirty$ProductPriceNoData;
							}
						},
						stores));
			}),
		products);
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格', 'R調整価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$toCsv = F3(
	function (storeHeader, priceRows, selectedDisplayStyle) {
		return {
			cY: _List_Nil,
			lX: A2(
				$elm$core$List$cons,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jD;
					},
					storeHeader),
				A2(
					$elm$core$List$concatMap,
					function (row) {
						var indices = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, selectedDisplayStyle);
								},
								$author$project$Pages$Users$Tv24Comnet$Thirty$displayStyleToValues)).b;
						var numrow = A2(
							$elm$core$List$take,
							$elm$core$List$length(indices),
							_List_fromArray(
								[0, 1, 2, 3, 4, 5]));
						return _Utils_ap(
							A2(
								$elm$core$List$map,
								function (ind) {
									return A2(
										$elm$core$List$map,
										function (cell) {
											switch (cell.$) {
												case 0:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data)).iF;
												case 1:
													return '';
												default:
													var data = cell.a;
													return A2(
														$elm$core$Maybe$withDefault,
														{iF: '', i: -1},
														A2($elm_community$list_extra$List$Extra$getAt, ind, data.g2)).iF;
											}
										},
										row);
								},
								numrow),
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (cell) {
											switch (cell.$) {
												case 0:
													return (ind === 2) ? '最低● 基準※' : '';
												case 1:
													return '';
												default:
													var data = cell.a;
													return data.gM ? '●' : (data.gK ? '※' : '');
											}
										};
									},
									row)
								]));
					},
					priceRows))
		};
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bs: A3($author$project$Utils$Helpers$formatDate, date.mL, date.lp, date.gp),
							aX: function (parts) {
								return A2($author$project$Utils$Helpers$formatTime, parts.k_, parts.ln);
							}(
								A2($author$project$Utils$Helpers$floorTimeByInterval, date, model.Z))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bs: date}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: time}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v1 = A2($author$project$Utils$Helpers$dateTimeToParts, model.bs, model.aX);
						if (!_v1.$) {
							var dateTimeParts = _v1.a;
							var to = $author$project$Utils$Helpers$formatDateTime(dateTimeParts);
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$justinmimbs$time_extra$Time$Extra$posixToParts,
									$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
									A4(
										$justinmimbs$time_extra$Time$Extra$add,
										13,
										(-1) * model.Z,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A2(
											$justinmimbs$time_extra$Time$Extra$partsToPosix,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											dateTimeParts))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Tv24Comnet$Thirty$getPriceResponseDecoder,
								$author$project$Pages$Users$Tv24Comnet$Thirty$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: true}),
					function () {
						var _v2 = model.en;
						if (!_v2.$) {
							var time = _v2.a;
							var to = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										time)));
							var from = $author$project$Utils$Helpers$formatDateTime(
								A2(
									$author$project$Utils$Helpers$roundByInterval,
									model.Z,
									A2(
										$justinmimbs$time_extra$Time$Extra$posixToParts,
										$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
										A4(
											$justinmimbs$time_extra$Time$Extra$add,
											13,
											(-1) * model.Z,
											$justinmimbs$timezone_data$TimeZone$asia__tokyo(0),
											time))));
							return A4(
								$author$project$Api$get,
								$author$project$Endpoints$User$defaultEndpoints.C(
									_List_fromArray(
										[
											A2($elm$url$Url$Builder$string, 'from', from),
											A2($elm$url$Url$Builder$string, 'to', to),
											A2(
											$elm$url$Url$Builder$string,
											'interval',
											$elm$core$String$fromInt(model.Z)),
											A2(
											$elm$url$Url$Builder$string,
											'usePast',
											model.f3 ? 'true' : ''),
											A2(
											$elm$url$Url$Builder$string,
											'bigCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.he))),
											A2(
											$elm$url$Url$Builder$string,
											'smallCategory',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.fH))),
											A2(
											$elm$url$Url$Builder$string,
											'maker',
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, -1, model.dw)))
										])),
								model.bu,
								$author$project$Pages$Users$Tv24Comnet$Thirty$getPriceResponseDecoder,
								$author$project$Pages$Users$Tv24Comnet$Thirty$GetPriceRequestSent);
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}());
			case 11:
				var bigCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							he: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(bigCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var smallCategoryId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fH: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(smallCategoryId))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var makerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dw: A2(
								$elm$core$Maybe$andThen,
								$elm$core$Maybe$Just,
								$elm$core$String$toInt(makerId))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				if (!result.$) {
					var initResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hR: initResponse.hR, ar: $elm$core$Maybe$Nothing, b2: false, ca: initResponse.ca, jy: initResponse.jy}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								b2: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = msg.a;
				if (!result.$) {
					var getPriceResponse = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Nothing,
								gz: true,
								c5: false,
								ck: getPriceResponse.h4,
								ja: getPriceResponse.ja,
								U: getPriceResponse.U,
								fP: model.bs,
								fQ: model.aX,
								L: $elm$core$Set$isEmpty(model.L) ? $elm$core$Set$fromList(
									A2(
										$elm$core$List$map,
										function ($) {
											return $.mh;
										},
										getPriceResponse.U)) : model.L
							}),
						$author$project$Api$createFixedMidashi(0));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(
									$author$project$Utils$Helpers$errToStr(error)),
								c5: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 14:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							en: $elm$core$Maybe$Just(posix)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var disp = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cq: disp}),
					$author$project$Api$createFixedMidashi(0));
			case 3:
				var checked = msg.a;
				var storeGroupIdStr = msg.b;
				var storeGroupId = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(storeGroupIdStr));
				var newStoreGroups = checked ? A2($elm$core$Set$insert, storeGroupId, model.L) : A2($elm$core$Set$remove, storeGroupId, model.L);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: newStoreGroups}),
					$author$project$Api$createFixedMidashi(0));
			case 10:
				var storeHeader = A2($author$project$Pages$Users$Tv24Comnet$Thirty$pStoreHeader, model.U, model.L);
				var priceRows = $author$project$Pages$Users$Tv24Comnet$Thirty$pProductPriceRows(model);
				var csvFileName = A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'data.csv',
					A2(
						$elm$core$Basics$composeR,
						$author$project$Utils$Helpers$formatDateTime,
						A2($author$project$Utils$Helpers$flip, $elm$core$String$append, '.csv')),
					A2($author$project$Utils$Helpers$dateTimeToParts, model.fP, model.fQ));
				var csv = $elm$bytes$Bytes$Encode$encode(
					function (byteCsv) {
						return $elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									$elm$bytes$Bytes$Encode$unsignedInt8(239),
									$elm$bytes$Bytes$Encode$unsignedInt8(187),
									$elm$bytes$Bytes$Encode$unsignedInt8(191),
									$elm$bytes$Bytes$Encode$bytes(byteCsv)
								]));
					}(
						$commonmind$elm_csv_encode$Csv$Encode$toBytes(
							A3($author$project$Pages$Users$Tv24Comnet$Thirty$toCsv, storeHeader, priceRows, model.cq))));
				return _Utils_Tuple2(
					model,
					A3($elm$file$File$Download$bytes, csvFileName, 'text/csv', csv));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$User$updateWithUserModel = F4(
	function (toMsg, cmsModel, news, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple3(cmsModel, subModel, news),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Pages$User$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$78:
		while (true) {
			switch (_v0.a.$) {
				case 29:
					var result = _v0.a.a;
					var _v1 = model;
					var user = _v1.a;
					var nav = _v1.b;
					var news = _v1.c;
					if (!result.$) {
						var res = result.a;
						return _Utils_Tuple2(
							_Utils_Tuple3(user, nav, res.iO),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = result.a;
						return _Utils_Tuple2(
							_Utils_Tuple3(user, nav, news),
							$elm$core$Platform$Cmd$none);
					}
				case 0:
					if (_v0.b.a.$ === 1) {
						var defaultMsg = _v0.a.a;
						var _v3 = _v0.b;
						var defaultModel = _v3.a.a;
						var navModel = _v3.b;
						var news = _v3.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$DefaultModel,
							$author$project$Pages$User$GotDefaultMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Common$Default$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 1:
					if (_v0.b.a.$ === 2) {
						var defaultMsg = _v0.a.a;
						var _v4 = _v0.b;
						var defaultModel = _v4.a.a;
						var navModel = _v4.b;
						var news = _v4.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TimeSeqModel,
							$author$project$Pages$User$GotTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Common$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 8:
					if (_v0.b.a.$ === 9) {
						var defaultMsg = _v0.a.a;
						var _v5 = _v0.b;
						var defaultModel = _v5.a.a;
						var navModel = _v5.b;
						var news = _v5.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ChangeProductModel,
							$author$project$Pages$User$GotChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Common$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 9:
					if (_v0.b.a.$ === 10) {
						var defaultMsg = _v0.a.a;
						var _v6 = _v0.b;
						var defaultModel = _v6.a.a;
						var navModel = _v6.b;
						var news = _v6.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmComnetTimeSeqModel,
							$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 11:
					if (_v0.b.a.$ === 12) {
						var defaultMsg = _v0.a.a;
						var _v7 = _v0.b;
						var defaultModel = _v7.a.a;
						var navModel = _v7.b;
						var news = _v7.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmComnetDayModel,
							$author$project$Pages$User$GotTo24tcmComnetDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmComnet$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 10:
					if (_v0.b.a.$ === 11) {
						var defaultMsg = _v0.a.a;
						var _v8 = _v0.b;
						var defaultModel = _v8.a.a;
						var navModel = _v8.b;
						var news = _v8.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmComnetCsvModel,
							$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmComnet$Csv$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 12:
					if (_v0.b.a.$ === 13) {
						var defaultMsg = _v0.a.a;
						var _v9 = _v0.b;
						var defaultModel = _v9.a.a;
						var navModel = _v9.b;
						var news = _v9.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmComnetChangeProductModel,
							$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 13:
					if (_v0.b.a.$ === 14) {
						var defaultMsg = _v0.a.a;
						var _v10 = _v0.b;
						var defaultModel = _v10.a.a;
						var navModel = _v10.b;
						var news = _v10.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmComnetProductModel,
							$author$project$Pages$User$GotTo24tcmComnetProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmComnet$Product$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 47:
					if (_v0.b.a.$ === 46) {
						var defaultMsg = _v0.a.a;
						var _v11 = _v0.b;
						var defaultModel = _v11.a.a;
						var navModel = _v11.b;
						var news = _v11.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tcmKitchenDayModel,
							$author$project$Pages$User$GotTo24tcmKitchenDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tcmKitchen$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 14:
					if (_v0.b.a.$ === 15) {
						var defaultMsg = _v0.a.a;
						var _v12 = _v0.b;
						var defaultModel = _v12.a.a;
						var navModel = _v12.b;
						var news = _v12.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tclComnetChangeProductModel,
							$author$project$Pages$User$GotTo24tclComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tclComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 15:
					if (_v0.b.a.$ === 16) {
						var defaultMsg = _v0.a.a;
						var _v13 = _v0.b;
						var defaultModel = _v13.a.a;
						var navModel = _v13.b;
						var news = _v13.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tclComnetDayModel,
							$author$project$Pages$User$GotTo24tclComnetDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tclComnet$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 16:
					if (_v0.b.a.$ === 17) {
						var defaultMsg = _v0.a.a;
						var _v14 = _v0.b;
						var defaultModel = _v14.a.a;
						var navModel = _v14.b;
						var news = _v14.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$To24tclComnetTimeSeqModel,
							$author$project$Pages$User$GotTo24tclComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$To24tclComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 17:
					if (_v0.b.a.$ === 18) {
						var defaultMsg = _v0.a.a;
						var _v15 = _v0.b;
						var defaultModel = _v15.a.a;
						var navModel = _v15.b;
						var news = _v15.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$JvcComnetTimeSeqModel,
							$author$project$Pages$User$GotJvcComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$JvcComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 2:
					if (_v0.b.a.$ === 3) {
						var defaultMsg = _v0.a.a;
						var _v16 = _v0.b;
						var defaultModel = _v16.a.a;
						var navModel = _v16.b;
						var news = _v16.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi24ComnetHourModel,
							$author$project$Pages$User$GotHi24ComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi24Comnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 3:
					if (_v0.b.a.$ === 4) {
						var defaultMsg = _v0.a.a;
						var _v17 = _v0.b;
						var defaultModel = _v17.a.a;
						var navModel = _v17.b;
						var news = _v17.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi24ComnetTimeSeqModel,
							$author$project$Pages$User$GotHi24ComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 4:
					if (_v0.b.a.$ === 5) {
						var defaultMsg = _v0.a.a;
						var _v18 = _v0.b;
						var defaultModel = _v18.a.a;
						var navModel = _v18.b;
						var news = _v18.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi24ComnetWeeklyProductFifteenModel,
							$author$project$Pages$User$GotHi24ComnetWeeklyProductFifteenMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 5:
					if (_v0.b.a.$ === 6) {
						var defaultMsg = _v0.a.a;
						var _v19 = _v0.b;
						var defaultModel = _v19.a.a;
						var navModel = _v19.b;
						var news = _v19.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi24ComnetTenAMModel,
							$author$project$Pages$User$GotHi24ComnetTenAMMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi24Comnet$TenAM$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 6:
					if (_v0.b.a.$ === 7) {
						var defaultMsg = _v0.a.a;
						var _v20 = _v0.b;
						var defaultModel = _v20.a.a;
						var navModel = _v20.b;
						var news = _v20.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi3ComnetHourModel,
							$author$project$Pages$User$GotHi3ComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi3Comnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 7:
					if (_v0.b.a.$ === 8) {
						var defaultMsg = _v0.a.a;
						var _v21 = _v0.b;
						var defaultModel = _v21.a.a;
						var navModel = _v21.b;
						var news = _v21.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Hi3ComnetTimeSeqModel,
							$author$project$Pages$User$GotHi3ComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 18:
					if (_v0.b.a.$ === 19) {
						var defaultMsg = _v0.a.a;
						var _v22 = _v0.b;
						var defaultModel = _v22.a.a;
						var navModel = _v22.b;
						var news = _v22.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseDayModel,
							$author$project$Pages$User$GotHisenseDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 19:
					if (_v0.b.a.$ === 20) {
						var defaultMsg = _v0.a.a;
						var _v23 = _v0.b;
						var defaultModel = _v23.a.a;
						var navModel = _v23.b;
						var news = _v23.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseTvHourModel,
							$author$project$Pages$User$GotHisenseTvHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$TvHour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 23:
					if (_v0.b.a.$ === 23) {
						var defaultMsg = _v0.a.a;
						var _v24 = _v0.b;
						var defaultModel = _v24.a.a;
						var navModel = _v24.b;
						var news = _v24.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseTimeSeqModel,
							$author$project$Pages$User$GotHisenseTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 20:
					if (_v0.b.a.$ === 22) {
						var defaultMsg = _v0.a.a;
						var _v25 = _v0.b;
						var defaultModel = _v25.a.a;
						var navModel = _v25.b;
						var news = _v25.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseAmazonModel,
							$author$project$Pages$User$GotHisenseAmazonMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$Amazon$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 21:
					if (_v0.b.a.$ === 24) {
						var defaultMsg = _v0.a.a;
						var _v26 = _v0.b;
						var defaultModel = _v26.a.a;
						var navModel = _v26.b;
						var news = _v26.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseChangeProductModel,
							$author$project$Pages$User$GotHisenseChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 22:
					if (_v0.b.a.$ === 21) {
						var defaultMsg = _v0.a.a;
						var _v27 = _v0.b;
						var defaultModel = _v27.a.a;
						var navModel = _v27.b;
						var news = _v27.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseReviewModel,
							$author$project$Pages$User$GotHisenseReviewMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$Review$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 24:
					if (_v0.b.a.$ === 25) {
						var defaultMsg = _v0.a.a;
						var _v28 = _v0.b;
						var defaultModel = _v28.a.a;
						var navModel = _v28.b;
						var news = _v28.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$HisenseExcelModel,
							$author$project$Pages$User$GotHisenseExcelMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Hisense$Excel$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 26:
					if (_v0.b.a.$ === 26) {
						var defaultMsg = _v0.a.a;
						var _v29 = _v0.b;
						var defaultModel = _v29.a.a;
						var navModel = _v29.b;
						var news = _v29.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TescomComnetHourModel,
							$author$project$Pages$User$GotTescomComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TescomComnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 27:
					if (_v0.b.a.$ === 27) {
						var defaultMsg = _v0.a.a;
						var _v30 = _v0.b;
						var defaultModel = _v30.a.a;
						var navModel = _v30.b;
						var news = _v30.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TescomComnetExcelModel,
							$author$project$Pages$User$GotTescomComnetExcelMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TescomComnet$Excel$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 28:
					if (_v0.b.a.$ === 28) {
						var defaultMsg = _v0.a.a;
						var _v31 = _v0.b;
						var defaultModel = _v31.a.a;
						var navModel = _v31.b;
						var news = _v31.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TescomComnetChangeProductModel,
							$author$project$Pages$User$GotTescomComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TescomComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 25:
					var navMsg = _v0.a.a;
					var _v32 = _v0.b;
					var userModel = _v32.a;
					var navModel = _v32.b;
					var news = _v32.c;
					return A4(
						$author$project$Pages$User$updateWithUserModel,
						$author$project$Pages$User$GotNavBarMsg,
						userModel,
						news,
						A2($author$project$Components$NavBar$update, navMsg, navModel));
				case 30:
					if (_v0.b.a.$ === 29) {
						var defaultMsg = _v0.a.a;
						var _v33 = _v0.b;
						var defaultModel = _v33.a.a;
						var navModel = _v33.b;
						var news = _v33.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Ca6ComnetHourCsvModel,
							$author$project$Pages$User$GotCa6ComnetHourCsvMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Ca6Comnet$HourCsv$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 31:
					if (_v0.b.a.$ === 30) {
						var defaultMsg = _v0.a.a;
						var _v34 = _v0.b;
						var defaultModel = _v34.a.a;
						var navModel = _v34.b;
						var news = _v34.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Ca6ComnetWeeklyProductFifteenModel,
							$author$project$Pages$User$GotCa6ComnetWeeklyProductFifteenMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 32:
					if (_v0.b.a.$ === 31) {
						var defaultMsg = _v0.a.a;
						var _v35 = _v0.b;
						var defaultModel = _v35.a.a;
						var navModel = _v35.b;
						var news = _v35.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetChangeProductModel,
							$author$project$Pages$User$GotMlnComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 33:
					if (_v0.b.a.$ === 35) {
						var defaultMsg = _v0.a.a;
						var _v36 = _v0.b;
						var defaultModel = _v36.a.a;
						var navModel = _v36.b;
						var news = _v36.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetChangeMailModel,
							$author$project$Pages$User$GotMlnComnetChangeMailMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$ChangeMail$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 39:
					if (_v0.b.a.$ === 38) {
						var defaultMsg = _v0.a.a;
						var _v37 = _v0.b;
						var defaultModel = _v37.a.a;
						var navModel = _v37.b;
						var news = _v37.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetChangePasswordModel,
							$author$project$Pages$User$GotMlnComnetChangePasswordMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$ChangePassword$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 34:
					if (_v0.b.a.$ === 32) {
						var defaultMsg = _v0.a.a;
						var _v38 = _v0.b;
						var defaultModel = _v38.a.a;
						var navModel = _v38.b;
						var news = _v38.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetChronologicalModel,
							$author$project$Pages$User$GotMlnComnetChronologicalMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$Chronological$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 35:
					if (_v0.b.a.$ === 33) {
						var defaultMsg = _v0.a.a;
						var _v39 = _v0.b;
						var defaultModel = _v39.a.a;
						var navModel = _v39.b;
						var news = _v39.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetHourModel,
							$author$project$Pages$User$GotMlnComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 36:
					if (_v0.b.a.$ === 34) {
						var defaultMsg = _v0.a.a;
						var _v40 = _v0.b;
						var defaultModel = _v40.a.a;
						var navModel = _v40.b;
						var news = _v40.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetHourYamadaModel,
							$author$project$Pages$User$GotMlnComnetHourYamadaMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$HourYamada$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 37:
					if (_v0.b.a.$ === 36) {
						var defaultMsg = _v0.a.a;
						var _v41 = _v0.b;
						var defaultModel = _v41.a.a;
						var navModel = _v41.b;
						var news = _v41.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetAutoReloadModel,
							$author$project$Pages$User$GotMlnComnetAutoReloadMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$AutoReload$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 38:
					if (_v0.b.a.$ === 37) {
						var defaultMsg = _v0.a.a;
						var _v42 = _v0.b;
						var defaultModel = _v42.a.a;
						var navModel = _v42.b;
						var news = _v42.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetMailSettingModel,
							$author$project$Pages$User$GotMlnComnetMailSettingMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$MailSetting$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 40:
					if (_v0.b.a.$ === 39) {
						var defaultMsg = _v0.a.a;
						var _v43 = _v0.b;
						var defaultModel = _v43.a.a;
						var navModel = _v43.b;
						var news = _v43.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$MlnComnetYahooModel,
							$author$project$Pages$User$GotMlnComnetYahooMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$MlnComnet$Yahoo$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 41:
					if (_v0.b.a.$ === 40) {
						var defaultMsg = _v0.a.a;
						var _v44 = _v0.b;
						var defaultModel = _v44.a.a;
						var navModel = _v44.b;
						var news = _v44.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Tv24ComnetChangeProductModel,
							$author$project$Pages$User$GotTv24ComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Tv24Comnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 42:
					if (_v0.b.a.$ === 41) {
						var defaultMsg = _v0.a.a;
						var _v45 = _v0.b;
						var defaultModel = _v45.a.a;
						var navModel = _v45.b;
						var news = _v45.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Tv24ComnetThirtyModel,
							$author$project$Pages$User$GotTv24ComnetThirtyMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Tv24Comnet$Thirty$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 43:
					if (_v0.b.a.$ === 42) {
						var defaultMsg = _v0.a.a;
						var _v46 = _v0.b;
						var defaultModel = _v46.a.a;
						var navModel = _v46.b;
						var news = _v46.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ElecComnetHourModel,
							$author$project$Pages$User$GotElecComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ElecComnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 44:
					if (_v0.b.a.$ === 43) {
						var defaultMsg = _v0.a.a;
						var _v47 = _v0.b;
						var defaultModel = _v47.a.a;
						var navModel = _v47.b;
						var news = _v47.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ElecComnetExcelModel,
							$author$project$Pages$User$GotElecComnetExcelMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ElecComnet$Excel$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 45:
					if (_v0.b.a.$ === 44) {
						var defaultMsg = _v0.a.a;
						var _v48 = _v0.b;
						var defaultModel = _v48.a.a;
						var navModel = _v48.b;
						var news = _v48.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ElecComnetChangeProductModel,
							$author$project$Pages$User$GotElecComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ElecComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 46:
					if (_v0.b.a.$ === 45) {
						var defaultMsg = _v0.a.a;
						var _v49 = _v0.b;
						var defaultModel = _v49.a.a;
						var navModel = _v49.b;
						var news = _v49.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ElecComnetTimeSeqModel,
							$author$project$Pages$User$GotElecComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ElecComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 48:
					if (_v0.b.a.$ === 47) {
						var defaultMsg = _v0.a.a;
						var _v50 = _v0.b;
						var defaultModel = _v50.a.a;
						var navModel = _v50.b;
						var news = _v50.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$DelonghiHourModel,
							$author$project$Pages$User$GotDelonghiHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Delonghi$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 49:
					if (_v0.b.a.$ === 48) {
						var defaultMsg = _v0.a.a;
						var _v51 = _v0.b;
						var defaultModel = _v51.a.a;
						var navModel = _v51.b;
						var news = _v51.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$DelonghiChangeProductModel,
							$author$project$Pages$User$GotDelonghiChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Delonghi$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 50:
					if (_v0.b.a.$ === 49) {
						var defaultMsg = _v0.a.a;
						var _v52 = _v0.b;
						var defaultModel = _v52.a.a;
						var navModel = _v52.b;
						var news = _v52.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$DelonghiTimeSeqModel,
							$author$project$Pages$User$GotDelonghiTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Delonghi$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 51:
					if (_v0.b.a.$ === 50) {
						var defaultMsg = _v0.a.a;
						var _v53 = _v0.b;
						var defaultModel = _v53.a.a;
						var navModel = _v53.b;
						var news = _v53.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$DelonghiTimeSeq2Model,
							$author$project$Pages$User$GotDelonghiTimeSeq2Msg,
							navModel,
							news,
							A2($author$project$Pages$Users$Delonghi$TimeSeq2$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 52:
					if (_v0.b.a.$ === 51) {
						var defaultMsg = _v0.a.a;
						var _v54 = _v0.b;
						var defaultModel = _v54.a.a;
						var navModel = _v54.b;
						var news = _v54.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$KinujoComnetExcelModel,
							$author$project$Pages$User$GotKinujoComnetExcelMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$KinujoComnet$Excel$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 53:
					if (_v0.b.a.$ === 52) {
						var defaultMsg = _v0.a.a;
						var _v55 = _v0.b;
						var defaultModel = _v55.a.a;
						var navModel = _v55.b;
						var news = _v55.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$KinujoComnetChangeProductModel,
							$author$project$Pages$User$GotKinujoComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$KinujoComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 54:
					if (_v0.b.a.$ === 53) {
						var defaultMsg = _v0.a.a;
						var _v56 = _v0.b;
						var defaultModel = _v56.a.a;
						var navModel = _v56.b;
						var news = _v56.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$KinujoComnetFifteenModel,
							$author$project$Pages$User$GotKinujoComnetFifteeenMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$KinujoComnet$Fifteen$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 55:
					if (_v0.b.a.$ === 54) {
						var defaultMsg = _v0.a.a;
						var _v57 = _v0.b;
						var defaultModel = _v57.a.a;
						var navModel = _v57.b;
						var news = _v57.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetWideModel,
							$author$project$Pages$User$GotShaComnetWideMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$Wide$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 56:
					if (_v0.b.a.$ === 55) {
						var defaultMsg = _v0.a.a;
						var _v58 = _v0.b;
						var defaultModel = _v58.a.a;
						var navModel = _v58.b;
						var news = _v58.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetHourModel,
							$author$project$Pages$User$GotShaComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 57:
					if (_v0.b.a.$ === 56) {
						var defaultMsg = _v0.a.a;
						var _v59 = _v0.b;
						var defaultModel = _v59.a.a;
						var navModel = _v59.b;
						var news = _v59.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetTimeSeqModel,
							$author$project$Pages$User$GotShaComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 58:
					if (_v0.b.a.$ === 57) {
						var defaultMsg = _v0.a.a;
						var _v60 = _v0.b;
						var defaultModel = _v60.a.a;
						var navModel = _v60.b;
						var news = _v60.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetWeeklyProductModel,
							$author$project$Pages$User$GotShaComnetWeeklyProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$WeeklyProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 59:
					if (_v0.b.a.$ === 58) {
						var defaultMsg = _v0.a.a;
						var _v61 = _v0.b;
						var defaultModel = _v61.a.a;
						var navModel = _v61.b;
						var news = _v61.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetDayModel,
							$author$project$Pages$User$GotShaComnetDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 60:
					if (_v0.b.a.$ === 59) {
						var defaultMsg = _v0.a.a;
						var _v62 = _v0.b;
						var defaultModel = _v62.a.a;
						var navModel = _v62.b;
						var news = _v62.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ShaComnetChangeProductModel,
							$author$project$Pages$User$GotShaComnetChangeProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$ShaComnet$ChangeProduct$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 61:
					if (_v0.b.a.$ === 60) {
						var defaultMsg = _v0.a.a;
						var _v63 = _v0.b;
						var defaultModel = _v63.a.a;
						var navModel = _v63.b;
						var news = _v63.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TstComnetTimeSeqModel,
							$author$project$Pages$User$GotTstComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TstComnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 62:
					if (_v0.b.a.$ === 61) {
						var defaultMsg = _v0.a.a;
						var _v64 = _v0.b;
						var defaultModel = _v64.a.a;
						var navModel = _v64.b;
						var news = _v64.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TstComnetWideModel,
							$author$project$Pages$User$GotTstComnetWideMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TstComnet$Wide$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 63:
					if (_v0.b.a.$ === 62) {
						var defaultMsg = _v0.a.a;
						var _v65 = _v0.b;
						var defaultModel = _v65.a.a;
						var navModel = _v65.b;
						var news = _v65.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Dbi6ComnetHourModel,
							$author$project$Pages$User$GotDbi6ComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Dbi6Comnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 64:
					if (_v0.b.a.$ === 63) {
						var defaultMsg = _v0.a.a;
						var _v66 = _v0.b;
						var defaultModel = _v66.a.a;
						var navModel = _v66.b;
						var news = _v66.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Dbi6ComnetTimeSeqModel,
							$author$project$Pages$User$GotDbi6ComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 65:
					if (_v0.b.a.$ === 65) {
						var defaultMsg = _v0.a.a;
						var _v67 = _v0.b;
						var defaultModel = _v67.a.a;
						var navModel = _v67.b;
						var news = _v67.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Denon6ComnetWideModel,
							$author$project$Pages$User$GotDenon6ComnetWideMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Denon6Comnet$Wide$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 66:
					if (_v0.b.a.$ === 64) {
						var defaultMsg = _v0.a.a;
						var _v68 = _v0.b;
						var defaultModel = _v68.a.a;
						var navModel = _v68.b;
						var news = _v68.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Denon6ComnetTimeSeqModel,
							$author$project$Pages$User$GotDenon6ComnetTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 67:
					if (_v0.b.a.$ === 66) {
						var reiMsg = _v0.a.a;
						var _v69 = _v0.b;
						var reiModel = _v69.a.a;
						var navModel = _v69.b;
						var news = _v69.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$EditProductsPermalinks,
							$author$project$Pages$User$GotEditProductsPermalinks,
							navModel,
							news,
							A2($author$project$Pages$Cms$Products$EditProductsPermalinks$update, reiMsg, reiModel));
					} else {
						break _v0$78;
					}
				case 68:
					if (_v0.b.a.$ === 67) {
						var reiMsg = _v0.a.a;
						var _v70 = _v0.b;
						var reiModel = _v70.a.a;
						var navModel = _v70.b;
						var news = _v70.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$ChangeUserProductModel,
							$author$project$Pages$User$GotChangeUserProductMsg,
							navModel,
							news,
							A2($author$project$Pages$Cms$Products$ChangeUserProduct$update, reiMsg, reiModel));
					} else {
						break _v0$78;
					}
				case 69:
					if (_v0.b.a.$ === 68) {
						var reiMsg = _v0.a.a;
						var _v71 = _v0.b;
						var reiModel = _v71.a.a;
						var navModel = _v71.b;
						var news = _v71.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$AddProductConfigModel,
							$author$project$Pages$User$GotAddProductConfigMsg,
							navModel,
							news,
							A2($author$project$Pages$Cms$Products$AddProductConfig$update, reiMsg, reiModel));
					} else {
						break _v0$78;
					}
				case 70:
					if (_v0.b.a.$ === 69) {
						var reiMsg = _v0.a.a;
						var _v72 = _v0.b;
						var reiModel = _v72.a.a;
						var navModel = _v72.b;
						var news = _v72.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$EditProductsModel,
							$author$project$Pages$User$GotEditProductsMsg,
							navModel,
							news,
							A2($author$project$Pages$Cms$Products$EditProducts$update, reiMsg, reiModel));
					} else {
						break _v0$78;
					}
				case 71:
					if (_v0.b.a.$ === 70) {
						var yMsg = _v0.a.a;
						var _v73 = _v0.b;
						var yModel = _v73.a.a;
						var navModel = _v73.b;
						var news = _v73.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$Yahoo,
							$author$project$Pages$User$GotYahooMsg,
							navModel,
							news,
							A2($author$project$Pages$Cms$Stores$Yahoo$update, yMsg, yModel));
					} else {
						break _v0$78;
					}
				case 72:
					if (_v0.b.a.$ === 71) {
						var defaultMsg = _v0.a.a;
						var _v74 = _v0.b;
						var defaultModel = _v74.a.a;
						var navModel = _v74.b;
						var news = _v74.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$BraunComnetHourModel,
							$author$project$Pages$User$GotBraunComnetHourMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$BraunComnet$Hour$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 73:
					if (_v0.b.a.$ === 72) {
						var defaultMsg = _v0.a.a;
						var _v75 = _v0.b;
						var defaultModel = _v75.a.a;
						var navModel = _v75.b;
						var news = _v75.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$BraunComnetWideModel,
							$author$project$Pages$User$GotBraunComnetWideMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$BraunComnet$Wide$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 74:
					if (_v0.b.a.$ === 74) {
						var defaultMsg = _v0.a.a;
						var _v76 = _v0.b;
						var defaultModel = _v76.a.a;
						var navModel = _v76.b;
						var news = _v76.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TsBatWideModel,
							$author$project$Pages$User$GotTsBatWideMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TsBat$Wide$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 75:
					if (_v0.b.a.$ === 73) {
						var defaultMsg = _v0.a.a;
						var _v77 = _v0.b;
						var defaultModel = _v77.a.a;
						var navModel = _v77.b;
						var news = _v77.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TsBatTimeSeqModel,
							$author$project$Pages$User$GotTsBatTimeSeqMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$TsBat$TimeSeq$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				case 76:
					if (_v0.b.a.$ === 75) {
						var defaultMsg = _v0.a.a;
						var _v78 = _v0.b;
						var defaultModel = _v78.a.a;
						var navModel = _v78.b;
						var news = _v78.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$FujiComnetDayModel,
							$author$project$Pages$User$GotFujiComnetDayMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$FujiComnet$Day$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
				default:
					if (_v0.b.a.$ === 76) {
						var defaultMsg = _v0.a.a;
						var _v79 = _v0.b;
						var defaultModel = _v79.a.a;
						var navModel = _v79.b;
						var news = _v79.c;
						return A5(
							$author$project$Pages$User$updateWithNavModel,
							$author$project$Pages$User$TestFifteenModel,
							$author$project$Pages$User$GotTestFifteeenMsg,
							navModel,
							news,
							A2($author$project$Pages$Users$Test$Fifteen$update, defaultMsg, defaultModel));
					} else {
						break _v0$78;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$7:
		while (true) {
			switch (_v0.a.$) {
				case 3:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Session$navKey(
									$author$project$Main$toSession(model)),
								url.lL));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 4:
					var url = _v0.a.a;
					return A2($author$project$Main$changeRouteTo, url, model);
				case 0:
					if (_v0.b.$ === 1) {
						var loginMsg = _v0.a.a;
						var loginModel = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Login,
							$author$project$Main$GotLoginMsg,
							A2($author$project$Pages$Login$update, loginMsg, loginModel));
					} else {
						break _v0$7;
					}
				case 1:
					if (_v0.b.$ === 2) {
						var cmsMsg = _v0.a.a;
						var cmsModel = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Cms,
							$author$project$Main$GotCmsMsg,
							A2($author$project$Pages$Cms$update, cmsMsg, cmsModel));
					} else {
						break _v0$7;
					}
				case 2:
					if (_v0.b.$ === 3) {
						var userMsg = _v0.a.a;
						var userModel = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$User,
							$author$project$Main$GotUserMsg,
							A2($author$project$Pages$User$update, userMsg, userModel));
					} else {
						break _v0$7;
					}
				case 5:
					var resultUserInfo = _v0.a.a;
					if (!resultUserInfo.$) {
						var userInfo = resultUserInfo.a;
						var loggedInSession = A5(
							$author$project$Session$login,
							$author$project$Main$toSession(model),
							userInfo.hy,
							userInfo.gJ,
							userInfo.mD,
							userInfo.hd);
						return _Utils_Tuple2(
							$author$project$Main$Redirect(loggedInSession),
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Session$navKey(
									$author$project$Main$toSession(model)),
								'/'));
					} else {
						var errMsg = resultUserInfo.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				default:
					var _v3 = _v0.a;
					return _Utils_Tuple2(
						$author$project$Main$Redirect(
							$author$project$Session$logout(
								$author$project$Main$toSession(model))),
						$elm$browser$Browser$Navigation$load('/'));
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $rtfeldman$elm_css$Html$Styled$h1 = $rtfeldman$elm_css$Html$Styled$node('h1');
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.kx) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.iM, record.kx, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{kx: decl, iM: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.h$;
	var imports = _v0.it;
	var namespaces = _v0.iN;
	var declarations = _v0.ky;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {h$: charset, ky: finalDeclarations, it: imports, iN: namespaces};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.ig + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.i)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.iM;
			var declaration = decl.a.kx;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.h$;
	var imports = _v0.it;
	var namespaces = _v0.iN;
	var declarations = _v0.ky;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {bO: charsProcessed, b$: hash, T: seed, ma: shift};
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1 = 3432918353;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize = function (data) {
	var acc = (!(!data.b$)) ? (data.T ^ A2(
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
		A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
			15,
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, data.b$)))) : data.T;
	var h0 = acc ^ data.bO;
	var h1 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
			5,
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
					A2(
						$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
						15,
						A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, k1))))) + 3864292196;
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.b$ | ((255 & $elm$core$Char$toCode(c)) << data.ma);
		var _v0 = data.ma;
		if (_v0 === 24) {
			return {
				bO: data.bO + 1,
				b$: 0,
				T: A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$mix, data.T, res),
				ma: 0
			};
		} else {
			return {bO: data.bO + 1, b$: res, T: data.T, ma: data.ma + 8};
		}
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString = F2(
	function (seed, str) {
		return $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold,
				A4($rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{kx: str, iM: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.h$;
	var imports = _v0.it;
	var namespaces = _v0.iN;
	var snippets = _v0.jB;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {h$: charset, ky: declarations, it: imports, iN: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {h$: $elm$core$Maybe$Nothing, it: _List_Nil, iN: _List_Nil, jB: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $author$project$Page$view = function (pageRequired) {
	return {
		cK: _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$toUnstyled(
				A2(
					$rtfeldman$elm_css$Html$Styled$h1,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(pageRequired.hx)
						]))),
				$rtfeldman$elm_css$Html$Styled$toUnstyled(pageRequired.ko)
			]),
		hx: pageRequired.hx
	};
};
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var styles = _v0.b;
		var classname = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			styles,
			classname);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $author$project$Components$NavBar$Logout = {$: 1};
var $rtfeldman$elm_css$Html$Styled$a = $rtfeldman$elm_css$Html$Styled$node('a');
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Config = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$updateOptions = F2(
	function (mapper, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				iW: mapper(conf.iW)
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$attrs = F2(
	function (attrs_, conf) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$updateOptions,
			function (opt) {
				return _Utils_update(
					opt,
					{
						d9: _Utils_ap(opt.d9, attrs_)
					});
			},
			conf);
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Role$Light = 6;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Light = 1;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Roled = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$config = function (toMsg) {
	return {
		cL: $elm$core$Maybe$Nothing,
		eo: _List_Nil,
		k9: _List_Nil,
		iW: {
			d9: _List_Nil,
			bX: $elm$core$Maybe$Nothing,
			eN: false,
			fD: $elm$core$Maybe$Just(
				{
					ec: $frandibar$elm_bootstrap$Bootstrap$Navbar$Roled(6),
					e_: 1
				}),
			dM: 0
		},
		fX: toMsg,
		bD: false
	};
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$CustomItem = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$customItem = function (elem) {
	return elem;
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$updateConfig = F2(
	function (mapper, _v0) {
		var conf = _v0;
		return mapper(conf);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$customItems = F2(
	function (items_, config_) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$updateConfig,
			function (conf) {
				return _Utils_update(
					conf,
					{eo: items_});
			},
			config_);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Top = 0;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$fixTop = function (conf) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Navbar$updateOptions,
		function (opts) {
			return _Utils_update(
				opts,
				{
					bX: $elm$core$Maybe$Just(0)
				});
		},
		conf);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Item = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$itemLink = F2(
	function (attributes, children) {
		return $frandibar$elm_bootstrap$Bootstrap$Navbar$Item(
			{d9: attributes, h0: children});
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$items = F2(
	function (items_, config_) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$updateConfig,
			function (conf) {
				return _Utils_update(
					conf,
					{k9: items_});
			},
			config_);
	});
var $rtfeldman$elm_css$Html$Styled$li = $rtfeldman$elm_css$Html$Styled$node('li');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Dark = 0;
var $frandibar$elm_bootstrap$Bootstrap$Internal$Role$Primary = 0;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$scheme = F3(
	function (modifier, bgColor, conf) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$updateOptions,
			function (opt) {
				return _Utils_update(
					opt,
					{
						fD: $elm$core$Maybe$Just(
							{ec: bgColor, e_: modifier})
					});
			},
			conf);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$primary = A2(
	$frandibar$elm_bootstrap$Bootstrap$Navbar$scheme,
	0,
	$frandibar$elm_bootstrap$Bootstrap$Navbar$Roled(0));
var $rtfeldman$elm_css$Html$Styled$ul = $rtfeldman$elm_css$Html$Styled$node('ul');
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $frandibar$elm_bootstrap$Bootstrap$Navbar$maybeBrand = function (brand_) {
	if (!brand_.$) {
		var b = brand_.a;
		return _List_fromArray(
			[b]);
	} else {
		return _List_Nil;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$sizeToComparable = function (size) {
	switch (size) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$LG = 3;
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$MD = 2;
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$SM = 1;
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$XL = 4;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$toScreenSize = function (windowWidth) {
	return (windowWidth <= 576) ? 0 : ((windowWidth <= 768) ? 1 : ((windowWidth <= 992) ? 2 : ((windowWidth <= 1200) ? 3 : 4)));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu = F2(
	function (_v0, _v1) {
		var windowWidth = _v0.dZ;
		var options = _v1.iW;
		var winMedia = function () {
			if (!windowWidth.$) {
				var s = windowWidth.a;
				return $frandibar$elm_bootstrap$Bootstrap$Navbar$toScreenSize(s);
			} else {
				return 0;
			}
		}();
		return _Utils_cmp(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$sizeToComparable(winMedia),
			$frandibar$elm_bootstrap$Bootstrap$Navbar$sizeToComparable(options.dM)) > 0;
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $rtfeldman$elm_css$VirtualDom$Styled$style = F2(
	function (key, val) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$style, key, val),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$style = $rtfeldman$elm_css$VirtualDom$Styled$style;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$Shown = 5;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$StartDown = 1;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$StartUp = 3;
var $frandibar$elm_bootstrap$Bootstrap$Navbar$visibilityTransition = F2(
	function (withAnimation_, visibility) {
		var _v0 = _Utils_Tuple2(withAnimation_, visibility);
		if (_v0.a) {
			switch (_v0.b) {
				case 0:
					var _v1 = _v0.b;
					return 1;
				case 1:
					var _v2 = _v0.b;
					return 2;
				case 2:
					var _v3 = _v0.b;
					return 5;
				case 5:
					var _v4 = _v0.b;
					return 3;
				case 3:
					var _v5 = _v0.b;
					return 4;
				default:
					var _v6 = _v0.b;
					return 0;
			}
		} else {
			switch (_v0.b) {
				case 0:
					var _v7 = _v0.b;
					return 5;
				case 5:
					var _v8 = _v0.b;
					return 0;
				default:
					return 0;
			}
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$transitionHandler = F2(
	function (state, configRec) {
		return $elm$json$Json$Decode$succeed(
			configRec.fX(
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
					function (s) {
						return _Utils_update(
							s,
							{
								B: A2($frandibar$elm_bootstrap$Bootstrap$Navbar$visibilityTransition, configRec.bD, s.B)
							});
					},
					state)));
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$transitionStyle = function (maybeHeight) {
	var pixelHeight = A2(
		$elm$core$Maybe$withDefault,
		'0',
		A2(
			$elm$core$Maybe$map,
			function (v) {
				return $elm$core$String$fromFloat(v) + 'px';
			},
			maybeHeight));
	return _List_fromArray(
		[
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'position', 'relative'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', pixelHeight),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100%'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'overflow', 'hidden'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-webkit-transition-timing-function', 'ease'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-o-transition-timing-function', 'ease'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'transition-timing-function', 'ease'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-webkit-transition-duration', '0.35s'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-o-transition-duration', '0.35s'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'transition-duration', '0.35s'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-webkit-transition-property', 'height'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, '-o-transition-property', 'height'),
			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'transition-property', 'height')
		]);
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$menuAttributes = F2(
	function (state, configRec) {
		var visibility = state.B;
		var height = state.ip;
		var defaults = _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('collapse navbar-collapse')
			]);
		switch (visibility) {
			case 0:
				if (height.$ === 1) {
					return ((!configRec.bD) || A2($frandibar$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, configRec)) ? defaults : _List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', '0'),
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'overflow', 'hidden'),
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100%')
						]);
				} else {
					return defaults;
				}
			case 1:
				return $frandibar$elm_bootstrap$Bootstrap$Navbar$transitionStyle($elm$core$Maybe$Nothing);
			case 2:
				return _Utils_ap(
					$frandibar$elm_bootstrap$Bootstrap$Navbar$transitionStyle(height),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$Events$on,
							'transitionend',
							A2($frandibar$elm_bootstrap$Bootstrap$Navbar$transitionHandler, state, configRec))
						]));
			case 4:
				return _Utils_ap(
					$frandibar$elm_bootstrap$Bootstrap$Navbar$transitionStyle($elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$Events$on,
							'transitionend',
							A2($frandibar$elm_bootstrap$Bootstrap$Navbar$transitionHandler, state, configRec))
						]));
			case 3:
				return $frandibar$elm_bootstrap$Bootstrap$Navbar$transitionStyle(height);
			default:
				return _Utils_ap(
					defaults,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('show')
						]));
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$menuWrapperAttributes = F2(
	function (state, confRec) {
		var visibility = state.B;
		var height = state.ip;
		var styleBlock = _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100%')
			]);
		var display = function () {
			if (height.$ === 1) {
				return ((!confRec.bD) || A2($frandibar$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, confRec)) ? 'flex' : 'block';
			} else {
				return 'flex';
			}
		}();
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', display),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100%')
					]);
			case 1:
				return styleBlock;
			case 2:
				return styleBlock;
			case 4:
				return styleBlock;
			case 3:
				return styleBlock;
			default:
				return ((!confRec.bD) || A2($frandibar$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, confRec)) ? _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('collapse navbar-collapse show')
					]) : _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block')
					]);
		}
	});
var $rtfeldman$elm_css$Html$Styled$nav = $rtfeldman$elm_css$Html$Styled$node('nav');
var $rtfeldman$elm_css$Html$Styled$Attributes$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('sm');
		case 2:
			return $elm$core$Maybe$Just('md');
		case 3:
			return $elm$core$Maybe$Just('lg');
		default:
			return $elm$core$Maybe$Just('xl');
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$expandOption = function (size) {
	var toClass = function (sz) {
		return $rtfeldman$elm_css$Html$Styled$Attributes$class(
			'navbar-expand' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return '-' + s;
					},
					$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(sz))));
	};
	switch (size) {
		case 0:
			return _List_fromArray(
				[
					toClass(1)
				]);
		case 1:
			return _List_fromArray(
				[
					toClass(2)
				]);
		case 2:
			return _List_fromArray(
				[
					toClass(3)
				]);
		case 3:
			return _List_fromArray(
				[
					toClass(4)
				]);
		default:
			return _List_Nil;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$fixOption = function (fix) {
	if (!fix) {
		return 'fixed-top';
	} else {
		return 'fixed-bottom';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass = F2(
	function (prefix, role) {
		return $rtfeldman$elm_css$Html$Styled$Attributes$class(
			prefix + ('-' + function () {
				switch (role) {
					case 0:
						return 'primary';
					case 1:
						return 'secondary';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'warning';
					case 5:
						return 'danger';
					case 6:
						return 'light';
					default:
						return 'dark';
				}
			}()));
	});
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$backgroundColorOption = function (bgClass) {
	switch (bgClass.$) {
		case 0:
			var role = bgClass.a;
			return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role);
		case 1:
			var color = bgClass.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$Attributes$style,
				'background-color',
				$avh4$elm_color$Color$toCssString(color));
		default:
			var classString = bgClass.a;
			return $rtfeldman$elm_css$Html$Styled$Attributes$class(classString);
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$linkModifierClass = function (modifier) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		function () {
			if (!modifier) {
				return 'navbar-dark';
			} else {
				return 'navbar-light';
			}
		}());
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$schemeAttributes = function (_v0) {
	var modifier = _v0.e_;
	var bgColor = _v0.ec;
	return _List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Navbar$linkModifierClass(modifier),
			$frandibar$elm_bootstrap$Bootstrap$Navbar$backgroundColorOption(bgColor)
		]);
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$navbarAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('navbar', true),
						_Utils_Tuple2('container', options.eN)
					]))
			]),
		_Utils_ap(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$expandOption(options.dM),
			_Utils_ap(
				function () {
					var _v0 = options.fD;
					if (!_v0.$) {
						var scheme_ = _v0.a;
						return $frandibar$elm_bootstrap$Bootstrap$Navbar$schemeAttributes(scheme_);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v1 = options.bX;
						if (!_v1.$) {
							var fix = _v1.a;
							return _List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class(
									$frandibar$elm_bootstrap$Bootstrap$Navbar$fixOption(fix))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.d9))));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$renderCustom = function (items_) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var item = _v0;
			return item;
		},
		items_);
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus = F2(
	function (id, _v0) {
		var dropdowns = _v0.a7;
		return A2(
			$elm$core$Maybe$withDefault,
			2,
			A2($elm$core$Dict$get, id, dropdowns));
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$toggleOpen = F3(
	function (state, id, _v0) {
		var toMsg = _v0.fX;
		var currStatus = A2($frandibar$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus, id, state);
		var newStatus = function () {
			switch (currStatus) {
				case 0:
					return 2;
				case 1:
					return 2;
				default:
					return 0;
			}
		}();
		return toMsg(
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{
							a7: A3($elm$core$Dict$insert, id, newStatus, s.a7)
						});
				},
				state));
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$renderDropdownToggle = F4(
	function (state, id, configRec, _v0) {
		var attributes = _v0.d9;
		var children = _v0.h0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('nav-link dropdown-toggle'),
						$rtfeldman$elm_css$Html$Styled$Attributes$href('#'),
						A2(
						$rtfeldman$elm_css$Html$Styled$Events$custom,
						'click',
						$elm$json$Json$Decode$succeed(
							{
								li: A3($frandibar$elm_bootstrap$Bootstrap$Navbar$toggleOpen, state, id, configRec),
								lR: true,
								mg: false
							}))
					]),
				attributes),
			children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$renderDropdown = F3(
	function (state, configRec, _v0) {
		var ddRec = _v0;
		var needsDropup = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (fix) {
					if (fix === 1) {
						return true;
					} else {
						return false;
					}
				},
				configRec.iW.bX));
		var isShown = A2($frandibar$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus, ddRec.c$, state) !== 2;
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('nav-item', true),
							_Utils_Tuple2('dropdown', true),
							_Utils_Tuple2('shown', isShown),
							_Utils_Tuple2('dropup', needsDropup)
						]))
				]),
			_List_fromArray(
				[
					A4($frandibar$elm_bootstrap$Bootstrap$Navbar$renderDropdownToggle, state, ddRec.c$, configRec, ddRec.jL),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('dropdown-menu', true),
									_Utils_Tuple2('show', isShown)
								]))
						]),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var item = _v1;
							return item;
						},
						ddRec.k9))
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$renderItemLink = function (_v0) {
	var attributes = _v0.d9;
	var children = _v0.h0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('nav-item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_Utils_ap(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('nav-link')
						]),
					attributes),
				children)
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$renderNav = F3(
	function (state, configRec, navItems) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$ul,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('navbar-nav mr-auto')
				]),
			A2(
				$elm$core$List$map,
				function (item) {
					if (!item.$) {
						var item_ = item.a;
						return $frandibar$elm_bootstrap$Bootstrap$Navbar$renderItemLink(item_);
					} else {
						var dropdown_ = item.a;
						return A3($frandibar$elm_bootstrap$Bootstrap$Navbar$renderDropdown, state, configRec, dropdown_);
					}
				},
				navItems));
	});
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $frandibar$elm_bootstrap$Bootstrap$Navbar$heightDecoder = function () {
	var tagDecoder = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (tag, val) {
				return _Utils_Tuple2(tag, val);
			}),
		A2($elm$json$Json$Decode$field, 'tagName', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$value);
	var resToDec = function (res) {
		if (!res.$) {
			var v = res.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			var err = res.a;
			return $elm$json$Json$Decode$fail(
				$elm$json$Json$Decode$errorToString(err));
		}
	};
	var fromNavDec = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['childNodes', '2', 'childNodes', '0', 'offsetHeight']),
				$elm$json$Json$Decode$float),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['childNodes', '1', 'childNodes', '0', 'offsetHeight']),
				$elm$json$Json$Decode$float)
			]));
	var fromButtonDec = $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement(fromNavDec);
	return A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			var tag = _v0.a;
			var val = _v0.b;
			switch (tag) {
				case 'NAV':
					return resToDec(
						A2($elm$json$Json$Decode$decodeValue, fromNavDec, val));
				case 'BUTTON':
					return resToDec(
						A2($elm$json$Json$Decode$decodeValue, fromButtonDec, val));
				default:
					return $elm$json$Json$Decode$succeed(0);
			}
		},
		$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$target(
			$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement(tagDecoder)));
}();
var $frandibar$elm_bootstrap$Bootstrap$Navbar$toggleHandler = F2(
	function (state, configRec) {
		var height = state.ip;
		var updState = function (h) {
			return A2(
				$frandibar$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{
							ip: $elm$core$Maybe$Just(h),
							B: A2($frandibar$elm_bootstrap$Bootstrap$Navbar$visibilityTransition, configRec.bD, s.B)
						});
				},
				state);
		};
		return A2(
			$rtfeldman$elm_css$Html$Styled$Events$on,
			'click',
			A2(
				$elm$json$Json$Decode$andThen,
				function (v) {
					return $elm$json$Json$Decode$succeed(
						configRec.fX(
							(v > 0) ? updState(v) : updState(
								A2($elm$core$Maybe$withDefault, 0, height))));
				},
				$frandibar$elm_bootstrap$Bootstrap$Navbar$heightDecoder));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$type_ = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('type');
var $frandibar$elm_bootstrap$Bootstrap$Navbar$view = F2(
	function (state, conf) {
		var configRec = conf;
		return A2(
			$rtfeldman$elm_css$Html$Styled$nav,
			$frandibar$elm_bootstrap$Bootstrap$Navbar$navbarAttributes(configRec.iW),
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Navbar$maybeBrand(configRec.cL),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class(
									'navbar-toggler' + A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function (_v0) {
												return ' navbar-toggler-right';
											},
											configRec.cL))),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('button'),
									A2($frandibar$elm_bootstrap$Bootstrap$Navbar$toggleHandler, state, configRec)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('navbar-toggler-icon')
										]),
									_List_Nil)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							A2($frandibar$elm_bootstrap$Bootstrap$Navbar$menuAttributes, state, configRec),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									A2($frandibar$elm_bootstrap$Bootstrap$Navbar$menuWrapperAttributes, state, configRec),
									_Utils_ap(
										_List_fromArray(
											[
												A3($frandibar$elm_bootstrap$Bootstrap$Navbar$renderNav, state, configRec, configRec.k9)
											]),
										$frandibar$elm_bootstrap$Bootstrap$Navbar$renderCustom(configRec.eo)))
								]))
						]))));
	});
var $frandibar$elm_bootstrap$Bootstrap$Navbar$withAnimation = function (config_) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Navbar$updateConfig,
		function (conf) {
			return _Utils_update(
				conf,
				{bD: true});
		},
		config_);
};
var $author$project$Components$NavBar$view = F2(
	function (model, barElements) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Navbar$view,
			model.dj,
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Navbar$customItems,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Navbar$customItem(
						A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('nav navbar-nav ml-auto')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('nav-item')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$a,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('nav-link text-white btn'),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$NavBar$Logout)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('ログアウト')
												]))
										]))
								])))
					]),
				A3(
					$elm$core$Basics$apR,
					A2(
						$elm$core$List$map,
						function (_v0) {
							var label = _v0.iF;
							var url = _v0.dS;
							return A2(
								$frandibar$elm_bootstrap$Bootstrap$Navbar$itemLink,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href(url),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('text-white')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(label)
									]));
						},
						barElements),
					$frandibar$elm_bootstrap$Bootstrap$Navbar$items,
					$frandibar$elm_bootstrap$Bootstrap$Navbar$withAnimation(
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Navbar$attrs,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('navBar')
								]),
							$frandibar$elm_bootstrap$Bootstrap$Navbar$primary(
								$frandibar$elm_bootstrap$Bootstrap$Navbar$fixTop(
									$frandibar$elm_bootstrap$Bootstrap$Navbar$config($author$project$Components$NavBar$NavbarMsg))))))));
	});
var $author$project$Pages$Cms$cmsNavBar = function (navModel) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$map,
		$author$project$Pages$Cms$GotNavBarMsg,
		A2(
			$author$project$Components$NavBar$view,
			navModel,
			_List_fromArray(
				[
					{iF: 'Top', dS: '/cms'}
				])));
};
var $author$project$Page$RootPageRequired = F2(
	function (title, content) {
		return {ko: content, hx: title};
	});
var $author$project$Page$map = F2(
	function (f, rootPageRequired) {
		return A2(
			$author$project$Page$RootPageRequired,
			rootPageRequired.hx,
			A2($rtfeldman$elm_css$Html$Styled$map, f, rootPageRequired.ko));
	});
var $author$project$Pages$Cms$Others$EditMailAddress$OnChangeSelectedUser = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$align = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('align');
var $frandibar$elm_bootstrap$Bootstrap$Form$Attrs = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$attrs = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Html$Styled$form = $rtfeldman$elm_css$Html$Styled$node('form');
var $frandibar$elm_bootstrap$Bootstrap$Form$form = F2(
	function (attributes, children) {
		return A2($rtfeldman$elm_css$Html$Styled$form, attributes, children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$formInline = function (attributes) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$form(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$class('form-inline'),
			attributes));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				d9: _Utils_ap(options.d9, value)
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$defaultOptions = {d9: _List_Nil};
var $frandibar$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Form$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-group')
			]),
		options.d9);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			$frandibar$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $rtfeldman$elm_css$Html$Styled$label = $rtfeldman$elm_css$Html$Styled$node('label');
var $frandibar$elm_bootstrap$Bootstrap$Form$label = F2(
	function (attributes, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$label,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-control-label'),
				attributes),
			children);
	});
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.i);
	});
var $rtfeldman$elm_css$Css$animationDuration = function (arg) {
	return A2($rtfeldman$elm_css$Css$prop1, 'animation-duration', arg);
};
var $rtfeldman$elm_css$Css$animationIterationCount = function (arg) {
	return A2($rtfeldman$elm_css$Css$prop1, 'animation-iteration-count', arg);
};
var $rtfeldman$elm_css$Css$Preprocess$WithKeyframes = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$animationName = function (arg) {
	return ((arg.i === 'none') || ((arg.i === 'inherit') || ((arg.i === 'unset') || (arg.i === 'initial')))) ? A2($rtfeldman$elm_css$Css$prop1, 'animation-name', arg) : $rtfeldman$elm_css$Css$Preprocess$WithKeyframes(arg.i);
};
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$auto = {j3: 0, c: 0, bY: 0, eL: 0, la: 0, b6: 0, aQ: 0, au: 0, ce: 0, ak: 0, fT: 0, cD: 0, W: 0, i: 'auto'};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.i);
};
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.i, argB.i, argC.i])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$borderTop3 = $rtfeldman$elm_css$Css$prop3('border-top');
var $rtfeldman$elm_css$Css$bottom = $rtfeldman$elm_css$Css$prop1('bottom');
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$Css$fixed = {cJ: 0, dp: 0, fT: 0, i: 'fixed'};
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		bH: 1,
		gh: 0,
		gl: 0,
		gy: 0,
		ha: 0,
		i: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				bH: alpha / 255,
				gh: blue,
				gl: 0,
				gy: green,
				ha: red,
				i: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$Css$margin = $rtfeldman$elm_css$Css$prop1('margin');
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		bf: 0,
		au: 0,
		lu: 0,
		e0: 0,
		aU: val,
		cE: '',
		dQ: 0,
		i: $elm$core$String$fromFloat(val)
	};
};
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			hG: 0,
			hX: 0,
			bY: 0,
			H: 0,
			bd: 0,
			b6: 0,
			aQ: 0,
			b7: 0,
			b8: 0,
			be: 0,
			bf: 0,
			au: 0,
			aU: numericValue,
			cC: 0,
			cE: unitLabel,
			dQ: units,
			i: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			bH: alpha,
			gh: b,
			gl: 0,
			gy: g,
			ha: r,
			i: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $rtfeldman$elm_css$Css$right = $rtfeldman$elm_css$Css$prop1('right');
var $rtfeldman$elm_css$Css$sec = function (amount) {
	return {
		ib: 0,
		i: $elm$core$String$fromFloat(amount) + 's'
	};
};
var $rtfeldman$elm_css$Css$solid = {N: 0, bw: 0, i: 'solid'};
var $rtfeldman$elm_css$Css$Internal$printKeyframeSelector = function (_v0) {
	var percentage = _v0.a;
	var properties = _v0.b;
	var propertiesStr = A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			function (_v1) {
				var prop = _v1;
				return prop + ';';
			},
			properties));
	var percentageStr = $elm$core$String$fromInt(percentage) + '%';
	return percentageStr + (' {' + (propertiesStr + '}'));
};
var $rtfeldman$elm_css$Css$Internal$compileKeyframes = function (tuples) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Internal$printKeyframeSelector, tuples));
};
var $rtfeldman$elm_css$Css$Animations$keyframes = function (tuples) {
	return $elm$core$List$isEmpty(tuples) ? {gP: 0, gU: 0, i: 'none'} : {
		gP: 0,
		gU: 0,
		i: $rtfeldman$elm_css$Css$Internal$compileKeyframes(tuples)
	};
};
var $rtfeldman$elm_css$Css$Internal$Property = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Animations$property = F2(
	function (key, value) {
		return key + (':' + value);
	});
var $author$project$Components$Loading$spinKeyframe = $rtfeldman$elm_css$Css$Animations$keyframes(
	_List_fromArray(
		[
			_Utils_Tuple2(
			0,
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Animations$property, 'transform', 'rotate(0deg)')
				])),
			_Utils_Tuple2(
			100,
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Animations$property, 'transform', 'rotate(360deg)')
				]))
		]));
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $author$project$Components$Loading$loadingCenter = function (diameter) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
						$rtfeldman$elm_css$Css$top(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$left(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$bottom(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$right(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$backgroundColor(
						A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.3))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$left(
								$rtfeldman$elm_css$Css$pct(50)),
								A3(
								$rtfeldman$elm_css$Css$border3,
								$rtfeldman$elm_css$Css$px(diameter / 12),
								$rtfeldman$elm_css$Css$solid,
								$rtfeldman$elm_css$Css$hex('fff')),
								A3(
								$rtfeldman$elm_css$Css$borderTop3,
								$rtfeldman$elm_css$Css$px(diameter / 12),
								$rtfeldman$elm_css$Css$solid,
								$rtfeldman$elm_css$Css$hex('007bff')),
								$rtfeldman$elm_css$Css$borderRadius(
								$rtfeldman$elm_css$Css$pct(50)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$px(diameter)),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px(diameter)),
								$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$auto),
								$rtfeldman$elm_css$Css$animationName($author$project$Components$Loading$spinKeyframe),
								$rtfeldman$elm_css$Css$animationDuration(
								$rtfeldman$elm_css$Css$sec(2)),
								$rtfeldman$elm_css$Css$animationIterationCount(
								$rtfeldman$elm_css$Css$num(300))
							]))
					]),
				_List_Nil)
			]));
};
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $rtfeldman$elm_css$Html$Styled$table = $rtfeldman$elm_css$Html$Styled$node('table');
var $rtfeldman$elm_css$Html$Styled$tbody = $rtfeldman$elm_css$Html$Styled$node('tbody');
var $rtfeldman$elm_css$Html$Styled$th = $rtfeldman$elm_css$Html$Styled$node('th');
var $rtfeldman$elm_css$Html$Styled$thead = $rtfeldman$elm_css$Html$Styled$node('thead');
var $rtfeldman$elm_css$Html$Styled$tr = $rtfeldman$elm_css$Html$Styled$node('tr');
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$Attrs = function (a) {
	return {$: 6, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Select$Attrs(attrs_);
};
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$Item = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Html$Styled$option = $rtfeldman$elm_css$Html$Styled$node('option');
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$item = F2(
	function (attributes, children) {
		return A2($rtfeldman$elm_css$Html$Styled$option, attributes, children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$Select = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$create = F2(
	function (options, items) {
		return {k9: items, iW: options};
	});
var $rtfeldman$elm_css$Html$Styled$select = $rtfeldman$elm_css$Html$Styled$node('select');
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						jx: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						c$: $elm$core$Maybe$Just(id_)
					});
			case 2:
				return _Utils_update(
					options,
					{P: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{es: val});
			case 4:
				var onChange_ = modifier.a;
				return _Utils_update(
					options,
					{
						iS: $elm$core$Maybe$Just(onChange_)
					});
			case 5:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						f9: $elm$core$Maybe$Just(validation_)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$defaultOptions = {d9: _List_Nil, P: false, es: false, c$: $elm$core$Maybe$Nothing, iS: $elm$core$Maybe$Nothing, jx: $elm$core$Maybe$Nothing, f9: $elm$core$Maybe$Nothing};
var $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$disabled = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('disabled');
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute = F2(
	function (isCustom, size_) {
		var prefix = isCustom ? 'custom-select-' : 'form-control-';
		return A2(
			$elm$core$Maybe$map,
			function (s) {
				return $rtfeldman$elm_css$Html$Styled$Attributes$class(
					_Utils_ap(prefix, s));
			},
			$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size_));
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$validationAttribute = function (validation_) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		$frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation_));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Form$Select$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Form$Select$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-control', !options.P),
						_Utils_Tuple2('custom-select', options.P)
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(options.es)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Attributes$id, options.c$),
						A2(
						$elm$core$Maybe$andThen,
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute(options.P),
						options.jx),
						A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange, options.iS),
						A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Form$Select$validationAttribute, options.f9)
					])),
			options.d9));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$view = function (_v0) {
	var options = _v0.iW;
	var items = _v0.k9;
	return A2(
		$rtfeldman$elm_css$Html$Styled$select,
		$frandibar$elm_bootstrap$Bootstrap$Form$Select$toAttributes(options),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var e = _v1;
				return e;
			},
			items));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$select = F2(
	function (options, items) {
		return $frandibar$elm_bootstrap$Bootstrap$Form$Select$view(
			A2($frandibar$elm_bootstrap$Bootstrap$Form$Select$create, options, items));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$selected = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('selected');
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $author$project$Components$UserSelector$userSelector = F3(
	function (users, selectedUser, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedUser))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (user) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(user.jS)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedUser),
												user.jS))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(user.mD)
										]));
							},
							users)))
				]));
	});
var $author$project$Pages$Cms$Others$EditMailAddress$OnUpdateNewPermalinkGroupName = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$Others$EditMailAddress$SubmitAddPermalinkGroup = function (a) {
	return {$: 10, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						jx: $elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						aF: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{ef: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{es: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {d9: _List_Nil, ef: false, aF: $elm$core$Maybe$Nothing, es: false, jx: $elm$core$Maybe$Nothing};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.ef),
						_Utils_Tuple2('disabled', options.es)
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(options.es)
			]),
		_Utils_ap(
			function () {
				var _v0 = A2($elm$core$Maybe$andThen, $frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.jx);
				if (!_v0.$) {
					var s = _v0.a;
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.aF;
					if (!_v1.$) {
						if (!_v1.a.$) {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class(
									'btn-' + $frandibar$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class(
									'btn-outline-' + $frandibar$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.d9)));
};
var $frandibar$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			$frandibar$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $frandibar$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return $frandibar$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Primary = 0;
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Button$primary = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(0));
var $rtfeldman$elm_css$Html$Styled$td = $rtfeldman$elm_css$Html$Styled$node('td');
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Input = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			iW: A2(
				$elm$core$List$cons,
				$frandibar$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						jx: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						c$: $elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{dL: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{es: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						i: $elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						fk: $elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						aw: $elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						f9: $elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{fy: val});
			case 9:
				var val = modifier.a;
				return _Utils_update(
					options,
					{$7: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {d9: _List_Nil, es: false, c$: $elm$core$Maybe$Nothing, aw: $elm$core$Maybe$Nothing, fk: $elm$core$Maybe$Nothing, $7: false, fy: false, jx: $elm$core$Maybe$Nothing, dL: 0, f9: $elm$core$Maybe$Nothing, i: $elm$core$Maybe$Nothing};
var $rtfeldman$elm_css$Html$Styled$Attributes$placeholder = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('placeholder');
var $rtfeldman$elm_css$Html$Styled$Attributes$readonly = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('readOnly');
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return $rtfeldman$elm_css$Html$Styled$Attributes$class('form-control-' + s);
		},
		$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		$frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Form$Input$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(
				options.$7 ? 'form-control-plaintext' : 'form-control'),
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(options.es),
				$rtfeldman$elm_css$Html$Styled$Attributes$readonly(options.fy || options.$7),
				$frandibar$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.dL)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Attributes$id, options.c$),
						A2($elm$core$Maybe$andThen, $frandibar$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.jx),
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Attributes$value, options.i),
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Attributes$placeholder, options.fk),
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Events$onInput, options.aw),
						A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.f9)
					])),
			options.d9));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$view = function (_v0) {
	var options = _v0.iW;
	return A2(
		$rtfeldman$elm_css$Html$Styled$input,
		$frandibar$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return $frandibar$elm_bootstrap$Bootstrap$Form$Input$view(
			A2($frandibar$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$text = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(0);
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var $author$project$Pages$Cms$Others$EditMailAddress$viewAddPermalinkGroup = function (permalinkGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(permalinkGroup.aV),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Others$EditMailAddress$OnUpdateNewPermalinkGroupName)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							permalinkGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Others$EditMailAddress$SubmitAddPermalinkGroup(permalinkGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Add')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Others$EditMailAddress$OnUpdatePermalinkGroupName = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Cms$Others$EditMailAddress$SubmitDeletePermalinkGroup = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Cms$Others$EditMailAddress$SubmitUpdatePermalinkGroup = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Others$EditMailAddress$viewPermalinkGroup = function (permalinkGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(permalinkGroup.R))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(permalinkGroup.aV),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Others$EditMailAddress$OnUpdatePermalinkGroupName(permalinkGroup.R))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							permalinkGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Others$EditMailAddress$SubmitUpdatePermalinkGroup(permalinkGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Update')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
								$author$project$Pages$Cms$Others$EditMailAddress$SubmitDeletePermalinkGroup(permalinkGroup.R))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Delete')
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Others$EditMailAddress$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- CRUD table users mailaddress')
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('User')
									])),
								A3(
								$author$project$Components$UserSelector$userSelector,
								model.jT,
								model.hk,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Others$EditMailAddress$OnChangeSelectedUser)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Id')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Name')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Update')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Delete')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							$author$project$Pages$Cms$Others$EditMailAddress$viewAddPermalinkGroup(model.cc),
							A2($elm$core$List$map, $author$project$Pages$Cms$Others$EditMailAddress$viewPermalinkGroup, model.A)))
					]))
			]));
};
var $author$project$Pages$Cms$Others$EditMailAddress$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Others$EditMailAddress$viewContent(model),
		hx: 'Mailing List'
	};
};
var $author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedDateFrom = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedDateTo = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Others$GetAccessCount$OnSend = {$: 4};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 10, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Date = 3;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$date = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(3);
var $rtfeldman$elm_css$Html$Styled$Attributes$for = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('htmlFor');
var $author$project$Pages$Cms$Others$GetAccessCount$userNameOfId = F2(
	function (maybeUserId, users) {
		if (!maybeUserId.$) {
			var userId = maybeUserId.a;
			return 'ユーザ : ' + A2(
				$elm$core$Maybe$withDefault,
				{jS: -1, mD: ''},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (user) {
							return _Utils_eq(user.jS, userId);
						},
						users))).mD;
		} else {
			return '';
		}
	});
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Pages$Cms$Others$GetAccessCount$viewTrEachDay = function (accessCountsEachDays) {
	var extractDate = A2(
		$elm$regex$Regex$replace,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('T.*')),
		function (_v0) {
			return '';
		});
	var rowOfDay = function (accessCountsEachDay) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							extractDate(
								A2($elm$core$Maybe$withDefault, '', accessCountsEachDay.hH)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, 0, accessCountsEachDay.cH)))
						]))
				]));
	};
	return A2($elm$core$List$map, rowOfDay, accessCountsEachDays);
};
var $author$project$Pages$Cms$Others$GetAccessCount$viewTrTotal = function (accessCountsTotal) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('合計')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(
								A2($elm$core$Maybe$withDefault, 0, accessCountsTotal.cH)))
						]))
				]))
		]);
};
var $author$project$Pages$Cms$Others$GetAccessCount$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				model.b2 ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('User')
									])),
								A3(
								$author$project$Components$UserSelector$userSelector,
								model.jT,
								model.hk,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedUser)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('From')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
										A2($elm$core$Maybe$withDefault, '', model.du)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedDateFrom)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('To')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
										A2($elm$core$Maybe$withDefault, '', model.dv)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Others$GetAccessCount$OnChangeSelectedDateTo)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Others$GetAccessCount$OnSend)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($author$project$Pages$Cms$Others$GetAccessCount$userNameOfId, model.bE.hC, model.jT))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('日付')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('アクセス回数')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						_Utils_ap(
							$author$project$Pages$Cms$Others$GetAccessCount$viewTrEachDay(model.bE.gd),
							$author$project$Pages$Cms$Others$GetAccessCount$viewTrTotal(model.bE.gc)))
					]))
			]));
};
var $author$project$Pages$Cms$Others$GetAccessCount$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Others$GetAccessCount$viewContent(model),
		hx: 'アクセス数カウント'
	};
};
var $author$project$Pages$Cms$Products$AddProductConfig$Add = {$: 6};
var $author$project$Pages$Cms$Products$AddProductConfig$AddChangeJan = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$Products$AddProductConfig$AddChangeKataban = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Products$AddProductConfig$OnChangeMaker = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Html$Styled$hr = $rtfeldman$elm_css$Html$Styled$node('hr');
var $author$project$Components$MakerSelector$makerSelector = F3(
	function (makers, selectedMaker, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedMaker))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (maker) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(maker.iJ)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedMaker),
												maker.iJ))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(maker.lg)
										]));
							},
							makers)))
				]));
	});
var $author$project$Pages$Cms$Products$AddProductConfig$OnChangeBigCategory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$Products$AddProductConfig$OnChangeSmallCategory = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$Products$AddProductConfig$OnChangeUserId = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$CategorySelector$bigCategorySelector = F3(
	function (bigCategories, selectedBigCategory, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, selectedBigCategory),
										-1))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (bigCategory) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(bigCategory.ed)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedBigCategory),
												bigCategory.ed))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(bigCategory.kb)
										]));
							},
							bigCategories)))
				]));
	});
var $author$project$Components$CategorySelector$smallCategorySelector = F4(
	function (smallCategories, selectedSmallCategory, selectedBigCategory, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, selectedSmallCategory),
										-1))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (smallCategory) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(smallCategory.ho)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedSmallCategory),
												smallCategory.ho))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(smallCategory.mc)
										]));
							},
							A2(
								$elm$core$List$filter,
								function (smallCategory) {
									return _Utils_eq(
										selectedBigCategory,
										$elm$core$Maybe$Just(-1)) || _Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, selectedBigCategory),
										smallCategory.ed);
								},
								smallCategories))))
				]));
	});
var $author$project$Pages$Cms$Products$AddProductConfig$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('')
							])),
						A3(
						$author$project$Components$UserSelector$userSelector,
						model.jT,
						model.fJ,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$AddProductConfig$OnChangeUserId, $elm$core$String$toInt))
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$AddProductConfig$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$AddProductConfig$OnChangeSmallCategory)
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$AddProductConfig$viewContent = function (model) {
	var addkataban = A2(
		$rtfeldman$elm_css$Html$Styled$input,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
				$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Input 型番'),
				$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bG),
				$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$AddProductConfig$AddChangeKataban)
			]),
		_List_Nil);
	var addJan = A2(
		$rtfeldman$elm_css$Html$Styled$input,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
				$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Input JAN-CODE'),
				$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bF),
				$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$AddProductConfig$AddChangeJan)
			]),
		_List_Nil);
	var add = A2(
		$frandibar$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$AddProductConfig$Add)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('　　add　　')
			]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('JANコード')
											])),
										addJan
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('　型番：')
											])),
										addkataban
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('メーカー')
											])),
										A3(
										$author$project$Components$MakerSelector$makerSelector,
										model.ca,
										model.dw,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$AddProductConfig$OnChangeMaker)
											]))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$AddProductConfig$viewProductFilter(model),
						A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
						(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
						(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							])) : A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[add]))
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$AddProductConfig$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$AddProductConfig$viewContent(model),
		hx: '商品の追加'
	};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeUserId = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ユーザ')
							])),
						A3(
						$author$project$Components$UserSelector$userSelector,
						model.jT,
						model.fJ,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeUserId, $elm$core$String$toInt))
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$ChangeDisplayInterval$OnChangeSmallCategory)
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateAllProducts = {$: 9};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateAnyPoint = function (a) {
	return {$: 18, a: a};
};
var $rtfeldman$elm_css$Html$Styled$br = $rtfeldman$elm_css$Html$Styled$node('br');
var $rtfeldman$elm_css$Html$Styled$Attributes$max = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('max');
var $rtfeldman$elm_css$Html$Styled$Attributes$min = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('min');
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Number = 7;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$number = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(7);
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateDisplayInterval = F3(
	function (a, b, c) {
		return {$: 19, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateInterval = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateProduct = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Utils$Helpers$calculatePriceWithoutPoint = F2(
	function (price, point) {
		return ((price * (100 - point)) / 100) | 0;
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$create = F2(
	function (options, label_) {
		return {iF: label_, iW: options};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Label = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$label = F2(
	function (attributes, children) {
		return {d9: attributes, h0: children};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						c$: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{o: val});
			case 2:
				return _Utils_update(
					options,
					{c0: true});
			case 3:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						e7: $elm$core$Maybe$Just(toMsg)
					});
			case 4:
				return _Utils_update(
					options,
					{P: true});
			case 5:
				var val = modifier.a;
				return _Utils_update(
					options,
					{es: val});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						f9: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Off = 1;
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions = {d9: _List_Nil, P: false, es: false, c$: $elm$core$Maybe$Nothing, c0: false, e7: $elm$core$Maybe$Nothing, o: 1, f9: $elm$core$Maybe$Nothing};
var $rtfeldman$elm_css$Html$Styled$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $rtfeldman$elm_css$Html$Styled$Events$onCheck = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetChecked));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$attribute = $rtfeldman$elm_css$VirtualDom$Styled$attribute;
var $rtfeldman$elm_css$Html$Styled$Attributes$checked = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('checked');
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute = function (state) {
	switch (state) {
		case 0:
			return $rtfeldman$elm_css$Html$Styled$Attributes$checked(true);
		case 1:
			return $rtfeldman$elm_css$Html$Styled$Attributes$checked(false);
		default:
			return A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'indeterminate', 'true');
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.P),
						_Utils_Tuple2('custom-control-input', options.P)
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox'),
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(options.es),
				$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute(options.o)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Events$onCheck, options.e7),
						A2($elm$core$Maybe$map, $rtfeldman$elm_css$Html$Styled$Attributes$id, options.c$)
					])),
			_Utils_ap(
				function () {
					var _v0 = options.f9;
					if (!_v0.$) {
						var v = _v0.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								$frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(v))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				options.d9)));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$view = function (_v0) {
	var chk = _v0;
	var opts = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions, chk.iW);
	var _v1 = chk.iF;
	var label_ = _v1;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.P),
						_Utils_Tuple2('form-check-inline', (!opts.P) && opts.c0),
						_Utils_Tuple2('custom-control', opts.P),
						_Utils_Tuple2('custom-checkbox', opts.P),
						_Utils_Tuple2('custom-control-inline', opts.c0 && opts.P)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes(opts),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_Utils_ap(
					label_.d9,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.P),
										_Utils_Tuple2('custom-control-label', opts.P)
									]))
							]),
						function () {
							var _v2 = opts.c$;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.h0)
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox = F2(
	function (options, labelText) {
		return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$create,
				options,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$label,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(labelText)
						]))));
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$On = 0;
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Value = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked = function (isCheck) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Value(
		isCheck ? 0 : 1);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Disabled = function (a) {
	return {$: 5, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled = function (disabled_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Disabled(disabled_);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$draggable = $rtfeldman$elm_css$VirtualDom$Styled$attribute('draggable');
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$DragStart = F3(
	function (a, b, c) {
		return {$: 14, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$Drop = {$: 17};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Cms$Products$ChangeDisplayInterval$Drop),
		lG: $author$project$Pages$Cms$Products$ChangeDisplayInterval$DragStart(ind)
	};
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$DragOver = F3(
	function (a, b, c) {
		return {$: 16, a: a, b: b, c: c};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$MoveOnDrop = 1;
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Cms$Products$ChangeDisplayInterval$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Cms$Products$ChangeDisplayInterval$DragOver(ind)
	};
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledAttribute = function (prop) {
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, prop, _List_Nil, '');
};
var $rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledAttribute;
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$intToOption = F2(
	function (selected_v, v) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$option,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$value(
					$elm$core$String$fromInt(v)),
					$rtfeldman$elm_css$Html$Styled$Attributes$selected(
					_Utils_eq(v, selected_v))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$elm$core$String$fromInt(v))
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked = function (a) {
	return {$: 3, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck = function (toMsg) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked(toMsg);
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$Event = F2(
	function (dataTransfer, mouseEvent) {
		return {kv: dataTransfer, lq: mouseEvent};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$DataTransfer = F3(
	function (files, types, dropEffect) {
		return {kF: dropEffect, kP: files, dO: types};
	});
var $elm$file$File$decoder = _File_decoder;
var $mpizenberg$elm_pointer_events$Internal$Decode$all = A2(
	$elm$core$List$foldr,
	$elm$json$Json$Decode$map2($elm$core$List$cons),
	$elm$json$Json$Decode$succeed(_List_Nil));
var $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf = function (itemDecoder) {
	var decodeOne = function (n) {
		return A2(
			$elm$json$Json$Decode$field,
			$elm$core$String$fromInt(n),
			itemDecoder);
	};
	var decodeN = function (n) {
		return $mpizenberg$elm_pointer_events$Internal$Decode$all(
			A2(
				$elm$core$List$map,
				decodeOne,
				A2($elm$core$List$range, 0, n - 1)));
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeN,
		A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$int));
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$fileListDecoder = $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$dataTransferDecoder = A4(
	$elm$json$Json$Decode$map3,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$DataTransfer,
	A2(
		$elm$json$Json$Decode$field,
		'files',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$fileListDecoder($elm$file$File$decoder)),
	A2(
		$elm$json$Json$Decode$field,
		'types',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'dropEffect', $elm$json$Json$Decode$string));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event = F6(
	function (keys, button, clientPos, offsetPos, pagePos, screenPos) {
		return {kd: button, kk: clientPos, lb: keys, lv: offsetPos, lK: pagePos, l4: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton = 4;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton = 0;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton = 5;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton = 1;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton = 2;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton = 3;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId = function (id) {
	switch (id) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		default:
			return 0;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder = A2(
	$elm$json$Json$Decode$map,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId,
	A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
var $mpizenberg$elm_pointer_events$Internal$Decode$clientPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$Keys = F3(
	function (alt, ctrl, shift) {
		return {j5: alt, kt: ctrl, ma: shift};
	});
var $mpizenberg$elm_pointer_events$Internal$Decode$keys = A4(
	$elm$json$Json$Decode$map3,
	$mpizenberg$elm_pointer_events$Internal$Decode$Keys,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'offsetX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'offsetY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$pagePos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$screenPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'screenX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'screenY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder = A7($elm$json$Json$Decode$map6, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event, $mpizenberg$elm_pointer_events$Internal$Decode$keys, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder, $mpizenberg$elm_pointer_events$Internal$Decode$clientPos, $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos, $mpizenberg$elm_pointer_events$Internal$Decode$pagePos, $mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$eventDecoder = A3(
	$elm$json$Json$Decode$map2,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$Event,
	A2($elm$json$Json$Decode$field, 'dataTransfer', $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$dataTransferDecoder),
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on = F2(
	function (event, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						li: tag(ev),
						lR: true,
						mg: true
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$valuePreventedOn = F2(
	function (event, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (value) {
					return {
						li: tag(value),
						lR: true,
						mg: true
					};
				},
				$elm$json$Json$Decode$value));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget = function (config) {
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				$elm$core$Maybe$Just(
				A2(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$valuePreventedOn,
					'dragover',
					config.lF(config.kF))),
				$elm$core$Maybe$Just(
				A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on, 'drop', config.lA)),
				A2(
				$elm$core$Maybe$map,
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on('dragenter'),
				config.lC),
				A2(
				$elm$core$Maybe$map,
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on('dragleave'),
				config.lD)
			]));
};
var $elm$html$Html$Attributes$draggable = _VirtualDom_attribute('draggable');
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$valueOn = F2(
	function (event, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (value) {
					return {
						li: tag(value),
						lR: false,
						mg: true
					};
				},
				$elm$json$Json$Decode$value));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag = function (config) {
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				$elm$core$Maybe$Just(
				$elm$html$Html$Attributes$draggable('true')),
				$elm$core$Maybe$Just(
				A2(
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$valueOn,
					'dragstart',
					config.lG(config.kG))),
				$elm$core$Maybe$Just(
				A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on, 'dragend', config.lB)),
				A2(
				$elm$core$Maybe$map,
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$on('drag'),
				config.lw)
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Cms$Products$ChangeDisplayInterval$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Cms$Products$ChangeDisplayInterval$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									$author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateInterval(ind)),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Pages$Cms$Products$ChangeDisplayInterval$intToOption(product.$9),
								_List_fromArray(
									[15, 60, 360])))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
									A2($elm$core$List$member, 60, product.kC)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(60 <= product.$9),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									A2($author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateDisplayInterval, ind, 60))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
									A2($elm$core$List$member, 360, product.kC)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(360 <= product.$9),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									A2($author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateDisplayInterval, ind, 360))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(base_price_without_point)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 101) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 102) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 103) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 105) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 107) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 110) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							function () {
								if (!anyPoint.$) {
									var point = anyPoint.a;
									return $author$project$Utils$Helpers$toCommmaSplited(
										$elm$core$String$fromInt(((base_price_without_point * (100 + point)) / 100) | 0));
								} else {
									return '';
								}
							}())
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$viewTable = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は想定売価（税抜き）です')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$ChangeDisplayInterval$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										function () {
										var intervalSum = $author$project$Pages$Cms$Products$ChangeDisplayInterval$getIntervalSum(model.ja);
										return A2(
											$rtfeldman$elm_css$Html$Styled$th,
											(intervalSum > 120) ? _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'color', '#f33'),
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'background-color', '#fb4')
												]) : _List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													(intervalSum > 120) ? _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('取得間隔'),
															A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
															$rtfeldman$elm_css$Html$Styled$text('【上限超過】')
														]) : _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('取得間隔')
														])),
													A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$core$String$fromInt(
																$author$project$Pages$Cms$Products$ChangeDisplayInterval$getIntervalSum(model.ja)) + '/120')
														]))
												]));
									}(),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示間隔'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('【60】')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示間隔'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('【360】')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込 P後')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('1%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('2%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('3%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('5%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('7%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('10%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('small float-left')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('任意%入力')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$ChangeDisplayInterval$UpdateAnyPoint),
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
														_List_fromArray(
															[
																A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px'),
																$rtfeldman$elm_css$Html$Styled$Attributes$max('100'),
																$rtfeldman$elm_css$Html$Styled$Attributes$min('0')
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヨドバシ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Cms$Products$ChangeDisplayInterval$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Cms$Products$ChangeDisplayInterval$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$ChangeDisplayInterval$viewTable(model)
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayInterval$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$ChangeDisplayInterval$viewContent(model),
		hx: '商品の表示間隔の追加設定'
	};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$OnChangeUserId = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ユーザ')
							])),
						A3(
						$author$project$Components$UserSelector$userSelector,
						model.jT,
						model.fJ,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$ChangeDisplayOrder$OnChangeUserId, $elm$core$String$toInt))
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$OnUpdateAllProducts = {$: 4};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$OnUpdateOrder = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$viewTr = F2(
	function (product, ind) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.lU)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '70px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeDisplayOrder$OnUpdateOrder(ind))
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$viewTable = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 表示順を表す数値を入力し，更新を押すことで表示順を変更できます')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 文字列を消しても空欄にならず「0」が必ず残ってしまいます．仕様です．')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 表示順は被らないように入力してください')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$ChangeDisplayOrder$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示順')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A2($author$project$Pages$Cms$Products$ChangeDisplayOrder$viewTr, product, ind);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Cms$Products$ChangeDisplayOrder$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$ChangeDisplayOrder$viewTable(model)
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Cms$Products$ChangeDisplayOrder$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$ChangeDisplayOrder$viewContent(model),
		hx: '商品の表示順の設定'
	};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnChangeUserId = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ユーザ')
							])),
						A3(
						$author$project$Components$UserSelector$userSelector,
						model.jT,
						model.fJ,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$ChangeUserProduct$OnChangeUserId, $elm$core$String$toInt))
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateAllProducts = {$: 15};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnAddProduct = {$: 13};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateAddProductId = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayFlag = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateGetFlag = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateInterval = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateProductOrder = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$intToOption = F2(
	function (selected_v, v) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$option,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$value(
					$elm$core$String$fromInt(v)),
					$rtfeldman$elm_css$Html$Styled$Attributes$selected(
					_Utils_eq(v, selected_v))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$elm$core$String$fromInt(v))
				]));
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$addUserProduct = function (product) {
	var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(product.i8),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateAddProductId)
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$select,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateInterval(-1)),
								A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
							]),
						A2(
							$elm$core$List$map,
							$author$project$Pages$Cms$Products$ChangeUserProduct$intToOption(product.$9),
							_List_fromArray(
								[0, 15, 60, 360])))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(
										$elm$core$String$fromInt(product.ka)),
										A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePrice(-1))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(
										$elm$core$String$fromInt(product.j9)),
										A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePoint(-1))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(
										$elm$core$String$fromInt(product.lU)),
										A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateProductOrder(-1))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(base_price_without_point)))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.aP),
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateGetFlag(-1))
							]),
						'')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.aI),
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayFlag(-1))
							]),
						'')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
								$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateYodobashiFlag(-1))
							]),
						'')
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$ChangeUserProduct$OnAddProduct)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('追加')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnDeleteProduct = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayInterval = F3(
	function (a, b, c) {
		return {$: 25, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateProduct = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 20, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$Drop = {$: 23};
var $author$project$Pages$Cms$Products$ChangeUserProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Cms$Products$ChangeUserProduct$Drop),
		lG: $author$project$Pages$Cms$Products$ChangeUserProduct$DragStart(ind)
	};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 22, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Cms$Products$ChangeUserProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Cms$Products$ChangeUserProduct$DragOver(ind)
	};
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					function () {
						if (!draggingProductInd.$) {
							var draggingInd = draggingProductInd.a;
							return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
						} else {
							return '';
						}
					}())
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_Utils_ap(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true')
									]),
								_Utils_ap(
									A2(
										$elm$core$List$map,
										$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
										$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
											$author$project$Pages$Cms$Products$ChangeUserProduct$dropSourceConfig(ind))),
									A2(
										$elm$core$List$map,
										$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
										$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
											$author$project$Pages$Cms$Products$ChangeUserProduct$dropTargetConfig(ind))))),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('⇅')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.i8)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateInterval(ind)),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Pages$Cms$Products$ChangeUserProduct$intToOption(product.$9),
								_List_fromArray(
									[0, 15, 60, 360])))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
									A2($elm$core$List$member, 60, product.kC)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(60 <= product.$9),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									A2($author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayInterval, ind, 60))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
									A2($elm$core$List$member, 360, product.kC)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(360 <= product.$9),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									A2($author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayInterval, ind, 360))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.lU)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateProductOrder(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(base_price_without_point)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.aP),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateGetFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.aI),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateDisplayFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.cG),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(true)
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Products$ChangeUserProduct$OnDeleteProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('削除')
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Products$ChangeUserProduct$viewTable = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は想定売価（税抜き）です')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、ドラッグボタンをドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$ChangeUserProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ドラッグ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('JANコード')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										function () {
										var intervalSum = $author$project$Pages$Cms$Products$ChangeUserProduct$getIntervalSum(model.ja);
										return A2(
											$rtfeldman$elm_css$Html$Styled$th,
											(intervalSum > 120) ? _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'color', '#f33'),
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'background-color', '#fb4')
												]) : _List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													(intervalSum > 120) ? _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('取得間隔'),
															A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
															$rtfeldman$elm_css$Html$Styled$text('【上限超過】')
														]) : _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('取得間隔')
														])),
													A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$core$String$fromInt(
																$author$project$Pages$Cms$Products$ChangeUserProduct$getIntervalSum(model.ja)) + '/120')
														]))
												]));
									}(),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示間隔'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('【60】')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示間隔'),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$rtfeldman$elm_css$Html$Styled$text('【360】')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示順')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込 P後')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得フラグ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示フラグ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヨドバシフラグ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('week30フラグ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('削除')
											]))
									]))
							])),
						$author$project$Pages$Cms$Products$ChangeUserProduct$addUserProduct(model.Y),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Cms$Products$ChangeUserProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Cms$Products$ChangeUserProduct$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$ChangeUserProduct$viewTable(model)
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Cms$Products$ChangeUserProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$ChangeUserProduct$viewContent(model),
		hx: 'ユーザーごとの商品変更'
	};
};
var $author$project$Pages$Cms$Products$EditProducts$OnChangeProductId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$EditProducts$OnChangeProductName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$EditProducts$Send = {$: 5};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Growing = 1;
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Kind = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$grow = $frandibar$elm_bootstrap$Bootstrap$Spinner$Kind(1);
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Large = 2;
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Size = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$large = $frandibar$elm_bootstrap$Bootstrap$Spinner$Size(2);
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Placeholder = function (a) {
	return {$: 7, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder = function (value_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Input$Placeholder(value_);
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var spinnerKind = modifier.a;
				return _Utils_update(
					options,
					{da: spinnerKind});
			case 1:
				var spinnerSize = modifier.a;
				return _Utils_update(
					options,
					{jx: spinnerSize});
			case 2:
				var color_ = modifier.a;
				return _Utils_update(
					options,
					{
						gl: $elm$core$Maybe$Just(color_)
					});
			default:
				var list = modifier.a;
				return _Utils_update(
					options,
					{d9: list});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Border = 0;
var $frandibar$elm_bootstrap$Bootstrap$Spinner$Normal = 0;
var $frandibar$elm_bootstrap$Bootstrap$Spinner$defaultOptions = {d9: _List_Nil, gl: $elm$core$Maybe$Nothing, da: 0, jx: 0};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$kindClassName = function (kind_) {
	if (!kind_) {
		return 'spinner-border';
	} else {
		return 'spinner-grow';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$kindClass = A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Html$Styled$Attributes$class, $frandibar$elm_bootstrap$Bootstrap$Spinner$kindClassName);
var $frandibar$elm_bootstrap$Bootstrap$Spinner$sizeAttributes = F2(
	function (size_, kind_) {
		switch (size_) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class(
							$frandibar$elm_bootstrap$Bootstrap$Spinner$kindClassName(kind_) + '-sm')
						]));
			default:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '3rem'),
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', '3rem')
						]));
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Text$textColorClass = function (color) {
	if (color.$ === 1) {
		return $rtfeldman$elm_css$Html$Styled$Attributes$class('text-white');
	} else {
		var role = color.a;
		return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'text', role);
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$toAttributes = function (options) {
	return _Utils_ap(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$frandibar$elm_bootstrap$Bootstrap$Spinner$kindClass(options.da)),
					A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Internal$Text$textColorClass, options.gl)
				])),
		_Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($frandibar$elm_bootstrap$Bootstrap$Spinner$sizeAttributes, options.jx, options.da)),
			_Utils_ap(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'status')
					]),
				options.d9)));
};
var $frandibar$elm_bootstrap$Bootstrap$Spinner$spinner = F2(
	function (options, children) {
		var opts = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Spinner$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Spinner$defaultOptions, options);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			$frandibar$elm_bootstrap$Bootstrap$Spinner$toAttributes(opts),
			children);
	});
var $author$project$Pages$Cms$Products$EditProducts$viewContent = function (model) {
	if (model.eJ) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Spinner$spinner,
			_List_fromArray(
				[$frandibar$elm_bootstrap$Bootstrap$Spinner$grow, $frandibar$elm_bootstrap$Bootstrap$Spinner$large]),
			_List_Nil);
	} else {
		var sendButton = A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$EditProducts$Send)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('送信')
				]));
		var modelNumText = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input Model-Number'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.c2),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$EditProducts$OnChangeProductName)
				]));
		var janText = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input JAN-CODE'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.c1),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$EditProducts$OnChangeProductId)
				]));
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
				]),
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('JANコード')
								])),
							janText
						])),
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('型番')
								])),
							modelNumText
						])),
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[sendButton]))
				]));
	}
};
var $author$project$Pages$Cms$Products$EditProducts$OnUpdateMaker = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$EditProducts$OnUpdateProductName = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$EditProducts$OnUpdateSmallCategory = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$EditProducts$SubmitUpdateProductGeneralInformations = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Products$EditProducts$viewTr = F4(
	function (productsGeneralInformation, index, makers, small_categories) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(productsGeneralInformation.g4)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(productsGeneralInformation.fm),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$EditProducts$OnUpdateProductName(index))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput(
											function (makerIdString) {
												return A2(
													$author$project$Pages$Cms$Products$EditProducts$OnUpdateMaker,
													index,
													A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(makerIdString)));
											})
										]))
								]),
							A2(
								$elm$core$List$map,
								function (maker) {
									return A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(
												$elm$core$String$fromInt(maker.bj)),
												$rtfeldman$elm_css$Html$Styled$Attributes$selected(
												_Utils_eq(productsGeneralInformation.bj, maker.bj))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(maker.iK)
											]));
								},
								makers))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput(
											function (scIdString) {
												return A2(
													$author$project$Pages$Cms$Products$EditProducts$OnUpdateSmallCategory,
													index,
													A2(
														$elm$core$Maybe$withDefault,
														0,
														$elm$core$String$toInt(scIdString)));
											})
										]))
								]),
							A2(
								$elm$core$List$map,
								function (small_category) {
									return A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(
												$elm$core$String$fromInt(small_category.bv)),
												$rtfeldman$elm_css$Html$Styled$Attributes$selected(
												_Utils_eq(productsGeneralInformation.bv, small_category.bv))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												small_category.hZ + ('  >  ' + (small_category.hS + ('  >  ' + A3($elm$core$String$replace, '-', 'ー', small_category.jA)))))
											]));
								},
								small_categories))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_Utils_ap(
								productsGeneralInformation.at ? _List_fromArray(
									[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
										$author$project$Pages$Cms$Products$EditProducts$SubmitUpdateProductGeneralInformations(index))
									])),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Update')
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Products$EditProducts$viewTable = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('JANCODE')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('メーカー名')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('小分類')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Update')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (ind, perm) {
									return A4($author$project$Pages$Cms$Products$EditProducts$viewTr, perm, ind, model.ca, model.jy);
								}),
							model.ao))
					]))
			]));
};
var $author$project$Pages$Cms$Products$EditProducts$viewContents = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$EditProducts$viewContent(model)
					])),
				model.eQ ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$EditProducts$viewTable(model)
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Products$EditProducts$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$EditProducts$viewContents(model),
		hx: '商品の型番，メーカー，小分類の変更'
	};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeProductId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeProductName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$Send = {$: 6};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$viewContent = function (model) {
	if (model.eJ) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Spinner$spinner,
			_List_fromArray(
				[$frandibar$elm_bootstrap$Bootstrap$Spinner$grow, $frandibar$elm_bootstrap$Bootstrap$Spinner$large]),
			_List_Nil);
	} else {
		var sendButton = A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$EditProductsPermalinks$Send)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('送信')
				]));
		var modelNumText = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input Model-Number'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dy),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeProductName)
				]));
		var janText = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input JAN-CODE'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dx),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeProductId)
				]));
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
				]),
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('JANコード')
								])),
							janText
						])),
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('型番')
								])),
							modelNumText
						])),
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
								]))
						]),
					_List_fromArray(
						[sendButton]))
				]));
	}
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$Nop = {$: 13};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeView = function (a) {
	return {$: 11, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode = $rtfeldman$elm_css$VirtualDom$Styled$Unstyled;
var $rtfeldman$elm_css$Html$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$OnChange = function (a) {
	return {$: 4, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange = function (toMsg) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Select$OnChange(toMsg);
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Utils$Helpers$preventDefaultEnter = function (nop) {
	var filterKey = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(0) : $elm$json$Json$Decode$fail('ignore');
	};
	var decoder = A2(
		$elm$json$Json$Decode$map,
		function (_v0) {
			return {li: nop, lR: true, mg: true};
		},
		A2($elm$json$Json$Decode$andThen, filterKey, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
	return A2($rtfeldman$elm_css$Html$Styled$Events$custom, 'keydown', decoder);
};
var $inkuzmin$elm_multiselect$Multiselect$ClickOnComponent = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$Focused = 1;
var $inkuzmin$elm_multiselect$Multiselect$Toggle = {$: 4};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$borderColor3 = F3(
	function (c1, c2, c3) {
		var value = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[c1.i, c2.i, c3.i]));
		return A2($rtfeldman$elm_css$Css$property, 'border-color', value);
	});
var $rtfeldman$elm_css$Css$borderLeftWidth = $rtfeldman$elm_css$Css$prop1('border-left-width');
var $rtfeldman$elm_css$Css$borderRightWidth = $rtfeldman$elm_css$Css$prop1('border-right-width');
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$Css$inlineBlock = {m: 0, i: 'inline-block'};
var $rtfeldman$elm_css$Css$relative = {dp: 0, i: 'relative'};
var $rtfeldman$elm_css$Css$transparent = {gl: 0, i: 'transparent'};
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$zero = {bd: 0, b6: 0, aQ: 0, b7: 0, b8: 0, be: 0, bf: 0, lu: 0, aU: 0, fh: 0, cE: '', dQ: 0, i: '0'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#999'),
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-2.5))
		]));
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $rtfeldman$elm_css$Css$pointer = {c: 0, i: 'pointer'};
var $rtfeldman$elm_css$Css$tableCell = {m: 0, i: 'table-cell'};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(25)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$px(5))
		]));
var $inkuzmin$elm_multiselect$Multiselect$withOptions = F2(
	function (options, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			function (m) {
				return {li: m, lR: options.lR, mg: options.mg};
			},
			decoder);
	});
var $inkuzmin$elm_multiselect$Multiselect$onClickNoDefault = function (message) {
	var config = {lR: true, mg: true};
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'click',
		A2(
			$inkuzmin$elm_multiselect$Multiselect$withOptions,
			config,
			$elm$json$Json$Decode$succeed(message)));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$rel = $rtfeldman$elm_css$VirtualDom$Styled$attribute('rel');
var $inkuzmin$elm_multiselect$Multiselect$arrow = function (_v0) {
	var model = _v0;
	var arrowRel = (model.ac === 2) ? 'arrowUpside' : 'arrow';
	var arrowCss = (model.ac === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow]);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Toggle)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(arrowCss),
						$rtfeldman$elm_css$Html$Styled$Attributes$rel(arrowRel)
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$Clear = {$: 7};
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clear = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(18)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1))
		]));
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.i);
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(17)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('#D0021B'))
				]))
		]));
var $inkuzmin$elm_multiselect$Multiselect$clear = function (_v0) {
	var model = _v0;
	return (!$elm$core$List$isEmpty(model.jo)) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Clear)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clear]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('×')
					]))
			])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
};
var $rtfeldman$elm_css$Css$table = {m: 0, i: 'table'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$container = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(4)),
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#d9d9d9'),
			$rtfeldman$elm_css$Css$hex('#ccc'),
			$rtfeldman$elm_css$Css$hex('#b3b3b3')),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px(34)),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$table)
		]));
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.i);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom = function (p) {
	return A2($rtfeldman$elm_css$Css$property, 'box-shadow', p);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderColor(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			$inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom('inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)')
		]));
var $inkuzmin$elm_multiselect$Multiselect$Adjust = function (a) {
	return {$: 11, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Filter = function (a) {
	return {$: 10, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Shortcut = function (a) {
	return {$: 13, a: a};
};
var $rtfeldman$elm_css$Css$border = $rtfeldman$elm_css$Css$prop1('border');
var $rtfeldman$elm_css$Css$initial = {hL: 0, v: 0, cJ: 0, hP: 0, bK: 0, hQ: 0, bL: 0, aD: 0, N: 0, eg: 0, gl: 0, c: 0, m: 0, bY: 0, gv: 0, cV: 0, ey: 0, ba: 0, H: 0, bZ: 0, h: 0, aN: 0, eL: 0, iE: 0, gP: 0, bd: 0, b6: 0, aQ: 0, b7: 0, b8: 0, be: 0, bf: 0, au: 0, eW: 0, e: 0, d: 0, gU: 0, lu: 0, aU: 0, fh: 0, ce: 0, ak: 0, fT: 0, cB: 0, bw: 0, cC: 0, cD: 0, bx: 0, W: 0, cE: '', dQ: 0, i: 'initial', B: 0, a1: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{i: 'inherit'});
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		aN: 0,
		eL: 0,
		bf: 0,
		au: 0,
		lu: 0,
		e0: 0,
		aU: val,
		cE: '',
		dQ: 0,
		i: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$none = {bK: 0, hV: 0, N: 0, c: 0, m: 0, k0: 0, iv: 0, gP: 0, b8: 0, be: 0, au: 0, e: 0, d: 0, gU: 0, fh: 0, lN: 0, ak: 0, fz: 0, l5: 0, cB: 0, bx: 0, W: 0, f_: 0, mA: 0, i: 'none'};
var $rtfeldman$elm_css$Css$outlineStyle = $rtfeldman$elm_css$Css$prop1('outline-style');
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$input = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(14)),
			$rtfeldman$elm_css$Css$outlineStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$paddingTop(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $rtfeldman$elm_css$Css$absolute = {dp: 0, i: 'absolute'};
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.i;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$normal = {gu: 0, bZ: 0, aN: 0, gZ: 0, i: 'normal', a1: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $rtfeldman$elm_css$Css$scroll = {cJ: 0, hV: 0, iv: 0, ce: 0, l6: 0, i: 'scroll'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$left(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$scroll),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1))
		]));
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$paddingBottom(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$previousSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'previousSibling', decoder);
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyDown = function (tagger) {
	var domF = $debois$elm_dom$DOM$target(
		$debois$elm_dom$DOM$previousSibling($debois$elm_dom$DOM$offsetWidth));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$map, tagger, domF));
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyPress = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyUp = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $inkuzmin$elm_multiselect$Multiselect$Start = {$: 0};
var $inkuzmin$elm_multiselect$Multiselect$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons = function () {
	var options = {lR: true, mg: false};
	var filterKey = function (code) {
		return (_Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow) || _Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) ? $elm$core$Result$Ok(code) : $elm$core$Result$Err('ignored input');
	};
	var decoder = A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$always($inkuzmin$elm_multiselect$Multiselect$Start),
		A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeR, filterKey, $inkuzmin$elm_multiselect$Multiselect$fromResult),
			$rtfeldman$elm_css$Html$Styled$Events$keyCode));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'keydown',
		A2($inkuzmin$elm_multiselect$Multiselect$withOptions, options, decoder));
}();
var $rtfeldman$elm_css$Html$Styled$Attributes$property = $rtfeldman$elm_css$VirtualDom$Styled$property;
var $inkuzmin$elm_multiselect$Multiselect$input = function (_v0) {
	var model = _v0;
	var w = $elm$core$String$fromFloat(model.gI + 23.0);
	var inputStyle = A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', w + 'px');
	var forceClear = function () {
		var _v1 = model.ix;
		if (_v1.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$Attributes$value('');
		} else {
			return $rtfeldman$elm_css$Html$Styled$Attributes$classList(_List_Nil);
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons,
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ix))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectInput' + model.u),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$input])),
						$inkuzmin$elm_multiselect$Multiselect$onKeyDown($inkuzmin$elm_multiselect$Multiselect$Adjust),
						$inkuzmin$elm_multiselect$Multiselect$onKeyPress($inkuzmin$elm_multiselect$Multiselect$Shortcut),
						$inkuzmin$elm_multiselect$Multiselect$onKeyUp($inkuzmin$elm_multiselect$Multiselect$Filter),
						inputStyle,
						A2(
						$rtfeldman$elm_css$Html$Styled$Attributes$property,
						'type',
						$elm$json$Json$Encode$string('text')),
						forceClear
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$OnHover = function (a) {
	return {$: 12, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$borderBottomLeftRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-left-radius');
var $rtfeldman$elm_css$Css$borderBottomRightRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-right-radius');
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.i);
};
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.i, argB.i, argC.i, argD.i])));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menu = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomRightRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$borderBottomLeftRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderTopColor(
			$rtfeldman$elm_css$Css$hex('#e6e6e6')),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$zero,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.06)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(-1)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
		]));
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.i, argB.i])));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#666')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(8),
			$rtfeldman$elm_css$Css$px(10)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight))
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333'))
		]));
var $rtfeldman$elm_css$Html$Styled$Events$onMouseOver = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$menu = function (_v0) {
	var model = _v0;
	var _v1 = model.ac;
	if (_v1 === 2) {
		var hovered = function () {
			var _v3 = model.l;
			if (_v3.$ === 1) {
				return '';
			} else {
				var _v4 = _v3.a;
				var id = _v4.a;
				return id;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menu])),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectMenu' + model.u)
				]),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var name = _v2.a;
					var value = _v2.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_Utils_eq(name, hovered) ? _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered, $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem]) : _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem])),
								$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault(
								$inkuzmin$elm_multiselect$Multiselect$OnSelect(
									_Utils_Tuple2(name, value))),
								$rtfeldman$elm_css$Html$Styled$Events$onMouseOver(
								$inkuzmin$elm_multiselect$Multiselect$OnHover(
									_Utils_Tuple2(name, value)))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(value)
							]));
				},
				model.k));
	} else {
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero)
		]));
var $inkuzmin$elm_multiselect$Multiselect$RemoveItem = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$border2 = $rtfeldman$elm_css$Css$prop2('border');
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tag = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			A2(
			$rtfeldman$elm_css$Css$border2,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(2)),
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$em(0.9)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.4)),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
		]));
var $rtfeldman$elm_css$Css$borderRight3 = $rtfeldman$elm_css$Css$prop3('border-right');
var $rtfeldman$elm_css$Css$padding3 = $rtfeldman$elm_css$Css$prop3('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					$rtfeldman$elm_css$Css$hex('#d8eafd'))
				])),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			A3(
			$rtfeldman$elm_css$Css$padding3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(3)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tag = F2(
	function (name, value) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tag]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$inkuzmin$elm_multiselect$Multiselect$RemoveItem(
								_Utils_Tuple2(name, value)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('×')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$inline = {m: 0, i: 'inline'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inline)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tags = function (_v0) {
	var model = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap]))
			]),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var name = _v1.a;
				var value = _v1.b;
				return A2($inkuzmin$elm_multiselect$Multiselect$tag, name, value);
			},
			model.jo));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $inkuzmin$elm_multiselect$Multiselect$styledView = function (_v0) {
	var model = _v0;
	var inputCss = (model.ac === 1) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused]) : ((model.ac === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick($inkuzmin$elm_multiselect$Multiselect$ClickOnComponent)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(inputCss)
					]),
				_List_fromArray(
					[
						$inkuzmin$elm_multiselect$Multiselect$tags(model),
						$inkuzmin$elm_multiselect$Multiselect$input(model),
						$inkuzmin$elm_multiselect$Multiselect$clear(model),
						$inkuzmin$elm_multiselect$Multiselect$arrow(model)
					])),
				$inkuzmin$elm_multiselect$Multiselect$menu(model)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$view = A2($elm$core$Basics$composeR, $inkuzmin$elm_multiselect$Multiselect$styledView, $rtfeldman$elm_css$Html$Styled$toUnstyled);
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangePermalink = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$OnUpdatePermalink = function (a) {
	return {$: 10, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$target = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var $author$project$Pages$Cms$Products$EditProductsPermalinks$viewTr = F2(
	function (sendResponse, index) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(sendResponse.ja.fm)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(sendResponse.E.y))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href(sendResponse.dS),
									$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(sendResponse.E.iX)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(sendResponse.fj),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangePermalink(index))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Products$EditProductsPermalinks$OnUpdatePermalink(index))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Products$EditProductsPermalinks$viewTable = function (model) {
	var userSelector = A3(
		$author$project$Components$UserSelector$userSelector,
		model.jT,
		model.fJ,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Events$onInput(
				A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeUserId, $elm$core$String$toInt))
			]));
	var usePermalinkInfos = $elm$core$List$isEmpty(
		$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? (model.eM ? model.aY : model.x) : A3($author$project$Pages$Cms$Products$EditProductsPermalinks$filterBySelectedStores, model.x, model.ap, model.r);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ユーザ')
									])),
								userSelector
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('表示')
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange(
										A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeView, $elm$core$String$toInt))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value('0')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全て表示')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value('1')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得量販店のみ表示')
											]))
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3'),
										$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Cms$Products$EditProductsPermalinks$Nop)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('量販店\u3000(プルダウンは矢印クリックで消えます(仮))')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer text-left'),
										$rtfeldman$elm_css$Html$Styled$Attributes$class(
										model.gb ? 'multiSelectWarning' : ''),
										A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '400px')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Pages$Cms$Products$EditProductsPermalinks$OnChangeStore,
										$rtfeldman$elm_css$Html$Styled$fromUnstyled(
											$inkuzmin$elm_multiselect$Multiselect$view(model.r))),
										$elm$core$List$isEmpty(
										$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? A2(
										$rtfeldman$elm_css$Html$Styled$span,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全て選択')
											])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ID')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('グループ名')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('パーマリンク')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (ind, perm) {
									return A2($author$project$Pages$Cms$Products$EditProductsPermalinks$viewTr, perm, ind);
								}),
							usePermalinkInfos))
					]))
			]));
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$viewContents = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$EditProductsPermalinks$viewContent(model)
					])),
				model.eQ ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Cms$Products$EditProductsPermalinks$viewTable(model)
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Products$EditProductsPermalinks$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$EditProductsPermalinks$viewContents(model),
		hx: 'パーマリンクの追加'
	};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$Add = {$: 1};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$AddChange = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$Remove = {$: 2};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$RmChange = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$renderProducts = function (lst) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (l) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(l.i8),
									$rtfeldman$elm_css$Html$Styled$text('  '),
									$rtfeldman$elm_css$Html$Styled$text(l.lT)
								]))
						]));
			},
			lst));
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$viewContent = function (model) {
	if (model.eJ) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Spinner$spinner,
			_List_fromArray(
				[$frandibar$elm_bootstrap$Bootstrap$Spinner$grow, $frandibar$elm_bootstrap$Bootstrap$Spinner$large]),
			_List_Nil);
	} else {
		var removeText = A2(
			$rtfeldman$elm_css$Html$Styled$input,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
					$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Input JAN-CODE'),
					$rtfeldman$elm_css$Html$Styled$Attributes$value(model.co),
					$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$RakutenExcludedItems$RmChange)
				]),
			_List_Nil);
		var remove = A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Cms$Products$RakutenExcludedItems$Remove)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('remove')
				]));
		var products = $author$project$Pages$Cms$Products$RakutenExcludedItems$renderProducts(model.ja);
		var addText = A2(
			$rtfeldman$elm_css$Html$Styled$input,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
					$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Input JAN-CODE'),
					$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bF),
					$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$RakutenExcludedItems$AddChange)
				]),
			_List_Nil);
		var add = A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Cms$Products$RakutenExcludedItems$Add)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('add')
				]));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[addText, add])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[removeText, remove])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[products]))
				]));
	}
};
var $author$project$Pages$Cms$Products$RakutenExcludedItems$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$RakutenExcludedItems$viewContent(model),
		hx: 'Linuxで楽天から取得しない商品の設定'
	};
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnChangeBigCategory = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnPressCsvUpload = {$: 0};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var $author$project$Components$Loading$loading = function (diameter) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						A3(
						$rtfeldman$elm_css$Css$border3,
						$rtfeldman$elm_css$Css$px(diameter / 12),
						$rtfeldman$elm_css$Css$solid,
						$rtfeldman$elm_css$Css$hex('f3f3f3')),
						A3(
						$rtfeldman$elm_css$Css$borderTop3,
						$rtfeldman$elm_css$Css$px(diameter / 12),
						$rtfeldman$elm_css$Css$solid,
						$rtfeldman$elm_css$Css$hex('333')),
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px(diameter)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(diameter)),
						$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$auto),
						$rtfeldman$elm_css$Css$animationName($author$project$Components$Loading$spinKeyframe),
						$rtfeldman$elm_css$Css$animationDuration(
						$rtfeldman$elm_css$Css$sec(1)),
						A2($rtfeldman$elm_css$Css$property, 'animation-iteration-count', 'infinite'),
						A2($rtfeldman$elm_css$Css$property, 'animation-timing-function', 'linear')
					]))
			]),
		_List_Nil);
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Success = 2;
var $frandibar$elm_bootstrap$Bootstrap$Button$success = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(2));
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Warning = 4;
var $frandibar$elm_bootstrap$Bootstrap$Button$warning = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(4));
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('大分類')
									])),
								A3(
								$author$project$Components$CategorySelector$bigCategorySelector,
								model.hR,
								model.he,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnChangeBigCategory)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Succeeded!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Press to Upload')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$viewContent(model),
		hx: 'Netgate/日立 商品の順序の変更'
	};
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnPressCsvUpload = {$: 0};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Succeeded!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Press to Upload')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$viewContent(model),
		hx: 'Netgate/日立 今週の商品の変更'
	};
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnPressCsvUpload = {$: 0};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Succeeded!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Press to Upload')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$viewContent(model),
		hx: 'Netgate/Sharp 今週の商品の変更'
	};
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnPressCsvUpload = {$: 0};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Success!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('アップロード')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$viewContent(model),
		hx: 'Netgate/Tescom 今週の商品の変更(CSV upload)'
	};
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnPressCsvUpload = {$: 0};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Success!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateTletProductsByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('アップロード')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateTletProductsByCsv$viewContent(model),
		hx: 'Netgate/東芝LET 今週の商品の変更(CSV upload)'
	};
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnPressCsvUpload = {$: 0};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-2')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])),
				function () {
				var _v0 = _Utils_Tuple2(model.b9, model.mo);
				if (_v0.a) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$warning,
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Loading$loading(23)
							]));
				} else {
					if (_v0.b) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$success,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Succeeded!')
								]));
					} else {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-1 w-25')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Products$UpdateWeek30ByCsv$OnPressCsvUpload)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Press to Upload')
								]));
					}
				}
			}()
			]));
};
var $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UpdateWeek30ByCsv$viewContent(model),
		hx: 'Netgate/キャノン 今週の商品の変更(CSV upload)'
	};
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$viewUserPages = function (model) {
	var _v0 = model.fJ;
	_v0$6:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a) {
				case 1:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateWeek30ByCsvEndpoints.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('キャノン 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateTletProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('東芝LET 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateTescomProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Tescom 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateSharpProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Sharp 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateHitachiWeeklyProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('日立 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateHitachiProductsOrder.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('日立 商品順序更新')
											]))
									]))
							]));
				case 2:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateHitachiWeeklyProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('日立 今週の品目更新')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateHitachiProductsOrder.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('日立 商品順序更新')
											]))
									]))
							]));
				case 6:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateWeek30ByCsvEndpoints.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('キャノン 今週の品目更新')
											]))
									]))
							]));
				case 16:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateTletProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('東芝LET 今週の品目更新')
											]))
									]))
							]));
				case 23:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateTescomProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Tescom 今週の品目更新')
											]))
									]))
							]));
				case 31:
					return A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$updateSharpProducts.j)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Sharp 今週の品目更新')
											]))
									]))
							]));
				default:
					break _v0$6;
			}
		} else {
			break _v0$6;
		}
	}
	return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$OnChangeUserId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$viewUserSelector = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ユーザ')
							])),
						A3(
						$author$project$Components$UserSelector$userSelector,
						model.jT,
						model.fJ,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Pages$Cms$Products$UserSpecificRouting$OnChangeUserId, $elm$core$String$toInt))
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- adminを選択すると一覧が表示されます')
							]))
					])),
				$author$project$Pages$Cms$Products$UserSpecificRouting$viewUserSelector(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				$author$project$Pages$Cms$Products$UserSpecificRouting$viewUserPages(model)
			]));
};
var $author$project$Pages$Cms$Products$UserSpecificRouting$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Products$UserSpecificRouting$viewContent(model),
		hx: 'ユーザーごとの作業'
	};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$OnUpdateNewPermalinkGroupName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitAddPermalinkGroup = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$viewAddPermalinkGroup = function (permalinkGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(permalinkGroup.aV),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Stores$EditPermalinkGroups$OnUpdateNewPermalinkGroupName)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							permalinkGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitAddPermalinkGroup(permalinkGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Add')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$OnUpdatePermalinkGroupName = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitDeletePermalinkGroup = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitUpdatePermalinkGroup = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$viewPermalinkGroup = function (permalinkGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(permalinkGroup.R))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(permalinkGroup.aV),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Stores$EditPermalinkGroups$OnUpdatePermalinkGroupName(permalinkGroup.R))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							permalinkGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitUpdatePermalinkGroup(permalinkGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Update')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
								$author$project$Pages$Cms$Stores$EditPermalinkGroups$SubmitDeletePermalinkGroup(permalinkGroup.R))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Delete')
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- CRUD table permalink_groups')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Id')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Name')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Update')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Delete')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							$author$project$Pages$Cms$Stores$EditPermalinkGroups$viewAddPermalinkGroup(model.cc),
							A2($elm$core$List$map, $author$project$Pages$Cms$Stores$EditPermalinkGroups$viewPermalinkGroup, model.A)))
					]))
			]));
};
var $author$project$Pages$Cms$Stores$EditPermalinkGroups$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$EditPermalinkGroups$viewContent(model),
		hx: 'Permalink Group'
	};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$OnUpdateNewStoreGroupName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$SubmitAddStoreGroup = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$viewAddStoreGroup = function (storeGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(storeGroup.mi),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Stores$EditStoreGroups$OnUpdateNewStoreGroupName)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							storeGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Stores$EditStoreGroups$SubmitAddStoreGroup(storeGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Add')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$OnUpdateStoreGroupName = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreGroups$SubmitDeleteStoreGroup = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$SubmitUpdateStoreGroup = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$viewStoreGroup = function (storeGroup) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(storeGroup.mh))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(storeGroup.mi),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								$author$project$Pages$Cms$Stores$EditStoreGroups$OnUpdateStoreGroupName(storeGroup.mh))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_Utils_ap(
							storeGroup.at ? _List_fromArray(
								[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Stores$EditStoreGroups$SubmitUpdateStoreGroup(storeGroup))
								])),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Update')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
								$author$project$Pages$Cms$Stores$EditStoreGroups$SubmitDeleteStoreGroup(storeGroup.mh))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Delete')
							]))
					]))
			]));
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- CRUD table store_groups')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Id')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Name')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Update')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Delete')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							$author$project$Pages$Cms$Stores$EditStoreGroups$viewAddStoreGroup(model.cd),
							A2($elm$core$List$map, $author$project$Pages$Cms$Stores$EditStoreGroups$viewStoreGroup, model.U)))
					]))
			]));
};
var $author$project$Pages$Cms$Stores$EditStoreGroups$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$EditStoreGroups$viewContent(model),
		hx: 'Store Group'
	};
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs = function (a) {
	return {$: 2, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs(attrs_);
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return $frandibar$elm_bootstrap$Bootstrap$Grid$Column(
			{h0: children, iW: options});
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$container = F2(
	function (attributes, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('container')
					]),
				attributes),
			children);
	});
var $elm$virtual_dom$VirtualDom$lazy6 = _VirtualDom_lazy6;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp5 = F6(
	function (fn, arg1, arg2, arg3, arg4, arg5) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			A5(fn, arg1, arg2, arg3, arg4, arg5));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy5 = F6(
	function (fn, arg1, arg2, arg3, arg4, arg5) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A7($elm$virtual_dom$VirtualDom$lazy6, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp5, fn, arg1, arg2, arg3, arg4, arg5));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy5 = $rtfeldman$elm_css$VirtualDom$Styled$lazy5;
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {h2: columnCount, jn: screenSize};
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _v0 = align_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						d5: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						d3: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						d2: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						d1: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						d4: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _v0 = offset_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						e6: $elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						e3: $elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						e2: $elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						e1: $elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						e5: $elm$core$Maybe$Just(offset_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _v0 = order_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fe: $elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fc: $elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fb: $elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						fa: $elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						fd: $elm$core$Maybe$Just(order_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _v0 = pull_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fs: $elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fq: $elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fp: $elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						fo: $elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						fr: $elm$core$Maybe$Just(pull_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _v0 = push_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fx: $elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fv: $elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fu: $elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						ft: $elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						fw: $elm$core$Maybe$Just(push_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _v0 = width_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						dY: $elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						dW: $elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						dV: $elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						dU: $elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						dX: $elm$core$Maybe$Just(width_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						fU: $elm$core$Maybe$Just(align)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('1');
		case 2:
			return $elm$core$Maybe$Just('2');
		case 3:
			return $elm$core$Maybe$Just('3');
		case 4:
			return $elm$core$Maybe$Just('4');
		case 5:
			return $elm$core$Maybe$Just('5');
		case 6:
			return $elm$core$Maybe$Just('6');
		case 7:
			return $elm$core$Maybe$Just('7');
		case 8:
			return $elm$core$Maybe$Just('8');
		case 9:
			return $elm$core$Maybe$Just('9');
		case 10:
			return $elm$core$Maybe$Just('10');
		case 11:
			return $elm$core$Maybe$Just('11');
		case 12:
			return $elm$core$Maybe$Just('12');
		default:
			return $elm$core$Maybe$Just('auto');
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_v0) {
	var screenSize = _v0.jn;
	var columnCount = _v0.h2;
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		'col' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, width_, widths));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {d1: $elm$core$Maybe$Nothing, d2: $elm$core$Maybe$Nothing, d3: $elm$core$Maybe$Nothing, d4: $elm$core$Maybe$Nothing, d5: $elm$core$Maybe$Nothing, d9: _List_Nil, e1: $elm$core$Maybe$Nothing, e2: $elm$core$Maybe$Nothing, e3: $elm$core$Maybe$Nothing, e5: $elm$core$Maybe$Nothing, e6: $elm$core$Maybe$Nothing, fa: $elm$core$Maybe$Nothing, fb: $elm$core$Maybe$Nothing, fc: $elm$core$Maybe$Nothing, fd: $elm$core$Maybe$Nothing, fe: $elm$core$Maybe$Nothing, fo: $elm$core$Maybe$Nothing, fp: $elm$core$Maybe$Nothing, fq: $elm$core$Maybe$Nothing, fr: $elm$core$Maybe$Nothing, fs: $elm$core$Maybe$Nothing, ft: $elm$core$Maybe$Nothing, fu: $elm$core$Maybe$Nothing, fv: $elm$core$Maybe$Nothing, fw: $elm$core$Maybe$Nothing, fx: $elm$core$Maybe$Nothing, fU: $elm$core$Maybe$Nothing, dU: $elm$core$Maybe$Nothing, dV: $elm$core$Maybe$Nothing, dW: $elm$core$Maybe$Nothing, dX: $elm$core$Maybe$Nothing, dY: $elm$core$Maybe$Nothing};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _v0 = $frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_v0.$) {
		var s = _v0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_v0) {
	var screenSize = _v0.jn;
	var offsetCount = _v0.iR;
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		'offset' + ($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, offset_, offsets));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.jn;
			var moveCount = m.a.cb;
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class(
					'order' + ($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, order_, orders));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.jn;
			var moveCount = m.a.cb;
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class(
					'pull' + ($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, pull_, pulls));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.jn;
			var moveCount = m.a.cb;
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class(
					'push' + ($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, push_, pushes));
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_v0) {
	var dir = _v0.h5;
	var size = _v0.jx;
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		'text' + (A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + $frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _v0) {
		var align = _v0.hK;
		var screenSize = _v0.jn;
		return $rtfeldman$elm_css$Html$Styled$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				$elm$core$Maybe$map,
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, align, aligns));
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !$elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[options.dY, options.dW, options.dV, options.dU, options.dX])));
	return _Utils_ap(
		$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? $elm$core$Maybe$Just(
					A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.dY,
					options.dW,
					options.dV,
					options.dU,
					options.dX
				])),
		_Utils_ap(
			$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.e6, options.e3, options.e2, options.e1, options.e5])),
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.fs, options.fq, options.fp, options.fo, options.fr])),
				_Utils_ap(
					$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.fx, options.fv, options.fu, options.ft, options.fw])),
					_Utils_ap(
						$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.fe, options.fc, options.fb, options.fa, options.fd])),
						_Utils_ap(
							A2(
								$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.d5, options.d3, options.d2, options.d1, options.d4])),
							_Utils_ap(
								function () {
									var _v0 = options.fU;
									if (!_v0.$) {
										var a = _v0.a;
										return _List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.d9)))))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$keyedNode = $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode;
var $rtfeldman$elm_css$Html$Styled$Keyed$node = $rtfeldman$elm_css$VirtualDom$Styled$keyedNode;
var $frandibar$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.iW;
			var children = column.a.h0;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.iW;
			var children = column.a.h0;
			return A3(
				$rtfeldman$elm_css$Html$Styled$Keyed$node,
				'div',
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _v0 = align.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						eE: $elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						eC: $elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						eB: $elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						eA: $elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						eD: $elm$core$Maybe$Just(align)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _v0 = align_.jn;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						f8: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						f5: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						f4: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						f7: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {d9: _List_Nil, eA: $elm$core$Maybe$Nothing, eB: $elm$core$Maybe$Nothing, eC: $elm$core$Maybe$Nothing, eD: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing, f4: $elm$core$Maybe$Nothing, f5: $elm$core$Maybe$Nothing, f6: $elm$core$Maybe$Nothing, f7: $elm$core$Maybe$Nothing, f8: $elm$core$Maybe$Nothing};
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var $frandibar$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_v0) {
	var align = _v0.hK;
	var screenSize = _v0.jn;
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		'justify-content-' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + $frandibar$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, align, aligns));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.f8, options.f6, options.f5, options.f4, options.f7])),
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.eE, options.eC, options.eB, options.eA, options.eD])),
				options.d9)));
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnClickApplyButton = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnClickDeleteButton = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnClickResetButton = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateConstantPoint = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateConvertTypeId = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateDriverId = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePointRegex = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePointSelector = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePriceRegex = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePriceSelector = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePropertyName = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSleepTime = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSoldoutRegex = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSoldoutSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$Config = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$mapOptions = F2(
	function (mapper, _v0) {
		var conf = _v0;
		var options = conf.iW;
		return _Utils_update(
			conf,
			{
				iW: mapper(options)
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup = $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$mapOptions(
	function (opts) {
		return _Utils_update(
			opts,
			{eO: true});
	});
var $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Config = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$CardBlock = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$applyBlockModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 0:
				var align = option.a;
				return _Utils_update(
					options,
					{
						aB: $elm$core$Maybe$Just(align)
					});
			case 1:
				var role = option.a;
				return _Utils_update(
					options,
					{
						aF: $elm$core$Maybe$Just(role)
					});
			case 2:
				var color = option.a;
				return _Utils_update(
					options,
					{
						a_: $elm$core$Maybe$Just(color)
					});
			default:
				var attrs = option.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$defaultBlockOptions = {aB: $elm$core$Maybe$Nothing, d9: _List_Nil, aF: $elm$core$Maybe$Nothing, a_: $elm$core$Maybe$Nothing};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$blockAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Card$Internal$applyBlockModifier, $frandibar$elm_bootstrap$Bootstrap$Card$Internal$defaultBlockOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('card-body')
			]),
		_Utils_ap(
			function () {
				var _v0 = options.aB;
				if (!_v0.$) {
					var align = _v0.a;
					return _List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(align)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.aF;
					if (!_v1.$) {
						var role = _v1.a;
						return _List_fromArray(
							[
								A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v2 = options.a_;
						if (!_v2.$) {
							var color = _v2.a;
							return _List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Internal$Text$textColorClass(color)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.d9))));
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$block = F2(
	function (options, items) {
		return $frandibar$elm_bootstrap$Bootstrap$Card$Internal$CardBlock(
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				$frandibar$elm_bootstrap$Bootstrap$Card$Internal$blockAttributes(options),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var e = _v0;
						return e;
					},
					items)));
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$block = F3(
	function (options, items, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				gg: _Utils_ap(
					conf.gg,
					_List_fromArray(
						[
							A2($frandibar$elm_bootstrap$Bootstrap$Card$Internal$block, options, items)
						]))
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig = F2(
	function (mapper, _v0) {
		var configRec = _v0;
		return mapper(configRec);
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children = function (children_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig(
		function (conf) {
			return _Utils_update(
				conf,
				{h0: children_});
		});
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Col = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Form$col = F2(
	function (options, children) {
		return {h0: children, ic: $rtfeldman$elm_css$Html$Styled$div, iW: options};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$colLabel = F2(
	function (options, children) {
		return {
			h0: children,
			ic: $rtfeldman$elm_css$Html$Styled$label,
			iW: A2(
				$elm$core$List$cons,
				$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('col-form-label')
						])),
				options)
		};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$colLabelSm = function (options) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$colLabel(
		A2(
			$elm$core$List$cons,
			$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('col-form-label-sm')
					])),
			options));
};
var $frandibar$elm_bootstrap$Bootstrap$Card$config = function (options) {
	return {gg: _List_Nil, gw: $elm$core$Maybe$Nothing, eH: $elm$core$Maybe$Nothing, gD: $elm$core$Maybe$Nothing, gE: $elm$core$Maybe$Nothing, iW: options};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config = {
	h0: _List_Nil,
	eU: $elm$core$Maybe$Nothing,
	iW: {d9: _List_Nil, es: false, eO: false}
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$BlockItem = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Card$Block$custom = function (element) {
	return element;
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Danger = 5;
var $frandibar$elm_bootstrap$Bootstrap$Button$danger = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(5));
var $frandibar$elm_bootstrap$Bootstrap$Card$Header = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Card$headerPrivate = F4(
	function (elemFn, attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				eH: $elm$core$Maybe$Just(
					A2(
						elemFn,
						A2(
							$elm$core$List$cons,
							$rtfeldman$elm_css$Html$Styled$Attributes$class('card-header'),
							attributes),
						children))
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$header = $frandibar$elm_bootstrap$Bootstrap$Card$headerPrivate($rtfeldman$elm_css$Html$Styled$div);
var $rtfeldman$elm_css$Html$Styled$legend = $rtfeldman$elm_css$Html$Styled$node('legend');
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend = F2(
	function (attributes, children_) {
		return $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig(
			function (conf) {
				return _Utils_update(
					conf,
					{
						eU: $elm$core$Maybe$Just(
							A2($rtfeldman$elm_css$Html$Styled$legend, attributes, children_))
					});
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$Coloring = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$Roled = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Card$light = $frandibar$elm_bootstrap$Bootstrap$Card$Internal$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Card$Internal$Roled(6));
var $frandibar$elm_bootstrap$Bootstrap$Button$resetButton = F2(
	function (options, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('reset'),
				$frandibar$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options)),
			children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$renderCol = function (_v0) {
	var elemFn = _v0.ic;
	var options = _v0.iW;
	var children = _v0.h0;
	return A2(
		elemFn,
		$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
		children);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$row = F2(
	function (options, cols) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-group'),
				$frandibar$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options)),
			A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Form$renderCol, cols));
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Size = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Button$small = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Size(1);
var $frandibar$elm_bootstrap$Bootstrap$Button$submitButton = F2(
	function (options, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
				$frandibar$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options)),
			children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$applyModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 0:
				var align = option.a;
				return _Utils_update(
					options,
					{
						aB: $elm$core$Maybe$Just(align)
					});
			case 1:
				var coloring = option.a;
				return _Utils_update(
					options,
					{
						aF: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				var coloring = option.a;
				return _Utils_update(
					options,
					{
						a_: $elm$core$Maybe$Just(coloring)
					});
			default:
				var attrs = option.a;
				return _Utils_update(
					options,
					{
						d9: _Utils_ap(options.d9, attrs)
					});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$defaultOptions = {aB: $elm$core$Maybe$Nothing, d9: _List_Nil, aF: $elm$core$Maybe$Nothing, a_: $elm$core$Maybe$Nothing};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$cardAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Card$Internal$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Card$Internal$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('card')
			]),
		_Utils_ap(
			function () {
				var _v0 = options.aF;
				if (!_v0.$) {
					if (!_v0.a.$) {
						var role = _v0.a.a;
						return _List_fromArray(
							[
								A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role)
							]);
					} else {
						var role = _v0.a.a;
						return _List_fromArray(
							[
								A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'border', role)
							]);
					}
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.a_;
					if (!_v1.$) {
						var color = _v1.a;
						return _List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Internal$Text$textColorClass(color)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v2 = options.aB;
						if (!_v2.$) {
							var align = _v2.a;
							return _List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(align)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.d9))));
};
var $frandibar$elm_bootstrap$Bootstrap$Card$Internal$renderBlocks = function (blocks) {
	return A2(
		$elm$core$List$map,
		function (block_) {
			if (!block_.$) {
				var e = block_.a;
				return e;
			} else {
				var e = block_.a;
				return e;
			}
		},
		blocks);
};
var $frandibar$elm_bootstrap$Bootstrap$Card$view = function (_v0) {
	var conf = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		$frandibar$elm_bootstrap$Bootstrap$Card$Internal$cardAttributes(conf.iW),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var e = _v1;
							return e;
						},
						conf.eH),
						A2(
						$elm$core$Maybe$map,
						function (_v2) {
							var e = _v2;
							return e;
						},
						conf.gE)
					])),
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Card$Internal$renderBlocks(conf.gg),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$map,
							function (_v3) {
								var e = _v3;
								return e;
							},
							conf.gw),
							A2(
							$elm$core$Maybe$map,
							function (_v4) {
								var e = _v4;
								return e;
							},
							conf.gD)
						])))));
};
var $rtfeldman$elm_css$Html$Styled$fieldset = $rtfeldman$elm_css$Html$Styled$node('fieldset');
var $frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view = function (_v0) {
	var rec = _v0;
	var options = rec.iW;
	return A2(
		$rtfeldman$elm_css$Html$Styled$fieldset,
		_Utils_ap(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-group', options.eO)
						])),
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(options.es)
				]),
			options.d9),
		function (xs) {
			return A2($elm$core$List$append, xs, rec.h0);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (e) {
						return _List_fromArray(
							[e]);
					},
					rec.eU))));
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$viewCard = F5(
	function (convertTypes, drivers, updateRequestState, updatingPropertyId, storeProperty) {
		return $frandibar$elm_bootstrap$Bootstrap$Card$view(
			A3(
				$frandibar$elm_bootstrap$Bootstrap$Card$block,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Card$Block$custom(
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('selector')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(storeProperty.cj),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePriceSelector(storeProperty.lV))
																			]))
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('regex')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(storeProperty.ci),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePriceRegex(storeProperty.lV))
																			]))
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('price')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('selector')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				A2($elm$core$Maybe$withDefault, '', storeProperty.cg)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePointSelector(storeProperty.lV))
																			]))
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('regex')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				A2($elm$core$Maybe$withDefault, '', storeProperty.cf)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePointRegex(storeProperty.lV))
																			]))
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('point')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('selector')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				A2($elm$core$Maybe$withDefault, '', storeProperty.cy)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSoldoutSelector(storeProperty.lV))
																			]))
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('regex')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				A2($elm$core$Maybe$withDefault, '', storeProperty.cx)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSoldoutRegex(storeProperty.lV))
																			]))
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('soldout')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('constant point')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				$elm$core$String$fromInt(storeProperty.bQ)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateConstantPoint(storeProperty.lV))
																			]))
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('sleep time')
																			])),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
																				$elm$core$String$fromInt(storeProperty.cv)),
																				$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateSleepTime(storeProperty.lV))
																			]))
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('\u3000')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('convert type')
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateConvertTypeId(storeProperty.lV))
																			]),
																		A2(
																			$elm$core$List$map,
																			function (convertType) {
																				return A2(
																					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$Attributes$value(
																							$elm$core$String$fromInt(convertType.em.c7)),
																							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
																							_Utils_eq(convertType.em.c7, storeProperty.bR))
																						]),
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$text(convertType.em.iF)
																						]));
																			},
																			convertTypes))
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('driver id')
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
																		_List_fromArray(
																			[
																				$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange(
																				$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateDriverId(storeProperty.lV))
																			]),
																		A2(
																			$elm$core$List$map,
																			function (driver) {
																				return A2(
																					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$Attributes$value(
																							$elm$core$String$fromInt(driver.c7)),
																							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
																							_Utils_eq(driver.c7, storeProperty.bV)),
																							$rtfeldman$elm_css$Html$Styled$Events$onInput(
																							$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdateDriverId(storeProperty.lV))
																						]),
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$text(driver.iF)
																						]));
																			},
																			drivers))
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('\u3000')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												]))
										]))
								])))
					]),
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Card$header,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('align-items-center')
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$row,
									_List_fromArray(
										[
											$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('align-items-center')
												]))
										]),
									_Utils_ap(
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$colLabelSm,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														$elm$core$String$fromInt(storeProperty.lV))
													])),
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-lg-8')
															]))
													]),
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																$author$project$Pages$Cms$Stores$EditStoreProperties$OnUpdatePropertyName(storeProperty.lV)),
																$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(storeProperty.cm)
															]))
													])),
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														((updateRequestState === 1) && _Utils_eq(
														updatingPropertyId,
														$elm$core$Maybe$Just(storeProperty.lV))) ? A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Button$primary,
																$frandibar$elm_bootstrap$Bootstrap$Button$small,
																$frandibar$elm_bootstrap$Bootstrap$Button$disabled(true)
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('loading')
															])) : (((updateRequestState === 2) && _Utils_eq(
														updatingPropertyId,
														$elm$core$Maybe$Just(storeProperty.lV))) ? A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
														_List_fromArray(
															[$frandibar$elm_bootstrap$Bootstrap$Button$primary, $frandibar$elm_bootstrap$Bootstrap$Button$small, $frandibar$elm_bootstrap$Bootstrap$Button$success]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('ok!')
															])) : (((updateRequestState === 3) && _Utils_eq(
														updatingPropertyId,
														$elm$core$Maybe$Just(storeProperty.lV))) ? A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
														_List_fromArray(
															[$frandibar$elm_bootstrap$Bootstrap$Button$primary, $frandibar$elm_bootstrap$Bootstrap$Button$small, $frandibar$elm_bootstrap$Bootstrap$Button$danger]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('failed!')
															])) : A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
														_Utils_ap(
															_List_fromArray(
																[
																	$frandibar$elm_bootstrap$Bootstrap$Button$small,
																	$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																	$author$project$Pages$Cms$Stores$EditStoreProperties$OnClickApplyButton(storeProperty.lV))
																]),
															storeProperty.at ? _List_fromArray(
																[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil),
														_List_fromArray(
															[
																_Utils_eq(storeProperty.lV, -1) ? $rtfeldman$elm_css$Html$Styled$text('Add') : $rtfeldman$elm_css$Html$Styled$text('Apply')
															]))))
													])),
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$resetButton,
														_Utils_ap(
															_List_fromArray(
																[
																	$frandibar$elm_bootstrap$Bootstrap$Button$small,
																	$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Events$onClick(
																			$author$project$Pages$Cms$Stores$EditStoreProperties$OnClickResetButton(storeProperty.lV))
																		]))
																]),
															storeProperty.at ? _List_fromArray(
																[$frandibar$elm_bootstrap$Bootstrap$Button$danger]) : _List_Nil),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Reset')
															]))
													]))
											]),
										_Utils_eq(storeProperty.lV, -1) ? _List_Nil : _List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$resetButton,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Button$danger,
																$frandibar$elm_bootstrap$Bootstrap$Button$small,
																$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Events$onClick(
																		$author$project$Pages$Cms$Stores$EditStoreProperties$OnClickDeleteButton(storeProperty.lV))
																	]))
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Delete')
															]))
													]))
											])))
								]))
						]),
					$frandibar$elm_bootstrap$Bootstrap$Card$config(
						_List_fromArray(
							[$frandibar$elm_bootstrap$Bootstrap$Card$light])))));
	});
var $author$project$Pages$Cms$Stores$EditStoreProperties$viewContent = function (model) {
	return model.eJ ? A2(
		$frandibar$elm_bootstrap$Bootstrap$Spinner$spinner,
		_List_fromArray(
			[$frandibar$elm_bootstrap$Bootstrap$Spinner$grow, $frandibar$elm_bootstrap$Bootstrap$Spinner$large]),
		_List_Nil) : A2(
		$frandibar$elm_bootstrap$Bootstrap$Grid$container,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A6($rtfeldman$elm_css$Html$Styled$Lazy$lazy5, $author$project$Pages$Cms$Stores$EditStoreProperties$viewCard, model.a2, model.a6, model.F, model.a0, model.aS)
							]))
					])),
			A2(
				$elm$core$List$map,
				function (storeProperty) {
					return A2(
						$frandibar$elm_bootstrap$Bootstrap$Grid$row,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Grid$col,
								_List_Nil,
								_List_fromArray(
									[
										A6($rtfeldman$elm_css$Html$Styled$Lazy$lazy5, $author$project$Pages$Cms$Stores$EditStoreProperties$viewCard, model.a2, model.a6, model.F, model.a0, storeProperty)
									]))
							]));
				},
				$elm$core$Dict$values(model.V))));
};
var $author$project$Pages$Cms$Stores$EditStoreProperties$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$EditStoreProperties$viewContent(model),
		hx: 'Netgate/取得店舗の属性の設定'
	};
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp2 = F3(
	function (fn, arg1, arg2) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			A2(fn, arg1, arg2));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy2 = F3(
	function (fn, arg1, arg2) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A4($elm$virtual_dom$VirtualDom$lazy3, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp2, fn, arg1, arg2));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy2 = $rtfeldman$elm_css$VirtualDom$Styled$lazy2;
var $author$project$Pages$Cms$Stores$EditStores$Add = {$: 8};
var $author$project$Pages$Cms$Stores$EditStores$Delete = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateDisplayName = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateExhibitionName = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdatePermalinkGroupId = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateStoreGroupId = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateStoreName = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateStorePropertyId = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$OnUpdateUrl = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditStores$Update = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Cms$Stores$EditStores$permalinkGroupsSelector = F3(
	function (permalinkGroups, selectedPermalinkGroupId, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedPermalinkGroupId))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (permgrp) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(permgrp.y)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedPermalinkGroupId),
												permgrp.y))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(permgrp.iX)
										]));
							},
							permalinkGroups)))
				]));
	});
var $author$project$Pages$Cms$Stores$EditStores$propertiesSelector = F3(
	function (storeProperties, selectedPropertyId, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedPropertyId))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (property) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(property.lV)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedPropertyId),
												property.lV))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(property.cm)
										]));
							},
							$elm$core$Dict$values(storeProperties))))
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Secondary = 1;
var $frandibar$elm_bootstrap$Bootstrap$Button$secondary = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(1));
var $author$project$Pages$Cms$Stores$EditStores$storeGroupsSelector = F3(
	function (storeGroups, selectedStoreGroupId, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedStoreGroupId))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (storegrp) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(storegrp.ml)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedStoreGroupId),
												storegrp.ml))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(storegrp.jE)
										]));
							},
							storeGroups)))
				]));
	});
var $author$project$Pages$Cms$Stores$EditStores$viewCard = F2(
	function (model, store) {
		var url = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input url'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
					A2($elm$core$Maybe$withDefault, '', store.dS)),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
					$author$project$Pages$Cms$Stores$EditStores$OnUpdateUrl(store.fO))
				]));
		var storePropertySelectors = A3(
			$author$project$Pages$Cms$Stores$EditStores$propertiesSelector,
			model.V,
			store.lV,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onInput(
					A2(
						$elm$core$Basics$composeL,
						$author$project$Pages$Cms$Stores$EditStores$OnUpdateStorePropertyId(store.fO),
						$elm$core$String$toInt))
				]));
		var storeName = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input store_name'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
					A2($elm$core$Maybe$withDefault, '', store.dz)),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
					$author$project$Pages$Cms$Stores$EditStores$OnUpdateStoreName(store.fO))
				]));
		var storeGroupsSelectors = A3(
			$author$project$Pages$Cms$Stores$EditStores$storeGroupsSelector,
			model.U,
			store.ml,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onInput(
					A2(
						$elm$core$Basics$composeL,
						$author$project$Pages$Cms$Stores$EditStores$OnUpdateStoreGroupId(store.fO),
						$elm$core$String$toInt))
				]));
		var permalinkGroupsSelectors = A3(
			$author$project$Pages$Cms$Stores$EditStores$permalinkGroupsSelector,
			model.A,
			store.y,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onInput(
					A2(
						$elm$core$Basics$composeL,
						$author$project$Pages$Cms$Stores$EditStores$OnUpdatePermalinkGroupId(store.fO),
						$elm$core$String$toInt))
				]));
		var exhibitionName = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input exhibition_name'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
					A2($elm$core$Maybe$withDefault, '', store.kL)),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
					$author$project$Pages$Cms$Stores$EditStores$OnUpdateExhibitionName(store.fO))
				]));
		var displayName = $frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$placeholder('Input display_name'),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
					A2($elm$core$Maybe$withDefault, '', store.h7)),
					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
					$author$project$Pages$Cms$Stores$EditStores$OnUpdateDisplayName(store.fO))
				]));
		return $frandibar$elm_bootstrap$Bootstrap$Card$view(
			A3(
				$frandibar$elm_bootstrap$Bootstrap$Card$block,
				_List_Nil,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Card$Block$custom(
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('store_groups')
																			])),
																		storeGroupsSelectors
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('url')
																			])),
																		url
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('store_property')
																			])),
																		storePropertySelectors
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('store_name')
																			])),
																		storeName
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												])),
											A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$col,
											_List_Nil,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$view(
													A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$children,
														_List_fromArray(
															[
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('permalink_group_id')
																			])),
																		permalinkGroupsSelectors
																	])),
																A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$group,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Form$label,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('exhibition_name')
																			])),
																		exhibitionName
																	]))
															]),
														A3(
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('')
																]),
															$frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($frandibar$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
												]))
										]))
								])))
					]),
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Card$header,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('align-items-center')
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$row,
									_List_fromArray(
										[
											$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('align-items-center')
												]))
										]),
									_Utils_ap(
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$colLabelSm,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														$elm$core$String$fromInt(store.fO))
													])),
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-lg-8')
															]))
													]),
												_List_fromArray(
													[displayName])),
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														_Utils_eq(store.fO, -1) ? A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$button,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Button$primary,
																$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Cms$Stores$EditStores$Add)
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Add')
															])) : A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$button,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Button$secondary,
																$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																$author$project$Pages$Cms$Stores$EditStores$Update(store.fO))
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Update')
															]))
													]))
											]),
										_Utils_eq(store.fO, -1) ? _List_Nil : _List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$col,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Grid$Col$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('col-auto')
															]))
													]),
												_List_fromArray(
													[
														A2(
														$frandibar$elm_bootstrap$Bootstrap$Button$resetButton,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Button$danger,
																$frandibar$elm_bootstrap$Bootstrap$Button$small,
																$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																$author$project$Pages$Cms$Stores$EditStores$Delete(store.fO))
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Delete')
															]))
													]))
											])))
								]))
						]),
					$frandibar$elm_bootstrap$Bootstrap$Card$config(
						_List_fromArray(
							[$frandibar$elm_bootstrap$Bootstrap$Card$light])))));
	});
var $author$project$Pages$Cms$Stores$EditStores$viewContent = function (model) {
	return model.eJ ? A2(
		$frandibar$elm_bootstrap$Bootstrap$Spinner$spinner,
		_List_fromArray(
			[$frandibar$elm_bootstrap$Bootstrap$Spinner$grow, $frandibar$elm_bootstrap$Bootstrap$Spinner$large]),
		_List_Nil) : A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.F === 1) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'position', 'fixed'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'top', '50%'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'left', '50%'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'transform', 'translate(-50%, -50%)'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'z-index', '1000')
					]),
				_List_fromArray(
					[
						$author$project$Components$Loading$loadingCenter(50.0)
					])) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Grid$container,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					A2(
						$frandibar$elm_bootstrap$Bootstrap$Grid$row,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Grid$col,
								_List_Nil,
								_List_fromArray(
									[
										A3($rtfeldman$elm_css$Html$Styled$Lazy$lazy2, $author$project$Pages$Cms$Stores$EditStores$viewCard, model, model.bl)
									]))
							])),
					A2(
						$elm$core$List$map,
						function (store) {
							return A2(
								$frandibar$elm_bootstrap$Bootstrap$Grid$row,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Grid$Row$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Grid$col,
										_List_Nil,
										_List_fromArray(
											[
												A3($rtfeldman$elm_css$Html$Styled$Lazy$lazy2, $author$project$Pages$Cms$Stores$EditStores$viewCard, model, store)
											]))
									]));
						},
						$elm$core$Dict$values(model.ap))))
			]));
};
var $author$project$Pages$Cms$Stores$EditStores$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$EditStores$viewContent(model),
		hx: 'Storesの編集'
	};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedNewStore = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedNewStoreGroup = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewGetEnabled = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewGetInterval = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewStoreOrder = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$SubmitAddUserStore = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$viewAddUserStore = F5(
	function (userStores, selectedNewUserStore, stores, storeGroups, userStore) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(userStore.hs))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedNewStore)
										]))
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											$elm_community$maybe_extra$Maybe$Extra$isNothing(selectedNewUserStore))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('選択してください')
										])),
								A2(
									$elm$core$List$map,
									function (store) {
										return A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$value(
													$elm$core$String$fromInt(store.hs)),
													$rtfeldman$elm_css$Html$Styled$Attributes$selected(
													_Utils_eq(
														selectedNewUserStore,
														$elm$core$Maybe$Just(store.hs)))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(store.jD)
												]));
									},
									A2(
										$elm$core$List$filter,
										function (s) {
											return A2(
												$elm$core$List$all,
												function (us) {
													return !_Utils_eq(s.hs, us.hs);
												},
												userStores);
										},
										stores))))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
									$elm$core$String$fromInt(userStore.mk)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewStoreOrder)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, '', $elm$core$String$fromInt, userStore.kT)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewGetInterval)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(userStore.kS),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck($author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateNewGetEnabled)
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedNewStoreGroup)
										]))
								]),
							A2(
								$elm$core$List$map,
								function (sg) {
									return A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(
												$elm$core$String$fromInt(sg.mh)),
												$rtfeldman$elm_css$Html$Styled$Attributes$selected(
												_Utils_eq(userStore.mh, sg.mh))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(sg.mi)
											]));
								},
								storeGroups))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_Utils_ap(
								userStore.at ? _List_fromArray(
									[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
										$author$project$Pages$Cms$Stores$EditUsersStores$SubmitAddUserStore(userStore))
									])),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Add')
								]))
						])),
					A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
				]));
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateGetEnabled = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateGetInterval = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateStoreGroupId = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateStoreOrder = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$SubmitDeleteUserStore = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$SubmitUpdateUserStore = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Cms$Stores$EditUsersStores$viewUserStore = F3(
	function (stores, storeGroups, userStore) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(userStore.hs))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(userStore.jD)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
									$elm$core$String$fromInt(userStore.mk)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateStoreOrder(userStore.hs))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(
									A3($elm_community$maybe_extra$Maybe$Extra$unwrap, '', $elm$core$String$fromInt, userStore.kT)),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateGetInterval(userStore.hs))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(userStore.kS),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateGetEnabled(userStore.hs))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput(
											$author$project$Pages$Cms$Stores$EditUsersStores$OnUpdateStoreGroupId(userStore.hs))
										]))
								]),
							A2(
								$elm$core$List$map,
								function (sg) {
									return A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(
												$elm$core$String$fromInt(sg.mh)),
												$rtfeldman$elm_css$Html$Styled$Attributes$selected(
												_Utils_eq(userStore.mh, sg.mh))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(sg.mi)
											]));
								},
								storeGroups))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_Utils_ap(
								userStore.at ? _List_fromArray(
									[$frandibar$elm_bootstrap$Bootstrap$Button$primary]) : _List_Nil,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
										$author$project$Pages$Cms$Stores$EditUsersStores$SubmitUpdateUserStore(userStore))
									])),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Update')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Cms$Stores$EditUsersStores$SubmitDeleteUserStore(userStore.hs))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Delete')
								]))
						]))
				]));
	});
var $author$project$Pages$Cms$Stores$EditUsersStores$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- CRUD table users_stores')
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ユーザ')
									])),
								A3(
								$author$project$Components$UserSelector$userSelector,
								model.jT,
								model.hk,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Cms$Stores$EditUsersStores$OnChangeSelectedUser)
									]))
							]))
					])),
				$elm_community$maybe_extra$Maybe$Extra$isNothing(model.hk) ? A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil) : A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('store_id')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('店名')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示順番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得間隔')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('有効')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Store Group')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Update')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Delete')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A5($author$project$Pages$Cms$Stores$EditUsersStores$viewAddUserStore, model.dT, model.fG, model.ap, model.U, model.s),
							A2(
								$elm$core$List$map,
								A2($author$project$Pages$Cms$Stores$EditUsersStores$viewUserStore, model.ap, model.U),
								model.dT)))
					]))
			]));
};
var $author$project$Pages$Cms$Stores$EditUsersStores$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$EditUsersStores$viewContent(model),
		hx: '取得量販店の変更'
	};
};
var $author$project$Pages$Cms$Stores$Yahoo$OnChangeDirectURL = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Stores$Yahoo$OnEnableDirectURL = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Pages$Cms$Stores$Yahoo$OnSubmit = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Cms$Stores$Yahoo$SelectProduct = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Cms$Stores$Yahoo$SelectUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Molecules$UserSelector$Config = $elm$core$Basics$identity;
var $author$project$Components$Molecules$UserSelector$custom = {hA: '全て選択'};
var $frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$Danger = 1;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Validation = function (a) {
	return {$: 6, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger = $frandibar$elm_bootstrap$Bootstrap$Form$Input$Validation(1);
var $rtfeldman$elm_css$Css$displayFlex = A2($rtfeldman$elm_css$Css$property, 'display', 'flex');
var $rtfeldman$elm_css$Css$flexDirection = $rtfeldman$elm_css$Css$prop1('flex-direction');
var $rtfeldman$elm_css$Css$marginBottom = $rtfeldman$elm_css$Css$prop1('margin-bottom');
var $rtfeldman$elm_css$Css$marginRight = $rtfeldman$elm_css$Css$prop1('margin-right');
var $rtfeldman$elm_css$Css$row = {gv: 0, cV: 0, i: 'row'};
var $frandibar$elm_bootstrap$Bootstrap$Form$FormInternal$Success = 0;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$success = $frandibar$elm_bootstrap$Bootstrap$Form$Input$Validation(0);
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Url = 9;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$url = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(9);
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$Disabled = function (a) {
	return {$: 3, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled = function (disabled_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Select$Disabled(disabled_);
};
var $author$project$Components$Molecules$UserSelector$view = F3(
	function (props, attrs, _v0) {
		var conf = _v0;
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs),
					A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled(true),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange,
					props.iS)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							$elm_community$maybe_extra$Maybe$Extra$isNothing(props.hk))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(conf.hA)
						])),
				A2(
					$elm$core$List$map,
					function (user) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$elm$core$String$fromInt(user.jS)),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, props.hk),
										user.jS))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(user.mD)
								]));
					},
					props.jT)));
	});
var $author$project$Components$Molecules$UserSelector$withUnselectedLabel = F2(
	function (label, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{hA: label});
	});
var $author$project$Pages$Cms$Stores$Yahoo$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginRight(
										$rtfeldman$elm_css$Css$px(16))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ユーザー')
									])),
								A3(
								$author$project$Components$Molecules$UserSelector$view,
								{
									iS: $elm$core$Maybe$Just($author$project$Pages$Cms$Stores$Yahoo$SelectUser),
									hk: $elm$core$String$toInt(model.cr),
									jT: A3(
										$krisajenkins$remotedata$RemoteData$unwrap,
										_List_Nil,
										function (res) {
											return res.jT;
										},
										model.eK)
								},
								_List_Nil,
								A2($author$project$Components$Molecules$UserSelector$withUnselectedLabel, '選択してください', $author$project$Components$Molecules$UserSelector$custom))
							]))
					])),
				(model.eR && (!model.b4)) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						function () {
						var _v0 = model.ja;
						if (_v0.$ === 3) {
							var products = _v0.a;
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginTop(
												$rtfeldman$elm_css$Css$px(24)),
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$px(40)),
												$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('商品の一覧')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$table,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table '),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'word-break', 'keep-all')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$thead,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('商品名')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('選択')
															]))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$tbody,
												_List_Nil,
												A2(
													$elm$core$List$map,
													function (product) {
														return A2(
															$rtfeldman$elm_css$Html$Styled$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text(product.lT)
																		])),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$frandibar$elm_bootstrap$Bootstrap$Button$button,
																			_List_fromArray(
																				[
																					$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																					$author$project$Pages$Cms$Stores$Yahoo$SelectProduct(product.i8))
																				]),
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text('選択')
																				]))
																		]))
																]));
													},
													products))
											]))
									]));
						} else {
							return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
						}
					}()
					])) : (model.b4 ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						function () {
						var _v1 = model.aG;
						if (_v1.$ === 3) {
							var urls = _v1.a;
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginTop(
												$rtfeldman$elm_css$Css$px(24)),
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$px(40)),
												$rtfeldman$elm_css$Css$marginLeft($rtfeldman$elm_css$Css$auto)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('YahooのURLを設定してください')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$table,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table '),
												A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'word-break', 'keep-all')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$thead,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('店舗名')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$css(
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Css$width(
																		$rtfeldman$elm_css$Css$px(600))
																	]))
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('URL')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('有効')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$th,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('更新')
															]))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$tbody,
												_List_Nil,
												A2(
													$elm$core$List$map,
													function (url) {
														var inputStatus = (url.dS === '') ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(_List_Nil) : ((A2($elm$core$String$startsWith, 'http://', url.dS) || A2($elm$core$String$startsWith, 'https://', url.dS)) ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$success : $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger);
														return A2(
															$rtfeldman$elm_css$Html$Styled$tr,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text(url.jD)
																		])),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$css(
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Css$width(
																					$rtfeldman$elm_css$Css$px(600))
																				]))
																		]),
																	_List_fromArray(
																		[
																			$frandibar$elm_bootstrap$Bootstrap$Form$Input$url(
																			_List_fromArray(
																				[
																					$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(url.dS),
																					$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																					A2($author$project$Pages$Cms$Stores$Yahoo$OnChangeDirectURL, url.cl, url.cz)),
																					inputStatus
																				]))
																		])),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$rtfeldman$elm_css$Html$Styled$input,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox'),
																					$rtfeldman$elm_css$Html$Styled$Attributes$checked(url.ev),
																					$rtfeldman$elm_css$Html$Styled$Events$onCheck(
																					A2($author$project$Pages$Cms$Stores$Yahoo$OnEnableDirectURL, url.cl, url.cz))
																				]),
																			_List_Nil)
																		])),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$frandibar$elm_bootstrap$Bootstrap$Button$button,
																			_List_fromArray(
																				[
																					$frandibar$elm_bootstrap$Bootstrap$Button$disabled(
																					_Utils_eq(inputStatus, $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger)),
																					$frandibar$elm_bootstrap$Bootstrap$Button$secondary,
																					$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																					$author$project$Pages$Cms$Stores$Yahoo$OnSubmit(url))
																				]),
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text('更新')
																				]))
																		]))
																]));
													},
													urls))
											]))
									]));
						} else {
							return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
						}
					}()
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Cms$Stores$Yahoo$view = function (model) {
	return {
		ko: $author$project$Pages$Cms$Stores$Yahoo$viewContent(model),
		hx: 'Yahoo 直接取得URL設定'
	};
};
var $rtfeldman$elm_css$Html$Styled$h3 = $rtfeldman$elm_css$Html$Styled$node('h3');
var $author$project$Pages$Cms$viewTop = {
	ko: A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('商品管理')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$userSpecificRoutingEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ユーザーごとの作業')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$changeUserProductEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('ユーザーごとの取得間隔，基準価格などの変更')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$addProductConfigEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('商品の追加')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editProductsEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('商品の型番，メーカー，小分類の変更')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editProductsPermalinksEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit products_permalinks')
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('取得店舗の管理')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editStorePropertiesEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit store_properties')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editPermalinkGroupsEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit permalink_groups')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editStoreGroupsEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit store_groups')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editUsersStoresEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit users_stores')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editStoresEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit stores')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href(
										$author$project$Endpoints$Cms$yahooEndpoints.j(_List_Nil))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Yahoo個別取得URL')
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('その他の管理')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$editMailAddressEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Edit mailaddress')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href($author$project$Endpoints$Cms$getAccessCountEndpoints.j)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('アクセス数カウント')
									]))
							]))
					]))
			])),
	hx: '管理ページ'
};
var $author$project$Pages$Cms$view = function (_v0) {
	var cmsModel = _v0.a;
	var navModel = _v0.b;
	return function (rootPageRequired) {
		return _Utils_update(
			rootPageRequired,
			{
				ko: A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Cms$cmsNavBar(navModel),
							rootPageRequired.ko
						]))
			});
	}(
		function () {
			switch (cmsModel.$) {
				case 0:
					var session = cmsModel.a;
					return $author$project$Pages$Cms$viewTop;
				case 1:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditStorePropertiesMsg,
						$author$project$Pages$Cms$Stores$EditStoreProperties$view(espModel));
				case 2:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditPermalinkGroupsMsg,
						$author$project$Pages$Cms$Stores$EditPermalinkGroups$view(espModel));
				case 3:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditStoreGroupsMsg,
						$author$project$Pages$Cms$Stores$EditStoreGroups$view(espModel));
				case 4:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditMailAddressMsg,
						$author$project$Pages$Cms$Others$EditMailAddress$view(espModel));
				case 5:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotGetAccessCountMsg,
						$author$project$Pages$Cms$Others$GetAccessCount$view(espModel));
				case 6:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditUsersStoresMsg,
						$author$project$Pages$Cms$Stores$EditUsersStores$view(espModel));
				case 21:
					var espModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditStoresMsg,
						$author$project$Pages$Cms$Stores$EditStores$view(espModel));
				case 10:
					var uwModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateWeek30ByCsv,
						$author$project$Pages$Cms$Products$UpdateWeek30ByCsv$view(uwModel));
				case 11:
					var model = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateTletProductsByCsv,
						$author$project$Pages$Cms$Products$UpdateTletProductsByCsv$view(model));
				case 12:
					var model = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateTescomProductsByCsv,
						$author$project$Pages$Cms$Products$UpdateTescomProductsByCsv$view(model));
				case 9:
					var model = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateSharpProductsByCsv,
						$author$project$Pages$Cms$Products$UpdateSharpProductsByCsv$view(model));
				case 7:
					var model = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateHitachiWeeklyProductsByCsv,
						$author$project$Pages$Cms$Products$UpdateHitachiWeeklyProductsByCsv$view(model));
				case 8:
					var model = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUpdateHIitachiProductsOrderByCsv,
						$author$project$Pages$Cms$Products$UpdateHitachiProductsOrderByCsv$view(model));
				case 13:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotUserSpecificRoutingMsg,
						$author$project$Pages$Cms$Products$UserSpecificRouting$view(reiModel));
				case 14:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotRakutenExcludedItems,
						$author$project$Pages$Cms$Products$RakutenExcludedItems$view(reiModel));
				case 15:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotChangeDisplayIntervalMsg,
						$author$project$Pages$Cms$Products$ChangeDisplayInterval$view(reiModel));
				case 17:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotAddProductConfigMsg,
						$author$project$Pages$Cms$Products$AddProductConfig$view(reiModel));
				case 18:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotChangeDisplayOrderMsg,
						$author$project$Pages$Cms$Products$ChangeDisplayOrder$view(reiModel));
				case 16:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotChangeUserProductMsg,
						$author$project$Pages$Cms$Products$ChangeUserProduct$view(reiModel));
				case 19:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditProductsPermalinks,
						$author$project$Pages$Cms$Products$EditProductsPermalinks$view(reiModel));
				case 20:
					var reiModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotEditProducts,
						$author$project$Pages$Cms$Products$EditProducts$view(reiModel));
				default:
					var yModel = cmsModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$Cms$GotYahooMsg,
						$author$project$Pages$Cms$Stores$Yahoo$view(yModel));
			}
		}());
};
var $author$project$Pages$Login$EnteredPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Login$EnteredUserName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Login$SubmittedForm = {$: 0};
var $rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $rtfeldman$elm_css$Html$Styled$Events$onSubmit = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Password = 1;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$password = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(1);
var $author$project$Pages$Login$formContent = F3(
	function (userName, password, errMsg) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Pages$Login$SubmittedForm),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('mx-auto col-lg-4')
				]),
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('ユーザーID')
								])),
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Login$EnteredUserName),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(userName)
										]))
								]))
						])),
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$group,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('パスワード')
								])),
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$password(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Login$EnteredPassword),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(password)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger small')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2($elm$core$Maybe$withDefault, '', errMsg))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Login$SubmittedForm),
									$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('float-right')
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Submit')
								]))
						]))
				]));
	});
var $author$project$Pages$Login$view = function (model) {
	return {
		ko: A3($author$project$Pages$Login$formContent, model.aO.mD, model.aO.dn, model.ar),
		hx: 'Netgate/ログインページ'
	};
};
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.ji, s.h1, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.f)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{h1: newCol, f: s.f, g: s.g, b: newOffset, ji: newRow, a: s.a});
	};
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {h1: s.h1 + (newOffset - s.b), f: s.f, g: s.g, b: newOffset, ji: s.ji, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.ji, s.h1 - (floatOffset + s.b), invalid, s.f));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.a)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.k6,
				c.kY,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.k6,
				c.iQ,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.k6,
				c.hT,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.k6,
				c.$8,
				c.iy,
				c.ii,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.k6,
				c.$8,
				c.iy,
				c.ii,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				hT: $elm$core$Result$Err(invalid),
				$8: expecting,
				ii: $elm$core$Result$Err(invalid),
				kY: $elm$core$Result$Err(invalid),
				iy: $elm$core$Result$Ok($elm$core$Basics$identity),
				k6: invalid,
				iQ: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Session$userName = function (session) {
	if (!session.$) {
		var name = session.c;
		return $elm$core$Maybe$Just(name);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$User$mlnAdmin = _List_fromArray(
	[
		{iF: 'WEB価格', dS: '/user/mln-comnet/hour'},
		{iF: 'WEB価格（自動更新）', dS: '/user/mln-comnet/auto-reload'},
		{iF: '時系列データ', dS: '/user/mln-comnet/chronological-30min'},
		{iF: '価格/アラート設定', dS: '/user/mln-comnet/change-product'},
		{iF: '送信先設定', dS: '/user/mln-comnet/change-mail'},
		{iF: '送信時間設定', dS: '/user/mln-comnet/mail-setting'},
		{iF: 'パスワード変更', dS: '/user/mln-comnet/change-password'}
	]);
var $author$project$Pages$User$mlnView = _List_fromArray(
	[
		{iF: 'WEB価格', dS: '/user/mln-comnet/hour'},
		{iF: '時系列データ', dS: '/user/mln-comnet/chronological-30min'},
		{iF: 'パスワード変更', dS: '/user/mln-comnet/change-password'}
	]);
var $author$project$Pages$User$pagesByUserId = function (userName) {
	switch (userName) {
		case 'to24tcm-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/to24tcm-comnet/fifteen'},
					{iF: '60分取得', dS: '/user/to24tcm-comnet/hour'},
					{iF: '15分時系列', dS: '/user/to24tcm-comnet/fifteen-seq'},
					{iF: '60分時系列', dS: '/user/to24tcm-comnet/hour-seq'},
					{iF: '期間指定CSV', dS: '/user/to24tcm-comnet/csv'},
					{iF: '商品の変更', dS: '/user/to24tcm-comnet/change-product'},
					{iF: '一日取得', dS: '/user/to24tcm-comnet/day'}
				]);
		case 'to24tcl-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/to24tcl-comnet/fifteen'},
					{iF: '15分時系列', dS: '/user/to24tcl-comnet/fifteen-seq'},
					{iF: '期間指定CSV', dS: '/user/to24tcl-comnet/csv'},
					{iF: '商品の変更', dS: '/user/to24tcl-comnet/change-product'},
					{iF: '一日取得', dS: '/user/to24tcl-comnet/day'}
				]);
		case 'to24tcm-fridge':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/to24tcm-fridge/fifteen'},
					{iF: '60分取得', dS: '/user/to24tcm-fridge/hour'},
					{iF: '15分時系列', dS: '/user/to24tcm-fridge/fifteen-seq'},
					{iF: '60分時系列', dS: '/user/to24tcm-fridge/hour-seq'},
					{iF: '期間指定CSV', dS: '/user/to24tcm-fridge/csv'},
					{iF: '商品の変更', dS: '/user/to24tcm-fridge/change-product'},
					{iF: '一日取得', dS: '/user/to24tcm-fridge/day'}
				]);
		case 'to24tcm-kitchen':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/to24tcm-kitchen/fifteen'},
					{iF: '60分取得', dS: '/user/to24tcm-kitchen/hour'},
					{iF: '15分時系列', dS: '/user/to24tcm-kitchen/fifteen-seq'},
					{iF: '60分時系列', dS: '/user/to24tcm-kitchen/hour-seq'},
					{iF: '期間指定CSV', dS: '/user/to24tcm-kitchen/csv'},
					{iF: '商品の変更', dS: '/user/to24tcm-kitchen/change-product'},
					{iF: '一日取得', dS: '/user/to24tcm-kitchen/day'}
				]);
		case 'hisense':
			return _List_fromArray(
				[
					{iF: '一日取得', dS: '/user/hisense/day'},
					{iF: '時系列取得', dS: '/user/hisense/time-seq'},
					{iF: 'レビュー', dS: '/user/hisense/review'},
					{iF: 'Amazonランキング', dS: '/user/hisense/amazon-ranking'},
					{iF: '60分取得/ハイセンス4商品', dS: '/user/hisense/tv-hour'},
					{iF: '商品の編集', dS: '/user/hisense/change-product'},
					{iF: 'エクセル', dS: '/user/hisense/excel'}
				]);
		case 'jvc-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/jvc-comnet/fifteen'},
					{iF: '60分取得', dS: '/user/jvc-comnet/hour'},
					{iF: '15分 時系列', dS: '/user/jvc-comnet/time-seq-fifteen'},
					{iF: '60分 時系列', dS: '/user/jvc-comnet/time-seq-hour'}
				]);
		case 'mln-comnet':
			return _List_fromArray(
				[
					{iF: 'WEB価格', dS: '/user/mln-comnet/hour'},
					{iF: 'WEB価格（自動更新）', dS: '/user/mln-comnet/auto-reload'},
					{iF: 'ヤマダ下取り価格', dS: '/user/mln-comnet/hour-yamada'},
					{iF: '時系列データ', dS: '/user/mln-comnet/chronological-30min'},
					{iF: '価格/アラート設定', dS: '/user/mln-comnet/change-product'},
					{iF: '送信先設定', dS: '/user/mln-comnet/change-mail'},
					{iF: '送信時間設定', dS: '/user/mln-comnet/mail-setting'}
				]);
		case 'minetec':
			return $author$project$Pages$User$mlnAdmin;
		case 'minetgate':
			return $author$project$Pages$User$mlnView;
		case 'ln936502':
			return $author$project$Pages$User$mlnAdmin;
		case 'ln124003':
			return $author$project$Pages$User$mlnAdmin;
		case 'ln234088':
			return $author$project$Pages$User$mlnAdmin;
		case 'ca6-comnet':
			return _List_fromArray(
				[
					{iF: '60分取得', dS: '/user/ca6-comnet/hour'},
					{iF: '今週の品目 15分取得', dS: '/user/ca6-comnet/weekly-product-15min'},
					{iF: '60分取得 期間指定CSV', dS: '/user/ca6-comnet/hour-csv'},
					{iF: '今週の品目 期間指定CSV', dS: '/user/ca6-comnet/hour-csv-week'}
				]);
		case 'tescom-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/tescom-comnet/15min'},
					{iF: '60分取得', dS: '/user/tescom-comnet/hour'},
					{iF: 'エクセル', dS: '/user/tescom-comnet/excel'},
					{iF: '商品の変更', dS: '/user/tescom-comnet/change-product'}
				]);
		case 'tv24-comnet':
			return _List_fromArray(
				[
					{iF: '30分取得', dS: '/user/tv24-comnet/thirty'},
					{iF: '期間指定CSV', dS: '/user/tv24-comnet/csv'},
					{iF: '商品の変更', dS: '/user/tv24-comnet/change-product'}
				]);
		case 'elec-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/elec-comnet/15min'},
					{iF: '60分取得', dS: '/user/elec-comnet/hour'},
					{iF: '15分 時系列', dS: '/user/elec-comnet/time-seq-fifteen'},
					{iF: '60分 時系列', dS: '/user/elec-comnet/time-seq-hour'},
					{iF: 'エクセル', dS: '/user/elec-comnet/excel'},
					{iF: '商品の変更', dS: '/user/elec-comnet/change-product'}
				]);
		case 'to24tcm-air':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/to24tcm-air/fifteen'},
					{iF: '60分取得', dS: '/user/to24tcm-air/hour'},
					{iF: '60分時系列', dS: '/user/to24tcm-air/hour-seq'},
					{iF: '期間指定CSV', dS: '/user/to24tcm-air/csv'},
					{iF: '商品の変更', dS: '/user/to24tcm-air/change-product'}
				]);
		case 'hi3-comnet':
			return _List_fromArray(
				[
					{iF: '60分取得', dS: '/user/hi3-comnet/hour'},
					{iF: '60分時系列', dS: '/user/hi3-comnet/hour-seq'},
					{iF: '商品の変更', dS: '/user/hi3-comnet/change-product'}
				]);
		case 'delonghi':
			return _List_fromArray(
				[
					{iF: '60分取得', dS: '/user/delonghi/hour'},
					{iF: '60分 時系列', dS: '/user/delonghi/time-seq-hour'},
					{iF: '60分 新時系列', dS: '/user/delonghi/time-seq-2-hour'},
					{iF: '商品の変更', dS: '/user/delonghi/change-product'}
				]);
		case 'kinujo-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/kinujo-comnet/fifteen'},
					{iF: 'エクセル', dS: '/user/kinujo-comnet/excel'},
					{iF: '商品の変更', dS: '/user/kinujo-comnet/change-product'}
				]);
		case 'hi24-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/hi24-comnet/hour'},
					{iF: '15分 時系列', dS: '/user/hi24-comnet/time-seq'},
					{iF: '今週の品目 15分取得', dS: '/user/hi24-comnet/weekly-product-15min'},
					{iF: '10時の状況', dS: '/user/hi24-comnet/ten-am'},
					{iF: '一日4回取得', dS: '/user/hi24-comnet/six-hour'}
				]);
		case 'sha-comnet':
			return _List_fromArray(
				[
					{iF: 'WIDE(24回)', dS: '/user/sha-comnet/wide'},
					{iF: '60分取得', dS: '/user/sha-comnet/hour'},
					{iF: '60分時系列', dS: '/user/sha-comnet/hour-seq'},
					{iF: '取り組み機種', dS: '/user/sha-comnet/weekly-product'},
					{iF: '販売店検索', dS: '/user/sha-comnet/day'},
					{iF: '商品の変更', dS: '/user/sha-comnet/change-product'}
				]);
		case 'tst-comnet':
			return _List_fromArray(
				[
					{iF: 'WIDE(24回)', dS: '/user/tst-comnet/wide'},
					{iF: '時系列', dS: '/user/tst-comnet/time-seq'}
				]);
		case 'dbi6-comnet':
			return _List_fromArray(
				[
					{iF: '60分取得', dS: '/user/dbi6-comnet/hour'},
					{iF: '60分時系列', dS: '/user/dbi6-comnet/time-seq'}
				]);
		case 'denon6-comnet':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: 'http://www2.digitalmedia.jp/users/15min'},
					{iF: '15分時系列', dS: 'http://www2.digitalmedia.jp/users/15min'},
					{iF: '60分取得', dS: '/user/denon6-comnet/wide'},
					{iF: '60分時系列', dS: '/user/denon6-comnet/time-seq'}
				]);
		case 'braun-comnet':
			return _List_fromArray(
				[
					{iF: 'WIDE(24回)', dS: '/user/braun-comnet/wide'},
					{iF: '60分取得', dS: '/user/braun-comnet/hour'}
				]);
		case 'ts-bat':
			return _List_fromArray(
				[
					{iF: 'WIDE(2回)', dS: '/user/ts-bat/wide'},
					{iF: '2回時系列', dS: '/user/ts-bat/time-seq'}
				]);
		case 'fuji-comnet':
			return _List_fromArray(
				[
					{iF: '中古取得', dS: '/user/fuji-comnet/day'}
				]);
		case 'test':
			return _List_fromArray(
				[
					{iF: '15分取得', dS: '/user/test/fifteen'}
				]);
		default:
			return _List_Nil;
	}
};
var $author$project$Pages$User$userNavBar = F2(
	function (userName, navModel) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$map,
			$author$project$Pages$User$GotNavBarMsg,
			A2(
				$author$project$Components$NavBar$view,
				navModel,
				A2(
					$elm$core$List$cons,
					{iF: 'TOP', dS: '/user'},
					$author$project$Pages$User$pagesByUserId(userName))));
	});
var $author$project$Pages$Users$BraunComnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$BraunComnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 5:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$colspan = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$Pages$Users$BraunComnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$BraunComnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$BraunComnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$BraunComnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$BraunComnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$BraunComnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$TimeSelector$timeSelector = F3(
	function (interval, initVal, attrs) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
				]),
			A2(
				$elm_community$list_extra$List$Extra$andThen,
				function (hour) {
					return A2(
						$elm_community$list_extra$List$Extra$andThen,
						function (minute) {
							var val = A2(
								$elm$core$String$right,
								2,
								'0' + $elm$core$String$fromInt(hour)) + (':' + A2(
								$elm$core$String$right,
								2,
								'0' + $elm$core$String$fromInt(minute)));
							return _List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(val),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(val, initVal))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(val)
										]))
								]);
						},
						A2(
							$elm$core$List$filter,
							function (min) {
								return !A2($elm$core$Basics$modBy, interval, min);
							},
							A2($elm$core$List$range, 0, 59)));
				},
				A2($elm$core$List$range, 0, 23)));
	});
var $author$project$Pages$Users$BraunComnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$BraunComnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$BraunComnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$BraunComnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$BraunComnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$BraunComnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$BraunComnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$BraunComnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$BraunComnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$BraunComnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$BraunComnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Attrs = function (a) {
	return {$: 7, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs = function (attrs_) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Attrs(attrs_);
};
var $author$project$Pages$Users$BraunComnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$BraunComnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$BraunComnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$BraunComnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$BraunComnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$BraunComnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$BraunComnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$BraunComnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$BraunComnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$BraunComnet$Hour$viewContent, model, $author$project$Pages$Users$BraunComnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$BraunComnet$Hour$pStoreHeader, $author$project$Pages$Users$BraunComnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$BraunComnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$BraunComnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$BraunComnet$Wide$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$rowspan = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'rowspan',
		$elm$core$String$fromInt(n));
};
var $author$project$Pages$Users$BraunComnet$Wide$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$BraunComnet$Wide$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Wide$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$BraunComnet$Wide$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$BraunComnet$Wide$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$BraunComnet$Wide$OnChangeBigCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$OnChangeMaker = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$OnChangeSmallCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$BraunComnet$Wide$OnSubmit = {$: 8};
var $author$project$Pages$Users$BraunComnet$Wide$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$CategorySelector$makerSelector = F3(
	function (makers, selectedMaker, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, selectedMaker),
										-1))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('全て選択')
								])),
						A2(
							$elm$core$List$map,
							function (maker) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(maker.iJ)),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, -1, selectedMaker),
												maker.iJ))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(maker.lg)
										]));
							},
							makers)))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Wide$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Wide$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Wide$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$BraunComnet$Wide$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$BraunComnet$Wide$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$BraunComnet$Wide$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$BraunComnet$Wide$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Utils$Helpers$displayStyleList = _List_fromArray(
	[
		_Utils_Tuple2('全てを表示', 'DisplayAll'),
		_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
		_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
		_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
		_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
	]);
var $author$project$Pages$Users$BraunComnet$Wide$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$BraunComnet$Wide$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$BraunComnet$Wide$OnPressDownloadCSV = {$: 9};
var $author$project$Pages$Users$BraunComnet$Wide$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$BraunComnet$Wide$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$BraunComnet$Wide$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$BraunComnet$Wide$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$BraunComnet$Wide$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$BraunComnet$Wide$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$BraunComnet$Wide$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$BraunComnet$Wide$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$BraunComnet$Wide$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$BraunComnet$Wide$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$BraunComnet$Wide$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$BraunComnet$Wide$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$BraunComnet$Wide$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$BraunComnet$Wide$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$BraunComnet$Wide$viewTableControl(model),
						$author$project$Pages$Users$BraunComnet$Wide$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$BraunComnet$Wide$view = function (model) {
	return {
		ko: $author$project$Pages$Users$BraunComnet$Wide$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return 'NET-WIDE 掲載された機種と価格の時間変化(24回版)';
			}
		}()
	};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$All = 0;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$Amazon = 2;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$Kakakucom = 5;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeBigCategory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeMaker = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeSmallCategory = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$Rakuten = 3;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$Ryouhan = 1;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$Yahoo = 4;
var $author$project$Pages$Users$Ca6Comnet$HourCsv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('メーカー')
									])),
								A3(
								$author$project$Components$CategorySelector$makerSelector,
								model.ca,
								model.dw,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeMaker)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('大分類')
									])),
								A3(
								$author$project$Components$CategorySelector$bigCategorySelector,
								model.hR,
								model.he,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeBigCategory)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('小分類')
									])),
								A4(
								$author$project$Components$CategorySelector$smallCategorySelector,
								model.jy,
								model.fH,
								model.he,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeSmallCategory)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 ')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('型番')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer wide')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Pages$Users$Ca6Comnet$HourCsv$OnChangeProduct,
										$rtfeldman$elm_css$Html$Styled$fromUnstyled(
											$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
										$elm$core$List$isEmpty(
										$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
										$rtfeldman$elm_css$Html$Styled$span,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全て選択')
											])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
							]),
						_List_Nil),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date'),
												$rtfeldman$elm_css$Html$Styled$Attributes$max(model.de),
												$rtfeldman$elm_css$Html$Styled$Attributes$min(model.dh)
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateDateFrom)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-2')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								model.Z,
								model.hi,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateTimeFrom)
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('〜')
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start ml-2 mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date'),
												$rtfeldman$elm_css$Html$Styled$Attributes$max(model.de),
												$rtfeldman$elm_css$Html$Styled$Attributes$min(model.dh)
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateDateTo)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								model.Z,
								model.hj,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$HourCsv$OnUpdateTimeTo)
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
							]),
						_List_Nil),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('送信')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(0))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('併合 リスト型')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(1))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('量販 リスト型')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(2))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('アマゾン リスト型')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(3))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('楽天 リスト型')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(4))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヤフー リスト型')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$Ca6Comnet$HourCsv$OnSubmit(5))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('価格COM リスト型')
											]))
									]))
							]))
					])),
				(model.b2 || model.c5) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$Ca6Comnet$HourCsv$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Ca6Comnet$HourCsv$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '期間指定CSV';
			}
		}()
	};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnSubmit = {$: 8};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewTableControl(model),
							$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewContent, model, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pStoreGroupsHeader, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pStoreHeader, $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$pProductPriceRows);
};
var $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Common$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$Common$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Common$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$Common$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Common$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Common$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$Common$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$Common$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$Common$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$Common$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Common$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$Common$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$Common$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$Common$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$Common$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$Common$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Common$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Common$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$Common$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$Common$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$Common$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$Common$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Common$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$Common$Default$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Common$Default$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Common$Default$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Common$Default$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Common$Default$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Common$Default$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Common$Default$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Common$Default$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Common$Default$OnSubmit = {$: 8};
var $author$project$Pages$Users$Common$Default$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Common$Default$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Common$Default$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Common$Default$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$Default$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Common$Default$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Common$Default$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Common$Default$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Common$Default$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Common$Default$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Common$Default$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Common$Default$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Common$Default$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Common$Default$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Common$Default$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Common$Default$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Common$Default$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Common$Default$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Common$Default$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Common$Default$viewTableControl(model),
							$author$project$Pages$Users$Common$Default$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Common$Default$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Common$Default$viewContent, model, $author$project$Pages$Users$Common$Default$pStoreGroupsHeader, $author$project$Pages$Users$Common$Default$pStoreHeader, $author$project$Pages$Users$Common$Default$pProductPriceRows);
};
var $author$project$Pages$Users$Common$Default$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Common$Default$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Common$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Common$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Common$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Common$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Common$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Common$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$Common$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Common$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Common$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Common$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Common$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Common$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Common$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Common$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Common$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Common$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Common$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Common$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Common$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Common$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Common$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Common$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Common$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Common$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Common$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Common$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Common$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Dbi6Comnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Dbi6Comnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeMaker = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Dbi6Comnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Dbi6Comnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Dbi6Comnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Dbi6Comnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Dbi6Comnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Dbi6Comnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Dbi6Comnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Dbi6Comnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Dbi6Comnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Dbi6Comnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Dbi6Comnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Dbi6Comnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$Dbi6Comnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Dbi6Comnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Dbi6Comnet$Hour$viewContent, model, $author$project$Pages$Users$Dbi6Comnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$Dbi6Comnet$Hour$pStoreHeader, $author$project$Pages$Users$Dbi6Comnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$Dbi6Comnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Dbi6Comnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeMaker = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$MakerSelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Dbi6Comnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Dbi6Comnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Dbi6Comnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Dbi6Comnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Dbi6Comnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Dbi6Comnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$Delonghi$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$Delonghi$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$Delonghi$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$Delonghi$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$Delonghi$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$Delonghi$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$Delonghi$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$Delonghi$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$Delonghi$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$Delonghi$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Delonghi$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$Delonghi$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Delonghi$Hour$viewTableHeader = function (headerStores) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				A2(
					$elm$core$List$indexedMap,
					function (ind) {
						return function (store) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class(
										function () {
											switch (ind) {
												case 0:
													return 'numberHeader rowHeader';
												case 1:
													return 'katabanHeader rowHeader';
												case 2:
													return 'itemHeader rowHeader';
												case 3:
													return 'basePriceHeader rowHeader';
												default:
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
											}
										}())
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(store.jD)
									]));
						};
					},
					headerStores))
			]));
};
var $author$project$Pages$Users$Delonghi$Hour$viewPriceTable = function (priceTableRequired) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:1;cols:4'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('delonghi')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Delonghi$Hour$viewTableHeader(priceTableRequired.gB),
				$author$project$Pages$Users$Delonghi$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Delonghi$Hour$OnChangeBigCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$OnChangeMaker = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$OnChangeSmallCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$OnSubmit = {$: 9};
var $author$project$Pages$Users$Delonghi$Hour$OnSubmitLatest = {$: 10};
var $author$project$Pages$Users$Delonghi$Hour$OnUpdateDate = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$OnUpdateTime = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ブランド')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$Hour$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Delonghi$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Delonghi$Hour$OnToggleStore = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Users$Delonghi$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$advancedCheckbox = F2(
	function (options, label_) {
		return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2($frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$create, options, label_));
	});
var $author$project$Pages$Users$Delonghi$Hour$gatherStoresInStoreGroups = function (storeGroups) {
	var addStoreGroup = F2(
		function (sg, maybeSG) {
			if (maybeSG.$ === 1) {
				return sg;
			} else {
				var sg2 = maybeSG.a;
				return _Utils_update(
					sg,
					{
						ap: _Utils_ap(sg.ap, sg2.ap)
					});
			}
		});
	return $elm$core$Dict$values(
		A3(
			$elm$core$List$foldr,
			function (sg) {
				return A2(
					$elm$core$Dict$update,
					sg.mh,
					A2(
						$elm$core$Basics$composeL,
						$elm$core$Maybe$Just,
						addStoreGroup(sg)));
			},
			$elm$core$Dict$empty,
			storeGroups));
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Id = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id = function (theId) {
	return $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Id(theId);
};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Inline = {$: 2};
var $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline = $frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$Inline;
var $author$project$Pages$Users$Delonghi$Hour$viewStoreGroupCheckbox = F3(
	function (storeGroups, visibleStoreGroups, visibleStores) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$table,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('table table-light table-bordered viewStoreGroupCheckbox')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									var isVisible = A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups);
									return A2(
										$rtfeldman$elm_css$Html$Styled$tr,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class(
														'storeGroupHeader' + $elm$core$String$fromInt(storeGroup.mh))
													]),
												_List_fromArray(
													[
														A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$advancedCheckbox,
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id(
																'visibleStoreGroup' + $elm$core$String$fromInt(storeGroup.mh)),
																$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(isVisible),
																$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$value(
																		$elm$core$String$fromInt(storeGroup.mh)),
																		A2(
																		$rtfeldman$elm_css$Html$Styled$Events$on,
																		'click',
																		A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Delonghi$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
																	]))
															]),
														A2(
															$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$label,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text(storeGroup.mi)
																])))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$td,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('')
													]),
												A2(
													$elm$core$List$map,
													function (store) {
														return A2(
															$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$advancedCheckbox,
															_List_fromArray(
																[
																	$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id(
																	'visibleStore' + $elm$core$String$fromInt(store.hs)),
																	$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
																	A2($elm$core$Set$member, store.hs, visibleStores)),
																	$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
																	$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(!isVisible),
																	$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$value(
																			$elm$core$String$fromInt(store.hs)),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$Events$on,
																			'click',
																			A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Delonghi$Hour$OnToggleStore, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
																		]))
																]),
															A2(
																$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$label,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(store.jD)
																	])));
													},
													storeGroup.ap))
											]));
								},
								$author$project$Pages$Users$Delonghi$Hour$gatherStoresInStoreGroups(storeGroups)))
						]))
				]));
	});
var $author$project$Pages$Users$Delonghi$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$Delonghi$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Delonghi$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Delonghi$Hour$OnPressDownloadCSV = {$: 11};
var $author$project$Pages$Users$Delonghi$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Delonghi$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Delonghi$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Delonghi$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Delonghi$Hour$viewContent = F3(
	function (model, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A3(pStoreHeader_, model.U, model.L, model.X);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Delonghi$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$Pages$Users$Delonghi$Hour$viewStoreGroupCheckbox, model.U, model.L, model.X),
							$author$project$Pages$Users$Delonghi$Hour$viewTableControl(model),
							$author$project$Pages$Users$Delonghi$Hour$viewPriceTable(
							{gi: bodyProducts, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Delonghi$Hour$viewContentDefault = function (model) {
	return A3($author$project$Pages$Users$Delonghi$Hour$viewContent, model, $author$project$Pages$Users$Delonghi$Hour$pStoreHeader, $author$project$Pages$Users$Delonghi$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$Delonghi$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Delonghi$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Delonghi$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Button$button,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Button$primary,
			$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$TimeSeq$OnPressDownloadCSV)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
		]));
var $author$project$Pages$Users$Delonghi$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (product) {
							switch (product.$) {
								case 0:
									var storeName = product.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('colStoreHeader')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(storeName)
											]));
								case 1:
									var cell = product.a;
									var priceChangingTypeClass = function () {
										var _v2 = cell.fl;
										switch (_v2.$) {
											case 0:
												return 'increasedPriceTd';
											case 1:
												return 'decreasedPriceTd';
											default:
												return '';
										}
									}();
									var comparedPriceTypeClass = function () {
										var _v1 = cell.cO;
										switch (_v1) {
											case 0:
												return 'lowestPriceTd';
											case 1:
												return 'lowerPriceTd';
											case 2:
												return 'lowestButHigherThanBasePriceTd';
											default:
												return '';
										}
									}();
									return A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceTypeClass),
												$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														$author$project$Utils$Helpers$toCommmaSplited(
															$elm$core$String$fromInt(cell.aW)))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														$author$project$Utils$Helpers$toCommmaSplited(
															$elm$core$String$fromInt(cell.iZ)))
													]))
											]));
								default:
									return A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil);
							}
						},
						productList));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$viewTableHeader = function (headerDateTimes) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('rowHeader')
					]),
				A2(
					$elm$core$List$map,
					function (datetime) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(datetime)
								]));
					},
					headerDateTimes))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$viewPriceTable = function (priceTableRequired) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('priceTableContainer')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:1;cols:1')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Users$Delonghi$TimeSeq$viewTableHeader(priceTableRequired.gA),
						$author$project$Pages$Users$Delonghi$TimeSeq$viewTableBody(priceTableRequired.gi)
					]))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$CategorySelector$productSelector = F5(
	function (products, selectedBigCategory, selectedSmallCategory, selectedProduct, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									A2($elm$core$Maybe$withDefault, '-1', selectedProduct) === '-1')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('選択してください')
								])),
						A2(
							$elm$core$List$map,
							function (product) {
								return A2(
									$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(product.i8),
											$rtfeldman$elm_css$Html$Styled$Attributes$selected(
											_Utils_eq(
												A2($elm$core$Maybe$withDefault, '', selectedProduct),
												product.i8))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(product.lT)
										]));
							},
							A2(
								$elm$core$List$filter,
								function (product) {
									return _Utils_eq(
										selectedSmallCategory,
										$elm$core$Maybe$Just(-1)) || _Utils_eq(
										A2($elm$core$Maybe$withDefault, -1, selectedSmallCategory),
										product.ho);
								},
								A2(
									$elm$core$List$filter,
									function (product) {
										return _Utils_eq(
											selectedBigCategory,
											$elm$core$Maybe$Just(-1)) || _Utils_eq(
											A2($elm$core$Maybe$withDefault, -1, selectedBigCategory),
											product.ed);
									},
									products)))))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$required = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('required');
var $author$project$Pages$Users$Delonghi$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex position-relative flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A5(
						$author$project$Components$CategorySelector$productSelector,
						model.ja,
						model.he,
						model.fH,
						model.hf,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnChangeProduct),
								$rtfeldman$elm_css$Html$Styled$Attributes$required(true)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-err-msg')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								(model.c6 && $elm_community$maybe_extra$Maybe$Extra$isNothing(model.hf)) ? '商品を選択してください' : '')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$viewContent = function (model) {
	var headerDateTimes = A3(
		$author$project$Pages$Users$Delonghi$TimeSeq$pStoreHeader,
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
		model.Z);
	var currentProduct = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Utils$Helpers$defaultProduct,
		A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.i8;
				},
				$elm$core$Basics$eq(
					A2($elm$core$Maybe$withDefault, '', model.dD))),
			model.ja));
	var bodyProducts = A4(
		$author$project$Pages$Users$Delonghi$TimeSeq$pProductPriceRows,
		model.ap,
		$elm$core$List$length(headerDateTimes),
		model.ck,
		currentProduct.ka);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Delonghi$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mb-3')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('モデル:\u3000\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(currentProduct.lT)
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格:\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(currentProduct.ka))
													]))
											]))
									])),
								$author$project$Pages$Users$Delonghi$TimeSeq$viewDownloadCSVButton
							])),
						$author$project$Pages$Users$Delonghi$TimeSeq$viewPriceTable(
						{gi: bodyProducts, gA: headerDateTimes})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Delonghi$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Delonghi$TimeSeq2$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Delonghi$TimeSeq2$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$Nop = {$: 18};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeBigCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeMaker = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeSmallCategory = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnSubmit = {$: 12};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateDateFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateDateTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateTimeFrom = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateTimeTo = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('ブランド')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Delonghi$TimeSeq2$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Delonghi$TimeSeq2$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('販売店')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeStore,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.r))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Delonghi$TimeSeq2$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$TimeSeq2$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Delonghi$TimeSeq2$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnPressDownloadCSV = {$: 13};
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Delonghi$TimeSeq2$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Delonghi$TimeSeq2$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Delonghi$TimeSeq2$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Delonghi$TimeSeq2$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Delonghi$TimeSeq2$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Delonghi$TimeSeq2$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Delonghi$TimeSeq2$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Delonghi$TimeSeq2$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Delonghi$TimeSeq2$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Delonghi$TimeSeq2$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Delonghi$TimeSeq2$viewTableControl(model),
						$author$project$Pages$Users$Delonghi$TimeSeq2$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Delonghi$TimeSeq2$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Delonghi$TimeSeq2$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 新時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Denon6Comnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Denon6Comnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Denon6Comnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Denon6Comnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Denon6Comnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Denon6Comnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Denon6Comnet$Wide$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Denon6Comnet$Wide$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeBigCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeMaker = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeSmallCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnSubmit = {$: 8};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$Wide$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$Wide$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Denon6Comnet$Wide$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Denon6Comnet$Wide$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Denon6Comnet$Wide$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Denon6Comnet$Wide$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Denon6Comnet$Wide$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$OnPressDownloadCSV = {$: 9};
var $author$project$Pages$Users$Denon6Comnet$Wide$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Denon6Comnet$Wide$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Denon6Comnet$Wide$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Denon6Comnet$Wide$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Denon6Comnet$Wide$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Denon6Comnet$Wide$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Denon6Comnet$Wide$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Denon6Comnet$Wide$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Denon6Comnet$Wide$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Denon6Comnet$Wide$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Denon6Comnet$Wide$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Denon6Comnet$Wide$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Denon6Comnet$Wide$viewTableControl(model),
						$author$project$Pages$Users$Denon6Comnet$Wide$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Denon6Comnet$Wide$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Denon6Comnet$Wide$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '60分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$ElecComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$ElecComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$ElecComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$ElecComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$ElecComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$ElecComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$ElecComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ElecComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table elec-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('ヨドバシを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$ElecComnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$ElecComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ElecComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Role$Danger = 5;
var $frandibar$elm_bootstrap$Bootstrap$Alert$Shown = 0;
var $frandibar$elm_bootstrap$Bootstrap$Alert$Config = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Alert$attrs = F2(
	function (attributes, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{d9: attributes});
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$children = F2(
	function (children_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{h0: children_});
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Role$Secondary = 1;
var $frandibar$elm_bootstrap$Bootstrap$Alert$config = {d9: _List_Nil, h0: _List_Nil, a5: $elm$core$Maybe$Nothing, hd: 1, B: 0, bD: false};
var $frandibar$elm_bootstrap$Bootstrap$Alert$role = F2(
	function (role_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{hd: role_});
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$Closed = 3;
var $frandibar$elm_bootstrap$Bootstrap$Alert$StartClose = 1;
var $frandibar$elm_bootstrap$Bootstrap$Alert$clickHandler = F2(
	function (visibility, configRec) {
		var handleClick = F2(
			function (viz, toMsg) {
				return $rtfeldman$elm_css$Html$Styled$Events$onClick(
					toMsg(viz));
			});
		var _v0 = configRec.a5;
		if (!_v0.$) {
			var dismissMsg = _v0.a;
			return _List_fromArray(
				[
					configRec.bD ? A2(handleClick, 1, dismissMsg) : A2(handleClick, 3, dismissMsg)
				]);
		} else {
			return _List_Nil;
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$injectButton = F2(
	function (btn, children_) {
		if (children_.b) {
			var head = children_.a;
			var tail = children_.b;
			return A2(
				$elm$core$List$cons,
				head,
				A2($elm$core$List$cons, btn, tail));
		} else {
			return _List_fromArray(
				[btn]);
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$isDismissable = function (configRec) {
	var _v0 = configRec.a5;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton = F3(
	function (visibilty, configRec, children_) {
		return $frandibar$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec) ? A2(
			$frandibar$elm_bootstrap$Bootstrap$Alert$injectButton,
			A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_Utils_ap(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$type_('button'),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('close'),
							A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-label', 'close')
						]),
					A2($frandibar$elm_bootstrap$Bootstrap$Alert$clickHandler, visibilty, configRec)),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'aria-hidden', 'true')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('×')
							]))
					])),
			children_) : children_;
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$viewAttributes = F2(
	function (visibility, configRec) {
		var visibiltyAttributes = (visibility === 3) ? _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'none')
			]) : _List_Nil;
		var animationAttributes = function () {
			if (configRec.bD) {
				var _v0 = configRec.a5;
				if (!_v0.$) {
					var dismissMsg = _v0.a;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$Events$on,
							'transitionend',
							$elm$json$Json$Decode$succeed(
								dismissMsg(3)))
						]);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		}();
		var alertAttributes = _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'alert'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('alert', true),
						_Utils_Tuple2(
						'alert-dismissible',
						$frandibar$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec)),
						_Utils_Tuple2('fade', configRec.bD),
						_Utils_Tuple2('show', !visibility)
					])),
				A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'alert', configRec.hd)
			]);
		return $elm$core$List$concat(
			_List_fromArray(
				[configRec.d9, alertAttributes, visibiltyAttributes, animationAttributes]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$view = F2(
	function (visibility, _v0) {
		var configRec = _v0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			A2($frandibar$elm_bootstrap$Bootstrap$Alert$viewAttributes, visibility, configRec),
			A3($frandibar$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton, visibility, configRec, configRec.h0));
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$simple = F3(
	function (role_, attributes, children_) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Alert$view,
			0,
			A2(
				$frandibar$elm_bootstrap$Bootstrap$Alert$children,
				children_,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Alert$attrs,
					attributes,
					A2($frandibar$elm_bootstrap$Bootstrap$Alert$role, role_, $frandibar$elm_bootstrap$Bootstrap$Alert$config))));
	});
var $frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger = $frandibar$elm_bootstrap$Bootstrap$Alert$simple(5);
var $author$project$Pages$Users$ElecComnet$Excel$Nop = {$: 13};
var $author$project$Pages$Users$ElecComnet$Excel$OnChangeBigCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$OnChangeSmallCategory = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$OnSubmit = {$: 10};
var $author$project$Pages$Users$ElecComnet$Excel$OnUpdateDateFrom = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$OnUpdateDateTo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$OnUpdateTimeFrom = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$OnUpdateTimeTo = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ElecComnet$Excel$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$Excel$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$Excel$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$ElecComnet$Excel$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$ElecComnet$Excel$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ElecComnet$Excel$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$Excel$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ElecComnet$Excel$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$Excel$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ElecComnet$Excel$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$ElecComnet$Excel$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$ElecComnet$Excel$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$ElecComnet$Excel$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ElecComnet$Excel$viewContent(model),
		hx: 'エクセル出力'
	};
};
var $author$project$Pages$Users$ElecComnet$Hour$view = $author$project$Pages$Users$Common$Default$view;
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Button$button,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Button$primary,
			$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ElecComnet$TimeSeq$OnPressDownloadCSV)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
		]));
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (product) {
							switch (product.$) {
								case 0:
									var storeName = product.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('colStoreHeader')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(storeName)
											]));
								case 1:
									var cell = product.a;
									var priceChangingTypeClass = function () {
										var _v2 = cell.fl;
										switch (_v2.$) {
											case 0:
												return 'increasedPriceTd';
											case 1:
												return 'decreasedPriceTd';
											default:
												return '';
										}
									}();
									var comparedPriceTypeClass = function () {
										var _v1 = cell.cO;
										switch (_v1) {
											case 0:
												return 'lowestPriceTd';
											case 1:
												return 'lowerPriceTd';
											case 2:
												return 'lowestButHigherThanBasePriceTd';
											default:
												return '';
										}
									}();
									return A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceTypeClass),
												$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(cell.aW))
											]));
								default:
									return A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil);
							}
						},
						productList));
			},
			bodyProducts));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewTableHeader = function (headerDateTimes) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('rowHeader')
					]),
				A2(
					$elm$core$List$map,
					function (datetime) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(datetime)
								]));
					},
					headerDateTimes))
			]));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('priceTableContainer')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:1;cols:1')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Users$ElecComnet$TimeSeq$viewTableHeader(priceTableRequired.gA),
						$author$project$Pages$Users$ElecComnet$TimeSeq$viewTableBody(priceTableRequired.gi)
					]))
			]));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex position-relative flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A5(
						$author$project$Components$CategorySelector$productSelector,
						model.ja,
						model.he,
						model.fH,
						model.hf,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnChangeProduct),
								$rtfeldman$elm_css$Html$Styled$Attributes$required(true)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-err-msg')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								(model.c6 && $elm_community$maybe_extra$Maybe$Extra$isNothing(model.hf)) ? '商品を選択してください' : '')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ElecComnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ElecComnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$viewContent = function (model) {
	var headerDateTimes = A3(
		$author$project$Pages$Users$ElecComnet$TimeSeq$pStoreHeader,
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
		model.Z);
	var currentProduct = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Utils$Helpers$defaultProduct,
		A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.i8;
				},
				$elm$core$Basics$eq(
					A2($elm$core$Maybe$withDefault, '', model.dD))),
			model.ja));
	var bodyProducts = A4(
		$author$project$Pages$Users$ElecComnet$TimeSeq$pProductPriceRows,
		model.ap,
		$elm$core$List$length(headerDateTimes),
		model.ck,
		currentProduct.ka);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$ElecComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mb-3')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('モデル:\u3000\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(currentProduct.lT)
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格:\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(currentProduct.ka))
													]))
											]))
									])),
								$author$project$Pages$Users$ElecComnet$TimeSeq$viewDownloadCSVButton
							])),
						$author$project$Pages$Users$ElecComnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, gA: headerDateTimes})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$ElecComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ElecComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$FujiComnet$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$FujiComnet$Day$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'dayHeader';
																	case 3:
																		return 'itemHeader';
																	case 4:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$FujiComnet$Day$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'dayHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$FujiComnet$Day$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$FujiComnet$Day$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$FujiComnet$Day$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$FujiComnet$Day$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$FujiComnet$Day$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$FujiComnet$Day$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$FujiComnet$Day$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$FujiComnet$Day$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('本日分を表示')
					]))
			]));
};
var $author$project$Pages$Users$FujiComnet$Day$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$FujiComnet$Day$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$FujiComnet$Day$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$FujiComnet$Day$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$FujiComnet$Day$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$FujiComnet$Day$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$FujiComnet$Day$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$FujiComnet$Day$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$FujiComnet$Day$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$FujiComnet$Day$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$FujiComnet$Day$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$FujiComnet$Day$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$FujiComnet$Day$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$FujiComnet$Day$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$FujiComnet$Day$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$FujiComnet$Day$viewTableControl(model),
							$author$project$Pages$Users$FujiComnet$Day$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$FujiComnet$Day$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$FujiComnet$Day$viewContent, model, $author$project$Pages$Users$FujiComnet$Day$pStoreGroupsHeader, $author$project$Pages$Users$FujiComnet$Day$pStoreHeader, $author$project$Pages$Users$FujiComnet$Day$pProductPriceRows);
};
var $author$project$Pages$Users$FujiComnet$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$FujiComnet$Day$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '中古取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 5:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi24Comnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi24Comnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Hi24Comnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi24Comnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi24Comnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi24Comnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi24Comnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi24Comnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Hi24Comnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi24Comnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$Hi24Comnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Hi24Comnet$Hour$viewContent, model, $author$project$Pages$Users$Hi24Comnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$Hi24Comnet$Hour$pStoreHeader, $author$project$Pages$Users$Hi24Comnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$Hi24Comnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi24Comnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$TenAM$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi24Comnet$TenAM$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$Nop = {$: 18};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeBigCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeSmallCategory = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnSubmit = {$: 12};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnUpdateDateFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnUpdateDateTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Hi24Comnet$TenAM$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('量販店')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeStore,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.r))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Hi24Comnet$TenAM$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$TenAM$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$TenAM$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$TenAM$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi24Comnet$TenAM$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnPressDownloadCSV = {$: 13};
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$TenAM$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi24Comnet$TenAM$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi24Comnet$TenAM$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$TenAM$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi24Comnet$TenAM$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi24Comnet$TenAM$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Hi24Comnet$TenAM$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Hi24Comnet$TenAM$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Hi24Comnet$TenAM$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Hi24Comnet$TenAM$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Hi24Comnet$TenAM$viewTableControl(model),
						$author$project$Pages$Users$Hi24Comnet$TenAM$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TenAM$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi24Comnet$TenAM$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Hi24Comnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi24Comnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Hi24Comnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Hi24Comnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi24Comnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnSubmit = {$: 8};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewTableControl(model),
							$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewContent, model, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pStoreGroupsHeader, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pStoreHeader, $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$pProductPriceRows);
};
var $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 5:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi3Comnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi3Comnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi3Comnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi3Comnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi3Comnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi3Comnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Hi3Comnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi3Comnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi3Comnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi3Comnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi3Comnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi3Comnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi3Comnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi3Comnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Hi3Comnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Hi3Comnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$Hi3Comnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Hi3Comnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Hi3Comnet$Hour$viewContent, model, $author$project$Pages$Users$Hi3Comnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$Hi3Comnet$Hour$pStoreHeader, $author$project$Pages$Users$Hi3Comnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$Hi3Comnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi3Comnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Hi3Comnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hi3Comnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Hi3Comnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Hi3Comnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Hi3Comnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hi3Comnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hi3Comnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Hisense$Amazon$OnChangeCategory = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$OnChangeDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$OnChangeTime = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hisense$Amazon$OnPressDownloadCSV = {$: 7};
var $author$project$Pages$Users$Hisense$Amazon$OnSubmit = {$: 3};
var $author$project$Pages$Users$Hisense$Amazon$categorySelector = F2(
	function (selectedCategory, attrs) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							_Utils_eq(selectedCategory, -1))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('カテゴリを選択')
						])),
				A2(
					$elm$core$List$indexedMap,
					function (ind) {
						return function (cate) {
							return A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(
										$elm$core$String$fromInt(ind + 1)),
										$rtfeldman$elm_css$Html$Styled$Attributes$selected(
										_Utils_eq(ind + 1, selectedCategory))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(cate)
									]));
						};
					},
					$author$project$Pages$Users$Hisense$Amazon$categories)));
	});
var $author$project$Pages$Users$Hisense$Amazon$rankingLinkPopup = function (content) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$span,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('popoverBottom')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(content)
			]));
};
var $author$project$Pages$Users$Hisense$Amazon$viewTable = function (tableRows) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('normalTable')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$thead,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$tr,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (header) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(header)
										]));
							},
							$author$project$Pages$Users$Hisense$Amazon$tableHeader))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					function (row) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$a,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$href(row.dS),
													$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													$elm$core$String$fromInt(row.g6))
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											A2(
												$elm$core$Maybe$withDefault,
												'',
												A2($elm_community$list_extra$List$Extra$getAt, row.gk - 1, $author$project$Pages$Users$Hisense$Amazon$categories)))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('popoverParent')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('dotUnderline')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													$elm$core$String$isEmpty(row.eS) ? 'Spec, etc.' : row.eS)
												])),
											$author$project$Pages$Users$Hisense$Amazon$rankingLinkPopup(row.jI)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(row.ge)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$elm$core$String$fromFloat(row.g8))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$elm$core$String$fromInt(row.g7))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$elm$core$String$fromInt(row.gO))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(row.lg)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											A2($elm$core$Maybe$withDefault, '', row.gN))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$td,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(row.go)
										]))
								]));
					},
					tableRows))
			]));
};
var $author$project$Pages$Users$Hisense$Amazon$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('カテゴリ')
									])),
								A2(
								$author$project$Pages$Users$Hisense$Amazon$categorySelector,
								model.cp,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$Amazon$OnChangeCategory)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$Amazon$OnChangeDate)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								60,
								model.aX,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$Amazon$OnChangeTime)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$Amazon$OnSubmit),
								$frandibar$elm_bootstrap$Bootstrap$Button$disabled(
								_Utils_eq(model.cp, -1))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				model.b1 ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				function () {
				if ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar)) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]));
				} else {
					var _v0 = model.dI;
					if (!_v0.$) {
						var tableRows = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mt-3')
								]),
							_List_fromArray(
								[
									A2(
									$frandibar$elm_bootstrap$Bootstrap$Button$button,
									_List_fromArray(
										[
											$frandibar$elm_bootstrap$Bootstrap$Button$primary,
											$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$Amazon$OnPressDownloadCSV)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-3')
										]),
									_List_Nil),
									$author$project$Pages$Users$Hisense$Amazon$viewTable(tableRows)
								]));
					} else {
						return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
					}
				}
			}()
			]));
};
var $author$project$Pages$Users$Hisense$Amazon$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$Amazon$viewContent(model),
		hx: 'Amazon ランキング'
	};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$Hisense$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$Hisense$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$Hisense$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$Hisense$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$Hisense$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$Hisense$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$Hisense$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$Hisense$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$Hisense$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$Hisense$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$Hisense$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$Hisense$Day$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Common$Default$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(filteredItemList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Common$Default$ProductPriceData(
									{eP: price.eP, gK: false, gM: false, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Common$Default$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Hisense$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 3, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hisense$Day$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hisense$Day$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Hisense$Day$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Hisense$Day$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Hisense$Day$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('大分類')
									])),
								A3(
								$author$project$Components$CategorySelector$bigCategorySelector,
								model.hR,
								model.he,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnChangeBigCategory)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('小分類')
									])),
								A4(
								$author$project$Components$CategorySelector$smallCategorySelector,
								model.jy,
								model.fH,
								model.he,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnChangeSmallCategory)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$Default$OnUpdateDate)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnSubmit)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				model.c5 ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Common$Default$viewTableControl(model),
						$author$project$Pages$Users$Common$Default$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hisense$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$Day$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Hisense$Excel$Nop = {$: 13};
var $author$project$Pages$Users$Hisense$Excel$OnChangeBigCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$OnChangeSmallCategory = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$OnSubmit = {$: 10};
var $author$project$Pages$Users$Hisense$Excel$OnUpdateDateTo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$OnUpdateTimeTo = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hisense$Excel$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$Excel$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$Excel$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$Hisense$Excel$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Hisense$Excel$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$Excel$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$Excel$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$Excel$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Hisense$Excel$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Hisense$Excel$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$Hisense$Excel$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$Excel$viewContent(model),
		hx: 'エクセル出力'
	};
};
var $author$project$Pages$Users$Hisense$Review$Submit = {$: 3};
var $author$project$Pages$Users$Hisense$Review$UpdateFromDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$Hisense$Review$UpdateToDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$Hisense$Review$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('開始日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bc),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '300px')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$Review$UpdateFromDate)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('終了日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.by),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '300px')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$Review$UpdateToDate)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Button$primary,
										$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$Review$Submit)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('エクセルでダウンロード')
									]))
							]))
					]))
			]));
};
var $author$project$Pages$Users$Hisense$Review$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$Review$viewContent(model),
		hx: '価格COM レビュー'
	};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$Hisense$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Button$button,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Button$primary,
			$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$TimeSeq$OnPressDownloadCSV)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
		]));
var $author$project$Pages$Users$Hisense$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (product) {
							switch (product.$) {
								case 0:
									var storeName = product.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('colStoreHeader')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(storeName)
											]));
								case 1:
									var cell = product.a;
									var priceChangingTypeClass = function () {
										var _v2 = cell.fl;
										switch (_v2.$) {
											case 0:
												return 'increasedPriceTd';
											case 1:
												return 'decreasedPriceTd';
											default:
												return '';
										}
									}();
									var comparedPriceTypeClass = function () {
										var _v1 = cell.cO;
										switch (_v1) {
											case 0:
												return 'lowestPriceTd';
											case 1:
												return 'lowerPriceTd';
											case 2:
												return 'lowestButHigherThanBasePriceTd';
											default:
												return '';
										}
									}();
									return A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceTypeClass),
												$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(cell.aW))
											]));
								default:
									return A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil);
							}
						},
						productList));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Hisense$TimeSeq$viewTableHeader = function (headerDateTimes) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('rowHeader')
					]),
				A2(
					$elm$core$List$map,
					function (datetime) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(datetime)
								]));
					},
					headerDateTimes))
			]));
};
var $author$project$Pages$Users$Hisense$TimeSeq$viewPriceTable = function (priceTableRequired) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('priceTableContainer')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:1;cols:1'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('hisense')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Users$Hisense$TimeSeq$viewTableHeader(priceTableRequired.gA),
						$author$project$Pages$Users$Hisense$TimeSeq$viewTableBody(priceTableRequired.gi)
					]))
			]));
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$Hisense$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$Hisense$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex position-relative flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A5(
						$author$project$Components$CategorySelector$productSelector,
						model.ja,
						model.he,
						model.fH,
						model.hf,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnChangeProduct),
								$rtfeldman$elm_css$Html$Styled$Attributes$required(true)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-err-msg')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								(model.c6 && $elm_community$maybe_extra$Maybe$Extra$isNothing(model.hf)) ? '商品を選択してください' : '')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Hisense$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Hisense$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$Hisense$TimeSeq$viewContent = function (model) {
	var headerDateTimes = A3(
		$author$project$Pages$Users$Hisense$TimeSeq$pStoreHeader,
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
		model.Z);
	var currentProduct = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Utils$Helpers$defaultProduct,
		A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.i8;
				},
				$elm$core$Basics$eq(
					A2($elm$core$Maybe$withDefault, '', model.dD))),
			model.ja));
	var bodyProducts = A4(
		$author$project$Pages$Users$Hisense$TimeSeq$pProductPriceRows,
		model.ap,
		$elm$core$List$length(headerDateTimes),
		model.ck,
		currentProduct.eb);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$Hisense$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mb-3')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('モデル:\u3000\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(currentProduct.lT)
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格:\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(currentProduct.eb))
													]))
											]))
									])),
								$author$project$Pages$Users$Hisense$TimeSeq$viewDownloadCSVButton,
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5'),
												$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('lowerPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格を下回るものはピンク')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('lowestPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格を下回っていて，最小値の場合は赤')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5'),
												$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('decreasedPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('価格が低くなったら赤枠')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('increasedPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('価格が高くなったら青枠')
													]))
											]))
									]))
							])),
						$author$project$Pages$Users$Hisense$TimeSeq$viewPriceTable(
						{gi: bodyProducts, gA: headerDateTimes})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hisense$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$Hisense$TvHour$pProductPriceRows = function (model) {
	var stores = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ap;
		},
		A2(
			$elm$core$List$filter,
			function (storeGroup) {
				return A2($elm$core$Set$member, storeGroup.mh, model.L) && (!$elm$core$List$isEmpty(storeGroup.ap));
			},
			model.U));
	var products = model.ja;
	return A2(
		$elm$core$List$indexedMap,
		function (ind) {
			return function (product) {
				var lowestPrice = A3(
					$elm$core$List$foldl,
					function (price) {
						return function (m) {
							return A2($elm$core$Basics$min, m, price);
						};
					},
					A2($elm$core$Basics$pow, 2, 30),
					A2(
						$elm$core$List$map,
						function (maybePrice) {
							if (!maybePrice.$) {
								var price = maybePrice.a;
								return price.eP ? price.aW : A2($elm$core$Basics$pow, 2, 30);
							} else {
								return A2($elm$core$Basics$pow, 2, 30);
							}
						},
						A2(
							$elm$core$List$map,
							function (store) {
								return A2(
									$elm$core$Dict$get,
									_Utils_Tuple2(product.i8, store.hs),
									model.ck);
							},
							stores)));
				var itemList = _List_fromArray(
					[
						{iF: '税抜き', i: 0},
						{iF: '税込み', i: 1},
						{iF: 'P/クーポン', i: 2},
						{iF: 'P後', i: 3},
						{iF: '取得時刻', i: 4}
					]);
				var filterItemList = function (list) {
					return A2(
						$elm$core$List$map,
						function (i) {
							return A2(
								$elm$core$Maybe$withDefault,
								{iF: '', i: -1},
								A2($elm_community$list_extra$List$Extra$getAt, i, list));
						},
						A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								'DisplayAll',
								_List_fromArray(
									[0, 1, 2, 3, 4])),
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (dispToValue) {
									return _Utils_eq(dispToValue.a, model.cq);
								},
								$author$project$Pages$Users$Common$Default$displayStyleToValues)).b);
				};
				var filteredItemList = filterItemList(itemList);
				var _v0 = {j9: product.j9, ka: product.ka, eb: product.eb, bM: product.bM};
				var basePrice = _v0.ka;
				var basePriceIncludeTax = _v0.eb;
				var basePoint = _v0.j9;
				var basePriceWithoutPoint = _v0.bM;
				var basePriceList = _List_fromArray(
					[
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePrice)),
						i: 0
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceIncludeTax)),
						i: 1
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePoint)),
						i: 2
					},
						{
						iF: $author$project$Utils$Helpers$toCommmaSplited(
							$elm$core$String$fromInt(basePriceWithoutPoint)),
						i: 3
					},
						{iF: '-', i: 4}
					]);
				var filteredBasePriceList = filterItemList(basePriceList);
				return _Utils_ap(
					_List_fromArray(
						[
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{
									iF: $elm$core$String$fromInt(ind + 1),
									i: 0
								}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(
							_List_fromArray(
								[
									{iF: product.lT, i: 0}
								])),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(filteredItemList),
							$author$project$Pages$Users$Common$Default$ProductPriceHeader(filteredBasePriceList)
						]),
					A2(
						$elm$core$List$map,
						function (store) {
							var maybePrice = A2(
								$elm$core$Dict$get,
								_Utils_Tuple2(product.i8, store.hs),
								model.ck);
							var isLowest = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_eq(price.aW, lowestPrice);
								} else {
									return false;
								}
							}();
							var isLowestButHigherThanBase = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return isLowest && (_Utils_cmp(lowestPrice, product.bM) > -1);
								} else {
									return false;
								}
							}();
							var isLower = function () {
								if (!maybePrice.$) {
									var price = maybePrice.a;
									return _Utils_cmp(price.aW, product.bM) < 0;
								} else {
									return false;
								}
							}();
							if (!maybePrice.$) {
								var price = maybePrice.a;
								var priceList = _List_fromArray(
									[
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i3)),
										i: 0
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.i4)),
										i: 1
									},
										{
										iF: function (point) {
											return _Utils_ap(
												point,
												(!price.h3) ? '' : (' / ' + $elm$core$String$fromInt(price.h3)));
										}(
											$elm$core$String$fromInt(price.iZ)),
										i: 2
									},
										{
										iF: $author$project$Utils$Helpers$toCommmaSplited(
											$elm$core$String$fromInt(price.aW)),
										i: 3
									},
										{
										iF: A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(
												A2(
													$elm$core$Maybe$withDefault,
													_List_Nil,
													$elm$core$List$tail(
														A2($elm$core$String$split, ' ', price.kw))))),
										i: 4
									}
									]);
								var filteredPriceList = filterItemList(priceList);
								return $author$project$Pages$Users$Common$Default$ProductPriceData(
									{eP: price.eP, gK: isLower, gM: isLowest, iB: isLowestButHigherThanBase, g2: filteredPriceList, dS: price.dS});
							} else {
								return $author$project$Pages$Users$Common$Default$ProductPriceNoData;
							}
						},
						stores));
			};
		},
		products);
};
var $author$project$Pages$Users$Hisense$TvHour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hisense$TvHour$pStoreHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				function (str) {
					return {mh: -1, jD: str};
				},
				_List_fromArray(
					['#', '型番', '項目', '基準価格'])),
			A2(
				$elm$core$List$concatMap,
				function (storeGroup) {
					return A2(
						$elm$core$List$map,
						function (store) {
							return {mh: storeGroup.mh, jD: store.h6};
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Hisense$TvHour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Common$Default$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Common$Default$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Common$Default$viewDownloadCSVButton,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('lowerPriceTd')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('基準価格を下回るものはピンク')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('lowestPriceTd')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('基準価格を下回っていて，最小値の場合は赤')
							]))
					]))
			]));
};
var $author$project$Pages$Users$Hisense$TvHour$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Hisense$TvHour$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Hisense$TvHour$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Hisense$TvHour$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$Default$OnUpdateDate)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								model.Z,
								model.aX,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$Default$OnUpdateTime)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnSubmit)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$Default$OnSubmitLatest)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
							]))
					])),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				model.c5 ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('hisense')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Users$Hisense$TvHour$viewTableControl(model),
						$author$project$Pages$Users$Common$Default$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$Hisense$TvHour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Hisense$TvHour$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Button$button,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Button$primary,
			$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$JvcComnet$TimeSeq$OnPressDownloadCSV)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
		]));
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (productList) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (product) {
							switch (product.$) {
								case 0:
									var storeName = product.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('colStoreHeader')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(storeName)
											]));
								case 1:
									var cell = product.a;
									var priceChangingTypeClass = function () {
										var _v2 = cell.fl;
										switch (_v2.$) {
											case 0:
												return 'increasedPriceTd';
											case 1:
												return 'decreasedPriceTd';
											default:
												return '';
										}
									}();
									var comparedPriceTypeClass = function () {
										var _v1 = cell.cO;
										switch (_v1) {
											case 0:
												return 'lowestPriceTd';
											case 1:
												return 'lowerPriceTd';
											case 2:
												return 'lowestButHigherThanBasePriceTd';
											default:
												return '';
										}
									}();
									return A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceTypeClass),
												$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(cell.aW))
											]));
								default:
									return A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil);
							}
						},
						productList));
			},
			bodyProducts));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewTableHeader = function (headerDateTimes) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('rowHeader')
					]),
				A2(
					$elm$core$List$map,
					function (datetime) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(datetime)
								]));
					},
					headerDateTimes))
			]));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('priceTableContainer')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:1;cols:1')
					]),
				_List_fromArray(
					[
						$author$project$Pages$Users$JvcComnet$TimeSeq$viewTableHeader(priceTableRequired.gA),
						$author$project$Pages$Users$JvcComnet$TimeSeq$viewTableBody(priceTableRequired.gi)
					]))
			]));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex position-relative flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A5(
						$author$project$Components$CategorySelector$productSelector,
						model.ja,
						model.he,
						model.fH,
						model.hf,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnChangeProduct),
								$rtfeldman$elm_css$Html$Styled$Attributes$required(true)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-err-msg')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								(model.c6 && $elm_community$maybe_extra$Maybe$Extra$isNothing(model.hf)) ? '商品を選択してください' : '')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$JvcComnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$JvcComnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$viewContent = function (model) {
	var headerDateTimes = A3(
		$author$project$Pages$Users$JvcComnet$TimeSeq$pStoreHeader,
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dB, model.dE),
		A2($author$project$Utils$Helpers$dateTimeToParts, model.dC, model.dF),
		model.Z);
	var currentProduct = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Utils$Helpers$defaultProduct,
		A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.i8;
				},
				$elm$core$Basics$eq(
					A2($elm$core$Maybe$withDefault, '', model.dD))),
			model.ja));
	var bodyProducts = A4(
		$author$project$Pages$Users$JvcComnet$TimeSeq$pProductPriceRows,
		model.ap,
		$elm$core$List$length(headerDateTimes),
		model.ck,
		currentProduct.eb);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$JvcComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mb-3')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('モデル:\u3000\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(currentProduct.lT)
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格:\u3000')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(currentProduct.eb))
													]))
											]))
									])),
								$author$project$Pages$Users$JvcComnet$TimeSeq$viewDownloadCSVButton,
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5'),
												$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('lowerPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格を下回るものはピンク')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('lowestButHigherThanBasePriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格を上回っているが最小値の場合は緑')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('lowestPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格を下回っていて，最小値の場合は赤')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-5'),
												$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('decreasedPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('価格が低くなったら赤枠')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('increasedPriceTd')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('価格が高くなったら青枠')
													]))
											]))
									]))
							])),
						$author$project$Pages$Users$JvcComnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, gA: headerDateTimes})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$JvcComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$JvcComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateAllProducts = {$: 9};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateCouponTriggerFlag = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdatePointTriggerFlag = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$Drop = {$: 13};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$KinujoComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$KinujoComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 12, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$KinujoComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$KinujoComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$KinujoComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$KinujoComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.bz),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdatePointTriggerFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.bz),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateCouponTriggerFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$KinujoComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table tescom-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('ヨドバシを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('トリガーを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('P算入後に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('トリガーを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('クーポン算入後に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$KinujoComnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$KinujoComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$KinujoComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$KinujoComnet$Excel$Nop = {$: 13};
var $author$project$Pages$Users$KinujoComnet$Excel$OnChangeBigCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnChangeSmallCategory = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnSubmit = {$: 10};
var $author$project$Pages$Users$KinujoComnet$Excel$OnUpdateDateFrom = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnUpdateDateTo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnUpdateTimeFrom = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$OnUpdateTimeTo = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Excel$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$KinujoComnet$Excel$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$KinujoComnet$Excel$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Excel$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$KinujoComnet$Excel$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$KinujoComnet$Excel$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$KinujoComnet$Excel$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$KinujoComnet$Excel$view = function (model) {
	return {
		ko: $author$project$Pages$Users$KinujoComnet$Excel$viewContent(model),
		hx: 'エクセル出力'
	};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$KinujoComnet$Fifteen$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$KinujoComnet$Fifteen$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnSubmit = {$: 8};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$KinujoComnet$Fifteen$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$KinujoComnet$Fifteen$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$KinujoComnet$Fifteen$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$KinujoComnet$Fifteen$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$KinujoComnet$Fifteen$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$KinujoComnet$Fifteen$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$KinujoComnet$Fifteen$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$KinujoComnet$Fifteen$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$KinujoComnet$Fifteen$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$KinujoComnet$Fifteen$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$KinujoComnet$Fifteen$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$KinujoComnet$Fifteen$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$KinujoComnet$Fifteen$viewTableControl(model),
							$author$project$Pages$Users$KinujoComnet$Fifteen$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$KinujoComnet$Fifteen$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$KinujoComnet$Fifteen$viewContent, model, $author$project$Pages$Users$KinujoComnet$Fifteen$pStoreGroupsHeader, $author$project$Pages$Users$KinujoComnet$Fifteen$pStoreHeader, $author$project$Pages$Users$KinujoComnet$Fifteen$pProductPriceRows);
};
var $author$project$Pages$Users$KinujoComnet$Fifteen$view = function (model) {
	return {
		ko: $author$project$Pages$Users$KinujoComnet$Fifteen$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$MlnComnet$AutoReload$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$MlnComnet$AutoReload$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$OnChangeDisplayStyle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$AutoReload$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column mr-3')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$MlnComnet$AutoReload$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$OnPressDownloadCSV = {$: 4};
var $author$project$Pages$Users$MlnComnet$AutoReload$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$AutoReload$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$MlnComnet$AutoReload$OnChangeSortOption = function (a) {
	return {$: 3, a: a};
};
var $author$project$Utils$Types$ProductNameAsc = 1;
var $author$project$Components$Molecules$SortTableSelector$decodeSortOption = function (sortOption) {
	switch (sortOption) {
		case 'release-date-desc':
			return $elm$core$Maybe$Just(0);
		case 'product-name-asc':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Molecules$SortTableSelector$encodeSortOption = function (sortOption) {
	if (!sortOption) {
		return 'release-date-desc';
	} else {
		return 'product-name-asc';
	}
};
var $author$project$Components$Molecules$SortTableSelector$toStringSortOption = function (sortOption) {
	if (!sortOption) {
		return '登録日順';
	} else {
		return '形名順';
	}
};
var $author$project$Components$Molecules$SortTableSelector$sortOptions = _List_fromArray(
	[
		_Utils_Tuple2(
		$author$project$Components$Molecules$SortTableSelector$encodeSortOption(0),
		$author$project$Components$Molecules$SortTableSelector$toStringSortOption(0)),
		_Utils_Tuple2(
		$author$project$Components$Molecules$SortTableSelector$encodeSortOption(1),
		$author$project$Components$Molecules$SortTableSelector$toStringSortOption(1))
	]);
var $author$project$Components$Molecules$SortTableSelector$sortTableSelector = function (props) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
		_List_fromArray(
			[
				A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled(true),
				function (onChange) {
					return $frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange(
						A2(
							$elm$core$Basics$composeR,
							$author$project$Components$Molecules$SortTableSelector$decodeSortOption,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$withDefault(0),
								onChange)));
				},
				props.iS),
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(_List_Nil)
			]),
		A2(
			$elm$core$List$map,
			function (_v0) {
				var v = _v0.a;
				var label = _v0.b;
				return A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							_Utils_eq(
								v,
								A3(
									$elm_community$maybe_extra$Maybe$Extra$unwrap,
									$author$project$Components$Molecules$SortTableSelector$encodeSortOption(0),
									$author$project$Components$Molecules$SortTableSelector$encodeSortOption,
									props.js)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label)
						]));
			},
			$author$project$Components$Molecules$SortTableSelector$sortOptions));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$viewSortSelector = function (selectedSortOption) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示順')
					])),
				$author$project$Components$Molecules$SortTableSelector$sortTableSelector(
				{
					iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$AutoReload$OnChangeSortOption),
					js: $elm$core$Maybe$Just(selectedSortOption)
				})
			]));
};
var $author$project$Pages$Users$MlnComnet$AutoReload$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$MlnComnet$AutoReload$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewTableControl = F4(
	function (storeGroups, visibleStoreGroups, selectedDisplayStyle, selectedSortOption) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Users$MlnComnet$AutoReload$viewStoreGroupCheckbox, storeGroups, visibleStoreGroups),
					$author$project$Pages$Users$MlnComnet$AutoReload$viewDisplayStyleSelector(selectedDisplayStyle),
					$author$project$Pages$Users$MlnComnet$AutoReload$viewSortSelector(selectedSortOption),
					$author$project$Pages$Users$MlnComnet$AutoReload$viewDownloadCSVButton
				]));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					function () {
					var _v0 = model.br;
					switch (_v0.$) {
						case 0:
							return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
						case 1:
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Components$Loading$loading(30.0)
									]));
						case 3:
							var res = _v0.a;
							var headerStores = A2(pStoreHeader_, res.U, model.L);
							var headerStoreGroups = A2(pStoreGroupsHeader_, res.U, model.L);
							var bodyProducts = A4(pProductPriceRows_, res, model.L, model.cq, model.js);
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										A4($author$project$Pages$Users$MlnComnet$AutoReload$viewTableControl, res.U, model.L, model.cq, model.js),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row description')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowerPriceTd')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('参考価格を下回る')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowestPriceTd')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('当該機種の最低価格')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowestButHigherThanBasePriceTd')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('最低価格だが参考価格より上')
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 pastPriceTd')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('当該時刻に取得できず参考として前回価格を表示')
															]))
													]))
											])),
										$author$project$Pages$Users$MlnComnet$AutoReload$viewPriceTable(
										{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
									]));
						default:
							var e = _v0.a;
							return A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(e)
									]));
					}
				}()
				]));
	});
var $author$project$Pages$Users$MlnComnet$AutoReload$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$MlnComnet$AutoReload$viewContent, model, $author$project$Pages$Users$MlnComnet$AutoReload$pStoreGroupsHeader, $author$project$Pages$Users$MlnComnet$AutoReload$pStoreHeader, $author$project$Pages$Users$MlnComnet$AutoReload$pProductPriceRows);
};
var $author$project$Pages$Users$MlnComnet$AutoReload$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$AutoReload$viewContentDefault(model),
		hx: 'WEB価格(自動更新)'
	};
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$OnChangeSelectedSortOption = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$OnInputQuery = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp = F2(
	function (fn, arg) {
		return $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled(
			fn(arg));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$lazy = F2(
	function (fn, arg) {
		return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
			A3($elm$virtual_dom$VirtualDom$lazy2, $rtfeldman$elm_css$VirtualDom$Styled$lazyHelp, fn, arg));
	});
var $rtfeldman$elm_css$Html$Styled$Lazy$lazy = $rtfeldman$elm_css$VirtualDom$Styled$lazy;
var $author$project$Pages$Users$MlnComnet$ChangeMail$OnChangeMail = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$MlnComnet$ChangeMail$UpdateMail = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$Email = 8;
var $frandibar$elm_bootstrap$Bootstrap$Form$Input$email = $frandibar$elm_bootstrap$Bootstrap$Form$Input$input(8);
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Info = 3;
var $frandibar$elm_bootstrap$Bootstrap$Button$info = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(3));
var $author$project$Pages$Users$MlnComnet$ChangeMail$viewTr = function (pm) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(pm.an.lT)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '600px')
					]),
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$email(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(pm.bh),
								(pm.bh === '') ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(_List_Nil) : ($author$project$Pages$Users$MlnComnet$ChangeMail$validateEmails(pm.bh) ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$success : $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								A2($author$project$Pages$Users$MlnComnet$ChangeMail$OnChangeMail, pm.an.i8, 0))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '600px')
					]),
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$email(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(pm.bi),
								(pm.bi === '') ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(_List_Nil) : ($author$project$Pages$Users$MlnComnet$ChangeMail$validateEmails(pm.bi) ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$success : $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
								A2($author$project$Pages$Users$MlnComnet$ChangeMail$OnChangeMail, pm.an.i8, 1))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '60px')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$info,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
								A3($author$project$Pages$Users$MlnComnet$ChangeMail$UpdateMail, pm.an.i8, pm.bh, pm.bi))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('更新')
							]))
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$viewKeyedTr = function (pm) {
	return _Utils_Tuple2(
		pm.an.i8,
		A2($rtfeldman$elm_css$Html$Styled$Lazy$lazy, $author$project$Pages$Users$MlnComnet$ChangeMail$viewTr, pm));
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.eK;
				switch (_v0.$) {
					case 0:
						return $author$project$Components$Loading$loadingCenter(50.0);
					case 1:
						return $author$project$Components$Loading$loadingCenter(50.0);
					case 2:
						var e = _v0.a;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Utils$Helpers$errToStr(e))
								]));
					default:
						var res = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1500px')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-2 d-flex flex-direction-row justify-content-between')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-direction-row align-items-center mr-3')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-2')
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('検索:')
														])),
													$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
													_List_fromArray(
														[
															$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '400px')
																])),
															$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$ChangeMail$OnInputQuery)
														]))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-direction-row align-items-center ')
												]),
											_List_fromArray(
												[
													$author$project$Components$Molecules$SortTableSelector$sortTableSelector(
													{
														iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$ChangeMail$OnChangeSelectedSortOption),
														js: $elm$core$Maybe$Just(model.js)
													})
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$table,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table nonscroll')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('形名')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '600px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('送信先アドレス(4回)')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '600px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('送信先アドレス(1時間ごと)')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '60px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('更新')
																]))
														]))
												])),
											A3(
											$rtfeldman$elm_css$Html$Styled$Keyed$node,
											'tbody',
											_List_Nil,
											A2(
												$elm$core$List$map,
												function (pm) {
													return $author$project$Pages$Users$MlnComnet$ChangeMail$viewKeyedTr(pm);
												},
												A2(
													$elm$core$List$filter,
													function (pm) {
														return (model.bn === '') ? true : (A2(
															$elm$core$String$contains,
															$elm$core$String$toLower(model.bn),
															$elm$core$String$toLower(pm.an.lT)) ? true : (A2(
															$elm$core$List$any,
															function (mail) {
																return A2(
																	$elm$core$String$contains,
																	$elm$core$String$toLower(model.bn),
																	$elm$core$String$toLower(mail));
															},
															A2($elm$core$String$split, ',', pm.bh)) ? true : (A2(
															$elm$core$List$any,
															function (mail) {
																return A2(
																	$elm$core$String$contains,
																	$elm$core$String$toLower(model.bn),
																	$elm$core$String$toLower(mail));
															},
															A2($elm$core$String$split, ',', pm.bi)) ? true : false)));
													},
													A2(
														$elm$core$List$sortWith,
														F2(
															function (pm1, pm2) {
																return A3($author$project$Utils$Helpers$compareProductBySortOption, model.js, pm1.an, pm2.an);
															}),
														res.fn))))
										]))
								]));
				}
			}()
			]));
};
var $author$project$Pages$Users$MlnComnet$ChangeMail$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$ChangeMail$viewContent(model),
		hx: '送信先設定'
	};
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$SubmitForm = {$: 3};
var $author$project$Pages$Users$MlnComnet$ChangePassword$UpdateCurrentPassword = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$UpdateNewPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$UpdateRetypedNewPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$validateForm = function (model) {
	return (!_Utils_eq(model.aR, model.ds)) ? $elm$core$Result$Err('パスワードが一致しません') : (((model.aR !== '') && (!function (reg) {
		return A2($elm$regex$Regex$contains, reg, model.aR);
	}(
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^[0-9a-zA-Z]{6,}$'))))) ? $elm$core$Result$Err('パスワードは6文字以上の英数字です') : $elm$core$Result$Ok(0));
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$viewContent = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('mx-auto ml-3 mr-3 mb-5 w-1 col-lg-4')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-5 ')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('パスワードを入力し送信ボタンを押してください。')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('パスワードには半角英数字が使用可能です。（6字以上）')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('パスワード失念時は、商品企画G宛にお問い合わせください。')
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('現在のパスワード')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$password(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.cP),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$ChangePassword$UpdateCurrentPassword)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('新しいパスワード')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$password(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.aR),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$ChangePassword$UpdateNewPassword)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('新しいパスワード(確認)')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$password(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.ds),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$ChangePassword$UpdateRetypedNewPassword)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
					]),
				_List_fromArray(
					[
						function () {
						var _v0 = $author$project$Pages$Users$MlnComnet$ChangePassword$validateForm(model);
						if (_v0.$ === 1) {
							var msg = _v0.a;
							return $rtfeldman$elm_css$Html$Styled$text(msg);
						} else {
							return $rtfeldman$elm_css$Html$Styled$text('');
						}
					}()
					])),
				function () {
				var _v1 = model.aA;
				switch (_v1.$) {
					case 3:
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('text-success')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('パスワードを更新しました')
								]));
					case 2:
						var errStr = _v1.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(errStr)
								]));
					default:
						return A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil);
				}
			}(),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$submitButton,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$ChangePassword$SubmitForm),
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('float-right')
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							]))
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$ChangePassword$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$ChangePassword$viewContent(model),
		hx: 'パスワードの変更'
	};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeBigCategory = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeQuery = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeSmallCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeSortOption = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateFilteredProducts = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$ToggleShowOnlyBasePriceProduct = {$: 13};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$ToggleShowOnlyMailEnabled = {$: 14};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$alertedProductsNum = function (model) {
	return $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (p) {
				return p.bg;
			},
			model.ja));
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeBasePrice = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateBasePrice = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateMailEnabled = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$viewTr = F2(
	function (product, ind) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '120px')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(ind + 1))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.bg),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateMailEnabled(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
						]),
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.bM)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '180px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$MlnComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				model.b2 ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-3 d-flex flex-row align-items-end')
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('大分類')
											])),
										A3(
										$author$project$Components$CategorySelector$bigCategorySelector,
										model.hR,
										model.he,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeBigCategory)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('中分類')
											])),
										A4(
										$author$project$Components$CategorySelector$smallCategorySelector,
										model.jy,
										model.fH,
										model.he,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeSmallCategory)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('検索')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bn),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeQuery)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('show-only-having-baseprice'),
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
												$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$ToggleShowOnlyBasePriceProduct)),
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.ay)
											]),
										'基準価格入のみ表示'),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('show-only-mail-enalbed'),
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
												$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$ChangeProduct$ToggleShowOnlyMailEnabled)),
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.ab)
											]),
										'アラート対象のみ表示')
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										'アラート対象の型番数：' + $elm$core$String$fromInt(
											$author$project$Pages$Users$MlnComnet$ChangeProduct$alertedProductsNum(model))),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$primary,
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
												$author$project$Pages$Users$MlnComnet$ChangeProduct$OnUpdateFilteredProducts(
													$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model)))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('一括更新')
											]))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$px(300)),
										$rtfeldman$elm_css$Css$marginBottom(
										$rtfeldman$elm_css$Css$px(10))
									]))
							]),
						_List_fromArray(
							[
								$author$project$Components$Molecules$SortTableSelector$sortTableSelector(
								{
									iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$ChangeProduct$OnChangeSortOption),
									js: $elm$core$Maybe$Just(model.js)
								})
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$table,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table nonscroll')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '120px'),
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'white-space', 'pre-line')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														'No.\n(totla:' + ($elm$core$String$fromInt(
															$elm$core$List$length(
																$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model))) + ')'))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px'),
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'white-space', 'pre-line')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('メール\n有効化')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('形名')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('閾値')
															])),
														A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
														A2(
														$rtfeldman$elm_css$Html$Styled$span,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('（ポイント無し）')
															]))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$th,
												_List_fromArray(
													[
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px'),
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'white-space', 'pre-line')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('更新')
													]))
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$tbody,
								_List_Nil,
								A2(
									$elm$core$List$indexedMap,
									function (ind) {
										return function (product) {
											return A2($author$project$Pages$Users$MlnComnet$ChangeProduct$viewTr, product, ind);
										};
									},
									A2(
										$elm$core$List$sortWith,
										$author$project$Utils$Helpers$compareProductBySortOption(model.js),
										$author$project$Pages$Users$MlnComnet$ChangeProduct$filteredProducts(model))))
							]))
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$ChangeProduct$viewContent(model),
		hx: '価格/アラート設定'
	};
};
var $author$project$Pages$Users$MlnComnet$Chronological$Nop = {$: 52};
var $author$project$Pages$Users$MlnComnet$Chronological$OnChangeBigCategory = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnChangeSearchText = function (a) {
	return {$: 51, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnChangeSmallCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnChangeSnapshotName = function (a) {
	return {$: 36, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickExcelProcessingStatusModal = {$: 47};
var $author$project$Pages$Users$MlnComnet$Chronological$OnSelectProduct = function (a) {
	return {$: 49, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnSubmitCreateSnapshot = {$: 33};
var $author$project$Pages$Users$MlnComnet$Chronological$OnSubmitSaveSnapshot = {$: 34};
var $author$project$Pages$Users$MlnComnet$Chronological$OnUnselectProduct = function (a) {
	return {$: 50, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleCreateSnapshotModal = {$: 30};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleExcelProcessingStatusModal = {$: 46};
var $author$project$Pages$Users$MlnComnet$Chronological$TogglePointEnabled = {$: 24};
var $author$project$Pages$Users$MlnComnet$Chronological$TogglePriceEnabled = {$: 23};
var $author$project$Pages$Users$MlnComnet$Chronological$TogglePriceWithoutPointEnabled = {$: 25};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleProductFilterModal = {$: 48};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleUpdateSnapshotModal = {$: 31};
var $frandibar$elm_bootstrap$Bootstrap$Table$TableAttr = function (a) {
	return {$: 7, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$attr = function (attr_) {
	return $frandibar$elm_bootstrap$Bootstrap$Table$TableAttr(attr_);
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$Body = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Modal$Config = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Modal$body = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				cK: $elm$core$Maybe$Just(
					{d9: attributes, h0: children})
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$Bordered = {$: 2};
var $frandibar$elm_bootstrap$Bootstrap$Table$bordered = $frandibar$elm_bootstrap$Bootstrap$Table$Bordered;
var $frandibar$elm_bootstrap$Bootstrap$Table$CellAttr = function (a) {
	return {$: 2, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$cellAttr = function (attr_) {
	return $frandibar$elm_bootstrap$Bootstrap$Table$CellAttr(attr_);
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$config = function (closeMsg) {
	return {
		cK: $elm$core$Maybe$Nothing,
		cN: closeMsg,
		gw: $elm$core$Maybe$Nothing,
		eH: $elm$core$Maybe$Nothing,
		iW: {ea: _List_Nil, ei: true, cZ: true, di: $elm$core$Maybe$Nothing, fE: false},
		bD: $elm$core$Maybe$Nothing
	};
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$Footer = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Modal$footer = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				gw: $elm$core$Maybe$Just(
					{d9: attributes, h0: children})
			});
	});
var $author$project$Utils$Helpers$formatPosix = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$time_extra$Time$Extra$posixToParts(
		$justinmimbs$timezone_data$TimeZone$asia__tokyo(0)),
	$author$project$Utils$Helpers$formatDateTime);
var $rtfeldman$elm_css$Html$Styled$h5 = $rtfeldman$elm_css$Html$Styled$node('h5');
var $frandibar$elm_bootstrap$Bootstrap$Modal$Header = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Modal$header = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				eH: $elm$core$Maybe$Just(
					{d9: attributes, h0: children})
			});
	});
var $frandibar$elm_bootstrap$Bootstrap$Modal$titledHeader = F3(
	function (itemFn, attributes, children) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Modal$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					itemFn,
					A2(
						$elm$core$List$cons,
						$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-title'),
						attributes),
					children)
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Modal$h5 = $frandibar$elm_bootstrap$Bootstrap$Modal$titledHeader($rtfeldman$elm_css$Html$Styled$h5);
var $frandibar$elm_bootstrap$Bootstrap$Table$HeadAttr = function (a) {
	return {$: 2, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$headAttr = function (attr_) {
	return $frandibar$elm_bootstrap$Bootstrap$Table$HeadAttr(attr_);
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$large = function (_v0) {
	var conf = _v0;
	var options = conf.iW;
	return _Utils_update(
		conf,
		{
			iW: _Utils_update(
				options,
				{
					di: $elm$core$Maybe$Just(3)
				})
		});
};
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Outlined = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Button$outlineInfo = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Outlined(3));
var $rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $author$project$Components$Molecules$BigCategorySelector$Config = $elm$core$Basics$identity;
var $author$project$Components$Molecules$BigCategorySelector$custom = {hA: '全て選択'};
var $author$project$Components$Molecules$BigCategorySelector$view = F3(
	function (props, attrs, _v0) {
		var conf = _v0;
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled(true),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange,
					props.iS),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							_Utils_eq(props.he, -1))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(conf.hA)
						])),
				A2(
					$elm$core$List$map,
					function (bigCategory) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$elm$core$String$fromInt(bigCategory.ed)),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(props.he, bigCategory.ed))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(bigCategory.kb)
								]));
					},
					props.hR)));
	});
var $author$project$Components$Molecules$BigCategorySelector$bigCategorySelector = F2(
	function (props, attrs) {
		return A3($author$project$Components$Molecules$BigCategorySelector$view, props, attrs, $author$project$Components$Molecules$BigCategorySelector$custom);
	});
var $rtfeldman$elm_css$Svg$Styled$Attributes$d = $rtfeldman$elm_css$VirtualDom$Styled$attribute('d');
var $rtfeldman$elm_css$Svg$Styled$Attributes$height = $rtfeldman$elm_css$VirtualDom$Styled$attribute('height');
var $rtfeldman$elm_css$VirtualDom$Styled$nodeNS = $rtfeldman$elm_css$VirtualDom$Styled$NodeNS;
var $rtfeldman$elm_css$Svg$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$nodeNS('http://www.w3.org/2000/svg');
var $rtfeldman$elm_css$Svg$Styled$path = $rtfeldman$elm_css$Svg$Styled$node('path');
var $rtfeldman$elm_css$Svg$Styled$svg = $rtfeldman$elm_css$Svg$Styled$node('svg');
var $rtfeldman$elm_css$Svg$Styled$Attributes$viewBox = $rtfeldman$elm_css$VirtualDom$Styled$attribute('viewBox');
var $rtfeldman$elm_css$Svg$Styled$Attributes$width = $rtfeldman$elm_css$VirtualDom$Styled$attribute('width');
var $author$project$Icons$CloseIcon$closeIcon = A2(
	$rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('0 0 320 512'),
			$rtfeldman$elm_css$Svg$Styled$Attributes$width('16'),
			$rtfeldman$elm_css$Svg$Styled$Attributes$height('16')
		]),
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Svg$Styled$Attributes$d('M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z')
				]),
			_List_Nil)
		]));
var $rtfeldman$elm_css$Css$column = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{i: 'column'});
var $author$project$Components$Organisms$ProductFilterModal$findProductById = F2(
	function (productId, products) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.i8;
				},
				$elm$core$Basics$eq(productId)),
			products);
	});
var $rtfeldman$elm_css$Css$flex = $rtfeldman$elm_css$Css$prop1('flex');
var $rtfeldman$elm_css$Css$flexStart = $rtfeldman$elm_css$Css$prop1('flex-start');
var $rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$minHeight = $rtfeldman$elm_css$Css$prop1('min-height');
var $rtfeldman$elm_css$Css$minWidth = $rtfeldman$elm_css$Css$prop1('min-width');
var $rtfeldman$elm_css$Css$opacity = $rtfeldman$elm_css$Css$prop1('opacity');
var $author$project$Components$Molecules$SmallCategorySelector$Config = $elm$core$Basics$identity;
var $author$project$Components$Molecules$SmallCategorySelector$custom = {hA: '全て選択'};
var $author$project$Components$Molecules$SmallCategorySelector$view = F3(
	function (props, attrs, _v0) {
		var conf = _v0;
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled(true),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange,
					props.iS),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							_Utils_eq(props.fH, -1))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(conf.hA)
						])),
				A2(
					$elm$core$List$map,
					function (smallCategory) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$elm$core$String$fromInt(smallCategory.ho)),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(props.fH, smallCategory.ho))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(smallCategory.mc)
								]));
					},
					A2(
						$elm$core$List$filter,
						function (smallCategory) {
							return _Utils_eq(props.he, -1) || _Utils_eq(props.he, smallCategory.ed);
						},
						props.jy))));
	});
var $author$project$Components$Molecules$SmallCategorySelector$smallCategorySelector = F2(
	function (props, attrs) {
		return A3($author$project$Components$Molecules$SmallCategorySelector$view, props, attrs, $author$project$Components$Molecules$SmallCategorySelector$custom);
	});
var $frandibar$elm_bootstrap$Bootstrap$Modal$StartClose = 1;
var $frandibar$elm_bootstrap$Bootstrap$Modal$getCloseMsg = function (config_) {
	var _v0 = config_.bD;
	if (!_v0.$) {
		var animationMsg = _v0.a;
		return animationMsg(1);
	} else {
		return config_.cN;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$isFade = function (conf) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return true;
			},
			conf.bD));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$backdrop = F2(
	function (visibility, conf) {
		var attributes = function () {
			switch (visibility) {
				case 0:
					return _Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal-backdrop', true),
										_Utils_Tuple2(
										'fade',
										$frandibar$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
										_Utils_Tuple2('show', true)
									]))
							]),
						conf.iW.cZ ? _List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$frandibar$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf))
							]) : _List_Nil);
				case 1:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', true)
								]))
						]);
				case 2:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', false)
								]))
						]);
				default:
					return _List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', false),
									_Utils_Tuple2(
									'fade',
									$frandibar$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
									_Utils_Tuple2('show', false)
								]))
						]);
			}
		}();
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$div, attributes, _List_Nil)
			]);
	});
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$className = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	$elm$json$Json$Decode$string);
var $frandibar$elm_bootstrap$Bootstrap$Modal$containerClickDecoder = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (c) {
			return A2($elm$core$String$contains, 'elm-bootstrap-modal', c) ? $elm$json$Json$Decode$succeed(closeMsg) : $elm$json$Json$Decode$fail('ignoring');
		},
		$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$target($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$className));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$display = F2(
	function (visibility, conf) {
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'pointer-events', 'none'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$frandibar$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'pointer-events', 'none'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'pointer-events', 'none'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', false)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$Events$on,
						'transitionend',
						$elm$json$Json$Decode$succeed(conf.cN))
					]);
			default:
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', '0px'),
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'display', 'block'),
						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$frandibar$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', false)
							]))
					]);
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Modal$modalClass = function (size) {
	var _v0 = $frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size);
	if (!_v0.$) {
		var s = _v0.a;
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-' + s)
			]);
	} else {
		return _List_Nil;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$modalAttributes = function (options) {
	return _Utils_ap(
		options.ea,
		_Utils_ap(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal-dialog', true),
							_Utils_Tuple2('modal-dialog-centered', options.ei),
							_Utils_Tuple2('modal-dialog-scrollable', options.fE)
						])),
					A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'pointer-events', 'auto')
				]),
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $frandibar$elm_bootstrap$Bootstrap$Modal$modalClass, options.di))));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$renderBody = function (maybeBody) {
	if (!maybeBody.$) {
		var cfg = maybeBody.a;
		return $elm$core$Maybe$Just(
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-body'),
					cfg.d9),
				cfg.h0));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$renderFooter = function (maybeFooter) {
	if (!maybeFooter.$) {
		var cfg = maybeFooter.a;
		return $elm$core$Maybe$Just(
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-footer'),
					cfg.d9),
				cfg.h0));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$closeButton = function (closeMsg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$button,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('close'),
				$rtfeldman$elm_css$Html$Styled$Events$onClick(closeMsg)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('×')
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$renderHeader = function (conf_) {
	var _v0 = conf_.eH;
	if (!_v0.$) {
		var cfg = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-header'),
					cfg.d9),
				_Utils_ap(
					cfg.h0,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Modal$closeButton(
							$frandibar$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf_))
						]))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_css$Html$Styled$Attributes$tabindex = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$view = F2(
	function (visibility, _v0) {
		var conf = _v0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_Utils_ap(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$tabindex(-1)
								]),
							A2($frandibar$elm_bootstrap$Bootstrap$Modal$display, visibility, conf)),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_Utils_ap(
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'role', 'document'),
											$rtfeldman$elm_css$Html$Styled$Attributes$class('elm-bootstrap-modal')
										]),
									_Utils_ap(
										$frandibar$elm_bootstrap$Bootstrap$Modal$modalAttributes(conf.iW),
										conf.iW.cZ ? _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$Events$on,
												'click',
												$frandibar$elm_bootstrap$Bootstrap$Modal$containerClickDecoder(conf.cN))
											]) : _List_Nil)),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('modal-content')
											]),
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Modal$renderHeader(conf),
													$frandibar$elm_bootstrap$Bootstrap$Modal$renderBody(conf.cK),
													$frandibar$elm_bootstrap$Bootstrap$Modal$renderFooter(conf.gw)
												])))
									]))
							]))
					]),
				A2($frandibar$elm_bootstrap$Bootstrap$Modal$backdrop, visibility, conf)));
	});
var $author$project$Components$Organisms$ProductFilterModal$productFilterModal = function (props) {
	var filteredProducts = A2(
		$elm$core$List$filter,
		function (p) {
			return A2(
				$elm$core$String$contains,
				$elm$core$String$toLower(props.g3),
				$elm$core$String$toLower(p.lT));
		},
		A2(
			$elm$core$List$filter,
			function (p) {
				return _Utils_eq(props.fM.fH, -1) || _Utils_eq(props.fM.fH, p.ho);
			},
			A2(
				$elm$core$List$filter,
				function (p) {
					return _Utils_eq(props.ee.he, -1) || _Utils_eq(props.ee.he, p.ed);
				},
				A2(
					$elm$core$List$filter,
					function (p) {
						return !A2($elm$core$List$member, p.i8, props.hh);
					},
					props.ja))));
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Modal$view,
		props.iL,
		A3(
			$frandibar$elm_bootstrap$Bootstrap$Modal$footer,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$frandibar$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Button$outlineInfo,
							$frandibar$elm_bootstrap$Bootstrap$Button$onClick(props.gX)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('閉じる')
						]))
				]),
			A3(
				$frandibar$elm_bootstrap$Bootstrap$Modal$body,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$form,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
												$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$group,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$displayFlex,
																$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart),
																$rtfeldman$elm_css$Css$marginRight(
																$rtfeldman$elm_css$Css$px(24))
															]))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('大分類')
													])),
												A2($author$project$Components$Molecules$BigCategorySelector$bigCategorySelector, props.ee, _List_Nil)
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$group,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$displayFlex,
																$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart)
															]))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('中分類')
													])),
												A2($author$project$Components$Molecules$SmallCategorySelector$smallCategorySelector, props.fM, _List_Nil)
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
												$rtfeldman$elm_css$Css$width(
												$rtfeldman$elm_css$Css$pct(100)),
												A2($rtfeldman$elm_css$Css$property, 'column-gap', '24px')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$group,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$width(
																$rtfeldman$elm_css$Css$pct(100))
															]))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('形名')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$displayFlex,
																$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
																A2($rtfeldman$elm_css$Css$property, 'column-gap', '24px')
															]))
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$css(
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Css$displayFlex,
																		$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																		$rtfeldman$elm_css$Css$flex(
																		$rtfeldman$elm_css$Css$int(1))
																	]))
															]),
														_List_fromArray(
															[
																$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
																_List_fromArray(
																	[
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(props.iT),
																		$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(props.g3)
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$div,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('border'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$css(
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$marginTop(
																				$rtfeldman$elm_css$Css$px(24)),
																				$rtfeldman$elm_css$Css$minHeight(
																				$rtfeldman$elm_css$Css$px(240)),
																				$rtfeldman$elm_css$Css$maxHeight(
																				$rtfeldman$elm_css$Css$px(240)),
																				$rtfeldman$elm_css$Css$flex(
																				$rtfeldman$elm_css$Css$int(1)),
																				$rtfeldman$elm_css$Css$displayFlex,
																				$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																				$rtfeldman$elm_css$Css$padding(
																				$rtfeldman$elm_css$Css$px(8)),
																				A2($rtfeldman$elm_css$Css$property, 'row-gap', '8px'),
																				$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
																			]))
																	]),
																A2(
																	$elm$core$List$map,
																	function (product) {
																		return A2(
																			$rtfeldman$elm_css$Html$Styled$span,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
																							$rtfeldman$elm_css$Css$hover(
																							_List_fromArray(
																								[
																									$rtfeldman$elm_css$Css$opacity(
																									$rtfeldman$elm_css$Css$num(0.7))
																								]))
																						])),
																					$rtfeldman$elm_css$Html$Styled$Events$onClick(
																					props.iU(product.i8))
																				]),
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text(product.lT)
																				]));
																	},
																	filteredProducts))
															]))
													]))
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$group,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$displayFlex,
																$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																$rtfeldman$elm_css$Css$width(
																$rtfeldman$elm_css$Css$pct(100))
															]))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('選択済み')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('border'),
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$flex(
																$rtfeldman$elm_css$Css$int(1)),
																$rtfeldman$elm_css$Css$displayFlex,
																$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
																$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart),
																$rtfeldman$elm_css$Css$maxHeight(
																$rtfeldman$elm_css$Css$px(302)),
																$rtfeldman$elm_css$Css$padding(
																$rtfeldman$elm_css$Css$px(8)),
																A2($rtfeldman$elm_css$Css$property, 'row-gap', '8px'),
																$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
															]))
													]),
												A2(
													$elm$core$List$map,
													function (product) {
														return A2(
															$rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$class('border'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$css(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Css$displayFlex,
																			$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
																			$rtfeldman$elm_css$Css$height(
																			$rtfeldman$elm_css$Css$px(40)),
																			$rtfeldman$elm_css$Css$minWidth(
																			$rtfeldman$elm_css$Css$px(200)),
																			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
																		])),
																	$rtfeldman$elm_css$Html$Styled$Events$onClick(
																	props.iV(product.i8))
																]),
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$div,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('border-right'),
																			$rtfeldman$elm_css$Html$Styled$Attributes$css(
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Css$width(
																					$rtfeldman$elm_css$Css$px(40)),
																					$rtfeldman$elm_css$Css$height(
																					$rtfeldman$elm_css$Css$px(40)),
																					$rtfeldman$elm_css$Css$displayFlex,
																					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
																					$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
																				]))
																		]),
																	_List_fromArray(
																		[$author$project$Icons$CloseIcon$closeIcon])),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$div,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$css(
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Css$displayFlex,
																					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
																					A2(
																					$rtfeldman$elm_css$Css$padding2,
																					$rtfeldman$elm_css$Css$px(0),
																					$rtfeldman$elm_css$Css$px(16))
																				]))
																		]),
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text(product.lT)
																		]))
																]));
													},
													A2(
														$elm$core$List$filterMap,
														function (selectedProduct) {
															return A2($author$project$Components$Organisms$ProductFilterModal$findProductById, selectedProduct, props.ja);
														},
														props.hh)))
											]))
									]))
							]))
					]),
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Modal$h5,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('大分類・中分類・形名を選択')
						]),
					$frandibar$elm_bootstrap$Bootstrap$Modal$large(
						$frandibar$elm_bootstrap$Bootstrap$Modal$config(props.gX))))));
};
var $frandibar$elm_bootstrap$Bootstrap$Modal$small = function (_v0) {
	var conf = _v0;
	var options = conf.iW;
	return _Utils_update(
		conf,
		{
			iW: _Utils_update(
				options,
				{
					di: $elm$core$Maybe$Just(1)
				})
		});
};
var $author$project$Components$Atoms$Spinner$Config = $elm$core$Basics$identity;
var $author$project$Components$Atoms$Spinner$custom = {
	gl: $rtfeldman$elm_css$Css$hex('333'),
	bT: 30
};
var $author$project$Components$Atoms$Spinner$spinKeyframe = $rtfeldman$elm_css$Css$Animations$keyframes(
	_List_fromArray(
		[
			_Utils_Tuple2(
			0,
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Animations$property, 'transform', 'rotate(0deg)')
				])),
			_Utils_Tuple2(
			100,
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Animations$property, 'transform', 'rotate(360deg)')
				]))
		]));
var $author$project$Components$Atoms$Spinner$view = function (_v0) {
	var conf = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						A3(
						$rtfeldman$elm_css$Css$border3,
						$rtfeldman$elm_css$Css$px(conf.bT / 12),
						$rtfeldman$elm_css$Css$solid,
						$rtfeldman$elm_css$Css$hex('f3f3f3')),
						A3(
						$rtfeldman$elm_css$Css$borderTop3,
						$rtfeldman$elm_css$Css$px(conf.bT / 12),
						$rtfeldman$elm_css$Css$solid,
						conf.gl),
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px(conf.bT)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(conf.bT)),
						$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$auto),
						$rtfeldman$elm_css$Css$animationName($author$project$Components$Atoms$Spinner$spinKeyframe),
						$rtfeldman$elm_css$Css$animationDuration(
						$rtfeldman$elm_css$Css$sec(1)),
						A2($rtfeldman$elm_css$Css$property, 'animation-iteration-count', 'infinite'),
						A2($rtfeldman$elm_css$Css$property, 'animation-timing-function', 'linear')
					]))
			]),
		_List_Nil);
};
var $author$project$Components$Atoms$Spinner$spinner = $author$project$Components$Atoms$Spinner$view($author$project$Components$Atoms$Spinner$custom);
var $frandibar$elm_bootstrap$Bootstrap$Table$Striped = {$: 1};
var $frandibar$elm_bootstrap$Bootstrap$Table$striped = $frandibar$elm_bootstrap$Bootstrap$Table$Striped;
var $frandibar$elm_bootstrap$Bootstrap$Table$Inversed = {$: 0};
var $frandibar$elm_bootstrap$Bootstrap$Table$isResponsive = function (option) {
	if (option.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$KeyedTBody = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$TBody = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$InversedRow = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$KeyedRow = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$Row = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$InversedCell = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$Td = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$Th = function (a) {
	return {$: 1, a: a};
};
var $frandibar$elm_bootstrap$Bootstrap$Table$mapInversedCell = function (cell) {
	var inverseOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $frandibar$elm_bootstrap$Bootstrap$Table$InversedCell(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (cell.$ === 1) {
		var cellCfg = cell.a;
		return $frandibar$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellCfg,
				{
					iW: inverseOptions(cellCfg.iW)
				}));
	} else {
		var cellCfg = cell.a;
		return $frandibar$elm_bootstrap$Bootstrap$Table$Td(
			_Utils_update(
				cellCfg,
				{
					iW: inverseOptions(cellCfg.iW)
				}));
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$mapInversedRow = function (row) {
	var inversedOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $frandibar$elm_bootstrap$Bootstrap$Table$InversedRow(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (!row.$) {
		var options = row.a.iW;
		var cells = row.a.G;
		return $frandibar$elm_bootstrap$Bootstrap$Table$Row(
			{
				G: A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$mapInversedCell, cells),
				iW: inversedOptions(options)
			});
	} else {
		var options = row.a.iW;
		var cells = row.a.G;
		return $frandibar$elm_bootstrap$Bootstrap$Table$KeyedRow(
			{
				G: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var cell = _v1.b;
						return _Utils_Tuple2(
							key,
							$frandibar$elm_bootstrap$Bootstrap$Table$mapInversedCell(cell));
					},
					cells),
				iW: inversedOptions(options)
			});
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody = F2(
	function (isTableInversed, tbody_) {
		var _v0 = _Utils_Tuple2(isTableInversed, tbody_);
		if (!_v0.a) {
			return tbody_;
		} else {
			if (!_v0.b.$) {
				var body = _v0.b.a;
				return $frandibar$elm_bootstrap$Bootstrap$Table$TBody(
					_Utils_update(
						body,
						{
							jj: A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$mapInversedRow, body.jj)
						}));
			} else {
				var keyedBody = _v0.b.a;
				return $frandibar$elm_bootstrap$Bootstrap$Table$KeyedTBody(
					_Utils_update(
						keyedBody,
						{
							jj: A2(
								$elm$core$List$map,
								function (_v1) {
									var key = _v1.a;
									var row = _v1.b;
									return _Utils_Tuple2(
										key,
										$frandibar$elm_bootstrap$Bootstrap$Table$mapInversedRow(row));
								},
								keyedBody.jj)
						}));
			}
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$InversedHead = {$: 0};
var $frandibar$elm_bootstrap$Bootstrap$Table$THead = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead = F2(
	function (isTableInversed, _v0) {
		var thead_ = _v0;
		var isHeadInversed = A2(
			$elm$core$List$any,
			function (opt) {
				return _Utils_eq(opt, $frandibar$elm_bootstrap$Bootstrap$Table$InversedHead);
			},
			thead_.iW);
		return (isTableInversed || isHeadInversed) ? _Utils_update(
			thead_,
			{
				jj: A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$mapInversedRow, thead_.jj)
			}) : thead_;
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive = F2(
	function (options, table_) {
		var responsiveClass = $rtfeldman$elm_css$Html$Styled$Attributes$class(
			'table-responsive' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return '-' + v;
					},
					A2(
						$elm$core$Maybe$andThen,
						$frandibar$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption,
						A2(
							$elm$core$Maybe$andThen,
							function (opt) {
								if (opt.$ === 5) {
									var val = opt.a;
									return val;
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							$elm$core$List$head(
								A2($elm$core$List$filter, $frandibar$elm_bootstrap$Bootstrap$Table$isResponsive, options)))))));
		return A2($elm$core$List$any, $frandibar$elm_bootstrap$Bootstrap$Table$isResponsive, options) ? A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[responsiveClass]),
			_List_fromArray(
				[table_])) : table_;
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$scope = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('scope');
var $frandibar$elm_bootstrap$Bootstrap$Table$addScopeIfTh = function (cell) {
	if (cell.$ === 1) {
		var cellConfig = cell.a;
		return $frandibar$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellConfig,
				{
					iW: A2(
						$elm$core$List$cons,
						$frandibar$elm_bootstrap$Bootstrap$Table$cellAttr(
							$rtfeldman$elm_css$Html$Styled$Attributes$scope('row')),
						cellConfig.iW)
				}));
	} else {
		return cell;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell = function (row) {
	if (!row.$) {
		var options = row.a.iW;
		var cells = row.a.G;
		if (!cells.b) {
			return row;
		} else {
			var first = cells.a;
			var rest = cells.b;
			return $frandibar$elm_bootstrap$Bootstrap$Table$Row(
				{
					G: A2(
						$elm$core$List$cons,
						$frandibar$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first),
						rest),
					iW: options
				});
		}
	} else {
		var options = row.a.iW;
		var cells = row.a.G;
		if (!cells.b) {
			return row;
		} else {
			var _v3 = cells.a;
			var firstKey = _v3.a;
			var first = _v3.b;
			var rest = cells.b;
			return $frandibar$elm_bootstrap$Bootstrap$Table$KeyedRow(
				{
					G: A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							firstKey,
							$frandibar$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first)),
						rest),
					iW: options
				});
		}
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$cellAttribute = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role);
			} else {
				var _v1 = option.a;
				return $rtfeldman$elm_css$Html$Styled$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg-', role);
			} else {
				var _v2 = option.a;
				return $rtfeldman$elm_css$Html$Styled$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$cellAttributes = function (options) {
	return A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$cellAttribute, options);
};
var $frandibar$elm_bootstrap$Bootstrap$Table$renderCell = function (cell) {
	if (!cell.$) {
		var options = cell.a.iW;
		var children = cell.a.h0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$td,
			$frandibar$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	} else {
		var options = cell.a.iW;
		var children = cell.a.h0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$th,
			$frandibar$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$rowClass = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role_);
			} else {
				var _v1 = option.a;
				return $rtfeldman$elm_css$Html$Styled$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($frandibar$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role_);
			} else {
				var _v2 = option.a;
				return $rtfeldman$elm_css$Html$Styled$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$rowAttributes = function (options) {
	return A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$rowClass, options);
};
var $frandibar$elm_bootstrap$Bootstrap$Table$renderRow = function (row) {
	if (!row.$) {
		var options = row.a.iW;
		var cells = row.a.G;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			$frandibar$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$renderCell, cells));
	} else {
		var options = row.a.iW;
		var cells = row.a.G;
		return A3(
			$rtfeldman$elm_css$Html$Styled$Keyed$node,
			'tr',
			$frandibar$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var cell = _v1.b;
					return _Utils_Tuple2(
						key,
						$frandibar$elm_bootstrap$Bootstrap$Table$renderCell(cell));
				},
				cells));
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$renderTBody = function (body) {
	if (!body.$) {
		var attributes = body.a.d9;
		var rows = body.a.jj;
		return A2(
			$rtfeldman$elm_css$Html$Styled$tbody,
			attributes,
			A2(
				$elm$core$List$map,
				function (row) {
					return $frandibar$elm_bootstrap$Bootstrap$Table$renderRow(
						$frandibar$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row));
				},
				rows));
	} else {
		var attributes = body.a.d9;
		var rows = body.a.jj;
		return A3(
			$rtfeldman$elm_css$Html$Styled$Keyed$node,
			'tbody',
			attributes,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var row = _v1.b;
					return _Utils_Tuple2(
						key,
						$frandibar$elm_bootstrap$Bootstrap$Table$renderRow(
							$frandibar$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row)));
				},
				rows));
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$theadAttribute = function (option) {
	switch (option.$) {
		case 0:
			return $rtfeldman$elm_css$Html$Styled$Attributes$class('thead-dark');
		case 1:
			return $rtfeldman$elm_css$Html$Styled$Attributes$class('thead-default');
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$theadAttributes = function (options) {
	return A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$theadAttribute, options);
};
var $frandibar$elm_bootstrap$Bootstrap$Table$renderTHead = function (_v0) {
	var options = _v0.iW;
	var rows = _v0.jj;
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		$frandibar$elm_bootstrap$Bootstrap$Table$theadAttributes(options),
		A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$renderRow, rows));
};
var $frandibar$elm_bootstrap$Bootstrap$Table$tableClass = function (option) {
	switch (option.$) {
		case 0:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-dark'));
		case 1:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-striped'));
		case 2:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-bordered'));
		case 3:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-hover'));
		case 4:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-sm'));
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just(
				$rtfeldman$elm_css$Html$Styled$Attributes$class('table-reflow'));
		default:
			var attr_ = option.a;
			return $elm$core$Maybe$Just(attr_);
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Table$tableAttributes = function (options) {
	return A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Html$Styled$Attributes$class('table'),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, $frandibar$elm_bootstrap$Bootstrap$Table$tableClass, options)));
};
var $frandibar$elm_bootstrap$Bootstrap$Table$table = function (rec) {
	var isInversed = A2(
		$elm$core$List$any,
		function (opt) {
			return _Utils_eq(opt, $frandibar$elm_bootstrap$Bootstrap$Table$Inversed);
		},
		rec.iW);
	var classOptions = A2(
		$elm$core$List$filter,
		function (opt) {
			return !$frandibar$elm_bootstrap$Bootstrap$Table$isResponsive(opt);
		},
		rec.iW);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive,
		rec.iW,
		A2(
			$rtfeldman$elm_css$Html$Styled$table,
			$frandibar$elm_bootstrap$Bootstrap$Table$tableAttributes(classOptions),
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Table$renderTHead(
					A2($frandibar$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead, isInversed, rec.ms)),
					$frandibar$elm_bootstrap$Bootstrap$Table$renderTBody(
					A2($frandibar$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody, isInversed, rec.mq))
				])));
};
var $frandibar$elm_bootstrap$Bootstrap$Table$tbody = F2(
	function (attributes, rows) {
		return $frandibar$elm_bootstrap$Bootstrap$Table$TBody(
			{d9: attributes, jj: rows});
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$td = F2(
	function (options, children) {
		return $frandibar$elm_bootstrap$Bootstrap$Table$Td(
			{h0: children, iW: options});
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$th = F2(
	function (options, children) {
		return $frandibar$elm_bootstrap$Bootstrap$Table$Th(
			{h0: children, iW: options});
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$thead = F2(
	function (options, rows) {
		return {iW: options, jj: rows};
	});
var $frandibar$elm_bootstrap$Bootstrap$Table$tr = F2(
	function (options, cells) {
		return $frandibar$elm_bootstrap$Bootstrap$Table$Row(
			{G: cells, iW: options});
	});
var $author$project$Pages$Users$MlnComnet$Chronological$OnCheckFilteredTime = function (a) {
	return {$: 39, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnCheckFromPreviousThursday = {$: 38};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickCreateSnapshot = {$: 29};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickDeselectAllFilteredTimesButton = {$: 42};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickPerhourFilteredTimesButton = {$: 41};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickRemoveSnapshot = {$: 35};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickSaveSnapshot = {$: 32};
var $author$project$Pages$Users$MlnComnet$Chronological$OnClickSelectAllFilteredTimesButton = {$: 40};
var $author$project$Pages$Users$MlnComnet$Chronological$OnSelectSavedSnapshot = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnSubmit = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnUpdateDateFrom = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnUpdateDateTo = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnUpdateTimeFrom = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$OnUpdateTimeTo = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleExportExcel = {$: 18};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyAlertProduct = {$: 21};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyLowest = {$: 19};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyMoved = {$: 20};
var $author$project$Pages$Users$MlnComnet$Chronological$ToggleSeparateSheetByProductID = {$: 26};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$DropdownItem = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$buttonItem = F2(
	function (attributes, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('button'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('dropdown-item')
					]),
				attributes),
			children);
	});
var $rtfeldman$elm_css$Html$Styled$details = $rtfeldman$elm_css$Html$Styled$node('details');
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$divider = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class('dropdown-divider')
		]),
	_List_Nil);
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$dropDir = function (maybeDir) {
	var toAttrs = function (dir) {
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(
				'drop' + function () {
					if (!dir) {
						return 'left';
					} else {
						return 'right';
					}
				}())
			]);
	};
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2($elm$core$Maybe$map, toAttrs, maybeDir));
};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes = F2(
	function (status, config) {
		return _Utils_ap(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn-group', true),
							_Utils_Tuple2('show', status !== 2),
							_Utils_Tuple2('dropup', config.c4)
						]))
				]),
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Dropdown$dropDir(config.cT),
				config.d9));
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$menuStyles = F2(
	function (_v0, config) {
		var status = _v0.ac;
		var toggleSize = _v0.fZ;
		var menuSize = _v0.df;
		var px = function (n) {
			return $elm$core$String$fromFloat(n) + 'px';
		};
		var translate = F3(
			function (x, y, z) {
				return 'translate3d(' + (px(x) + (',' + (px(y) + (',' + (px(z) + ')')))));
			});
		var _default = _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'top', '0'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'left', '0')
			]);
		var _v1 = _Utils_Tuple2(config.c4, config.cT);
		_v1$0:
		while (true) {
			if (!_v1.b.$) {
				if (_v1.b.a === 1) {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v2 = _v1.b.a;
						return _default;
					}
				} else {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v3 = _v1.b.a;
						return _Utils_ap(
							_default,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$Attributes$style,
									'transform',
									A3(translate, (-toggleSize.j_) - menuSize.j_, 0, 0))
								]));
					}
				}
			} else {
				if (_v1.a) {
					break _v1$0;
				} else {
					return _Utils_ap(
						_default,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$Attributes$style,
								'transform',
								A3(translate, -toggleSize.j_, toggleSize.ip, 0))
							]));
				}
			}
		}
		return _Utils_ap(
			_default,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$Attributes$style,
					'transform',
					A3(translate, -toggleSize.j_, -menuSize.ip, 0))
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu = F3(
	function (state, config, items) {
		var status = state.ac;
		var menuSize = state.df;
		var wrapperStyles = (status === 2) ? _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', '0'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'overflow', 'hidden'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'position', 'relative')
			]) : _List_fromArray(
			[
				A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'position', 'relative')
			]);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			wrapperStyles,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('dropdown-menu', true),
										_Utils_Tuple2('dropdown-menu-right', config.eG),
										_Utils_Tuple2('show', status !== 2)
									]))
							]),
						_Utils_ap(
							A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$menuStyles, state, config),
							config.eZ)),
					A2(
						$elm$core$List$map,
						function (_v0) {
							var x = _v0;
							return x;
						},
						items))
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$applyModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 1:
				return _Utils_update(
					options,
					{eG: true});
			case 0:
				return _Utils_update(
					options,
					{c4: true});
			case 4:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{d9: attrs_});
			case 2:
				var dir = option.a;
				return _Utils_update(
					options,
					{
						cT: $elm$core$Maybe$Just(dir)
					});
			default:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{eZ: attrs_});
		}
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$defaultOptions = {d9: _List_Nil, cT: $elm$core$Maybe$Nothing, eG: false, c4: false, eZ: _List_Nil};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$toConfig = function (options) {
	return A3($elm$core$List$foldl, $frandibar$elm_bootstrap$Bootstrap$Dropdown$applyModifier, $frandibar$elm_bootstrap$Bootstrap$Dropdown$defaultOptions, options);
};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdown = F2(
	function (state, _v0) {
		var status = state.ac;
		var toggleMsg = _v0.mx;
		var toggleButton = _v0.mw;
		var items = _v0.k9;
		var options = _v0.iW;
		var config = $frandibar$elm_bootstrap$Bootstrap$Dropdown$toConfig(options);
		var _v1 = toggleButton;
		var buttonFn = _v1;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes, status, config),
			_List_fromArray(
				[
					A2(buttonFn, toggleMsg, state),
					A3($frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu, state, config, items)
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Light = 7;
var $frandibar$elm_bootstrap$Bootstrap$Button$light = $frandibar$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$frandibar$elm_bootstrap$Bootstrap$Internal$Button$Roled(7));
var $author$project$Utils$Helpers$preventDefaultClick = function (onClick) {
	var decoder = $elm$json$Json$Decode$succeed(
		_Utils_Tuple2(onClick, true));
	return A2($rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn, 'click', decoder);
};
var $rtfeldman$elm_css$Html$Styled$summary = $rtfeldman$elm_css$Html$Styled$node('summary');
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$DropdownToggle = $elm$core$Basics$identity;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$Open = 0;
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$nextStatus = function (status) {
	switch (status) {
		case 0:
			return 2;
		case 1:
			return 2;
		default:
			return 0;
	}
};
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeft_, scrollTop_, offsetLeft_, offsetTop_) {
						return _Utils_Tuple2((x + offsetLeft_) - scrollLeft_, (y + offsetTop_) - scrollTop_);
					}),
				$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft,
				$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop,
				$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft,
				$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop));
	});
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {ip: height, iH: x, jN: y, j_: width};
		}),
	A2($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, 0, 0),
	$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth,
	$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight);
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode = function (idx) {
	return $elm$json$Json$Decode$at(
		_List_fromArray(
			[
				'childNodes',
				$elm$core$String$fromInt(idx)
			]));
};
var $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'nextSibling', decoder);
};
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$isToggle = A2(
	$elm$json$Json$Decode$andThen,
	function (_class) {
		return A2($elm$core$String$contains, 'dropdown-toggle', _class) ? $elm$json$Json$Decode$succeed(true) : $elm$json$Json$Decode$succeed(false);
	},
	$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$className);
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$toggler = F2(
	function (path, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$andThen,
					function (res) {
						return res ? A2($elm$json$Json$Decode$at, path, decoder) : $elm$json$Json$Decode$fail('');
					},
					A2($elm$json$Json$Decode$at, path, $frandibar$elm_bootstrap$Bootstrap$Dropdown$isToggle)),
					A2(
					$elm$json$Json$Decode$andThen,
					function (_v0) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Dropdown$toggler,
							_Utils_ap(
								path,
								_List_fromArray(
									['parentElement'])),
							decoder);
					},
					A2(
						$elm$json$Json$Decode$at,
						_Utils_ap(
							path,
							_List_fromArray(
								['parentElement'])),
						$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$className)),
					$elm$json$Json$Decode$fail('No toggler found')
				]));
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2(
		$frandibar$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea),
	A2(
		$frandibar$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling(
			A2($frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode, 0, $frandibar$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea))));
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$clickHandler = F2(
	function (toMsg, state) {
		var status = state.ac;
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var b = _v0.a;
				var m = _v0.b;
				return $elm$json$Json$Decode$succeed(
					toMsg(
						{
							df: m,
							ac: $frandibar$elm_bootstrap$Bootstrap$Dropdown$nextStatus(status),
							fZ: b
						}));
			},
			$frandibar$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder);
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$togglePrivate = F4(
	function (buttonOptions, children, toggleMsg, state) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_Utils_ap(
				$frandibar$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(buttonOptions),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('dropdown-toggle'),
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('button'),
						A2(
						$rtfeldman$elm_css$Html$Styled$Events$on,
						'click',
						A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$clickHandler, toggleMsg, state))
					])),
			children);
	});
var $frandibar$elm_bootstrap$Bootstrap$Dropdown$toggle = F2(
	function (buttonOptions, children) {
		return A2($frandibar$elm_bootstrap$Bootstrap$Dropdown$togglePrivate, buttonOptions, children);
	});
var $author$project$Pages$Users$MlnComnet$Chronological$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.bt;
				if (_v0.$ === 2) {
					var e = _v0.a;
					return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
				} else {
					return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
				}
			}(),
				A3(
				$krisajenkins$remotedata$RemoteData$unwrap,
				A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				function (snapshots) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start')
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('出力条件の呼び出し')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$MlnComnet$Chronological$OnSelectSavedSnapshot)
											]),
										A2(
											$elm$core$List$cons,
											A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(''),
														$rtfeldman$elm_css$Html$Styled$Attributes$selected(
														_Utils_eq(model.aa, $elm$core$Maybe$Nothing))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('選択してください')
													])),
											A2(
												$elm$core$List$map,
												function (snapshot) {
													return A2(
														$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(snapshot.fK)),
																$rtfeldman$elm_css$Html$Styled$Attributes$selected(
																_Utils_eq(
																	model.aa,
																	$elm$core$Maybe$Just(
																		$elm$core$String$fromInt(snapshot.fK))))
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(snapshot.iM)
															]));
												},
												snapshots)))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('出力条件の保存/削除')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Dropdown$dropdown,
										model.dt,
										{
											k9: function () {
												var _v1 = model.aa;
												if (!_v1.$) {
													return _List_fromArray(
														[
															A2(
															$frandibar$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickSaveSnapshot)
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('選択したフォームを上書き保存')
																])),
															A2(
															$frandibar$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickCreateSnapshot)
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('現在のフォームを新規保存')
																])),
															$frandibar$elm_bootstrap$Bootstrap$Dropdown$divider,
															A2(
															$frandibar$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickRemoveSnapshot),
																	$rtfeldman$elm_css$Html$Styled$Attributes$class('text-danger')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('選択したフォームを削除')
																]))
														]);
												} else {
													return _List_fromArray(
														[
															A2(
															$frandibar$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickCreateSnapshot)
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('現在のフォームを新規保存')
																]))
														]);
												}
											}(),
											iW: _List_Nil,
											mw: A2(
												$frandibar$elm_bootstrap$Bootstrap$Dropdown$toggle,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('選択してください')
													])),
											mx: $author$project$Pages$Users$MlnComnet$Chronological$GotDropdownMsg
										})
									]))
							]));
				},
				model.bt),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('形名')
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$author$project$Utils$Helpers$preventDefaultClick($author$project$Pages$Users$MlnComnet$Chronological$ToggleProductFilterModal)
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$light,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$ToggleProductFilterModal)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('形名を表示')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column  align-items-start')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得日')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.ag),
														$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnUpdateDateFrom)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-2')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得時刻')
											])),
										A3(
										$author$project$Components$TimeSelector$timeSelector,
										60,
										model.hi,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
												$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnUpdateTimeFrom),
												$rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.ag)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-2')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('〜')
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得日')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.ag),
														$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnUpdateDateTo)
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Form$label,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('取得時刻')
											])),
										A3(
										$author$project$Components$TimeSelector$timeSelector,
										60,
										model.hj,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
												$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnUpdateTimeTo),
												$rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.ag)
											]))
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
								$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$OnCheckFromPreviousThursday)),
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.ag),
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('from-previous-thursday')
							]),
						'前週木曜日の10時から')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
										$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
								$author$project$Pages$Users$MlnComnet$Chronological$OnSubmit(false))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('送信')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('vr')
							]),
						_List_Nil),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyAlertProduct)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.bo),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('query-only-alert')
									]),
								'アラート対象のみ'),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyLowest)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.bp),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('query-only-lowest')
									]),
								'最安のみ'),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$ToggleQueryOnlyMoved)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.bq),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('query-only-moved')
									]),
								'変動があった時間帯のみ')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('vr')
							]),
						_List_Nil),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$ToggleExportExcel)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.aK),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('export-excel')
									]),
								'xlsx形式でダウンロード'),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(!model.aK),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$ToggleSeparateSheetByProductID)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.cu),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('separate-sheet-by-id')
									]),
								'機種ごとにシートを分割')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$details,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$summary,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('時間設定')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1100px')
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('pt-2 pb-2')
											]))
									]),
								_List_fromArray(
									[
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$info,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-2')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickSelectAllFilteredTimesButton)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全て選択')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$info,
												$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-2')
													])),
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickPerhourFilteredTimesButton)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('1時間ごと')
											])),
										A2(
										$frandibar$elm_bootstrap$Bootstrap$Button$button,
										_List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Button$info,
												$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickDeselectAllFilteredTimesButton)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全て選択解除')
											]))
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$group,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function (timeStr) {
										return A2(
											$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$inline,
													$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
													$elm$core$Basics$always(
														$author$project$Pages$Users$MlnComnet$Chronological$OnCheckFilteredTime(timeStr))),
													$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
													A2($elm$core$Set$member, timeStr, model.O)),
													$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('filtered-time-set-' + timeStr)
												]),
											timeStr);
									},
									$author$project$Pages$Users$MlnComnet$Chronological$filteredTimes))
							]))
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$Chronological$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$MlnComnet$Chronological$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginRight(
												$rtfeldman$elm_css$Css$px(24))
											]))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.al),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$TogglePriceEnabled)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('price-enabled'),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(model.aj || model.am)
									]),
								'金額を表示'),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.aj),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$TogglePointEnabled)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('point-enabled'),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(model.al || model.am)
									]),
								'ポイントを表示'),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.am),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
										$elm$core$Basics$always($author$project$Pages$Users$MlnComnet$Chronological$TogglePriceWithoutPointEnabled)),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$id('price-without-point-enabled'),
										$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(model.aj || model.al)
									]),
								'金額-ポイント を表示')
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
										$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$MlnComnet$Chronological$Nop)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('法人')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Pages$Users$MlnComnet$Chronological$OnChangeStore,
										$rtfeldman$elm_css$Html$Styled$fromUnstyled(
											$inkuzmin$elm_multiselect$Multiselect$view(model.r)))
									]))
							]))
					])),
				function () {
				var _v0 = model.cA;
				switch (_v0.$) {
					case 0:
						return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
					case 1:
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-3')
								]),
							_List_fromArray(
								[
									$author$project$Components$Loading$loading(30)
								]));
					case 2:
						var e = _v0.a;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									function () {
										if ((e.$ === 3) && (e.a === 404)) {
											return '該当するデータが見つかりませんでした。検索条件を変更して再度お試しください';
										} else {
											return $author$project$Utils$Helpers$errToStr(e);
										}
									}())
								]));
					default:
						var data = _v0.a;
						var rowsNum = '2';
						var colsNum = '5';
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Table$table(
									{
										iW: _List_fromArray(
											[
												$frandibar$elm_bootstrap$Bootstrap$Table$bordered,
												$frandibar$elm_bootstrap$Bootstrap$Table$striped,
												$frandibar$elm_bootstrap$Bootstrap$Table$attr(
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mln-comnet text-nowrap')),
												$frandibar$elm_bootstrap$Bootstrap$Table$attr(
												$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable')),
												$frandibar$elm_bootstrap$Bootstrap$Table$attr(
												A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum))))
											]),
										mq: A2(
											$frandibar$elm_bootstrap$Bootstrap$Table$tbody,
											_List_Nil,
											A2(
												$elm$core$List$map,
												function (row) {
													return A2(
														$frandibar$elm_bootstrap$Bootstrap$Table$tr,
														_List_Nil,
														$elm$core$List$concat(
															_List_fromArray(
																[
																	_List_fromArray(
																	[
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(row.gk)
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(row.lT)
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(row.jD)
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(row.mi)
																			])),
																		A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(
																				$elm$core$String$fromInt(row.hJ))
																			]))
																	]),
																	A2(
																	$elm$core$List$map,
																	function (maybecell) {
																		if (!maybecell.$) {
																			var cell = maybecell.a;
																			return A2(
																				$frandibar$elm_bootstrap$Bootstrap$Table$td,
																				_List_fromArray(
																					[
																						$frandibar$elm_bootstrap$Bootstrap$Table$cellAttr(
																						$rtfeldman$elm_css$Html$Styled$Attributes$classList(
																							_List_fromArray(
																								[
																									_Utils_Tuple2('lowestPriceTdMln', cell.gM),
																									_Utils_Tuple2('increasedPriceTdMln', cell.iA),
																									_Utils_Tuple2('decreasedPriceTdMln', cell.iz)
																								])))
																					]),
																				_List_fromArray(
																					[
																						model.al ? A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(
																								A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(cell.i4))
																							])) : A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil),
																						model.aj ? A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(
																								$elm$core$String$fromInt(cell.iZ))
																							])) : A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil),
																						model.am ? A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_Nil,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(
																								A2($elm$core$Basics$composeL, $author$project$Utils$Helpers$toCommmaSplited, $elm$core$String$fromInt)(cell.aW))
																							])) : A2($rtfeldman$elm_css$Html$Styled$p, _List_Nil, _List_Nil)
																					]));
																		} else {
																			return A2($frandibar$elm_bootstrap$Bootstrap$Table$td, _List_Nil, _List_Nil);
																		}
																	},
																	row.i9)
																])));
												},
												data.jj)),
										ms: A2(
											$frandibar$elm_bootstrap$Bootstrap$Table$thead,
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Table$headAttr(
													$rtfeldman$elm_css$Html$Styled$Attributes$class('storeGroupHeader1'))
												]),
											_List_fromArray(
												[
													A2(
													$frandibar$elm_bootstrap$Bootstrap$Table$tr,
													_List_Nil,
													$elm$core$List$concat(
														_List_fromArray(
															[
																_List_fromArray(
																[
																	A2($frandibar$elm_bootstrap$Bootstrap$Table$th, _List_Nil, _List_Nil),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_fromArray(
																		[
																			$frandibar$elm_bootstrap$Bootstrap$Table$cellAttr(
																			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'min-width', '200px'))
																		]),
																	_List_Nil),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_fromArray(
																		[
																			$frandibar$elm_bootstrap$Bootstrap$Table$cellAttr(
																			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'min-width', '200px'))
																		]),
																	_List_Nil),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_fromArray(
																		[
																			$frandibar$elm_bootstrap$Bootstrap$Table$cellAttr(
																			A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'min-width', '100px'))
																		]),
																	_List_Nil),
																	A2($frandibar$elm_bootstrap$Bootstrap$Table$th, _List_Nil, _List_Nil)
																]),
																A2(
																$elm$core$List$map,
																function (date) {
																	return A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(
																				$author$project$Utils$Helpers$formatPosix(date))
																			]));
																},
																data.gC)
															]))),
													A2(
													$frandibar$elm_bootstrap$Bootstrap$Table$tr,
													_List_Nil,
													$elm$core$List$concat(
														_List_fromArray(
															[
																_List_fromArray(
																[
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('自社カテゴリ')
																		])),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('商品名')
																		])),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('店舗')
																		])),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('サイト')
																		])),
																	A2(
																	$frandibar$elm_bootstrap$Bootstrap$Table$th,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$text('アラート価格')
																		]))
																]),
																A2(
																$elm$core$List$map,
																$elm$core$Basics$always(
																	A2(
																		$frandibar$elm_bootstrap$Bootstrap$Table$th,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text('商品価格')
																			]))),
																data.gC)
															])))
												]))
									})
								]));
				}
			}(),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Modal$view,
				model.a3,
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Modal$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnSubmitCreateSnapshot)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('送信')
								]))
						]),
					A3(
						$frandibar$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('保存名')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.az),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnChangeSnapshotName)
									]))
							]),
						A3(
							$frandibar$elm_bootstrap$Bootstrap$Modal$h5,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('設定を保存')
								]),
							$frandibar$elm_bootstrap$Bootstrap$Modal$small(
								$frandibar$elm_bootstrap$Bootstrap$Modal$config($author$project$Pages$Users$MlnComnet$Chronological$ToggleCreateSnapshotModal)))))),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Modal$view,
				model.bA,
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Modal$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$primary,
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnSubmitSaveSnapshot)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('送信')
								]))
						]),
					A3(
						$frandibar$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('保存名')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.az),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$Chronological$OnChangeSnapshotName)
									]))
							]),
						A3(
							$frandibar$elm_bootstrap$Bootstrap$Modal$h5,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('設定を更新')
								]),
							$frandibar$elm_bootstrap$Bootstrap$Modal$small(
								$frandibar$elm_bootstrap$Bootstrap$Modal$config($author$project$Pages$Users$MlnComnet$Chronological$ToggleUpdateSnapshotModal)))))),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Modal$view,
				model.aJ,
				A3(
					$frandibar$elm_bootstrap$Bootstrap$Modal$footer,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$outlineInfo,
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Chronological$OnClickExcelProcessingStatusModal)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('閉じる')
								]))
						]),
					A3(
						$frandibar$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						function () {
							var _v4 = model.a9;
							switch (_v4.$) {
								case 1:
									var res = _v4.a;
									return _List_fromArray(
										[
											$author$project$Components$Atoms$Spinner$spinner,
											A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
											A2(
											$rtfeldman$elm_css$Html$Styled$p,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('処理完了後に自動的にファイルを開きます。')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$p,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('または、一定時間が経過したら'),
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(res.gt),
															$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('こちらのリンク')
														])),
													$rtfeldman$elm_css$Html$Styled$text('からダウンロードが可能です。')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$p,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('※ 広い期間を選択した場合、処理完了まで数分程度かかる場合がございます。')
												]))
										]);
								case 2:
									var res = _v4.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(res.gt),
															$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('こちらのリンク')
														])),
													$rtfeldman$elm_css$Html$Styled$text('からダウンロードが可能です。')
												]))
										]);
								case 3:
									var msg = _v4.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$p,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(msg)
												]))
										]);
								default:
									return _List_Nil;
							}
						}(),
						A3(
							$frandibar$elm_bootstrap$Bootstrap$Modal$h5,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									function () {
										var _v3 = model.a9;
										switch (_v3.$) {
											case 1:
												return 'Excelファイルの処理中です';
											case 2:
												return 'Excelファイルの処理が完了しました';
											case 3:
												return 'Excelファイルの処理中にエラーが発生しました';
											default:
												return '';
										}
									}())
								]),
							$frandibar$elm_bootstrap$Bootstrap$Modal$large(
								$frandibar$elm_bootstrap$Bootstrap$Modal$config($author$project$Pages$Users$MlnComnet$Chronological$ToggleExcelProcessingStatusModal)))))),
				$author$project$Components$Organisms$ProductFilterModal$productFilterModal(
				{
					ee: {
						hR: A3(
							$krisajenkins$remotedata$RemoteData$unwrap,
							_List_Nil,
							function ($) {
								return $.hR;
							},
							model.as),
						iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$Chronological$OnChangeBigCategory),
						he: model.he
					},
					iL: model.dq,
					iT: $author$project$Pages$Users$MlnComnet$Chronological$OnChangeSearchText,
					iU: $author$project$Pages$Users$MlnComnet$Chronological$OnSelectProduct,
					gX: $author$project$Pages$Users$MlnComnet$Chronological$ToggleProductFilterModal,
					iV: $author$project$Pages$Users$MlnComnet$Chronological$OnUnselectProduct,
					g3: model.g3,
					ja: A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						_List_Nil,
						function ($) {
							return $.ja;
						},
						model.as),
					hh: model.hh,
					fM: {
						iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$Chronological$OnChangeSmallCategory),
						he: model.he,
						fH: model.fH,
						jy: A3(
							$krisajenkins$remotedata$RemoteData$unwrap,
							_List_Nil,
							function ($) {
								return $.jy;
							},
							model.as)
					}
				})
			]));
};
var $author$project$Pages$Users$MlnComnet$Chronological$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$Chronological$viewContent(model),
		hx: '時系列データ'
	};
};
var $author$project$Pages$Users$MlnComnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$MlnComnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$MlnComnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$MlnComnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$MlnComnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$MlnComnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$OnSubmit = {$: 9};
var $author$project$Pages$Users$MlnComnet$Hour$OnSubmitLatest = {$: 10};
var $author$project$Pages$Users$MlnComnet$Hour$OnUpdateDate = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$OnUpdateTime = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('中分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column mr-3')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$MlnComnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$OnPressDownloadCSV = {$: 11};
var $author$project$Pages$Users$MlnComnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$MlnComnet$Hour$OnChangeSortOption = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$MlnComnet$Hour$viewSortSelector = function (selectedSortOption) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示順')
					])),
				$author$project$Components$Molecules$SortTableSelector$sortTableSelector(
				{
					iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$Hour$OnChangeSortOption),
					js: $elm$core$Maybe$Just(selectedSortOption)
				})
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$MlnComnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$MlnComnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$MlnComnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$MlnComnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$MlnComnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$MlnComnet$Hour$viewSortSelector(model.js),
				$author$project$Pages$Users$MlnComnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$MlnComnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$MlnComnet$Hour$viewProductFilter(model),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row description')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowerPriceTd')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('参考価格を下回る')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowestPriceTd')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('当該機種の最低価格')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 lowestButHigherThanBasePriceTd')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('最低価格だが参考価格より上')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3 pastPriceTd')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('当該時刻に取得できず参考として前回価格を表示')
										]))
								]))
						])),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$MlnComnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$MlnComnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$MlnComnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$MlnComnet$Hour$viewContent, model, $author$project$Pages$Users$MlnComnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$MlnComnet$Hour$pStoreHeader, $author$project$Pages$Users$MlnComnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$MlnComnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$Hour$viewContentDefault(model),
		hx: 'WEB価格'
	};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$pStoreGroupsHeader = function (storeGroups) {
	return A2(
		$elm$core$List$cons,
		{bd: 4, mh: -1, mi: ''},
		A2(
			$elm$core$List$map,
			function (storeGroup) {
				return {
					bd: $elm$core$List$length(storeGroup.ap),
					mh: storeGroup.mh,
					mi: storeGroup.mi
				};
			},
			A2(
				$elm$core$List$filter,
				function (storeGroup) {
					return A2(
						$elm$core$List$any,
						function (store) {
							return store.hs === 1;
						},
						storeGroup.ap);
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return !$elm$core$List$isEmpty(storeGroup.ap);
					},
					A2(
						$elm$core$List$map,
						function (storeGroup) {
							return _Utils_update(
								storeGroup,
								{
									ap: A2(
										$elm$core$List$filter,
										function (store) {
											return store.hs === 1;
										},
										storeGroup.ap)
								});
						},
						A2(
							$elm$core$List$filter,
							function (storeGroup) {
								return storeGroup.mh === 1;
							},
							storeGroups))))));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$MlnComnet$HourYamada$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$MlnComnet$HourYamada$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnSubmit = {$: 8};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$HourYamada$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('中分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$HourYamada$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$HourYamada$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$MlnComnet$HourYamada$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$HourYamada$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$HourYamada$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnChangeDisplayStyle = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column mr-3')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$MlnComnet$HourYamada$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$MlnComnet$HourYamada$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$MlnComnet$HourYamada$OnChangeSortOption = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewSortSelector = function (selectedSortOption) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示順')
					])),
				$author$project$Components$Molecules$SortTableSelector$sortTableSelector(
				{
					iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$HourYamada$OnChangeSortOption),
					js: $elm$core$Maybe$Just(selectedSortOption)
				})
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$MlnComnet$HourYamada$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$MlnComnet$HourYamada$viewSortSelector(model.js),
				$author$project$Pages$Users$MlnComnet$HourYamada$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$MlnComnet$HourYamada$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = pStoreHeader_(model.U);
		var headerStoreGroups = pStoreGroupsHeader_(model.U);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$MlnComnet$HourYamada$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$MlnComnet$HourYamada$viewTableControl(model),
							$author$project$Pages$Users$MlnComnet$HourYamada$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$MlnComnet$HourYamada$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$MlnComnet$HourYamada$viewContent, model, $author$project$Pages$Users$MlnComnet$HourYamada$pStoreGroupsHeader, $author$project$Pages$Users$MlnComnet$HourYamada$pStoreHeader, $author$project$Pages$Users$MlnComnet$HourYamada$pProductPriceRows);
};
var $author$project$Pages$Users$MlnComnet$HourYamada$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$HourYamada$viewContentDefault(model),
		hx: 'ヤマダ下取り価格'
	};
};
var $author$project$Pages$Users$MlnComnet$MailSetting$OnInputQuery = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Users$MlnComnet$MailSetting$OnChangeMail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Users$MlnComnet$MailSetting$viewTr = F2(
	function (index, mail) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(20))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(index))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(400))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(mail.eX)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(80))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(mail.ev),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									function (b) {
										return $author$project$Pages$Users$MlnComnet$MailSetting$OnChangeMail(
											_Utils_update(
												mail,
												{ev: b}));
									})
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(200))
								]))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Components$TimeSelector$timeSelector,
							10,
							A2($elm$core$Maybe$withDefault, '', mail.fN),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									function (s) {
										return $author$project$Pages$Users$MlnComnet$MailSetting$OnChangeMail(
											_Utils_update(
												mail,
												{
													fN: $elm$core$Maybe$Just(s)
												}));
									})
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(200))
								]))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Components$TimeSelector$timeSelector,
							10,
							A2($elm$core$Maybe$withDefault, '', mail.ew),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									function (s) {
										return $author$project$Pages$Users$MlnComnet$MailSetting$OnChangeMail(
											_Utils_update(
												mail,
												{
													ew: $elm$core$Maybe$Just(s)
												}));
									})
								]))
						]))
				]));
	});
var $author$project$Pages$Users$MlnComnet$MailSetting$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.eK;
				switch (_v0.$) {
					case 0:
						return $author$project$Components$Loading$loadingCenter(50.0);
					case 1:
						return $author$project$Components$Loading$loadingCenter(50.0);
					case 2:
						var e = _v0.a;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Utils$Helpers$errToStr(e))
								]));
					default:
						var res = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '900px')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5 mb-5'),
											$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
										]),
									_List_fromArray(
										[
											A2($rtfeldman$elm_css$Html$Styled$ul, _List_Nil, _List_Nil)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-direction-row align-items-center mb-2')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('pr-2')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('検索:')
														]))
												])),
											$frandibar$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '400px')
														])),
													$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$MlnComnet$MailSetting$OnInputQuery)
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$table,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$thead,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$tr,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '20px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '400px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('メールアドレス')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('有効')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('開始時刻')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$th,
															_List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '200px')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('終了時刻')
																]))
														]))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$tbody,
											_List_Nil,
											A2(
												$elm$core$List$indexedMap,
												$author$project$Pages$Users$MlnComnet$MailSetting$viewTr,
												A2(
													$elm$core$List$filter,
													function (mail) {
														return (model.bn === '') && A2(
															$elm$core$String$contains,
															$elm$core$String$toLower(model.bn),
															$elm$core$String$toLower(mail.eX));
													},
													res.bh)))
										]))
								]));
				}
			}()
			]));
};
var $author$project$Pages$Users$MlnComnet$MailSetting$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$MailSetting$viewContent(model),
		hx: '送信時間設定'
	};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$OnChangeDirectURL = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$MlnComnet$Yahoo$OnEnableDirectURL = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$MlnComnet$Yahoo$OnSubmit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$MlnComnet$Yahoo$SelectProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Molecules$ProductSelector$Config = $elm$core$Basics$identity;
var $author$project$Components$Molecules$ProductSelector$custom = {he: $elm$core$Maybe$Nothing, fH: $elm$core$Maybe$Nothing, hA: '全て選択'};
var $rtfeldman$elm_css$Css$maxWidth = $rtfeldman$elm_css$Css$prop1('max-width');
var $author$project$Components$Molecules$ProductSelector$view = F3(
	function (props, attrs, _v0) {
		var conf = _v0;
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(attrs),
					A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$disabled(true),
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange,
					props.iS)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('-1'),
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							A2($elm$core$Maybe$withDefault, '-1', props.hf) === '-1')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(conf.hA)
						])),
				A2(
					$elm$core$List$map,
					function (product) {
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(product.i8),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(
										A2($elm$core$Maybe$withDefault, '', props.hf),
										product.i8))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(product.lT)
								]));
					},
					A2(
						$elm$core$List$filter,
						function (product) {
							return _Utils_eq(conf.fH, $elm$core$Maybe$Nothing) || (_Utils_eq(
								conf.fH,
								$elm$core$Maybe$Just(-1)) || _Utils_eq(
								A2($elm$core$Maybe$withDefault, -1, conf.fH),
								product.ho));
						},
						A2(
							$elm$core$List$filter,
							function (product) {
								return _Utils_eq(conf.he, $elm$core$Maybe$Nothing) || (_Utils_eq(
									conf.he,
									$elm$core$Maybe$Just(-1)) || _Utils_eq(
									A2($elm$core$Maybe$withDefault, -1, conf.he),
									product.ed));
							},
							props.ja)))));
	});
var $author$project$Components$Molecules$ProductSelector$withUnselectedLabel = F2(
	function (label, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{hA: label});
	});
var $author$project$Pages$Users$MlnComnet$Yahoo$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$maxWidth(
						$rtfeldman$elm_css$Css$px(780)),
						$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$auto)
					]))
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.eK;
				if (_v0.$ === 3) {
					var initRes = _v0.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('YahooのURLを設定する商品を選択してください')
											])),
										A3(
										$author$project$Components$Molecules$ProductSelector$view,
										{
											iS: $elm$core$Maybe$Just($author$project$Pages$Users$MlnComnet$Yahoo$SelectProduct),
											ja: initRes.ja,
											hf: $elm$core$Maybe$Just(model.hg)
										},
										_List_Nil,
										A2($author$project$Components$Molecules$ProductSelector$withUnselectedLabel, '選択してください', $author$project$Components$Molecules$ProductSelector$custom))
									])),
								function () {
								var _v1 = model.aG;
								if (_v1.$ === 3) {
									var urls = _v1.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$marginTop(
														$rtfeldman$elm_css$Css$px(24)),
														$rtfeldman$elm_css$Css$marginBottom(
														$rtfeldman$elm_css$Css$px(40))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$p,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('YahooのURLを設定してください')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$table,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table '),
														A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'word-break', 'keep-all')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$thead,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$th,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('店舗名')
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$th,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$css(
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Css$width(
																				$rtfeldman$elm_css$Css$px(600))
																			]))
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('URL')
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$th,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('有効')
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$th,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('更新')
																	]))
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$tbody,
														_List_Nil,
														A2(
															$elm$core$List$map,
															function (url) {
																var inputStatus = (url.dS === '') ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(_List_Nil) : ((A2($elm$core$String$startsWith, 'http://', url.dS) || A2($elm$core$String$startsWith, 'https://', url.dS)) ? $frandibar$elm_bootstrap$Bootstrap$Form$Input$success : $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger);
																return A2(
																	$rtfeldman$elm_css$Html$Styled$tr,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$rtfeldman$elm_css$Html$Styled$td,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text(url.jD)
																				])),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$td,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$width(
																							$rtfeldman$elm_css$Css$px(600))
																						]))
																				]),
																			_List_fromArray(
																				[
																					$frandibar$elm_bootstrap$Bootstrap$Form$Input$url(
																					_List_fromArray(
																						[
																							$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(url.dS),
																							$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
																							A2($author$project$Pages$Users$MlnComnet$Yahoo$OnChangeDirectURL, url.cl, url.cz)),
																							inputStatus
																						]))
																				])),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$td,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$rtfeldman$elm_css$Html$Styled$input,
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox'),
																							$rtfeldman$elm_css$Html$Styled$Attributes$checked(url.ev),
																							$rtfeldman$elm_css$Html$Styled$Events$onCheck(
																							A2($author$project$Pages$Users$MlnComnet$Yahoo$OnEnableDirectURL, url.cl, url.cz))
																						]),
																					_List_Nil)
																				])),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$td,
																			_List_Nil,
																			_List_fromArray(
																				[
																					A2(
																					$frandibar$elm_bootstrap$Bootstrap$Button$button,
																					_List_fromArray(
																						[
																							$frandibar$elm_bootstrap$Bootstrap$Button$disabled(
																							_Utils_eq(inputStatus, $frandibar$elm_bootstrap$Bootstrap$Form$Input$danger)),
																							$frandibar$elm_bootstrap$Bootstrap$Button$secondary,
																							$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
																							$author$project$Pages$Users$MlnComnet$Yahoo$OnSubmit(url))
																						]),
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$text('更新')
																						]))
																				]))
																		]));
															},
															urls))
													]))
											]));
								} else {
									return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
								}
							}()
							]));
				} else {
					return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
				}
			}()
			]));
};
var $author$project$Pages$Users$MlnComnet$Yahoo$view = function (model) {
	return {
		ko: $author$project$Pages$Users$MlnComnet$Yahoo$viewContent(model),
		hx: 'Yahoo 直接取得URL設定'
	};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$ShaComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$ShaComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$ShaComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$ShaComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$ShaComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$ShaComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$ShaComnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$ShaComnet$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Day$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																lowestClass,
																_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass)))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 5:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$ShaComnet$Day$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Day$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Day$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$ShaComnet$Day$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$ShaComnet$Day$Nop = {$: 18};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeBigCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeMaker = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeSmallCategory = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$OnSubmit = {$: 12};
var $author$project$Pages$Users$ShaComnet$Day$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$ShaComnet$Day$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('量販店')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$ShaComnet$Day$OnChangeStore,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.r))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.r)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Day$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Day$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Day$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$ShaComnet$Day$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$ShaComnet$Day$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Day$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$Day$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ShaComnet$Day$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$ShaComnet$Day$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$ShaComnet$Day$OnPressDownloadCSV = {$: 13};
var $author$project$Pages$Users$ShaComnet$Day$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Day$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$ShaComnet$Day$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$Day$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$ShaComnet$Day$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Day$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Day$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$ShaComnet$Day$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$ShaComnet$Day$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$ShaComnet$Day$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$ShaComnet$Day$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$ShaComnet$Day$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$ShaComnet$Day$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$ShaComnet$Day$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$ShaComnet$Day$viewTableControl(model),
						$author$project$Pages$Users$ShaComnet$Day$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$ShaComnet$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$Day$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '本日掲載された機種と価格';
			}
		}()
	};
};
var $author$project$Pages$Users$ShaComnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 5:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$ShaComnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$ShaComnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$ShaComnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$ShaComnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$ShaComnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ShaComnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ShaComnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$ShaComnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$ShaComnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$ShaComnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$ShaComnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$ShaComnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$ShaComnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$ShaComnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$ShaComnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$ShaComnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$ShaComnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$ShaComnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$ShaComnet$Hour$viewContent, model, $author$project$Pages$Users$ShaComnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$ShaComnet$Hour$pStoreHeader, $author$project$Pages$Users$ShaComnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$ShaComnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$ShaComnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$ShaComnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$ShaComnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$ShaComnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$ShaComnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$ShaComnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$ShaComnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$ShaComnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$ShaComnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$ShaComnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$ShaComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$ShaComnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$ShaComnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$ShaComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return (item.iF !== '__blank__') ? A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	])) : A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'height', '5em')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('')
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnSubmit = {$: 8};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$WeeklyProduct$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$ShaComnet$WeeklyProduct$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$WeeklyProduct$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewTableControl(model),
							$author$project$Pages$Users$ShaComnet$WeeklyProduct$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$ShaComnet$WeeklyProduct$viewContent, model, $author$project$Pages$Users$ShaComnet$WeeklyProduct$pStoreGroupsHeader, $author$project$Pages$Users$ShaComnet$WeeklyProduct$pStoreHeader, $author$project$Pages$Users$ShaComnet$WeeklyProduct$pProductPriceRows);
};
var $author$project$Pages$Users$ShaComnet$WeeklyProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$WeeklyProduct$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$ShaComnet$Wide$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$ShaComnet$Wide$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$ShaComnet$Wide$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Wide$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Wide$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$ShaComnet$Wide$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$OnChangeBigCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$OnChangeMaker = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$OnChangeSmallCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$OnSubmit = {$: 8};
var $author$project$Pages$Users$ShaComnet$Wide$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Wide$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Wide$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$ShaComnet$Wide$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$ShaComnet$Wide$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$disabled(!model.ct),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Wide$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$ShaComnet$Wide$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$ShaComnet$Wide$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$OnPressDownloadCSV = {$: 9};
var $author$project$Pages$Users$ShaComnet$Wide$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$ShaComnet$Wide$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$ShaComnet$Wide$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$ShaComnet$Wide$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$ShaComnet$Wide$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$ShaComnet$Wide$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$ShaComnet$Wide$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$ShaComnet$Wide$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$ShaComnet$Wide$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$ShaComnet$Wide$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$ShaComnet$Wide$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$ShaComnet$Wide$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$ShaComnet$Wide$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$ShaComnet$Wide$viewTableControl(model),
						$author$project$Pages$Users$ShaComnet$Wide$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$ShaComnet$Wide$view = function (model) {
	return {
		ko: $author$project$Pages$Users$ShaComnet$Wide$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return 'NET-WIDE 掲載された機種と価格の時間変化(24回版)';
			}
		}()
	};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateAllProducts = {$: 8};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateTriggerFlag = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$Drop = {$: 12};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$TescomComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$TescomComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$TescomComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$TescomComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$TescomComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$TescomComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mz),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateTriggerFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$TescomComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は税込みです')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TescomComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table tescom-comnet')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('ヨドバシを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('トリガーを')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('P算入後に')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$TescomComnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$TescomComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TescomComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$TescomComnet$Excel$Nop = {$: 13};
var $author$project$Pages$Users$TescomComnet$Excel$OnChangeBigCategory = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$OnChangeSmallCategory = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$OnSubmit = {$: 10};
var $author$project$Pages$Users$TescomComnet$Excel$OnUpdateDateFrom = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$OnUpdateDateTo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$OnUpdateTimeFrom = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$OnUpdateTimeTo = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TescomComnet$Excel$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Excel$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Excel$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$TescomComnet$Excel$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$TescomComnet$Excel$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TescomComnet$Excel$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Excel$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TescomComnet$Excel$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Excel$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TescomComnet$Excel$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$TescomComnet$Excel$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$TescomComnet$Excel$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Alert$simpleDanger,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, '', model.ar))
							]))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$TescomComnet$Excel$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TescomComnet$Excel$viewContent(model),
		hx: 'エクセル出力'
	};
};
var $author$project$Pages$Users$TescomComnet$Hour$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TescomComnet$Hour$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(pastClass, lowerClass))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$TescomComnet$Hour$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$TescomComnet$Hour$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TescomComnet$Hour$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$TescomComnet$Hour$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$TescomComnet$Hour$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$OnSubmit = {$: 8};
var $author$project$Pages$Users$TescomComnet$Hour$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$TescomComnet$Hour$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Hour$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Hour$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TescomComnet$Hour$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TescomComnet$Hour$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TescomComnet$Hour$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TescomComnet$Hour$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$TescomComnet$Hour$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TescomComnet$Hour$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$TescomComnet$Hour$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$TescomComnet$Hour$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$TescomComnet$Hour$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TescomComnet$Hour$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$TescomComnet$Hour$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TescomComnet$Hour$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$TescomComnet$Hour$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$TescomComnet$Hour$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TescomComnet$Hour$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$TescomComnet$Hour$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$TescomComnet$Hour$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$TescomComnet$Hour$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$TescomComnet$Hour$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$TescomComnet$Hour$viewTableControl(model),
							$author$project$Pages$Users$TescomComnet$Hour$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$TescomComnet$Hour$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$TescomComnet$Hour$viewContent, model, $author$project$Pages$Users$TescomComnet$Hour$pStoreGroupsHeader, $author$project$Pages$Users$TescomComnet$Hour$pStoreHeader, $author$project$Pages$Users$TescomComnet$Hour$pProductPriceRows);
};
var $author$project$Pages$Users$TescomComnet$Hour$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TescomComnet$Hour$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$Test$Fifteen$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Test$Fifteen$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Test$Fifteen$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Test$Fifteen$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Test$Fifteen$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Test$Fifteen$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Test$Fifteen$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$OnSubmit = {$: 8};
var $author$project$Pages$Users$Test$Fifteen$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Test$Fifteen$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Test$Fifteen$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Test$Fifteen$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Test$Fifteen$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Test$Fifteen$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Test$Fifteen$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Test$Fifteen$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Test$Fifteen$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Test$Fifteen$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Test$Fifteen$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Test$Fifteen$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Test$Fifteen$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Test$Fifteen$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Test$Fifteen$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Test$Fifteen$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Test$Fifteen$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Test$Fifteen$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Test$Fifteen$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Test$Fifteen$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Test$Fifteen$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Test$Fifteen$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Test$Fifteen$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Test$Fifteen$viewTableControl(model),
							$author$project$Pages$Users$Test$Fifteen$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Test$Fifteen$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Test$Fifteen$viewContent, model, $author$project$Pages$Users$Test$Fifteen$pStoreGroupsHeader, $author$project$Pages$Users$Test$Fifteen$pStoreHeader, $author$project$Pages$Users$Test$Fifteen$pProductPriceRows);
};
var $author$project$Pages$Users$Test$Fifteen$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Test$Fifteen$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateAllProducts = {$: 7};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateAnyPoint = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$Drop = {$: 11};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$To24tclComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$To24tclComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$To24tclComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$To24tclComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$viewTr = F5(
	function (product, ind, dragFrom, dragTo, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						product.c3 ? ('targetDrop' + function () {
							var _v0 = _Utils_Tuple2(dragFrom, dragTo);
							if ((!_v0.a.$) && (!_v0.b.$)) {
								var from = _v0.a.a;
								var to = _v0.b.a;
								return (_Utils_cmp(from, to) > 0) ? 'Up' : 'Down';
							} else {
								return '';
							}
						}()) : ''),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!dragFrom.$) {
								var draggingInd = dragFrom.a;
								return _Utils_eq(ind, draggingInd) ? 'srcDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$To24tclComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$To24tclComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(base_price_without_point)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 101) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 102) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 103) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 105) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 110) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 113) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 115) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							function () {
								if (!anyPoint.$) {
									var point = anyPoint.a;
									return $author$project$Utils$Helpers$toCommmaSplited(
										$elm$core$String$fromInt(((base_price_without_point * (100 + point)) / 100) | 0));
								} else {
									return '';
								}
							}())
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は想定売価（税抜き）です')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tclComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込 P後')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('1%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('2%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('3%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('5%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('10%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('13%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('15%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('small float-left')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('任意%入力')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$To24tclComnet$ChangeProduct$UpdateAnyPoint),
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
														_List_fromArray(
															[
																A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px'),
																$rtfeldman$elm_css$Html$Styled$Attributes$max('100'),
																$rtfeldman$elm_css$Html$Styled$Attributes$min('0')
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヨドバシ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A5($author$project$Pages$Users$To24tclComnet$ChangeProduct$viewTr, product, ind, model.bU, model.cS, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$To24tclComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tclComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$To24tclComnet$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tclComnet$Day$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'dayHeader';
																	case 3:
																		return 'itemHeader';
																	case 4:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$To24tclComnet$Day$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'dayHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$To24tclComnet$Day$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tclComnet$Day$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$To24tclComnet$Day$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$To24tclComnet$Day$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$To24tclComnet$Day$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tclComnet$Day$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tclComnet$Day$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tclComnet$Day$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('本日分を表示')
					]))
			]));
};
var $author$project$Pages$Users$To24tclComnet$Day$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$To24tclComnet$Day$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$To24tclComnet$Day$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$To24tclComnet$Day$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$To24tclComnet$Day$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tclComnet$Day$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$To24tclComnet$Day$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tclComnet$Day$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$To24tclComnet$Day$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$To24tclComnet$Day$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tclComnet$Day$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$To24tclComnet$Day$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$To24tclComnet$Day$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$To24tclComnet$Day$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$To24tclComnet$Day$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$To24tclComnet$Day$viewTableControl(model),
							$author$project$Pages$Users$To24tclComnet$Day$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$To24tclComnet$Day$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$To24tclComnet$Day$viewContent, model, $author$project$Pages$Users$To24tclComnet$Day$pStoreGroupsHeader, $author$project$Pages$Users$To24tclComnet$Day$pStoreHeader, $author$project$Pages$Users$To24tclComnet$Day$pProductPriceRows);
};
var $author$project$Pages$Users$To24tclComnet$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tclComnet$Day$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Common$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('emptyMultiselectPlaceholder text-dark')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Common$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Common$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$To24tclComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Common$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Common$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$To24tclComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tclComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateAllProducts = {$: 8};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateAnyPoint = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateBasePoint = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateInterval = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$Drop = {$: 12};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$To24tcmComnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$To24tcmComnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$To24tcmComnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$To24tcmComnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$intToOption = F2(
	function (selected_v, v) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$option,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$value(
					$elm$core$String$fromInt(v)),
					$rtfeldman$elm_css$Html$Styled$Attributes$selected(
					_Utils_eq(v, selected_v))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$elm$core$String$fromInt(v))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		var base_price_without_point = A2($author$project$Utils$Helpers$calculatePriceWithoutPoint, product.ka, product.j9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$To24tcmComnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$To24tcmComnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									$author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateInterval(ind)),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
								]),
							A2(
								$elm$core$List$map,
								$author$project$Pages$Users$To24tcmComnet$ChangeProduct$intToOption(product.$9),
								_List_fromArray(
									[15, 60, 360])))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.j9)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '80px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateBasePoint(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(base_price_without_point)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 101) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 102) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 103) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 105) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 107) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Utils$Helpers$toCommmaSplited(
								$elm$core$String$fromInt(((base_price_without_point * 110) / 100) | 0)))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							function () {
								if (!anyPoint.$) {
									var point = anyPoint.a;
									return $author$project$Utils$Helpers$toCommmaSplited(
										$elm$core$String$fromInt(((base_price_without_point * (100 + point)) / 100) | 0));
								} else {
									return '';
								}
							}())
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は想定売価（税抜き）です')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										function () {
										var intervalSum = $author$project$Pages$Users$To24tcmComnet$ChangeProduct$getIntervalSum(model.ja);
										return A2(
											$rtfeldman$elm_css$Html$Styled$th,
											(intervalSum > 120) ? _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'color', '#f33'),
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'font-size', '150%'),
													A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'background-color', '#fb4')
												]) : _List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													(intervalSum > 120) ? _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Interval Exceeded')
														]) : _List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Interval')
														])),
													A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
													A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$core$String$fromInt(
																$author$project$Pages$Users$To24tcmComnet$ChangeProduct$getIntervalSum(model.ja)) + '/120')
														]))
												]));
									}(),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ポイント')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('基準価格')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('税込 P後')
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('1%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('2%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('3%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('5%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('7%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('10%')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('small float-left')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('任意%入力')
													])),
												A2($rtfeldman$elm_css$Html$Styled$br, _List_Nil, _List_Nil),
												$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$To24tcmComnet$ChangeProduct$UpdateAnyPoint),
														$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
														_List_fromArray(
															[
																A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '100px'),
																$rtfeldman$elm_css$Html$Styled$Attributes$max('100'),
																$rtfeldman$elm_css$Html$Styled$Attributes$min('0')
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヨドバシ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$To24tcmComnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmComnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$OnSubmit = {$: 8};
var $author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateDateFrom = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateDateTo = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateTimeFrom = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateTimeTo = function (a) {
	return {$: 7, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$multiple = $elm$html$Html$Attributes$boolProperty('multiple');
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $abadi199$elm_input_extra$MultiSelect$Option = F3(
	function (value, text, selected) {
		return {jo: selected, jI: text, i: value};
	});
var $abadi199$elm_input_extra$MultiSelect$optionDecoder = A4(
	$elm$json$Json$Decode$map3,
	$abadi199$elm_input_extra$MultiSelect$Option,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'selected', $elm$json$Json$Decode$bool));
var $abadi199$elm_input_extra$MultiSelect$optionsDecoder = A2(
	$elm$json$Json$Decode$field,
	'options',
	A2(
		$elm$json$Json$Decode$andThen,
		function (list) {
			var onlySuccess = A3(
				$elm$core$List$foldr,
				F2(
					function (_v0, sofar) {
						var next = _v0.b;
						if (next.$ === 1) {
							return sofar;
						} else {
							var x = next.a;
							return A2($elm$core$List$cons, x, sofar);
						}
					}),
				_List_Nil,
				list);
			return $elm$json$Json$Decode$succeed(onlySuccess);
		},
		$elm$json$Json$Decode$keyValuePairs(
			$elm$json$Json$Decode$maybe($abadi199$elm_input_extra$MultiSelect$optionDecoder))));
var $abadi199$elm_input_extra$MultiSelect$selectedOptionsDecoder = function () {
	var filterSelected = function (options) {
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.i;
			},
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.jo;
				},
				options));
	};
	return A2(
		$elm$json$Json$Decode$map,
		filterSelected,
		A2($elm$json$Json$Decode$field, 'target', $abadi199$elm_input_extra$MultiSelect$optionsDecoder));
}();
var $abadi199$elm_input_extra$MultiSelect$onChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $abadi199$elm_input_extra$MultiSelect$selectedOptionsDecoder));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $abadi199$elm_input_extra$MultiSelect$multiSelect = F3(
	function (options, attributes, currentValue) {
		var isSelected = function (value) {
			return A2(
				$elm$core$List$any,
				$elm$core$Basics$eq(value),
				currentValue);
		};
		var toOption = function (_v0) {
			var value = _v0.i;
			var text = _v0.jI;
			var enabled = _v0.ev;
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(value),
						$elm$html$Html$Attributes$selected(
						isSelected(value)),
						$elm$html$Html$Attributes$disabled(!enabled)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(text)
					]));
		};
		return A2(
			$elm$html$Html$select,
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$abadi199$elm_input_extra$MultiSelect$onChange(options.iS),
						$elm$html$Html$Attributes$multiple(true)
					])),
			A2($elm$core$List$map, toOption, options.k9));
	});
var $author$project$Pages$Users$To24tcmComnet$Csv$UpdateMultiselect = function (a) {
	return {$: 10, a: a};
};
var $abadi199$elm_input_extra$MultiSelect$defaultOptions = function (onChangeHandler) {
	return {k9: _List_Nil, iS: onChangeHandler};
};
var $author$project$Pages$Users$To24tcmComnet$Csv$multiSelectOptions = function (model) {
	var defaultOptions = $abadi199$elm_input_extra$MultiSelect$defaultOptions($author$project$Pages$Users$To24tcmComnet$Csv$UpdateMultiselect);
	return _Utils_update(
		defaultOptions,
		{
			k9: A2(
				$elm$core$List$map,
				function (_v0) {
					var v = _v0.a;
					var t = _v0.b;
					return {ev: true, jI: t, i: v};
				},
				model.ja)
		});
};
var $elm$html$Html$Attributes$size = function (n) {
	return A2(
		_VirtualDom_attribute,
		'size',
		$elm$core$String$fromInt(n));
};
var $author$project$Pages$Users$To24tcmComnet$Csv$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 ')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('型番')
									])),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
								A3(
									$abadi199$elm_input_extra$MultiSelect$multiSelect,
									$author$project$Pages$Users$To24tcmComnet$Csv$multiSelectOptions(model),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('multiSelectContainer'),
											$elm$html$Html$Attributes$id('products-selector'),
											$elm$html$Html$Attributes$size(18)
										]),
									model.hh))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date'),
												$rtfeldman$elm_css$Html$Styled$Attributes$max(model.de),
												$rtfeldman$elm_css$Html$Styled$Attributes$min(model.dh)
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateDateFrom)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								model.Z,
								model.hi,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateTimeFrom)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('\u3000')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('align-middle')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('〜')
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得日')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$id('date'),
												$rtfeldman$elm_css$Html$Styled$Attributes$max(model.de),
												$rtfeldman$elm_css$Html$Styled$Attributes$min(model.dh)
											])),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
										$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateDateTo)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('取得時刻')
									])),
								A3(
								$author$project$Components$TimeSelector$timeSelector,
								model.Z,
								model.hj,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
										$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Csv$OnUpdateTimeTo)
									]))
							])),
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$group,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Form$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('\u3000')
									])),
								A2(
								$frandibar$elm_bootstrap$Bootstrap$Button$button,
								_List_fromArray(
									[
										$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											])),
										$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Csv$OnSubmit)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('送信')
									]))
							]))
					])),
				(model.b2 || model.c5) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Csv$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmComnet$Csv$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '期間指定CSV';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tcmComnet$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'dayHeader';
																	case 3:
																		return 'itemHeader';
																	case 4:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$To24tcmComnet$Day$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'dayHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmComnet$Day$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$To24tcmComnet$Day$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Day$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$To24tcmComnet$Day$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Day$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Day$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Day$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('本日分を表示')
					]))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Day$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Day$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$To24tcmComnet$Day$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Day$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$To24tcmComnet$Day$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Day$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$To24tcmComnet$Day$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$Day$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$To24tcmComnet$Day$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmComnet$Day$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$To24tcmComnet$Day$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$To24tcmComnet$Day$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Day$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$To24tcmComnet$Day$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$To24tcmComnet$Day$viewTableControl(model),
							$author$project$Pages$Users$To24tcmComnet$Day$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Day$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$To24tcmComnet$Day$viewContent, model, $author$project$Pages$Users$To24tcmComnet$Day$pStoreGroupsHeader, $author$project$Pages$Users$To24tcmComnet$Day$pStoreHeader, $author$project$Pages$Users$To24tcmComnet$Day$pProductPriceRows);
};
var $author$project$Pages$Users$To24tcmComnet$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmComnet$Day$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tcmComnet$Product$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 4, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$To24tcmComnet$Product$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmComnet$Product$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$To24tcmComnet$Product$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnSubmit = {$: 8};
var $author$project$Pages$Users$To24tcmComnet$Product$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$To24tcmComnet$Product$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Product$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Product$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$To24tcmComnet$Product$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmComnet$Product$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Product$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Product$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$To24tcmComnet$Product$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$To24tcmComnet$Product$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Product$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$To24tcmComnet$Product$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmComnet$Product$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$To24tcmComnet$Product$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmComnet$Product$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$To24tcmComnet$Product$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmComnet$Product$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$To24tcmComnet$Product$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$To24tcmComnet$Product$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$To24tcmComnet$Product$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$To24tcmComnet$Product$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$To24tcmComnet$Product$viewTableControl(model),
							$author$project$Pages$Users$To24tcmComnet$Product$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$To24tcmComnet$Product$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$To24tcmComnet$Product$viewContent, model, $author$project$Pages$Users$To24tcmComnet$Product$pStoreGroupsHeader, $author$project$Pages$Users$To24tcmComnet$Product$pStoreHeader, $author$project$Pages$Users$To24tcmComnet$Product$pProductPriceRows);
};
var $author$project$Pages$Users$To24tcmComnet$Product$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmComnet$Product$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 ')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$Common$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('emptyMultiselectPlaceholder text-dark')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Common$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Common$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$Common$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$Common$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$Common$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$To24tcmComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$Common$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$Common$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$To24tcmComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'dayHeader';
																	case 3:
																		return 'itemHeader';
																	case 4:
																		return 'basePriceHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerClass = price.gK ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(lowestButHigherThanBasePriceClass, lowerClass))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'dayHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmKitchen$Day$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$To24tcmKitchen$Day$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$To24tcmKitchen$Day$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmKitchen$Day$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$To24tcmKitchen$Day$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmKitchen$Day$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('本日分を表示')
					]))
			]));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$To24tcmKitchen$Day$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$To24tcmKitchen$Day$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$To24tcmKitchen$Day$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$To24tcmKitchen$Day$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$To24tcmKitchen$Day$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$To24tcmKitchen$Day$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$To24tcmKitchen$Day$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$To24tcmKitchen$Day$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$To24tcmKitchen$Day$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$To24tcmKitchen$Day$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$To24tcmKitchen$Day$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$To24tcmKitchen$Day$viewTableControl(model),
							$author$project$Pages$Users$To24tcmKitchen$Day$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$To24tcmKitchen$Day$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$To24tcmKitchen$Day$viewContent, model, $author$project$Pages$Users$To24tcmKitchen$Day$pStoreGroupsHeader, $author$project$Pages$Users$To24tcmKitchen$Day$pStoreHeader, $author$project$Pages$Users$To24tcmKitchen$Day$pProductPriceRows);
};
var $author$project$Pages$Users$To24tcmKitchen$Day$view = function (model) {
	return {
		ko: $author$project$Pages$Users$To24tcmKitchen$Day$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $author$project$Pages$Users$TsBat$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TsBat$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$TsBat$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$TsBat$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TsBat$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$TsBat$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$TsBat$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$TsBat$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$TsBat$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$TsBat$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TsBat$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TsBat$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$TsBat$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$TsBat$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TsBat$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$TsBat$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TsBat$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$TsBat$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$TsBat$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TsBat$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$TsBat$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$TsBat$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$TsBat$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$TsBat$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$TsBat$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$TsBat$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$TsBat$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$TsBat$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$TsBat$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TsBat$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return '2回 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$TsBat$Wide$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TsBat$Wide$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$TsBat$Wide$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$TsBat$Wide$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TsBat$Wide$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$TsBat$Wide$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$TsBat$Wide$OnChangeBigCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$OnChangeMaker = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$OnChangeSmallCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$OnSubmit = {$: 8};
var $author$project$Pages$Users$TsBat$Wide$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$Wide$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$Wide$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TsBat$Wide$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TsBat$Wide$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TsBat$Wide$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$TsBat$Wide$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TsBat$Wide$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$TsBat$Wide$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$TsBat$Wide$OnPressDownloadCSV = {$: 9};
var $author$project$Pages$Users$TsBat$Wide$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TsBat$Wide$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$TsBat$Wide$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TsBat$Wide$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$TsBat$Wide$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$TsBat$Wide$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TsBat$Wide$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$TsBat$Wide$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$TsBat$Wide$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$TsBat$Wide$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$TsBat$Wide$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$TsBat$Wide$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$TsBat$Wide$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$TsBat$Wide$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$TsBat$Wide$viewTableControl(model),
						$author$project$Pages$Users$TsBat$Wide$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$TsBat$Wide$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TsBat$Wide$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return 'NET-WIDE 掲載された機種と価格の時間変化(2回版)';
			}
		}()
	};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TstComnet$TimeSeq$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$TstComnet$TimeSeq$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$Nop = {$: 17};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnChangeBigCategory = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnChangeSmallCategory = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnSubmit = {$: 11};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateDateTo = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateTimeFrom = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateTimeTo = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3 '),
								$author$project$Utils$Helpers$preventDefaultEnter($author$project$Pages$Users$TstComnet$TimeSeq$Nop)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('型番')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiSelectContainer'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(
								model.gb ? 'multiSelectWarning' : '')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Pages$Users$TstComnet$TimeSeq$OnChangeProduct,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.D))),
								$elm$core$List$isEmpty(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.D)) ? A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('allSelectMultiselectPlaceholder')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('全て選択')
									])) : A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('flex-break')
					]),
				_List_Nil),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hi,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hi),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateTimeFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.dv),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateDateTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.hj,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hj),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$TimeSeq$OnUpdateTimeTo)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TstComnet$TimeSeq$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TstComnet$TimeSeq$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$TstComnet$TimeSeq$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$OnPressDownloadCSV = {$: 12};
var $author$project$Pages$Users$TstComnet$TimeSeq$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TstComnet$TimeSeq$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$TstComnet$TimeSeq$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$TstComnet$TimeSeq$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$TstComnet$TimeSeq$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TstComnet$TimeSeq$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$TstComnet$TimeSeq$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$TstComnet$TimeSeq$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$TstComnet$TimeSeq$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$TstComnet$TimeSeq$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$TstComnet$TimeSeq$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$TstComnet$TimeSeq$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$TstComnet$TimeSeq$viewTableControl(model),
						$author$project$Pages$Users$TstComnet$TimeSeq$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$TstComnet$TimeSeq$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TstComnet$TimeSeq$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得 時系列';
			}
		}()
	};
};
var $author$project$Pages$Users$TstComnet$Wide$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$TstComnet$Wide$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (productRow) {
					return A2(
						$elm$core$List$indexedMap,
						function (rowInd) {
							return function (row) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$tr,
									_List_Nil,
									$elm$core$List$concat(
										A2(
											$elm$core$List$indexedMap,
											function (ind) {
												return function (cell) {
													switch (cell.$) {
														case 0:
															var _v1 = cell.a;
															var rowspan = _v1.a;
															var itemList = _v1.b;
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(
																			function () {
																				var fixedInd = (!rowInd) ? ind : (ind + 2);
																				switch (fixedInd) {
																					case 0:
																						return 'numberHeader';
																					case 1:
																						return 'katabanHeader';
																					case 2:
																						return 'nthHeader';
																					case 3:
																						return 'itemHeader';
																					case 4:
																						return 'basePriceHeader';
																					default:
																						return '';
																				}
																			}()),
																			$rtfeldman$elm_css$Html$Styled$Attributes$rowspan(rowspan)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			return A2(
																				$rtfeldman$elm_css$Html$Styled$p,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]));
																		},
																		itemList))
																]);
														case 1:
															return _List_fromArray(
																[
																	A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
																]);
														default:
															var price = cell.a;
															var priceChangingTypeClass = function () {
																var _v5 = price.fl;
																switch (_v5.$) {
																	case 0:
																		return 'increasedPriceTd ';
																	case 1:
																		return 'decreasedPriceTd ';
																	default:
																		return '';
																}
															}();
															var comparedPriceType = function () {
																var _v4 = price.cO;
																switch (_v4) {
																	case 0:
																		return 'lowestPriceTd';
																	case 2:
																		return 'lowestButHigherThanBasePriceTd ';
																	case 1:
																		return 'lowerPriceTd';
																	default:
																		return '';
																}
															}();
															return _List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$td,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(comparedPriceType),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class(priceChangingTypeClass)
																		]),
																	A2(
																		$elm$core$List$map,
																		function (item) {
																			var _v3 = item.i;
																			switch (_v3) {
																				case 2:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				case 4:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$text(item.iF)
																							]));
																				default:
																					return A2(
																						$rtfeldman$elm_css$Html$Styled$p,
																						_List_fromArray(
																							[
																								$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$rtfeldman$elm_css$Html$Styled$a,
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																										$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																									]),
																								_List_fromArray(
																									[
																										$rtfeldman$elm_css$Html$Styled$text(item.iF)
																									]))
																							]));
																			}
																		},
																		price.g2))
																]);
													}
												};
											},
											row)));
							};
						},
						productRow);
				},
				bodyProducts)));
};
var $author$project$Pages$Users$TstComnet$Wide$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'nthHeader rowHeader';
														case 3:
															return 'itemHeader rowHeader';
														case 4:
															return 'basePriceHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$TstComnet$Wide$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum)))
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TstComnet$Wide$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$TstComnet$Wide$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$OnChangeBigCategory = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$OnChangeMaker = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$OnChangeSmallCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$OnSubmit = {$: 8};
var $author$project$Pages$Users$TstComnet$Wide$OnUpdateDateFrom = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('メーカー')
							])),
						A3(
						$author$project$Components$CategorySelector$makerSelector,
						model.ca,
						model.dw,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$Wide$OnChangeMaker)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$Wide$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$TstComnet$Wide$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.du),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$TstComnet$Wide$OnUpdateDateFrom)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('button')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TstComnet$Wide$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					]))
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$TstComnet$Wide$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$TstComnet$Wide$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					$author$project$Utils$Helpers$displayStyleList))
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$OnPressDownloadCSV = {$: 9};
var $author$project$Pages$Users$TstComnet$Wide$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$TstComnet$Wide$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$TstComnet$Wide$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$TstComnet$Wide$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$TstComnet$Wide$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$TstComnet$Wide$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$TstComnet$Wide$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$TstComnet$Wide$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$TstComnet$Wide$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$viewContent = function (model) {
	var headerStores = A2($author$project$Pages$Users$TstComnet$Wide$pStoreHeader, model.U, model.L);
	var headerStoreGroups = A2($author$project$Pages$Users$TstComnet$Wide$pStoreGroupsHeader, model.U, model.L);
	var bodyProducts = $author$project$Pages$Users$TstComnet$Wide$pProductPriceRows(model);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
			]),
		_List_fromArray(
			[
				$author$project$Pages$Users$TstComnet$Wide$viewProductFilter(model),
				A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
				(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : (model.gz ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$Users$TstComnet$Wide$viewTableControl(model),
						$author$project$Pages$Users$TstComnet$Wide$viewPriceTable(
						{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
			]));
};
var $author$project$Pages$Users$TstComnet$Wide$view = function (model) {
	return {
		ko: $author$project$Pages$Users$TstComnet$Wide$viewContent(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return 'NET-WIDE 掲載された機種と価格の時間変化(4回版)';
			}
		}()
	};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateAllProducts = {$: 8};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateBasePrice = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateProduct = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateRPrice = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateYodobashiFlag = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$DragStart = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$Drop = {$: 12};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$dropSourceConfig = function (ind) {
	return {
		kG: {kq: false, lc: false, lr: true},
		lw: $elm$core$Maybe$Nothing,
		lB: $elm$core$Basics$always($author$project$Pages$Users$Tv24Comnet$ChangeProduct$Drop),
		lG: $author$project$Pages$Users$Tv24Comnet$ChangeProduct$DragStart(ind)
	};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$DragOver = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$dropTargetConfig = function (ind) {
	return {
		kF: 1,
		lA: $elm$core$Basics$always($author$project$Pages$Users$Tv24Comnet$ChangeProduct$Drop),
		lC: $elm$core$Maybe$Nothing,
		lD: $elm$core$Maybe$Nothing,
		lF: $author$project$Pages$Users$Tv24Comnet$ChangeProduct$DragOver(ind)
	};
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$viewTr = F4(
	function (product, ind, draggingProductInd, anyPoint) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$draggable('true'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						(product.kE > 0) ? 'draggingUpward' : ((product.kE < 0) ? 'draggingDownward' : '')),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						function () {
							if (!draggingProductInd.$) {
								var draggingInd = draggingProductInd.a;
								return _Utils_eq(ind, draggingInd) ? 'targetDrop' : '';
							} else {
								return '';
							}
						}())
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onSourceDrag(
							$author$project$Pages$Users$Tv24Comnet$ChangeProduct$dropSourceConfig(ind))),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$Attributes$fromUnstyled,
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Drag$onDropTarget(
							$author$project$Pages$Users$Tv24Comnet$ChangeProduct$dropTargetConfig(ind))))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.lT)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(product.i8)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(product.ka)),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '120px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateBasePrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$frandibar$elm_bootstrap$Bootstrap$Form$Input$number(
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$value(
											$elm$core$String$fromInt(
												A2($elm$core$Maybe$withDefault, 0, product.dr))),
											A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '120px')
										])),
									$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateRPrice(ind))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(product.mM),
									$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
									$author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateYodobashiFlag(ind))
								]),
							'')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Button$button,
							_List_fromArray(
								[
									$frandibar$elm_bootstrap$Bootstrap$Button$onClick(
									$author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateProduct(ind))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('更新')
								]))
						]))
				]));
	});
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$viewContent = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3 mt-5'),
				$rtfeldman$elm_css$Html$Styled$Attributes$align('center')
			]),
		_List_fromArray(
			[
				(model.b2 || model.J) ? $author$project$Components$Loading$loadingCenter(50.0) : ($elm_community$maybe_extra$Maybe$Extra$isJust(model.ar) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.ar))
					])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', '1000px'),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5 mb-5'),
						$rtfeldman$elm_css$Html$Styled$Attributes$align('left')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 基準価格は想定売価（税抜き）です')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('- 商品の順序は、行をドラッグすることで変更できます(変更後は、一括更新してください)')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Button$primary,
								$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Tv24Comnet$ChangeProduct$OnUpdateAllProducts)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('一括更新')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('default-table')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('型番')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('JANコード')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('基準価格')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('R調整価格')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ヨドバシ')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$th,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('更新')
											]))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$tbody,
						_List_Nil,
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (product) {
									return A4($author$project$Pages$Users$Tv24Comnet$ChangeProduct$viewTr, product, ind, model.ia, model.hM);
								};
							},
							model.ja))
					]))
			]));
};
var $author$project$Pages$Users$Tv24Comnet$ChangeProduct$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Tv24Comnet$ChangeProduct$viewContent(model),
		hx: '商品の設定'
	};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$pStoreGroupsHeader = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$elm$core$List$cons,
			{bd: 5, mh: -1, mi: ''},
			A2(
				$elm$core$List$map,
				function (storeGroup) {
					return {
						bd: $elm$core$List$length(storeGroup.ap),
						mh: storeGroup.mh,
						mi: storeGroup.mi
					};
				},
				A2(
					$elm$core$List$filter,
					function (storeGroup) {
						return A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups) && (!$elm$core$List$isEmpty(storeGroup.ap));
					},
					storeGroups)));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewTableBody = function (bodyProducts) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (row) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (ind) {
								return function (cell) {
									switch (cell.$) {
										case 0:
											var itemList = cell.a;
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															function () {
																switch (ind) {
																	case 0:
																		return 'numberHeader';
																	case 1:
																		return 'katabanHeader';
																	case 2:
																		return 'itemHeader';
																	case 3:
																		return 'basePriceHeader';
																	case 4:
																		return 'katabanHeader';
																	default:
																		return '';
																}
															}())
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															return A2(
																$rtfeldman$elm_css$Html$Styled$p,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(item.iF)
																	]));
														},
														itemList))
												]);
										case 1:
											return _List_fromArray(
												[
													A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil)
												]);
										default:
											var price = cell.a;
											var pastClass = (!price.eP) ? 'pastPriceTd ' : '';
											var lowestClass = price.gM ? 'lowestPriceTd ' : '';
											var lowestButHigherThanBasePriceClass = price.iB ? 'lowestButHigherThanBasePriceTd ' : '';
											var lowerRtyouseiClass = price.gL ? 'lowerRtyouseiPriceTd' : '';
											var lowerClass = (price.gK && (!price.gL)) ? 'lowerPriceTd ' : '';
											return _List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class(
															_Utils_ap(
																pastClass,
																_Utils_ap(
																	lowestClass,
																	_Utils_ap(
																		lowestButHigherThanBasePriceClass,
																		_Utils_ap(lowerClass, lowerRtyouseiClass)))))
														]),
													A2(
														$elm$core$List$map,
														function (item) {
															var _v2 = item.i;
															switch (_v2) {
																case 2:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap text-danger')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																case 4:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(item.iF)
																			]));
																default:
																	return A2(
																		$rtfeldman$elm_css$Html$Styled$p,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('text-nowrap')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$rtfeldman$elm_css$Html$Styled$a,
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$Attributes$href(price.dS),
																						$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
																					]),
																				_List_fromArray(
																					[
																						$rtfeldman$elm_css$Html$Styled$text(item.iF)
																					]))
																			]));
															}
														},
														price.g2))
												]);
									}
								};
							},
							row)));
			},
			bodyProducts));
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewTableHeader = F2(
	function (headerStoreGroups, headerStores) {
		var colsNum = A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, headerStoreGroups)).bd;
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (headerStoreGroup) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (!ind) {
													return 'rowHeader';
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(headerStoreGroup.mh);
												}
											}()),
											$rtfeldman$elm_css$Html$Styled$Attributes$colspan(headerStoreGroup.bd)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(headerStoreGroup.mi)
										]));
							};
						},
						headerStoreGroups)),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						function (ind) {
							return function (store) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class(
											function () {
												if (_Utils_cmp(ind, colsNum) < 0) {
													switch (ind) {
														case 0:
															return 'numberHeader rowHeader';
														case 1:
															return 'katabanHeader rowHeader';
														case 2:
															return 'itemHeader rowHeader';
														case 3:
															return 'basePriceHeader rowHeader';
														case 4:
															return 'katabanHeader rowHeader';
														default:
															return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
													}
												} else {
													return 'storeGroupHeader' + $elm$core$String$fromInt(store.mh);
												}
											}())
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(store.jD)
										]));
							};
						},
						headerStores))
				]));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewPriceTable = function (priceTableRequired) {
	var rowsNum = '2';
	var colsNum = $elm$core$String$fromInt(
		A2(
			$elm$core$Maybe$withDefault,
			{bd: 0, mh: 0, mi: ''},
			A2($elm_community$list_extra$List$Extra$getAt, 0, priceTableRequired.eI)).bd);
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('priceTable'),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, '_fixedhead', 'rows:' + (rowsNum + (';cols:' + colsNum))),
				A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'class', 'Tv24Comnet')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Tv24Comnet$Thirty$viewTableHeader, priceTableRequired.eI, priceTableRequired.gB),
				$author$project$Pages$Users$Tv24Comnet$Thirty$viewTableBody(priceTableRequired.gi)
			]));
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeBigCategory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeSmallCategory = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnSubmit = {$: 8};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnSubmitLatest = {$: 9};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnUpdateDate = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnUpdateTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewProductFilter = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$formInline,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-end')
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('大分類')
							])),
						A3(
						$author$project$Components$CategorySelector$bigCategorySelector,
						model.hR,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeBigCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('小分類')
							])),
						A4(
						$author$project$Components$CategorySelector$smallCategorySelector,
						model.jy,
						model.fH,
						model.he,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeSmallCategory)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-3')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('date')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得日')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Form$Input$date(
						_List_fromArray(
							[
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$attrs(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('date')
									])),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$value(model.bs),
								$frandibar$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Users$Tv24Comnet$Thirty$OnUpdateDate)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$group,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column align-items-start mr-5')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$frandibar$elm_bootstrap$Bootstrap$Form$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('取得時刻')
							])),
						A3(
						$author$project$Components$TimeSelector$timeSelector,
						model.Z,
						model.aX,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.aX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Pages$Users$Tv24Comnet$Thirty$OnUpdateTime)
							]))
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
							])),
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Tv24Comnet$Thirty$OnSubmit)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('送信')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Tv24Comnet$Thirty$OnSubmitLatest)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('最新時刻で送信')
					]))
			]));
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeDisplayStyle = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewDisplayStyleSelector = function (selectedDisplayStyle) {
	var displayStyleList = _List_fromArray(
		[
			_Utils_Tuple2('全てを表示', 'DisplayAll'),
			_Utils_Tuple2('価格のみ表示', 'DisplayPrice'),
			_Utils_Tuple2('価格及びポイント表示', 'DisplayPriceAndPoint'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示', 'DisplayAfterP'),
			_Utils_Tuple2('ポイント適用後の価格のみ表示(時刻抜き)', 'DisplayAfterPWithoutTime')
		]);
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$group,
		_List_fromArray(
			[
				$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('表示形式')
					])),
				A2(
				$frandibar$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Users$Tv24Comnet$Thirty$OnChangeDisplayStyle),
						$frandibar$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var label = _v0.a;
						var v = _v0.b;
						return A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(v),
									$rtfeldman$elm_css$Html$Styled$Attributes$selected(
									_Utils_eq(v, selectedDisplayStyle))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(label)
								]));
					},
					displayStyleList))
			]));
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnPressDownloadCSV = {$: 10};
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewDownloadCSVButton = A2(
	$frandibar$elm_bootstrap$Bootstrap$Form$group,
	_List_fromArray(
		[
			$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-column ml-5')
				]))
		]),
	_List_fromArray(
		[
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$label,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('\u3000')
				])),
			A2(
			$frandibar$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Button$primary,
					$frandibar$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Users$Tv24Comnet$Thirty$OnPressDownloadCSV)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('CSVダウンロード')
				]))
		]));
var $author$project$Pages$Users$Tv24Comnet$Thirty$OnToggleStoreGroup = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewStoreGroupCheckbox = F2(
	function (storeGroups, visibleStoreGroups) {
		return A2(
			$frandibar$elm_bootstrap$Bootstrap$Form$group,
			_List_fromArray(
				[
					$frandibar$elm_bootstrap$Bootstrap$Form$attrs(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row mr-3')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$frandibar$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('表示店舗')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row')
								]),
							A2(
								$elm$core$List$map,
								function (storeGroup) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('mr-3')
											]),
										_List_fromArray(
											[
												A2(
												$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
												_List_fromArray(
													[
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$checked(
														A2($elm$core$Set$member, storeGroup.mh, visibleStoreGroups)),
														$frandibar$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																$elm$core$String$fromInt(storeGroup.mh)),
																A2(
																$rtfeldman$elm_css$Html$Styled$Events$on,
																'click',
																A3($elm$json$Json$Decode$map2, $author$project$Pages$Users$Tv24Comnet$Thirty$OnToggleStoreGroup, $rtfeldman$elm_css$Html$Styled$Events$targetChecked, $rtfeldman$elm_css$Html$Styled$Events$targetValue))
															]))
													]),
												storeGroup.mi)
											]));
								},
								storeGroups))
						]))
				]));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewTableControl = function (model) {
	return A2(
		$frandibar$elm_bootstrap$Bootstrap$Form$form,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('d-flex flex-row align-items-start mt-5 mb-1')
			]),
		_List_fromArray(
			[
				A2($author$project$Pages$Users$Tv24Comnet$Thirty$viewStoreGroupCheckbox, model.U, model.L),
				$author$project$Pages$Users$Tv24Comnet$Thirty$viewDisplayStyleSelector(model.cq),
				$author$project$Pages$Users$Tv24Comnet$Thirty$viewDownloadCSVButton
			]));
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewContent = F4(
	function (model, pStoreGroupsHeader_, pStoreHeader_, pProductPriceRows_) {
		var headerStores = A2(pStoreHeader_, model.U, model.L);
		var headerStoreGroups = A2(pStoreGroupsHeader_, model.U, model.L);
		var bodyProducts = pProductPriceRows_(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-3 mr-3 mb-3')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Users$Tv24Comnet$Thirty$viewProductFilter(model),
					A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
					(model.c5 || model.b2) ? $author$project$Components$Loading$loadingCenter(50.0) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					(A2($elm$core$Maybe$withDefault, '', model.ar) !== '') ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($elm$core$Maybe$withDefault, '', model.ar))
						])) : (model.gz ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$Users$Tv24Comnet$Thirty$viewTableControl(model),
							$author$project$Pages$Users$Tv24Comnet$Thirty$viewPriceTable(
							{gi: bodyProducts, eI: headerStoreGroups, gB: headerStores})
						])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$Users$Tv24Comnet$Thirty$viewContentDefault = function (model) {
	return A4($author$project$Pages$Users$Tv24Comnet$Thirty$viewContent, model, $author$project$Pages$Users$Tv24Comnet$Thirty$pStoreGroupsHeader, $author$project$Pages$Users$Tv24Comnet$Thirty$pStoreHeader, $author$project$Pages$Users$Tv24Comnet$Thirty$pProductPriceRows);
};
var $author$project$Pages$Users$Tv24Comnet$Thirty$view = function (model) {
	return {
		ko: $author$project$Pages$Users$Tv24Comnet$Thirty$viewContentDefault(model),
		hx: function () {
			var _v0 = model.hx;
			if (!_v0.$) {
				var title = _v0.a;
				return title;
			} else {
				return $elm$core$String$fromInt(model.Z) + '分取得';
			}
		}()
	};
};
var $rtfeldman$elm_css$Html$Styled$h2 = $rtfeldman$elm_css$Html$Styled$node('h2');
var $rtfeldman$elm_css$Html$Styled$ol = $rtfeldman$elm_css$Html$Styled$node('ol');
var $author$project$Pages$User$viewTop = function (userName) {
	if (userName === 'nnagashio') {
		return {
			ko: A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('ml-5')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h3,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('商品管理')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('user/products/edit-products-permalinks')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Edit products_permalinks')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('user/products/add-product-config')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('商品の追加')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('user/products/yahoo')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Yahoo個別取得URL')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('user/products/change-user-product')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ユーザーごとの商品の変更')
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('user/products/edit-products')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('商品の型番，メーカー，小分類の変更')
											]))
									]))
							]))
					])),
			hx: '管理ページ'
		};
	} else {
		return {
			ko: A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('col-md-10 mx-auto')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h2,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mb-3')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('サービスの概要')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$ol,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('border-ridge')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('このサービスは電化新聞社が提供しているものです。販売店には提供しておりませんので、ご利用は社内に留めて下さい。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('商品ごとの価格情報は随時収集しますが、当該企業のサイトのリニューアルなどの影響を受けることもあります。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('当日の価格のみならず、任意の曜日の価格推移が一覧できます。また、ポイントの併記やポイント分を値引いた表示も可能です。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('表示された価格をクリックすると当該ページにリンクしますから、内容を確認できます。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('全てのページでCSVダウンロードが可能となっています。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('このデータの持ち出しを禁止します。ハードコピーを取った場合はパスワード所持者の責任において破棄して下さい。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('掲載する商品は発売・登録から2年間を目安に削除されます。')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ご不明な点は管理責任者にお問い合わせ下さい。')
											]))
									]))
							]))
					])),
			hx: 'Netgate/インデックスページ'
		};
	}
};
var $author$project$Pages$User$view = function (model) {
	var _v0 = model;
	var userModel = _v0.a;
	var navModel = _v0.b;
	var news = _v0.c;
	return function (rootPageRequired) {
		return _Utils_update(
			rootPageRequired,
			{
				ko: A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Pages$User$userNavBar,
							A3(
								$elm$core$Basics$composeL,
								$elm$core$Maybe$withDefault(''),
								$author$project$Session$userName,
								$author$project$Pages$User$toSession(model)),
							navModel),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('mt-5 mb-3 col-12 col-md-10 mx-auto')
								]),
							$elm$core$String$isEmpty(news) ? _List_Nil : function (result) {
								if (!result.$) {
									var nodes = result.a;
									return function (dom) {
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('news-container')
													]),
												A2($elm$core$List$map, $rtfeldman$elm_css$Html$Styled$fromUnstyled, dom))
											]);
									}(
										$hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes));
								} else {
									return _List_Nil;
								}
							}(
								$hecrj$html_parser$Html$Parser$run(news))),
							rootPageRequired.ko
						]))
			});
	}(
		function () {
			switch (userModel.$) {
				case 0:
					var session = userModel.a;
					return $author$project$Pages$User$viewTop(
						A3(
							$elm$core$Basics$composeL,
							$elm$core$Maybe$withDefault(''),
							$author$project$Session$userName,
							$author$project$Pages$User$toSession(model)));
				case 1:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDefaultMsg,
						$author$project$Pages$Users$Common$Default$view(defaultModel));
				case 2:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTimeSeqMsg,
						$author$project$Pages$Users$Common$TimeSeq$view(defaultModel));
				case 9:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotChangeProductMsg,
						$author$project$Pages$Users$Common$ChangeProduct$view(defaultModel));
				case 10:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmComnetTimeSeqMsg,
						$author$project$Pages$Users$To24tcmComnet$TimeSeq$view(defaultModel));
				case 12:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmComnetDayMsg,
						$author$project$Pages$Users$To24tcmComnet$Day$view(defaultModel));
				case 11:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmComnetCsvMsg,
						$author$project$Pages$Users$To24tcmComnet$Csv$view(defaultModel));
				case 13:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmComnetChangeProductMsg,
						$author$project$Pages$Users$To24tcmComnet$ChangeProduct$view(defaultModel));
				case 14:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmComnetProductMsg,
						$author$project$Pages$Users$To24tcmComnet$Product$view(defaultModel));
				case 46:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tcmKitchenDayMsg,
						$author$project$Pages$Users$To24tcmKitchen$Day$view(defaultModel));
				case 15:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tclComnetChangeProductMsg,
						$author$project$Pages$Users$To24tclComnet$ChangeProduct$view(defaultModel));
				case 16:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tclComnetDayMsg,
						$author$project$Pages$Users$To24tclComnet$Day$view(defaultModel));
				case 17:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTo24tclComnetTimeSeqMsg,
						$author$project$Pages$Users$To24tclComnet$TimeSeq$view(defaultModel));
				case 18:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotJvcComnetTimeSeqMsg,
						$author$project$Pages$Users$JvcComnet$TimeSeq$view(defaultModel));
				case 3:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi24ComnetHourMsg,
						$author$project$Pages$Users$Hi24Comnet$Hour$view(defaultModel));
				case 4:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi24ComnetTimeSeqMsg,
						$author$project$Pages$Users$Hi24Comnet$TimeSeq$view(defaultModel));
				case 5:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi24ComnetWeeklyProductFifteenMsg,
						$author$project$Pages$Users$Hi24Comnet$WeeklyProductFifteen$view(defaultModel));
				case 6:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi24ComnetTenAMMsg,
						$author$project$Pages$Users$Hi24Comnet$TenAM$view(defaultModel));
				case 7:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi3ComnetHourMsg,
						$author$project$Pages$Users$Hi3Comnet$Hour$view(defaultModel));
				case 8:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHi3ComnetTimeSeqMsg,
						$author$project$Pages$Users$Hi3Comnet$TimeSeq$view(defaultModel));
				case 19:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseDayMsg,
						$author$project$Pages$Users$Hisense$Day$view(defaultModel));
				case 20:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseTvHourMsg,
						$author$project$Pages$Users$Hisense$TvHour$view(defaultModel));
				case 22:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseAmazonMsg,
						$author$project$Pages$Users$Hisense$Amazon$view(defaultModel));
				case 24:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseChangeProductMsg,
						$author$project$Pages$Users$Hisense$ChangeProduct$view(defaultModel));
				case 21:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseReviewMsg,
						$author$project$Pages$Users$Hisense$Review$view(defaultModel));
				case 23:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseTimeSeqMsg,
						$author$project$Pages$Users$Hisense$TimeSeq$view(defaultModel));
				case 25:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotHisenseExcelMsg,
						$author$project$Pages$Users$Hisense$Excel$view(defaultModel));
				case 26:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTescomComnetHourMsg,
						$author$project$Pages$Users$TescomComnet$Hour$view(defaultModel));
				case 27:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTescomComnetExcelMsg,
						$author$project$Pages$Users$TescomComnet$Excel$view(defaultModel));
				case 28:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTescomComnetChangeProductMsg,
						$author$project$Pages$Users$TescomComnet$ChangeProduct$view(defaultModel));
				case 29:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotCa6ComnetHourCsvMsg,
						$author$project$Pages$Users$Ca6Comnet$HourCsv$view(defaultModel));
				case 30:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotCa6ComnetWeeklyProductFifteenMsg,
						$author$project$Pages$Users$Ca6Comnet$WeeklyProductFifteen$view(defaultModel));
				case 31:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetChangeProductMsg,
						$author$project$Pages$Users$MlnComnet$ChangeProduct$view(defaultModel));
				case 35:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetChangeMailMsg,
						$author$project$Pages$Users$MlnComnet$ChangeMail$view(defaultModel));
				case 38:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetChangePasswordMsg,
						$author$project$Pages$Users$MlnComnet$ChangePassword$view(defaultModel));
				case 32:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetChronologicalMsg,
						$author$project$Pages$Users$MlnComnet$Chronological$view(defaultModel));
				case 33:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetHourMsg,
						$author$project$Pages$Users$MlnComnet$Hour$view(defaultModel));
				case 34:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetHourYamadaMsg,
						$author$project$Pages$Users$MlnComnet$HourYamada$view(defaultModel));
				case 36:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetAutoReloadMsg,
						$author$project$Pages$Users$MlnComnet$AutoReload$view(defaultModel));
				case 37:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetMailSettingMsg,
						$author$project$Pages$Users$MlnComnet$MailSetting$view(defaultModel));
				case 39:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotMlnComnetYahooMsg,
						$author$project$Pages$Users$MlnComnet$Yahoo$view(defaultModel));
				case 40:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTv24ComnetChangeProductMsg,
						$author$project$Pages$Users$Tv24Comnet$ChangeProduct$view(defaultModel));
				case 41:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTv24ComnetThirtyMsg,
						$author$project$Pages$Users$Tv24Comnet$Thirty$view(defaultModel));
				case 42:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotElecComnetHourMsg,
						$author$project$Pages$Users$ElecComnet$Hour$view(defaultModel));
				case 43:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotElecComnetExcelMsg,
						$author$project$Pages$Users$ElecComnet$Excel$view(defaultModel));
				case 44:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotElecComnetChangeProductMsg,
						$author$project$Pages$Users$ElecComnet$ChangeProduct$view(defaultModel));
				case 45:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotElecComnetTimeSeqMsg,
						$author$project$Pages$Users$ElecComnet$TimeSeq$view(defaultModel));
				case 47:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDelonghiHourMsg,
						$author$project$Pages$Users$Delonghi$Hour$view(defaultModel));
				case 48:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDelonghiChangeProductMsg,
						$author$project$Pages$Users$Delonghi$ChangeProduct$view(defaultModel));
				case 49:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDelonghiTimeSeqMsg,
						$author$project$Pages$Users$Delonghi$TimeSeq$view(defaultModel));
				case 50:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDelonghiTimeSeq2Msg,
						$author$project$Pages$Users$Delonghi$TimeSeq2$view(defaultModel));
				case 51:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotKinujoComnetExcelMsg,
						$author$project$Pages$Users$KinujoComnet$Excel$view(defaultModel));
				case 52:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotKinujoComnetChangeProductMsg,
						$author$project$Pages$Users$KinujoComnet$ChangeProduct$view(defaultModel));
				case 53:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotKinujoComnetFifteeenMsg,
						$author$project$Pages$Users$KinujoComnet$Fifteen$view(defaultModel));
				case 54:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetWideMsg,
						$author$project$Pages$Users$ShaComnet$Wide$view(defaultModel));
				case 55:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetHourMsg,
						$author$project$Pages$Users$ShaComnet$Hour$view(defaultModel));
				case 56:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetTimeSeqMsg,
						$author$project$Pages$Users$ShaComnet$TimeSeq$view(defaultModel));
				case 57:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetWeeklyProductMsg,
						$author$project$Pages$Users$ShaComnet$WeeklyProduct$view(defaultModel));
				case 58:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetDayMsg,
						$author$project$Pages$Users$ShaComnet$Day$view(defaultModel));
				case 59:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotShaComnetChangeProductMsg,
						$author$project$Pages$Users$ShaComnet$ChangeProduct$view(defaultModel));
				case 60:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTstComnetTimeSeqMsg,
						$author$project$Pages$Users$TstComnet$TimeSeq$view(defaultModel));
				case 61:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTstComnetWideMsg,
						$author$project$Pages$Users$TstComnet$Wide$view(defaultModel));
				case 62:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDbi6ComnetHourMsg,
						$author$project$Pages$Users$Dbi6Comnet$Hour$view(defaultModel));
				case 63:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDbi6ComnetTimeSeqMsg,
						$author$project$Pages$Users$Dbi6Comnet$TimeSeq$view(defaultModel));
				case 65:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDenon6ComnetWideMsg,
						$author$project$Pages$Users$Denon6Comnet$Wide$view(defaultModel));
				case 64:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotDenon6ComnetTimeSeqMsg,
						$author$project$Pages$Users$Denon6Comnet$TimeSeq$view(defaultModel));
				case 71:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotBraunComnetHourMsg,
						$author$project$Pages$Users$BraunComnet$Hour$view(defaultModel));
				case 72:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotBraunComnetWideMsg,
						$author$project$Pages$Users$BraunComnet$Wide$view(defaultModel));
				case 67:
					var reiModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotChangeUserProductMsg,
						$author$project$Pages$Cms$Products$ChangeUserProduct$view(reiModel));
				case 66:
					var reiModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotEditProductsPermalinks,
						$author$project$Pages$Cms$Products$EditProductsPermalinks$view(reiModel));
				case 68:
					var reiModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotAddProductConfigMsg,
						$author$project$Pages$Cms$Products$AddProductConfig$view(reiModel));
				case 69:
					var reiModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotEditProductsMsg,
						$author$project$Pages$Cms$Products$EditProducts$view(reiModel));
				case 70:
					var yModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotYahooMsg,
						$author$project$Pages$Cms$Stores$Yahoo$view(yModel));
				case 74:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTsBatWideMsg,
						$author$project$Pages$Users$TsBat$Wide$view(defaultModel));
				case 73:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTsBatTimeSeqMsg,
						$author$project$Pages$Users$TsBat$TimeSeq$view(defaultModel));
				case 75:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotFujiComnetDayMsg,
						$author$project$Pages$Users$FujiComnet$Day$view(defaultModel));
				default:
					var defaultModel = userModel.a;
					return A2(
						$author$project$Page$map,
						$author$project$Pages$User$GotTestFifteeenMsg,
						$author$project$Pages$Users$Test$Fifteen$view(defaultModel));
			}
		}());
};
var $author$project$Main$view = function (model) {
	var viewPage = F2(
		function (toMsg, pageRequired) {
			var _v1 = $author$project$Page$view(pageRequired);
			var title = _v1.hx;
			var body = _v1.cK;
			return {
				cK: A2(
					$elm$core$List$map,
					$elm$html$Html$map(toMsg),
					body),
				hx: title
			};
		});
	switch (model.$) {
		case 0:
			var session = model.a;
			return $author$project$Page$view(
				{
					ko: A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
					hx: ''
				});
		case 1:
			var loginModel = model.a;
			return A2(
				viewPage,
				$author$project$Main$GotLoginMsg,
				$author$project$Pages$Login$view(loginModel));
		case 2:
			var cmsModel = model.a;
			return A2(
				viewPage,
				$author$project$Main$GotCmsMsg,
				$author$project$Pages$Cms$view(cmsModel));
		default:
			var userModel = model.a;
			return A2(
				viewPage,
				$author$project$Main$GotUserMsg,
				$author$project$Pages$User$view(userModel));
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{w: $author$project$Main$init, lH: $author$project$Main$OnNavigation, lI: $author$project$Main$LinkClicked, mn: $author$project$Main$subscriptions, ad: $author$project$Main$update, mE: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$value)
			])))(0)}});}(this));